export const WALLET_PT = {
  balanceTable: {
    columns: {
      balance: 'Quantidade',
      price: 'Preço token',
      sacas: 'Sacas',
      token: 'Ativo',
    },
    emptyState: {
      subtitle: 'Quando você os tiver, você os verá aqui',
      title: 'No tenés balance',
    },
    loadingError: 'Erro ao carregar saldo',
    subtitle: 'Resumo de seus ativos',
    title: 'Carteira',
  },
  header: {
    subtitle: '',
    title: 'Movimentos',
  },
  movementsTable: {
    columns: {
      amount: 'Quantidade',
      business: 'Usuário',
      category: 'Categoria',
      date: 'Data',
      linkCreator: 'Criador de links',
      status: 'Status',
      transactionType: 'Tipo de transação',
      user: 'Usuário interno',
      value: 'Valorização',
      type: 'Tipo',
      sacas: 'Sacas',
    },
    detailsButtonLabel: 'Detalhe',
    download: 'Baixar em CSV',
    downloadBalance: 'Baixar extrato da conta',
    downloadBalanceFileName: 'extrato_da_conta',
    emptyState: {
      subtitle: 'Quando você fizer um movimento, você o verá aqui',
      title: 'Você não tem movimentos',
    },
    loadingError: 'Erro ao carregar movimentos',
    status: {
      placeholder: 'Filtrar por status',
      completed: 'Concluído',
      expired: 'Expirado',
      failed: 'Fracassado',
      liquidated: 'Liquidado',
      pending: 'Pendente',
      notClaimable: 'Liquidez pendente',
      claimed: 'Concluído',
      claimAvailable: 'Claim disponível',
      claimable: 'Claim disponível',
      cancel: 'Cancelada',
      rejected: 'Recusada',
      all: 'Todo',
    },
    subtitle: 'Aqui você pode ver um resumo de todos seus movimentos da conta.',
    tabs: {
      all: 'Todo',
      guarantees: 'Garantias',
      agrotokens: 'Agrotokens',
      fixedGrains: 'Grãos fixados',
    },
    title: 'Movimentos da conta',
    transactionType: {
      creditCardBlockedReturn: 'Devolução por bloqueio de pagamento com cartão',
      detokenization: 'Destokenização',
      fee: 'Taxa de transação',
      gift: 'Benefício',
      loans: {
        granted: 'Bloqueio de tokens',
        reimbursement: 'Devolução da garantia',
        fee: 'Comissão Agrotoken',
        loanPaid: 'Pagamento enviado',
      },
      payment: {
        cryptoAssetSettlement: 'Conversão para {{localCurrency}}',
        received: 'Pagamento recebido',
        sent: 'Pagamento enviado',
      },
      paymentInKind: {
        cryptoAssetSettlement: 'Liquidação de ativos',
        received: 'Pagamento em espécie recebido',
        sent: 'Pagamento em espécie enviado',
      },
      forwarding: {
        sent: 'Pagamento encaminhado',
        received: 'Pagamento recebido',
      },
      paymentWithCard: 'Pagamento com cartão',
      cardAuthorizationHold: 'Bloqueio de pagamento com cartão',
      tokenization: 'Tokenização de agrotokens',
      swapPogr: 'Passar em perpetuidade',
      unknown: 'Nome da transação não disponível',
      warranty: 'Tokenização',
      warrantyId: 'Garantia #{{number}}',
      warrantyClaim: 'Liquidação solicitada',
      collateralPaymentReceived: 'Pagamento com garantia recebido',
      paymentWithFeitoGuarantee: 'Pagamento com garantia feito',
      freezeTokens: 'Bloqueio',
      unfreezeTokens: 'Desbloqueio',
      warrantyTransfer: {
        sent: 'Transferência de garantia enviada',
        received: 'Transferência de garantia recebida',
      },
    },
    tooltipMessage: {
      cancelWarrantyTransfer:
        'Você cancelou a transferência. Os tokens foram desbloqueados e estão novamente disponíveis em sua conta.',
      rejectedWarrantyTransfer:
        'O usuário receptor recusou a transferência. Os tokens foram desbloqueados e estão novamente disponíveis em sua conta.',
      expiredWarrantyTransfer:
        'A transferência expirou sem resposta do destinatário. Os tokens foram desbloqueados e estão novamente disponíveis na sua conta.',
      createdWarrantyTransfer:
        'Os seus tokens foram bloqueados até que o destinatário da transferência os aceite ou a solicitação expire.',
    },
  },
  tokenCards: {
    subtitle: 'Tokens disponíveis:',
    title: 'Agrotokens',
    SACAS: 'Sacas',
  },
  warrantyTokens: {
    badge: 'Nova funcionalidade',
    cardTitle: 'Link de pagamento de garantia',
    loadingError: 'Erro ao carregar empréstimos',
    more: 'Veja mais',
    subtitle: 'Resumo de seus tokens sendo usados como garantia.',
    title: 'Tokens colaterais',
  },
  warrantySection: {
    subtitle: 'Garantias de grãos digitalizadas:',
    title: 'Garantias',
    tooltipPF: 'Equivalência com base no valor do contrato de tokenizaçao',
    tooltipPAF:
      'Equivalência calculada em tempo real com base na localização do grão: {{city}}, {{geographicalDivision}}',
    fixCondition: 'Condição de fixação',
    fixedPrice: 'Preço fixo',
    fixedPriceDescription:
      'Equivalência calculada em tempo real com base na localização do grão',
    priceToFixAGTK: 'Preço para fixar Agrotoken',
    priceToFixAGTKDescription: 'Completar',
    priceToFix: 'Preço a fixar',
    priceToFixDescription:
      'Equivalência com base no valor do contrato de tokenização.',

    availableTons: 'Toneladas disponíveis',
    availableSacas: 'Sacas disponíveis',
    expireDate: 'Data de vencimento',
  },
};
