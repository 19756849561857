import { TransferStatus, TRANSFER_STATUSES } from '../../types';

interface GetAlertPropsByStatusParams {
  isSender: boolean;
  status?: TransferStatus;
}

const getAlertPropsByStatus = ({
  isSender,
  status,
}: GetAlertPropsByStatusParams): { text: string; supportText?: string } => {
  const translation = isSender ? 'sender' : 'receiver';

  switch (status) {
    case TRANSFER_STATUSES.CANCELLED:
      return { text: `alerts.${translation}.cancelled.text` };

    case TRANSFER_STATUSES.EXPIRED:
      return {
        text: `alerts.${translation}.expired.text`,
        supportText: `alerts.${translation}.expired.supportText`,
      };

    case TRANSFER_STATUSES.REJECTED:
      return { text: `alerts.${translation}.rejected.text` };

    default:
      return { text: '' };
  }
};

export default getAlertPropsByStatus;
