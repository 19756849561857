import { Button } from '@agrotoken-tech/ui';
import {
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../context/authContext';
import { BREAKPOINTS } from '@common/const';

const EmailConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const [otp, setOtp] = useState('');
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { encodedEmail } = useParams();
  const email = encodedEmail && atob(encodedEmail);

  const handleResendCode = async () => {
    email && (await auth.resendUserConfirmation(email));
  };
  const onCompleteOtp = (value: string) => {
    setOtp(value);
    setIsInvalidOtp(false);
  };

  const confirmEmail = () => {
    if (otp && email) {
      setIsLoading(true);
      auth
        .confirmUser(email, otp)
        .then(() => {
          navigate('/sign-up-success');
        })
        .catch((err) => {
          setIsInvalidOtp(true);
          console.error('Cant confirm user: ', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const isMobile = window.innerWidth < BREAKPOINTS.md;

  return (
    <Flex direction="column">
      <Flex direction={['column', 'column', 'row']} align="center">
        <HStack m="6" width={isMobile ? '100%' : undefined}>
          <PinInput
            otp
            onComplete={onCompleteOtp}
            placeholder=""
            isInvalid={isInvalidOtp}
          >
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
          </PinInput>
        </HStack>
        <Button
          variant="primary"
          onClick={confirmEmail}
          isLoading={isLoading}
          size="lg"
          width={isMobile ? '100%' : undefined}
        >
          {t('softOnboarding.emailConfirmation.next')}
        </Button>
      </Flex>
      <Divider my="6" />
      {isMobile ? (
        <>
          <Text variant="medium">
            {t('softOnboarding.emailConfirmation.confirmationTitle')}
          </Text>
          <Text color="gray.500">
            {t('softOnboarding.emailConfirmation.confirmationSubtitle')}
          </Text>
        </>
      ) : (
        <>
          <Heading as="h6" size="xs">
            {t('softOnboarding.emailConfirmation.confirmationTitle')}
          </Heading>
          <Text>
            {t('softOnboarding.emailConfirmation.confirmationSubtitle')}
          </Text>
        </>
      )}

      <Flex my="6">
        <FormControl>
          <FormLabel htmlFor="email">
            {isMobile ? (
              <Text variant="medium">
                {t('softOnboarding.emailConfirmation.emailLabel')}
              </Text>
            ) : (
              <Heading as="h6" size="xs">
                {t('softOnboarding.emailConfirmation.emailLabel')}
              </Heading>
            )}
          </FormLabel>
          <Stack spacing="6" direction={['column', 'column', 'row']}>
            <Input id="email" type="email" value={email} disabled />
            <Flex w="full">
              <Button
                variant="secondary"
                w="full"
                maxW={isMobile ? 'full' : 'fit-content'}
                onClick={() => handleResendCode()}
              >
                {t('softOnboarding.emailConfirmation.resend')}
              </Button>
            </Flex>
          </Stack>
        </FormControl>
      </Flex>
    </Flex>
  );
};

export default EmailConfirmation;
