import { Center, Flex, Heading, Text } from '@chakra-ui/react';
import { BarChartIcon } from './Svg/BarChartIcon';
import React from 'react';
import { CircledCheck } from './Svg/CircledCheck';

interface Props {
  isActive?: boolean;
}
export const CreditsBanner = ({ isActive }: Props) => {
  const colorsProps = isActive
    ? {
        bg: 'linear-gradient(92.06deg, #1570EF 0.68%, #6120EA 98.97%);',
        color: 'white',
      }
    : {
        bg: 'white',
        color: 'gray.900',
        border: '1px solid var(--chakra-colors-gray-200)',
      };

  return (
    <Center
      p="24px"
      borderRadius="4px"
      {...colorsProps}
      w="full"
      justifyContent={'space-between'}
    >
      <Flex flexDir="column">
        <Heading fontFamily="Larsseit-Medium" fontWeight="500" fontSize="18px">
          Este préstamo se encuentra activo
        </Heading>
        <Text fontFamily="Larsseit" fontSize="14px">
          Para finalizar este préstamo, contactate con el prestamista.
        </Text>
      </Flex>
      {isActive ? <BarChartIcon /> : <CircledCheck />}
    </Center>
  );
};
