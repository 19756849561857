import { useEffect, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { PAYMENT_PATH } from '../../../../common/const';
import { TBreadcrumbOption } from '../../../../common/types';
import { Container } from '../../../Global/Container';
import { Box, Flex } from '@chakra-ui/react';

interface Props {
  header: React.ReactNode;
  main: React.ReactNode;
  rightSide: React.ReactNode;
  breadcrumbOptions?: TBreadcrumbOption[];
  customWidth?: {
    main: string;
    right?: string;
  };
}

export const PaymentLayout: VFC<Props> = ({
  header,
  main,
  rightSide,
  breadcrumbOptions,
  customWidth,
}) => {
  const { t } = useTranslation();
  const { setOptions } = useOutletContext() as any;
  const widthMain = customWidth?.main ?? 'md:w-2/3';

  breadcrumbOptions = breadcrumbOptions ?? [
    { label: t('breadCrumb.paymentRequests'), to: PAYMENT_PATH },
    {
      label: t('breadCrumb.paymentRequestDetails'),
      to: '',
    },
  ];

  useEffect(() => {
    setOptions(breadcrumbOptions);
    return () => {
      setOptions([]);
    };
  }, []);

  return (
    <Container>
      <Box width="100%">
        <Flex flexDirection="column">
          <Box marginBottom={2}>{header}</Box>
          <Flex>
            <Box flex={1} marginRight={8}>
              <Box>{main}</Box>
            </Box>
            <Box maxWidth="516px" width="100%">
              {rightSide}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
};
