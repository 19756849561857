import { ENationalities, ISOCurrency } from '@agrotoken/common-utils';
import {
  ETokenizationOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import BigNumber from 'bignumber.js';
import { Decimal } from 'decimal.js';
import { t } from 'i18next';
import moment from 'moment';
import { AUTOMATED_REMINDER_HOURS } from '@common/const';
import { GrainContractValidationStatus } from '@common/enum';
import {
  ECreatorStatus,
  EDocusignStatus,
  ESignatureTypes,
  EGrainContractStatus,
  ELocalCurrency,
  ETokens,
  ETokensBR,
  EUnits,
  IBalance,
  IDocusign,
  IGrainContract,
  IUser,
  EDocusignType,
} from '../types';

export * from './fees';
export * from './calculate-tons-in-sacas';
export * from './PaymentRequests/warranties';
export * from './getWarrantyOrigin';
export * from './convertFiatToSacas';
export * from './format-date';

// redondea numeros y lo pasa como enteros para enviar a blockchain
export const parseUnits = (
  value: string | number,
  decimals: number
): string => {
  const ten = new BigNumber(10);
  const value_ = new BigNumber(value);
  const decimals_ = new BigNumber(decimals);
  return value_.times(ten.pow(decimals_)).toFixed(0, BigNumber.ROUND_CEIL);
};

// ui y mails
export const formatNumber = (
  value: number | Decimal | null | undefined,
  unit: EUnits | ISOCurrency,
  showCurrencyLabel = false
): string => {
  let valueToFormat = value;
  if (!value) valueToFormat = 0;

  let locale = process.env.DEFAULT_LOCALE_STRING || 'es-AR';
  if (unit === EUnits.BRL) {
    locale = 'pt-BR';
  }

  const maximumFractionDigits = {
    [EUnits.FIAT]: 2,
    [EUnits.ARS]: 2,
    [EUnits.BRL]: 2,
    [EUnits.USD]: 2,
    [EUnits.TONS]: 4,
    [EUnits.CRYPTO]: 4,
    [EUnits.PARAM]: 2,
    [EUnits.SACAS]: 2,
    [EUnits.FIAT]: 2,
  };
  const minimumFractionDigits = {
    [EUnits.FIAT]: 2,
    [EUnits.ARS]: 2,
    [EUnits.BRL]: 2,
    [EUnits.USD]: 2,
    [EUnits.TONS]: 2,
    [EUnits.CRYPTO]: 4,
    [EUnits.PARAM]: 0,
    [EUnits.SACAS]: 2,
    [EUnits.FIAT]: 2,
  };

  let result;
  if (showCurrencyLabel && unit !== EUnits.SACAS) {
    result = Number(valueToFormat).toLocaleString(locale, {
      maximumFractionDigits: maximumFractionDigits[unit],
      minimumFractionDigits: minimumFractionDigits[unit],
      style: 'currency',
      currency: unit,
    });
  } else {
    result = Number(valueToFormat).toLocaleString(locale, {
      maximumFractionDigits: maximumFractionDigits[unit],
      minimumFractionDigits: minimumFractionDigits[unit],
    });
  }

  if (showCurrencyLabel && unit === EUnits.SACAS) {
    result += ' SACAS';
  }

  return result;
};

export const formatFileName = (fileName: string, length = 25): string => {
  const fileType = fileName.slice(fileName.lastIndexOf('.'));
  if (fileName.length > length) {
    return `${fileName.substring(0, length)}...${fileType}`;
  } else {
    return fileName;
  }
};

// function that calculate reserved tons when the agreement type is fixed price
export const calculateTonsReserved = (
  tons: number,
  percentReserved: number
): Decimal => {
  const result = tons - tons / (1 + percentReserved / 100);
  return roundValue({
    value: result,
    operationType: ETokenizationOperationType.RESERVE,
    scale: 4,
  });
};

//functionToCalculate Tons In Sacas

export const tokensToTons = (tokens: number, multiplier: number): number => {
  return tokens * multiplier;
};

// parse dates to send them in the correct type to BE
export const parseDate = (date: Date | string): number => {
  return moment(date).startOf('day').unix();
};

export const getTodayTimestamp = (): number => {
  return moment().unix();
};

export const getTotalBalanceAmountFiatAR = (
  balances: IBalance[],
  rateArs: number,
  rateArsSoy: number,
  rateSoyaArs: number
) => {
  return balances.reduce((acc, curr) => {
    const amount = +curr.amount;
    const currencyRate = +curr.rate;

    let rateToUse = rateArs;
    if (curr.name === ETokens.SOYA && (rateSoyaArs || rateArsSoy)) {
      rateToUse = rateSoyaArs ? rateSoyaArs : rateArsSoy;
    }
    if (rateSoyaArs && curr.name === ETokens.SOYA) {
      return acc + amount * rateToUse;
    }

    return acc + amount * currencyRate * rateToUse;
  }, 0);
};

export const getTotalBalanceAmountFiatBR = (
  balances: IBalance[],
  rate: number
) => {
  return balances.reduce((acc, curr) => {
    const { amount, rate: currencyRate } = curr;
    return acc + rate * +amount * +currencyRate;
  }, 0);
};

export const currentUserCreator = (
  currentUser: IUser,
  selectedBusinessId: number
): boolean =>
  currentUser?.businessUsers.find((bu) => bu.businessId === selectedBusinessId)
    ?.isCreator === ECreatorStatus.IS_CREATOR;

export const isAlmostExpired = (expirationDate: number): boolean => {
  const reminderDate = moment
    .unix(expirationDate)
    .subtract(AUTOMATED_REMINDER_HOURS, 'hours')
    .startOf('day');
  const now = moment().startOf('day');
  const expirationDateUnix = moment.unix(expirationDate).startOf('day');
  return (
    moment(now).isSameOrAfter(reminderDate) &&
    moment(now).isBefore(expirationDateUnix)
  );
};

export const dateLabel = (
  nationality: ENationalities,
  isFixedPrice: boolean
) => {
  if (nationality === ENationalities.BR)
    return t('tokenization.summary.documentationCard.expirationDate');
  if (isFixedPrice)
    return t('tokenization.summary.documentationCard.maxContractDeadline');
  return t('tokenization.summary.documentationCard.maxFixationDeadline');
};

export const getLocalCurrency = (nationality: string) => {
  switch (nationality) {
    case ENationalities.BR:
      return ELocalCurrency.BRL;
    case ENationalities.AR:
    default:
      return ELocalCurrency.ARS;
  }
};

export const getLocalToken = (nationality: string) => {
  switch (nationality) {
    case ENationalities.BR:
      return {
        tokenSoy: ETokensBR.SOYB,
        tokenCor: ETokensBR.CORB,
      };
    case ENationalities.AR:
    default:
      return {
        tokenSoy: ETokens.SOYA,
        tokenCor: ETokens.CORA,
        tokenWhe: ETokens.WHEA,
      };
  }
};

export const getBankAccountIdentifierForRegion = (
  region: ENationalities
): string => {
  switch (region) {
    case ENationalities.AR:
    default:
      return 'CBU';
    case ENationalities.BR:
      return '';
  }
};

export const isPendingDocusignRescission = (
  grainContract: IGrainContract,
  isDocuSignCreditAssignment: boolean
) => {
  const detokenizationStatus = [
    EGrainContractStatus.DETOKENIZATION_IN_PROGRESS,
    EGrainContractStatus.DETOKENIZED,
  ];

  if (
    isDocuSignCreditAssignment &&
    detokenizationStatus.includes(grainContract.grainContractStatus.name)
  ) {
    const rescissionNotification = grainContract.docusigns.find(
      (docusign) =>
        docusign.docusignTypeId === EDocusignType.RESCISSION_NOTIFICATION
    );

    return (
      rescissionNotification?.docusignStatusId === EDocusignStatus.PENDING_USER
    );
  }

  return false;
};

export const alertContent = (
  grainContract: IGrainContract,
  docusigns: IDocusign[],
  region: ENationalities,
  isFutureGrain: boolean,
  currentUserId?: number
) => {
  const { tonsVerified, tonsRequested, grainContractStatus, signatureType } =
    grainContract;

  const hasLessTonsVerified: boolean =
    tonsVerified && tonsRequested
      ? Number(tonsVerified) < Number(tonsRequested)
      : false;

  const isManualCreditAssignment =
    signatureType?.keyCode === ESignatureTypes.MANUAL;

  const isDocuSignCreditAssignment =
    signatureType?.keyCode === ESignatureTypes.DOCUSIGN;

  //DOCUSIGN & MANUAL (futuro & entregue)
  const isPendingApproval =
    grainContractStatus.name === EGrainContractStatus.PENDING_APPROVAL &&
    !(
      grainContract.validationStatus === GrainContractValidationStatus.APPROVED
    );

  //DOCUSIGN & MANUAL (futuro & entregue)
  const isOfferLetterPendingUser =
    (grainContractStatus.name === EGrainContractStatus.PENDING_APPROVAL &&
      grainContract.validationStatus ===
        GrainContractValidationStatus.APPROVED) ||
    (grainContractStatus.name === EGrainContractStatus.OFFER_LETTER &&
      docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_USER);

  const isOfferLetterPendingAgtk =
    grainContractStatus.name === EGrainContractStatus.OFFER_LETTER &&
    docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_AGTK;

  const isDocuSignOfferLetterPendingAgtk =
    grainContractStatus.name === EGrainContractStatus.OFFER_LETTER &&
    (docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_AGTK ||
      docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_ORACLE ||
      docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_WITNESSES);

  const isOfferLetterSigningComplete =
    grainContractStatus.name === EGrainContractStatus.OFFER_LETTER &&
    docusigns[0]?.docusignStatusId === EDocusignStatus.COMPLETED;

  const isNotificationLetterPending =
    grainContractStatus.name === EGrainContractStatus.ASSIGNMENT_NOTIFICATION;

  const isBlockchainStatus =
    grainContractStatus.name === EGrainContractStatus.PENDING_BLOCKCHAIN ||
    grainContractStatus.name === EGrainContractStatus.IN_PROGRESS_BLOCKCHAIN;

  const isTokenizationCompleted =
    grainContractStatus.name === EGrainContractStatus.TOKENIZED ||
    grainContractStatus.name ===
      EGrainContractStatus.DETOKENIZATION_IN_PROGRESS ||
    grainContractStatus.name === EGrainContractStatus.DETOKENIZED;

  let title = '';
  let supportingText = '';

  if (region === ENationalities.AR) {
    if (isPendingApproval) {
      title = t('tokenization.summary.pendingApproval.title');
      supportingText = t('tokenization.summary.pendingApproval.text');
    }
    if (isOfferLetterPendingUser && hasLessTonsVerified) {
      title = `${t('tokenization.summary.lessTonsVerifiedTitle')} ${
        grainContract.tonsVerified
      } tons`;
      supportingText = t('tokenization.summary.lessTonsVerifiedText');
    }
    if (isOfferLetterPendingUser) {
      if (currentUserId && currentUserId !== grainContract.userId) {
        title = t('tokenization.summary.noSignOfferLetterTitle');
        supportingText = t('tokenization.summary.noSignOfferLetterText');
      } else {
        title = t('tokenization.summary.signOfferLetterTitle');
        supportingText = t('tokenization.summary.signOfferLetterText');
      }
    }

    if (
      isOfferLetterPendingAgtk ||
      isOfferLetterSigningComplete ||
      isNotificationLetterPending ||
      isBlockchainStatus
    ) {
      title = t('tokenization.summary.offerLetterValidationTitle');
      supportingText = t('tokenization.summary.offerLetterValidationText');
    }
    if (isTokenizationCompleted) {
      title = t('tokenization.summary.tokenizationCompletedTitle');
      supportingText = t('tokenization.summary.tokenizationCompletedText');
    }
    return { title, supportingText };
  }

  if (region === ENationalities.BR) {
    //TODO: FIX CASE WHICH DOESN'T SHOW CORRESPONDING LINK IN MARGEM DA SEGURANCA BECAUSE OF THE NEW FUNCTION USED

    if (isPendingApproval) {
      if (isFutureGrain) {
        title = t('tokenization.summary.pendingApproval.futureTitle');
        supportingText = t('tokenization.confirm.futurePendingText');
      } else {
        title = t('tokenization.summary.pendingApproval.title');
        supportingText = [
          t('tokenization.summary.pendingApproval.text'),
          t('tokenization.confirm.alertLink'),
          t('tokenization.summary.pendingApproval.text2'),
        ].join(' ');
      }
    }

    //MANUAL PROCESS
    if (
      isOfferLetterPendingUser &&
      hasLessTonsVerified &&
      isManualCreditAssignment
    ) {
      title = `${t('tokenization.summary.lessTonsVerifiedTitle')} ${
        grainContract.tonsVerified
      } tons`;
      supportingText = t(
        'tokenization.summary.creditAssigmentTypeManualLessTons'
      );
    }
    if (
      isOfferLetterPendingUser &&
      isManualCreditAssignment &&
      !hasLessTonsVerified
    ) {
      title = t('tokenization.summary.creditAssigmentTypeManualTitle');
      supportingText = isFutureGrain
        ? t('tokenization.summary.futureCreditAssignmentTypeManualTotalTons')
        : t('tokenization.summary.manualCreditAssigmentText');
    }

    //DOCUSIGN PROCESS

    if (
      isOfferLetterPendingUser &&
      hasLessTonsVerified &&
      isDocuSignCreditAssignment
    ) {
      title = `${t('tokenization.summary.lessTonsVerifiedTitle')} ${
        grainContract.tonsVerified
      } tons`;
      supportingText = t(
        'tokenization.summary.creditAssigmentTypeDocusignLessTons'
      );
    }

    if (
      isOfferLetterPendingUser &&
      isDocuSignCreditAssignment &&
      !hasLessTonsVerified
    ) {
      if (currentUserId && currentUserId !== grainContract.userId) {
        title = t('tokenization.summary.noSignOfferLetterTitle');
        supportingText = t('tokenization.summary.noSignOfferLetterText');
      } else {
        title = t('tokenization.summary.creditAssigmentTypeDocusignTitle');
        supportingText = isFutureGrain
          ? t('tokenization.summary.futureCreditAssigmentTypeDocusignText')
          : t('tokenization.summary.docusignCreditAssigmentText');
      }
    }
    if (
      isDocuSignOfferLetterPendingAgtk ||
      isOfferLetterSigningComplete ||
      isNotificationLetterPending ||
      isBlockchainStatus
    ) {
      title = t('tokenization.summary.offerLetterValidationTitle');
      supportingText = t(
        'tokenization.summary.creditAssigmentTypeDocusignPendingAgtkText'
      );
    }

    const warrantySettlementStatus = [
      EGrainContractStatus.PENDING_SETTLEMENT,
      EGrainContractStatus.CHARGED_AND_PENDING_PAYMENT,
      EGrainContractStatus.PAID_COMPLETED,
    ];
    const isWarrantySettlementStatus = warrantySettlementStatus.includes(
      grainContract.grainContractStatus.name
    );

    if (isTokenizationCompleted || isWarrantySettlementStatus) {
      title = t('tokenization.summary.tokenizationCompletedTitle');
      supportingText = t('tokenization.summary.tokenizationCompletedText');
    }

    if (
      isPendingDocusignRescission(grainContract, isDocuSignCreditAssignment)
    ) {
      title = t('detokenization.summary.rescissionDocusignPendingTitle');
      supportingText = t(
        'detokenization.summary.rescissionDocusignPendingText'
      );
    }
  }
  return { title, supportingText };
};

export const tonsToSacas = (amount: number) => {
  if (!amount) return 0;
  const result = (amount * 1000) / 60;
  return ((result * 100) / 100).toFixed(2);
};
