import { REGEX } from '@agrotoken/common-utils';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { usePostalCodeConditionsByNationality } from './usePostalCodeConditionsByNationality';

const useValidationSchema = () => {
  const { t } = useTranslation();
  const isRequired = `${t('general.form.requiredMsg')}`;
  const { postalCodeSchema } = usePostalCodeConditionsByNationality();

  const requestCardFlowSchema = yup.object({
    street: yup.string().required(isRequired),
    streetNumber: yup
      .string()
      .required(t('card.shipmentCard.form.errors.onlyNumbers')),
    geographicalDivisionId: yup.string().required(isRequired),
    city: yup.string().required(isRequired),
    postalCode: postalCodeSchema,
    consentTerms: yup
      .boolean()
      .default(false)
      .oneOf(
        [true],
        t('softOnboarding.signUpScreen.form.termsAndConditionsError')
      ),
    apartment: yup
      .string()
      .max(10, t('general.form.errors.maxCharacters', { max: 10 }))
      .matches(
        new RegExp(`${REGEX.ADDRESS.DEFAULT}|${REGEX.ALLOW_EMPTY}`),
        t('general.form.errors.specialCharacters')
      ),
    floor: yup
      .string()
      .max(10, t('general.form.errors.maxCharacters', { max: 10 }))
      .matches(
        new RegExp(`${REGEX.NUMERIC}|${REGEX.ALLOW_EMPTY}`),
        t('card.shipmentCard.form.errors.onlyNumbers')
      ),
  });

  return { requestCardFlowSchema };
};

export default useValidationSchema;
