import { useMutation } from 'react-query';

import { collateralizeLoan } from '@services/loans';

const useCollateralizeLoan = () => {
  const { mutate, isLoading, error } = useMutation(
    ['loans', 'collateralize'],
    (id: string) => collateralizeLoan(id)
  );

  return { mutate, isLoading, error };
};

export default useCollateralizeLoan;
