import { ENationalities } from '@agrotoken/common-utils';
import { Button, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { MovementsTabs } from '@common/enum/movements.enum';
import { useAuth } from '@src/context/authContext';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { EmptyState } from '../Global/EmptyState';
import { MovementsToolbar } from './MovementsToolbar';

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  onChangeTab?: (value: MovementsTabs) => void;
  showTabs?: boolean;
  onRowClick?: (row: any) => void;
  cursor?: string;
}

function MovementsTable<T extends object>({
  columns,
  data,
  onChangeTab,
  showTabs = true,
  onRowClick,
  cursor,
}: TableProps<T>): React.ReactElement {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    pageCount,
    setFilter,
    setGlobalFilter,
  } = useTable<T>(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const { t } = useTranslation();
  const { pageIndex } = state;
  const { nationality } = useAuth();
  const attributes: {
    cursor?: string;
    className?: string;
    onClick?: (uuid: string) => void;
  } = {};

  attributes.cursor = cursor ? cursor : 'pointer';
  attributes.className = 'border-t border-gray-200';

  if (data && data.length === 0)
    return (
      <EmptyState
        color="primary"
        theme="dark-circle"
        title={t('wallet.movementsTable.emptyState.title')}
        text={t('wallet.movementsTable.emptyState.subtitle')}
        ilustration="cloud"
        className="py-6"
      />
    );

  return (
    <>
      <MovementsToolbar
        setFilter={setFilter}
        setGlobalFilter={setGlobalFilter}
        showTabs={nationality === ENationalities.BR && showTabs}
        onChangeTab={onChangeTab}
      />
      {page && page.length === 0 && (
        <EmptyState
          color="primary"
          theme="light-circle"
          title={t('tokenizations.table.resultNotFound.title')}
          text={t('tokenizations.table.resultNotFound.subtitle')}
          ilustration="cloud"
          className="py-6"
        />
      )}
      {page && page.length > 0 && (
        <Table {...getTableProps()} className="w-full">
          <Thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <Tr
                key={`A${index}`}
                {...headerGroup.getHeaderGroupProps()}
                className="bg-gray-50"
              >
                {headerGroup.headers.map((column: any, i: number) => (
                  <Th
                    key={`B${i}`}
                    {...column.getHeaderProps()}
                    className="text-gray-500 text-xs text-left px-6 py-5 font-normal"
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row: any, index: number) => {
              prepareRow(row);
              return (
                <Tr
                  {...attributes}
                  key={`C${index}`}
                  {...row.getRowProps()}
                  _hover={{
                    background: 'gray.100',
                    color: 'teal.500',
                  }}
                  onClick={() => {
                    if (onRowClick) onRowClick(row);
                  }}
                >
                  {row.cells.map((cell: any, i: number) => {
                    return (
                      <Td
                        key={`D${i}`}
                        {...cell.getCellProps()}
                        className="px-6 py-4"
                      >
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
      {data.length > 0 && pageCount > 1 && (
        <div className="flex justify-between items-center px-5 py-4 border-t border-gray-200">
          <Button
            onClick={() => previousPage()}
            variant="secondary"
            disabled={!canPreviousPage}
            leftIcon={<FiArrowLeft />}
          >
            {t('btnPrevLabel')}
          </Button>
          <span className="mx-3">{`Página ${pageIndex + 1} de ${
            pageOptions.length
          }`}</span>
          <Button
            onClick={() => nextPage()}
            variant="secondary"
            disabled={!canNextPage}
            rightIcon={<FiArrowRight />}
          >
            {t('btnNextLabel')}
          </Button>
        </div>
      )}
    </>
  );
}

export default MovementsTable;
