import {
  PaymentRequestType,
  PaymentRequestWarrantyStatus,
  WarrantyPaymentRequestStatusTranslated,
} from '@common/enum';
import {
  EPaymentRequestStatusTranslated,
  PaymentRequestStatus,
  PAYMENT_REQUEST_STATUSES,
} from '@common/types';

export const getPaymentRequestStatusTranslationKey = ({
  paymentRequestStatus,
  paymentRequestType,
}: {
  paymentRequestStatus: PaymentRequestStatus;
  paymentRequestType: PaymentRequestType;
}) => {
  switch (paymentRequestType) {
    case PaymentRequestType.FUTURE_WARRANTY:
      if (paymentRequestStatus === PAYMENT_REQUEST_STATUSES.PENDING) {
        return WarrantyPaymentRequestStatusTranslated.CREATED;
      }
      return EPaymentRequestStatusTranslated[paymentRequestStatus];

    default:
      return EPaymentRequestStatusTranslated[paymentRequestStatus];
  }
};
