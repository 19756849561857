import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getHistoricMovements } from '@services/Movements';
import { useUser } from '@src/context/userContext';

export const useHandleDownloadMovementsHistoric = () => {
  const { t } = useTranslation();
  const { selectedBusinessId } = useUser();
  const [isLoadingHistoricMovements, setIsLoadingHistoricMovements] =
    useState(false);

  const handleDownloadMovementsHistoric = () => {
    if (!selectedBusinessId) return;
    const downloadBalance = async () => {
      try {
        setIsLoadingHistoricMovements(true);
        const data = await getHistoricMovements(selectedBusinessId);
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const tempLink = document.createElement('a');
        const filename = t('wallet.movementsTable.downloadBalanceFileName', {
          defaultValue: 'balance',
        });
        tempLink.href = url;
        tempLink.setAttribute('download', `${filename}.xlsx`);
        tempLink.click();
        tempLink.remove();
        setIsLoadingHistoricMovements(false);
      } catch {
        setIsLoadingHistoricMovements(false);
      }
    };
    downloadBalance();
  };

  return { isLoadingHistoricMovements, handleDownloadMovementsHistoric };
};

export default useHandleDownloadMovementsHistoric;
