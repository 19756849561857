import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface TermsAndCondsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TermsAndCondsModal: FC<TermsAndCondsModalProps> = (props) => {
  const { isOpen, onClose } = props;

  const { t } = useTranslation();
  const text: string[] = t('transferTokens.terms.text', {
    returnObjects: true,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent p="6">
        <ModalHeader>{t('transferTokens.terms.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {text.map((el: string, index: number) => (
            <Box key={index}>
              <Text>{el}</Text>
              <br />
            </Box>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
