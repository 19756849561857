import { GetTransferDetailResponse } from '@transfers/interfaces';

import { httpPlatform } from '../../http-common';

const getTransfer = async (
  uuid: string
): Promise<GetTransferDetailResponse | undefined> => {
  const { data } = await httpPlatform.get<GetTransferDetailResponse>(
    `/transfers/${uuid}`
  );

  return data;
};

export default getTransfer;
