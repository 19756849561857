import { ENationalities, REGEX } from '@agrotoken/common-utils';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import get from 'lodash.get';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AddressFormRestrictions } from '@common/const';
import useDebounce from '@hooks/useDebounce';
import { getGeographicalDivisions } from '@services/GeographicalDivision';
import { IViacepResponse, getViacepData } from '@services/Integrations';
import { getCities } from '@services/Shared';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import { isValidCep } from '@utils/isValidCep';

interface Props {
  defaultFiscalAddress?: string;
  defaultStreetNumber?: string;
  defaultNeighborhood?: string;
  defaultComplement?: string;
  defaultPostalCode?: string;
  defaultCityId?: number;
  defaultGeographicalDivisionId?: number;
  entityKey?: 'user' | 'business';
  disabled?: boolean;
}

const AddressFields: FC<Props> = ({
  defaultFiscalAddress = '',
  defaultStreetNumber = '',
  defaultNeighborhood = '',
  defaultComplement = '',
  defaultPostalCode = '',
  defaultCityId = '',
  defaultGeographicalDivisionId = '',
  entityKey = '',
  disabled = false,
}) => {
  const {
    register,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useFormContext();
  let { selectedBusinessUserWasInvited } = useUser();
  const { selectedBusinessUserWasAdminInvited } = useUser();
  const { nationality } = useAuth();
  selectedBusinessUserWasInvited =
    selectedBusinessUserWasInvited && !selectedBusinessUserWasAdminInvited;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.lvl_2.form',
  });

  const {
    ADDRESS_MAX_LENGTH,
    COMPLEMENT_MAX_LENGTH,
    NEIGHBORHOOD_MAX_LENGTH,
    STREET_NUMBER_MAX_LENGTH,
    POSTAL_CODE_MAX_LENGTH,
  } = AddressFormRestrictions;

  const addressRegistration = register(
    entityKey ? `${entityKey}PhysicalAddress` : 'physicalAddress',
    {
      required: !selectedBusinessUserWasInvited && !disabled,
      maxLength: {
        value: ADDRESS_MAX_LENGTH,
        message: t('errors.maxLength', {
          max: ADDRESS_MAX_LENGTH,
        }),
      },
      pattern: {
        value: new RegExp(REGEX.ADDRESS.STREET_NAME),
        message: t('errors.address.streetName'),
      },
    }
  );
  const streetNumberRegistration = register(
    entityKey ? `${entityKey}StreetNumber` : 'streetNumber',
    {
      required: !selectedBusinessUserWasInvited && !disabled,
      maxLength: {
        value: STREET_NUMBER_MAX_LENGTH,
        message: t('errors.maxLength', {
          max: STREET_NUMBER_MAX_LENGTH,
        }),
      },
      pattern: {
        value: new RegExp(REGEX.ADDRESS.DEFAULT),
        message: t('errors.address.defaultFormat'),
      },
    }
  );
  const neighborhoodRegistration = register(
    entityKey ? `${entityKey}Neighborhood` : 'neighborhood',
    {
      required: !selectedBusinessUserWasInvited && !disabled,
      maxLength: {
        value: NEIGHBORHOOD_MAX_LENGTH,
        message: t('errors.maxLength', {
          max: NEIGHBORHOOD_MAX_LENGTH,
        }),
      },
      pattern: {
        value: new RegExp(REGEX.ADDRESS.DEFAULT),
        message: t('errors.address.defaultFormat'),
      },
    }
  );
  const complementRegistration = register(
    entityKey ? `${entityKey}Complement` : 'complement',
    {
      maxLength: {
        value: COMPLEMENT_MAX_LENGTH,
        message: t('errors.maxLength', {
          max: COMPLEMENT_MAX_LENGTH,
        }),
      },
      pattern: {
        value: new RegExp(REGEX.ADDRESS.DEFAULT),
        message: t('errors.address.defaultFormat'),
      },
    }
  );
  const postalCodeRegistration = register(
    entityKey ? `${entityKey}PostalCode` : 'postalCode',
    {
      required: !selectedBusinessUserWasInvited && !disabled,
      maxLength: {
        value: POSTAL_CODE_MAX_LENGTH,
        message: t('errors.maxLength', {
          max: POSTAL_CODE_MAX_LENGTH,
        }),
      },
      pattern: {
        value:
          nationality === ENationalities.BR
            ? new RegExp(REGEX.ADDRESS.CEP)
            : new RegExp(REGEX.ADDRESS.DEFAULT),
        message:
          nationality === ENationalities.BR
            ? t('errors.address.postalCodeCEP')
            : t('errors.address.defaultFormat'),
      },
    }
  );
  const geographicalDivisionRegistration = register(
    entityKey ? `${entityKey}GeographicalDivisionId` : 'geographicalDivisionId',
    {
      required: !selectedBusinessUserWasInvited && !disabled,
      valueAsNumber: true,
    }
  );
  const cityRegistration = register(
    entityKey ? `${entityKey}CityId` : 'cityId',
    {
      required: !selectedBusinessUserWasInvited && !disabled,
      valueAsNumber: true,
    }
  );

  const { data: geographicalDivisions, isLoading: isLoadingGeoDivisions } =
    useQuery(
      [`geographical-divisions-${entityKey}`],
      async () => {
        return getGeographicalDivisions();
      },
      {
        onSuccess: () => {
          if (defaultGeographicalDivisionId)
            setValue(
              geographicalDivisionRegistration.name,
              defaultGeographicalDivisionId
            );
        },
      }
    );

  const geographicalDivisionId =
    watch(geographicalDivisionRegistration.name) ??
    defaultGeographicalDivisionId;

  const cityId = watch(cityRegistration.name) ?? defaultCityId;

  const { data: cities, isLoading: isLoadingCities } = useQuery(
    [`get-cities-${entityKey}`, geographicalDivisionId],
    () => {
      return getCities(Number(geographicalDivisionId));
    },
    {
      enabled: !!geographicalDivisionId,
    }
  );

  const debouncedPostalCode = useDebounce(
    watch(postalCodeRegistration.name),
    800
  );

  const [viacepMessage, setViacepMessage] = useState('');

  const fillAddressFieldsFromViacep = async (
    viacepData: IViacepResponse | undefined
  ) => {
    if (!viacepData) return;
    setViacepMessage('');
    const { address, neighborhood, cityId, geographicalDivisionId } =
      viacepData;
    setValue(addressRegistration.name, address, { shouldValidate: true });
    setValue(neighborhoodRegistration.name, neighborhood, {
      shouldValidate: true,
    });
    setValue(geographicalDivisionRegistration.name, geographicalDivisionId, {
      shouldValidate: true,
    });
    setValue(cityRegistration.name, cityId, { shouldValidate: true });
  };

  const { data: cepData, isLoading: isLoadingCep } = useQuery(
    [`search-cep-${entityKey}`, debouncedPostalCode],
    async () => {
      if (!isValidCep(debouncedPostalCode)) {
        return setError(postalCodeRegistration.name, {
          message: t('viacep.errors.invalid'),
        });
      }
      clearErrors(postalCodeRegistration.name);
      return getViacepData(debouncedPostalCode);
    },
    {
      enabled: !!debouncedPostalCode && !defaultPostalCode,
      onSuccess: fillAddressFieldsFromViacep,
      onError: (err: any) => setViacepMessage(err.message),
      retry: false,
    }
  );

  const addressError = get(errors, addressRegistration.name, false);
  const streetNumberError = get(errors, streetNumberRegistration.name, false);
  const neighborhoodError = get(errors, neighborhoodRegistration.name, false);
  const complementError = get(errors, complementRegistration.name, false);
  const postalCodeError = get(errors, postalCodeRegistration.name);
  const geographicalDivisionError = get(
    errors,
    geographicalDivisionRegistration.name
  );
  const cityError = get(errors, cityRegistration.name);

  const requiredFieldError = t('errors.required');

  useEffect(() => {
    if (!cityId) return;

    setValue(cityRegistration.name, cityId, { shouldValidate: true });
  }, [cities, cityId, cityRegistration.name, setValue]);

  return (
    <SimpleGrid columns={[1, 1, 2]} gap="6">
      <FormControl isInvalid={Boolean(postalCodeError)}>
        <FormLabel htmlFor={postalCodeRegistration.name}>
          {t('address.postalCodeLabel')}
        </FormLabel>
        <InputGroup>
          <Input
            id={postalCodeRegistration.name}
            h="12"
            {...postalCodeRegistration}
            defaultValue={defaultPostalCode}
            isDisabled={disabled}
          />
          {isLoadingCep && (
            <InputRightElement>
              <Spinner />
            </InputRightElement>
          )}
        </InputGroup>

        <FormErrorMessage>
          {(postalCodeError && postalCodeError.message) || requiredFieldError}
        </FormErrorMessage>

        {!postalCodeError && (
          <Text mt="2" color="gray.500">
            {viacepMessage}
          </Text>
        )}
      </FormControl>

      <FormControl display={['none', 'none', 'block']}></FormControl>

      <FormControl isInvalid={Boolean(geographicalDivisionError)}>
        <FormLabel htmlFor={geographicalDivisionRegistration.name}>
          {t('address.stateLabel')}
        </FormLabel>
        <Select
          id={geographicalDivisionRegistration.name}
          size="lg"
          {...(isLoadingGeoDivisions
            ? { icon: <Spinner color="primary.200" />, disabled: true }
            : {})}
          placeholder={t('address.statePlaceholder')}
          {...geographicalDivisionRegistration}
          value={geographicalDivisionId}
          isDisabled={disabled}
        >
          {geographicalDivisions?.map(({ id, name }) => (
            <option value={id} key={id}>
              {name}
            </option>
          ))}
        </Select>

        <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={Boolean(cityError)}>
        <FormLabel htmlFor={cityRegistration.name}>
          {t('address.cityLabel')}
        </FormLabel>
        <Select
          id={cityRegistration.name}
          size="lg"
          placeholder={t('address.cityPlaceholder')}
          {...(isLoadingCities
            ? { icon: <Spinner color="primary.200" />, disabled: true }
            : {})}
          {...cityRegistration}
          value={cityId}
          isDisabled={disabled}
        >
          {cities?.map(({ id, name }) => (
            <option value={id} key={id}>
              {name}
            </option>
          ))}
        </Select>

        <FormErrorMessage>
          {!isLoadingCities && requiredFieldError}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={Boolean(neighborhoodError)}>
        <FormLabel htmlFor={neighborhoodRegistration.name}>
          {t('address.neighborhoodLabel')}
        </FormLabel>
        <Input
          id={neighborhoodRegistration.name}
          h="12"
          defaultValue={defaultNeighborhood}
          {...neighborhoodRegistration}
          isDisabled={disabled}
        />
        <FormErrorMessage>
          {(neighborhoodError && neighborhoodError.message) ||
            requiredFieldError}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={Boolean(complementError)}>
        <FormLabel htmlFor={complementRegistration.name}>
          {t('address.complementLabel')}
        </FormLabel>
        <Input
          id={complementRegistration.name}
          h="12"
          defaultValue={defaultComplement}
          {...complementRegistration}
          isDisabled={disabled}
        />
        <FormErrorMessage>
          {complementError && complementError.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={Boolean(addressError)}>
        <FormLabel htmlFor={addressRegistration.name}>
          {t('address.streetLabel')}
        </FormLabel>
        <Input
          id={addressRegistration.name}
          h="12"
          defaultValue={defaultFiscalAddress}
          {...addressRegistration}
          isDisabled={disabled}
        />
        <FormErrorMessage>
          {(addressError && addressError.message) || requiredFieldError}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={Boolean(streetNumberError)}>
        <FormLabel htmlFor={streetNumberRegistration.name}>
          {t('address.streetNumberLabel')}
        </FormLabel>
        <Input
          id={streetNumberRegistration.name}
          h="12"
          defaultValue={defaultStreetNumber}
          {...streetNumberRegistration}
          isDisabled={disabled}
        />
        <FormErrorMessage>
          {(streetNumberError && streetNumberError.message) ||
            requiredFieldError}
        </FormErrorMessage>
      </FormControl>
    </SimpleGrid>
  );
};

export default AddressFields;
