import { Text, Image, Badge, Flex, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useStepForm, StepTitle } from '@comp/Global/StepForm';
import type { TransferTokensFormValues } from '@comp/TransferTokens';
import illustration from '../../../svgs/illustration.svg';

export const InitialStep = () => {
  const { handleNextStep } = useStepForm<TransferTokensFormValues>();
  const { t } = useTranslation();

  return (
    <>
      <StepTitle>{t('transferTokens.initialStep.title')}</StepTitle>
      <Flex flexDirection="column" alignItems="center">
        <Image src={illustration} marginTop="25px" sizes="sm" />
        <Badge
          borderRadius="16"
          fontFamily="Larsseit-Medium"
          color="orange.700"
          textAlign="center"
          fontSize="xs"
          backgroundColor="orange.50"
          boxShadow="none"
          marginBottom="0.5"
          paddingX="2"
          paddingY="0.5"
          textTransform="none"
        >
          {t('transferTokens.initialStep.badge')}
        </Badge>

        <Text marginTop="4" fontFamily="Larsseit-Medium" fontSize="md">
          {t('transferTokens.initialStep.subtitle')}
        </Text>

        <Text fontSize="md" fontFamily="Larsseit" align="center" marginTop="4">
          {t('transferTokens.initialStep.text')}
        </Text>

        <Button
          variant="primary"
          size="lg"
          marginTop="6"
          isFullWidth
          onClick={handleNextStep}
        >
          {t('transferTokens.initialStep.btnLabel')}
        </Button>
      </Flex>
    </>
  );
};
