import { Flex, Text } from '@chakra-ui/react';
import { TCurrencyName } from '@common/types';
import { TokenValue } from './TokenValue';

interface Props {
  value: string;
  token: TCurrencyName;
  localCurrencyValue: string;
}
export const TotalDebt = ({ token, value, localCurrencyValue }: Props) => (
  <Flex justifyContent="space-between">
    <Text>{`Deuda total acumulada (${localCurrencyValue})`}</Text>
    <TokenValue value={value} token={token} />
  </Flex>
);
