import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { HOME_PATH } from '../../common/const';
import { useAuth } from '../../context/authContext';
import LoginLayout from '../../layouts/LoginLayout';
import BottomLinks from './BottomLinks';
import Form from './Form';
import Header from './Header';

const ResetPassword: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  // TODO: Maybe move this into a hook or a redirect on the route?
  useEffect(() => {
    if (!auth) return;
    auth
      .isAuth()
      .then(() => navigate(HOME_PATH))
      .catch((error: Error) => {
        console.error(error);
      });
  }, [auth, navigate]);

  return (
    <LoginLayout>
      <Header />
      <Form />
      <BottomLinks />
    </LoginLayout>
  );
};

export default ResetPassword;
