import { Flex } from '@chakra-ui/react';
import TimeLine from '@comp/TimeLine/TimeLine';
import { useUser } from '@src/context/userContext';
import { VFC } from 'react';
import { getTimelineItems } from './utils';
interface Props {
  currentStep: number;
  isCompany?: boolean;
}
const FullOnboardingProgress: VFC<Props> = ({ currentStep, isCompany }) => {
  const {
    selectedBusinessUserWasInvited: isInvited,
    selectedBusinessUserWasAdminInvited: isAdminInvited,
  } = useUser();
  const items = getTimelineItems({
    currentStep,
    isCompany,
    isInvited,
    isAdminInvited,
  });
  return (
    <Flex
      bg={{ base: undefined, md: 'gray.50' }}
      p="4"
      w={{ base: undefined, md: '96' }}
      borderRadius="base"
      h="fit-content"
    >
      <TimeLine title="Mi Cuenta" steps={items} />
    </Flex>
  );
};

export default FullOnboardingProgress;
