import { WarrantyCard } from '@agrotoken/frontend-utils';
import { useTranslation } from 'react-i18next';

import { Origin } from '@common/types';
import FormSectionCard from '@comp/FormSectionCard';

import { useCreateWarrantyTransferData } from '../hooks';

const WarrantyFormDataSection = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix:
      'transfers.createWarrantyTransfer.form.selectedWarrantyToTransfer',
  });

  const { warranty, isLoadingWarranty } = useCreateWarrantyTransferData();

  return (
    <FormSectionCard title={t('title')}>
      <WarrantyCard
        key={warranty?.id}
        currencyName={warranty?.currency.name as 'SOYB' | 'CORB'}
        requestNumber={warranty?.requestNumber ?? 0}
        amountInSacas={warranty?.conversions.amount['SACAS']}
        initialAmountInSacas={warranty?.conversions.initialAmount['SACAS']}
        farmCity={warranty?.farm?.city?.name ?? ''}
        farmGeographicalDivision={
          warranty?.farm?.city?.geographicalDivision?.name ?? ''
        }
        expirationDate={warranty?.expirationDate ?? 0}
        healthControl={Number(warranty?.healthControl)}
        status={warranty?.status}
        origin={warranty?.origin as Origin}
        equivalencesAmountBrl={warranty?.equivalences.amount['BRL']}
        isLoading={isLoadingWarranty}
      />
    </FormSectionCard>
  );
};
export default WarrantyFormDataSection;
