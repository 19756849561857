import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import { ECognitoErrors } from '../../common/const';
import { useAuth } from '../../context/authContext';
import { Toast } from '../Global/Toast';

interface FormData {
  email: string;
}

const Form: React.VFC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);

  const { userPool } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { email: passedEmail } = (location?.state as { email?: string }) ?? {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      email: passedEmail ?? '',
    },
  });

  const navigateToVerification = (email?: string) => {
    navigate('verification', {
      state: { email: email },
    });
  };

  // Handle password reset through Cognito SDK
  const onSubmit = (data: FormData): void => {
    setLoading(true);
    const { email } = data;

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: function (_data) {
        navigateToVerification(email);
      },
      onFailure: function (_err) {
        const errorCode = (_err as any as CognitoError).code;

        if (errorCode === ECognitoErrors.USER_NOT_FOUND) {
          navigateToVerification(email);
          return;
        }

        setShowToast(true);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mr="6" isInvalid={Boolean(errors.email)}>
          <FormLabel htmlFor="email">
            {t('resetPassword.email.label')}
          </FormLabel>
          <Input
            placeholder={t('resetPassword.email.placeholder')}
            id="email"
            type="email"
            borderRadius={3}
            h="12"
            w="full"
            disabled={Boolean(passedEmail)}
            {...register('email', {
              required: t('resetPassword.email.errors.required'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('resetPassword.email.errors.email'),
              },
            })}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          variant="primary"
          size="lg"
          w="full"
          mt="6"
          type="submit"
          isLoading={loading}
          disabled={loading}
        >
          {t('resetPassword.submitButton')}
        </Button>
      </form>

      <Toast
        className="fixed top-6 right-6 z-50"
        show={showToast}
        onClickClose={() => setShowToast(false)}
        title={t('toasts.serverError.title')}
        text={t('toasts.serverError.text')}
        featuredIcon={FiAlertCircle}
        variant="destructive"
      />
    </>
  );
};

export default Form;
