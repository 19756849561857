import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import axios from 'axios';
import { ERROR_MESSAGE } from '../common/const';
import { ICreateDocumentDto, IDocument } from '../common/types';
import { http } from '../http-common';

axios.defaults.withCredentials = true;

axios.defaults.headers.post['Content-type'] =
  'multipart/form-data; boundary=<calculated when request is sent>';

export const createDocument = (
  body: ICreateDocumentDto
): Promise<IDocument | undefined> => {
  return http
    .post<IDocument | undefined>(
      `/${PLATFORM_PREFIX}/documents/${body.documentTypeId}/upload`,
      body.formData
    )
    .then((resp) => {
      const dataResult: IDocument | undefined = resp.data;
      return dataResult;
    })
    .catch((err) => {
      if (err.response.data.message) throw new Error(err.response.data.message);
      throw new Error(ERROR_MESSAGE);
    });
};

export const uploadImage = (
  body: ICreateDocumentDto
): Promise<IDocument | undefined> => {
  return http
    .post<IDocument | undefined>(
      `/${PLATFORM_PREFIX}/documents/${body.documentTypeId}/upload/image`,
      body.formData
    )
    .then((resp) => {
      const dataResult: IDocument | undefined = resp.data;
      return dataResult;
    })
    .catch((err) => {
      if (err.response.data.message) throw new Error(err.response.data.message);
      throw new Error(ERROR_MESSAGE);
    });
};

export const deleteDocument = (
  uuid: string
): Promise<IDocument | undefined> => {
  return http
    .delete<IDocument | undefined>(`/${PLATFORM_PREFIX}/documents/${uuid}`)
    .then((resp) => {
      const dataResult: IDocument | undefined = resp.data;
      return dataResult;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const getDocumentByUuid = ({ queryKey }: any): Promise<IDocument> => {
  const [, uuid] = queryKey;
  return http
    .get<IDocument>(`/${PLATFORM_PREFIX}/documents/${uuid}`)
    .then((resp) => {
      const dataResult: IDocument = resp.data;
      return dataResult;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const downloadDocument = (uuid: string): Promise<string | undefined> => {
  return http
    .get<string | undefined>(`/${PLATFORM_PREFIX}/documents/download/${uuid}`)
    .then((resp) => {
      const dataResult: string | undefined = resp.data;
      return dataResult;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};
