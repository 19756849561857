import { FC, HTMLAttributes } from 'react';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle?: string;
}

export const TitleSubtitle: FC<Props> = ({ title, subtitle }) => {
  return (
    <div>
      <p className="text-sm text-gray-900 font-normal">{title}</p>
      <p className="text-sm text-gray-500">{subtitle}</p>
    </div>
  );
};
