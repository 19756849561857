import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Spinner,
} from '@chakra-ui/react';
import get from 'lodash.get';
import { VFC } from 'react';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { IGeographicalDivision } from '@def/geographicalDivision';
import { CityType } from '@def/shared';

interface Props {
  businessAddressId?: number;
  addressRegistration: UseFormRegisterReturn;
  postalCodeRegistration: UseFormRegisterReturn;
  geographicalDivisionRegistration: UseFormRegisterReturn;
  cityRegistration: UseFormRegisterReturn;
  defaultFiscalAddress?: string;
  defaultPostalCode?: string;
  cities: CityType[] | undefined;
  geographicalDivisions: IGeographicalDivision[] | undefined;
  isLoadingGeoDivisions: boolean;
  isLoadingCities: boolean;
}

const AddressFields: VFC<Props> = ({
  addressRegistration,
  postalCodeRegistration,
  geographicalDivisionRegistration,
  cityRegistration,
  cities,
  geographicalDivisions,
  defaultFiscalAddress,
  defaultPostalCode,
  isLoadingGeoDivisions,
  isLoadingCities,
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.lvl_2.form',
  });

  const addressError = get(errors, addressRegistration.name, false);
  const postalCodeError = get(errors, postalCodeRegistration.name, false);
  const geographicalDivisionError = get(
    errors,
    geographicalDivisionRegistration.name,
    false
  );
  const cityError = get(errors, cityRegistration.name, false);

  const requiredFieldError = t('errors.required');

  return (
    <>
      <SimpleGrid columns={[1, 1, 2]} gap="4">
        <FormControl isInvalid={Boolean(addressError)}>
          <FormLabel htmlFor={addressRegistration.name}>
            {t('address.addressLabel')}
          </FormLabel>
          <Input
            id={addressRegistration.name}
            h="12"
            defaultValue={defaultFiscalAddress}
            {...addressRegistration}
          />
          <FormErrorMessage>
            {(addressError && addressError.message) || requiredFieldError}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(postalCodeError)}>
          <FormLabel htmlFor={postalCodeRegistration.name}>
            {t('address.postalCodeLabel')}
          </FormLabel>
          <Input
            id={postalCodeRegistration.name}
            h="12"
            defaultValue={defaultPostalCode}
            {...postalCodeRegistration}
          />
          <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(geographicalDivisionError)}>
          <FormLabel htmlFor={geographicalDivisionRegistration.name}>
            {t('address.stateLabel')}
          </FormLabel>
          <Select
            id={geographicalDivisionRegistration.name}
            size="lg"
            {...(isLoadingGeoDivisions
              ? { icon: <Spinner color="primary.200" />, disabled: true }
              : {})}
            placeholder={t('address.statePlaceholder')}
            {...geographicalDivisionRegistration}
          >
            {geographicalDivisions?.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(cityError)}>
          <FormLabel htmlFor={cityRegistration.name}>
            {t('address.cityLabel')}
          </FormLabel>
          <Select
            id={cityRegistration.name}
            size="lg"
            placeholder={t('address.cityPlaceholder')}
            {...(isLoadingCities
              ? { icon: <Spinner color="primary.200" />, disabled: true }
              : {})}
            {...cityRegistration}
          >
            {cities?.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </Select>

          <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};

export default AddressFields;
