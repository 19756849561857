import { FC, useRef } from 'react';

interface ModalBackgroundProps {
  onPressOut?: () => void;
}

export const ModalBackground: FC<ModalBackgroundProps> = (props) => {
  const { children, onPressOut } = props;
  const overlayRef = useRef<HTMLDivElement>(null);

  const handlePressOut = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!onPressOut) {
      return;
    }

    if (event.target === overlayRef.current) {
      onPressOut();
    }
  };

  return (
    <div className="relative">
      <div
        className="fixed inset-0 bg-[#34405499] overflow-auto z-40 h-screen flex items-end sm:items-center justify-center"
        ref={overlayRef}
        onClick={handlePressOut}
      >
        {children}
      </div>
    </div>
  );
};
