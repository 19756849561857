import { useEffect, useRef, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

interface ISerialization<T> {
  serialize: (value: T | null) => string;
  deserialize: (value: string) => T | null;
}

const useLocalStorageState = <T>(
  key: string,
  defaultValue: T | null = null,
  options: ISerialization<T> = {
    serialize: JSON.stringify,
    deserialize: JSON.parse,
  }
): [T | null, Dispatch<SetStateAction<T | null>>] => {
  const { serialize, deserialize } = options;

  /**
   * The callback in useState is only called for state initialization
   *  */
  const [state, setState] = useState<T | null>((): T | null => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage && valueInLocalStorage !== 'undefined') {
      return deserialize(valueInLocalStorage);
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  });

  /**
   * Keep a reference to the old key in localStorage,
   * to delete it if the key changes
   */
  const prevKeyRef = useRef(key);

  useEffect(() => {
    // Check if key has changed, and remove previous key if necessary
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
      prevKeyRef.current = key;
    }

    // Set item in localStorage
    window.localStorage.setItem(key, serialize(state));
  }, [key, serialize, state]);

  return [state, setState];
};

export default useLocalStorageState;
