import React from 'react';
import { useTranslation } from 'react-i18next';
import { Mailto } from '@comp/Global/Mailto';
import { Modal } from '@comp/Global/Modal';

const BlockedSacasModal = ({
  setIsOpenInfoModal,
  isOpenInfoModal,
}: {
  setIsOpenInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenInfoModal: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      noSpaceAdded
      isOpen={isOpenInfoModal}
      variant="primary"
      iconColor="gray"
      title={t('tokenization.summary.breakDown.breakDownModalTitle')}
      text={[
        t('tokenization.summary.breakDown.breakDownModalText1'),
        t('tokenization.summary.breakDown.breakDownModalText2'),
      ].join(' ')}
      cancelButtonHidden
      labelBtnConfirm={t('tokenization.summary.collateralDetails.labelConfirm')}
      onClickConfirm={() => setIsOpenInfoModal(false)}
      confirmButtonFill={true}
    >
      <Mailto
        className="text-md font-normal text-primary-700 underline underline-offset-4"
        email={t('tokenization.summary.collateralDetails.mail')}
        subject={''}
      >
        {t('tokenization.summary.breakDown.breakDownContactUs')}
      </Mailto>
    </Modal>
  );
};

export default BlockedSacasModal;
