import { Box, Grid, Spinner, Text } from '@chakra-ui/react';
import { formatDate } from '@common/utils/format-date';
import { FC, ReactNode } from 'react';
import { EmptyState } from './EmptyState';

export interface SummaryElement {
  left: ReactNode;
  right: ReactNode;
}

interface GenericSummaryProps {
  status: 'READY' | 'LOADING' | 'EMPTY';
  title: ReactNode; // Ex: Summary
  totalText: ReactNode; // Ex: Total
  totalAmount: ReactNode;
  topElements: SummaryElement[];
  bottomElements: SummaryElement[];
  createdDate: number | undefined;
}

interface SummaryElementProps {
  element: SummaryElement;
}

export const GenericSummary: FC<GenericSummaryProps> = ({
  status,
  title,
  totalText,
  totalAmount,
  topElements,
  bottomElements,
  createdDate,
}) => {
  const SummaryElement: FC<SummaryElementProps> = (
    props: SummaryElementProps
  ) => {
    const { element } = props;
    return (
      <div className="mb-2 flex justify-between text-sm font-normal">
        {element.left}
        {element.right}
      </div>
    );
  };
  if (status === 'EMPTY') {
    return <EmptyState />;
  }

  if (status === 'LOADING') {
    return (
      <div className="p-10 min-h-[225px] flex items-center justify-center text-center border border-gray-200 bg-gray-50 rounded">
        <Spinner
          size="md"
          thickness="2px"
          speed="0.75s"
          emptyColor="primary.50"
          color="primary.600"
        />
      </div>
    );
  }
  const format = 'DD/MM/YY - HH:mm A';
  const date = createdDate ? formatDate(createdDate, format) : '';

  return (
    <Box className={'h-fit p-6 border border-gray-200 bg-gray-50 rounded mb-4'}>
      {/* Top Title */}
      <Box className="font-normal mb-4 flex justify-between ">
        <Text variant="medium">{title}</Text>
      </Box>

      {/* Top Elements */}
      {topElements.map((element, index) => {
        return <SummaryElement key={index} element={element} />;
      })}

      <hr className=" border-gray-200 my-4" />

      {/* Total Title */}
      <Grid className="w-100" templateColumns="1fr 1fr">
        <Box className="flex">{totalText}</Box>
        <Box className="flex mb-2 justify-end text-lg font-medium text-gray-900">
          {totalAmount}
        </Box>
      </Grid>

      {/* Bottom Elements */}
      {bottomElements.map((element, index) => {
        return <SummaryElement key={index} element={element} />;
      })}
    </Box>
  );
};
