import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { IAccountType, IBankAccount } from '../common/types';
import { http } from '../http-common';

export const getBankAccountTypes = async ({
  queryKey,
}: any): Promise<IAccountType[]> => {
  try {
    const [, id] = queryKey;
    return http
      .get<IAccountType[]>(`/${PLATFORM_PREFIX}/account-types`)
      .then((resp: { data: any }) => {
        const business: IAccountType[] = resp.data;
        return business;
      });
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const getOneBankAccountByBusiness = async ({
  queryKey,
}: any): Promise<IBankAccount> => {
  try {
    const [, businessId] = queryKey;
    return http
      .get<IBankAccount>(
        `/${PLATFORM_PREFIX}/business-bank-accounts/business/${businessId}`
      )
      .then((resp: { data: IBankAccount }) => {
        const bankAccount: IBankAccount = resp.data;
        return bankAccount;
      });
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const updateBusinessBankAccount = async (data: any): Promise<void> => {
  await http.patch<IBankAccount>(
    `/${PLATFORM_PREFIX}/business-bank-accounts/${data.id}`,
    data.body
  );
};
