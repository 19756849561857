import { getMultiRegionMap } from '@agrotoken/common-utils';
import { Box, Heading, Button, Text, Center, Flex } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { FC, Suspense, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ServerError } from '@comp/Global/ServerError';
import { useAuth } from '@src/context/authContext';
import { useUser } from '../../context/userContext';
import { Container } from '../Global/Container';
import { Loader } from '../Global/Loader';
import { Spinner } from '../Global/Spinner';
import CollateralizedTokens from './CollateralizedTokens';
import CollateralizedTokensSkeleton from './CollateralizedTokensSkeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetBalances,
  useGetMovementsForWallet,
  useHandleDownloadMovementsHistoric,
  useMovementsDataCSV,
} from './hooks';
import { MovementsTabs } from '@common/enum/movements.enum';
import { useNavigateSelectOrg } from '@hooks/useNavigateSelectOrg';

const MovementsColumns_Ar = React.lazy(
  () => import('../AR/Wallet/MovementsColumns')
);
const MovementsColumns_Br = React.lazy(
  () => import('../BR/Wallet/MovementsColumns')
);

const movementsColumns = getMultiRegionMap({
  AR: MovementsColumns_Ar,
  BR: MovementsColumns_Br,
});

export const Wallet: FC = () => {
  const {
    showBalancesDownloadButtonOnPlatformFront,
    collateralizedTokensSectionBalance,
  } = useFlags();
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const MovementColumns = movementsColumns[nationality];
  const navigate = useNavigate();
  const { selectedBusinessId, errorUser, isLoadingUser } = useUser();
  const { isLoadingHistoricMovements, handleDownloadMovementsHistoric } =
    useHandleDownloadMovementsHistoric();
  const { movementsDataCSV } = useMovementsDataCSV();
  const [movementTabSelected, setMovementTabSelected] = useState<MovementsTabs>(
    MovementsTabs.ALL
  );
  const {
    isLoading: isLoadingMovements,
    data: movements,
    isError: isErrorMovements,
  } = useGetMovementsForWallet();

  const {
    isLoading: isLoadingLoans,
    data: loans,
    isError: isErrorLoans,
  } = useGetBalances();

  const movementsHeadersCSV = [
    {
      label: t('wallet.movementsTable.columns.transactionType'),
      key: 'operationType',
    },
    { label: t('wallet.movementsTable.columns.amount'), key: 'amount' },
    { label: t('wallet.movementsTable.columns.value'), key: 'value' },
    {
      label: t('wallet.movementsTable.columns.business'),
      key: 'toBusiness',
    },
    { label: t('wallet.movementsTable.columns.date'), key: 'createdAt' },
    { label: t('wallet.movementsTable.columns.status'), key: 'status' },
  ];

  const filteredMovements = movements?.filter(
    (movement) => +movement.amount !== 0
  );

  useNavigateSelectOrg();

  if (isLoadingUser) return <Loader />;

  if (errorUser) {
    return (
      <ServerError title={t('serverError.title')} text={errorUser?.message} />
    );
  }

  return (
    <Container>
      <Box className="mb-6">
        <Heading mb="2" color="gray.900">
          {t('wallet.header.title')}
        </Heading>
        <Text className="text-sm text-gray-600 font-normal">{`${t(
          'wallet.header.subtitle'
        )}`}</Text>
      </Box>
      {collateralizedTokensSectionBalance && (
        <>
          {loans?.data.length ? (
            <CollateralizedTokens loans={loans.data} />
          ) : (
            <></>
          )}
          {isLoadingLoans && <CollateralizedTokensSkeleton />}
          {isErrorLoans && (
            <Text className="text-center py-10">
              {t('wallet.warrantyTokens.loadingError')}
            </Text>
          )}
        </>
      )}

      <Box className="border shadow-xs overflow-x-auto bg-white rounded">
        <Box className="py-5 px-6 bg-white border-b border-gray-200 flex gap-4 flex-col md:flex-row md:justify-between md:items-center">
          <Box>
            <Text className="text-lg text-gray-900 font-medium mb-1">
              {t('wallet.movementsTable.title')}
            </Text>
            <Text className="text-sm text-gray-500 font-normal">
              {t('wallet.movementsTable.subtitle')}
            </Text>
          </Box>
          {movements && movements.length > 0 && (
            <Flex gap={2}>
              <CSVLink
                data={movementsDataCSV ?? []}
                headers={movementsHeadersCSV}
                filename={`${t(
                  'wallet.movementsTable.title'
                ).toLowerCase()}_${moment().format('DD-MM-YYYY')}.csv`}
              ></CSVLink>
              {showBalancesDownloadButtonOnPlatformFront && (
                <Button
                  variant="secondary"
                  leftIcon={<FiDownload size={20} />}
                  onClick={handleDownloadMovementsHistoric}
                  className="hidden md:flex"
                  isLoading={isLoadingHistoricMovements}
                >
                  {t('wallet.movementsTable.downloadBalance')}
                </Button>
              )}
            </Flex>
          )}
        </Box>
        {isLoadingMovements && <Spinner className="m-auto py-10" />}
        {isErrorMovements && (
          <Text className="text-center py-10">
            {t('wallet.movementsTable.loadingError')}
          </Text>
        )}
        {filteredMovements && (
          <Suspense
            fallback={
              <Center paddingY={3}>
                <Loader />
              </Center>
            }
          >
            <MovementColumns
              setMovementTabSelected={setMovementTabSelected}
              filteredMovements={filteredMovements}
            />
          </Suspense>
        )}
      </Box>
    </Container>
  );
};
