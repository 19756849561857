import { LoansTranslationType } from '../es';

const LOANS_TRANSLATIONS_PT: LoansTranslationType = {
  blockAuthorization: {
    heading: 'Autorização de empréstimo para {{beneficiaryName}}',
    subheading:
      'Necessitamos de autorização para poder utilizar seus tokens como garantia em seu empréstimo {{lenderName}}',
    cancelled:
      'Este empréstimo foi cancelado, por favor entrar em contato com o banco.',
    amountToBorrow: {
      label: 'Valor do empéstimo:',
      value: '{{currency}} {{value, fiat}}',
      supportText:
        'Este valor é o capital que será desembolsado na sua conta bancária',
    },
    total: {
      label:
        'Total de tokens a serem bloqueados de sua carteira como garantia:',
      value: '{{value, crypto}} {{token}}',
    },
    buttons: {
      authorize: 'Autorizar',
    },
    error: {
      heading: 'Não foi possível bloquear os tokens em sua carteira',
      subheading:
        'Ocorreu um erro ao bloquear os tokens para autorizar o empréstimo. Entre em contato com ',
    },
    success: {
      heading: 'Os tokens em sua carteira foram bloqueados com sucesso.',
      subheading:
        'Você receberá um e-mail e uma notificação do banco quando o empréstimo for depositado',
    },
  },
  paymentAuthorization: {
    heading: 'Foi solicitado o pagamento do empréstimo com tokens',
    subheading:
      'Precisamos de sua autorização para quitar sua dívida com os tokens utilizados como garantia',
    tokenAmount: {
      label: 'Total de tokens a serem utilizados para cobrir o empréstimo',
      value: '{{tokenAmount, crypto}} {{token}}',
      supportText:
        'Este valor taxa e coeficiente de segurança acumulados até o momento, outras despesas e a comissão agrotoken',
    },
    button: 'Autorizar',
    success: {
      heading: 'Sua dívida foi liquidada com os tokens usados como garantia.',
      subheading:
        'Você receberá um e-mail e uma notificação do banco quando o empréstimo for liquidado.',
    },
    error: {
      heading:
        'Sua dívida não pôde ser liquidada com os tokens usado como garantia.',
      subheading:
        'Ocorreu um erro e não foi possível usar seus tokens para pagar sua dívida. Entre em contato com a Agrotoken através ',
    },
  },
};

export default LOANS_TRANSLATIONS_PT;
