import { Badge } from '@agrotoken-tech/ui';
import { Flex, Heading } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  subtitle?: string;
  requestNumber?: number | null;
  mt?: string;
  mb?: string;
  managerId?: number | null;
}

export const Title: FC<Props> = ({
  title,
  subtitle,
  requestNumber,
  mt,
  mb,
  managerId,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {requestNumber ? (
        <Flex gap="2" flexWrap="wrap" alignItems={'start'}>
          <Heading mt={mt} mb={mb}>
            {title} #{requestNumber}
          </Heading>
          {managerId && (
            <Badge
              bgColor={'gray.100'}
              textColor={'gray.700'}
              outline="none"
              boxShadow="none"
              textTransform={'initial'}
              px="2"
              py="0.5"
              marginTop="4"
            >
              {t('tokenization.createdByAgrotoken', {
                requestNumber: requestNumber,
              })}
            </Badge>
          )}
        </Flex>
      ) : (
        <Heading color="gray.900">{title}</Heading>
      )}
      {subtitle && (
        <h2 className="mt-1 text-md text-gray-700 font-normal">{subtitle}</h2>
      )}
    </>
  );
};
