import { Button, Flex, RadioGroup, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { EUnits } from '@common/types';
import { Loader } from '@comp/Global/Loader';
import { WarrantySelectorModalProps } from '@comp/PaymentRequestView/CreatePaymentRequest/types';
import { getValidFutureGrainContractsByFiscalId } from '@services/GrainContracts';
import PaymentRequestWarrantyCard from './PaymentRequestWarrantyCard';
import { EmptyState } from '@agrotoken-tech/ui';

const WarrantySelector: FC<WarrantySelectorModalProps> = (
  props: WarrantySelectorModalProps
) => {
  const {
    setSelectedWarranty,
    toBusinessFiscalId,
    selectedWarranty,
    setIsChecked,
  } = props;
  const { data: grainContractsFuture, isLoading: isLoadingGrainContracts } =
    useQuery(['selectedWarranty', toBusinessFiscalId], () => {
      setSelectedWarranty(undefined);
      return getValidFutureGrainContractsByFiscalId(toBusinessFiscalId);
    });
  const { t } = useTranslation();

  if (isLoadingGrainContracts) return <Loader size={'sm'} />;
  if (!grainContractsFuture || grainContractsFuture.length === 0)
    return (
      <>
        <EmptyState
          title={t('warrantySelector.emptyState.title')}
          description={t('warrantySelector.emptyState.description1')}
          size={'sm'}
          fontSize="sm"
          color={'gray.500'}
        >
          {t('warrantySelector.emptyState.description2')}
        </EmptyState>
      </>
    );

  return (
    <>
      <Text size="sm" variant="medium" color="gray.700" mb="3" mt="6">
        {t('warrantySelector.title')}
      </Text>
      <Flex flexDir="column" width={'100%'}>
        {isLoadingGrainContracts && <Loader />}
        <RadioGroup value={selectedWarranty?.id}>
          {!isLoadingGrainContracts &&
            grainContractsFuture?.map((grainContractFuture) => (
              <Button
                key={grainContractFuture.id}
                marginBottom={6}
                onClick={() => {
                  setSelectedWarranty(grainContractFuture);
                  setIsChecked(true);
                }}
                h="100%"
                w="100%"
                variant="unstyled"
                _focus={{ outline: 'none' }}
              >
                {grainContractFuture && (
                  <PaymentRequestWarrantyCard
                    key={grainContractFuture.requestNumber}
                    currencyName={
                      grainContractFuture.currency.name as 'SOYB' | 'CORB'
                    }
                    requestNumber={grainContractFuture.requestNumber!}
                    amountInSacas={
                      grainContractFuture.equivalences.amount[EUnits.SACAS]
                    }
                    farmCity={grainContractFuture.farm?.city?.name ?? ''}
                    farmGeographicalDivision={
                      grainContractFuture.farm?.city?.geographicalDivision
                        .name ?? ''
                    }
                    expirationDate={grainContractFuture.expirationDate}
                    healthControl={Number(grainContractFuture.healthControl)}
                    equivalencesAmountBrl={
                      grainContractFuture.equivalences.amount[EUnits.BRL]
                    }
                    selectedWarranty={selectedWarranty}
                    id={grainContractFuture.id}
                    toBusinessFiscalId={toBusinessFiscalId}
                  />
                )}
              </Button>
            ))}
        </RadioGroup>
      </Flex>
    </>
  );
};

export default WarrantySelector;
