import { HOME_PATH, SUPPORT_EMAIL } from '@common/const';
import Level2CompletedModal from '@comp/FullOnboarding/v2/lvl-2/Level2CompletedModal';
import { Modal } from '@comp/Global/Modal';
import { useAuth } from '@src/context/authContext';
import { useFullOnboardingAccess } from '@src/context/fullOnboardingAccessContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const RequireOnboardingLevelTwoAccess: FC = ({ children }) => {
  const {
    hasOnboardingLevel2Completed_v2,
    hasOnboardingLevel2PendingVerification_v2,
    hasNotSentOnboardingLevel3_v2,
    hasOnboardingLevel2ChangesRequested_v2,
  } = useFullOnboardingAccess();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const onClickNavigateHome = () => navigate(HOME_PATH);

  if (
    hasOnboardingLevel2PendingVerification_v2 &&
    hasNotSentOnboardingLevel3_v2 &&
    !hasOnboardingLevel2ChangesRequested_v2
  ) {
    return <Level2CompletedModal onClose={onClickNavigateHome} isOpen />;
  }

  if (
    hasOnboardingLevel2PendingVerification_v2 &&
    !hasOnboardingLevel2ChangesRequested_v2
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={onClickNavigateHome}
        cancelButtonHidden
      />
    );
  }

  if (hasOnboardingLevel2Completed_v2) {
    return (
      <Modal
        isOpen={true}
        title={t('onboardingLevelTwoAccessModal.titleNoPermissions')}
        labelBtnConfirm={t('onboardingLevelTwoAccessModal.confirmBtn')}
        iconName="alert-circle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={onClickNavigateHome}
        onClickClose={onClickNavigateHome}
      >
        <p>
          <span className="text-md font-normal text-gray-700">
            {t('onboardingLevelTwoAccessModal.textNoPermissions')}
          </span>{' '}
          <a
            className="text-md font-bold underline text-primary-700"
            href={`mailto:${SUPPORT_EMAIL[nationality]}`}
          >
            {SUPPORT_EMAIL[nationality]}
          </a>
        </p>
      </Modal>
    );
  }
  return <>{children}</>;
};

export default RequireOnboardingLevelTwoAccess;
