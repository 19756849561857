import {
  CURRENCIES,
  Currency,
  ENationalities,
  isoCurrencies,
  ISOCurrency,
  PLATFORM_PREFIX,
  tokenCurrencies,
  TokenCurrency,
  WEIGHT_UNITS,
} from '@agrotoken/common-utils';
import { ISerializedCurrency } from '@comp/AR/Tokenizations/interfaces/currencies';
import { GetAvailableCurrenciesToTokenizeResponse } from '@features/tokenizations';
import {
  ICurrency,
  PricingCurrency,
  TCurrencyName,
  ValueOf,
} from '../common/types';
import { http, httpPricing } from '../http-common';

export const getCryptoCurrencies = (): Promise<ICurrency[] | undefined> => {
  return http
    .get<ICurrency[]>(`/${PLATFORM_PREFIX}/currencies?is_crypto=1`)
    .then((resp) => {
      const dataResult: ICurrency[] | undefined = resp.data;
      return dataResult;
    });
};

export const getAllCurrenciesByRegion = (
  region: ENationalities
): Promise<PricingCurrency[]> => {
  const queryParams =
    region === ENationalities.BR
      ? 'isActive=true&isCrypto=true'
      : 'isActive=true';

  return httpPricing
    .get<PricingCurrency[]>(`/currencies/prices?${queryParams}`, {
      headers: {
        'x-current-region': region,
      },
    })
    .then((resp) => {
      const dataResult: PricingCurrency[] = resp.data;

      return dataResult;
    });
};

interface PricingConvertResponse {
  amount: string;
  currency: TCurrencyName;
}

export const pricingConvert = (params: {
  quantity: number;
  sourceCurrency: Currency;
  targetCurrency?: Currency;
  targetWeightUnits?: ValueOf<typeof WEIGHT_UNITS>;
}): Promise<PricingConvertResponse> | void => {
  const { targetCurrency, sourceCurrency, quantity, targetWeightUnits } =
    params;

  // Check if target currency is provided for token-to-token conversion
  if (!targetCurrency) return;

  // Define valid currency conversions
  const validCurrencies: Record<ISOCurrency, TokenCurrency[]> = {
    [CURRENCIES.ARS]: [CURRENCIES.SOYA, CURRENCIES.CORA, CURRENCIES.WHEA],
    [CURRENCIES.BRL]: [CURRENCIES.SOYB, CURRENCIES.CORB],
  };

  // Check if it's a valid token-to-ISO or ISO-to-token conversion
  const isTokenToISOConvertion =
    tokenCurrencies.includes(sourceCurrency) &&
    isoCurrencies.includes(targetCurrency);

  const isISOToTokenConvertion =
    isoCurrencies.includes(sourceCurrency) &&
    tokenCurrencies.includes(targetCurrency);

  if (!isTokenToISOConvertion && !isISOToTokenConvertion) {
    throw new Error('Invalid params');
  }

  // Check if the conversion is supported
  let isValidConvertion;

  if (isTokenToISOConvertion) {
    isValidConvertion = validCurrencies[targetCurrency as ISOCurrency].includes(
      sourceCurrency as TokenCurrency
    );
  }

  if (isISOToTokenConvertion) {
    isValidConvertion = validCurrencies[sourceCurrency as ISOCurrency].includes(
      targetCurrency as TokenCurrency
    );
  }

  if (!isValidConvertion) {
    throw new Error('Invalid conversion');
  }

  return httpPricing
    .post<PricingConvertResponse>('convert', {
      amount: quantity.toString(),
      sourceCurrency,
      targetCurrency,
      targetWeightUnits,
    })
    .then((resp) => {
      const conversion = resp?.data;
      return conversion;
    });
};

export const getActiveCurrencies = (): Promise<ISerializedCurrency[]> => {
  return http
    .get<ISerializedCurrency[]>(`/${PLATFORM_PREFIX}/currencies/active`)
    .then((resp) => {
      const dataResult: ISerializedCurrency[] = resp.data;
      return dataResult;
    });
};

export const getAvailableCurrenciesToTokenize = async (): Promise<
  GetAvailableCurrenciesToTokenizeResponse[]
> => {
  const { data } = await http.get<GetAvailableCurrenciesToTokenizeResponse[]>(
    `/${PLATFORM_PREFIX}/currencies/available-to-tokenize`
  );

  return data;
};
