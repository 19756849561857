import { Box, Button, Icon, Tooltip } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@comp/Global/Badge';
import { Checkbox } from '@comp/Global/Checkbox';
import { PaymentForwardingFormData } from '@comp/PaymentRequestView/CreatePaymentRequest/types';

import ForwardPaymentForm from './ForwardPaymentForm';
import { useForwardPayment } from './hooks';

interface ForwardPaymentSectionProps {
  forwardings: PaymentForwardingFormData[];
  setForwardings: React.Dispatch<
    React.SetStateAction<PaymentForwardingFormData[]>
  >;
  payerFiscalId: string;
  amountToReceive: number;
}

const ForwardPayment = ({
  forwardings,
  setForwardings,
  payerFiscalId,
  amountToReceive,
}: ForwardPaymentSectionProps) => {
  const { t } = useTranslation();

  const {
    isForwarding,
    onChangeIsForwardingCheckboxHandler,
    onClickAddNewForwardingHandler,
    isAddForwardingDisabled,
  } = useForwardPayment({ forwardings, setForwardings });

  return (
    <>
      <Badge
        color="info"
        size="lg"
        label={t('createPayment.form.forwardings.badge.label')}
        icon={
          <Tooltip
            label={t('createPayment.form.forwardings.badge.tooltip', {
              fiscalPrefix: 'CUIT', // TODO: Remove hardcoded 'CUIT' prefix when forwardings are available for Brazil
            })}
            hasArrow
            placement="top"
            gutter={16}
            shouldWrapChildren
            bg="white"
            textColor="gray.500"
          >
            <Icon bgSize={4} color="blue.700" padding="0">
              {<FeatherIcon icon="help-circle" size="24" />}
            </Icon>
          </Tooltip>
        }
        iconPosition="trailing"
        iconSize={12}
      />
      <Checkbox
        name="is-forwarding"
        id="is-forwarding"
        label={t('createPayment.form.forwardings.checkbox')}
        className="mt-4"
        checked={isForwarding}
        onChange={onChangeIsForwardingCheckboxHandler}
      />
      <Box pl="8">
        {isForwarding && (
          <>
            {forwardings.map((forwarding, index) => (
              <ForwardPaymentForm
                key={forwarding.uuid}
                {...{
                  forwardings,
                  index,
                  setForwardings,
                  payerFiscalId,
                  amountToReceive,
                }}
              />
            ))}
            <Button
              variant="linkPrimary"
              mt="8"
              leftIcon={<FeatherIcon icon="plus" size={16} />}
              onClick={onClickAddNewForwardingHandler}
              disabled={isAddForwardingDisabled}
            >
              {t('createPayment.form.forwardings.addUser')}
            </Button>
          </>
        )}
      </Box>
    </>
  );
};

export default ForwardPayment;
