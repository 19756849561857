import { getOneBankAccountByBusiness } from '@services/BankAccounts';
import { getBusinessOnboardingLevel3Data } from '@services/Businesses';
import {
  EDocumentTypes,
  IBankAccount,
  IBusiness,
  IDocumentPreview,
} from '@src/common/types';
import { useUser } from '@src/context/userContext';
import React, { useEffect, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { IFinalBeneficiary } from '../types';
import { UIF_RADIO } from './consts';

interface Props {
  setDefaultUIFDocument: React.Dispatch<
    React.SetStateAction<IDocumentPreview | undefined>
  >;
}
const LoadDefaultsEffect: VFC<Props> = ({ setDefaultUIFDocument }) => {
  const { reset } = useFormContext();
  const { setSelectedBusiness, selectedBusiness } = useUser();

  const {
    data: onboardingLevel3Data,
    isLoading: isLoadingOnboardingLevel3Data,
    isError: isErrorOnboardingLevel3Data,
  } = useQuery<Partial<IBusiness>>(
    ['onboardingLevel3Data'],
    () => getBusinessOnboardingLevel3Data(selectedBusiness!.id),
    { enabled: !!selectedBusiness }
  );

  const { data: bankAccount } = useQuery<IBankAccount | undefined>(
    ['bankAccount', selectedBusiness?.id],
    getOneBankAccountByBusiness
  );

  const defaultUifDocument = onboardingLevel3Data?.documents?.find(
    ({ documentTypeId }) => documentTypeId === EDocumentTypes.OBLIGATED_SUBJECT
  );

  const beneficiaryBusinesses =
    onboardingLevel3Data?.beneficiaryBusinesses?.map(
      ({
        participationPercentage,
        city,
        pep,
        ...rest
      }: any): IFinalBeneficiary => ({
        ...rest,
        participationPercentage: participationPercentage,
        cityId: city.id,
        geographicalDivision: city.geographicalDivisionId,
        nationality: city.geographicalDivision.countryId,
        pep: String(pep),
      })
    ) || [];

  const defaultValues = {
    businessBankAccounts: [bankAccount],
    [UIF_RADIO]: onboardingLevel3Data?.[UIF_RADIO],
    businessActivityId: selectedBusiness?.businessActivityId,
    documents: selectedBusiness?.documents,
    beneficiaryBusinesses,
  };
  useEffect(() => {
    if (selectedBusiness) {
      reset(defaultValues);
      setDefaultUIFDocument(defaultUifDocument);
    }
  }, [selectedBusiness?.id, onboardingLevel3Data, bankAccount]);

  return null;
};
export default LoadDefaultsEffect;
