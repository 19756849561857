import { Divider, Flex, Text } from '@chakra-ui/react';
import { ECurrencyName } from '@common/types';
import { TokenSummaryItem } from './LockedTokenSummaryItem';
import { SumLockedTokens } from './SumLockedTokens';
import { TotalDebt } from './TotalDebt';

interface Props {
  amountToFinance: number;
  operationAmountToFinance: number;
  feePercentageRate: number;
  operationFee: number;
  operationInterest: number;
  operationReserve: number;
  operationTotal: number;
  annualPercentageRate: number;
  reservePercentageRate: number;
  operationTokenPriceRate: number;
  token: ECurrencyName;
}
export const LockedTokensSummary = ({
  amountToFinance,
  feePercentageRate,
  operationFee,
  annualPercentageRate,
  operationAmountToFinance,
  operationInterest,
  operationReserve,
  operationTotal,
  operationTokenPriceRate,
  reservePercentageRate,
  token,
}: Props) => {
  return (
    <Flex
      p="24px"
      borderRadius="4px"
      border="1px solid"
      borderColor="gray.200"
      gap="16px"
      flexDir="column"
    >
      <Text fontFamily="Larsseit-Medium" fontWeight="500" fontSize="16px">
        Resumen del bloqueo de tokens
      </Text>
      <Flex flexDir="column" gap="8px">
        <TokenSummaryItem
          label="Capital desembolsado"
          labelDetail={amountToFinance}
          token={token}
          value={operationAmountToFinance}
        />
        <TokenSummaryItem
          label="Comisión de Agrotoken"
          labelDetail={`${feePercentageRate * 100} %`}
          token={token}
          value={operationFee}
        />
        <TokenSummaryItem
          label="Cálculo de interéses a fin de plazo"
          labelDetail={`${annualPercentageRate}%`}
          token={token}
          value={operationInterest}
        />
        <TokenSummaryItem
          label="Aforo"
          labelDetail={`${reservePercentageRate}%`}
          token={token}
          value={operationReserve}
        />
      </Flex>
      <Divider />
      <Flex flexDir="column" gap="8px">
        <SumLockedTokens
          token={token}
          value={operationTotal}
          localCurrencyValue={operationTotal * operationTokenPriceRate}
        />
      </Flex>
      <Divider />
      {/* TODO: Fix those values: */}
      <TotalDebt
        token={token}
        value="900,0123"
        localCurrencyValue="ARS 50.000,00"
      />
    </Flex>
  );
};
