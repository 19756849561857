import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { http } from '@src/http-common';
import { IShippingAddress } from '@def/shippingAddress';

export const getUserAddressById = async (
  id: number
): Promise<IShippingAddress | any> => {
  const { data } = await http.get<IShippingAddress>(
    `/${PLATFORM_PREFIX}/user-addresses/${id}`
  );
  return data;
};
