import FeatherIcon from 'feather-icons-react';
import { FC, HTMLAttributes, ChangeEvent, ReactNode } from 'react';

export interface Props extends HTMLAttributes<HTMLSelectElement> {
  name: string;
  options: { label: string; value: string | number }[];
  value: string | number;
  label?: string;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  icon?: string | ReactNode;
  hintMessage?: string;
  errorMessage?: string;
  isValid?: boolean;
  showError?: boolean;
  onChange?: (ev: ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: (ev: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (ev: ChangeEvent<HTMLSelectElement>) => void;
  onInput?: (ev: ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  isLoading?: boolean;
  wfit?: boolean;
}

export const Select: FC<Props> = ({
  label,
  name,
  options,
  fullWidth = true,
  required = true,
  disabled = false,
  icon,
  hintMessage,
  errorMessage,
  value = '',
  isValid = true,
  showError,
  onChange,
  onBlur,
  onFocus,
  onInput,
  className,
  isLoading,
  wfit,
}) => {
  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={name}
          className="block text-gray-700 text-sm mb-1.5 font-medium"
        >
          {label}
        </label>
      )}
      <div className="relative inline">
        <select
          id={name}
          name={name}
          className={`appearance-none border rounded py-2.5 pr-11 text-md text-gray-900 shadow-xs placeholder:text-gray-500 focus-visible:outline-0 disabled:bg-gray-50 ${
            !isValid && showError
              ? 'border-error-300 focus-visible:shadow-focus-error focus:shadow-focus-error'
              : 'border-gray-300 focus-visible:shadow-focus-primary focus:shadow-focus-primary focus:border-primary-300 focus-visible:border-primary-300'
          } ${icon ? 'pl-11' : 'pl-3.5'} ${fullWidth && 'w-full'} ${
            wfit ? 'w-fit' : 'w-full'
          }
          `}
          aria-required={required}
          disabled={disabled || isLoading}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onInput={onInput}
          value={value}
        >
          {!isLoading ? (
            options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.value === ''}
              >
                {option.label}
              </option>
            ))
          ) : (
            <option key="loading" value="Loading...">
              Loading...
            </option>
          )}
        </select>
        {icon && typeof icon === 'string' ? (
          <FeatherIcon
            icon={icon}
            className="absolute inset-y-0 left-4 text-gray-500 my-auto"
            size="20"
          />
        ) : (
          <div className="absolute inset-y-0 left-4 text-gray-500 my-auto">
            {icon}
          </div>
        )}
        <FeatherIcon
          icon="chevron-down"
          className="pointer-events-none absolute inset-y-0 right-4 text-gray-500 my-auto"
          size="20"
        />
      </div>
      {hintMessage && isValid ? (
        <p className="mt-1.5 text-sm text-gray-500">{hintMessage}</p>
      ) : (
        !isValid &&
        errorMessage &&
        showError && (
          <p className="mt-1.5 text-sm text-error-500">{errorMessage}</p>
        )
      )}
    </div>
  );
};
