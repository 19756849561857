import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Text,
  ModalOverlay,
  useDisclosure,
  FormLabel,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import { AsyncSelect, Select, chakraComponents } from 'chakra-react-select';
import { debounce } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch, FiX } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { EPermissionCategory, IBusiness, IBusinessUser } from '@common/types';
import { getBusinessUsersAsManager } from '@services/BusinessUsers';
import { getBusinesses } from '@services/Businesses';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import { LOGIN_PATH, TOKENIZATIONS_PATH } from '../../common/const';

interface Props {
  isOpen: boolean;
  canClose?: boolean;
  setIsOpen?: React.Dispatch<boolean>;
}
export const SelectManagerOrg: FC<{
  setIsOpen: React.Dispatch<boolean> | undefined;
}> = (props: { setIsOpen: React.Dispatch<boolean> | undefined }) => {
  const { setIsOpen } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState<IBusiness[] | undefined>();
  const [selectedBusinessUserValue, setSelectedBusinessUserValue] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const {
    selectedBusinessId,
    setSelectedBusiness,
    setSelectedBusinessId,
    setSelectedBusinessUser,
    selectedBusinessUserId,
    setSelectedBusinessUserId,
  } = useUser();

  const { isLoading: isLoadingBusinessUsers, data: businessUsers } = useQuery<
    IBusinessUser[] | undefined
  >(['businessUsers', selectedBusinessId], getBusinessUsersAsManager, {
    enabled: selectedBusinessId !== null,
  });

  const auth = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLogout = () => {
    localStorage.clear();
    auth.signOut();
    navigate(LOGIN_PATH);
  };
  const handleSubmit = () => {
    if (selectedBusinessId && selectedBusinessUserId) {
      onClose();
      if (setIsOpen) {
        setIsOpen(false);
      }
      navigate(`${TOKENIZATIONS_PATH}`);
    }
  };

  useEffect(() => {
    loadBusinessOptions('');
    onOpen();
  }, []);

  const loadBusinessOptions = async (inputValue: any) => {
    const business = await getBusinesses({ filter: inputValue });
    setBusinesses(business);
    const options = business?.map((business) => ({
      label: business.name,
      value: business.id,
    }));
    return options;
  };

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    loadBusinessOptions(inputValue).then((options) => {
      callback(options);
    });
  }, 1500);

  const loadOptions = (inputValue: any, callback: any) => {
    debouncedLoadOptions(inputValue, callback);
  };
  const components = {
    ClearIndicator: (props: any) => (
      <chakraComponents.ClearIndicator {...props}>
        <Icon as={FiX} w={4} h={4} />
      </chakraComponents.ClearIndicator>
    ),
    DropdownIndicator: () => <></>,
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent p={6}>
          <Text fontSize="large" fontFamily="Larsseit-Medium">
            {t('selectManagerOrg.title')}
          </Text>
          <Text fontSize="small" mt="2" textColor={'gray.500'}>
            {t('selectManagerOrg.supportingText')}
          </Text>
          <FormLabel mt="4">
            {t('selectManagerOrg.searchBusinessUnit.label')}
          </FormLabel>
          <AsyncSelect
            isDisabled={false}
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            isClearable
            components={components}
            noOptionsMessage={() => t('selectManagerOrg.noOptions')}
            onChange={(
              ev: {
                label: string;
                value: number;
              } | null
            ) => {
              if (ev?.value) {
                setSelectedBusinessId(ev?.value);
                const selectedBusinessOption = businesses?.find(
                  (item) => item.id === ev.value
                );
                if (selectedBusinessOption) {
                  setSelectedBusiness(selectedBusinessOption);
                }
                setSelectedBusinessUser(null);
                setSelectedBusinessUserId(null);
              } else {
                setSelectedBusinessId(null);
                setSelectedBusiness(null);
              }
              setSelectedBusinessUserValue(null);
            }}
            placeholder={
              <Text>
                <Icon as={FiSearch} mr="2" color={'black'} />
                {t('selectManagerOrg.searchBusinessUnit.placeholder')}
              </Text>
            }
            loadingMessage={() => <Spinner />}
          ></AsyncSelect>
          <FormLabel mt="4">
            {t('selectManagerOrg.searchBusinessUser.label')}
          </FormLabel>
          <Select
            id="business-user-select"
            options={businessUsers
              ?.filter(
                (bu) =>
                  bu.permissions.some(
                    (permission) =>
                      permission.permissionCategory.keyCode ===
                      EPermissionCategory.MAKE_TOKENIZATIONS_AND_DETOKENIZATION
                  ) && !!bu?.isRepresentant
              )
              .map((business) => ({
                label: business.user.firstName + ' ' + business.user.lastName,
                value: business.id,
              }))}
            components={components}
            onChange={(ev) => {
              if (ev?.value) {
                setSelectedBusinessUserId(ev?.value);
                setSelectedBusinessUserValue(ev);
                const selectedBusinessUserOption = businessUsers?.find(
                  (item) => item.id === ev.value
                );
                if (selectedBusinessUserOption) {
                  setSelectedBusinessUser(selectedBusinessUserOption);
                }
              } else {
                setSelectedBusinessUserId(null);
                setSelectedBusinessUser(null);
                setSelectedBusinessUserValue(null);
              }
            }}
            isLoading={isLoadingBusinessUsers}
            isDisabled={!selectedBusinessId}
            noOptionsMessage={() => t('selectManagerOrg.noOptions')}
            placeholder={
              <Text>
                <Icon as={FiSearch} mr="2" color={'black'} />
                {t('selectManagerOrg.searchBusinessUser.placeholder')}
              </Text>
            }
            value={selectedBusinessUserValue}
            isClearable
            loadingMessage={() => <Spinner />}
          ></Select>
          {
            <Button
              variant="primary"
              mt="4"
              onClick={handleSubmit}
              isDisabled={!selectedBusinessId || !selectedBusinessUserId}
            >
              {t('selectManagerOrg.confirm')}
            </Button>
          }
          <ModalFooter p="0" mt="4" justifyContent={'start'}>
            <Button variant="linkPrimary" onClick={handleLogout}>
              {t('dropdown.logOut')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
