import { IPixType } from '@common/types';
import { http } from '@src/http-common';

export const getPixTypes = async (): Promise<IPixType[]> => {
  return [
    { id: 1, name: 'EMAIL', description: 'Email' },
    { id: 2, name: 'PHONE_NUMBER', description: 'Número de telefone' },
    { id: 3, name: 'RANDOM_KEY', description: 'Chave aleatória' },
    { id: 4, name: 'FISCAL_ID', description: 'Número fiscal' },
  ];
};
