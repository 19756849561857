import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import GenericLayout from '../components/CardGenericLayout';
import Header from './components/Header';
import { BusinessUserCards } from '../interfaces';
import { VFC } from 'react';
import { useUser } from '@src/context/userContext';
import CardListItem from './components/CardListItem';
import { useAccess } from '@src/context/accessContext';
import { EXTERNAL_CARD_STATUS } from '../types';

interface CardListProps {
  businessUserWithCards: BusinessUserCards[] | undefined;
  handleDownloadFile: () => void;
  isLoadingFile?: boolean;
}

const CardList: VFC<CardListProps> = ({
  businessUserWithCards,
  handleDownloadFile,
  isLoadingFile,
}) => {
  const { t } = useTranslation();
  const { canAdminUsers, isCompleteOnboardingLevel, canRequestCard } =
    useAccess();
  const isAdministrator = canAdminUsers && isCompleteOnboardingLevel;

  const { selectedBusinessUser } = useUser();

  const currentUserCards =
    businessUserWithCards &&
    businessUserWithCards.find((bu) => bu.id === selectedBusinessUser?.id)
      ?.cards;

  const otherBuWithCards = businessUserWithCards?.filter(
    (bu) => bu.cards.length > 0 && bu.id !== selectedBusinessUser?.id
  );

  return (
    <GenericLayout
      header={
        <Header
          isLoading={isLoadingFile}
          handleDownloadFile={handleDownloadFile}
          otherBuWithCards={otherBuWithCards}
          currentUserCards={currentUserCards}
        />
      }
      main={
        <>
          {currentUserCards && (
            <Text variant="medium" size="lg" mb={4} mt={10}>
              {t('cards.cardList.main.myCardsTitle')}
            </Text>
          )}
          <Flex
            direction={'column'}
            border={currentUserCards ? '1px solid' : ''}
            borderColor="gray.300"
            borderRadius="4px"
            backgroundColor="gray.25"
          >
            {currentUserCards?.map((card) => {
              return <CardListItem key={card.id} card={card} />;
            })}
          </Flex>

          {isAdministrator &&
            otherBuWithCards?.map((bu) => {
              return (
                <>
                  <Text
                    size="lg"
                    color={'gray.900'}
                    fontFamily="Larsseit-Medium"
                    mb={4}
                    mt={10}
                  >
                    {bu.fullName}
                  </Text>
                  <Flex
                    direction={'column'}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="4px"
                    backgroundColor="gray.25"
                  >
                    {bu.cards.map((card) => {
                      return <CardListItem key={card.id} card={card} />;
                    })}
                  </Flex>
                </>
              );
            })}
        </>
      }
    ></GenericLayout>
  );
};
export default CardList;
