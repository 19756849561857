import React, { FC, HTMLAttributes, ReactNode, useState } from 'react';

const arrowPosition = {
  'bottom-center': 'mx-auto inset-x-0 bottom-[-6px]',
  'bottom-left': 'left-3 bottom-[-6px]',
  'bottom-right': 'right-3 bottom-[-6px]',
  'top-center': 'mx-auto inset-x-0 top-[-6px]',
  left: 'my-auto inset-y-0 left-[-9px]',
  right: 'my-auto inset-y-0 right-[-9px]',
  none: '',
};

export type TTooltipArrow =
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-center'
  | 'left'
  | 'right'
  | 'none';
export type TTooltipTheme = 'light' | 'dark';
export type TTextAlignment =
  | 'text-left'
  | 'text-right'
  | 'text-center'
  | 'text-justify';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  theme: TTooltipTheme;
  text: string | ReactNode;
  arrow: TTooltipArrow;
  supportText?: string;
  className?: string;
}

export const Tooltip: FC<Props> = ({
  theme,
  text,
  supportText,
  arrow = 'none',
  className = '',
}) => {
  return (
    <div className={`absolute inline-block w-max max-w-xs ${className}`}>
      <div className="relative">
        <div
          className={`rounded-md shadow-lg ${
            supportText ? 'p-3' : 'px-3 py-2'
          } ${theme === 'light' ? 'bg-white' : 'bg-gray-900'}`}
        >
          <p
            className={`text-sm font-medium ${
              theme === 'light' ? 'text-gray-700' : 'text-white'
            }`}
          >
            {text}
          </p>
          {supportText && (
            <p
              className={`text-xs font-normal mt-1 ${
                theme === 'light' ? 'text-gray-500' : 'text-white'
              }`}
            >
              {supportText}
            </p>
          )}
        </div>
        {arrow !== 'none' && (
          <svg
            className={`absolute origin-center ${arrowPosition[arrow]} ${
              arrow === 'top-center'
                ? 'rotate-180'
                : arrow === 'left'
                ? 'rotate-90'
                : arrow === 'right'
                ? '-rotate-90'
                : ''
            }`}
            width="15"
            height="9"
            viewBox="0 0 15 9"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className={` ${
                theme === 'light' ? 'fill-white' : 'fill-gray-900'
              }`}
              d="M13.5711 0.485289C14.462 0.485289 14.9081 1.56243 14.2782 2.1924L8.20711 8.26347C7.81658 8.654 7.18342 8.654 6.79289 8.26347L0.721832 2.1924C0.0918675 1.56243 0.538034 0.485289 1.42894 0.485289L13.5711 0.485289Z"
              fill="currentColor"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
