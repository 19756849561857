var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/enums/index.ts
var enums_exports = {};
__export(enums_exports, {
  AGREEMENT_TYPE: () => AGREEMENT_TYPE,
  API_BUSSINESS_IDENTIFIER_STATUSES: () => API_BUSSINESS_IDENTIFIER_STATUSES,
  BUSINESS_FISCAL_IDENTIFIER_STATUSES: () => BUSINESS_FISCAL_IDENTIFIER_STATUSES,
  BUSINESS_FISCAL_IDENTIFIER_TYPES: () => BUSINESS_FISCAL_IDENTIFIER_TYPES,
  BUSINESS_REQUEST_TYPE: () => BUSINESS_REQUEST_TYPE,
  BUSINESS_WALLET_TYPES: () => BUSINESS_WALLET_TYPES,
  CARD_STATUSES: () => CARD_STATUSES,
  CARD_STATUS_REASONS: () => CARD_STATUS_REASONS,
  CARD_TYPES: () => CARD_TYPES,
  CONCILIATION_ADJUSTMENT_TYPES: () => CONCILIATION_ADJUSTMENT_TYPES,
  CONCILIATION_DETAIL_STATUSES: () => CONCILIATION_DETAIL_STATUSES,
  CONCILIATION_STATUSES: () => CONCILIATION_STATUSES,
  CONCILIATION_TRANSACTION_STATUS_DETAILS: () => CONCILIATION_TRANSACTION_STATUS_DETAILS,
  CPR_TYPES: () => CPR_TYPES,
  CREDIT_STATUSES: () => CREDIT_STATUSES,
  CURRENCY_DETAILS_KEY_CODES: () => CURRENCY_DETAILS_KEY_CODES,
  CURRENCY_NAMES: () => CURRENCY_NAMES,
  DOCUMENT_STATUSES: () => DOCUMENT_STATUSES,
  DOCUMENT_TYPES: () => DOCUMENT_TYPES,
  EXTERNAL_NOTIFICATION_STATUSES: () => EXTERNAL_NOTIFICATION_STATUSES,
  FIAT_CURRENCIES: () => FIAT_CURRENCIES,
  GRAIN_CONTRACT_REJECTION_REASONS: () => GRAIN_CONTRACT_REJECTION_REASONS,
  GRAIN_CONTRACT_TONS_VERIFIER: () => GRAIN_CONTRACT_TONS_VERIFIER,
  GRAIN_CONTRACT_TYPES: () => GRAIN_CONTRACT_TYPES,
  GRAIN_CONTRACT_VALIDATION_STATUS: () => GRAIN_CONTRACT_VALIDATION_STATUS,
  GRAIN_CONTRACT_VALIDATOR: () => GRAIN_CONTRACT_VALIDATOR,
  MOVEMENT_DIRECTIONS: () => MOVEMENT_DIRECTIONS,
  MOVEMENT_TYPES: () => MOVEMENT_TYPES,
  OPERATION_STATUSES: () => OPERATION_STATUSES,
  OPERATION_TYPES: () => OPERATION_TYPES,
  OVERCOLLATERAL_METHODS: () => OVERCOLLATERAL_METHODS,
  PARAMETER_NAME: () => PARAMETER_NAME,
  PAYMENT_REQUESTS_ALLOWED_FISCAL_ID_TYPES: () => PAYMENT_REQUESTS_ALLOWED_FISCAL_ID_TYPES,
  PAYMENT_REQUEST_STATUSES: () => PAYMENT_REQUEST_STATUSES,
  PAYMENT_REQUEST_TYPES: () => PAYMENT_REQUEST_TYPES,
  PAYMENT_REQUEST_WARRANTY_STATUSES: () => PAYMENT_REQUEST_WARRANTY_STATUSES,
  POMELO_TRANSACTION_STATUSES: () => POMELO_TRANSACTION_STATUSES,
  POMELO_TRANSACTION_TYPES: () => POMELO_TRANSACTION_TYPES,
  ParameterNames: () => ParameterNames,
  REFERENCE_MARKET_TYPES: () => REFERENCE_MARKET_TYPES,
  TRANSACTION_ENTRY_TYPE: () => TRANSACTION_ENTRY_TYPE,
  TRANSFER_ASSET_TYPES: () => TRANSFER_ASSET_TYPES,
  TRANSFER_STATUSES: () => TRANSFER_STATUSES,
  USER_TYPES: () => USER_TYPES,
  VALIDATION_STATUSES: () => VALIDATION_STATUSES,
  VAULT_STATUSES: () => VAULT_STATUSES,
  VAULT_TYPES: () => VAULT_TYPES,
  agreementTypes: () => agreementTypes,
  apiBusinessIdentifierStatuses: () => apiBusinessIdentifierStatuses,
  businessFiscalIdentitierStatuses: () => businessFiscalIdentitierStatuses,
  businessFiscalIdentitierTypes: () => businessFiscalIdentitierTypes,
  businessRequestTypes: () => businessRequestTypes,
  businessWalletTypes: () => businessWalletTypes,
  cardStatusReasons: () => cardStatusReasons,
  cardStatuses: () => cardStatuses,
  cardTypes: () => cardTypes,
  conciliationAdjustmentTypes: () => conciliationAdjustmentTypes,
  conciliationDetailStatuses: () => conciliationDetailStatuses,
  conciliationStatuses: () => conciliationStatuses,
  conciliationTransactionStatusDetails: () => conciliationTransactionStatusDetails,
  cprTypes: () => cprTypes,
  creditStatuses: () => creditStatuses,
  currencyDetailsKeyCodes: () => currencyDetailsKeyCodes,
  currencyNames: () => currencyNames,
  documentStatuses: () => documentStatuses,
  documentTypes: () => documentTypes,
  externalNotificationStatuses: () => externalNotificationStatuses,
  fiatCurrencies: () => fiatCurrencies,
  grainContractRejectionReasons: () => grainContractRejectionReasons,
  grainContractTonsVerifiers: () => grainContractTonsVerifiers,
  grainContractTypes: () => grainContractTypes,
  grainContractValidationStatuses: () => grainContractValidationStatuses,
  grainContractValidators: () => grainContractValidators,
  movementDirections: () => movementDirections,
  movementTypes: () => movementTypes,
  operationStatuses: () => operationStatuses,
  operationTypes: () => operationTypes,
  overcollateralMethods: () => overcollateralMethods,
  paymentRequestStatuses: () => paymentRequestStatuses,
  paymentRequestTypes: () => paymentRequestTypes,
  paymentRequestWarrantyStatuses: () => paymentRequestWarrantyStatuses,
  paymentRequestsAllowedFiscalIdTypes: () => paymentRequestsAllowedFiscalIdTypes,
  pomeloTransactionStatuses: () => pomeloTransactionStatuses,
  pomeloTransactionTypes: () => pomeloTransactionTypes,
  referenceMarketTypes: () => referenceMarketTypes,
  transactionEntryTypes: () => transactionEntryTypes,
  transferAssetTypes: () => transferAssetTypes,
  transferStatuses: () => transferStatuses,
  userTypes: () => userTypes,
  validationStatuses: () => validationStatuses,
  vaultStatuses: () => vaultStatuses,
  vaultTypes: () => vaultTypes
});
module.exports = __toCommonJS(enums_exports);

// src/enums/cpr-type.enum.ts
var CPR_TYPES = {
  FOR_AGROTOKEN: "FOR_AGROTOKEN",
  ENDORSEMENT_AGROTOKEN: "ENDORSEMENT_AGROTOKEN"
};
var cprTypes = Object.values(CPR_TYPES);

// src/enums/validation-status.enum.ts
var VALIDATION_STATUSES = {
  PENDING: 1,
  CHANGE_REQUEST: 2,
  APPROVED: 3
};
var validationStatuses = Object.values(VALIDATION_STATUSES);

// src/enums/businesses/api-business-identifier-status.enum.ts
var API_BUSSINESS_IDENTIFIER_STATUSES = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DELETED: "DELETED"
};
var apiBusinessIdentifierStatuses = Object.values(API_BUSSINESS_IDENTIFIER_STATUSES);

// src/enums/businesses/business-fiscal-identifier-status.enum.ts
var BUSINESS_FISCAL_IDENTIFIER_STATUSES = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DELETED: "DELETED"
};
var businessFiscalIdentitierStatuses = Object.values(BUSINESS_FISCAL_IDENTIFIER_STATUSES);

// src/enums/businesses/business-fiscal-identifier-type.enum.ts
var BUSINESS_FISCAL_IDENTIFIER_TYPES = {
  CUIT: "CUIT",
  CUIL: "CUIL",
  CNPJ: "CNPJ",
  CPF: "CPF"
};
var businessFiscalIdentitierTypes = Object.values(BUSINESS_FISCAL_IDENTIFIER_TYPES);
var PAYMENT_REQUESTS_ALLOWED_FISCAL_ID_TYPES = {
  [BUSINESS_FISCAL_IDENTIFIER_TYPES.CUIT]: BUSINESS_FISCAL_IDENTIFIER_TYPES.CUIT,
  [BUSINESS_FISCAL_IDENTIFIER_TYPES.CNPJ]: BUSINESS_FISCAL_IDENTIFIER_TYPES.CNPJ
};
var paymentRequestsAllowedFiscalIdTypes = Object.values(PAYMENT_REQUESTS_ALLOWED_FISCAL_ID_TYPES);

// src/enums/businesses/business-wallet-type.enum.ts
var BUSINESS_WALLET_TYPES = {
  INTERNAL: "INTERNAL",
  BLOCKED: "BLOCKED",
  GLOBAL: "GLOBAL",
  EXTERNAL: "EXTERNAL",
  TREASURY: "TREASURY",
  PREBURN: "PREBURN"
};
var businessWalletTypes = Object.values(BUSINESS_WALLET_TYPES);

// src/enums/businesses/business-request-type.enum.ts
var BUSINESS_REQUEST_TYPE = {
  USER: "USER",
  ADMIN: "ADMIN",
  LANDTOKEN: "LANDTOKEN"
};
var businessRequestTypes = Object.values(BUSINESS_REQUEST_TYPE);

// src/enums/cards/card-status-reason.enum.ts
var CARD_STATUS_REASONS = {
  NONE: "NONE",
  CLIENT_INTERNAL_REASON: "CLIENT_INTERNAL_REASON",
  USER_INTERNAL_REASON: "USER_INTERNAL_REASON",
  POMELO_INTERNAL_REASON: "POMELO_INTERNAL_REASON",
  FRAUDULENT: "FRAUDULENT",
  LOST: "LOST",
  STOLEN: "STOLEN",
  BROKEN: "BROKEN",
  UPGRADE: "UPGRADE"
};
var cardStatusReasons = Object.values(CARD_STATUS_REASONS);

// src/enums/cards/card-status.enum.ts
var CARD_STATUSES = {
  /**
  * Card is fully active and ready to use
  */
  ACTIVE: "ACTIVE",
  /**
  * Card is temporarily blocked
  */
  BLOCKED: "BLOCKED",
  /**
  * Card is permanently disabled
  */
  DISABLED: "DISABLED",
  /**
  * Card has been requested, but not printed/delivered or pending activation yet
  */
  CREATED: "CREATED",
  /**
  * Card is printed and pending activation
  */
  EMBOSSED: "EMBOSSED"
};
var cardStatuses = Object.values(CARD_STATUSES);

// src/enums/cards/card-type.enum.ts
var CARD_TYPES = {
  PHYSICAL: "PHYSICAL",
  VIRTUAL: "VIRTUAL"
};
var cardTypes = Object.values(CARD_TYPES);

// src/enums/credits/credit-status.enum.ts
var CREDIT_STATUSES = {
  PENDING_BLOCKCHAIN: "PENDING_BLOCKCHAIN",
  ACTIVE: "ACTIVE",
  CONCLUDED: "CONCLUDED"
};
var creditStatuses = Object.values(CREDIT_STATUSES);

// src/enums/currencies/currency-details-key-code.enum.ts
var CURRENCY_DETAILS_KEY_CODES = {
  SMALL_BACKGROUND_IMAGE_URL: "SMALL_BACKGROUND_IMAGE_URL",
  MEDIUM_BACKGROUND_IMAGE_URL: "MEDIUM_BACKGROUND_IMAGE_URL",
  BIG_BACKGROUND_IMAGE_URL: "BIG_BACKGROUND_IMAGE_URL",
  SMALL_ICON_URL: "SMALL_ICON_URL",
  BIG_ICON_URL: "BIG_ICON_URL",
  DISABLED_IMAGE_URL: "DISABLED_IMAGE_URL",
  DESCRIPTION: "DESCRIPTION",
  TEXT_COLOR: "TEXT_COLOR",
  BACKGROUND_COLOR: "BACKGROUND_COLOR",
  ICON_BACKGROUND_COLOR: "ICON_BACKGROUND_COLOR"
};
var currencyDetailsKeyCodes = Object.values(CURRENCY_DETAILS_KEY_CODES);

// src/enums/currencies/currency-name.enum.ts
var CURRENCY_NAMES = {
  SOYA: "SOYA",
  CORA: "CORA",
  WHEA: "WHEA",
  ARS: "ARS",
  ARS_SOY: "ARS_SOY",
  SOYA_ARS: "SOYA_ARS",
  CORA_ARS: "CORA_ARS",
  BRL: "BRL",
  USD: "USD",
  SOYB: "SOYB",
  CORB: "CORB",
  WHEB: "WHEB",
  ABRC: "ABRC",
  COFB: "COFB"
};
var currencyNames = Object.values(CURRENCY_NAMES);

// src/enums/currencies/fiat-currency.enum.ts
var FIAT_CURRENCIES = {
  BRL: "BRL",
  ARS: "ARS",
  USD: "USD"
};
var fiatCurrencies = Object.values(FIAT_CURRENCIES);

// src/enums/documents/document-status.enum.ts
var DOCUMENT_STATUSES = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECT: "REJECT"
};
var documentStatuses = Object.values(DOCUMENT_STATUSES);

// src/enums/documents/document-type.enum.ts
var DOCUMENT_TYPES = {
  CDE: "CDE",
  AGREEMENT_DOC: "AGREEMENT_DOC",
  DNI_FRONT: "DNI_FRONT",
  DNI_BACK: "DNI_BACK",
  LEGAL_POWER: "LEGAL_POWER",
  PAYMENT_REQUEST: "PAYMENT_REQUEST",
  STATUTE_OR_SOCIAL_CONTRACT: "STATUTE_OR_SOCIAL_CONTRACT",
  DESIGNATION_OF_AUTHORITIES: "DESIGNATION_OF_AUTHORITIES",
  UIF: "UIF",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  MERCHANT_FEE_BILL: "MERCHANT_FEE_BILL",
  FARMER_FEE_BILL: "FARMER_FEE_BILL",
  ECHECK_CERTIFICATE: "ECHECK_CERTIFICATE",
  STATE_REGISTRATION: "STATE_REGISTRATION",
  CAR: "CAR",
  CREDIT_ASSIGNMENT: "CREDIT_ASSIGNMENT",
  PROOF_OF_ORIGIN: "PROOF_OF_ORIGIN",
  SELFIE: "SELFIE",
  ESG: "ESG",
  CPR: "CPR",
  VAULT: "VAULT",
  RESCISSION_NOTIFICATION: "RESCISSION_NOTIFICATION",
  PROOF_OF_HEALTH: "PROOF_OF_HEALTH"
};
var documentTypes = Object.values(DOCUMENT_TYPES);

// src/enums/grain-contracts/grain-contract-rejection-reason.enum.ts
var GRAIN_CONTRACT_REJECTION_REASONS = {
  TONS_ALREADY_FIXED: "TONS_ALREADY_FIXED",
  TONS_ALREADY_SETTLED: "TONS_ALREADY_SETTLED",
  CONTRACT_COMMITTED: "CONTRACT_COMMITED",
  CONTRACT_PLEDGED: "CONTRACT_PLEDGED",
  OTHER: "OTHER"
};
var grainContractRejectionReasons = Object.values(GRAIN_CONTRACT_REJECTION_REASONS);

// src/enums/grain-contracts/grain-contract-validator.enum.ts
var GRAIN_CONTRACT_VALIDATOR = {
  ORACLE: "ORACLE",
  OPERATIONS: "OPERATIONS",
  RISKS: "RISKS"
};
var grainContractValidators = Object.values(GRAIN_CONTRACT_VALIDATOR);

// src/enums/grain-contracts/grain-contract-tons-verifier.enum.ts
var GRAIN_CONTRACT_TONS_VERIFIER = {
  ORACLE: GRAIN_CONTRACT_VALIDATOR.ORACLE,
  RISKS: GRAIN_CONTRACT_VALIDATOR.RISKS
};
var grainContractTonsVerifiers = Object.values(GRAIN_CONTRACT_TONS_VERIFIER);

// src/enums/grain-contracts/grain-contract-type.enum.ts
var GRAIN_CONTRACT_TYPES = {
  REAL: "REAL",
  VIRTUAL: "VIRTUAL",
  FUTURE: "FUTURE",
  FIXED_GRAIN: "FIXED_GRAIN"
};
var grainContractTypes = Object.values(GRAIN_CONTRACT_TYPES);

// src/enums/grain-contracts/grain-contract-validation-status.enum.ts
var GRAIN_CONTRACT_VALIDATION_STATUS = {
  PENDING: "PENDING",
  CHANGE_REQUEST: "CHANGE_REQUEST",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED"
};
var grainContractValidationStatuses = Object.values(GRAIN_CONTRACT_VALIDATION_STATUS);

// src/enums/movements/movement-direction.enum.ts
var MOVEMENT_DIRECTIONS = {
  INCOMING: "INCOMING",
  OUTGOING: "OUTGOING"
};
var movementDirections = Object.values(MOVEMENT_DIRECTIONS);

// src/enums/movements/movement-type.enum.ts
var MOVEMENT_TYPES = {
  TOKENIZATION: "tokenization",
  NET_COMISSION: "net_comission",
  INTERESTS: "interests",
  TOKENIZATION_FEE: "tokenization_fee",
  TOKENIZATION_TRANSFER: "tokenization_transfer",
  ADJUSTMENT: "adjust",
  RETURN_TOKENIZATION_FEE: "return_tokenization_fee",
  DETOKENIZATION: "detokenization",
  DETOKENIZATION_FEE: "detokenization_fee",
  TRANSFER: "transfer",
  PAYMENT_FEE: "payment_fee",
  LOAN_GRANTED: "loan_granted",
  LOAN_PAID: "loan_paid",
  LOAN_REIMBURSMENT: "loan_reimbursment",
  CRYPTO_ASSET_SETTLEMENT: "crypto_asset_settlement",
  PAYMENT_WITH_CARD: "payment_with_card",
  SWAP_POGR: "swap_pogr",
  SWAP_COST: "swap_cost",
  GIFT: "gift",
  PAYMENT_IN_WARRANTY: "payment_in_warranty",
  PAYMENT_WARRANTY_CLAIM: "payment_warranty_claim",
  PAYMENT_IN_FIXED_GRAIN_CONTRACT: "payment_in_fixed_grain_contract",
  FREEZE_TOKENS: "freeze_tokens",
  UNFREEZE_TOKENS: "unfreeze_tokens",
  WARRANTY_TRANSFER_TOKEN_LOCK: "warranty_transfer_token_lock",
  WARRANTY_TRANSFER: "warranty_transfer",
  TRANSFER_FEE: "transfer_fee",
  CANCELED_WARRANTY_TRANSFER_TOKEN_UNLOCK: "canceled_warranty_transfer_token_unlock",
  REJECTED_WARRANTY_TRANSFER_TOKEN_UNLOCK: "rejected_warranty_transfer_token_unlock",
  EXPIRED_WARRANTY_TRANSFER_TOKEN_UNLOCK: "expired_warranty_transfer_token_unlock",
  CREDIT_LINE_COLLATERALIZATION: "CREDIT_LINE_COLLATERALIZATION",
  CREDIT_LINE_DISBURSEMENT: "CREDIT_LINE_DISBURSEMENT",
  CREDIT_LINE_LIQUIDITY_PROVIDED: "CREDIT_LINE_LIQUIDITY_PROVIDED",
  CREDIT_LINE_OPERATIVE_AMOUNT_RECOMPOSITION: "CREDIT_LINE_OPERATIVE_AMOUNT_RECOMPOSITION",
  CREDIT_LINE_LIQUIDITY_RETURNED: "CREDIT_LINE_LIQUIDITY_RETURNED"
};
var movementTypes = Object.values(MOVEMENT_TYPES);

// src/enums/operations/operation-status.enum.ts
var OPERATION_STATUSES = {
  PENDING: "PENDING",
  SUCCESSFUL: "SUCCESSFUL",
  FAILED: "FAILED",
  LIQUIDATED: "LIQUIDATED",
  EXPIRED: "EXPIRED"
};
var operationStatuses = Object.values(OPERATION_STATUSES);

// src/enums/operations/operation-type.enum.ts
var OPERATION_TYPES = {
  // Tokenizations
  TOKENIZATION: "TOKENIZATION",
  DETOKENIZATION: "DETOKENIZATION",
  GIFT: "GIFT",
  FREEZE_TOKENS: "FREEZE_TOKENS",
  UNFREEZE_TOKENS: "UNFREEZE_TOKENS",
  FEE_SETTLEMENT: "FEE_SETTLEMENT",
  FIAT_RELEASE: "FIAT_RELEASE",
  // Payment requests
  PAYMENT: "PAYMENT",
  PAYMENT_IN_KIND: "PAYMENT_IN_KIND",
  PAYMENT_IN_WARRANTIES: "PAYMENT_IN_WARRANTIES",
  PAYMENT_IN_FIXED_GRAIN_CONTRACT: "PAYMENT_IN_FIXED_GRAIN_CONTRACT",
  PAYMENT_WARRANTY_CLAIM: "PAYMENT_WARRANTY_CLAIM",
  MERCHANT_TO_MERCHANT_PAYMENT_FORWARDING: "MERCHANT_TO_MERCHANT_PAYMENT_FORWARDING",
  MERCHANT_TO_MERCHANT_PAYMENT_FORWARDING_SETTLEMENT: "MERCHANT_TO_MERCHANT_PAYMENT_FORWARDING_SETTLEMENT",
  // Transfers (warranty)
  ASSET_LOCK: "ASSET_LOCK",
  WARRANTY_TRANSFER: "WARRANTY_TRANSFER",
  ASSET_UNLOCK: "ASSET_UNLOCK",
  WARRANTY_SETTLEMENT_BLOCKED: "WARRANTY_SETTLEMENT_BLOCKED",
  PARTIAL_WARRANTY_SETTLEMENT: "PARTIAL_WARRANTY_SETTLEMENT",
  // Card payments
  PAYMENT_WITH_CARD: "PAYMENT_WITH_CARD",
  CREDIT_CARD_BLOCKED_RETURN: "CREDIT_CARD_BLOCKED_RETURN",
  CARD_PAYMENT_AUTHORIZATION_HOLD: "CARD_PAYMENT_AUTHORIZATION_HOLD",
  // Loans
  LOANS: "LOANS",
  LOAN_RELEASE: "LOAN_RELEASE",
  LOAN_DISTRIBUTE: "LOAN_DISTRIBUTE",
  SWAP_POGR: "SWAP_POGR",
  // Credits
  CREDIT_LINE_COLLATERALIZATION: "CREDIT_LINE_COLLATERALIZATION",
  CREDIT_SETTLEMENT: "CREDIT_SETTLEMENT",
  PARTIAL_CREDIT_SETTLEMENT: "PARTIAL_CREDIT_SETTLEMENT"
};
var operationTypes = Object.values(OPERATION_TYPES);

// src/enums/parameters/parameters-name.enum.ts
var PARAMETER_NAME = {
  OVERCOLLATERAL_STANDARD_PERCENTAGE: "OVERCOLLATERAL_STANDARD_PERCENTAGE",
  OVERCOLLATERAL_FIXED_PERCENTAGE: "OVERCOLLATERAL_FIXED_PERCENTAGE",
  MULTIPLIER: "MULTIPLIER",
  CORA: "CORA",
  SOYA: "SOYA",
  ARS: "ARS",
  WHEA: "WHEA",
  ARS_SOY: "ARS_SOY",
  CORB: "CORB",
  SOYB: "SOYB",
  WHEB: "WHEB",
  BRL: "BRL",
  SOYA_ARS: "SOYA_ARS",
  FEE_TOKENIZACION: "FEE_TOKENIZACION",
  FEE_POR_DESTOKENIZACION: "FEE_POR_DESTOKENIZACION",
  RESERVA: "RESERVA",
  FEE_POR_DESTOKENIZACION_ANTICIPADA: "FEE_POR_DESTOKENIZACION_ANTICIPADA",
  FEE_DE_SWAP: "FEE_DE_SWAP",
  FEE_TRADE_DE_SWAP: "FEE_TRADE_DE_SWAP",
  SOYA_BUFFER_RATE_PERCENTAGE: "SOYA_BUFFER_RATE_PERCENTAGE",
  CORA_BUFFER_RATE_PERCENTAGE: "CORA_BUFFER_RATE_PERCENTAGE",
  WHEA_BUFFER_RATE_PERCENTAGE: "WHEA_BUFFER_RATE_PERCENTAGE",
  SOYB_BUFFER_RATE_PERCENTAGE: "SOYB_BUFFER_RATE_PERCENTAGE",
  CORB_BUFFER_RATE_PERCENTAGE: "CORB_BUFFER_RATE_PERCENTAGE",
  WHEB_BUFFER_RATE_PERCENTAGE: "WHEB_BUFFER_RATE_PERCENTAGE",
  CARD_PAYMENT_INTEREST_RATE_PERCENTAGE: "CARD_PAYMENT_INTEREST_RATE_PERCENTAGE",
  CARD_PAYMENT_INTEREST_RATE_TAX_PERCENTAGE: "CARD_PAYMENT_INTEREST_RATE_TAX_PERCENTAGE",
  MAXIMUM_DAILY_MARKET_VOLATILITY: "MAXIMUM_DAILY_MARKET_VOLATILITY",
  CLOSEST_OVERCOLLATERAL_ADJUSTMENT: "CLOSEST_OVERCOLLATERAL_ADJUSTMENT",
  MEDIUM_OVERCOLLATERAL_ADJUSTMENT: "MEDIUM_OVERCOLLATERAL_ADJUSTMENT",
  FAREST_OVERCOLLATERAL_ADJUSTMENT: "FAREST_OVERCOLLATERAL_ADJUSTMENT",
  BUFFER_FREIGHT_MULTIPLIER: "BUFFER_FREIGHT_MULTIPLIER",
  SHORT_FREIGHT_MULTIPLIER: "SHORT_FREIGHT_MULTIPLIER",
  MERCHANT_FEE_PERCENTAGE: "MERCHANT_FEE_PERCENTAGE",
  FARMER_FEE_PERCENTAGE: "FARMER_FEE_PERCENTAGE",
  POLYGON: "Red",
  ethereum: "Red",
  M2M_MAX_ALLOWED_FORWARDINGS: "M2M_MAX_ALLOWED_FORWARDINGS",
  M2M_FORWARDING_FEE_PERCENTAGE: "M2M_FORWARDING_FEE_PERCENTAGE",
  MAX_FREIGHT_COST: "MAX_FREIGHT_COST",
  MIN_FREIGHT_COST: "MIN_FREIGHT_COST",
  TRANSFER_EXPIRATION_LIMIT: "TRANSFER_EXPIRATION_LIMIT",
  RECEIVER_WARRANTY_TRANSFER_FEE_PERCENTAGE: "RECEIVER_WARRANTY_TRANSFER_FEE_PERCENTAGE",
  SENDER_WARRANTY_TRANSFER_FEE_PERCENTAGE: "SENDER_WARRANTY_TRANSFER_FEE_PERCENTAGE",
  FEE_CREDITS: "FEE_CREDITS",
  PAYMENT_REQUEST_EXPIRATION_LIMIT: "PAYMENT_REQUEST_EXPIRATION_LIMIT",
  FARMER_FEE_FIXED_GRAIN_PERCENTAGE: "FARMER_FEE_FIXED_GRAIN_PERCENTAGE",
  MERCHANT_FEE_FIXED_GRAIN_PERCENTAGE: "MERCHANT_FEE_FIXED_GRAIN_PERCENTAGE"
};
var ParameterNames = Object.values(PARAMETER_NAME);

// src/enums/payfacs/pomelo/transaction-status.enum.ts
var POMELO_TRANSACTION_STATUSES = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED"
};
var pomeloTransactionStatuses = Object.values(POMELO_TRANSACTION_STATUSES);

// src/enums/payfacs/pomelo/transaction-type.enum.ts
var POMELO_TRANSACTION_TYPES = {
  PAYMENT: "PAYMENT",
  PURCHASE: "PURCHASE",
  REFUND: "REFUND",
  REVERSAL_PURCHASE: "REVERSAL_PURCHASE",
  REVERSAL_REFUND: "REVERSAL_REFUND",
  REVERSAL_PAYMENT: "REVERSAL_PAYMENT",
  BALANCE_INQUIRY: "BALANCE_INQUIRY"
};
var pomeloTransactionTypes = Object.values(POMELO_TRANSACTION_TYPES);

// src/enums/payfacs/conciliation-adjustment-type.enum.ts
var CONCILIATION_ADJUSTMENT_TYPES = {
  MISSING_IN_PAYMENT_FACILITATOR: "MISSING_IN_PAYMENT_FACILITATOR",
  MISSING_OPERATION_IN_DB: "MISSING_OPERATION_IN_DB",
  ADJUSTMENT_NEEDED: "ADJUSTMENT_NEEDED",
  IS_CORRECT: "IS_CORRECT"
};
var conciliationAdjustmentTypes = Object.values(CONCILIATION_ADJUSTMENT_TYPES);

// src/enums/payfacs/conciliation-detail-status.enum.ts
var CONCILIATION_DETAIL_STATUSES = {
  OK: "OK",
  ERROR: "ERROR",
  IGNORED: "IGNORED",
  PENDING: "PENDING",
  ADJUSTMENT_APPLIED: "ADJUSTMENT_APPLIED"
};
var conciliationDetailStatuses = Object.values(CONCILIATION_DETAIL_STATUSES);

// src/enums/payfacs/conciliation-status.enum.ts
var CONCILIATION_STATUSES = {
  PENDING: "PENDING",
  PROCESSED: "PROCESSED",
  APPLIED: "APPLIED",
  ERROR: "ERROR"
};
var conciliationStatuses = Object.values(CONCILIATION_STATUSES);

// src/enums/payfacs/conciliation-transaction-status-detail.enum.ts
var CONCILIATION_TRANSACTION_STATUS_DETAILS = {
  CARD_BLOCKED: "CARD_BLOCKED",
  CARD_DISABLED: "CARD_DISABLED",
  CARD_NOT_ACTIVE: "CARD_NOT_ACTIVE",
  CARD_NOT_CONFIGURED: "CARD_NOT_CONFIGURED",
  CARD_NOT_FOUND: "CARD_NOT_FOUND",
  CLIENT_TIMEOUT: "CLIENT_TIMEOUT",
  CLIENT_UNAVAILABLE: "CLIENT_UNAVAILABLE",
  CLIENT_SIGNATURE_ERROR: "CLIENT_SIGNATURE_ERROR",
  CRYPTO_ERROR: "CRYPTO_ERROR",
  DUPLICATE_TRANSMISSION_DETECTED: "DUPLICATE_TRANSMISSION_DETECTED",
  EXPIRED_CARD: "EXPIRED_CARD",
  EXTRA_FIELDS: "EXTRA_FIELDS",
  INSUFFICIENT_FUNDS: "INSUFFICIENT_FUNDS",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  INVALID_AMOUNT: "INVALID_AMOUNT",
  INVALID_CVV: "INVALID_CVV",
  INVALID_EXPIRATION_DATE: "INVALID_EXPIRATION_DATE",
  INVALID_MERCHANT: "INVALID_MERCHANT",
  INVALID_PIN: "INVALID_PIN",
  INVALID_TRANSACTION: "INVALID_TRANSACTION",
  LOST_CARD: "LOST_CARD",
  MISSING_FIELDS: "MISSING_FIELDS",
  NOT_DECLINED: "NOT_DECLINED",
  ORIGINAL_NOT_FOUND: "ORIGINAL_NOT_FOUND",
  OTHER: "OTHER",
  RESTRICTED_USER: "RESTRICTED_USER",
  SECURITY_VIOLATION: "SECURITY_VIOLATION",
  SERVICE_UNAVAILABLE: "SERVICE_UNAVAILABLE",
  STOLEN_CARD: "STOLEN_CARD",
  SYSTEM_ERROR: "SYSTEM_ERROR",
  APPROVED: "APPROVED",
  TRANSACTION_NOT_PERMITTED: "TRANSACTION_NOT_PERMITTED",
  REJECTED_FRAUD: "REJECTED_FRAUD"
};
var conciliationTransactionStatusDetails = Object.values(CONCILIATION_TRANSACTION_STATUS_DETAILS);

// src/enums/payfacs/transaction-entry-type.enum.ts
var TRANSACTION_ENTRY_TYPE = {
  DEBIT: "DEBIT",
  CREDIT: "CREDIT",
  PREPAID: "PREPAID"
};
var transactionEntryTypes = Object.values(TRANSACTION_ENTRY_TYPE);

// src/enums/payment-requests/payment-request-status.enum.ts
var PAYMENT_REQUEST_STATUSES = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  EXPIRED: "EXPIRED"
};
var paymentRequestStatuses = Object.values(PAYMENT_REQUEST_STATUSES);

// src/enums/payment-requests/payment-request-type.enum.ts
var PAYMENT_REQUEST_TYPES = {
  STANDARD: "STANDARD",
  IN_KIND: "IN_KIND",
  FUTURE_WARRANTY: "FUTURE_WARRANTY",
  FIXED: "FIXED"
};
var paymentRequestTypes = Object.values(PAYMENT_REQUEST_TYPES);

// src/enums/payment-requests/payment-request-warranty-status.enum.ts
var PAYMENT_REQUEST_WARRANTY_STATUSES = {
  NOT_CLAIMABLE: "NOT_CLAIMABLE",
  CLAIMABLE: "CLAIMABLE",
  CLAIMED: "CLAIMED"
};
var paymentRequestWarrantyStatuses = Object.values(PAYMENT_REQUEST_WARRANTY_STATUSES);

// src/enums/ppa/agreement-type.enum.ts
var AGREEMENT_TYPE = {
  PRICE_TO_FIX: "PRICE_TO_FIX",
  PRICE_TO_FIX_AGTK: "PRICE_TO_FIX_AGTK",
  FIXED_PRICE: "FIXED_PRICE"
};
var agreementTypes = Object.values(AGREEMENT_TYPE);

// src/enums/ppa/overcollateral-method.enum.ts
var OVERCOLLATERAL_METHODS = {
  VOLATILITY: "VOLATILITY",
  CORRELATION_30_DAYS: "CORRELATION_30_DAYS",
  CORRELATION_60_DAYS: "CORRELATION_60_DAYS",
  MAXIMUM_HISTORICAL_DIFFERENCE: "MAXIMUM_HISTORICAL_DIFFERENCE"
};
var overcollateralMethods = Object.values(OVERCOLLATERAL_METHODS);

// src/enums/ppa/reference-market-type.enum.ts
var REFERENCE_MARKET_TYPES = {
  TAKER: "TAKER",
  MAKER: "MAKER"
};
var referenceMarketTypes = Object.values(REFERENCE_MARKET_TYPES);

// src/enums/transfers/transfer-status.enum.ts
var TRANSFER_STATUSES = {
  PENDING_APPROVAL: "PENDING_APPROVAL",
  PROCESSING: "PROCESSING",
  SUCCESSFUL: "SUCCESSFUL",
  ERROR: "ERROR",
  CANCELLED: "CANCELLED",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED"
};
var transferStatuses = Object.values(TRANSFER_STATUSES);

// src/enums/transfers/transfer-type.enum.ts
var TRANSFER_ASSET_TYPES = {
  AGROTOKENS: "AGROTOKENS",
  WARRANTY: "WARRANTY"
};
var transferAssetTypes = Object.values(TRANSFER_ASSET_TYPES);

// src/enums/users/user-type.enum.ts
var USER_TYPES = {
  NATIVE: "NATIVE",
  MANAGER: "MANAGER"
};
var userTypes = Object.values(USER_TYPES);

// src/enums/vaults/vault-status.enum.ts
var VAULT_STATUSES = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED"
};
var vaultStatuses = Object.values(VAULT_STATUSES);

// src/enums/vaults/vault-type.enum.ts
var VAULT_TYPES = {
  ROLLED: "ROLLED",
  FUTURES: "FUTURES"
};
var vaultTypes = Object.values(VAULT_TYPES);

// src/enums/external-notifications/external-notification-status.enum.ts
var EXTERNAL_NOTIFICATION_STATUSES = {
  PENDING: "PENDING",
  DELIVERED: "DELIVERED",
  FAILED: "FAILED"
};
var externalNotificationStatuses = Object.values(EXTERNAL_NOTIFICATION_STATUSES);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AGREEMENT_TYPE,
  API_BUSSINESS_IDENTIFIER_STATUSES,
  BUSINESS_FISCAL_IDENTIFIER_STATUSES,
  BUSINESS_FISCAL_IDENTIFIER_TYPES,
  BUSINESS_REQUEST_TYPE,
  BUSINESS_WALLET_TYPES,
  CARD_STATUSES,
  CARD_STATUS_REASONS,
  CARD_TYPES,
  CONCILIATION_ADJUSTMENT_TYPES,
  CONCILIATION_DETAIL_STATUSES,
  CONCILIATION_STATUSES,
  CONCILIATION_TRANSACTION_STATUS_DETAILS,
  CPR_TYPES,
  CREDIT_STATUSES,
  CURRENCY_DETAILS_KEY_CODES,
  CURRENCY_NAMES,
  DOCUMENT_STATUSES,
  DOCUMENT_TYPES,
  EXTERNAL_NOTIFICATION_STATUSES,
  FIAT_CURRENCIES,
  GRAIN_CONTRACT_REJECTION_REASONS,
  GRAIN_CONTRACT_TONS_VERIFIER,
  GRAIN_CONTRACT_TYPES,
  GRAIN_CONTRACT_VALIDATION_STATUS,
  GRAIN_CONTRACT_VALIDATOR,
  MOVEMENT_DIRECTIONS,
  MOVEMENT_TYPES,
  OPERATION_STATUSES,
  OPERATION_TYPES,
  OVERCOLLATERAL_METHODS,
  PARAMETER_NAME,
  PAYMENT_REQUESTS_ALLOWED_FISCAL_ID_TYPES,
  PAYMENT_REQUEST_STATUSES,
  PAYMENT_REQUEST_TYPES,
  PAYMENT_REQUEST_WARRANTY_STATUSES,
  POMELO_TRANSACTION_STATUSES,
  POMELO_TRANSACTION_TYPES,
  ParameterNames,
  REFERENCE_MARKET_TYPES,
  TRANSACTION_ENTRY_TYPE,
  TRANSFER_ASSET_TYPES,
  TRANSFER_STATUSES,
  USER_TYPES,
  VALIDATION_STATUSES,
  VAULT_STATUSES,
  VAULT_TYPES,
  agreementTypes,
  apiBusinessIdentifierStatuses,
  businessFiscalIdentitierStatuses,
  businessFiscalIdentitierTypes,
  businessRequestTypes,
  businessWalletTypes,
  cardStatusReasons,
  cardStatuses,
  cardTypes,
  conciliationAdjustmentTypes,
  conciliationDetailStatuses,
  conciliationStatuses,
  conciliationTransactionStatusDetails,
  cprTypes,
  creditStatuses,
  currencyDetailsKeyCodes,
  currencyNames,
  documentStatuses,
  documentTypes,
  externalNotificationStatuses,
  fiatCurrencies,
  grainContractRejectionReasons,
  grainContractTonsVerifiers,
  grainContractTypes,
  grainContractValidationStatuses,
  grainContractValidators,
  movementDirections,
  movementTypes,
  operationStatuses,
  operationTypes,
  overcollateralMethods,
  paymentRequestStatuses,
  paymentRequestTypes,
  paymentRequestWarrantyStatuses,
  paymentRequestsAllowedFiscalIdTypes,
  pomeloTransactionStatuses,
  pomeloTransactionTypes,
  referenceMarketTypes,
  transactionEntryTypes,
  transferAssetTypes,
  transferStatuses,
  userTypes,
  validationStatuses,
  vaultStatuses,
  vaultTypes
});
