import { Heading, Text } from '@chakra-ui/react';
import { IFinalBeneficiary } from '@comp/FullOnboarding/types';
import { useEffect, useState, VFC } from 'react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import AddBeneficiary from './AddBeneficiary';
import BeneficiaryForm from './BeneficiaryForm';
import FinalBeneficiaryPreview from './FinalBeneficiaryPreview';
import { hasValues } from '@comp/FullOnboarding/common/utils';
import { useTranslation } from 'react-i18next';

const fieldsNames = [
  'name',
  'surname',
  'identificationValue',
  'maritalStatusId',
  'nationality',
  'address',
  'postalCode',
  'geographicalDivision',
  'cityId',
  'participationPercentage',
  'managementPositionId',
  'identificationTaxValue',
];

const checkIfUserTypedSomething = (data: IFinalBeneficiary): boolean => {
  return hasValues(data, ['pep']);
};

interface Props {
  canEdit: boolean;
}

const FinalBeneficiaries: VFC<Props> = ({ canEdit }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboardingLevelThree.finalBeneficiaries',
  });
  const { watch, trigger, resetField, setValue } = useFormContext();
  const beneficiaryBusinesses = watch('beneficiaryBusinesses')?.filter(
    (beneficiary: IFinalBeneficiary) =>
      beneficiary && Object.values(beneficiary).some(Boolean)
  ) as IFinalBeneficiary[];
  const hasBeneficiaryBusinesses = Boolean(beneficiaryBusinesses?.length);
  const [beneficiaryIndex, setBeneficiaryIndex] = useState<number>(0);
  const currentPrefix = `beneficiaryBusinesses[${beneficiaryIndex}]`;
  const newBeneficiaryIndex = beneficiaryBusinesses?.length || 0;
  const newBeneficiaryPrefix = `beneficiaryBusinesses[${newBeneficiaryIndex}]`;
  const currentBeneficiary = get(watch(), currentPrefix, {});
  const userHasTypedSomething = checkIfUserTypedSomething(currentBeneficiary);

  useEffect(() => {
    setValue(currentPrefix, watch(currentPrefix));
  }, [beneficiaryIndex]);

  const handleAddFinalBeneficiary = async () => {
    const fields = fieldsNames.map((name) => `${currentPrefix}.${name}`);
    const result = await trigger(fields, { shouldFocus: true });
    if (result) {
      setBeneficiaryIndex(newBeneficiaryIndex);
      const nextFields = fieldsNames.map(
        (name) => `${newBeneficiaryPrefix}.${name}`
      );
      nextFields.map((field) => resetField(field));
    }
  };

  return (
    <>
      <Heading fontSize="2xl" mt="16">
        {t('title')}
      </Heading>
      <Text>{t('description')}</Text>
      {!canEdit && (
        //TODO modify text with info component.
        <Text>{t('beneficiaryForm.cantUpdate')}</Text>
      )}
      {hasBeneficiaryBusinesses &&
        beneficiaryBusinesses.map(
          ({ name = '', surname = '' }, index: number) => (
            <FinalBeneficiaryPreview
              key={index}
              fullName={name + ' ' + surname}
              handleEdit={() => {
                setBeneficiaryIndex(index);
              }}
            />
          )
        )}
      <BeneficiaryForm
        prefix={currentPrefix}
        shouldSetFieldsRequired={userHasTypedSomething}
        canEdit={canEdit}
      />
      {canEdit && (
        <AddBeneficiary handleAddFinalBeneficiary={handleAddFinalBeneficiary} />
      )}
    </>
  );
};
export default FinalBeneficiaries;
