import { CoinsIcon } from '@agrotoken-tech/ui';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { FiFile, FiMessageSquare, FiUser } from 'react-icons/fi';
import { CryptoCurrencySymbol, EUnits } from '@common/types';
import {
  Forwarding,
  ForwardingMetadata,
} from '@common/types/payment-request-forwarding';
import { formatNumber } from '@common/utils';
import { Icon } from '@comp/Global/Icon';
import { ItemIcon } from '../../ItemIcon';
import { TokenAmount } from '../../TokenAmount';

interface PaymentRequestParametersDetailsForwardingsProps {
  units: EUnits;
  handleViewDocument: any;
  isLoadingDownloadLink: boolean;
  forwardingMetadata: ForwardingMetadata;
  isPaymentRequestCompleted?: boolean;
}

const PaymentRequestParametersDetailsForwardings = (
  props: PaymentRequestParametersDetailsForwardingsProps
) => {
  const { t } = useTranslation();
  const {
    units,
    handleViewDocument,
    isLoadingDownloadLink,
    forwardingMetadata,
    isPaymentRequestCompleted,
  } = props;

  return (
    <Box paddingLeft={12} marginTop={6}>
      <Text marginBottom={5}>
        {t('payPaymentRequest.breakdown.amountToResend')}
      </Text>
      {forwardingMetadata
        ? forwardingMetadata?.forwardings?.map((forwarding: Forwarding) => {
            return (
              <Box key={forwarding.id}>
                {/*To Business --->  Tokens | Equivalence */}
                <ItemIcon
                  icon={FiUser}
                  label={`${forwarding?.toBusinessName} - ${forwarding?.toBusinessFiscalId}`}
                  value={
                    isPaymentRequestCompleted ? (
                      <TokenAmount
                        amount={forwarding?.grossTokenAmount}
                        token={forwarding?.tokenName as CryptoCurrencySymbol}
                      />
                    ) : (
                      <Text>
                        ARS {formatNumber(forwarding?.grossAmount, units, true)}
                      </Text>
                    )
                  }
                  className="mb-4"
                />

                {/*To Business ---> Equivalence */}
                {isPaymentRequestCompleted ? (
                  <ItemIcon
                    icon={CoinsIcon as IconType}
                    label={t(
                      'payPaymentRequest.breakdown.localCurrencyEquivalent'
                    )}
                    value={`ARS${formatNumber(
                      forwarding?.grossAmount,
                      units,
                      true
                    )}`}
                    className="mb-4"
                  />
                ) : null}

                {/* Comments */}
                <ItemIcon
                  icon={FiMessageSquare}
                  label={
                    <Text> {t('payPaymentRequest.breakdown.comments')}</Text>
                  }
                  value={<Box>{forwarding.concept}</Box>}
                  className="mb-4"
                  isBold={false}
                />

                {/* File */}
                <ItemIcon
                  icon={FiFile}
                  label={t('payPaymentRequest.breakdown.file')}
                  value={
                    forwarding?.documentUuid ? (
                      <Flex className="mt-2">
                        <Icon
                          size="sm"
                          color="gray"
                          theme="no-background"
                          icon="download"
                        />
                        <Button
                          size="sm"
                          variant="linkPrimary"
                          onClick={handleViewDocument}
                          isLoading={isLoadingDownloadLink}
                        >
                          <Text fontWeight={'bold'} color="#288703">
                            {forwarding.documentOriginalName}
                          </Text>
                        </Button>
                      </Flex>
                    ) : null
                  }
                  className="mb-4"
                />
                <hr className="mt-5 mb-5" />
              </Box>
            );
          })
        : null}

      <ItemIcon
        label={t('payPaymentRequest.breakdown.totalToForward')}
        value={
          <Flex justifyContent={'space-between'}>
            <Box display={isPaymentRequestCompleted ? 'flex' : 'none'}>
              <TokenAmount
                amount={Number(forwardingMetadata?.totalTokenAmount ?? 0)}
                token={forwardingMetadata.tokenName as CryptoCurrencySymbol}
              />
              <Box> ≈ </Box>
            </Box>
            <Text>
              ARS {formatNumber(forwardingMetadata.totalAmount, units, true)}
            </Text>
          </Flex>
        }
        className="mb-4"
        isBold={false}
      />
    </Box>
  );
};

export default PaymentRequestParametersDetailsForwardings;
