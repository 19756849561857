import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { IBalance, IGetLoansBalancesResponse } from '../common/types';
import { http, httpLoans } from '../http-common';

export const getBalances = async (): Promise<IGetLoansBalancesResponse> => {
  return await httpLoans
    .get<IGetLoansBalancesResponse>(`/${PLATFORM_PREFIX}/loans`)
    .then((resp) => {
      const loans: IGetLoansBalancesResponse = resp.data;
      return loans;
    });
};

export const getBalanceByBusiness = async ({
  queryKey,
}: any): Promise<IBalance[]> => {
  const [, businessId] = queryKey;
  return await http
    .get<IBalance[]>(`/${PLATFORM_PREFIX}/balances/business/${businessId}`)
    .then((resp) => {
      const response: IBalance[] = resp.data;
      return response;
    });
};
