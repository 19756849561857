import { CoinsIcon } from '@agrotoken-tech/ui';
import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import {
  FiFile,
  FiMessageSquare,
  FiUser,
  FiHash,
  FiCopy,
} from 'react-icons/fi';
import { PaymentRequestAsset } from '@common/enum';
import {
  CryptoCurrencySymbol,
  EUnits,
  PAYMENT_REQUEST_STATUSES,
} from '@common/types';
import { formatNumber } from '@common/utils';

import { Icon } from '@comp/Global/Icon';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import {
  ItemIcon,
  PaymentLayout,
  Title,
} from '@comp/PaymentRequestView/Fragments';
import { PaymentCompleteSummary } from '@comp/PaymentRequestView/Fragments/PaymentCompleteSummary';
import PaymentRequestParameters from '@comp/PaymentRequestView/Fragments/PaymentRequestParameters';
import { TokenAmount } from '@comp/PaymentRequestView/Fragments/TokenAmount';

import { useForwarding } from './hooks/useForwarding';

const ForwardingView = () => {
  const { t } = useTranslation();

  const { isLoading, error, data: forwarding } = useForwarding();

  if (isLoading) return <Loader />;

  // TODO: Handle different error cases: Forbbiden or Not found.
  if (!forwarding || error) return <ServerError />;

  const getGeneralDataElements = (): ReactNode[] => {
    const generalDataElements: ReactNode[] = [];
    forwarding?.paymentRequestUuid &&
      generalDataElements.push(
        <ItemIcon
          icon={FiHash}
          label={t('payPaymentRequest.breakdown.transactionNumber')}
          value={
            <>
              {forwarding.paymentRequestUuid}
              <CopyToClipboard text={forwarding.paymentRequestUuid}>
                <IconButton
                  variant="link"
                  aria-label="copy"
                  icon={<FiCopy />}
                />
              </CopyToClipboard>
            </>
          }
          className="mb-4"
        />
      );

    generalDataElements.push(
      <ItemIcon
        icon={FiUser}
        label={t('payPaymentRequest.breakdown.whoForwardedThePayment')}
        value={`${forwarding?.fromBusinessName} - ${forwarding?.fromBusinessFiscalId}`}
        className="mb-4"
      />
    );

    generalDataElements.push(
      <ItemIcon
        icon={FiMessageSquare}
        label={<Text> {t('payPaymentRequest.breakdown.comments')}</Text>}
        value={<Box>{forwarding?.concept ?? ''}</Box>}
        className="mb-4"
        isBold={false}
      />
    );

    generalDataElements.push(
      <ItemIcon
        icon={FiFile}
        label={t('payPaymentRequest.breakdown.file')}
        value={
          forwarding?.documentUuid ? (
            <Flex className="mt-2">
              <Icon
                size="sm"
                color="gray"
                theme="no-background"
                icon="download"
              />
              <Button size="sm" variant="linkPrimary">
                <Text fontWeight={'bold'} color="#288703">
                  {forwarding.documentOriginalName}
                </Text>
              </Button>
            </Flex>
          ) : null
        }
        className="mb-4"
      />
    );

    return generalDataElements;
  };

  const getPaymentDataElements = (): ReactNode[] => {
    const paymentDataElements: ReactNode[] = [];

    /* To Business --->  Tokens | Equivalence */
    paymentDataElements.push(
      <ItemIcon
        icon={FiUser}
        label={`${forwarding?.toBusinessName} - ${forwarding?.toBusinessFiscalId}`}
        value={
          <TokenAmount
            amount={forwarding?.grossTokenAmount ?? 0}
            token={forwarding?.tokenName as CryptoCurrencySymbol}
          />
        }
        className="mb-4"
      />
    );

    /* To Business ---> Equivalence */
    paymentDataElements.push(
      <ItemIcon
        icon={CoinsIcon as IconType}
        label={t('payPaymentRequest.breakdown.localCurrencyEquivalent')}
        value={`${formatNumber(forwarding?.grossAmount, EUnits.ARS, true)}`}
        className="mb-4"
        isBold={false}
      />
    );

    return paymentDataElements;
  };

  return (
    <PaymentLayout
      header={
        <Title
          title={t('paymentRequestsPage.table.transactionType.regular')}
          subtitle={''}
        />
      }
      main={
        <PaymentRequestParameters
          createAtBoundOperation={Number(forwarding?.createdAt ?? 0)}
          paymentRequestStatus={PAYMENT_REQUEST_STATUSES.COMPLETED}
          createdAtPaymentRequest={Number(forwarding?.createdAt ?? 0)}
          generalDataElements={getGeneralDataElements()}
          paymentDataElements={getPaymentDataElements()}
        />
      }
      rightSide={
        forwarding?.paymentRequest ? (
          <PaymentCompleteSummary
            status={'READY'}
            paymentAsset={PaymentRequestAsset.TOKENS}
            isPayer={false}
            selectedToken={forwarding?.tokenName as CryptoCurrencySymbol}
            paymentRequest={forwarding?.paymentRequest}
            forwarding={forwarding}
          />
        ) : null
      }
    />
  );
};

export default ForwardingView;
