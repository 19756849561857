import { Box } from '@chakra-ui/react';
import { useAccess } from '@src/context/accessContext';
import { FC } from 'react';

import CanNotRequestCard from './CanNotRequestCard';
import CanRequestCard from './CanRequestCard';

interface RequestCardProps {
  setShowCardShipmentForm: React.Dispatch<React.SetStateAction<boolean>>;
}
const RequestCard: FC<RequestCardProps> = ({ setShowCardShipmentForm }) => {
  const { canRequestCard } = useAccess();

  return (
    <Box marginTop={8}>
      {canRequestCard ? <CanRequestCard /> : <CanNotRequestCard />}
    </Box>
  );
};

export default RequestCard;
