import moment from 'moment';

export type FormatDateTypes =
  | 'DD-MM-YYYY'
  | 'DD-MM-YYYY hh:mm A'
  | 'DD/MM/YY'
  | 'DD/MM/YY - HH:mm'
  | 'DD/MM/YY - HH:mm A'
  | 'DD/MM/YYYY'
  | 'DD/MM/YYYY HH:mm'
  | 'hh:mm A';

export const formatDate = (
  timestamp: number,
  format: FormatDateTypes
): string => {
  return moment.unix(timestamp).format(format);
};
