import { Button } from '@agrotoken-tech/ui';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
} from '@chakra-ui/react';
import { LOGIN_PATH } from '@common/const';
import { VFC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { passwordRegExp } from '@utils/regExp';
import agrotoken from '../../assets/logos/logo_standard.svg';
import welcomeVideo from '../../assets/videos/welcome-video.m4v';
import { useAuth } from '../../context/authContext';
import i18n from '../../translations/i18n';

const NewPasswordRequired: VFC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    watch,
  } = useForm<FieldValues>();
  const auth = useAuth();
  const password = watch('password');

  enum NewPasswordExceptionCodes {
    NotAuthorizedException = 'NotAuthorizedException',
    DefaultError = 'DefaultError',
    InvalidPasswordException = 'InvalidPasswordException',
  }

  const onSubmit = (values: FieldValues) => {
    if (values.passwordConfirmation === values.password) {
      auth
        .changePassword(values.password)
        .then(() => {
          auth.signOut();
          navigate(LOGIN_PATH);
        })
        .catch((error: any) => {
          console.log(error);
          switch (error.code) {
            case NewPasswordExceptionCodes.NotAuthorizedException:
              navigate(`/${i18n.language}/login`);
              break;
            case NewPasswordExceptionCodes.InvalidPasswordException:
              setError('newPassword', {
                message: t(
                  `newPassword.errorMessages.${NewPasswordExceptionCodes.InvalidPasswordException}`
                ),
              });
              break;
            default:
              setError('newPassword', {
                message: t(
                  `newPassword.errorMessages.${NewPasswordExceptionCodes.DefaultError}`
                ),
              });
              setError('confirmPassword', { message: '' });
              break;
          }
        });
    } else {
      setError('newPassword', {
        message: t('newPassword.errorMessages.PasswordNotMatch'),
      });
      setError('confirmPassword', { message: '' });
    }
  };

  return (
    <div className="flex h-screen">
      <div className="max-w-sm m-auto px-4 md:px-0">
        <div className="mb-8 w-full flex">
          <img
            src={agrotoken}
            alt="Logo Agrotoken"
            className="inline-block max-h-6"
          />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading mb="2">
            {t('resetPasswordVerification.newPasswordForm.title')}
          </Heading>
          <Text textColor={'gray.500'}>
            {t('resetPasswordVerification.newPasswordForm.subtitle')}
          </Text>
          <div>
            <FormControl
              mr="6"
              mt="6"
              my="6"
              isInvalid={Boolean(errors.password)}
            >
              <FormLabel htmlFor="first-name">
                {t('resetPasswordVerification.newPasswordForm.password.label')}
              </FormLabel>
              <Input
                placeholder={t(
                  'resetPasswordVerification.newPasswordForm.password.placeholder'
                )}
                id="password"
                type="password"
                borderRadius={3}
                h="12"
                w="full"
                {...register('password', {
                  required: t(
                    'resetPasswordVerification.newPasswordForm.password.errors.required'
                  ),
                  pattern: {
                    value: passwordRegExp,
                    message: t(
                      'resetPasswordVerification.newPasswordForm.password.errors.pattern'
                    ),
                  },
                })}
              />
              {errors.password && (
                <FormErrorMessage>{errors.password.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              mr="6"
              mt="5"
              isInvalid={Boolean(errors.passwordConfirmation)}
            >
              <FormLabel htmlFor="first-name">
                {t(
                  'resetPasswordVerification.newPasswordForm.passwordConfirmation.label'
                )}
              </FormLabel>
              <Input
                placeholder={t(
                  'resetPasswordVerification.newPasswordForm.passwordConfirmation.placeholder'
                )}
                id="passwordConfirmation"
                type="password"
                borderRadius={3}
                h="12"
                w="full"
                {...register('passwordConfirmation', {
                  validate: (confirmation) =>
                    confirmation === password ||
                    t(
                      'resetPasswordVerification.newPasswordForm.passwordConfirmation.errors.match'
                    ),
                })}
              />
              {errors.passwordConfirmation && (
                <FormErrorMessage>
                  {errors.passwordConfirmation.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <Button
              variant="primary"
              size="lg"
              w="full"
              mt="6"
              type="submit"
              isLoading={isSubmitting}
            >
              {t('resetPasswordVerification.newPasswordForm.submit')}
            </Button>
          </div>
        </form>
      </div>
      <div className="hidden lg:block w-1/2">
        <div className="overflow-y-hidden h-screen relative">
          <div className="absolute h-screen w-full flex items-center justify-center">
            <div className="px-6 xl:px-0 max-w-lg">
              <h1 className="display-lg font-normal text-white mb-6">
                {t('login.sideTitle')}
              </h1>
              <p className="text-white mr-8">{t('login.sideDescription')}</p>
            </div>
          </div>
          <video
            autoPlay
            muted
            loop
            className="overflow-y-hidden object-cover w-full h-screen"
          >
            <source src={welcomeVideo} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordRequired;
