import { Modal } from '@agrotoken-tech/ui';
import { BREAKPOINTS, FULL_ONBOARDING_LEVEL_3, HOME_PATH } from '@common/const';
import CongratsModal from '@comp/FullOnboarding/CongratsModal';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const Level2CompletedModal: VFC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.lvl_2.Level2CompletedModal',
  });

  const navigate = useNavigate();
  const goHome = () => navigate(HOME_PATH);
  const goNextOnboardingStep = () => navigate(FULL_ONBOARDING_LEVEL_3);

  const isMobile = window.innerWidth < BREAKPOINTS.md;

  if (isMobile) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('title')}
        text={t('description')}
        labelBtnConfirm={t('primaryAction')}
        labelBtnClose={t('secondaryAction')}
        onClickConfirm={goNextOnboardingStep}
      />
    );
  } else {
    return (
      <CongratsModal
        title={t('title')}
        size="xl"
        description={t('description')}
        primaryActionLabel={t('primaryAction')}
        secondaryActionLabel={t('secondaryAction')}
        primaryAction={goNextOnboardingStep}
        secondaryAction={goHome}
        isModalOpen={isOpen}
        handleCloseModal={onClose}
      />
    );
  }
};

export default Level2CompletedModal;
