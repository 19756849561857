import { Flex, Text } from '@chakra-ui/react';
import { TCurrencyName } from '@common/types';
import { TokenValue } from './TokenValue';

interface Props {
  label: string;
  labelDetail?: string | number;
  value: string | number;
  token: TCurrencyName;
}
export const TokenSummaryItem = ({
  label,
  labelDetail,
  token,
  value,
}: Props) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text color="gray.700" fontSize="14px">{`${label} ${
        labelDetail ? `(${labelDetail})` : ''
      }`}</Text>
      <TokenValue token={token} value={value} />
    </Flex>
  );
};
