export enum GrainContractTypes {
  REAL = 'REAL', // Normal grain contracts, created at tokenization time
  VIRTUAL = 'VIRTUAL', // Grain contracts that are not produced during tokenization, and instead have funds based on a real grain contract
  FUTURE = 'FUTURE', // Future grain contracts
  FIXED_GRAIN = 'FIXED_GRAIN', //Fixed grain contracts
}

export enum GrainContractTabs {
  AGROTOKENS = 'AGROTOKENS',
  GUARANTEES = 'GUARANTEES',
  FIXED_GRAINS = 'FIXED_GRAINS',
}

export enum GrainContractValidationBy {
  OPERATIONS = 'OPERATIONS',
  ORACLE = 'ORACLE',
}

export enum GrainContractValidationStatus {
  PENDING = 'PENDING',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}
