var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var common_utils_exports = {};
__export(common_utils_exports, {
  ADMIN_PREFIX: () => ADMIN_PREFIX,
  ARG_TOKEN_CURRENCIES: () => ARG_TOKEN_CURRENCIES,
  ARG_TOKEN_CURRENCY_SYMBOLS: () => ARG_TOKEN_CURRENCY_SYMBOLS,
  AppErrors: () => AppErrors,
  BACKOFFICE_NOTIFICATION_ID: () => BACKOFFICE_NOTIFICATION_ID,
  BACKOFFICE_PATHS: () => BACKOFFICE_PATHS,
  BACKOFFICE_REGION_PREFIXES: () => BACKOFFICE_REGION_PREFIXES,
  BACKOFFICE_URL_START: () => BACKOFFICE_URL_START,
  BLOCKCHAIN_PREFIX: () => BLOCKCHAIN_PREFIX,
  BRA_TOKEN_CURRENCIES: () => BRA_TOKEN_CURRENCIES,
  BRL_TOKEN_CURRENCY_SYMBOLS: () => BRL_TOKEN_CURRENCY_SYMBOLS,
  CURRENCIES: () => CURRENCIES,
  CURRENCY_PREFIXES: () => CURRENCY_PREFIXES,
  ENationalities: () => ENationalities,
  ERROR_CODES: () => ERROR_CODES,
  ERROR_MESSAGES: () => ERROR_MESSAGES,
  FINANCE_NOTIFICATION_ID: () => FINANCE_NOTIFICATION_ID,
  GRAIN_CONTRACT_TYPES: () => GRAIN_CONTRACT_TYPES,
  HTTP_ERROR_CODES: () => HTTP_ERROR_CODES,
  INTERNAL_PREFIX: () => INTERNAL_PREFIX,
  ISO_CURRENCIES: () => ISO_CURRENCIES,
  LANDTOKEN_PREFIX: () => LANDTOKEN_PREFIX,
  LOANS_PREFIX: () => LOANS_PREFIX,
  MOBILE_PREFIX: () => MOBILE_PREFIX,
  ORACLE_PREFIX: () => ORACLE_PREFIX,
  PERMISSION_CATEGORIES: () => PERMISSION_CATEGORIES,
  PHONE_COUNTRY_CODES: () => PHONE_COUNTRY_CODES,
  PLATFORM_PATHS: () => PLATFORM_PATHS,
  PLATFORM_PERMISSIONS: () => PLATFORM_PERMISSIONS,
  PLATFORM_PREFIX: () => PLATFORM_PREFIX,
  PRICES_PREFIX: () => PRICES_PREFIX,
  PUBLIC_PREFIX: () => PUBLIC_PREFIX,
  REFERENCE_CURRENCIES: () => REFERENCE_CURRENCIES,
  REGEX: () => regex_default,
  REGIONS: () => REGIONS,
  TOKEN_CURRENCIES: () => TOKEN_CURRENCIES,
  TOKEN_CURRENCY_SYMBOLS: () => TOKEN_CURRENCY_SYMBOLS,
  TRANSLATIONS_ES: () => TRANSLATIONS_ES,
  TRANSLATIONS_PT: () => TRANSLATIONS_PT,
  UNITS: () => UNITS,
  WARRANTY_HEALTH_CONTROL: () => WARRANTY_HEALTH_CONTROL,
  WEIGHT_UNITS: () => WEIGHT_UNITS,
  addThousandSeparators: () => addThousandSeparators,
  argTokenCurrencies: () => argTokenCurrencies,
  baseDocusignUrl: () => baseDocusignUrl,
  braTokenCurrencies: () => braTokenCurrencies,
  calculateFiatInSacas: () => calculateFiatInSacas,
  checkCnpjTypeAndLength: () => checkCnpjTypeAndLength,
  checkCpfTypeAndLength: () => checkCpfTypeAndLength,
  checkCuitTypeAndLength: () => checkCuitTypeAndLength,
  checkDniTypeAndLength: () => checkDniTypeAndLength,
  cleanupStringWithRegex: () => cleanupStringWithRegex,
  convertAmountToLocalCurrency: () => convertAmountToLocalCurrency,
  currencies: () => currencies,
  currencyPrefixes: () => currencyPrefixes,
  currencySymbols: () => currencySymbols,
  dateToTimestamp: () => dateToTimestamp,
  equivalences: () => equivalences,
  extractValues: () => extractValues,
  formatDate: () => formatDate,
  formatNumber: () => formatNumber,
  getAccountNumberFromCbu: () => getAccountNumberFromCbu,
  getAlphanumericRegex: () => getAlphanumericRegex,
  getBankEntityNumberFromCbu: () => getBankEntityNumberFromCbu,
  getDocumentTypeByRegion: () => getDocumentTypeByRegion,
  getDocusignClient: () => getDocusignClient,
  getFirstCbuBlock: () => getFirstCbuBlock,
  getGeoClient: () => getGeoClient,
  getMultiRegionMap: () => getMultiRegionMap,
  getRegionalCurrency: () => getRegionalCurrency,
  getRegionalCurrencySymbol: () => getRegionalCurrencySymbol,
  getSecondCbuBlock: () => getSecondCbuBlock,
  grainContractTypes: () => grainContractTypes,
  isAgrotokenBusiness: () => isAgrotokenBusiness,
  isLengthOk: () => isLengthOk,
  isString: () => isString,
  isoCurrencies: () => isoCurrencies,
  isoToTimestamp: () => isoToTimestamp,
  offerLetterPath: () => offerLetterPath,
  parsify: () => parsify,
  phoneCountryCodes: () => phoneCountryCodes,
  referenceCurrencies: () => referenceCurrencies,
  regions: () => regions,
  timestampToDate: () => timestampToDate,
  timestampToIso: () => timestampToIso,
  tokenCurrencies: () => tokenCurrencies,
  validateCBU: () => validateCBU,
  warrantyHealthControls: () => warrantyHealthControls,
  weightUnits: () => weightUnits
});
module.exports = __toCommonJS(common_utils_exports);

// src/country-specifics/ar/banking.ts
var isLengthOk = /* @__PURE__ */ __name((cbu) => {
  const cbuRegex = /^\d{22}$/;
  if (!cbuRegex.test(cbu)) {
    return false;
  }
  return true;
}, "isLengthOk");
var isValidAccount = /* @__PURE__ */ __name((acc) => {
  if (!acc || acc.length !== 14) {
    return false;
  }
  const [C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, C11, C12, C13, verifier] = acc.split("").map(Number);
  const sum = C1 * 3 + C2 * 9 + C3 * 7 + C4 * 1 + C5 * 3 + C6 * 9 + C7 * 7 + C8 * 1 + C9 * 3 + C10 * 9 + C11 * 7 + C12 * 1 + C13 * 3;
  const lastDigitOfSum = Number(String(sum).slice(-1));
  const diff = 10 - lastDigitOfSum;
  if (verifier !== 0 && verifier === diff) {
    return true;
  }
  if (verifier === 0 && diff === 10) {
    return true;
  }
  return false;
}, "isValidAccount");
var isValidBankCode = /* @__PURE__ */ __name((code) => {
  if (!code || code.length !== 8) {
    return false;
  }
  const [B1, B2, B3] = code.substring(0, 3).split("").map(Number);
  const [S1, S2, S3, S4, verifier] = code.substring(3).split("").map(Number);
  const sum = B1 * 7 + B2 * 1 + B3 * 3 + S1 * 9 + S2 * 7 + S3 * 1 + S4 * 3;
  const lastDigitOfSum = Number(String(sum).slice(-1));
  const diff = 10 - lastDigitOfSum;
  if (verifier !== 0 && verifier === diff) {
    return true;
  }
  if (verifier === 0 && diff === 10) {
    return true;
  }
  return false;
}, "isValidBankCode");
var validateCBU = /* @__PURE__ */ __name((cbu) => {
  if (!cbu || !cbu.substring)
    return false;
  var bankCode = cbu.substring(0, 8);
  var accountCode = cbu.substring(8);
  return isLengthOk(cbu) && isValidBankCode(bankCode) && isValidAccount(accountCode);
}, "validateCBU");
var getFirstCbuBlock = /* @__PURE__ */ __name((cbu) => {
  const firstBlock = cbu.substring(0, 8);
  const bank = firstBlock.substring(0, 3);
  const bankBranch = firstBlock.substring(3, 7);
  const checker = firstBlock.substring(7, 8);
  return {
    bank,
    bankBranch,
    checker
  };
}, "getFirstCbuBlock");
var getSecondCbuBlock = /* @__PURE__ */ __name((cbu) => {
  const secondBlock = cbu.substring(8, 22);
  const account = secondBlock.substring(0, 13);
  const checker = secondBlock.substring(13, 14);
  return {
    account,
    checker
  };
}, "getSecondCbuBlock");
var getBankEntityNumberFromCbu = /* @__PURE__ */ __name((cbu) => {
  return getFirstCbuBlock(cbu).bank;
}, "getBankEntityNumberFromCbu");
var getAccountNumberFromCbu = /* @__PURE__ */ __name((cbu) => {
  return getSecondCbuBlock(cbu).account;
}, "getAccountNumberFromCbu");

// src/country-specifics/ar/cuit.ts
var checkCuitTypeAndLength = /* @__PURE__ */ __name((cuit) => {
  const cuitRegex = /^\d{11}$/;
  return cuitRegex.test(cuit);
}, "checkCuitTypeAndLength");

// src/country-specifics/ar/dni.ts
var checkDniTypeAndLength = /* @__PURE__ */ __name((dni) => {
  const dniRegex = /^\d{7,8}$/;
  return dniRegex.test(dni);
}, "checkDniTypeAndLength");

// src/country-specifics/br/cpf.ts
var checkCpfTypeAndLength = /* @__PURE__ */ __name((cpf) => {
  const cpfRegex = /^\d{11}$/;
  return cpfRegex.test(cpf);
}, "checkCpfTypeAndLength");

// src/country-specifics/br/cnpj.ts
var checkCnpjTypeAndLength = /* @__PURE__ */ __name((cnpj) => {
  const cnpjRegex = /^\d{14}$/;
  return cnpjRegex.test(cnpj);
}, "checkCnpjTypeAndLength");

// src/currencies/calculate-fiat-in-sacas.ts
var calculateFiatInSacas = /* @__PURE__ */ __name((fiatAmount, agreementDocPrice, grainContractPriceAdjustmentPercentage) => {
  return fiatAmount.div(agreementDocPrice.minus(agreementDocPrice.mul(grainContractPriceAdjustmentPercentage != null ? grainContractPriceAdjustmentPercentage : 0).div(100))).toDecimalPlaces(2);
}, "calculateFiatInSacas");

// src/currencies/equivalences.ts
var import_enums = require("@agrotoken/core-db/enums");
var equivalences = /* @__PURE__ */ __name((amount, price, priceAdjustment, currency, rate) => {
  var _a, _b, _c;
  const _amount = (_a = amount == null ? void 0 : amount.toNumber()) != null ? _a : 0;
  const _price = (_b = price == null ? void 0 : price.toNumber()) != null ? _b : 0;
  const _priceAdjustment = (_c = priceAdjustment == null ? void 0 : priceAdjustment.toNumber()) != null ? _c : 0;
  const _rate = rate != null ? rate : 1;
  const currencies2 = {
    [import_enums.FIAT_CURRENCIES.BRL]: (value) => {
      return value * _price * (1 - _priceAdjustment / 100) * 1e3 / 60;
    },
    [import_enums.FIAT_CURRENCIES.USD]: (value) => {
      return value * _price * _rate * (1 - _priceAdjustment / 100) * 1e3 / 60;
    },
    [import_enums.FIAT_CURRENCIES.ARS]: () => {
      return 0;
    },
    default: () => {
      return 0;
    }
  };
  return (currencies2[currency] || currencies2["default"])(_amount);
}, "equivalences");

// src/enums/currencies.enum.ts
var ISO_CURRENCIES = {
  ARS: "ARS",
  BRL: "BRL"
};
var isoCurrencies = Object.keys(ISO_CURRENCIES);
var ARG_TOKEN_CURRENCIES = {
  SOYA: "SOYA",
  WHEA: "WHEA",
  CORA: "CORA"
};
var argTokenCurrencies = Object.keys(ARG_TOKEN_CURRENCIES);
var BRA_TOKEN_CURRENCIES = {
  SOYB: "SOYB",
  CORB: "CORB",
  WHEB: "WHEB",
  ABRC: "ABRC",
  COFB: "COFB"
};
var braTokenCurrencies = Object.keys(BRA_TOKEN_CURRENCIES);
var TOKEN_CURRENCIES = __spreadValues(__spreadValues({}, ARG_TOKEN_CURRENCIES), BRA_TOKEN_CURRENCIES);
var tokenCurrencies = Object.keys(TOKEN_CURRENCIES);
var CURRENCIES = __spreadValues(__spreadValues({}, TOKEN_CURRENCIES), ISO_CURRENCIES);
var currencies = Object.keys(CURRENCIES);
var ARG_TOKEN_CURRENCY_SYMBOLS = {
  SOYA: "SOYA",
  CORA: "CORA",
  WHEA: "WHEA",
  ARS: "ARS",
  USD: "USD",
  SOYA_ARS: "SOYA_ARS",
  CORA_ARS: "CORA_ARS"
};
var BRL_TOKEN_CURRENCY_SYMBOLS = {
  SOYA: "SOYA",
  CORA: "CORA",
  WHEA: "WHEA",
  BRL: "BRL",
  USD: "USD",
  ABRC: "ABRC"
};
var TOKEN_CURRENCY_SYMBOLS = __spreadValues(__spreadValues({}, ARG_TOKEN_CURRENCY_SYMBOLS), BRL_TOKEN_CURRENCY_SYMBOLS);
var currencySymbols = Object.keys(TOKEN_CURRENCY_SYMBOLS);
var CURRENCY_PREFIXES = {
  BRL_TONS: "R$",
  USD_TONS: "USD",
  ARS_TONS: "ARS$",
  ARS_KG: "ARS$/kg",
  BRL_SACAS: "R$/sc",
  USD_SACAS: "USD/sc",
  USD_KG: "USD/kg"
};
var currencyPrefixes = Object.keys(CURRENCY_PREFIXES);
var REFERENCE_CURRENCIES = {
  BRL: "BRL",
  USD: "USD",
  ARS: "ARS"
};
var referenceCurrencies = Object.keys(REFERENCE_CURRENCIES);
var WEIGHT_UNITS = {
  TONS: "tons",
  KILOGRAMS: "kg",
  GRAMS: "g",
  SACAS: "sacas"
};
var weightUnits = Object.keys(WEIGHT_UNITS);

// src/enums/grain-contract-types.ts
var GRAIN_CONTRACT_TYPES = {
  REAL: "REAL",
  VIRTUAL: "VIRTUAL",
  FUTURE: "FUTURE",
  FIXED_GRAIN: "FIXED_GRAIN"
};
var grainContractTypes = Object.values(GRAIN_CONTRACT_TYPES);

// src/enums/region.enum.ts
var REGIONS = {
  AR: "AR",
  BR: "BR"
};
var regions = Object.values(REGIONS);

// src/enums/warranties.enum.ts
var WARRANTY_HEALTH_CONTROL = {
  EXCELLENT: "1",
  BAD: "0"
};
var warrantyHealthControls = Object.keys(WARRANTY_HEALTH_CONTROL);

// src/enums/phone-country-code.enum.ts
var PHONE_COUNTRY_CODES = {
  AR: "+54",
  BR: "+55"
};
var phoneCountryCodes = Object.values(PHONE_COUNTRY_CODES);

// src/currencies/get-regional-currency.ts
function getRegionalCurrency(regionCode) {
  switch (regionCode) {
    case REGIONS.BR:
      return ISO_CURRENCIES.BRL;
    case REGIONS.AR:
    default:
      return ISO_CURRENCIES.ARS;
  }
}
__name(getRegionalCurrency, "getRegionalCurrency");
var ISO_CURRENCY_SYMBOLS = {
  [REGIONS.BR]: "R$",
  [REGIONS.AR]: ISO_CURRENCIES.ARS
};
function getRegionalCurrencySymbol(regionCode) {
  var _a;
  return (_a = ISO_CURRENCY_SYMBOLS[regionCode]) != null ? _a : ISO_CURRENCY_SYMBOLS.AR;
}
__name(getRegionalCurrencySymbol, "getRegionalCurrencySymbol");

// src/currencies/convert-amount-to-local-currency.ts
var convertAmountToLocalCurrency = /* @__PURE__ */ __name((amount, localCurrencyRate) => {
  return amount.mul(localCurrencyRate).toDecimalPlaces(2);
}, "convertAmountToLocalCurrency");

// src/documents/get-document-type-by-region.ts
var getDocumentTypeByRegion = /* @__PURE__ */ __name((region) => {
  switch (region) {
    case REGIONS.BR:
      return "CPF/CNPJ";
    case REGIONS.AR:
    default:
      return "CUIT";
  }
}, "getDocumentTypeByRegion");

// src/paths/platform.paths.ts
var PLATFORM_PATHS = {
  PAYMENT_REQUESTS: "/payment-requests"
};

// src/paths/backoffice.paths.ts
var BACKOFFICE_PATHS = {
  TOKENIZATIONS: "/tokenizations",
  PAYMENT_REQUESTS: {
    INTERNAL_LINK: (uuid) => `/transactions/${uuid}`
  }
};
var BACKOFFICE_REGION_PREFIXES = {
  [REGIONS.AR]: "/es",
  [REGIONS.BR]: "/pt"
};

// src/paths/index.ts
var BACKOFFICE_URL_START = "/es/admin";

// src/permissions/platform.permissions.ts
var PLATFORM_PERMISSIONS = {
  VIEW_WALLET: "VIEW_WALLET",
  MAKE_TOKENIZATIONS_AND_DETOKENIZATIONS: "MAKE_TOKENIZATIONS_AND_DETOKENIZATIONS",
  CREATE_PAYMENT_LINKS: "CREATE_PAYMENT_LINKS",
  MAKE_PAYMENTS_IN_KIND: "MAKE_PAYMENTS_IN_KIND",
  MAKE_TRANSACTIONS: "MAKE_TRANSACTIONS",
  CREATE_INVITATION_LINKS: "CREATE_INVITATION_LINKS",
  REMOVE_USERS: "REMOVE_USERS",
  MANAGE_PERMISSIONS: "MANAGE_PERMISSIONS",
  REQUEST_CARDS: "REQUEST_CARDS",
  MANAGE_ACCOUNTS: "MANAGE_ACCOUNTS",
  ADD_USERS_TO_BRANCH_OFFICES: "ADD_USERS_TO_BRANCH_OFFICES",
  HOME_XP: "HOME_XP",
  MAKE_EXTERNAL_EXCHANGES: "MAKE_EXTERNAL_EXCHANGES",
  REQUEST_LOANS: "REQUEST_LOANS",
  REGISTER_BRANCHES: "REGISTER_BRANCHES"
};

// src/permissions/permission.categories.ts
var PERMISSION_CATEGORIES = {
  VIEW_WALLET: "VIEW_WALLET",
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION: "MAKE_TOKENIZATIONS_AND_DETOKENIZATION",
  CREATE_PAYMENT_LINK: "CREATE_PAYMENT_LINK",
  PAYMENTS: "PAYMENTS",
  ADMINISTRATOR: "ADMINISTRATOR",
  SUPER_ADMIN: "SUPER_ADMIN",
  OTHER: "OTHER",
  TOKENIZATION_MANAGER: "TOKENIZATION_MANAGER"
};

// src/notifications/constants.ts
var BACKOFFICE_NOTIFICATION_ID = "backoffice-account";
var FINANCE_NOTIFICATION_ID = "finance-account";

// src/numbers/units.enum.ts
var UNITS = {
  FIAT: "FIAT",
  ARS: "ARS",
  BRL: "BRL",
  USD: "USD",
  TONS: "TONS",
  CRYPTO: "CRYPTO",
  PARAM: "PARAM",
  SACAS: "SACAS",
  HECTARES: "HECTARES"
};

// src/numbers/format.ts
var addThousandSeparators = /* @__PURE__ */ __name((str) => {
  return str.toString().replace(/\./g, ",").replace(new RegExp("\\B(?<!\\,\\d*)(?=(\\d{3})+(?!\\d))", "g"), ".");
}, "addThousandSeparators");
var parsify = /* @__PURE__ */ __name((str) => {
  var _a;
  const withoutThousands = str.replace(/\./g, "");
  const withDecimalPoint = (_a = withoutThousands.replace(/\,/g, ".")) != null ? _a : withoutThousands;
  return withDecimalPoint;
}, "parsify");
var formatNumber = /* @__PURE__ */ __name((value, unit, showCurrencyLabel = false) => {
  let valueToFormat = value;
  if (!value)
    valueToFormat = 0;
  let locale = process.env.DEFAULT_LOCALE_STRING || "es-AR";
  if (unit === UNITS.BRL) {
    locale = "pt-BR";
  }
  const maximumFractionDigits = {
    [UNITS.FIAT]: 2,
    [UNITS.ARS]: 2,
    [UNITS.BRL]: 2,
    [UNITS.USD]: 2,
    [UNITS.TONS]: 4,
    [UNITS.CRYPTO]: 4,
    [UNITS.PARAM]: 2,
    [UNITS.SACAS]: 2,
    [UNITS.FIAT]: 2,
    [UNITS.HECTARES]: 2
  };
  const minimumFractionDigits = {
    [UNITS.FIAT]: 2,
    [UNITS.ARS]: 2,
    [UNITS.BRL]: 2,
    [UNITS.USD]: 2,
    [UNITS.TONS]: 2,
    [UNITS.CRYPTO]: 4,
    [UNITS.PARAM]: 0,
    [UNITS.SACAS]: 2,
    [UNITS.FIAT]: 2,
    [UNITS.HECTARES]: 2
  };
  let result;
  if (showCurrencyLabel && unit !== UNITS.SACAS) {
    result = Number(valueToFormat).toLocaleString(locale, {
      maximumFractionDigits: maximumFractionDigits[unit],
      minimumFractionDigits: minimumFractionDigits[unit],
      style: "currency",
      currency: unit
    });
  } else {
    result = Number(valueToFormat).toLocaleString(locale, {
      maximumFractionDigits: maximumFractionDigits[unit],
      minimumFractionDigits: minimumFractionDigits[unit]
    });
  }
  if (showCurrencyLabel && unit === UNITS.SACAS) {
    result += " SACAS";
  }
  if (showCurrencyLabel && unit === UNITS.HECTARES) {
    result += " HECTARES";
  }
  return result;
}, "formatNumber");

// src/services/geo/geo.ts
var getGeoClient = /* @__PURE__ */ __name((http) => {
  const getCountries = /* @__PURE__ */ __name(async () => {
    const result = await http.get("/countries");
    return result.data;
  }, "getCountries");
  const getCities = /* @__PURE__ */ __name(async (geographicalDivisionId) => {
    const result = await http.get(`/cities?geographical_division_id=${geographicalDivisionId}`);
    return result.data;
  }, "getCities");
  const getGeographicalDivisions = /* @__PURE__ */ __name(async (countryId) => {
    const result = await http.get(`/geographical-divisions`, __spreadValues({}, countryId && {
      params: {
        countryId
      }
    }));
    return result.data;
  }, "getGeographicalDivisions");
  const getLocations = /* @__PURE__ */ __name(async (cityId) => {
    const result = await http.get(
      // 		`/locations?city_id=${cityId}`
      "/locations"
    );
    return result.data;
  }, "getLocations");
  return {
    getCountries,
    getCities,
    getGeographicalDivisions,
    getLocations
  };
}, "getGeoClient");

// src/services/docusign/docusign.ts
var baseDocusignUrl = "/docusign/";
var offerLetterPath = "offer-letter/";
var getDocusignClient = /* @__PURE__ */ __name((http) => {
  const viewAndSignDocusign = /* @__PURE__ */ __name((data) => {
    return http.post(`${baseDocusignUrl}${data.docusignId}`, data.body).then((resp) => {
      const dataResult = resp.data;
      return dataResult;
    });
  }, "viewAndSignDocusign");
  const viewDocusign = /* @__PURE__ */ __name((data, id) => {
    return http.post(`${baseDocusignUrl}${id}`, data).then((resp) => {
      const dataResult = resp.data;
      return dataResult;
    });
  }, "viewDocusign");
  const createOfferLetter = /* @__PURE__ */ __name((data) => {
    return http.post(`${baseDocusignUrl}${offerLetterPath}`, data).then((resp) => {
      const dataResult = resp.data;
      return dataResult;
    });
  }, "createOfferLetter");
  return {
    viewDocusign,
    createOfferLetter,
    viewAndSignDocusign
  };
}, "getDocusignClient");

// src/time/format.ts
var import_moment = __toESM(require("moment"));
var formatDate = /* @__PURE__ */ __name((date, format = "DD/MM/YYYY") => {
  if (typeof date !== "number" && typeof date !== "string") {
    return (0, import_moment.default)(date).format(format);
  }
  const intDate = Number(date);
  if (Number.isNaN(intDate)) {
    throw new Error("Date cannot be NaN");
  }
  return import_moment.default.unix(intDate).format(format);
}, "formatDate");

// src/time/conversion.ts
var dateToTimestamp = /* @__PURE__ */ __name((date) => {
  return (date != null ? date : /* @__PURE__ */ new Date()).getTime() / 1e3;
}, "dateToTimestamp");
var isoToTimestamp = /* @__PURE__ */ __name((isoString) => {
  return (isoString ? new Date(isoString) : /* @__PURE__ */ new Date()).getTime() / 1e3;
}, "isoToTimestamp");
var timestampToDate = /* @__PURE__ */ __name((timestamp, isInSeconds = true) => {
  return new Date(timestamp * (isInSeconds ? 1e3 : 1));
}, "timestampToDate");
var timestampToIso = /* @__PURE__ */ __name((timestamp, isInSeconds = true) => {
  return timestampToDate(timestamp, isInSeconds).toISOString();
}, "timestampToIso");

// src/types/functions.ts
function isString(value) {
  return typeof value === "string";
}
__name(isString, "isString");
function extractValues(obj) {
  let values = [];
  Object.entries(obj).forEach(([_, value]) => {
    if (isString(value)) {
      values.push(value);
    } else if (typeof value === "object" && value !== null) {
      values = values.concat(extractValues(value));
    }
  });
  return values;
}
__name(extractValues, "extractValues");

// src/multi-region/ENationalities.ts
var ENationalities;
(function(ENationalities2) {
  ENationalities2["AR"] = "AR";
  ENationalities2["BR"] = "BR";
})(ENationalities || (ENationalities = {}));

// src/multi-region/getMultiRegionMap.ts
function getMultiRegionMap(mapRegionToValue) {
  const handler = {
    get: (target, name) => {
      const value = target[name];
      if (typeof value !== "undefined") {
        return value;
      }
      throw new Error(`Property "${name}" does not exist on mapRegionToValue keys: ${JSON.stringify(Object.keys(target))}`);
    }
  };
  const mappedProxy = new Proxy(mapRegionToValue, handler);
  return mappedProxy;
}
__name(getMultiRegionMap, "getMultiRegionMap");

// src/prefixs/index.ts
var LOANS_PREFIX = "loans";
var ADMIN_PREFIX = "admin";
var PLATFORM_PREFIX = "platform";
var MOBILE_PREFIX = "mobile";
var ORACLE_PREFIX = "oracle";
var BLOCKCHAIN_PREFIX = "blockchain";
var PRICES_PREFIX = "prices";
var PUBLIC_PREFIX = "pub";
var INTERNAL_PREFIX = "internal";
var LANDTOKEN_PREFIX = "landtoken";

// src/validators/regex.ts
var getAlphanumericRegex = /* @__PURE__ */ __name((allowedExtra = []) => {
  return `^[A-Za-z\xC0-\xFF0-9${allowedExtra.join("")}]+$`;
}, "getAlphanumericRegex");
var cleanupStringWithRegex = /* @__PURE__ */ __name((str, regex, options) => {
  if (!str) {
    return str;
  }
  let result = str.replace(regex, "");
  const { trimEnds = true, clearMultipleSpaces = true } = options != null ? options : {};
  if (trimEnds) {
    result = result.trim();
  }
  if (clearMultipleSpaces) {
    result = result.replace(REGEX.MULTIPLE_SPACES, " ");
  }
  return result;
}, "cleanupStringWithRegex");
var REGEX = {
  // Used to check if string matches the regex. Matches only the full valid regex.
  ALLOW_EMPTY: "^$",
  TWO_WORDS: "^[a-zA-Z\xC0-\xFF\xE1\xE9\xED\xF3\xFA\xF1\xC1\xC9\xCD\xD3\xDA\xD1]{3,40}( [a-zA-Z\xE1\xE9\xED\xF3\xFA\xF1\xC1\xC9\xCD\xD3\xDA\xD1]{2,40})+$",
  ALPHANUMERIC: `^[A-Za-z\xC0-\xFF\xC0-\xFF0-9]+$`,
  NUMERIC: "^[0-9]+$",
  MULTIPLE_SPACES: /\s{2,}/g,
  ADDRESS: {
    DEFAULT: `^[A-Za-z\xC0-\xFF\xC0-\xFF0-9 .,-]+$`,
    CEP: "^[0-9]{8}$",
    STREET_NAME: `^[A-Za-z\xC0-\xFF\xC0-\xFF0-9 .,\xBA'#:-]+$`
  },
  IMAGE_URL: "^https?://.+.(jpg|jpeg|png)$",
  // Used to cleanup/replace characters in strings. Matches anything NOT in the regex.
  NEGATED: {
    ALPHA_SPACE: /([^a-zA-ZáéíóúñÁÉÍÓÚÑ ])/gi,
    ALPHANUMERIC: /([^a-z0-9À-ÿ])/gi,
    NUMERIC: /([^0-9])/g,
    ADDRESS: {
      DEFAULT: /([^a-z0-9À-ÿ .,-])/gi,
      STREET_NAME: /([^a-z0-9À-ÿ .,º'#:-])/gi
    }
  }
};
var regex_default = REGEX;

// src/errors/error-code.enum.ts
var ERROR_CODES = {
  // TODO: Collect these generic error codes under the key `GENERIC/COMMON`.
  // 400
  VALIDATION_ERROR: "VALIDATION_ERROR",
  // 401
  MISSING_CREDENTIALS: "MISSING_CREDENTIALS",
  INVALID_ACCESS_TOKEN: "INVALID_ACCESS_TOKEN",
  // 403
  MISSING_SCOPES: "MISSING_SCOPES",
  MISSING_PERMISSIONS: "MISSING_PERMISSIONS",
  FORBIDDEN_RESOURCE: "FORBIDDEN_RESOURCE",
  // 404
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  // 409
  IDEMPOTENCY_FAILED: "IDEMPOTENCY_FAILED",
  // 422
  MEDIA_UPLOAD_FAILED: "MEDIA_UPLOAD_FAILED",
  MALFORMED_REQUEST: "MALFORMED_REQUEST",
  PROCESSING_ERROR: "PROCESSING_ERROR",
  NOT_YET_SUPPORTED: "NOT_YET_SUPPORTED",
  // 427
  INVALID_VERSION: "INVALID_VERSION",
  // 500
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  // Error codes for specific use cases
  CARD: {
    PHYSICAL: {
      SHIPPING_ADDRESS_NOT_PROVIDED: "CARD_PHYSICAL_SHIPPING_ADDRESS_NOT_PROVIDED",
      CREATE_SHIPPING_ADDRESS_FAILED: "CARD_PHYSICAL_CREATE_SHIPPING_ADDRESS_FAILED",
      MAX_LIMIT_REACHED: "CARD_PHYSICAL_MAX_LIMIT_REACHED"
    },
    MISSING_PERMISSIONS: "CARD_MISSING_PERMISSIONS"
  },
  PAYMENT_FACILITATOR: {
    USER: {
      INVALID_ADDRESS: "PAYMENT_FACILITATOR_USER_INVALID_ADDRESS",
      MISSING_IDENTIFICATION: "PAYMENT_FACILITATOR_USER_MISSING_IDENTIFICATION",
      INTERNAL_SERVER_ERROR: "PAYMENT_FACILITATOR_USER_INTERNAL_SERVER_ERROR",
      VALIDATION_ERROR: "PAYMENT_FACILITATOR_USER_VALIDATION_ERROR"
    }
  },
  TOKENIZATIONS: {
    VIRTUAL: {
      RELEASE: {
        INVALID_TYPE: "TOKENIZATIONS__VIRTUAL__RELEASE__INVALID_TYPE",
        INVALID_CURRENCY: "TOKENIZATIONS__VIRTUAL__RELEASE__INVALID_CURRENCY",
        INVALID_AMOUNT: "TOKENIZATIONS__VIRTUAL__RELEASE__INVALID_AMOUNT",
        INVALID_REGION: "TOKENIZATIONS__VIRTUAL__RELEASE__INVALID_REGION"
      }
    }
  }
};
var HTTP_ERROR_CODES = {
  // 400 codes
  [ERROR_CODES.VALIDATION_ERROR]: 400,
  [ERROR_CODES.MISSING_CREDENTIALS]: 401,
  [ERROR_CODES.INVALID_ACCESS_TOKEN]: 401,
  [ERROR_CODES.MISSING_SCOPES]: 403,
  [ERROR_CODES.MISSING_PERMISSIONS]: 403,
  [ERROR_CODES.FORBIDDEN_RESOURCE]: 403,
  [ERROR_CODES.RECORD_NOT_FOUND]: 403,
  [ERROR_CODES.MEDIA_UPLOAD_FAILED]: 422,
  [ERROR_CODES.MALFORMED_REQUEST]: 422,
  [ERROR_CODES.PROCESSING_ERROR]: 422,
  [ERROR_CODES.NOT_YET_SUPPORTED]: 422,
  [ERROR_CODES.IDEMPOTENCY_FAILED]: 409,
  [ERROR_CODES.INVALID_VERSION]: 427,
  // 500 codes
  [ERROR_CODES.INTERNAL_SERVER_ERROR]: 500
};

// src/errors/error-messages.ts
var ERROR_MESSAGES = {
  GENERAL: {
    VALIDATION_ERROR: "Invalid payload."
  },
  // Idempotency
  IDEMPOTENCY_FAILED: "Idempotency key already used.",
  // Operation builder
  INVALID_OPERATION_TYPE: "Invalid operation type.",
  INVALID_INTERNAL_TRANSACTION_ID: "Invalid transaction ID in operation builder.",
  // General
  MALFORMED_REQUEST: "Malformed request data.",
  INTERNAL_SERVER_ERROR: "Internal server error.",
  AUTH: {
    MISSING_BUSINESS_ID: "No business provided in the request.",
    MISSING_REQUEST_BUSINESS_FISCAL_ID: "No fiscal identifier found for the requested business ID.",
    FORBIDDEN_RESOURCE: "Access to the requested resource denied.",
    INVALID_ACCESS_TOKEN: "Invalid or expired access token."
  },
  PAYMENT_REQUESTS: {
    PAYER_NOT_FOUND: "Required payer fiscal ID not found.",
    SAME_PAYER_AND_RECEIVER: "Payer and receiver of a payment request cannot be the same business.",
    FORWARDINGS_NOT_AVAILABLE_FOR_TYPE: "Forwardings not available for specified payment request type.",
    TOO_MANY_FORWARDINGS: "Amount of forwardings surpasses the maximum allowed amount.",
    NON_UNIQUE_FORWARDING_RECIPIENTS: "Forwarding recipients must all be distinct.",
    NO_SELF_FORWARDING: "Forwarding to the original receiver business is now allowed.",
    FORWARDING_TO_NON_EXISTENT_BUSINESS: "Forwarding requested to a non existing business.",
    FORWARDING_TO_NON_TRANSACTIONABLE_BUSINESS: "Forwarding requested to a non transactionable business.",
    FORWARDINGS_EXCEED_AMOUNT: "Forwarded amount exceeds the payment request amount.",
    FORWARDING_NON_POSITIVE_AMOUNT: "Forwarded amount must be positive."
  },
  TOKENIZATIONS: {
    VIRTUAL: {
      RELEASE: {
        INVALID_TYPE: "Invalid grain contract type, expected virtual.",
        INVALID_CURRENCY: "Invalid grain contract currency, behaviour disabled.",
        INVALID_AMOUNT: "Grain contract amount must be positive.",
        INVALID_REGION: "Only enabled for Argentina."
      }
    }
  },
  BUSINESS_CAPABILITIES: {
    INVALID_CAPABILITIES: "The provided capabilies are invalid.",
    NO_CAPABILITIES_REQUESTED: "No capabilities were requested to be checked.",
    BUSINESS_NOT_FOUND: "Business fiscal ID not found."
  }
};

// src/errors/errors.ts
var AppErrors;
(function(AppErrors2) {
  AppErrors2["UserNotFound"] = "UserNotFoundException";
  AppErrors2["InvalidInput"] = "InvalidInputException";
  AppErrors2["DuplicateRecord"] = "DuplicateRecordException";
  AppErrors2["AuthenticationFailed"] = "AuthenticationFailedException";
})(AppErrors || (AppErrors = {}));

// src/business/business-utils.ts
var isAgrotokenBusiness = /* @__PURE__ */ __name((businessId) => {
  return businessId === -1;
}, "isAgrotokenBusiness");

// src/translations/es/partials/countries.ts
var countries = {
  AF: "Afganist\xE1n",
  AL: "Albania",
  DE: "Alemania",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguila",
  AG: "Antigua y Barbuda",
  SA: "Arabia Saudita",
  DZ: "Argelia",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaiy\xE1n",
  BS: "Bahamas",
  BD: "Bangladesh",
  BB: "Barbados",
  BH: "Bar\xE9in",
  BE: "B\xE9lgica",
  BZ: "Belice",
  BJ: "Ben\xEDn",
  BY: "Bielorrusia",
  BO: "Bolivia",
  BA: "Bosnia y Herzegovina",
  BW: "Botsuana",
  BR: "Brasil",
  BN: "Brun\xE9i",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  BT: "But\xE1n",
  CV: "Cabo Verde",
  KH: "Camboya",
  CM: "Camer\xFAn",
  CA: "Canad\xE1",
  TD: "Chad",
  CZ: "Rep\xFAblica Checa",
  CL: "Chile",
  CN: "China",
  CY: "Chipre",
  CO: "Colombia",
  KM: "Comoras",
  CG: "Congo (Congo-Brazzaville)",
  KP: "Corea del Norte",
  KR: "Corea del Sur",
  CI: "Costa de Marfil",
  CR: "Costa Rica",
  HR: "Croacia",
  CU: "Cuba",
  CW: "Curazao",
  DK: "Dinamarca",
  DM: "Dominica",
  EC: "Ecuador",
  EG: "Egipto",
  SV: "El Salvador",
  AE: "Emiratos \xC1rabes Unidos",
  ER: "Eritrea",
  SK: "Eslovaquia",
  SI: "Eslovenia",
  ES: "Espa\xF1a",
  PS: "Palestina",
  US: "Estados Unidos",
  EE: "Estonia",
  ET: "Etiop\xEDa",
  PH: "Filipinas",
  FI: "Finlandia",
  FJ: "Fiyi",
  FR: "Francia",
  GA: "Gab\xF3n",
  GM: "Gambia",
  GE: "Georgia",
  GH: "Ghana",
  GI: "Gibraltar",
  GD: "Granada",
  GR: "Grecia",
  GU: "Guam",
  GT: "Guatemala",
  GY: "Guyana",
  GN: "Guinea",
  GQ: "Guinea Ecuatorial",
  GW: "Guinea-Bis\xE1u",
  HT: "Hait\xED",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungr\xEDa",
  IN: "India",
  ID: "Indonesia",
  IQ: "Irak",
  IR: "Ir\xE1n",
  IE: "Irlanda",
  CK: "Islas Cook",
  IM: "Isla de Man",
  IS: "Islandia",
  BM: "Bermudas",
  KY: "Islas Caim\xE1n",
  MH: "Islas Marshall",
  SB: "Islas Salom\xF3n",
  TC: "Islas Turcas y Caicos",
  VI: "Islas V\xEDrgenes de EE. UU.",
  VG: "Islas V\xEDrgenes del Reino Unido",
  IL: "Israel",
  IT: "Italia",
  JM: "Jamaica",
  JP: "Jap\xF3n",
  JO: "Jordania",
  QA: "Catar",
  KZ: "Kazajist\xE1n",
  KE: "Kenia",
  KG: "Kirguist\xE1n",
  XK: "Kosovo",
  KW: "Kuwait",
  LA: "Laos",
  LS: "Lesoto",
  LV: "Letonia",
  LB: "L\xEDbano",
  LR: "Liberia",
  LY: "Libia",
  LI: "Liechtenstein",
  LT: "Lituania",
  LU: "Luxemburgo",
  MO: "Macao",
  MK: "Macedonia del Norte",
  MG: "Madagascar",
  MY: "Malasia",
  MW: "Malaui",
  MV: "Maldivas",
  ML: "Mal\xED",
  MT: "Malta",
  MA: "Marruecos",
  MU: "Mauricio",
  MR: "Mauritania",
  MX: "M\xE9xico",
  FM: "Micronesia",
  MD: "Moldavia",
  MC: "M\xF3naco",
  MN: "Mongolia",
  ME: "Montenegro",
  MZ: "Mozambique",
  MM: "Myanmar (Birmania)",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NI: "Nicaragua",
  NE: "N\xEDger",
  NG: "Nigeria",
  NU: "Niue",
  NO: "Noruega",
  NZ: "Nueva Zelanda",
  OM: "Om\xE1n",
  NL: "Pa\xEDses Bajos",
  PK: "Pakist\xE1n",
  PW: "Palaos",
  PA: "Panam\xE1",
  PG: "Pap\xFAa Nueva Guinea",
  PY: "Paraguay",
  PE: "Per\xFA",
  PL: "Polonia",
  PT: "Portugal",
  PR: "Puerto Rico",
  GB: "Reino Unido",
  CF: "Rep\xFAblica Centroafricana",
  CD: "Rep\xFAblica Democr\xE1tica del Congo",
  DO: "Rep\xFAblica Dominicana",
  RW: "Ruanda",
  RO: "Ruman\xEDa",
  RU: "Rusia",
  WS: "Samoa",
  AS: "Samoa Americana",
  BL: "San Bartolom\xE9",
  KN: "San Crist\xF3bal y Nieves",
  SM: "San Marino",
  MF: "San Mart\xEDn",
  PM: "San Pedro y Miquel\xF3n",
  VC: "San Vicente y las Granadinas",
  SH: "Santa Elena, Ascensi\xF3n y Trist\xE1n de Acu\xF1a",
  LC: "Santa Luc\xEDa",
  ST: "Santo Tom\xE9 y Pr\xEDncipe",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leona",
  SG: "Singapur",
  SX: "Sint Maarten",
  SY: "Siria",
  SO: "Somalia",
  LK: "Sri Lanka",
  SZ: "Suazilandia",
  ZA: "Sud\xE1frica",
  SD: "Sud\xE1n",
  SS: "Sud\xE1n del Sur",
  SE: "Suecia",
  CH: "Suiza",
  SR: "Surinam",
  PF: "Tahit\xED",
  TH: "Tailandia",
  TW: "Taiw\xE1n",
  TZ: "Tanzania",
  TJ: "Tayikist\xE1n",
  TL: "Timor Oriental",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad y Tobago",
  TN: "T\xFAnez",
  TM: "Turkmenist\xE1n",
  TR: "Turqu\xEDa",
  TV: "Tuvalu",
  UA: "Ucrania",
  UG: "Uganda",
  UY: "Uruguay",
  UZ: "Uzbekist\xE1n",
  VU: "Vanuatu",
  VA: "Ciudad del Vaticano",
  VE: "Venezuela",
  VN: "Vietnam",
  WF: "Wallis y Futuna",
  YE: "Yemen",
  DJ: "Yibuti",
  ZM: "Zambia",
  ZW: "Zimbabue"
};

// src/translations/es/index.ts
var TRANSLATIONS_ES = {
  countries
};

// src/translations/pt/partials/countries.ts
var countries2 = {
  AF: "Afeganist\xE3o",
  AL: "Alb\xE2nia",
  DE: "Alemanha",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AG: "Ant\xEDgua e Barbuda",
  SA: "Ar\xE1bia Saudita",
  DZ: "Arg\xE9lia",
  AR: "Argentina",
  AM: "Arm\xEAnia",
  AW: "Aruba",
  AU: "Austr\xE1lia",
  AT: "\xC1ustria",
  AZ: "Azerbaij\xE3o",
  BS: "Bahamas",
  BD: "Bangladesh",
  BB: "Barbados",
  BH: "Bahrein",
  BE: "B\xE9lgica",
  BZ: "Belize",
  BJ: "Benin",
  BY: "Bielorr\xFAssia",
  BO: "Bol\xEDvia",
  BA: "B\xF3snia e Herzegovina",
  BW: "Botsuana",
  BR: "Brasil",
  BN: "Brunei",
  BG: "Bulg\xE1ria",
  BF: "Burkina Faso",
  BI: "Burundi",
  BT: "But\xE3o",
  CV: "Cabo Verde",
  KH: "Camboja",
  CM: "Camar\xF5es",
  CA: "Canad\xE1",
  TD: "Chade",
  CZ: "Rep\xFAblica Tcheca",
  CL: "Chile",
  CN: "China",
  CY: "Chipre",
  CO: "Col\xF4mbia",
  KM: "Comores",
  CG: "Congo (Congo-Brazzaville)",
  KP: "Coreia do Norte",
  KR: "Coreia do Sul",
  CI: "Costa do Marfim",
  CR: "Costa Rica",
  HR: "Cro\xE1cia",
  CU: "Cuba",
  CW: "Cura\xE7ao",
  DK: "Dinamarca",
  DM: "Dominica",
  EC: "Equador",
  EG: "Egito",
  SV: "El Salvador",
  AE: "Emirados \xC1rabes Unidos",
  ER: "Eritreia",
  SK: "Eslov\xE1quia",
  SI: "Eslov\xEAnia",
  ES: "Espanha",
  PS: "Palestina",
  US: "Estados Unidos",
  EE: "Est\xF4nia",
  ET: "Eti\xF3pia",
  PH: "Filipinas",
  FI: "Finl\xE2ndia",
  FJ: "Fiji",
  FR: "Fran\xE7a",
  GA: "Gab\xE3o",
  GM: "G\xE2mbia",
  GE: "Ge\xF3rgia",
  GH: "Gana",
  GI: "Gibraltar",
  GD: "Granada",
  GR: "Gr\xE9cia",
  GU: "Guam",
  GT: "Guatemala",
  GY: "Guiana",
  GN: "Guin\xE9",
  GQ: "Guin\xE9 Equatorial",
  GW: "Guin\xE9-Bissau",
  HT: "Haiti",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungria",
  IN: "\xCDndia",
  ID: "Indon\xE9sia",
  IQ: "Iraque",
  IR: "Ir\xE3",
  IE: "Irlanda",
  CK: "Ilhas Cook",
  IM: "Ilha de Man",
  IS: "Isl\xE2ndia",
  BM: "Bermudas",
  KY: "Ilhas Cayman",
  MH: "Ilhas Marshall",
  SB: "Ilhas Salom\xE3o",
  TC: "Ilhas Turcas e Caicos",
  VI: "Ilhas Virgens (EUA)",
  VG: "Ilhas Virgens (Reino Unido)",
  IL: "Israel",
  IT: "It\xE1lia",
  JM: "Jamaica",
  JP: "Jap\xE3o",
  JO: "Jord\xE2nia",
  QA: "Catar",
  KZ: "Cazaquist\xE3o",
  KE: "Qu\xEAnia",
  KG: "Quirguist\xE3o",
  XK: "Kosovo",
  KW: "Kuwait",
  LA: "Laos",
  LS: "Lesoto",
  LV: "Let\xF4nia",
  LB: "L\xEDbano",
  LR: "Lib\xE9ria",
  LY: "L\xEDbia",
  LI: "Liechtenstein",
  LT: "Litu\xE2nia",
  LU: "Luxemburgo",
  MO: "Macau",
  MK: "Maced\xF4nia do Norte",
  MG: "Madagascar",
  MY: "Mal\xE1sia",
  MW: "Malawi",
  MV: "Maldivas",
  ML: "Mali",
  MT: "Malta",
  MA: "Marrocos",
  MU: "Maur\xEDcio",
  MR: "Maurit\xE2nia",
  MX: "M\xE9xico",
  FM: "Micron\xE9sia",
  MD: "Mold\xE1via",
  MC: "M\xF4naco",
  MN: "Mong\xF3lia",
  ME: "Montenegro",
  MZ: "Mo\xE7ambique",
  MM: "Mianmar (Birm\xE2nia)",
  NA: "Nam\xEDbia",
  NR: "Nauru",
  NP: "Nepal",
  NI: "Nicar\xE1gua",
  NE: "N\xEDger",
  NG: "Nig\xE9ria",
  NU: "Niue",
  NO: "Noruega",
  NZ: "Nova Zel\xE2ndia",
  OM: "Om\xE3",
  NL: "Pa\xEDses Baixos",
  PK: "Paquist\xE3o",
  PW: "Palau",
  PA: "Panam\xE1",
  PG: "Papua-Nova Guin\xE9",
  PY: "Paraguai",
  PE: "Peru",
  PL: "Pol\xF4nia",
  PT: "Portugal",
  PR: "Porto Rico",
  GB: "Reino Unido",
  CF: "Rep\xFAblica Centro-Africana",
  CD: "Rep\xFAblica Democr\xE1tica do Congo",
  DO: "Rep\xFAblica Dominicana",
  RW: "Ruanda",
  RO: "Rom\xEAnia",
  RU: "R\xFAssia",
  WS: "Samoa",
  AS: "Samoa Americana",
  BL: "S\xE3o Bartolomeu",
  KN: "S\xE3o Crist\xF3v\xE3o e Nevis",
  SM: "San Marino",
  MF: "S\xE3o Martinho",
  PM: "S\xE3o Pedro e Miquelon",
  VC: "S\xE3o Vicente e Granadinas",
  SH: "Santa Helena, Ascens\xE3o e Trist\xE3o da Cunha",
  LC: "Santa L\xFAcia",
  ST: "S\xE3o Tom\xE9 e Pr\xEDncipe",
  SN: "Senegal",
  RS: "S\xE9rvia",
  SC: "Seychelles",
  SL: "Serra Leoa",
  SG: "Singapura",
  SX: "S\xE3o Martinho (parte holandesa)",
  SY: "S\xEDria",
  SO: "Som\xE1lia",
  LK: "Sri Lanka",
  SZ: "Suazil\xE2ndia",
  ZA: "\xC1frica do Sul",
  SD: "Sud\xE3o",
  SS: "Sud\xE3o do Sul",
  SE: "Su\xE9cia",
  CH: "Su\xED\xE7a",
  SR: "Suriname",
  PF: "Taiti",
  TH: "Tail\xE2ndia",
  TW: "Taiwan",
  TZ: "Tanz\xE2nia",
  TJ: "Tajiquist\xE3o",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad e Tobago",
  TN: "Tun\xEDsia",
  TM: "Turcomenist\xE3o",
  TR: "Turquia",
  TV: "Tuvalu",
  UA: "Ucr\xE2nia",
  UG: "Uganda",
  UY: "Uruguai",
  UZ: "Uzbequist\xE3o",
  VU: "Vanuatu",
  VA: "Cidade do Vaticano",
  VE: "Venezuela",
  VN: "Vietn\xE3",
  WF: "Wallis e Futuna",
  YE: "I\xEAmen",
  DJ: "Djibuti",
  ZM: "Z\xE2mbia",
  ZW: "Zimb\xE1bue"
};

// src/translations/pt/index.ts
var TRANSLATIONS_PT = {
  countries: countries2
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ADMIN_PREFIX,
  ARG_TOKEN_CURRENCIES,
  ARG_TOKEN_CURRENCY_SYMBOLS,
  AppErrors,
  BACKOFFICE_NOTIFICATION_ID,
  BACKOFFICE_PATHS,
  BACKOFFICE_REGION_PREFIXES,
  BACKOFFICE_URL_START,
  BLOCKCHAIN_PREFIX,
  BRA_TOKEN_CURRENCIES,
  BRL_TOKEN_CURRENCY_SYMBOLS,
  CURRENCIES,
  CURRENCY_PREFIXES,
  ENationalities,
  ERROR_CODES,
  ERROR_MESSAGES,
  FINANCE_NOTIFICATION_ID,
  GRAIN_CONTRACT_TYPES,
  HTTP_ERROR_CODES,
  INTERNAL_PREFIX,
  ISO_CURRENCIES,
  LANDTOKEN_PREFIX,
  LOANS_PREFIX,
  MOBILE_PREFIX,
  ORACLE_PREFIX,
  PERMISSION_CATEGORIES,
  PHONE_COUNTRY_CODES,
  PLATFORM_PATHS,
  PLATFORM_PERMISSIONS,
  PLATFORM_PREFIX,
  PRICES_PREFIX,
  PUBLIC_PREFIX,
  REFERENCE_CURRENCIES,
  REGEX,
  REGIONS,
  TOKEN_CURRENCIES,
  TOKEN_CURRENCY_SYMBOLS,
  TRANSLATIONS_ES,
  TRANSLATIONS_PT,
  UNITS,
  WARRANTY_HEALTH_CONTROL,
  WEIGHT_UNITS,
  addThousandSeparators,
  argTokenCurrencies,
  baseDocusignUrl,
  braTokenCurrencies,
  calculateFiatInSacas,
  checkCnpjTypeAndLength,
  checkCpfTypeAndLength,
  checkCuitTypeAndLength,
  checkDniTypeAndLength,
  cleanupStringWithRegex,
  convertAmountToLocalCurrency,
  currencies,
  currencyPrefixes,
  currencySymbols,
  dateToTimestamp,
  equivalences,
  extractValues,
  formatDate,
  formatNumber,
  getAccountNumberFromCbu,
  getAlphanumericRegex,
  getBankEntityNumberFromCbu,
  getDocumentTypeByRegion,
  getDocusignClient,
  getFirstCbuBlock,
  getGeoClient,
  getMultiRegionMap,
  getRegionalCurrency,
  getRegionalCurrencySymbol,
  getSecondCbuBlock,
  grainContractTypes,
  isAgrotokenBusiness,
  isLengthOk,
  isString,
  isoCurrencies,
  isoToTimestamp,
  offerLetterPath,
  parsify,
  phoneCountryCodes,
  referenceCurrencies,
  regions,
  timestampToDate,
  timestampToIso,
  tokenCurrencies,
  validateCBU,
  warrantyHealthControls,
  weightUnits
});
