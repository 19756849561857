import { Box, Divider } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CreatePaymentFormARProps } from '../../../CreatePaymentRequest/types';
import { GeneralDataSection } from '../GeneralDataSection';
import PaymentDataSection from '../PaymentDataSection';
import ForwardPayment from '../PaymentDataSection/ForwardPayment';

export const CreatePaymentForm: FC<CreatePaymentFormARProps> = ({
  translateType,
  fiscalId,
  setFiscalId,
  isChecking,
  firstCheck,
  isValidFiscalId,
  firstSubmit,
  checkedBusiness,
  isErrorCheck,
  amount,
  setAmount,
  isValidAmount,
  comment,
  setComment,
  isInKind,
  setDocumentId,
  documentId,
  maxSizeFileBytes,
  forwardings,
  setForwardings,
  estimatedReceiverFeeAmount,
}) => {
  const { t } = useTranslation();

  const { merchantToMerchantPayments } = useFlags();

  const amountToReceive = amount - estimatedReceiverFeeAmount;

  return (
    <Box>
      <GeneralDataSection
        translateType={translateType}
        fiscalId={fiscalId}
        setFiscalId={setFiscalId}
        isChecking={isChecking}
        firstCheck={firstCheck}
        isValidFiscalId={isValidFiscalId}
        firstSubmit={firstSubmit}
        checkedBusiness={checkedBusiness}
        isErrorCheck={isErrorCheck}
        comment={comment}
        setComment={setComment}
        setDocumentId={setDocumentId}
        documentId={documentId}
        maxSizeFileBytes={maxSizeFileBytes}
        isInKind={isInKind}
      />
      <PaymentDataSection
        {...{
          amount,
          firstSubmit,
          isValidAmount,
          setAmount,
          translateType,
          setForwardings,
        }}
        title={`2. ${t('createPayment.form.amount.title')}`}
      >
        {merchantToMerchantPayments && !isInKind && (
          <>
            <Divider my="4" borderColor="gray.200" />
            <ForwardPayment
              {...{
                setForwardings,
                forwardings,
                payerFiscalId: fiscalId,
                amountToReceive,
              }}
            />
          </>
        )}
      </PaymentDataSection>
    </Box>
  );
};
