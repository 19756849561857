import { Text } from '@chakra-ui/react';
import { PaymentRequestStatus, PAYMENT_REQUEST_STATUSES } from '@common/types';
import { formatDate } from '@common/utils/format-date';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemIcon } from '../../ItemIcon';

const PaymentRequestParametersTitle: FC<{
  paymentRequestStatus?: PaymentRequestStatus;
  createdAtBoundOperation?: number;
  creatAtPaymentRequest: number | undefined;
}> = ({
  paymentRequestStatus,
  createdAtBoundOperation,
  creatAtPaymentRequest,
}) => {
  const { t } = useTranslation();
  return (
    <ItemIcon
      label={t('payPaymentRequest.breakdown.operationDetail')}
      value={
        <Text>
          {paymentRequestStatus === PAYMENT_REQUEST_STATUSES.COMPLETED
            ? t('payPaymentRequest.breakdown.paymentMade', {
                date: formatDate(
                  Number(createdAtBoundOperation),
                  'DD/MM/YYYY HH:mm'
                ),
              })
            : t('payPaymentRequest.breakdown.paymentCreated', {
                date: formatDate(
                  Number(creatAtPaymentRequest),
                  'DD/MM/YYYY HH:mm'
                ),
              })}
        </Text>
      }
      className="mb-4"
      isBold={false}
    />
  );
};

export default PaymentRequestParametersTitle;
