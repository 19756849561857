import { formatNumber } from '@agrotoken/common-utils';
import { useTranslation } from 'react-i18next';
import { PaymentRequestType } from '@common/enum';
import {
  ECurrencyNationality,
  EUnits,
  OPERATION_STATUSES,
} from '@common/types';
import { formatDate } from '@common/utils/format-date';
import { useGetConversionRate } from '@hooks/index';
import { useAuth } from '@src/context/authContext';
import { useGetMovementsForWallet, useGetTransactionTypeTranslation } from '.';

export const useMovementsDataCSV = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wallet.movementsTable.status',
  });
  const { data: movements } = useGetMovementsForWallet();
  const { nationality } = useAuth();
  const { getTransactionTypeTranslation } = useGetTransactionTypeTranslation();
  const { tokenToLocalCurrency } = useGetConversionRate();

  const movementsDataCSV = movements?.map((movement) => {
    const { operation, amount, createdAt, amountFiat } = movement;
    const amountARS =
      movement.operation.paymentRequest?.type !==
      PaymentRequestType.FUTURE_WARRANTY
        ? amountFiat
        : tokenToLocalCurrency(amount, operation.currency?.name);
    const formattedAmount = amountARS ? formatNumber(amountARS, EUnits.ARS) : 0;
    const status = {
      [OPERATION_STATUSES.PENDING]: t('pending'),
      [OPERATION_STATUSES.SUCCESSFUL]: t('completed'),
      [OPERATION_STATUSES.FAILED]: t('expired'),
      [OPERATION_STATUSES.EXPIRED]: t('completed'), // TODO: fix here
      [OPERATION_STATUSES.LIQUIDATED]: t('expired'),
    };
    return {
      operationType: getTransactionTypeTranslation(operation, movement),
      amount,
      value: amount
        ? `${ECurrencyNationality[nationality]} ${formattedAmount}`
        : '-',
      toBusiness: operation.toBusinessUser
        ? operation.toBusinessUser.business.name
        : 'Agrotoken',
      createdAt: formatDate(createdAt, 'DD-MM-YYYY hh:mm A'),
      status: status[operation.operationStatus],
    };
  });

  return { movementsDataCSV };
};
