/* eslint-disable import/no-unresolved */
import { Box, Button, Checkbox, Divider, Flex, Text } from '@chakra-ui/react';
import { formatNumber } from '@common/utils';
import { EUnits, TCurrencyName } from '@src/common/types';
import Feathericon from 'feather-icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockchainState } from '@comp/Global/BlockchainState';
import { StepTitle } from '@comp/Global/StepForm';
import { TokenIcon } from '@comp/Global/TokenIcon';
import { TermsAndCondsModal } from '@comp/TransferTokens/TermsAndCondsModal';
import { useSwapSubmit } from './use-swap-submit';

export const SubmitStep = () => {
  const {
    isSwapLoading,
    swapError,
    token,
    isAwaitingBlockchainConfirmation,
    parsedAmount,
    perpetuityPercentage,
    perpetuityAmount,
    totalTokenAmount,
    totalFiatAmount,
    handleSubmit,
  } = useSwapSubmit();

  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);

  // TODO: Get from API
  const exchange = 'Ripio';

  return (
    <>
      <TermsAndCondsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <StepTitle>{t('transferTokens.submitStep.title')}</StepTitle>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        fontSize="md"
        marginTop="6"
        background="gray.100"
        borderRadius="base"
      >
        <Text fontSize="sm" p="3" fontFamily="Larsseit-medium">
          {t('transferTokens.submitStep.banner')}
        </Text>
        <Box marginRight="3">
          <Feathericon icon="info" size="15" />
        </Box>
      </Flex>
      <Flex marginTop="6" justifyContent="space-between" fontSize="md">
        <Text>{t('transferTokens.submitStep.subtotal')}</Text>
        <Flex alignItems="center">
          <Text marginRight="2">
            {formatNumber(parsedAmount, EUnits.CRYPTO)}
          </Text>
          <TokenIcon token={token as TCurrencyName} size="sm" />
          <Text ml="1">{token}</Text>
        </Flex>
      </Flex>

      <Flex marginTop="2" justifyContent="space-between" fontSize="md">
        <Text>
          {t('transferTokens.submitStep.ipp')} ({perpetuityPercentage}%)
        </Text>
        <Flex alignItems="center">
          <Text marginRight="2">
            - {formatNumber(perpetuityAmount, EUnits.CRYPTO)}
          </Text>
          <TokenIcon token={token as TCurrencyName} size="sm" />
          <Text ml="1">{token}</Text>
        </Flex>
      </Flex>

      <Divider marginTop="6" background="gray.200" />

      <Flex marginTop="6" justifyContent="space-between">
        <Text fontFamily="Larsseit-Medium" fontSize="xl">
          {t('transferTokens.submitStep.total')}
        </Text>
        <Flex alignItems="center">
          <Text marginRight="2" fontFamily="Larsseit-Medium" fontSize="xl">
            {formatNumber(totalTokenAmount, EUnits.CRYPTO)}
          </Text>
          <TokenIcon token={token as TCurrencyName} size="sm" />
          <Text ml="1" fontSize="xl">
            {token}
          </Text>
        </Flex>
      </Flex>

      <Flex marginTop="2" justifyContent="space-between" fontSize="md">
        <Text>{t('transferTokens.submitStep.equivalence')}</Text>
        <Text>
          {`~ ${t('localCurrency')} ${formatNumber(
            totalFiatAmount,
            EUnits.CRYPTO
          )}`}
        </Text>
      </Flex>

      <Flex marginTop="2" justifyContent="space-between" fontSize="md">
        <Text>{t('transferTokens.submitStep.time')}</Text>
        <Text>{t('transferTokens.submitStep.immediately')}</Text>
      </Flex>

      <Flex marginTop="2" justifyContent="space-between" fontSize="md">
        <Text>{t('transferTokens.submitStep.exchange')}</Text>
        <Text>{exchange}</Text>
      </Flex>

      {/* <Flex marginTop="2" justifyContent="space-between" fontSize="md">
				<Text>{t('transferTokens.submitStep.wallet')}</Text>
				<Text>{wallet}</Text>
			</Flex> */}

      <Checkbox
        variant="primary"
        fontSize="sm"
        color="gray.700"
        mt="6"
        isChecked={areTermsAccepted}
        onChange={() => setAreTermsAccepted(!areTermsAccepted)}
      >
        {t('transferTokens.terms.accept')}
        <Text
          as="span"
          color="primary.700"
          _hover={{
            textDecoration: 'underline',
          }}
          onClick={() => setIsModalOpen(true)}
        >
          {t('transferTokens.terms.termsAndConds')}
        </Text>
      </Checkbox>

      <Button
        variant="primary"
        size="lg"
        marginTop="6"
        isFullWidth
        onClick={handleSubmit}
        isLoading={isSwapLoading}
        disabled={!areTermsAccepted}
      >
        {t('transferTokens.submitStep.btnLabel')}
      </Button>
      {isAwaitingBlockchainConfirmation && (
        <BlockchainState
          className="mt-6"
          title={t('transferTokens.submitStep.blockchain.title')}
          subtitle={t('transferTokens.submitStep.blockchain.subtitle')}
        />
      )}
      {swapError && (
        <Text size="sm" color="error.500" mt="1.5" align="right">
          {swapError?.message}
        </Text>
      )}
    </>
  );
};
