import { Flex, Spinner } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import {
  FULL_ONBOARDING_LEVEL_2,
  FULL_ONBOARDING_LEVEL_3,
  HOME_PATH,
} from '@common/const';
import { Modal } from '@comp/Global/Modal';
import { useAccess } from '@src/context/accessContext';
import { useFullOnboardingAccess } from '@src/context/fullOnboardingAccessContext';
import { useUser } from '@src/context/userContext';

const RequireAdminAccess: FC = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canAdminUsers } = useAccess();

  const {
    hasOnboardingLevel3ChangesRequested_v2,
    hasOnboardingLevel3PendingVerification_v2,
    hasNotSentOnboardingLevel3_v2,
    hasOnboardingLevel2PendingVerification_v2,
    hasOnboardingLevel2ChangesRequested_v2,
    hasNotSentOnboardingLevel2_v2,
  } = useFullOnboardingAccess();

  const { selectedBusiness, selectedBusinessId, selectedBusinessUser } =
    useUser();

  if ((!selectedBusiness || !selectedBusinessUser) && selectedBusinessId)
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    );

  if (!canAdminUsers) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (hasNotSentOnboardingLevel2_v2 || hasOnboardingLevel2ChangesRequested_v2) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_LEVEL_2)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (hasNotSentOnboardingLevel3_v2 || hasOnboardingLevel3ChangesRequested_v2) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_LEVEL_3)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (
    hasOnboardingLevel2PendingVerification_v2 ||
    hasOnboardingLevel3PendingVerification_v2
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  return <>{children}</>;
};

export default RequireAdminAccess;
