const COMPONENTS_TRANSLATIONS_ES = {
  summaryDetail: {
    emptyState:
      'Al ingresar los datos correspondientes, podrás visualizar un resumen de tu transacción.',
  },
  transactionExpirationTimer: {
    text: 'Expira en',
  },
};

export default COMPONENTS_TRANSLATIONS_ES;

export type ComponentTranslationType = typeof COMPONENTS_TRANSLATIONS_ES;
