import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '@src/context/authContext';

export const useRedirectUrl = () => {
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout | undefined>();
  const redirectingIntervalRef = useRef<NodeJS.Timeout | undefined>();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');

  const { signOut } = useAuth();

  // Third parties payment requests could have a redirect url to be redirected to after payment is completed.
  useEffect(() => {
    if (!redirectUrl) return;

    // If redirect url is provided, set isRedirecting to true after 3 seconds.
    // This will impact the loading state of the payment request view.
    intervalRef.current = setInterval(() => {
      setIsRedirecting(true);

      // Wait 3 seconds before redirecting to the redirect url.
      redirectingIntervalRef.current = setInterval(() => {
        window.location.replace(redirectUrl ?? '');
        signOut();
      }, 3000);
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
      clearInterval(redirectingIntervalRef.current);
    };
  }, [redirectUrl, signOut]);

  return { isRedirecting };
};
