import FeatherIcon from 'feather-icons-react';
import { FC, HTMLAttributes, ChangeEvent } from 'react';

export interface Props extends HTMLAttributes<HTMLInputElement> {
  name: string;
  id?: string;
  type?: 'checkbox' | 'radio';
  label?: string;
  required?: boolean;
  disabled?: boolean;
  hintMessage?: string;
  value?: string | undefined;
  className?: string;
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (ev: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  showError?: boolean;
}

export const Checkbox: FC<Props> = ({
  name,
  id,
  type = 'checkbox',
  label,
  required = true,
  disabled = false,
  hintMessage,
  value = '',
  className,
  onChange,
  onBlur,
  onFocus,
  onInput,
  checked,
  showError,
}) => {
  return (
    <div className={`flex ${className}`}>
      <div>
        <div className="relative flex items-center">
          <input
            type={type}
            id={id}
            name={name}
            className={
              checked && disabled
                ? `border border-gray-200 p-0 w-4 h-4 text-md text-gray-200 shadow-xs placeholder:text-gray-200 focus-visible:outline-0 focus-visible:shadow-focus-primary focus:shadow-focus-primary focus:border-primary-300 focus-visible:border-primary-300 disabled:bg-gray-50 disabled:border-gray-200 hover:bg-primary-50 hover:border-primary-600 checked:bg-primary-50 checked:border-primary-600 appearance-none
						${type === 'checkbox' ? 'rounded' : 'rounded-[50%]'}`
                : showError
                ? `border border-error-300 p-0 w-4 h-4 text-md text-error-900 shadow-xs placeholder:text-error-500 focus-visible:outline-0 focus-visible:shadow-focus-error focus:shadow-focus-error focus:border-error-300 focus-visible:border-error-300 disabled:bg-error-50 disabled:border-error-300 hover:bg-error-50 hover:border-error-600 checked:bg-error-50 checked:border-error-600 appearance-none ${
                    type === 'checkbox' ? 'rounded' : 'rounded-[50%]'
                  }`
                : `border border-gray-300 p-0 w-4 h-4 text-md text-gray-900 shadow-xs placeholder:text-gray-500 focus-visible:outline-0 focus-visible:shadow-focus-primary focus:shadow-focus-primary focus:border-primary-300 focus-visible:border-primary-300 disabled:bg-gray-50 disabled:border-gray-300 hover:bg-primary-50 hover:border-primary-600 checked:bg-primary-50 checked:border-primary-600 appearance-none
          ${type === 'checkbox' ? 'rounded' : 'rounded-[50%]'}`
            }
            aria-required={required}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onInput={onInput}
            checked={checked}
          />
          {checked && (
            <FeatherIcon
              icon={type === 'checkbox' ? 'check' : 'circle'}
              //pointer-events: none for IE11 (it does not work in IE10 or below)
              className={
                checked && disabled
                  ? `absolute inset-0 m-auto text-gray-200 pointer-events-none ${
                      type === 'radio' && 'fill-primary-600'
                    }`
                  : `absolute inset-0 m-auto text-primary-600 pointer-events-none ${
                      type === 'radio' && 'fill-primary-600'
                    }`
              }
              size={type === 'checkbox' ? '14' : '7'}
            />
          )}
        </div>
      </div>
      {label && (
        <div className="flex flex-col">
          <label
            htmlFor={id}
            className={`block text-sm font-normal pl-2 ${
              !hintMessage && disabled ? 'text-gray-900' : 'text-gray-700'
            }`}
          >
            {label}
          </label>
          {hintMessage && (
            <p className="text-sm text-gray-500 pl-2">{hintMessage}</p>
          )}
        </div>
      )}
    </div>
  );
};
