/* eslint-disable no-multi-str */
import { ENationalities } from '@agrotoken/common-utils';
import { Decimal } from 'decimal.js';

import {
  IAgreementForm,
  ICdeForm,
  EAgreementType,
  CurrencySymbol,
  CprForm,
  ECurrencyNameBr,
  CurrencyNameBrTranslation,
} from './types';
import { PaymentRequestType } from './enum/payment-requests.enum';

export const SOYA: CurrencySymbol = 'SOYA';
export const WHEA: CurrencySymbol = 'WHEA';
export const CORA: CurrencySymbol = 'CORA';
export const USD: CurrencySymbol = 'USD';
export const USD_SOY: CurrencySymbol = 'USD SOJA';
export const ARS: CurrencySymbol = 'ARS';
export const BRL: CurrencySymbol = 'BRL';
export const SOYA_ARS: CurrencySymbol = 'SOYA';
export const CORA_ARS: CurrencySymbol = 'CORA';

export const EMPTY_CDE: ICdeForm = {
  internalNumber: '',
  tons: '',
  documentId: null,
  action: 'create',
};
export const EMPTY_AGREEMENT: IAgreementForm = {
  internalNumber: '',
  agreementTypeId: '',
  agreementTons: '',
  tons: '',
  limitRenewDate: null,
  expirationDate: null,
  price: '',
  documentId: null,
  oracle: '',
};

export const EMPTY_CPR: CprForm = {
  internalNumber: '',
  plantedArea: '',
  grainVolume: '',
  type: '',
  plantingDate: null,
  harvestDate: null,
  expirationDate: null,
  price: '',
  documentId: null,
};

export const SELECT_CURRENCIES = [
  {
    label: CurrencyNameBrTranslation.BRL,
    value: ECurrencyNameBr.BRL,
  },
  {
    label: CurrencyNameBrTranslation.USD,
    value: ECurrencyNameBr.USD,
  },
];

export const LOGIN_PATH = '/';
export const HOME_PATH = '/home';
export const RESET_PASSWORD_PATH = '/reset-password';
export const SELECT_ORG = '/select-organization';
export const JOIN_BUSINESS = '/join-business';
export const DELETED_INVITATION = '/invitation-not-found';
export const CREATE_NEW_BUSINESS_PATH = '/create-new-business';
export const WALLET_PATH = '/wallet';
export const USERS_PATH = '/users';
export const CARD_PATH = '/cards';
export const CREDITS_PATH = '/credits';
export const CREDITS_DETAIL_PATH = '/credits/:creditId';
export const TOKENIZATIONS_PATH = '/tokenizations';
export const SUCCESS_PATH = '/success';
export const FULL_ONBOARDING_KYC = '/KYC';
export const FULL_ONBOARDING_REPRESENTATIVE_DOCS = '/representative-docs';
export const FULL_ONBOARDING_BANKING_AND_BILLING = '/banking-and-billing';
export const FULL_ONBOARDING_LEVEL_2 = '/onboarding-level-2';
export const FULL_ONBOARDING_LEVEL_3 = '/onboarding-level-3';
export const PAYMENT_PATH = '/payment-requests';
export const NOTIFICATIONS_PATH = '/notifications';
export const ERROR_MESSAGE = 'Ocurrió un error interno en el servidor.';
export const CDES_ADDED = 4;
export const DETOKENIZATIONS_PATH = '/detokenizations';
export const WARRANTY_PATH = '/warranties';
export const TRANSFER_PATH = '/transfers';
export const LOANS_PATH = '/loans';

export const AGREEMENT_TYPE_LABEL: { [key: number]: string } = {
  [EAgreementType.PRICE_TO_FIX]: 'priceToFix',
  [EAgreementType.PRICE_TO_FIX_AGTK]: 'priceToFixAGTK',
  [EAgreementType.FIXED_PRICE]: 'fixedPrice',
};

export const AGREEMENT_TYPE_LABEL_DESCRIPTION: { [key: number]: string } = {
  [EAgreementType.PRICE_TO_FIX]: 'priceToFixDescription',
  [EAgreementType.PRICE_TO_FIX_AGTK]: 'priceToFixAGTKDescription', // TODO: fix translations
  [EAgreementType.FIXED_PRICE]: 'fixedPriceDescription',
};

export const TOKENIZATION_FEE = 0.25;
export const AUTOMATED_REMINDER_HOURS = 48;
export const AMOUNT_DETOKENIZED = 0;

export const API_BASE = '/api/v1';
export const FORGOT_PASSWORD_URL =
  'https://login.agrotoken.io/es/forgot-password';
export const AGROTOKEN_VISA_ANIMATION_URL =
  'https://content.test.agrotoken.io/Agrotoken_Visa_Animation_600x440_v2_3.mp4';
export const SOYB = 'SOYB';
export const WHEB = 'WHEB';
export const CORB = 'CORB';

export const DAYS = 'días';
export const ENABLED = 1;
export const ALL_PERMISSIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const TOAST_DURATION = 8000;

export const transactionFee = 0;

export const RATES_REFETCH_INTERVAL = 30000;

export const CURRENT_USER_REFETCH_INTERVAL = 60000;

export const FISCAL_ID_COLLISION = 'FISCAL_ID_COLLISION';

// Cognito error codes

export enum ECognitoErrors {
  PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
  NOT_AUTHORIZED = 'NotAuthorizedException',
  USER_NOT_FOUND = 'UserNotFoundException',
}

export const NO_FEES = {
  payerFee: new Decimal(0),
  receiverFee: new Decimal(0),
  payerFixedFee: new Decimal(0),
  receiverFixedFee: new Decimal(0),
};

export const SUPPORT_EMAIL = {
  [ENationalities.AR]: 'soporte@agrotoken.io',
  [ENationalities.BR]: 'suporte@agrotoken.io',
};

export const TOKENS_DEFAULT_DECIMALS = 4;
export const FIAT_DEFAULT_DECIMALS = 2;

export const MINIMUM_TOKENS_TO_TRANSFER = 0.001;

//Cookies
export const PAYER_FEE_MODAL_COOKIE = 'remember_payer_fee';
export const RECEIVER_FEE_MODAL_COOKIE = 'remember_receiver_fee';

export const CURRENT_REGION_HEADER = 'x-current-region';

export const AddressFormRestrictions = {
  ADDRESS_MAX_LENGTH: 300,
  STREET_NUMBER_MAX_LENGTH: 50,
  NEIGHBORHOOD_MAX_LENGTH: 255,
  COMPLEMENT_MAX_LENGTH: 255,
  POSTAL_CODE_MAX_LENGTH: 20,
};

export const HealthRatioValues = {
  HIGH: 60,
  LOW: 20,
};

export const HEALTH_STATUS_O = 0;
export const HEALTH_RATIO_O = 0;
export const X_CURRENT_BUSINESS_USER_ID = 'x-current-business-user-id';

export const MIN_TONS_VALIDATION = 5;

export const PAYMENT_TYPE_TRANSLATION: { [key: string]: string } = {
  [PaymentRequestType.FUTURE_WARRANTY]: 'warranty',
  [PaymentRequestType.IN_KIND]: 'kind',
  [PaymentRequestType.STANDARD]: 'regular',
};

export const BREAKPOINTS = {
  md: 640,
  lg: 1024,
};
