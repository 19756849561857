import { ButtonGroup } from '@agrotoken-tech/ui';
import { FormLabel, FormControl, Button } from '@chakra-ui/react';
import { EBusinessStructureTypes } from '@src/common/types';
import { UseFormSetValue } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormValues } from './GeneralInfoForm';

const ProducerTypeSelector = ({
  setValue,
  control,
}: {
  setValue: UseFormSetValue<FormValues>;
  control: any;
}) => {
  const { t } = useTranslation();

  useController({
    name: 'business_person_type_id',
    control,
    rules: { required: 'Required' },
    defaultValue: String(EBusinessStructureTypes.PERSON),
  });

  const handleButtonClick = (value: number) => {
    setValue('business_person_type_id', value);
  };

  const producerTypeButtons = [
    <Button
      key={'individual'}
      onClick={() => handleButtonClick(EBusinessStructureTypes.PERSON)}
    >
      {t('softOnboarding.signUpScreen.form.typeOfProducer.individual')}
    </Button>,
    <Button
      key={'company'}
      onClick={() => handleButtonClick(EBusinessStructureTypes.COMPANY)}
    >
      {t('softOnboarding.signUpScreen.form.typeOfProducer.company')}
    </Button>,
  ];

  return (
    <FormControl>
      <FormLabel htmlFor="businessStructure">
        {t('softOnboarding.signUpScreen.form.typeOfProducer.question')}
      </FormLabel>
      <ButtonGroup
        buttons={producerTypeButtons}
        size={'lg'}
        width={'100%'}
        selectedId={0}
      />
    </FormControl>
  );
};

export default ProducerTypeSelector;
