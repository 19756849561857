export const UIF_RADIO = 'ddjjSobligated';
export const typeOfBankAccount = [
  { label: 'Caja de ahorro', value: 1 },
  { label: 'Cuenta corriente', value: 2 },
];

export const managementPositions = [
  { keyCode: 1, name: 'Socio/Accionista' },
  { keyCode: 2, name: 'Socio/a cooperativista' },
  { keyCode: 3, name: 'Cargo directivo' },
  { keyCode: 4, name: 'Representante legal' },
  { keyCode: 5, name: 'Apoderado/a' },
];

export const maritalStatuses = [
  { keyCode: 1, name: 'Soltero/a' },
  { keyCode: 2, name: 'Casado/a' },
  { keyCode: 3, name: 'Unión libre o unión de hecho' },
  { keyCode: 4, name: 'Separado/a' },
  { keyCode: 5, name: 'Divorciado/a' },
  { keyCode: 6, name: 'Viudo/a' },
];

export const ActivitiesList = [
  'Actividades administrativas y servicios de apoyo',
  'Agricultura, ganadería, caza, silvicultura y pesca',
  'Enseñanza y educación',
  'Industria manufacturera',
  'Jubilados',
  'Salud humana y servicios sociales',
  'Servicios artísticos, culturales, deportivos y de esparcimiento',
  'Servicios de asociaciones y servicios personales',
  'Servicios de transporte y almacenamiento',
  'Servicios científicos y técnicos',
  'Comercio al por mayor y al por menor',
  'Suministro de agua, cloacas, gestión de residuos, recuperación de materiales y saneamiento público',
  'Servicios de profesiones liberales (abogado, contador, otros)',
  'Comercio al por mayor y al por menor',
  'Reparación de vehículos automotores y motocicletas',
  'Explotación de minas y canteras',
  'Información y telecomunicaciones',
  'Remesadoras de fondos',
  'Compra venta de automotores, embarcaciones, aeronaves y otros',
  'Casas y agencias de cambio',
  'Cooperativas y Mutuales',
  'Instituciones Bancarias',
  'Instituciones financieras no bancarias (Administradoras de Crédito, proveedores no financieros de crédito, PSP, Emisoras de tarjetas de crédito y/o compra, Fideicomisos)',
  'Transporte de caudales',
  'Construcción',
  'Hotelería y agencias de viajes',
  'Inmobiliaria',
  'Organizaciones no gubernamentales (ONG)',
  'Organizaciones sin fines de lucro',
  'Fundaciones',
  'Organizaciones religiosas',
  'Sindicatos y gremios',
  'Comerciantes y distribuidores de metales preciosos, joyas, antigüedades y/u obras de arte',
  'Casas de remate',
  'Brokers/Dealers/ Fondos de Inversión',
  'Fideicomisos',
  'Negocios de uso intensivo en efectivo (Ejemplo: supermercados, restaurantes, bares, etc.).',
  'Persona Jurídica que organiza y regula deportes profesionales',
  'Personas humanas o jurídicas proveedoras del Estado',
  'Proveedores de Servicios de Activos Virtuales (VASP)',
  'Sin actividad laboral u otro',
];
