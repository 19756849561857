import { http } from '@src/http-common';
import { ECardStatus, ECardType, ICard, ICardCreateResponse } from '@def/card';

import { ENationalities, PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { BusinessUserCards } from '@src/features/cards/interfaces/card';

export const createCard = async (
  card: ICard
): Promise<ICardCreateResponse | any> => {
  const { data } = await http.post<ICardCreateResponse>(
    `/${PLATFORM_PREFIX}/cards`,
    card
  );
  return data;
};

export const getCardByType = async (
  type: ECardType,
  isBlocked = false
): Promise<ICard[]> => {
  const { data } = await http.get<ICard[]>(
    `/${PLATFORM_PREFIX}/cards?include-blocked=${isBlocked}&card-type=${type}`
  );

  return data;
};

//TODO: Is this needed with the new behaviour of cards menu in platform?

export const shouldShowCardMenu = async (
  showCardFlowInPlatformFlag: boolean,
  nationality: ENationalities
): Promise<boolean> => {
  // Get user cards.
  const { data } = await http.get<ICard[]>(
    `/${PLATFORM_PREFIX}/cards?include-blocked=${true}&card-type=${
      ECardType.PHYSICAL
    }`
  );

  if (
    nationality === ENationalities.BR ||
    !showCardFlowInPlatformFlag ||
    !data
  ) {
    return false;
  }

  // Filter active and blocked cards.
  const cards = data.filter(
    (card) =>
      card.status === ECardStatus.ACTIVE || card.status === ECardStatus.BLOCKED
  );

  return showCardFlowInPlatformFlag && cards.length === 0 ? true : false;
};

export const getBusinessUsersWithCards = async (): Promise<
  BusinessUserCards[]
> => {
  return http
    .get<BusinessUserCards[]>(`/${PLATFORM_PREFIX}/business/cards`)
    .then((resp) => {
      const dataResult: BusinessUserCards[] = resp.data;
      return dataResult;
    });
};

export const getOracleSettlements = async (): Promise<any> => {
  return http
    .get<any>(`/${PLATFORM_PREFIX}/oracle-settlements/business`, {
      responseType: 'arraybuffer',
    })
    .then((resp) => {
      const dataResult: any = resp.data;
      return dataResult;
    });
};
