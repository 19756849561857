import { Modal } from '@agrotoken-tech/ui';

interface Props {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  hideCloseButton?: boolean;
  title: string;
  description?: string;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
}
const CongratsModal = ({
  isModalOpen,
  handleCloseModal,
  hideCloseButton = true,
  title,
  description,
  primaryActionLabel,
  primaryAction,
  secondaryActionLabel,
  size = 'lg',
}: Props) => {
  return (
    <Modal
      title={title}
      text={description}
      variantBtnConfirm={'primary'}
      onClickConfirm={primaryAction}
      labelBtnConfirm={primaryActionLabel}
      labelBtnClose={secondaryActionLabel}
      hideModalCloseButton={hideCloseButton}
      size={size}
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      hasActionButtons
      buttonsPosition="start"
    />
  );
};

export default CongratsModal;
