// TODO: REFACTOR FILE NAME CAMEL CASE
export interface ClaimPaymentRequestWarrantyPayload {
  uuid: string;
  businessUserId: string;
}

export const PAYMENT_REQUEST_STATUSES = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  EXPIRED: 'EXPIRED',
} as const;

export type PaymentRequestStatus =
  typeof PAYMENT_REQUEST_STATUSES[keyof typeof PAYMENT_REQUEST_STATUSES];
export const paymentRequestStatuses = Object.values(PAYMENT_REQUEST_STATUSES);

export const PAYMENT_REQUEST_STATUS_COLORS: Record<
  PaymentRequestStatus,
  string
> = {
  [PAYMENT_REQUEST_STATUSES.PENDING]: 'warning',
  [PAYMENT_REQUEST_STATUSES.IN_PROGRESS]: 'info',
  [PAYMENT_REQUEST_STATUSES.COMPLETED]: 'success',
  [PAYMENT_REQUEST_STATUSES.EXPIRED]: 'error',
};

//

export const PAYMENT_REQUEST_TYPES = {
  STANDARD: 'STANDARD',
  IN_KIND: 'IN_KIND',
  FUTURE_WARRANTY: 'FUTURE_WARRANTY',
} as const;

export type PaymentRequestType =
  typeof PAYMENT_REQUEST_TYPES[keyof typeof PAYMENT_REQUEST_TYPES];
export const paymentRequestTypes = Object.values(PAYMENT_REQUEST_TYPES);
