import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface CardRequestedSummaryItemProps {
  title: string;
  content: string | number | undefined;
}

const CardRequestedSummaryItem: FC<CardRequestedSummaryItemProps> = ({
  title,
  content,
}) => {
  return (
    <Box key={content} width="50%" marginY={4}>
      <Text marginBottom={1}>{title}:</Text>
      <Text>{content}</Text>
    </Box>
  );
};

export default CardRequestedSummaryItem;
