import { Spinner } from '@chakra-ui/react';
import { useAuth } from '@src/context/authContext';
import { getMultiRegionMap } from '@agrotoken/common-utils';
import { lazy, Suspense } from 'react';

const ProofOfLiquidityAR = lazy(() => import('../AR/ProofOfLiquidity/index'));
const ProofOfLiquidityBR = lazy(() => import('../BR/ProofOfLiquidity/index'));

const ComponentForRegion = getMultiRegionMap({
  AR: ProofOfLiquidityAR,
  BR: ProofOfLiquidityBR,
});

const ProofOfLiquidity = () => {
  const { nationality } = useAuth();
  const CurrentRegionProofOfLiquidity = ComponentForRegion[nationality];
  return (
    <Suspense fallback={<Spinner />}>
      <CurrentRegionProofOfLiquidity />
    </Suspense>
  );
};

export default ProofOfLiquidity;
