import { PLATFORM_PREFIX } from '@agrotoken/common-utils';

import { GetLoanPaymentAuthorizationDetailsResponse } from '@features/loans/interfaces';
import { http } from '../../http-common';

const getLoanPaymentAuthorizationDetails = async (
  uuid: string
): Promise<GetLoanPaymentAuthorizationDetailsResponse | undefined> => {
  const { data } = await http.get<GetLoanPaymentAuthorizationDetailsResponse>(
    `/${PLATFORM_PREFIX}/loans/${uuid}/authorization-details`
  );

  return data;
};

export default getLoanPaymentAuthorizationDetails;
