export const DollarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="4" fill="#DBF5D4" />
    <path
      d="M12 6.5V17.5M14.5 8.5H10.75C10.2859 8.5 9.84075 8.68437 9.51256 9.01256C9.18437 9.34075 9 9.78587 9 10.25C9 10.7141 9.18437 11.1592 9.51256 11.4874C9.84075 11.8156 10.2859 12 10.75 12H13.25C13.7141 12 14.1592 12.1844 14.4874 12.5126C14.8156 12.8408 15 13.2859 15 13.75C15 14.2141 14.8156 14.6592 14.4874 14.9874C14.1592 15.3156 13.7141 15.5 13.25 15.5H9"
      stroke="#288703"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
