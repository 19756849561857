import { ENationalities } from '@agrotoken/common-utils';
import { IGrainContract } from '@src/common/types';

enum FutureTokenizationStep {
  ORIGIN_OF_GRAIN,
  PRODUCTION_EXPECTATION,
  PROOF_OF_LIQUIDITY,
  VERIFICATION,
}

enum RealTokenizationStep {
  PROOF_OF_ORIGIN,
  PROOF_OF_EXISTENCE,
  PROOF_OF_LIQUIDITY,
  VERIFICATION,
}

// Shared helper function for both getRealTokenizationStep and getFutureTokenizationStep
const getTokenizationStep = <T>(
  isStep1: boolean,
  isStep2: boolean,
  isStep3: boolean,
  isStep4: boolean
): T | undefined => {
  const tokenizationSteps = [
    { step: FutureTokenizationStep.ORIGIN_OF_GRAIN, active: isStep1 },
    {
      step: FutureTokenizationStep.PRODUCTION_EXPECTATION,
      active: isStep2,
    },
    {
      step: FutureTokenizationStep.PROOF_OF_LIQUIDITY,
      active: isStep3,
    },
    { step: FutureTokenizationStep.VERIFICATION, active: isStep4 },
  ];

  return tokenizationSteps.find((step) => step.active)?.step as T;
};

// Function for getRealTokenizationStep
export const getRealTokenizationStep = (
  grainContract: IGrainContract,
  nationality: ENationalities
): RealTokenizationStep | undefined => {
  const isProofOfOriginStep =
    !grainContract.farmId && nationality === ENationalities.BR;

  const isProofOfExistenceStep =
    !isProofOfOriginStep &&
    (!grainContract.cdes?.length ||
      grainContract.cdes.some((cde) => !cde?.documentId));

  const isProofOfLiquidityStep =
    !grainContract.agreementDoc?.documentId &&
    !grainContract.isPretokenized &&
    !isProofOfExistenceStep &&
    !isProofOfOriginStep;

  const isConfirmStep =
    !isProofOfExistenceStep && !isProofOfLiquidityStep && !isProofOfOriginStep;

  return getTokenizationStep<RealTokenizationStep>(
    isProofOfOriginStep,
    isProofOfExistenceStep,
    isProofOfLiquidityStep,
    isConfirmStep
  );
};

// Function for getFutureTokenizationStep
export const getFutureTokenizationStep = (
  grainContract: IGrainContract,
  nationality: ENationalities
): FutureTokenizationStep | undefined => {
  const isOriginOfGrainStep =
    !grainContract.farmId && nationality === ENationalities.BR;

  const isProductionExpectationStep =
    !isOriginOfGrainStep && !grainContract.cpr?.documentId;

  const isProofOfAgreementStep =
    !grainContract.agreementDoc?.documentId &&
    !grainContract.isPretokenized &&
    !isProductionExpectationStep &&
    !isOriginOfGrainStep;

  const isConfirmStep =
    !isOriginOfGrainStep &&
    !isProductionExpectationStep &&
    !isProofOfAgreementStep;

  return getTokenizationStep<FutureTokenizationStep>(
    isOriginOfGrainStep,
    isProductionExpectationStep,
    isProofOfAgreementStep,
    isConfirmStep
  );
};
