import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';

import Warranty from '../Warranty';

interface WarrantyDrawerProps {
  onClose: () => void;
  isOpen: boolean;
  warrantyId?: number;
  warrantyOwnerBusinessFiscalId?: string;
}

// FIXME: This component is using <Warranty /> component wich is not a generic component.
// Warranty component should receive by props what ever information it needs to be displayed
// This is not the case since Warranty component is fetching warranty data

/**
 * A drawer component for displaying warranty details.
 *
 * @param {() => void} onClose Function to be called when the drawer is closed
 * @param {boolean} isOpen Indicates whether the drawer is open or not
 * @param {number} warrantyId ID of the warranty
 * @param {string} warrantyOwnerBusinessFiscalId Fiscal ID of the warranty owner's business
 * @returns {JSX.Element} WarrantyDrawer component.
 */
const WarrantyDrawer = ({
  isOpen,
  onClose,
  warrantyId,
  warrantyOwnerBusinessFiscalId,
}: WarrantyDrawerProps): JSX.Element => {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size={'xl'}>
      <DrawerOverlay />
      <DrawerContent padding="8">
        <DrawerHeader>
          <DrawerCloseButton _focus={{ outline: 'none' }} />
        </DrawerHeader>
        <DrawerBody>
          <Warranty
            paymentRequestSelectWarrantyId={warrantyId}
            farmerFiscalId={warrantyOwnerBusinessFiscalId}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default WarrantyDrawer;
