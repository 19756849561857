import { Flex, Spinner } from '@chakra-ui/react';
import {
  FULL_ONBOARDING_LEVEL_2,
  FULL_ONBOARDING_LEVEL_3,
  HOME_PATH,
  SELECT_ORG,
} from '@common/const';
import { IMerchantRequest } from '@common/types';
import { BeMerchantRequestModal } from '@comp/AccessControl/BeMerchantRequestModal';
import { Loader } from '@comp/Global/Loader';
import { Modal } from '@comp/Global/Modal';
import { ServerError } from '@comp/Global/ServerError';
import { useNavigateSelectOrg } from '@hooks/useNavigateSelectOrg';
import { getMerchantRequest } from '@services/MerchantRequests';
import { useAccess } from '@src/context/accessContext';
import { useFullOnboardingAccess } from '@src/context/fullOnboardingAccessContext';
import { useUser } from '@src/context/userContext';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserCheck } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const RequireCreatePaymentAccess: FC = ({ children }) => {
  const [isOpenRequestMerchantModal, setIsOpenRequestMerchantModal] =
    useState<boolean>(true);
  const { isMerchant, canCreatePaymentLink } = useAccess();

  const {
    hasOnboardingLevel2Completed_v2,
    hasOnboardingLevel3Completed_v2,
    hasOnboardingLevel2ChangesRequested_v2,
    hasOnboardingLevel3ChangesRequested_v2,
    hasOnboardingLevel2PendingVerification_v2,
    hasOnboardingLevel3PendingVerification_v2,
    hasNotSentOnboardingLevel2_v2,
    hasNotSentOnboardingLevel3_v2,
  } = useFullOnboardingAccess();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedBusiness, selectedBusinessUser, selectedBusinessId } =
    useUser();

  useNavigateSelectOrg();

  const {
    isLoading,
    data: merchantRequest,
    error,
    refetch,
  } = useQuery<IMerchantRequest | undefined>(
    ['merchantRequest'],
    getMerchantRequest
  );

  if (isLoading) return <Loader />;

  if (error)
    return (
      <ServerError
        title={t('serverError.title')}
        text={(error as any)?.message}
      />
    );

  if ((!selectedBusiness || !selectedBusinessUser) && selectedBusinessId)
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    );

  if (!canCreatePaymentLink) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (hasNotSentOnboardingLevel2_v2 || hasOnboardingLevel2ChangesRequested_v2) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_LEVEL_2)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (hasNotSentOnboardingLevel3_v2 || hasOnboardingLevel3ChangesRequested_v2) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_LEVEL_3)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (
    hasOnboardingLevel3PendingVerification_v2 ||
    hasOnboardingLevel2PendingVerification_v2
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (merchantRequest && !isMerchant) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleMerchantRequestVerificationPending')}
        text={t('verificationPending.textMerchantRequestVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (
    hasOnboardingLevel2Completed_v2 &&
    hasOnboardingLevel3Completed_v2 &&
    !isMerchant
  ) {
    return (
      <BeMerchantRequestModal
        refetch={refetch}
        setIsOpenRequestMerchantModal={setIsOpenRequestMerchantModal}
        isOpen={isOpenRequestMerchantModal}
        title={t('user.requestMerchantModal.title')}
        text={`${t('user.requestMerchantModal.text1')} ${
          selectedBusiness?.name
        } ${t('user.requestMerchantModal.text2')} `}
        subtitle={t('user.requestMerchantModal.subtitle')}
        labelBtnClose={t('user.requestMerchantModal.cancelBtnLabel')}
        labelBtnConfirm={t('user.requestMerchantModal.sendBtnLabel')}
        onClickClose={() => navigate(`${HOME_PATH}`)}
        center
      />
    );
  }

  return <>{children}</>;
};

export default RequireCreatePaymentAccess;
