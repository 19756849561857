import { Flex, Text } from '@chakra-ui/react';
import { ECurrencyName } from '@common/types';
import { SummaryItem } from './SummaryItem';
import { TokenValue } from '../LockedTokensSummary/TokenValue';

interface Props {
  id: string;
  loanCreationDate: string;
  loanDueDate: string;
  cuit: string;
  businessName: string;
  amountToFinance: number;
  lockedTotal: number;
  token: ECurrencyName;
}
export const CreditSummary = ({
  id,
  loanCreationDate,
  loanDueDate,
  cuit,
  businessName,
  amountToFinance,
  token,
  lockedTotal,
}: Props) => {
  return (
    <Flex
      p="24px"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      gap="24px"
      flexDir="column"
    >
      <Text>Resumen del préstamo</Text>
      <Flex
        flexDir="column"
        gap="16px"
        sx={{ div: { justifyContent: 'space-between' } }}
      >
        <SummaryItem iconKey="id" label="ID de operación:" value={id} />
        <SummaryItem
          iconKey="personArrow"
          label="Prestamista:"
          value={`${businessName} - ${cuit}`}
        />
        <SummaryItem
          iconKey="calendar"
          label="Fecha de creación del préstamo:"
          value={loanCreationDate}
        />
        <SummaryItem
          iconKey="dollar"
          label="Capital desembolsado:"
          value={amountToFinance}
        />
        <SummaryItem
          iconKey="coins"
          label="Tokens bloqueados:"
          value={<TokenValue value={lockedTotal} token={token} />}
        />
        <SummaryItem
          iconKey="calendar"
          label="Fecha de vencimiento:"
          value={loanDueDate}
        />
      </Flex>
    </Flex>
  );
};
