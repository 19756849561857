import { Badge } from '@comp/Global/Badge';
import { ELoanType } from '@src/common/enum/loan-type.enum';
import { Loan } from '@src/common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LoanCard from './LoanCard';

interface Props {
  loans: Loan[];
}
const CollateralizedTokens: FC<Props> = ({ loans }) => {
  const { t } = useTranslation();

  return (
    <div className="py-6">
      <>
        <div className="flex">
          <p className="text-lg text-gray-900 font-medium mb-1 mr-2">
            {t('wallet.warrantyTokens.title')}
          </p>
          <Badge
            color="warning"
            size="lg"
            label={t('wallet.warrantyTokens.badge')}
          />
        </div>
        <p className="text-sm text-gray-500 font-normal mt-2">
          {t('wallet.warrantyTokens.subtitle')}
        </p>
      </>
      <div className="py-5 flex flex-col md:flex-row flex-wrap md:gap-6 mb-6">
        {loans.map((loan) => {
          return (
            loan.type === ELoanType.Product && (
              <LoanCard key={loan.id} loan={loan} />
            )
          );
        })}
      </div>
    </div>
  );
};

export default CollateralizedTokens;
