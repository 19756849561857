import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiArrowRight, FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { TOKENIZATIONS_PATH } from '@common/const';
import { BaseCard } from '@comp/BaseCard';

/**
 * Functional component representing a card for starting a new tokenization process.
 * @returns {JSX.Element} The JSX for the NewTokenizationCard component.
 */
const NewTokenizationCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'home.newTokenizationCard',
  });

  /**
   * Event handler for the "Start Tokenization" button click. Navigates to the new tokenization page.
   */
  const onClickStartTokenizationHandler = () =>
    navigate(`${TOKENIZATIONS_PATH}/new-tokenization`);

  return (
    <BaseCard
      title={t('title')}
      subtitle={t('subtitle')}
      variant="gray"
      topRightElement={<FiPlusCircle />}
      onClick={onClickStartTokenizationHandler}
      bottomElements={
        <Text
          variant="medium"
          size="sm"
          color="content-secondary"
          display="flex"
          alignItems="center"
          columnGap="1"
        >
          {t('cta')}
          <FiArrowRight />
        </Text>
      }
    />
  );
};

export default NewTokenizationCard;
