import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { useAuth } from '@src/context/authContext';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';
import { maxSizeFileBytes } from '@comp/Global/DragAndDrop/consts';
import { EActions, EDocumentTypes, ICdeForm } from '../../../common/types';
import { useValidateCdeForm } from '../../../hooks/useValidateForm';
import { DragAndDrop } from '../../Global/DragAndDrop';
import { Input } from '@agrotoken-tech/ui';

interface Props {
  index: number;
  cdesForm: ICdeForm[];
  setCdesForm: React.Dispatch<React.SetStateAction<ICdeForm[]>>;
  shouldShowError: boolean;
  setIsValidForm: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  deleteDocumentCallback: (currentCdeFormIndex: number) => void;
  setValidCdeTonsSum: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
}

export const Form: FC<Props> = ({
  index,
  cdesForm,
  setCdesForm,
  shouldShowError,
  setIsValidForm,
  isEditing,
  deleteDocumentCallback,
  setValidCdeTonsSum,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();

  const {
    isValidTons,
    isValidTonsMinAllCdes,
    isValidFile,
    areValidCdes,
    internalNumberError,
    isValidInternalNumber,
  } = useValidateCdeForm(cdesForm, index);

  const isValidInternalNumberByRegion = isValidInternalNumber();

  //TODO: Review the code below, mapping cdesForm is unnecesary since we have index value.
  const handleDelete = () => {
    const result: ICdeForm[] =
      isEditing && cdesForm[index].action !== EActions.CREATE
        ? cdesForm.map((el, i) =>
            i === index ? { ...el, action: EActions.DELETE } : el
          )
        : cdesForm.filter((_el, i) => i !== index);
    setCdesForm(result);
  };

  const handleOnChangeNumber = (ev: React.FormEvent<HTMLInputElement>) => {
    const result: ICdeForm[] = cdesForm.map((el, i) =>
      i === index ? { ...el, internalNumber: ev.currentTarget.value } : el
    );
    setCdesForm(result);
  };

  const handleOnChangeTons = (ev: React.FormEvent<HTMLInputElement>) => {
    const result: ICdeForm[] = cdesForm.map((el, i) =>
      i === index ? { ...el, tons: ev.currentTarget.value } : el
    );
    setCdesForm(result);
  };

  const errorMessage = {
    empty: t('tokenization.proofOfExistence.form.errorMessage.empty'),
    min: t('tokenization.proofOfExistence.form.errorMessage.min'),
    length: t('tokenization.proofOfExistence.form.errorMessage.length'),
    unique: t('tokenization.proofOfExistence.form.errorMessage.unique'),
  };

  useEffect(() => {
    setIsValidForm(areValidCdes);
  }, [areValidCdes, setIsValidForm, cdesForm]);

  useEffect(() => {
    setValidCdeTonsSum(isValidTonsMinAllCdes(cdesForm));
  }, [isValidTonsMinAllCdes, setValidCdeTonsSum, cdesForm]);

  return (
    <div className="border border-gray-200 bg-white pb-6 pt-5 mt-8">
      <div className="flex justify-between border-b border-gray-200 pb-5 px-6 gap-2">
        <div>
          <p className="mb-1 text-lg font-medium text-gray-900">
            {t('tokenization.proofOfExistence.addNewTitle')}
          </p>
          <p className="text-sm font-normal text-gray-500">
            {t('tokenization.proofOfExistence.addInfo')}
          </p>
        </div>
        <Button
          leftIcon={<FiTrash2 />}
          variant="linkPrimary"
          onClick={handleDelete}
          width="min-content"
          isDisabled={isDisabled}
        >
          {t('btnDeleteLabel')}
        </Button>
      </div>
      <form className="px-6 mt-4">
        <div className="grid xl:grid-cols-2 gap-6">
          <FormControl mr="4">
            <FormLabel htmlFor="internalNumber" variant={'medium'} size={'sm'}>
              {t('tokenization.proofOfExistence.form.inputNumberLabel')}
            </FormLabel>
            <Input
              isInvalid={shouldShowError && !isValidInternalNumberByRegion}
              id={`cde_number[${index}]`}
              name={`number[${index}]`}
              type="text"
              placeholder={t(
                'tokenization.proofOfExistence.form.inputNumberPlaceholder'
              )}
              onChange={handleOnChangeNumber}
              value={cdesForm[index].internalNumber}
              errorMessage={internalNumberError}
              isDisabled={isDisabled}
            />
          </FormControl>
          <FormControl
            mr="4"
            isInvalid={
              shouldShowError &&
              (!isValidTons(index) || !isValidTonsMinAllCdes(cdesForm))
            }
          >
            <FormLabel htmlFor="internalNumber" variant={'medium'} size={'sm'}>
              {t('tokenization.proofOfExistence.form.inputTonsLabel')}
            </FormLabel>
            <Input
              id={`cde_tons[${index}]`}
              name={`tons[${index}]`}
              type="number"
              placeholder={t(
                'tokenization.proofOfExistence.form.inputTonsPlaceholder'
              )}
              onChange={handleOnChangeTons}
              value={cdesForm[index].tons}
              isDisabled={isDisabled}
            />
            <FormErrorMessage>
              {!isValidTons(index)
                ? errorMessage.empty
                : !isValidTonsMinAllCdes
                ? errorMessage.min
                : ''}
            </FormErrorMessage>
          </FormControl>
        </div>
        <div className="mt-6">
          <p className="text-sm font-medium text-gray-700 mb-1.5">
            {t('tokenization.proofOfExistence.form.attachCde')}
          </p>
          <DragAndDrop
            documentTypeId={EDocumentTypes.CDE}
            cdesForm={cdesForm}
            setCdesForm={setCdesForm}
            index={index}
            shouldShowError={shouldShowError}
            isValid={isValidFile(index)}
            maxFileSize={maxSizeFileBytes}
            deleteCallback={() => deleteDocumentCallback(index)}
            disabled={isDisabled}
          />
        </div>
      </form>
    </div>
  );
};
