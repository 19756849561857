import { FC, HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  to: string;
  label: string;
}

const activeClasses =
  'md:border-b-2 md:border-t-2 md:border-b-primary-700 md:border-t-white text-primary-700 py-5 md:px-0 inline-block';
const inactiveClasses =
  'py-5 md:px-0 block inline-block border-b-2 md:border-b-white border-t-2 md:border-t-white text-gray-500 hover:text-primary-600 hover:border-b-2 hover:border-t-2 hover:border-b-primary-600 hover:border-t-white';

const NavItem: FC<Props> = ({ to, label }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)}
    >
      {label}
    </NavLink>
  );
};

export default NavItem;
