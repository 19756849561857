import React from 'react';

interface RipioSvgProps {
  fill?: string;
  fillOpacity?: number;
}

export const RipioSvg: React.VFC<RipioSvgProps> = (props) => {
  const { fill = 'black', fillOpacity = 1 } = props;

  return (
    <svg
      width="84"
      height="48"
      viewBox="0 0 84 48"
      xmlns="http://www.w3.org/2000/svg"
      {...{ fill, fillOpacity }}
    >
      <path d="M20.2015 20.3703H20.2454C20.9995 19.0025 22.3059 18.3887 23.8491 18.3887H24.612V22.1941C24.0683 21.8872 23.5422 21.8258 22.9372 21.8258C20.7364 21.8258 20.1928 23.2814 20.1928 25.2016V29.6997H16.2383V18.5465H20.2015V20.3703Z" />
      <path d="M30.6186 18.5464H26.6641V29.7084H30.6186V18.5464Z" />
      <path d="M37.5629 19.8091H37.6067C38.3257 18.7043 39.676 18.1782 40.9825 18.1782C44.3144 18.1782 46.6117 20.9665 46.6117 24.1582C46.6117 27.3761 44.332 30.0768 41.0176 30.0768C39.7374 30.0768 38.4046 29.6208 37.5629 28.6388V34.7502L33.5996 36.425V18.5465H37.5629V19.8091ZM37.4313 24.1319C37.4313 25.5436 38.3695 26.6308 39.9741 26.6308C41.5787 26.6308 42.5169 25.5436 42.5169 24.1319C42.5169 22.764 41.5787 21.6066 39.9741 21.6066C38.3695 21.6066 37.4313 22.764 37.4313 24.1319Z" />
      <path d="M52.6791 18.5464H48.7158V29.7084H52.6791V18.5464Z" />
      <path d="M68.1117 24.1319C68.1117 27.9197 64.9814 30.068 61.3864 30.068C57.8177 30.068 54.6611 27.9373 54.6611 24.1319C54.6611 20.3264 57.7914 18.1694 61.3864 18.1694C64.9814 18.1694 68.1117 20.3264 68.1117 24.1319ZM58.8436 24.1319C58.8436 25.5435 59.7818 26.6308 61.3864 26.6308C62.991 26.6308 63.9292 25.5435 63.9292 24.1319C63.9292 22.764 62.991 21.6066 61.3864 21.6066C59.7818 21.6066 58.8436 22.764 58.8436 24.1319Z" />
      <path d="M30.6188 15.4424L26.752 17.0032V13.3117L30.6275 11.751L30.6188 15.4424Z" />
      <path d="M52.6266 15.4424L48.7598 17.0032V13.3117L52.6353 11.751L52.6266 15.4424Z" />
    </svg>
  );
};
