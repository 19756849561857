import { Flex, Heading } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { Loan } from '@def/loan';
import { getLoans } from '@services/Credits';
import { CreditsTable } from './Table';

export const CreditsPage = () => {
  const {
    data: loansData = [],
    error,
    isLoading,
  } = useQuery<any[]>(['get-loans'], getLoans);

  const checkIsPaid = (loan: Loan) => Boolean(loan.paidAt);
  const checkNotPaid = (loan: Loan) => !checkIsPaid(loan);
  const historicLoans = loansData.filter(checkIsPaid);
  const inProgressLoans = loansData.filter(checkNotPaid);

  return (
    <Flex px="80px" pt="48px" pb="66px" flexDir={'column'}>
      <Heading as="h1" mb="32px" fontSize="36px">
        Créditos
      </Heading>
      <Heading as="h2" fontSize="18px" mb="25px">
        Créditos en progreso
      </Heading>
      <CreditsTable
        data={inProgressLoans}
        error={error}
        isLoading={isLoading}
      />
      <Heading as="h2" fontSize="18px" mb="25px" mt="32px">
        Historial de créditos
      </Heading>
      <CreditsTable
        data={historicLoans}
        isLoading={isLoading}
        error={error}
        alreadyPaid
      />
    </Flex>
  );
};
