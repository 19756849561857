import { TOKENIZATIONS_PATH } from '@common/const';
import { UserTypes } from '@common/types';
import { useUser } from '@src/context/userContext';
import { FC, Props } from 'react';
import { useNavigate } from 'react-router-dom';

const RequireHomeAccess: FC = ({ children }) => {
  const navigate = useNavigate();
  const { currentUser } = useUser();

  if (currentUser?.userType === UserTypes.MANAGER) {
    navigate(TOKENIZATIONS_PATH);
  }
  return <>{children}</>;
};

export default RequireHomeAccess;
