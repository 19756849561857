import { ContextPhoneInput } from '@agrotoken-tech/ui';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
  Icon,
} from '@chakra-ui/react';
import { useAuth } from '@src/context/authContext';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { emailRegExp, passwordRegExp } from '@utils/regExp';
import { ICognitoError } from '../types';
import { FiEyeOff, FiEye } from 'react-icons/fi';

const CommonFields = ({
  errorsFromCognito,
}: {
  errorsFromCognito: ICognitoError;
}) => {
  const context = useFormContext();
  const {
    register,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = context;
  const { t } = useTranslation();
  const isInvalidEmail =
    Boolean(errors.email) || Boolean(errorsFromCognito.email);
  const emailErrorMessage = errorsFromCognito.email
    ? errorsFromCognito.email
    : t('softOnboarding.signUpScreen.form.emailError');
  const isInvalidPassword =
    Boolean(errors.password) || Boolean(errorsFromCognito.password);
  const helperText = t('softOnboarding.signUpScreen.form.passwordError');
  const passwordErrorMessage = errorsFromCognito.password
    ? errorsFromCognito.password
    : helperText;

  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [showPassword, { toggle: toggleShowPassword }] = useBoolean(false);
  const [showPasswordConfirm, { toggle: toggleShowPasswordConfirm }] =
    useBoolean(false);

  const auth = useAuth();

  useEffect(() => {
    if (!auth) return;
    auth
      .isAuth()
      .then(() => setIsAuthorized(true))
      .catch((error: Error) => {
        setIsAuthorized(false);
        console.error(error);
      });
  }, [auth]);
  const password = watch('password');
  const passwordConfirmation = watch('password-confirm');

  useEffect(() => {
    if (password && passwordConfirmation) {
      if (passwordConfirmation !== password) {
        setError('password-confirm', {
          message: t('softOnboarding.signUpScreen.form.verifyPasswordError'),
        });
      } else {
        clearErrors('password-confirm');
      }
    }
  }, [password]);

  return (
    <>
      <Flex direction={['column', 'column', 'column', 'row']} gap="6">
        {!isAuthorized && (
          <ContextPhoneInput
            label={t('softOnboarding.signUpScreen.form.phone')}
            name="phoneTemp"
            context={context}
            defaultCounty={watch('country')}
            size="lg"
            helperText={t('softOnboarding.signUpScreen.form.phoneHelper')}
            options={{
              required: t('softOnboarding.signUpScreen.form.requiredError'),
            }}
          />
        )}
        {!isAuthorized && (
          <FormControl isInvalid={isInvalidEmail}>
            <FormLabel htmlFor="email">
              {t('softOnboarding.signUpScreen.form.email')}
            </FormLabel>
            <Input
              type="email"
              id="email"
              placeholder={t(
                'softOnboarding.signUpScreen.form.emailPlaceholder'
              )}
              h="12"
              {...register('email', {
                required: `${t('shared.required')}`,
                validate: (email) => emailRegExp.test(email),
              })}
            />
            {isInvalidEmail && (
              <FormErrorMessage>{emailErrorMessage}</FormErrorMessage>
            )}
          </FormControl>
        )}
      </Flex>
      <Flex direction={['column', 'column', 'row']}>
        {!isAuthorized && (
          <FormControl mr="6" isInvalid={isInvalidPassword}>
            <FormLabel htmlFor="password">
              {t('softOnboarding.signUpScreen.form.password')}
            </FormLabel>
            <InputGroup>
              <InputRightElement onClick={() => toggleShowPassword()} h="full">
                <Icon
                  as={showPassword ? FiEyeOff : FiEye}
                  size={20}
                  color="var(--chakra-colors-gray-500)"
                />
              </InputRightElement>
              <Input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder={t(
                  'softOnboarding.signUpScreen.form.passwordPlaceholder'
                )}
                h="12"
                {...register('password', {
                  required: `${t('shared.required')}`,
                  validate: (pw) => Boolean(pw.match(passwordRegExp)),
                })}
              />
            </InputGroup>
            {isInvalidPassword ? (
              <FormErrorMessage whiteSpace="pre-line">
                {passwordErrorMessage}
              </FormErrorMessage>
            ) : (
              <FormHelperText whiteSpace="pre-line">
                {helperText}
              </FormHelperText>
            )}
          </FormControl>
        )}
        {!isAuthorized && (
          <FormControl
            isInvalid={Boolean(errors['password-confirm'])}
            marginTop={{ base: 5, md: 0 }}
          >
            <FormLabel htmlFor="password-confirmation">
              {t('softOnboarding.signUpScreen.form.verifyPassword')}
            </FormLabel>
            <InputGroup>
              <InputRightElement
                onClick={() => toggleShowPasswordConfirm()}
                h="full"
              >
                <Icon
                  as={showPasswordConfirm ? FiEyeOff : FiEye}
                  size={20}
                  color="var(--chakra-colors-gray-500)"
                />
              </InputRightElement>
              <Input
                type={showPasswordConfirm ? 'text' : 'password'}
                id="password-confirmation"
                placeholder={t(
                  'softOnboarding.signUpScreen.form.verifyPasswordPlaceholder'
                )}
                h="12"
                {...register('password-confirm', {
                  required: `${t('shared.required')}`,
                  validate: (confirmation) => {
                    return confirmation === password;
                  },
                })}
              />
            </InputGroup>
            {Boolean(errors['password-confirm']) && (
              <FormErrorMessage>
                {t('softOnboarding.signUpScreen.form.verifyPasswordError')}
              </FormErrorMessage>
            )}
          </FormControl>
        )}
      </Flex>
    </>
  );
};

export default CommonFields;
