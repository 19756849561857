import { TokenCurrency, WarrantyHealthControl } from '@agrotoken/common-utils';
import { SimpleGrid } from '@chakra-ui/react';
import Decimal from 'decimal.js';

import { EUnits, IBalance, IConversions, Warranty } from '@common/types';
import {
  GetFixedTokenizationsResponse,
  GetPreTokenizationsResponse,
} from '@features/tokenizations';

import FixedGrainCard from './FixedGrainCard';
import NewTokenizationCard from './NewTokenizationCard';
import PreTokenizedCard from './PreTokenizedCard';
import TokenBalanceCard from './TokenBalanceCard';
import WarrantyCard from './WarrantyCard';

interface TokenCardsProps {
  balances?: IBalance[];
  preTokenizations?: GetPreTokenizationsResponse[];
  warranties?: Warranty[];
  fixedTokenizations?: GetFixedTokenizationsResponse[];
}

/**
 * React component for displaying a collection of token-related cards.
 *
 * @param {IBalance[]} balances - An array of token balance data.
 * @param {GetPreTokenizationsResponse[]} preTokenizations - An array of pre-tokenization data.
 * @param {Warranty[]} warranties - An array of warranty data.
 * @param {GetFixedTokenizationsResponse[]} fixedTokenizations - An array of fixedtokenization data.

 */
const TokenCards = ({
  balances,
  preTokenizations,
  warranties,
  fixedTokenizations,
}: TokenCardsProps) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="8">
      {balances?.map((balance, index) => {
        const amountFiat = balance.conversions.find(
          (item: IConversions) => item.unit === EUnits.BRL
        )?.amount;

        const amountSacas = balance.conversions.find(
          (item: IConversions) => item.unit === EUnits.SACAS
        )?.amount;

        return (
          <TokenBalanceCard
            key={'balances' + index}
            amountFiat={amountFiat ?? 0}
            amountSacas={amountSacas ?? 0}
            amountTokens={Number(balance.amount)}
            currency={balance.name as TokenCurrency}
          />
        );
      })}
      {preTokenizations?.map((tokenization, index) => (
        <PreTokenizedCard
          key={'pretokenizations' + index}
          amountSacas={new Decimal(tokenization.amountSacas)}
          amountTons={new Decimal(tokenization.amountTons)}
          currency={tokenization.currencyName}
          grainContractId={tokenization.grainContractId}
          grainContractType={tokenization.grainContractType}
          requestNumber={tokenization.requestNumber}
          isProofOfLiquidityCompleted={tokenization.isProofOfLiquidityCompleted}
        />
      ))}
      {warranties?.map((warranty, index) => (
        <WarrantyCard
          key={'warranties' + index}
          amountSacas={warranty.conversions.amount.SACAS}
          amountFiat={warranty.equivalences.amount.BRL}
          origin={warranty.origin}
          currency={warranty.currency.name as TokenCurrency}
          warrantyId={warranty.id}
          requestNumber={warranty.requestNumber}
          healthControl={
            warranty.healthControl as unknown as WarrantyHealthControl
          } // TODO: Review this type, healthControl shouldn't be typed as Decimal
          expirationDate={warranty.expirationDate}
        />
      ))}
      {fixedTokenizations?.map((fixedTokenization, index) => (
        <FixedGrainCard
          key={'fixedTokenizations' + index}
          amountSacas={new Decimal(fixedTokenization.equivalences.amount.SACAS)}
          amountFiat={new Decimal(fixedTokenization.equivalences.amount.BRL)}
          currency={fixedTokenization.currency.name}
          requestNumber={fixedTokenization.requestNumber}
          expirationDate={fixedTokenization.expirationDate}
        />
      ))}
      <NewTokenizationCard />
    </SimpleGrid>
  );
};

export default TokenCards;
