import { Flex, Text, useToken } from '@chakra-ui/react';
import { FiClock } from 'react-icons/fi';

import calculateTimeRemaining from '@utils/calculateTimeRemaining';
import { useTranslation } from 'react-i18next';

interface TransactionExpirationTimerProps {
  expirationTimestamp: number;
}

/**
 * A component to display the time remaining until a transaction expires.
 * @param {TransactionExpirationTimerProps} props - The component props.
 * @returns {JSX.Element} The JSX element representing the transaction expiration timer.
 */
const TransactionExpirationTimer = ({
  expirationTimestamp,
}: TransactionExpirationTimerProps) => {
  // Get the 'gray.600' color from Chakra UI's theme
  const gray600 = useToken('colors', 'gray.600');

  const { t } = useTranslation();

  // Calculate the hours and minutes remaining until the expiration timestamp
  const { hours, minutes } = calculateTimeRemaining(expirationTimestamp);

  return (
    <Flex columnGap="1">
      <FiClock size="20" color={gray600} />
      <Text size="sm" color="gray.600" variant="regular">
        {t('components.transactionExpirationTimer.text')}
        <Text as="span" size="sm" color="gray.600" variant="medium">
          {` ${hours}h ${minutes}m`}
        </Text>
      </Text>
    </Flex>
  );
};

export default TransactionExpirationTimer;
