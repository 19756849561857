import { Badge, Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ExternalWallet: FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      border={'1px'}
      borderColor={'gray.200'}
      height={'80px'}
      marginTop="4"
      borderRadius="4"
      alignItems="center"
    >
      <Flex justifyContent={'space-between'} marginTop="4" alignItems="center">
        <Flex flexDir="column">
          <Text
            marginStart="4"
            fontFamily="Larsseit-Medium"
            fontSize="md"
            color="gray.500"
          >
            {t('transferTokens.walletStep.externalWalletTitle')}
          </Text>
          <Text
            fontFamily="Larsseit"
            fontSize="md"
            marginStart="4"
            color="gray.400"
          >
            {t('transferTokens.walletStep.externalWalletSubtitle')}
          </Text>
        </Flex>

        <Badge
          color="gray.700"
          textAlign="center"
          fontFamily="Larsseit"
          fontSize="xs"
          textTransform="none"
          paddingX="2"
          paddingY="0.5"
          background="gray.100"
          borderRadius="16px"
          boxShadow="none"
          height="20px"
          marginEnd="4"
        >
          {t('transferTokens.walletStep.soon')}
        </Badge>
      </Flex>
    </Box>
  );
};
