import { Box, Flex, HStack, Input, Spinner, Text } from '@chakra-ui/react';
import { ChangeEvent, FC, KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@common/utils';
import { Select } from '@comp/Global/Select';
import { useStepForm } from '@comp/Global/StepForm';
import { TokenIcon } from '@comp/Global/TokenIcon';
import { TransferTokensFormValues } from '@comp/TransferTokens';
import useGetConversionRate from '@hooks/useGetConversionRate';
import {
  CryptoCurrencySymbol,
  ECurrencyName,
  EUnits,
  PricingCurrency,
  TCurrencyName,
} from '@src/common/types';
import { useRates } from '@src/context/ratesContext';
import { TokenCurrency } from '@agrotoken/common-utils';

interface TokenAmountSelectorProps {
  isLoadingBalance: boolean;
  balance?: Record<CryptoCurrencySymbol, number>;
  className?: string;
}

export const TokenAmountSelector: FC<TokenAmountSelectorProps> = (props) => {
  const { balance, isLoadingBalance, className = '' } = props;
  const { t } = useTranslation();
  const { tokenToLocalCurrency } = useGetConversionRate();
  const { currencies } = useRates();
  const { formValues, setFormValues } = useStepForm<TransferTokensFormValues>();
  const { token, amount } = formValues;

  const selectTokenOptions:
    | { label: string; value: string | number }[]
    | undefined = currencies
    ?.filter(
      (currency: PricingCurrency) => currency.name === ECurrencyName.SOYA
    )
    .map((currency: PricingCurrency) => {
      const { name } = currency;
      return {
        value: name,
        label: name,
      };
    });

  const formattedTokenBalance = balance
    ? formatNumber(balance[token as CryptoCurrencySymbol], EUnits.CRYPTO)
    : 0;
  const formattedFiatAmount = formatNumber(
    tokenToLocalCurrency(Number(amount), token as TokenCurrency),
    EUnits.ARS
  );

  const haveEnoughTokens = balance
    ? Number(amount) <= balance[token as CryptoCurrencySymbol]
    : false;

  const showError =
    !haveEnoughTokens && !isLoadingBalance && !isNaN(Number(amount));

  const handleChangeToken = (ev: ChangeEvent<HTMLSelectElement>) => {
    const { value } = ev.target;

    setFormValues({
      ...formValues,
      token: value,
    });
  };

  const handleChangeAmount = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    const splitValue = value.split('.');
    splitValue[0] = String(parseInt(splitValue[0]));

    if (splitValue[1]) {
      splitValue[1] = splitValue[1].slice(0, 4);
    }

    setFormValues({
      ...formValues,
      amount: splitValue.join('.'),
    });
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> | undefined = (
    ev
  ) => {
    switch (ev.key) {
      case 'e':
      case '.':
        ev.preventDefault();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        px="6"
        py="4"
        bgColor="gray.50"
        gap="2"
        className={className}
      >
        <Box>
          <Flex>
            <TokenIcon
              token={token as TCurrencyName}
              size="md"
              className="h-fit mt-2 mr-2"
            />
            <Input
              onKeyDown={handleOnKeyDown}
              variant="filled"
              size="xl"
              type="number"
              value={amount}
              onChange={handleChangeAmount}
              onBlur={() =>
                Number.isNaN(Number(amount))
                  ? setFormValues({
                      ...formValues,
                      amount: 0,
                    })
                  : null
              }
              onWheel={(e) => e.currentTarget.blur()}
              bg="gray.50"
              p="0"
              ring="none"
              textColor="gray.900"
              className="display-lg"
              mb="1"
              _focus={{ border: 'none' }}
            />
          </Flex>
          <Text fontSize="md" fontWeight="normal" textColor="gray.700">{`~ ${t(
            'localCurrency'
          )} ${formattedFiatAmount}`}</Text>
        </Box>
        <Flex flexDirection="column" justifyContent="space-between">
          <Select
            wfit
            name="token"
            options={selectTokenOptions ?? []}
            value={token}
            icon={<TokenIcon token={token as TCurrencyName} size="md" />}
            onChange={handleChangeToken}
          />
          <HStack
            spacing="1"
            justifyContent="end"
            fontSize="md"
            fontWeight="normal"
            textColor="gray.700"
            textAlign="right"
          >
            <Text textColor={showError ? 'error.700' : 'inherit'}>
              {t('transferTokens.tokenAmountStep.balance')}
            </Text>
            {isLoadingBalance ? (
              <Spinner size="xs" ml="2" speed="0.75s" color="gray.300" />
            ) : (
              <Text textColor={showError ? 'error.700' : 'inherit'}>
                {formattedTokenBalance}
              </Text>
            )}
          </HStack>
        </Flex>
      </Flex>
      {showError && (
        <Text
          textAlign="center"
          fontSize="md"
          textColor="red.700"
          fontWeight="normal"
          mt="4"
        >
          {t('transferTokens.tokenAmountStep.error')}
        </Text>
      )}
    </>
  );
};
