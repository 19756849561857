import { useTranslation } from 'react-i18next';
import BasePage from '../BasePage';
import EmailConfirmation from './EmailConfirmation';

const EmailConfirmationPage = () => {
  const { t } = useTranslation();
  return (
    <BasePage
      title={t('softOnboarding.emailConfirmation.title')}
      subTitle={t('softOnboarding.emailConfirmation.subtitle')}
      allowBack={false}
    >
      <EmailConfirmation />
    </BasePage>
  );
};

export default EmailConfirmationPage;
