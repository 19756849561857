import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { Box, Flex } from '@chakra-ui/react';
import { PaymentRequestAsset, PaymentRequestType } from '@common/enum';
import { calculateWarrantyGCNetPricePerSaca } from '@common/utils';
import { Decimal } from 'decimal.js';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PaymentRequestExpirationTimer from '@comp/PaymentRequestView/Fragments/PaymentRequestExpirationTimer';
import { IPaymentRequest } from '../../../../common/types';
import { BreakDown, PaymentLayout, ShareLink, Title } from '../../Fragments';
import { PaymentSummaryReceiver } from '../PaymentSummary';

interface Props {
  paymentRequest: IPaymentRequest;
}
export const CreatedPayment: FC<Props> = ({ paymentRequest }) => {
  const { t } = useTranslation();
  const params = useParams();
  const paymentUrl = `${process.env.REACT_APP_BASE_URL}/payment-requests/${params.uuid}`;
  const amount = paymentRequest.amount;
  const estimatedFeeAmount: number = amount
    ? roundValue({
        value: amount * (paymentRequest.merchantFee / 100),
        operationType: EPaymentRequestOperationType.MERCHANT_FEE,
        scale: 2,
      }).toNumber()
    : 0;
  const createdDate = paymentRequest?.createdAt;
  const warrantyPricePerSaca = paymentRequest.warrantyMetadata?.pricePerSaca
    ? new Decimal(paymentRequest.warrantyMetadata?.pricePerSaca)
    : calculateWarrantyGCNetPricePerSaca(
        paymentRequest.warrantyMetadata?.warrantyGrainContract
      );

  let translateType: string;
  let asset: PaymentRequestAsset;
  switch (paymentRequest.type) {
    case PaymentRequestType.FUTURE_WARRANTY:
      translateType = 'warranty';
      asset = PaymentRequestAsset.WARRANTY;
      break;
    case PaymentRequestType.IN_KIND:
      translateType = 'kind';
      asset = PaymentRequestAsset.TOKENS;
      break;
    case PaymentRequestType.FIXED:
      translateType = 'fixed';
      asset = PaymentRequestAsset.FIXED_GRAIN;
      break;
    case PaymentRequestType.STANDARD:
    default:
      translateType = 'regular';
      asset = PaymentRequestAsset.TOKENS;
      break;
  }

  return (
    <PaymentLayout
      header={
        <>
          <Flex alignItems={'center'} gap={4}>
            <Title title={t(`paymentCreated.title.${translateType}`)} />
            <PaymentRequestExpirationTimer
              expirationTimestamp={+paymentRequest.expiresAt}
            />
          </Flex>
          <Title title={''} subtitle={t('paymentCreated.subtitle')} />
        </>
      }
      main={
        <Box>
          <ShareLink link={paymentUrl} />
          <BreakDown paymentRequest={paymentRequest} />
        </Box>
      }
      rightSide={
        <Box>
          <PaymentSummaryReceiver
            amount={amount}
            paymentAsset={asset ?? PaymentRequestAsset.TOKENS}
            tax={estimatedFeeAmount}
            status={asset === undefined ? 'EMPTY' : 'READY'}
            isPayer={false}
            createdDate={createdDate ? Number(createdDate) : 0}
            warrantyReceptionDate={
              paymentRequest.warrantyMetadata?.claimabilityDate
            }
            warrantyPricePerSaca={warrantyPricePerSaca}
            finalPrice={
              paymentRequest.fixedGrainContractMetadata?.fixedGrainContract
                .agreementDoc.finalPrice
            }
            receiverFee={new Decimal(paymentRequest.merchantFee)}
            payerFee={new Decimal(paymentRequest.payerFee)}
            forwardingsAmountSum={
              paymentRequest.forwardingMetadata?.totalAmount
            }
          />
        </Box>
      }
    />
  );
};
