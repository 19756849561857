export enum EPaymentRequestViewer {
  PAYER = 'payer',
  RECEIVER = 'receiver',
}

export enum PaymentRequestAsset {
  TOKENS = 'TOKENS',
  WARRANTY = 'WARRANTY',
  FIXED_GRAIN = 'FIXED_GRAIN',
}

export enum PaymentRequestType {
  STANDARD = 'STANDARD',
  IN_KIND = 'IN_KIND',
  FUTURE_WARRANTY = 'FUTURE_WARRANTY',
  FIXED = 'FIXED',
}

export enum PaymentRequestWarrantyStatus {
  NOT_CLAIMABLE = 'NOT_CLAIMABLE',
  CLAIMABLE = 'CLAIMABLE',
  CLAIMED = 'CLAIMED',
}

export enum WarrantyPaymentRequestStatusTranslated {
  CREATED = 'created',
  NOT_CLAIMABLE = 'notClaimable',
  CLAIMABLE = 'claimable',
  CLAIMED = 'claimed',
  EXPIRED = 'expired',
  COMPLETED = 'completed',
}
