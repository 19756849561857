import {
  Button,
  Flex,
  Heading,
  Text,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import image from '../../assets/images/login.png';
import agrotokenLogo from './../../assets/logos/logo_standard.svg';

const Layout: React.FC = (props) => {
  const { children } = props;

  const shouldRenderImage = useBreakpointValue([false, false, true]);
  const navigation = useNavigate();
  const goBack = () => navigation(-1);
  const { t } = useTranslation();

  return (
    <Flex
      h="full"
      py={['6', '6', '0']}
      minH={['full', 'full', 'full', '100vh']}
    >
      <Flex
        direction="column"
        w="full"
        px={['6', '6', '28']}
        py={['0', '0', '6']}
        justify="center"
      >
        <Image src={agrotokenLogo} w="40" mb="8" />
        <Button
          variant="linkPrimary"
          fontWeight="medium"
          fontSize="sm"
          w="fit-content"
          mb="8"
          onClick={goBack}
        >
          {'<- '} {t('general.goBack')}
        </Button>
        <Heading>{t('resetPasswordVerification.title')}</Heading>
        <Text>{t('resetPasswordVerification.subtitle')}</Text>
        {children}
      </Flex>
      {shouldRenderImage && (
        <Image src={image} maxW="30%" objectFit="cover" objectPosition="left" />
      )}
    </Flex>
  );
};

export default Layout;
