import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ResendEmailFormProps {
  email?: string;
  handleResendCode: () => void;
  setEmail: (email: string) => void;
  isLoading: boolean;
}

const ResendEmailForm: React.VFC<ResendEmailFormProps> = (props) => {
  const { email, handleResendCode, setEmail, isLoading } = props;
  const { t } = useTranslation();

  return (
    <>
      <Heading as="h6" size="xs">
        {t('softOnboarding.emailConfirmation.confirmationTitle')}
      </Heading>
      <Text>{t('softOnboarding.emailConfirmation.confirmationSubtitle')}</Text>
      <Flex my="6">
        <FormControl>
          <FormLabel htmlFor="email">
            <Heading as="h6" size="xs">
              {t('softOnboarding.emailConfirmation.emailLabel')}
            </Heading>
          </FormLabel>
          <Stack spacing="6" direction={['column', 'column', 'row']}>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Flex w="full">
              <Button
                variant="secondary"
                w="full"
                size="md"
                maxW="fit-content"
                onClick={handleResendCode}
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                {t('softOnboarding.emailConfirmation.resend')}
              </Button>
            </Flex>
          </Stack>
        </FormControl>
      </Flex>
    </>
  );
};

export default ResendEmailForm;
