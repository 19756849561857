import { Route } from 'react-router-dom';

import { TRANSFER_PATH, WARRANTY_PATH } from '@common/const';
import { RequireWarrantyTransferAccess } from '@comp/BR/AccessControl';

import CreateWarrantyTransfer from '../add';
import { CreateWarrantyTransferDataProvider } from '../add/providers';
import TransferDetail from '../detail';

const TransfersRouter = [
  <Route
    key="createWarrantyTransfer"
    path={`${TRANSFER_PATH}${WARRANTY_PATH}/:gcRequestNumber/add`}
    element={
      <RequireWarrantyTransferAccess>
        <CreateWarrantyTransferDataProvider>
          <CreateWarrantyTransfer />
        </CreateWarrantyTransferDataProvider>
      </RequireWarrantyTransferAccess>
    }
  />,
  <Route
    key="transferDetail"
    path={`${TRANSFER_PATH}/:uuid`}
    element={<TransferDetail />}
  />,
];

export default TransfersRouter;
