/* eslint-disable import/no-unresolved */
import { SOYA } from '@src/common/const';
import { useState, FC } from 'react';
import { ModalBackground } from '@comp/Global/ModalBackground';
import StepForm, { FormValues } from '@comp/Global/StepForm';
import { useDisableBodyScroll } from '@hooks/useDisabledBodyScroll';
import {
  InitialStep,
  SubmitStep,
  SuccessStep,
  TokenAmountStep,
  WalletStep,
} from './Steps';

interface TransferTokensProps {
  isOpen: boolean;
  handleClose: () => void;
}

export interface TransferTokensFormValues extends FormValues {
  token: string;
  amount: string;
  wallet: string;
}

export const defaultTransferTokensFormValues = {
  token: SOYA,
  amount: '0',
  wallet: '',
};

const TransferTokens: FC<TransferTokensProps> = (props) => {
  const { isOpen, handleClose } = props;
  useDisableBodyScroll(isOpen);

  const [formValues, setFormValues] = useState<TransferTokensFormValues>(
    defaultTransferTokensFormValues
  );

  const handleSumbit = () => {
    console.log('Transfer Sent');
  };

  if (!isOpen) return null;

  return (
    <ModalBackground>
      <section className="relative p-6 border border-gray-200 bg-white rounded-md w-full h-fit mx-4 mb-20 sm:my-auto z-50 max-w-lg">
        <StepForm
          onSubmit={handleSumbit}
          {...{ onClose: handleClose, formValues, setFormValues }}
        >
          <InitialStep />
          <TokenAmountStep />
          <WalletStep />
          <SubmitStep />
          <SuccessStep />
        </StepForm>
      </section>
    </ModalBackground>
  );
};

export default TransferTokens;
