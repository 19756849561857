import { HOME_PATH } from '@src/common/const';
import { useCards } from '@src/context/cardsContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@comp/Global/Modal';

const RequireCardsAccess: FC = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { shouldShowCardsMenu } = useCards();

  if (!shouldShowCardsMenu) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  return <>{children}</>;
};

export default RequireCardsAccess;
