import { Card } from '../features/cards/interfaces';
import { CARD_STATUS_UI, EXTERNAL_CARD_STATUS } from '../features/cards/types';
import PendingClock from '@src/components/svgs/pending-clock.svg';
import Phone from '@src/components/svgs/phone.svg';
import { BADGE_COLORS } from '@src/features/cards/types/badgeColorOptions';
import { t } from 'i18next';

export const getCardStatus = (card: Card) => {
  const uiBadgeProperties = [
    {
      uiStatus: CARD_STATUS_UI.INACTIVE,
      externalCardStatus: [
        EXTERNAL_CARD_STATUS.PENDING_ACTIVATION,
        EXTERNAL_CARD_STATUS.CREATED,
        EXTERNAL_CARD_STATUS.EMBOSSED,
      ],
      image: Phone,
      color: BADGE_COLORS.GRAY,
      text: t('cards.cardList.main.activation'),
    },
    {
      uiStatus: CARD_STATUS_UI.ACTIVE,
      externalCardStatus: [EXTERNAL_CARD_STATUS.ACTIVE],
      image: PendingClock,
      color: BADGE_COLORS.SUCCESS,
      text: `${t('cards.cardList.main.expiration')}${card.expiration}`,
    },
    {
      uiStatus: CARD_STATUS_UI.PAUSED,
      externalCardStatus: [EXTERNAL_CARD_STATUS.PAUSED],
      image: Phone,
      color: BADGE_COLORS.WARNING,
      text: t('cards.cardList.main.activation'),
    },
  ];

  const selectedBadgeProperties = uiBadgeProperties.find(
    (selectedBadgeProperties) =>
      selectedBadgeProperties.externalCardStatus.includes(
        card.status.toUpperCase()
      )
  );
  return selectedBadgeProperties;
};
