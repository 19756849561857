import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IWarehouse } from '../../../common/types';
import 'react-datepicker/dist/react-datepicker.css';
import SearcherBr from './Searcher';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';

interface Props {
  className?: string;
  cdeTons: number;
  searchDataValue: string;
  warehouses: IWarehouse[] | undefined;
  setSearchDataValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  isValidSearchData: boolean;
  shouldShowError: boolean;
  selectedWarehouse: IWarehouse | undefined;
  setSelectedWarehouse: React.Dispatch<
    React.SetStateAction<IWarehouse | undefined>
  >;
  isLoadingWarehouses: boolean;
  isDisabled?: boolean;
}

export const FormBr: FC<Props> = ({
  warehouses,
  className,
  searchDataValue,
  setSearchDataValue,
  isValidSearchData,
  selectedWarehouse,
  setSelectedWarehouse,
  shouldShowError,
  isLoadingWarehouses,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <form className={`${className}`}>
      <div className={selectedWarehouse ? 'py-8' : 'pt-8'}>
        <div className="grid md:grid-cols-1 gap-x-6 gap-y-8 mb-8 xl:w-[50%]">
          <SearcherBr
            isLoadingWarehouses={isLoadingWarehouses}
            selectedWarehouse={selectedWarehouse}
            setSelectedWarehouse={setSelectedWarehouse}
            warehouses={warehouses}
            setSearchDataValue={setSearchDataValue!}
            isValidSearchData={isValidSearchData}
            searchErrorMessage={'error'}
            shouldShowError={shouldShowError}
            searchDataValue={searchDataValue}
            isDisabled={isDisabled}
          />
        </div>
        {selectedWarehouse && (
          <div className="grid md:grid-cols-2 gap-x-6 gap-y-8">
            <FormControl>
              <FormLabel>
                {t('tokenization.proofOfExistence.form.addressNumberLabel')}
              </FormLabel>
              <Input
                name="addressNumber"
                value={selectedWarehouse?.cda}
                disabled
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('tokenization.proofOfExistence.form.addressLabel')}
              </FormLabel>
              <Input
                name="address"
                value={selectedWarehouse?.address}
                disabled
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('tokenization.proofOfExistence.form.stateLabel')}
              </FormLabel>
              <Input
                name="state"
                value={selectedWarehouse?.city.geographicalDivision?.name}
                disabled
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                {t('tokenization.proofOfExistence.form.cityLabel')}
              </FormLabel>
              <Input
                name="city"
                value={selectedWarehouse?.city.name}
                disabled
              />
            </FormControl>
          </div>
        )}
      </div>
    </form>
  );
};
