import { IDocument } from '@common/types';
import { useUser } from '@src/context/userContext';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { PaymentForwardingFormData } from '@comp/PaymentRequestView/CreatePaymentRequest/types';
import useDebounce from '@hooks/useDebounce';
import { getTransactionableBusinessByFiscalId } from '@services/Businesses';
import { deleteDocument as deleteDocumentService } from '@services/Documents';

interface UseSearchBusinessParams {
  forwardings: PaymentForwardingFormData[];
  setForwardings: React.Dispatch<
    React.SetStateAction<PaymentForwardingFormData[]>
  >;
  index: number;
  payerFiscalId: string;
  amountToReceive: number;
}

const useForwardPaymentForm = ({
  forwardings,
  setForwardings,
  index,
  payerFiscalId,
  amountToReceive,
}: UseSearchBusinessParams) => {
  const { t } = useTranslation();

  const { selectedBusiness } = useUser();

  const currentAmount = forwardings[index].amount;
  const currentFiscalId = forwardings[index].fiscalId;
  const debouncedFiscalId = useDebounce(currentFiscalId, 1000);

  const { mutate: deleteDocument } = useMutation(deleteDocumentService);

  const {
    data: business,
    isLoading: isLoadingBusiness,
    error: searchBusinessError,
  } = useQuery(
    ['business', debouncedFiscalId],
    () => getTransactionableBusinessByFiscalId(debouncedFiscalId),
    {
      enabled: !!debouncedFiscalId,
      retry: false,
    }
  );

  const businessError: string = useMemo(() => {
    if (!currentFiscalId) {
      return '';
    }

    // Check if the business search throws an error
    if (searchBusinessError && typeof searchBusinessError === 'object') {
      const axiosError = searchBusinessError as AxiosError;

      // Check if the error is status 404 (Not Found)
      if (axiosError.response?.status === 404) {
        return t('createPayment.form.forwardings.fiscalId.errors.notFound', {
          fiscalPrefix: 'CUIT',
        });
      }

      return t('createPayment.form.forwardings.fiscalId.errors.unknown');
    }

    // Check if forwardings `fiscalId` is equal to payment request creator `fiscalId`
    const isCreatorFiscalId = debouncedFiscalId === selectedBusiness?.fiscalId;
    if (isCreatorFiscalId) {
      return t('createPayment.form.forwardings.fiscalId.errors.equalCreator', {
        fiscalPrefix: 'CUIT',
      });
    }

    // Check if forwardings `fiscalId` is equal to payment request payer `fiscalId`
    const isPayerFiscalId = debouncedFiscalId === payerFiscalId;
    if (isPayerFiscalId) {
      return t('createPayment.form.forwardings.fiscalId.errors.equalPayer', {
        fiscalPrefix: 'CUIT',
      });
    }

    // Check if forwardings `fiscalId` are duplicated
    const areForwardingFiscalIdsDuplicated =
      forwardings.filter(
        (forwarding) => forwarding.fiscalId === debouncedFiscalId
      ).length > 1;
    if (areForwardingFiscalIdsDuplicated) {
      return t('createPayment.form.forwardings.fiscalId.errors.repeat', {
        fiscalPrefix: 'CUIT',
      });
    }

    return '';
  }, [
    currentFiscalId,
    debouncedFiscalId,
    forwardings,
    payerFiscalId,
    searchBusinessError,
    selectedBusiness?.fiscalId,
    t,
  ]);

  const amountError: string = useMemo(() => {
    if (!currentAmount) {
      return '';
    }

    const forwardingsAmount = forwardings.reduce((acc, currentValue) => {
      const amount = Number.isNaN(parseFloat(currentValue.amount))
        ? 0
        : parseFloat(currentValue.amount);

      return acc + amount;
    }, 0);

    // Check if the sum of forwardings amounts are greater than the amount to receive
    if (forwardingsAmount > amountToReceive) {
      return t('createPayment.form.forwardings.amount.error', {
        fiatCurrency: 'ARS$',
        amountToReceive,
        minimumFractionDigits: 2,
      });
    }

    return '';
  }, [amountToReceive, currentAmount, forwardings, t]);

  const onChangeFiscalIdHandler = (
    ev: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedForwardings = forwardings.map((el, i) =>
      i === index ? { ...el, fiscalId: ev.currentTarget.value } : el
    );
    setForwardings(updatedForwardings);
  };

  const onChangeConceptHandler = (
    ev: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedForwardings = forwardings.map((el, i) =>
      i === index ? { ...el, concept: ev.currentTarget.value } : el
    );
    setForwardings(updatedForwardings);
  };

  const onChangeAmountHandler = (
    ev: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedForwardings = forwardings.map((el, i) =>
      i === index ? { ...el, amount: ev.currentTarget.value } : el
    );
    setForwardings(updatedForwardings);
  };

  const onClickDeleteUserHandler = (index: number) => {
    const documentUuid = forwardings[index].documentUuid;

    if (documentUuid) {
      deleteDocument(documentUuid);
    }

    const updatedForwardings = forwardings.filter((_el, i) => i !== index);
    setForwardings(updatedForwardings);
  };

  const onSuccessUploadedFileHandler = (data: IDocument, index: number) => {
    const updatedForwardings = forwardings.map((el, i) =>
      i === index ? { ...el, documentUuid: data.uuid } : el
    );
    setForwardings(updatedForwardings);
  };

  const onSuccessDeleteFileHandler = (index: number) => {
    const updatedForwardings = forwardings.map((el, i) =>
      i === index ? { ...el, documentUuid: null } : el
    );
    setForwardings(updatedForwardings);
  };

  return {
    business,
    isLoadingBusiness,
    businessError,
    amountError,
    onChangeFiscalIdHandler,
    onChangeAmountHandler,
    onSuccessDeleteFileHandler,
    onSuccessUploadedFileHandler,
    onClickDeleteUserHandler,
    onChangeConceptHandler,
  };
};

export default useForwardPaymentForm;
