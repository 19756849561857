import { Checkbox, Link, Text } from '@chakra-ui/react';
import { HOME_PATH } from '@common/const';
import { IBusinessUser } from '@common/types';
import { getTodayTimestamp } from '@common/utils';
import { useUser } from '@src/context/userContext';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@comp/Global/Modal';
import { updateBusinessUser } from '@services/BusinessUsers';

const AcceptTokenizationsTermsAndCondsModal = ({
  isOpen,
}: {
  isOpen: boolean;
}) => {
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen);
  const {
    selectedBusinessUserId,
    setSelectedBusinessUser,
    selectedBusinessUser,
  } = useUser();

  const handleCheckbox = (e: any) => {
    setAcceptTerms(e.target.checked);
  };
  const navigate = useNavigate();

  const onSubmit = async () => {
    const today = getTodayTimestamp();
    const isUpdateSuccessful = await updateBusinessUser({
      id: Number(selectedBusinessUserId),
      body: {
        tokenizationConditionsAcceptedAt: today,
      },
    });
    if (isUpdateSuccessful) {
      setSelectedBusinessUser({
        ...(selectedBusinessUser as IBusinessUser),
        tokenizationConditionsAcceptedAt: today,
      });
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      title={t('acceptTermAndCondsModal.title')}
      labelBtnConfirm={t('acceptTermAndCondsModal.confirmBtn')}
      labelBtnClose={t('acceptTermAndCondsModal.closeBtn')}
      iconName="alert-circle"
      iconColor="gray"
      onClickConfirm={() => onSubmit()}
      onClickClose={() => navigate(HOME_PATH)}
      disabled={!acceptTerms}
    >
      <div className="flex">
        <Checkbox
          className="mr-1"
          id="terms"
          onChange={handleCheckbox}
          checked={acceptTerms}
          name="terms"
          type="checkbox"
          required
        />
        <div className="flex flex-col mr-2">
          <div className="flex items-center">
            <span className="text-sm font-medium text-gray-700 mr-1">
              {t('fullOnboarding.common.actions.accept')}
            </span>
            <Link
              className="font-medium text-sm"
              href={t('fullOnboarding.common.actions.termsAndConditionsLink')}
              target="_blank"
              rel="noreferrer"
            >
              <Text
                color="primary.700"
                fontWeight="medium"
                fontSize="sm"
                display="inline-block"
                mr="2"
              >
                {t('fullOnboarding.common.actions.termsAndConditions')}
              </Text>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AcceptTokenizationsTermsAndCondsModal;
