import { Box, Heading, Text } from '@chakra-ui/react';
import {
  IDocument,
  IDocumentPreview,
  IDocumentForBusinessUser,
} from '@src/common/types';
import { useEffect, useState, VFC } from 'react';
import { useMutation } from 'react-query';
import { DragAndDrop } from '@comp/Global/DragAndDrop';
import { SuccessPreview } from '@comp/Global/DragAndDrop/SuccessPreview';
import { deleteDocument } from '@services/Documents';

interface Props {
  title?: string;
  description: string;
  documentId: number | null;
  setDocumentId: React.Dispatch<React.SetStateAction<number | null>>;
  documentTypeId: number;
  maxSize?: number;
  isValid?: boolean;
  allowImages?: boolean;
  defaultFile?:
    | IDocument
    | IDocumentPreview
    | IDocumentForBusinessUser
    | undefined;
  registrationProps?: any;
  disabled?: boolean;
}
const FileUploader: VFC<Props> = ({
  title,
  description,
  documentId,
  setDocumentId,
  documentTypeId,
  maxSize = 20_000_000,
  isValid = true,
  allowImages = false,
  defaultFile: defaultFileFromProps,
  registrationProps,
  disabled,
}) => {
  const deleteCallback = () => setDocumentId(null);
  const [showDefaultFile, setShowDefaultValue] = useState(true);

  useEffect(() => {
    if (defaultFileFromProps?.id) {
      setDocumentId(defaultFileFromProps.id);
    }
  }, [defaultFileFromProps?.id]);

  const { mutate: mutateDeleteDocument, isLoading: isLoadingDelete } =
    useMutation(deleteDocument, {
      onSuccess: (_data: IDocument | undefined) => {
        setDocumentId(null);
        setShowDefaultValue(false);
      },
    });
  return (
    <Box>
      {title && <Heading size="xs">{title}</Heading>}
      <Text size="sm" variant="regular" my="1.5">
        {description}
      </Text>
      {defaultFileFromProps && showDefaultFile ? (
        <SuccessPreview
          fileName={defaultFileFromProps.originalName}
          fileSize={Number(defaultFileFromProps.size)}
          onClickDelete={() => mutateDeleteDocument(defaultFileFromProps.uuid)}
          isLoading={isLoadingDelete}
          disabled={disabled}
        />
      ) : (
        <DragAndDrop
          isValid={isValid}
          shouldShowError={!isValid}
          documentId={documentId}
          setDocumentId={setDocumentId}
          deleteCallback={deleteCallback}
          documentTypeId={documentTypeId}
          maxFileSize={maxSize}
          allowImages={allowImages}
          registrationProps={registrationProps}
        />
      )}
    </Box>
  );
};

export default FileUploader;
