import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import { VFC } from 'react';
import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Watch } from '@comp/FullOnboarding/types';
import { TRegister } from '../types';
import FiscalIdField from './FiscalIdFIeld';
import { FormValues } from './GeneralInfoForm';
import { EBusinessStructureTypes } from '@common/types';

interface Props {
  errors: FormState<FormValues>['errors'];
  register: TRegister;
  watch: Watch;
}
const PersonFields: VFC<Props> = ({ register, errors, watch }) => {
  const { t } = useTranslation();

  return (
    <>
      <SimpleGrid columns={[1, 1, 1, 1, 2]} gap="6">
        <FormControl isInvalid={!!errors.givenName} mr="6">
          <FormLabel htmlFor="name">
            {t('softOnboarding.signUpScreen.form.name')}
          </FormLabel>
          <Input
            id="name"
            placeholder={t('softOnboarding.signUpScreen.form.namePlaceholder')}
            h="12"
            {...register('givenName', { required: `${t('shared.required')}` })}
          />
          {errors.givenName && (
            <FormErrorMessage>{errors.givenName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.lastName}>
          <FormLabel htmlFor="lastName">
            {t('softOnboarding.signUpScreen.form.lastName')}
          </FormLabel>
          <Input
            id="lastName"
            placeholder={t(
              'softOnboarding.signUpScreen.form.lastNamePlaceholder'
            )}
            h="12"
            {...register('lastName', { required: `${t('shared.required')}` })}
          />
          {errors.lastName && (
            <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FiscalIdField
          register={register}
          errors={errors}
          watch={watch}
          businessType={EBusinessStructureTypes.PERSON}
        />
      </SimpleGrid>
    </>
  );
};

export default PersonFields;
