import { Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import GenericTransactionLayout from '@layouts/GenericTransactionLayout';

import { WarrantyTransferSummaryDetail } from '../../transfers/components';
import { CreateWarrantyTransferHeader, TermsAndConditions } from './components';
import {
  CreateWarrantyTransferForm,
  CREATE_WARRANTY_TRANSFER_FORM_ID,
} from './forms';
import { useCreateTransfer, useCreateWarrantyTransferForm } from './hooks';

const CreateWarrantyTransfer = () => {
  const { t } = useTranslation();

  const { form, businessSearch, preview, debouncedAmount, debouncedFiscalId } =
    useCreateWarrantyTransferForm();

  const {
    mutate: createTransfer,
    isLoading: isLoadingCreateTransfer,
    error: errorCreateTransfer,
  } = useCreateTransfer();

  const {
    amountFiat,
    issuerFeePercentage,
    issuerFeeAmountFiat,
    warrantyTransfer,
  } = preview.data ?? {};
  const { amountSacas, issuerFeeAmountSacas } = warrantyTransfer ?? {};

  const { watch } = form;

  const hasAcceptedTermsAndConds = watch('hasAcceptedTermsAndConds');

  const isSubmitDisabled = !hasAcceptedTermsAndConds;
  const isSummaryEmpty =
    !debouncedAmount || !debouncedFiscalId || !businessSearch.data;

  return (
    <GenericTransactionLayout
      header={<CreateWarrantyTransferHeader />}
      main={
        <CreateWarrantyTransferForm
          form={form}
          businessSearch={businessSearch}
          createTransfer={createTransfer}
        />
      }
      aside={
        <Flex flexDirection="column" rowGap="4">
          <WarrantyTransferSummaryDetail
            feeAmountFiat={Number(issuerFeeAmountFiat)}
            feeAmountSacas={Number(issuerFeeAmountSacas)}
            feePercentage={Number(issuerFeePercentage)}
            totalAmountFiat={Number(amountFiat) + Number(issuerFeeAmountFiat)}
            totalAmountSacas={
              Number(amountSacas) + Number(issuerFeeAmountSacas)
            }
            transferAmountFiat={Number(amountFiat)}
            transferAmountSacas={Number(amountSacas)}
            isSender
            isEmpty={isSummaryEmpty}
            isLoading={preview.isLoading}
          />
          <TermsAndConditions form={form} />
          <Button
            size="lg"
            variant="primary"
            type="submit"
            isFullWidth
            form={CREATE_WARRANTY_TRANSFER_FORM_ID}
            isDisabled={isSubmitDisabled}
            isLoading={isLoadingCreateTransfer}
          >
            {t('transfers.warrantySummary.request')}
          </Button>
          {/* TODO: This error should be translated on service by checking error code */}
          {errorCreateTransfer && (
            <Text size="sm" variant="regular" color="error.500">
              {t('transfers.warrantySummary.errors.unknown')}
            </Text>
          )}
        </Flex>
      }
    />
  );
};

export default CreateWarrantyTransfer;
