import { CoinsIcon, Divider } from '@agrotoken-tech/ui';
import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import {
  FiCopy,
  FiFile,
  FiFileText,
  FiHash,
  FiMessageSquare,
  FiUser,
} from 'react-icons/fi';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BlockchainState } from '@comp/Global/BlockchainState';
import { ErrorMessage } from '@comp/Global/ErrorMessage';
import { Icon } from '@comp/Global/Icon';
import { Title } from '@comp/Global/Title';
import { TokenIcon } from '@comp/Global/TokenIcon';
import { ItemIcon, PaymentLayout } from '@comp/PaymentRequestView/Fragments';
import PaymentRequestExpirationTimer from '@comp/PaymentRequestView/Fragments/PaymentRequestExpirationTimer';
import PaymentRequestParameters from '@comp/PaymentRequestView/Fragments/PaymentRequestParameters/PaymentRequestParameters';
import SelectTypePaymentMethod from '@comp/PaymentRequestView/Fragments/SelectTypePaymentMethod/SelectTypePaymentMethod';
import { PaymentsFeeModal } from '@comp/PaymentsFeeModal';
import useGetLocalCurrency from '@hooks/useGetLocalCurrency';
import { findAmountToCoverPayment } from '@services/GrainContracts';
import {
  getPaymentFixation,
  getPaymentRequestStatus,
  makePayment,
  getDocumentUrl,
} from '@services/PaymentRequests';
import {
  HOME_PATH,
  PAYER_FEE_MODAL_COOKIE,
  PAYMENT_PATH,
} from '@src/common/const';
import {
  EPaymentRequestViewer,
  PaymentRequestAsset,
  PaymentRequestType,
} from '@src/common/enum';
import { useAuth } from '@src/context/authContext';
import { useRates } from '@src/context/ratesContext';
import {
  CryptoCurrencySymbol,
  ECurrencyId,
  ETokens,
  ETokensBR,
  EUnits,
  IPaymentRequest,
  PAYMENT_REQUEST_STATUSES,
  TBreadcrumbOption,
  TCryptoCurrencyName,
  TCurrencyName,
} from '../../../../common/types';
import {
  calculateWarrantyGCNetPricePerSaca,
  estimatedFee,
  formatNumber,
} from '../../../../common/utils';
import { useUser } from '../../../../context/userContext';
import { PaymentSummaryPayer } from '../PaymentSummary';
import { useCalculateTokensToPayByFarmer } from './hooks';
import { ENationalities } from '@agrotoken/common-utils';

interface MakePaymentProps {
  paymentRequest: IPaymentRequest;
}

export const MakePayment: FC<MakePaymentProps> = ({ paymentRequest }) => {
  // ===== Constants =====
  const {
    id,
    uuid,
    amount,
    fromBusinessUser,
    fromBusinessUserId,
    description,
    document,
    toBusiness,
    operation,
    type,
    warrantyMetadata,
    fixedGrainContractMetadata,
    expiresAt,
  } = paymentRequest;

  const asset: PaymentRequestAsset = {
    [PaymentRequestType.FUTURE_WARRANTY]: PaymentRequestAsset.WARRANTY,
    [PaymentRequestType.IN_KIND]: PaymentRequestAsset.TOKENS,
    [PaymentRequestType.STANDARD]: PaymentRequestAsset.TOKENS,
    [PaymentRequestType.FIXED]: PaymentRequestAsset.FIXED_GRAIN,
  }[type];

  // ===== Hooks =====
  const { localCurrency } = useGetLocalCurrency();
  const { t } = useTranslation();
  const { currentUser } = useUser();
  const { getRates, currencies } = useRates();
  const { nationality } = useAuth();
  const [token, setToken] = useState<TCryptoCurrencyName>();
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
  const [tonsToReserve, setTonsToReserve] = useState<{
    reserve: number;
    feeReserve: number;
  } | null>(null);
  const [availableAmount, setAvailableAmount] = useState<number | null>(null);
  const [error, setError] = useState<string>('');
  const [isLoadingSummary, setIsLoadingSummary] = useState<boolean>(false);
  const [isInBlockchain, setIsInBlockchain] = useState<boolean>(false);
  const [selectedTerm, setSelectedTerm] = useState<number>();
  const [isOpenPaymentsFeeModal, setIsOpenPaymentsFeeModal] = useState(false);

  const [cookies, setCookie] = useCookies([PAYER_FEE_MODAL_COOKIE]);
  const rememberPayerFee = cookies[PAYER_FEE_MODAL_COOKIE] === 'true';
  const intervalRef = useRef<NodeJS.Timeout | undefined>();
  const navigate = useNavigate();

  const checkIfPaymentIsCompleted = useCallback(async () => {
    const { status } = await getPaymentRequestStatus(uuid);

    if (status !== PAYMENT_REQUEST_STATUSES.COMPLETED) return;

    clearInterval(intervalRef.current);
    setIsInBlockchain(false);

    if (paymentRequest.redirectUrl !== null) {
      navigate(`success?redirectUrl=${paymentRequest.redirectUrl}`);
    } else {
      navigate('success');
    }
  }, [uuid, paymentRequest.redirectUrl, navigate]);

  const getAvailableAmount = useCallback(async () => {
    if (!currentUser) return;
    const res = await findAmountToCoverPayment(
      fromBusinessUserId,
      ECurrencyId[token as TCurrencyName],
      currentUser.authUuid,
      uuid
    );
    setAvailableAmount(res.amount);
  }, [currentUser, fromBusinessUserId, uuid, token]);

  const getTonsToReserve = useCallback(async () => {
    if (!currentUser) return;
    const res = await getPaymentFixation(
      uuid,
      currentUser.authUuid,
      ECurrencyId[token as TCurrencyName],
      nationality
    );
    setTonsToReserve(res);
  }, [currentUser, uuid, token, nationality]);

  // ===== Queries | Mutations =====
  const { mutate: mutateMakePayment, isLoading: isLoadingMakePayment } =
    useMutation(
      makePayment(
        uuid,
        paymentRequest.type === PaymentRequestType.STANDARD ||
          paymentRequest.type === PaymentRequestType.IN_KIND
          ? ECurrencyId[token as keyof typeof ECurrencyId]
          : undefined
      ),
      {
        onSuccess: () => {
          setError('');
          setIsInBlockchain(true);
        },
        onError: (_err: Error) => {
          setError(t('generalError'));
        },
      }
    );

  // ===== Helpers =====
  let paymentTypeTranslation: string;
  let isPaymentInKind = false;
  switch (
    type // TODO: Centralize and reuse in every screen.
  ) {
    case PaymentRequestType.IN_KIND:
      paymentTypeTranslation = 'kind';
      isPaymentInKind = true;
      break;
    case PaymentRequestType.FUTURE_WARRANTY:
      paymentTypeTranslation = 'warranty';
      break;
    case PaymentRequestType.STANDARD:
    default:
      paymentTypeTranslation = 'regular';
      break;
  }

  //  =====  Calculate amounts  =====
  let subtotalTokensAmount: Decimal | null = useCalculateTokensToPayByFarmer({
    localAmount: new Decimal(amount),
    selectedToken: token as unknown as ETokensBR | ETokens,
  });
  subtotalTokensAmount = amount && token ? subtotalTokensAmount : null;

  const estimatedFeeAmount: number = estimatedFee(
    Number(amount),
    paymentRequest,
    true
  );
  let feeTokensAmount = useCalculateTokensToPayByFarmer({
    localAmount: new Decimal(estimatedFeeAmount),
    selectedToken: token as unknown as ETokensBR | ETokens,
    isFee: true,
  });
  feeTokensAmount =
    estimatedFeeAmount && token ? feeTokensAmount : new Decimal(0);

  const totalTokensAmount = subtotalTokensAmount
    ? subtotalTokensAmount?.plus(feeTokensAmount ?? 0)
    : null;

  const hasAmountToCoverPayment =
    paymentRequest.type === PaymentRequestType.FUTURE_WARRANTY ||
    ((paymentRequest.type === PaymentRequestType.IN_KIND ||
      paymentRequest.type === PaymentRequestType.STANDARD) &&
      totalTokensAmount &&
      availableAmount &&
      totalTokensAmount.lessThanOrEqualTo(availableAmount));

  const fixationTotalAmount = isPaymentInKind
    ? subtotalTokensAmount
    : totalTokensAmount;
  const fixationAmountTons = new Decimal(tonsToReserve?.reserve ?? 0).minus(
    fixationTotalAmount ?? 0
  );
  const fixationFeeAmount = feeTokensAmount;
  const fixationFeeTons = new Decimal(tonsToReserve?.feeReserve ?? 0).minus(
    fixationFeeAmount ?? 0
  );

  const handleMakePayment = async () => {
    setError('');
    mutateMakePayment();
  };

  const handleViewDocument = () => {
    const getUrl = async () => {
      setIsLoadingUrl(true);
      try {
        const url = await getDocumentUrl(id, document.uuid);
        window.open(url, '_blank');
      } catch (error) {
        /**
         * TODO: handle error
         */
      } finally {
        setIsLoadingUrl(false);
      }
    };
    getUrl();
  };

  const onClosePaymentFeeModal = () => {
    setIsOpenPaymentsFeeModal(false);
  };

  const onAcceptPaymentFeeModal = () => {
    setCookie(PAYER_FEE_MODAL_COOKIE, true);
    setIsOpenPaymentsFeeModal(false);
  };

  const breadcrumbOptions: TBreadcrumbOption[] = [
    { label: t('breadCrumb.paymentRequests'), to: PAYMENT_PATH },
    {
      label: `${t('breadCrumb.paymentRequestDetails')} #${id}`,
      to: '',
    },
  ];

  const shouldDisableButton = () => {
    return (
      ((paymentRequest.type === PaymentRequestType.STANDARD ||
        paymentRequest.type === PaymentRequestType.IN_KIND) &&
        (!totalTokensAmount ||
          !availableAmount ||
          !hasAmountToCoverPayment ||
          isLoadingMakePayment ||
          isInBlockchain)) ||
      ((paymentRequest.type === PaymentRequestType.FUTURE_WARRANTY ||
        paymentRequest.type === PaymentRequestType.FIXED) &&
        (isLoadingMakePayment || isInBlockchain))
    );
  };

  const warrantyPricePerSaca = paymentRequest.warrantyMetadata?.pricePerSaca
    ? new Decimal(paymentRequest.warrantyMetadata?.pricePerSaca)
    : calculateWarrantyGCNetPricePerSaca(
        paymentRequest.warrantyMetadata?.warrantyGrainContract
      );

  const getGeneralDataElements = (): ReactNode[] => {
    const generalDataElements: ReactNode[] = [];

    generalDataElements.push(
      <ItemIcon
        icon={FiHash}
        label={t('payPaymentRequest.breakdown.transactionNumber')}
        value={
          <>
            {paymentRequest.uuid}
            <CopyToClipboard text={paymentRequest.uuid}>
              <IconButton variant="link" aria-label="copy" icon={<FiCopy />} />
            </CopyToClipboard>
          </>
        }
        className="mb-4"
      />
    );

    generalDataElements.push(
      <ItemIcon
        icon={FiUser}
        label={t(
          `payPaymentRequest.breakdown.to.${
            operation?.createdAt ? 'paymentMake' : 'paymentSent'
          }`
        )}
        value={`${toBusiness.name} - ${toBusiness.fiscalId}`}
        className="mb-4"
      />
    );

    warrantyMetadata &&
      generalDataElements.push(
        <ItemIcon
          icon={FiFileText}
          label={t('payPaymentRequest.breakdown.warrantyLabel')}
          value={t('payPaymentRequest.breakdown.warranty', {
            grainContractFutureId:
              warrantyMetadata?.warrantyGrainContract.requestNumber,
          })}
          className="mb-4"
        />
      );

    fixedGrainContractMetadata &&
      generalDataElements.push(
        <ItemIcon
          icon={FiFileText}
          label={t('payPaymentRequest.breakdown.fixedGrainLabel')}
          value={t(
            `payPaymentRequest.breakdown.fixedGrain.${fixedGrainContractMetadata.fixedGrainContract.currency.name}`,
            {
              fixedGrainRequestNumber:
                fixedGrainContractMetadata.fixedGrainContract.requestNumber,
            }
          )}
          className="mb-4"
        />
      );

    generalDataElements.push(
      <ItemIcon
        icon={FiMessageSquare}
        label={<Text> {t('payPaymentRequest.breakdown.comments')}</Text>}
        value={<Box>{description ?? ''}</Box>}
        className="mb-4"
        isBold={false}
      />
    );

    generalDataElements.push(
      <ItemIcon
        icon={FiFile}
        label={t('payPaymentRequest.breakdown.file')}
        value={
          paymentRequest?.document ? (
            <Flex className="mt-2">
              <Icon
                size="sm"
                color="gray"
                theme="no-background"
                icon="download"
              />
              <Button
                size="sm"
                variant="linkPrimary"
                onClick={handleViewDocument}
                isLoading={isLoadingUrl}
              >
                <Text fontWeight={'bold'} color="#288703">
                  {document.originalName}
                </Text>
              </Button>
            </Flex>
          ) : null
        }
        className="mb-4"
      />
    );

    return generalDataElements;
  };

  const getPaymentDataElements = (): ReactNode[] => {
    const generalDataElements: ReactNode[] = [];
    generalDataElements.push(
      <Box marginLeft={'32px'}>
        <ItemIcon
          label={t('payPaymentRequest.breakdown.amountToSend')}
          className="mb-4"
        />
      </Box>
    );
    generalDataElements.push(
      <Box marginLeft={'32px'}>
        <ItemIcon
          icon={FiUser}
          label={`${fromBusinessUser?.business.name} - ${fromBusinessUser?.business.fiscalId}`}
          className="mb-4"
        />
      </Box>
    );

    generalDataElements.push(
      <Box marginLeft={'32px'}>
        <ItemIcon
          icon={CoinsIcon as IconType}
          label={t('payPaymentRequest.breakdown.localEquivalence')}
          value={formatNumber(amount, localCurrency, true)}
          className="mb-4"
        />
      </Box>
    );

    return generalDataElements;
  };

  // ===== Effects =====
  useEffect(() => {
    getRates();
    if (rememberPayerFee) return;
    setIsOpenPaymentsFeeModal(true);
  }, []);

  useEffect(() => {
    if (!operation) return;
    setIsInBlockchain(true);
    setToken(operation.currency?.name as TCryptoCurrencyName);
  }, [operation]);

  useEffect(() => {
    if (!token && type !== PaymentRequestType.FUTURE_WARRANTY) return;
    const fetchData = async () => {
      setIsLoadingSummary(true);
      try {
        if (type !== PaymentRequestType.FUTURE_WARRANTY) {
          await getAvailableAmount();
          await getTonsToReserve();
        }
      } catch (error) {
        setError(''); // TODO: handle error
      } finally {
        setIsLoadingSummary(false);
      }
    };
    fetchData();
  }, [currentUser, getAvailableAmount, getTonsToReserve, token, currencies]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isInBlockchain) {
        await checkIfPaymentIsCompleted();
      }
    }, 6000);
    intervalRef.current = interval;
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [checkIfPaymentIsCompleted, isInBlockchain]);

  return (
    <Box>
      <PaymentsFeeModal
        isOpen={isOpenPaymentsFeeModal}
        onAccept={onAcceptPaymentFeeModal}
        onClose={onClosePaymentFeeModal}
        viewer={EPaymentRequestViewer.PAYER}
      />
      <PaymentLayout
        breadcrumbOptions={breadcrumbOptions}
        header={
          <Flex alignItems={'center'} gap={4}>
            <Title
              title={t(`payPaymentRequest.title.${paymentTypeTranslation}`)}
            />
            <PaymentRequestExpirationTimer expirationTimestamp={expiresAt} />
          </Flex>
        }
        main={
          <Box>
            <PaymentRequestParameters
              createdAtPaymentRequest={Number(paymentRequest.createdAt)}
              paymentRequestStatus={paymentRequest.status}
              generalDataElements={getGeneralDataElements()}
              paymentDataElements={getPaymentDataElements()}
            />
            <Box className="pt-6">
              <SelectTypePaymentMethod
                paymentRequestId={id}
                paymentRequestUuid={uuid}
                type={type}
                token={token}
                setToken={setToken}
                isInBlockchain={isInBlockchain}
                selectedTerm={selectedTerm}
                setSelectedTerm={setSelectedTerm}
              />
            </Box>
          </Box>
        }
        rightSide={
          <>
            <PaymentSummaryPayer
              amount={amount}
              paymentAsset={asset ?? PaymentRequestAsset.TOKENS}
              tax={estimatedFeeAmount}
              status={
                asset === undefined ||
                (asset === PaymentRequestAsset.TOKENS && !token)
                  ? 'EMPTY'
                  : 'READY'
              }
              isPayer={true}
              warrantyPricePerSaca={warrantyPricePerSaca}
              fixedFinalPrice={
                fixedGrainContractMetadata?.fixedGrainContract.agreementDoc
                  .finalPrice
              }
              selectedToken={
                asset === PaymentRequestAsset.TOKENS
                  ? (token as CryptoCurrencySymbol)
                  : (paymentRequest.warrantyMetadata?.warrantyGrainContract
                      .currency.name as CryptoCurrencySymbol)
              }
              receiverFee={new Decimal(paymentRequest.merchantFee)}
              payerFee={new Decimal(paymentRequest.payerFee)}
              forwardings={paymentRequest?.forwardingMetadata?.forwardings}
            />
            {((asset === PaymentRequestAsset.TOKENS && token) ||
              asset === PaymentRequestAsset.WARRANTY ||
              asset === PaymentRequestAsset.FIXED_GRAIN) && (
              <>
                {!isLoadingSummary && (
                  <>
                    {(type === PaymentRequestType.IN_KIND ||
                      type === PaymentRequestType.STANDARD) &&
                      hasAmountToCoverPayment &&
                      ENationalities.BR !== nationality && (
                        <>
                          <Text
                            className={`text-sm font-normal text-gray-700 ${
                              isPaymentInKind ? 'mb-1' : 'mb-6'
                            }`}
                          >{`${t(
                            `payPaymentRequest.tonsToRegister.text1.${paymentTypeTranslation}`
                          )} (${formatNumber(
                            fixationTotalAmount,
                            EUnits.TONS
                          )} + ${formatNumber(
                            fixationAmountTons,
                            EUnits.TONS
                          )}) ${t('payPaymentRequest.tonsToRegister.text2')} ${
                            token && t(`grain.${token}`)
                          }.`}</Text>
                          {isPaymentInKind && (
                            <Text className="text-sm font-normal text-gray-700 mb-6">{`${t(
                              'payPaymentRequest.tonsToFix.text1'
                            )} (${formatNumber(
                              fixationFeeAmount,
                              EUnits.TONS
                            )} + ${formatNumber(
                              fixationFeeTons,
                              EUnits.TONS
                            )}) ${t('payPaymentRequest.tonsToFix.text2')} ${
                              token && t(`grain.${token}`)
                            } ${t('payPaymentRequest.tonsToFix.text3')}`}</Text>
                          )}
                        </>
                      )}
                    <Button
                      variant="primary"
                      size="lg"
                      type="submit"
                      disabled={shouldDisableButton()}
                      isLoading={isLoadingMakePayment || isInBlockchain}
                      isFullWidth
                      mb="4"
                      onClick={handleMakePayment}
                    >
                      {`${t('payPaymentRequest.payBtnLabel')} ${
                        totalTokensAmount
                          ? `${formatNumber(
                              totalTokensAmount,
                              EUnits.CRYPTO
                            )} ${token}`
                          : ''
                      }`}
                    </Button>
                    {(type === PaymentRequestType.STANDARD ||
                      type === PaymentRequestType.IN_KIND) && (
                      <Box className="flex justify-between text-md">
                        <Text
                          className={`font-normal ${
                            hasAmountToCoverPayment
                              ? 'text-gray-700'
                              : 'text-error-500'
                          }`}
                        >
                          {hasAmountToCoverPayment
                            ? t('payPaymentRequest.availableBalance')
                            : t(
                                `payPaymentRequest.insuficientBalance.${paymentTypeTranslation}`
                              )}
                        </Text>
                        <Box className="flex gap-1">
                          {token && <TokenIcon size="sm" token={token} />}
                          <span
                            className={`font-medium ${
                              hasAmountToCoverPayment
                                ? 'text-gray-900'
                                : 'text-error-500'
                            }`}
                          >{`${formatNumber(
                            availableAmount,
                            EUnits.CRYPTO
                          )} ${token}`}</span>
                        </Box>
                      </Box>
                    )}
                    {error ? (
                      <>
                        <Divider />
                        <ErrorMessage>{error}</ErrorMessage>
                      </>
                    ) : (
                      ''
                    )}
                    {isInBlockchain ? (
                      <>
                        <Divider />
                        <BlockchainState
                          title={t('payPaymentRequest.inBlockchain.title')}
                          subtitle={t('payPaymentRequest.inBlockchain.text')}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            )}
          </>
        }
      />
    </Box>
  );
};
