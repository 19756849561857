import { TokenCurrency } from '@agrotoken/common-utils';
import { Button, CoinsIcon, Text } from '@agrotoken-tech/ui';
import { Flex, useDisclosure, useToken } from '@chakra-ui/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import {
  FiCalendar,
  FiCopy,
  FiDownload,
  FiFile,
  FiHash,
  FiMessageSquare,
  FiUser,
} from 'react-icons/fi';

import { EUnits } from '@common/types';
import { formatDate, formatNumber } from '@common/utils';
import {
  OperationDetail,
  OperationDetailRowProps,
} from '@comp/OperationDetail';
import { WarrantyDrawer } from '@comp/WarrantyDrawer';
import useDownloadDocument from '@hooks/useDownloadDocument';

interface WarrantyTransferOperationDetailProps {
  createdAt: number;
  transferUuid: string;
  transferAmountFiat: number;
  fromBusiness?: { name: string; fiscalId: string };
  toBusiness?: { name: string; fiscalId: string };
  referenceCurrency: string;
  assetName: TokenCurrency;
  requestNumber: number;
  isSender: boolean;
  acceptedAt?: number;
  comment?: string;
  document?: { originalName: string; uuid: string };
  isLoading?: boolean;
}

const WarrantyTransferOperationDetail = ({
  assetName,
  createdAt,
  requestNumber,
  fromBusiness,
  toBusiness,
  transferUuid,
  referenceCurrency,
  transferAmountFiat,
  acceptedAt,
  comment,
  document,
  isLoading,
  isSender,
}: WarrantyTransferOperationDetailProps) => {
  const { t } = useTranslation();
  const translationKeyPrefix = 'transfers.details.operationDetail';

  const gray500 = useToken('colors', 'gray.500');

  const { mutate: downloadDocument } = useDownloadDocument();

  // Warranty drawer handler
  const {
    isOpen: isOpenWarrantyDrawer,
    onClose: onCloseWarrantyDrawer,
    onOpen: onOpenWarrantyDrawer,
  } = useDisclosure();

  const onClickDownloadDocumentHandler = () => {
    downloadDocument(document?.uuid ?? '', {
      onSuccess: (url) => {
        window.open(url);
      },
    });
  };

  const warrantyId = requestNumber - 5000;

  const assetGrain = assetName ? t(`grain.${assetName}`) : '';

  const business = isSender ? toBusiness : fromBusiness;

  const shouldShowAcceptedAtDate = !!acceptedAt;
  const acceptedAtFormatted = shouldShowAcceptedAtDate
    ? formatDate(acceptedAt, 'DD/MM/YY - HH:mm')
    : '';

  const topElements: OperationDetailRowProps[] = [
    {
      label: t(`${translationKeyPrefix}.createdAt`),
      icon: FiCalendar,
      element: (
        <Text size="md" variant="medium" color="gray.700">
          {formatDate(createdAt, 'DD/MM/YY - HH:mm')}
        </Text>
      ),
    },
    {
      label: t(`${translationKeyPrefix}.transactionNumber`),
      icon: FiHash,
      element: (
        <Flex columnGap="1" alignItems="center">
          <Text
            size="md"
            variant="medium"
            color="gray.700"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            w="7ch"
          >
            {transferUuid}
          </Text>
          <CopyToClipboard text={transferUuid}>
            <FiCopy size="20" color={gray500} cursor="pointer" />
          </CopyToClipboard>
        </Flex>
      ),
    },
    {
      label: isSender
        ? t(`${translationKeyPrefix}.business.receiver`)
        : t(`${translationKeyPrefix}.business.sender`),
      icon: FiUser,
      element: (
        <Text size="md" variant="medium" color="gray.900">
          {`${business?.name} - ${business?.fiscalId}`}
        </Text>
      ),
    },
    {
      label: t(`${translationKeyPrefix}.warranty.label`),
      icon: FiFile,
      element: (
        <Button variant="linkPrimary" size="md" onClick={onOpenWarrantyDrawer}>
          {t(`${translationKeyPrefix}.warranty.value`, {
            assetName: assetGrain,
            requestNumber,
          })}
        </Button>
      ),
    },
  ];

  if (comment) {
    topElements.push({
      label: t(`${translationKeyPrefix}.comment`),
      icon: FiMessageSquare,
      element: (
        <Text size="md" variant="regular" color="gray.700">
          {comment}
        </Text>
      ),
    });
  }

  if (document) {
    topElements.push({
      label: t(`${translationKeyPrefix}.document`),
      icon: FiFile,
      element: (
        <Button
          variant="linkPrimary"
          size="md"
          leftIcon={<FiDownload size="20" />}
          onClick={onClickDownloadDocumentHandler}
        >
          {document.originalName}
        </Button>
      ),
    });
  }

  const bottomElements: OperationDetailRowProps[] = [
    {
      label: t(`${translationKeyPrefix}.total`),
      icon: CoinsIcon as IconType,
      element: (
        <Text size="md" variant="medium" color="gray.900">
          {formatNumber(transferAmountFiat, referenceCurrency as EUnits, true)}
        </Text>
      ),
    },
  ];

  return (
    <>
      <OperationDetail
        title={t(`${translationKeyPrefix}.title`)}
        topElementsTitle={t(`${translationKeyPrefix}.topElementsTitle`)}
        bottomElementsTitle={t(`${translationKeyPrefix}.bottomElementsTitle`)}
        topElements={topElements}
        bottomElements={bottomElements}
        isLoading={isLoading}
        date={
          shouldShowAcceptedAtDate
            ? t(`${translationKeyPrefix}.acceptedAt`, {
                acceptedAt: acceptedAtFormatted,
              })
            : ''
        }
      />
      <WarrantyDrawer
        warrantyId={warrantyId}
        isOpen={isOpenWarrantyDrawer}
        onClose={onCloseWarrantyDrawer}
        warrantyOwnerBusinessFiscalId={fromBusiness?.fiscalId}
      />
    </>
  );
};

export default WarrantyTransferOperationDetail;
