import { Box } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServerError } from '@comp/Global/ServerError';
import { Loader } from '../../Global/Loader';
import 'react-loading-skeleton/dist/skeleton.css';
import { CreditsPageTable } from './CreditsPageTable';
import { Loan } from '@def/loan';

interface Props {
  data: Loan[];
  isLoading?: boolean;
  error?: any;
  alreadyPaid?: boolean;
}
export const CreditsTable = ({
  data,
  isLoading,
  error,
  alreadyPaid = false,
}: Props) => {
  const { t } = useTranslation();

  if (isLoading) return <Loader />;

  if (error) {
    return <ServerError title={t('serverError.title')} text={error?.message} />;
  }

  return (
    <Box className="border border-gray-200 shadow-md overflow-x-auto bg-white">
      <CreditsPageTable data={data} alreadyPaid={alreadyPaid} />
    </Box>
  );
};
