import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { OperationStatus } from '@common/types';

import { http } from '../http-common';

interface OperationStatusReturn {
  status: OperationStatus;
}

export const getOperationStatus = async (
  uuid: string
): Promise<OperationStatusReturn> => {
  const response = await http.get<OperationStatusReturn>(
    `/${PLATFORM_PREFIX}/operations/${uuid}/status`
  );
  return response.data;
};
