import { Button, Flex, Link, useBreakpointValue, Text } from '@chakra-ui/react';
import { useAuth } from '@src/context/authContext';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@comp/Global/Checkbox';
import { ENationalities } from '@agrotoken/common-utils';
import { BREAKPOINTS } from '@common/const';

interface Props {
  handleSubmit?(): void;
  isLoading: boolean;
  isDisabled?: boolean;
  secondaryAction?(): void;
  rightSideContent?: ReactNode;
  currentStep?: number;
}

const Form: FC<Props> = ({
  isLoading,
  isDisabled = false,
  handleSubmit,
  children,
  secondaryAction,
  rightSideContent = null,
  currentStep,
}) => {
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.common.actions',
  });
  const handleCheckbox = (e: any) => {
    setAcceptTerms(e.target.checked);
  };
  const isMobile = window.innerWidth < BREAKPOINTS.md;
  return (
    <Flex
      as="form"
      px={isMobile ? 4 : ['8', '8', '32']}
      pt="8"
      pb="12"
      gap="4"
      onSubmit={handleSubmit}
      flexDir={isMobile ? 'column' : 'row'}
    >
      {isMobile ? (
        <>
          {rightSideContent}
          {children}
        </>
      ) : (
        <>
          {children}
          {rightSideContent}
        </>
      )}
      <Flex
        position={isMobile ? 'static' : 'fixed'}
        w="full"
        borderColor={isMobile ? 'white' : 'gray.100'}
        bg={isMobile ? 'white' : 'gray.50'}
        borderRadius="sm"
        bottom="0"
        left="0"
        py="4"
        px={isMobile ? '0' : '28'}
        mr={'30'}
        justify="flex-end"
        alignItems={isMobile ? 'left' : 'center'}
        flexDir={isMobile ? 'column' : 'row'}
      >
        {currentStep === 2 && (
          <div className="flex">
            <Checkbox
              className="mr-1"
              id="terms"
              onChange={handleCheckbox}
              checked={acceptTerms}
              name="terms"
              type="checkbox"
              required
            />
            <div className="flex flex-col mr-2">
              <div className="flex items-center">
                <span className="text-sm font-medium text-gray-700 mr-1">
                  {t('accept')}{' '}
                </span>
                <Link
                  className="font-medium text-sm"
                  href={t('termsAndConditionsLink')}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    color="primary.700"
                    fontWeight="medium"
                    fontSize="sm"
                    display="inline-block"
                    mr="2"
                  >
                    {t('termsAndConditions')}
                  </Text>
                </Link>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <Button
            type="submit"
            mt="2"
            size="lg"
            variant="primary"
            isLoading={isLoading}
            disabled={isDisabled || (!acceptTerms && currentStep === 2)}
            width="100%"
          >
            {t('save')}
          </Button>
        )}
        {secondaryAction && (
          <Button
            type="button"
            variant="ghost"
            mr={isMobile ? '0' : '4'}
            mt={isMobile ? '2' : '0'}
            size={isMobile ? 'lg' : 'md'}
            bg="white"
            onClick={secondaryAction}
            disabled={isDisabled}
            width={isMobile ? '100%' : undefined}
            border="1px solid"
            borderColor={'gray.300'}
          >
            {t('completeLatter')}
          </Button>
        )}
        {!isMobile && (
          <Button
            type="submit"
            mt="0"
            variant="primary"
            isLoading={isLoading}
            disabled={isDisabled || (!acceptTerms && currentStep === 2)}
          >
            {t('save')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Form;
