import { Button } from '@chakra-ui/react';
import { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IToken } from '@src/common/types';
import { Input } from '../../Global/Input';

interface Props {
  onSubmit: (ev: ChangeEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  isAuthorized: boolean;
}

const Form: FC<Props> = ({
  onSubmit,
  isLoading = false,
  email,
  setEmail,
  password,
  setPassword,
  isAuthorized,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!email) return;
    setEmail(email);
  }, [email, setEmail]);

  return (
    <form onSubmit={onSubmit}>
      <Input
        className="mb-5"
        value={email}
        disabled
        name="email"
        type="email"
        label={t('login.form.email.label')}
        fullWidth
      />
      <Input
        value={password}
        name="password"
        placeholder={t('login.form.password.placeholder')}
        type="password"
        label={t('login.form.password.label')}
        fullWidth
        onChange={(ev) => {
          setPassword(ev.currentTarget.value);
        }}
      />
      {!isAuthorized && (
        <p className="text-sm text-error-500 mt-2">
          {t('login.form.invalidCredentialsMessage')}
        </p>
      )}
      <Button
        variant="primary"
        size="lg"
        w="full"
        mt="6"
        type="submit"
        isLoading={isLoading}
        disabled={isLoading}
      >
        {t('login.form.buttonLabel')}
      </Button>
    </form>
  );
};

export default Form;
