import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import { Alert } from '@agrotoken-tech/ui';
import { Button, Show } from '@chakra-ui/react';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft } from 'react-icons/fi';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { DocumentStatus } from '@common/types/document-status';
import { FormBr } from '@comp/BR/ProofOfExistence/Form';
import useDebounce from '@hooks/useDebounce';
import { searchWarehouses } from '@services/Warehouses';
import { useAuth } from '@src/context/authContext';
import { TOKENIZATIONS_PATH, EMPTY_CDE } from '../../common/const';
import {
  RealTokenizationStep,
  IGrainContract,
  ICdeForm,
  EActions,
  EGrainContractStatusId,
  IWarehouse,
} from '../../common/types';
import { createCdes, updateCdes } from '../../services/Cdes';
import {
  getGrainContract,
  updateGrainContract,
} from '../../services/GrainContracts';
import { Form } from '../AR/ProofOfExistence/Form';
import { ActionFooter } from '../Global/ActionFooter';
import { Container } from '../Global/Container';
import { GrainContractDetails } from '../Global/GrainContractDetails';
import { Loader } from '../Global/Loader';
import { ServerError } from '../Global/ServerError';
import { TimeLine } from '../Global/TimeLine';
import { Title } from '../Global/Title';

const AddNew_AR = React.lazy(() => import('../AR/ProofOfExistence/AddNew'));
const AddNew_BR = React.lazy(() => import('../BR/ProofOfExistence/AddNew'));

const addNewComponent = getMultiRegionMap({
  AR: AddNew_AR,
  BR: AddNew_BR,
});

export const ProofOfExistence = () => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const { id } = useParams();
  const [validCdeTonsSum, setValidCdeTonsSum] = useState<boolean>(false);
  const {
    isLoading,
    error: grainContractError,
    data: grainContract,
  } = useQuery<IGrainContract | undefined>(
    ['grainContract', id],
    getGrainContract
  );
  const AddNewComponent = addNewComponent[nationality];

  const navigate = useNavigate();
  const [searchDataValue, setSearchDataValue] = useState<string | undefined>(
    undefined
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    IWarehouse | undefined
  >(undefined);
  const [cdesForm, setCdesForm] = useState<ICdeForm[]>([EMPTY_CDE]);
  const [cdeMissingError, setCdeMissingError] = useState<boolean>(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [mutateError, setMutateError] = useState<string>('');

  // state to check if the user is editing the form
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const debouncedSearchDataValue = useDebounce(searchDataValue, 500);

  const {
    isLoading: isLoadingWarehouses,
    data: warehouses,
    error: getWarehousesError,
  } = useQuery<IWarehouse[] | undefined>(
    ['searchDataValue', debouncedSearchDataValue],
    searchWarehouses,
    {
      enabled: !!debouncedSearchDataValue,
    }
  );
  const isValidWarehouse = selectedWarehouse !== undefined;

  useEffect(() => {
    if (grainContract && grainContract.cdes.length > 0) {
      setIsEditing(true);
      const result: ICdeForm[] = grainContract.cdes.map((cde) => {
        return {
          id: cde.id,
          internalNumber: cde.internalNumber,
          tons: cde.tons.toString(),
          documentId: cde.documentId,
          preloadedFile: cde.document
            ? {
                path: cde.document.originalName,
                size: cde.document.size,
                uuid: cde.document.uuid,
              }
            : null,
          action: EActions.UPDATE,
        };
      });
      if (grainContract?.warehouse) {
        setSelectedWarehouse(grainContract.warehouse);
        setSearchDataValue(grainContract.warehouse.name);
      }
      setCdesForm(result);
    }
  }, [grainContract]);

  const deleteDocumentCallback = (currentCdeFormIndex: number) => {
    setCdesForm(
      cdesForm.map((cdeForm, index) => {
        if (index === currentCdeFormIndex) {
          return { ...cdeForm, documentId: EMPTY_CDE.documentId };
        }
        return cdeForm;
      })
    );
  };

  const { mutate: mutateCreateCdes, isLoading: isLoadingCreate } = useMutation(
    createCdes,
    {
      onSuccess: () => {
        setMutateError('');
        if (nationality === ENationalities.AR)
          navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
      },
      onError: (err: Error) => {
        setMutateError(err.message);
      },
    }
  );

  const { mutate: mutateUpdateCdes, isLoading: isLoadingUpdate } = useMutation(
    updateCdes,
    {
      onSuccess: () => {
        setMutateError('');
        if (nationality === ENationalities.AR)
          navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
      },
      onError: (err: Error) => {
        setMutateError(err.message);
      },
    }
  );

  const { mutate: mutateUpdateGrainContract } = useMutation(
    updateGrainContract,
    {
      onSuccess: () => {
        setMutateError('');
        navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
      },
      onError: (err: Error) => {
        setMutateError(err.message);
      },
    }
  );
  const handlePreviousStep = () => {
    const previousStep = getMultiRegionMap({
      AR: '/tokenizations',
      BR: grainContract
        ? `${TOKENIZATIONS_PATH}/${grainContract.id}/new-tokenization`
        : `${TOKENIZATIONS_PATH}/new-tokenization`,
    });
    navigate(previousStep[nationality]);
  };

  const {
    grainContractStatusId,
    isPretokenized,
    grainContractType,
    cdes,
    cpr,
  } = grainContract ?? {};

  const shouldAllowNextStep =
    grainContractStatusId === EGrainContractStatusId.PENDING ||
    (grainContractStatusId === EGrainContractStatusId.PENDING_APPROVAL &&
      isPretokenized);

  const isPretokenizedStatus =
    grainContract?.grainContractStatusId ===
    EGrainContractStatusId.PRE_TOKENIZED;

  const isProofOfExistenceDocumentApprovedForBr = cdes?.some(
    (cde) =>
      cde?.document?.documentStatusId === DocumentStatus.APPROVED &&
      nationality === ENationalities.BR
  );

  const disableForm =
    isPretokenizedStatus || isProofOfExistenceDocumentApprovedForBr;

  const handleNextStep = async () => {
    if (cdesForm.some((cde) => cde.action !== EActions.DELETE)) {
      if (isPretokenizedStatus) {
        navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
      }
      if (!shouldAllowNextStep) {
        setMutateError(t('tokenization.proofOfExistence.mutateError'));
        return;
      }
      if (
        !isValidForm ||
        (nationality === ENationalities.BR && !isValidWarehouse)
      ) {
        setShouldShowError(true);
        return;
      }
      const mapCdes = cdesForm.map((cde) => {
        const newCde = {
          internalNumber: cde.internalNumber,
          tons: parseFloat(cde.tons),
          documentId: cde.documentId,
          action: cde.action,
        };
        return cde.action === EActions.CREATE
          ? newCde
          : { id: cde.id, ...newCde };
      });

      const body = { cdes: mapCdes };
      if (nationality === ENationalities.BR) {
        await mutateUpdateGrainContract({
          id: grainContract?.id,
          body: { warehouseId: selectedWarehouse?.id },
        });
      }

      !isEditing && mutateCreateCdes({ id: grainContract?.id, body });
      isEditing && mutateUpdateCdes({ id: grainContract?.id, body });
    } else {
      setCdeMissingError(true);
    }
  };

  if (isLoading) return <Loader />;

  if (grainContractError || getWarehousesError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(grainContractError as any)?.message}
      />
    );
  }

  return (
    <Container className="pb-20">
      <Button
        variant={'link'}
        leftIcon={<FiArrowLeft />}
        onClick={handlePreviousStep}
        my="8"
      >
        {t('btnPrevLabel')}
      </Button>
      <div className="flex gap-x-12">
        <div className="xl:w-3/4">
          <Title
            title={t('tokenization.proofOfExistence.title')}
            subtitle={t('tokenization.proofOfExistence.subtitle')}
          />
          {nationality === ENationalities.AR && (
            <Suspense fallback={<div>Loading...</div>}>
              <Alert
                variant="gray"
                title={t('tokenization.proofOfExistence.helpTipTitle')}
                description={t('tokenization.proofOfExistence.helpTipText')}
                mt="9"
              ></Alert>
            </Suspense>
          )}
          {!validCdeTonsSum && shouldShowError && (
            <Alert
              variant="error"
              title={t('tokenization.proofOfExistence.form.errorMessage.min')}
              mt="4"
              showCloseButton={false}
            ></Alert>
          )}
          {nationality === ENationalities.BR && (
            <Suspense fallback={<div>Loading...</div>}>
              <FormBr
                isLoadingWarehouses={isLoadingWarehouses}
                selectedWarehouse={selectedWarehouse}
                setSelectedWarehouse={setSelectedWarehouse}
                warehouses={warehouses}
                cdeTons={0}
                searchDataValue={searchDataValue!}
                setSearchDataValue={setSearchDataValue}
                isValidSearchData={isValidWarehouse}
                shouldShowError={shouldShowError}
                isDisabled={disableForm}
              />
            </Suspense>
          )}
          {cdesForm.map((el, index) =>
            el.action !== EActions.DELETE ? (
              <Form
                isDisabled={disableForm}
                setValidCdeTonsSum={setValidCdeTonsSum}
                index={index}
                key={index}
                cdesForm={cdesForm}
                setCdesForm={setCdesForm}
                shouldShowError={shouldShowError}
                setIsValidForm={setIsValidForm}
                isEditing={isEditing}
                deleteDocumentCallback={deleteDocumentCallback}
              />
            ) : null
          )}

          {!isPretokenizedStatus && (
            <Suspense fallback={<div>Loading...</div>}>
              <AddNewComponent
                setCdeMissingError={setCdeMissingError}
                cdesForm={cdesForm}
                setCdesForm={setCdesForm}
                setIsValidForm={setIsValidForm}
                setShouldShowError={setShouldShowError}
              />
            </Suspense>
          )}

          {cdeMissingError && (
            <p className="mt-1.5 text-sm text-error-500">
              {t('tokenization.proofOfExistence.cdeEmpyError')}
            </p>
          )}
        </div>
        <Show above="xl">
          <div className="w-1/4">
            <TimeLine
              currentStep={RealTokenizationStep.PROOF_OF_EXISTENCE}
              grainContract={grainContract!}
            />
            <GrainContractDetails
              grainContract={grainContract!}
              currentStep={RealTokenizationStep.PROOF_OF_EXISTENCE}
            />
          </div>
        </Show>
      </div>
      <ActionFooter
        shouldShowError={shouldShowError}
        onClick={handleNextStep}
        step={RealTokenizationStep.PROOF_OF_EXISTENCE}
        error={mutateError}
        isLoading={isEditing ? isLoadingUpdate : isLoadingCreate}
      />
    </Container>
  );
};
