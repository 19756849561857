import { FeaturedIcons } from '@agrotoken-tech/ui';
import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { type IconType } from 'react-icons';

export interface OperationDetailRowProps {
  icon: IconType;
  label: string;
  element: JSX.Element | JSX.Element[];
  isLoading?: boolean;
}

/**
 * @name OperationDetailRow
 * @description A general row component to be used in OperationDetail component.
 * @param {IconType} icon Icon to display at the left side of the row
 * @param {string} label Text to display at the left side of the row
 * @param {string} topTitle The title for the top part of the summary
 * @param {JSX.Element | JSX.Element[]} element Element to display at the right side of the row
 * @param {boolean} isLoading Determines if the component is loading or not
 * @returns {React.ReactElement} The react component
 */
const OperationDetailRow = ({
  icon,
  label,
  element,
  isLoading,
}: OperationDetailRowProps) => {
  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex columnGap="3">
          <FeaturedIcons
            size="xs"
            shape="square"
            variant="primary"
            icon={icon}
          />
          <Text size="md" color="gray.700" variant="regular">
            {label}
          </Text>
        </Flex>
        {element}
      </Flex>
    </Skeleton>
  );
};

export default OperationDetailRow;
