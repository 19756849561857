export const onlyNumbersSlashAndDashRegExp = /^[0-9/-]*$/;
export const onlyNumbersAndLettersSlashAndDashRegExp =
  /^[a-zA-Z0-9/]+(-?[a-zA-Z0-9/]+)*$/;
export const onlyNumbersAndHyphen = /^[0-9]+(-?[0-9]+)+$/;
export const is12DigitsRegExp = /^\d{12}$/;
export const emailRegExp =
  /^(([^<>()[\].,;:\s@”]+(\.[^<>()[\].,;:\s@”]+)*)|(”.+”))@(([^<>()[\].,;:\s@”]+\.)+[^<>()[\].,;:\s@”]{2,})$/;
/* by today our Cognito password policy is: 
    Minimum length of 8 characters.
    At least one uppercase letter.
    At least one lowercase letter.
    At least one number.
    At least one special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =) 
*/
export const passwordRegExp =
  // eslint-disable-next-line no-useless-escape
  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-"\!@\#%&\/\\,\>\<'\:;\|_~`\+\=]).{8,64})/g;

export const namesRegExp =
  /^[a-zA-ZáéíóúñÁÉÍÓÚÑ]{3,40}( [a-zA-ZáéíóúñÁÉÍÓÚÑ]{2,40})*$/;
