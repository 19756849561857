import {
  Button,
  Heading,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { FormValues } from './index';
import type { UseFormRegister, FieldErrors } from 'react-hook-form';
import { passwordRegExp } from '@utils/regExp';

interface NewPasswordFormProps {
  register: UseFormRegister<FormValues>;
  password: string;
  errors: FieldErrors<FormValues>;
  isLoading: boolean;
}

const NewPasswordForm: React.VFC<NewPasswordFormProps> = (props) => {
  const { register, password, errors, isLoading } = props;

  const { t } = useTranslation();

  return (
    <>
      <Heading>{t('resetPasswordVerification.newPasswordForm.title')}</Heading>
      <Text>{t('resetPasswordVerification.newPasswordForm.subtitle')}</Text>
      <div className="w-3/6">
        <FormControl mr="6" mt="6" my="6" isInvalid={Boolean(errors.password)}>
          <FormLabel htmlFor="first-name">
            {t('resetPasswordVerification.newPasswordForm.password.label')}
          </FormLabel>
          <Input
            placeholder={t(
              'resetPasswordVerification.newPasswordForm.password.placeholder'
            )}
            id="password"
            type="password"
            borderRadius={3}
            h="12"
            w="full"
            {...register('password', {
              required: t(
                'resetPasswordVerification.newPasswordForm.password.errors.required'
              ),
              pattern: {
                value: passwordRegExp,
                message: t(
                  'resetPasswordVerification.newPasswordForm.password.errors.pattern'
                ),
              },
            })}
          />
          {errors.password && (
            <FormErrorMessage>{errors.password.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          mr="6"
          mt="5"
          isInvalid={Boolean(errors.passwordConfirmation)}
        >
          <FormLabel htmlFor="first-name">
            {t(
              'resetPasswordVerification.newPasswordForm.passwordConfirmation.label'
            )}
          </FormLabel>
          <Input
            placeholder={t(
              'resetPasswordVerification.newPasswordForm.passwordConfirmation.placeholder'
            )}
            id="passwordConfirmation"
            type="password"
            borderRadius={3}
            h="12"
            w="full"
            {...register('passwordConfirmation', {
              validate: (confirmation) =>
                confirmation === password ||
                t(
                  'resetPasswordVerification.newPasswordForm.passwordConfirmation.errors.match'
                ),
            })}
          />
          {errors.passwordConfirmation && (
            <FormErrorMessage>
              {errors.passwordConfirmation.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <Button
          variant="primary"
          size="lg"
          w="full"
          mt="6"
          type="submit"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t('resetPasswordVerification.newPasswordForm.submit')}
        </Button>
      </div>
    </>
  );
};

export default NewPasswordForm;
