import { Box, Spinner, Text } from '@chakra-ui/react';
import { EDocumentTypes, EPaymentRequestType, IBusiness } from '@common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FcCheckmark } from 'react-icons/fc';

import { DragAndDrop } from '@comp/Global/DragAndDrop';
import { Input } from '@comp/Global/Input';

interface GeneralDataSectionProps {
  translateType: string;
  fiscalId: string;
  setFiscalId: React.Dispatch<React.SetStateAction<string>>;
  isChecking: boolean;
  firstCheck: boolean;
  isValidFiscalId: boolean;
  firstSubmit: boolean;
  checkedBusiness: IBusiness | null | undefined;
  isErrorCheck: boolean;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setDocumentId: React.Dispatch<React.SetStateAction<number | null>>;
  documentId: number | null;
  maxSizeFileBytes: number;
  isInKind?: boolean | undefined | null;
  paymentRequestType?: EPaymentRequestType | undefined;
}
export const GeneralDataSection: FC<GeneralDataSectionProps> = ({
  translateType,
  fiscalId,
  setFiscalId,
  isChecking,
  firstCheck,
  isValidFiscalId,
  firstSubmit,
  checkedBusiness,
  isErrorCheck,
  comment,
  setComment,
  setDocumentId,
  documentId,
  maxSizeFileBytes,
  isInKind,
  paymentRequestType,
}) => {
  const { t } = useTranslation();

  return (
    <Box className="bg-white border border-gray-200 rounded p-6 mb-6">
      <Text className="h-10 font-Larsseit font-normal font-semibold text-16 leading-24 text-#101828">
        {`1. ${t('createPayment.form.user.title')}`}
      </Text>
      <Input
        className="mb-3"
        name="fiscalId"
        type="text"
        label={t(`createPayment.form.user.label.${translateType}`)}
        placeholder={t('createPayment.form.user.placeholder')}
        value={fiscalId}
        onChange={(ev) => setFiscalId(ev.currentTarget.value)}
        fullWidth
        showError={
          (!isChecking && firstCheck && !isValidFiscalId) ||
          (!isChecking && firstSubmit && !isValidFiscalId)
        }
        errorMessage={t('createPayment.form.user.error')}
        isValid={isValidFiscalId}
        hintMessage={
          <div style={{ color: 'green' }} className="flex gap-1 ">
            {checkedBusiness && !isChecking ? (
              <>
                {' '}
                <FcCheckmark />
                {`${t('createPayment.form.user.hintMessage')}` +
                  `${checkedBusiness.name}`}
              </>
            ) : (
              ''
            )}
          </div>
        }
      />
      {isChecking && !isErrorCheck && (
        <Spinner size="xs" className="mt-1.5" color="primary.300" />
      )}
      {isErrorCheck && <Text>{t('createPayment.form.user.serverError')}</Text>}
      {/* Comments */}
      {paymentRequestType && (
        <Text
          className={`text-gray-700 text-sm font-medium mt-4 mb-2 ${
            paymentRequestType === EPaymentRequestType.IN_KIND ? 'mt-6' : ''
          }`}
        ></Text>
      )}

      <Input
        style={{ marginTop: '12px' }}
        name="comment"
        type="text"
        label={t('createPayment.form.comment.label')}
        placeholder={t('createPayment.form.comment.placeholder')}
        value={comment}
        onChange={(ev) => setComment(ev.currentTarget.value)}
        fullWidth
      />
      <p className="mb-2 mt-1 text-sm font-normal color-#475467">
        {t('createPayment.form.comment.subtitle')}
      </p>
      {/* FileUpload */}
      <Text
        className={`text-gray-700 text-sm font-medium mt-6 mb-2 ${
          isInKind ? 'mt-6' : ''
        }`}
      >
        {t('createPayment.form.file.label')}
      </Text>
      <DragAndDrop
        documentTypeId={EDocumentTypes.PAYMENT_REQUEST}
        isValid
        shouldShowError={false}
        setDocumentId={setDocumentId}
        documentId={documentId}
        maxFileSize={maxSizeFileBytes}
      />
    </Box>
  );
};
