import { createContext } from 'react';
import { useParams } from 'react-router-dom';

import { useAuthorizeLoanPaymentDetails } from '../hooks';
import { GetLoanPaymentAuthorizationDetailsResponse } from '../interfaces';

interface AuthorizeLoanPaymentDataContextType {
  authorizeLoanPaymentDetails?: GetLoanPaymentAuthorizationDetailsResponse;
  isLoadingAuthorizeLoanPaymentDetails: boolean;
}

export const AuthorizeLoanPaymentDataContext =
  createContext<AuthorizeLoanPaymentDataContextType>(
    {} as AuthorizeLoanPaymentDataContextType
  );

interface AuthorizeLoanPaymentDataProviderProps {
  children?: JSX.Element | JSX.Element[];
}

const AuthorizeLoanPaymentDataProvider = ({
  children,
}: AuthorizeLoanPaymentDataProviderProps) => {
  const { loanUuid } = useParams();

  const {
    data: authorizeLoanPaymentDetails,
    isLoading: isLoadingAuthorizeLoanPaymentDetails,
  } = useAuthorizeLoanPaymentDetails(loanUuid ?? '');

  return (
    <AuthorizeLoanPaymentDataContext.Provider
      value={{
        authorizeLoanPaymentDetails,
        isLoadingAuthorizeLoanPaymentDetails,
      }}
    >
      {children}
    </AuthorizeLoanPaymentDataContext.Provider>
  );
};

export default AuthorizeLoanPaymentDataProvider;
