import { Badge } from '@comp/Global/Badge';
import { Loan } from '@src/common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import LoanCard from './LoanCard';

const CollateralizedTokensSkeleton: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="py-6">
      <div className="w-4/12">
        <Skeleton />
      </div>
      <div className="w-5/12 py-3">
        <Skeleton />
      </div>
      <div className="py-5 flex flex-col md:flex-row flex-wrap md:gap-6 mb-6">
        <div className="w-1/5">
          <Skeleton className="p-4  h-48 border mr-6 border-gray-200 shadow-md overflow-x-auto  h-full flex flex-col justify-center" />
        </div>
        <div className="w-1/5">
          <Skeleton className="p-4  h-48 border mr-6 border-gray-200 shadow-md overflow-x-auto  h-full flex flex-col justify-center" />
        </div>
        <div className="w-1/5">
          <Skeleton className="p-4  h-48 border mr-6 border-gray-200 shadow-md overflow-x-auto  h-full flex flex-col justify-center" />
        </div>
      </div>
    </div>
  );
};

export default CollateralizedTokensSkeleton;
