import { ENationalities } from '@agrotoken/common-utils';
import {
  EBackOfficeValidationStatus,
  EDocumentTypes,
  EFullOnboardingLevel,
  EFullOnboardingLevelName,
  EPermissionCategory,
  EValidationStatus,
  IDocumentPreview,
} from '@src/common/types';
import React, { FC, useContext, useMemo, ReactNode } from 'react';
import { useAuth } from './authContext';
import { useUser } from './userContext';

interface AccessContextType {
  canTokenize: boolean;
  isRepresentant: boolean;
  isOnboardingValidated: boolean;
  isTransactionable: boolean;
  isMerchant: boolean;
  canAdminUsers: boolean;
  canMakePayments: boolean;
  canRequestCard: boolean;
  canCreatePaymentLink: boolean;
  isFirstOnboardingLevel: boolean;
  isSecondOnboardingLevel: boolean;
  isThirdOnboardingLevel: boolean;
  isCompleteOnboardingLevel: boolean;
  canOnlyCreatePaymentLink: boolean;
  businessUserHasLoadedRepresentativeDocuments: boolean;
  hasChangeRequestedInSecondLevel: boolean;
  hasChangeRequestedInThirdLevel: boolean;
  canOnlyViewWallet: boolean;
  businessHasRepresentant: boolean;
  hasAcceptedTokenizationTermsAndConds: boolean;
  isArUser: boolean;
  hasSecondOnboardingValidated: boolean;
}
interface Props {
  children: ReactNode;
}

const AccessContext = React.createContext<AccessContextType>(null!);

export const useAccess = () => {
  return useContext(AccessContext);
};

export const AccessProvider: FC<Props> = ({ children }) => {
  const { selectedBusinessUser, currentUser, selectedBusiness } = useUser();
  const { nationality } = useAuth();

  const isArUser = nationality === ENationalities.AR;

  //permission management

  const isRepresentant = selectedBusinessUser
    ? selectedBusinessUser?.isRepresentant === 1
    : false;

  const businessHasRepresentant =
    (selectedBusiness?.businessUsers ?? []).find((bu) => bu.isRepresentant) !==
    undefined;

  const isValidated = selectedBusinessUser
    ? selectedBusinessUser?.validationStatus?.name ===
      EValidationStatus.APPROVED
    : false;

  const isOnboardingValidated =
    selectedBusinessUser?.isOnboardingValidated ?? false;

  const isTransactionable = selectedBusinessUser
    ? selectedBusinessUser.business.isTransactionable === 1
    : false;

  const isMerchant = selectedBusinessUser
    ? selectedBusinessUser.business.isMerchant === 1
    : false;

  const isFirstOnboardingLevel =
    selectedBusinessUser?.fullOnboardingLevel?.level ===
    EFullOnboardingLevelName.ONE;

  const isSecondOnboardingLevel =
    selectedBusinessUser?.fullOnboardingLevel?.level ===
    EFullOnboardingLevelName.TWO;

  const isThirdOnboardingLevel =
    selectedBusinessUser?.fullOnboardingLevel?.level ===
      EFullOnboardingLevelName.THREE ||
    selectedBusinessUser?.fullOnboardingLevelId === EFullOnboardingLevel.LEVEL3;

  const isCompleteOnboardingLevel =
    selectedBusinessUser && selectedBusinessUser.fullOnboardingLevel
      ? selectedBusinessUser.fullOnboardingLevel.level ===
        EFullOnboardingLevelName.COMPLETED
      : false;

  const canTokenize = selectedBusinessUser
    ? selectedBusinessUser.permissions.some(
        (permission) =>
          permission.permissionCategory.keyCode ===
          EPermissionCategory.MAKE_TOKENIZATIONS_AND_DETOKENIZATION
      )
    : false;

  const canAdminUsers = selectedBusinessUser
    ? selectedBusinessUser.permissions.some(
        (permission) =>
          permission.permissionCategory.keyCode ===
          EPermissionCategory.ADMINISTRATOR
      )
    : false;

  const canCreatePaymentLink = selectedBusinessUser
    ? selectedBusinessUser.permissions.some(
        (permission) =>
          permission.permissionCategory.keyCode ===
          EPermissionCategory.CREATE_PAYMENT_LINK
      )
    : false;

  const businessUserPermissions = selectedBusinessUser?.permissions?.filter(
    ({ keyCode }) => keyCode !== EPermissionCategory.VIEW_WALLET
  );

  const canOnlyViewWallet = businessUserPermissions?.length === 0;

  const canOnlyCreatePaymentLink =
    businessUserPermissions?.length === 1 &&
    businessUserPermissions?.some(
      ({ keyCode }: any) => keyCode === EPermissionCategory.CREATE_PAYMENT_LINK
    );

  const hasBusinessUserDocuments = Boolean(
    selectedBusinessUser?.businessUserDocuments &&
      selectedBusinessUser?.businessUserDocuments?.length > 0
  );

  const businessUserHasLegalAtLeastOneLegalPower =
    hasBusinessUserDocuments &&
    selectedBusinessUser?.businessUserDocuments?.some(
      ({ document }: { document: IDocumentPreview }) =>
        document?.documentTypeId === EDocumentTypes.LEGAL_POWER
    );

  const businessUserHasDesignationOfAuthorities =
    hasBusinessUserDocuments &&
    selectedBusinessUser?.businessUserDocuments?.some(
      ({ document }: { document: IDocumentPreview }) =>
        document?.documentTypeId === EDocumentTypes.DESIGNATION_OF_AUTHORITIES
    );

  const businessUserHasStatuteSocialContract =
    hasBusinessUserDocuments &&
    selectedBusinessUser?.businessUserDocuments?.some(
      ({ document }: { document: IDocumentPreview }) =>
        document?.documentTypeId === EDocumentTypes.STATUTE_OR_SOCIAL_CONTRACT
    );

  const businessUserHasLoadedRepresentativeDocuments = Boolean(
    businessUserHasLegalAtLeastOneLegalPower ||
      (businessUserHasDesignationOfAuthorities &&
        businessUserHasStatuteSocialContract)
  );

  const canMakePayments = selectedBusinessUser
    ? selectedBusinessUser.permissions.some(
        (permission) =>
          permission.permissionCategory.keyCode === EPermissionCategory.PAYMENTS
      )
    : false;

  const hasSecondOnboardingValidated =
    currentUser?.validationStatusId === EBackOfficeValidationStatus.APPROVED;

  const canRequestCard =
    (isTransactionable &&
      isRepresentant &&
      canMakePayments &&
      isCompleteOnboardingLevel) ||
    (canMakePayments && hasSecondOnboardingValidated);

  const hasChangeRequestedInSecondLevel = useMemo(() => {
    const userHasChangeRequest =
      currentUser?.validationStatusId ===
      EBackOfficeValidationStatus.CHANGE_REQUEST;

    const businessUserHasChangeRequest =
      selectedBusinessUser?.validationStatusId ===
        EBackOfficeValidationStatus.CHANGE_REQUEST ||
      selectedBusinessUser?.validationStatus?.id ===
        EBackOfficeValidationStatus.CHANGE_REQUEST;

    const businessAddressHasChangeRequest =
      selectedBusiness?.businessAddress?.validationStatusId ===
      EBackOfficeValidationStatus.CHANGE_REQUEST;

    return (
      userHasChangeRequest ||
      businessUserHasChangeRequest ||
      businessAddressHasChangeRequest
    );
  }, [currentUser, selectedBusinessUser, selectedBusiness]);

  const hasChangeRequestedInThirdLevel = useMemo(() => {
    const businessHasChangeRequest =
      selectedBusiness?.validationStatusId ===
      EBackOfficeValidationStatus.CHANGE_REQUEST;

    const businessBankAccountHasChangeRequest = (
      selectedBusiness?.businessBankAccounts ?? []
    ).some(
      ({ validationStatusId }) =>
        validationStatusId === EBackOfficeValidationStatus.CHANGE_REQUEST
    );

    return businessHasChangeRequest || businessBankAccountHasChangeRequest;
  }, [selectedBusiness]);

  const hasAcceptedTokenizationTermsAndConds =
    !!selectedBusinessUser?.tokenizationConditionsAcceptedAt;

  const value = {
    canTokenize,
    isRepresentant,
    isValidated,
    isOnboardingValidated,
    isTransactionable,
    isMerchant,
    canAdminUsers,
    canCreatePaymentLink,
    canMakePayments,
    isFirstOnboardingLevel,
    isSecondOnboardingLevel,
    isThirdOnboardingLevel,
    isCompleteOnboardingLevel,
    canOnlyCreatePaymentLink,
    canOnlyViewWallet,
    businessUserHasLoadedRepresentativeDocuments,
    hasChangeRequestedInSecondLevel,
    hasChangeRequestedInThirdLevel,
    businessHasRepresentant,
    hasAcceptedTokenizationTermsAndConds,
    canRequestCard,
    isArUser,
    hasSecondOnboardingValidated,
  };

  return (
    <AccessContext.Provider value={value}>{children}</AccessContext.Provider>
  );
};
