import { ENationalities, TokenCurrency } from '@agrotoken/common-utils';
import { Decimal } from 'decimal.js';
import {
  GrainContractTypes,
  PaymentRequestWarrantyStatus,
  PaymentRequestType,
  GrainContractValidationBy,
  GrainContractValidationStatus,
} from '@common/enum';
import { CprTypes } from '@common/enum/cpr-types.enum';
import { UIF_RADIO } from '@comp/FullOnboarding/BankingAndBilling/consts';
import {
  IBusinessAddress,
  IFinalBeneficiary,
} from '@comp/FullOnboarding/types';
import { ICard } from '@def/card';
import { TColor, TIconTheme } from '../../components/Global/types';
import { FiatCurrencies, RegionCode } from '../enum/currency-code.enum';
import { ELoanType } from '../enum/loan-type.enum';
import { OperationStatus, OperationType } from './operation';
import { PaymentRequestStatus } from './payment-request';
import { ForwardingMetadata } from './payment-request-forwarding';
import { DocumentStatus } from './document-status';
import { Nationality } from '@def/nationality';

export * from './operation';
export * from './payment-request';
export * from './rates';
export * from './valueOf';

// types
export type CryptoCurrencySymbol =
  | 'SOYA'
  | 'CORA'
  | 'WHEA'
  | 'SOYB'
  | 'CORB'
  | 'WHEB';
export type FiatCurrencySymbol =
  | 'USD'
  | 'ARS'
  | 'USD SOJA'
  | 'BRL'
  | 'ARS_SOY'
  | 'SOYA_ARS'
  | 'CORA_ARS';
export type CurrencyType = 'FIAT' | 'CRYPTO';
export type CurrencySymbol = FiatCurrencySymbol | CryptoCurrencySymbol;
export type TCdeAction = 'create' | 'update' | 'delete';

export type TOpertationType =
  | 'TOKENIZATION'
  | 'DETOKENIZATION'
  | 'PAYMENT'
  | 'PAYMENT_IN_KIND'
  | 'PAYMENT_WITH_CARD'
  | 'CREDIT_CARD_BLOCKED_RETURN';

// enums
export enum EUnits {
  FIAT = 'FIAT',
  ARS = 'ARS',
  USD = 'USD',
  CRYPTO = 'CRYPTO',
  TONS = 'TONS',
  PARAM = 'PARAM',
  SACAS = 'SACAS',
  BRL = 'BRL',
}

export enum EUnitsTranscript {
  ARS = 'ARS',
  USD = 'USD',
  CRYPTO = 'CRYPTO',
  TONS = 'TONS',
  PARAM = 'PARAM',
  SACAS = 'Sacas',
  BRL = 'BRL',
  FIAT = 'FIAT',
  ABRC = 'ABRC',
}

export enum ERegionUnits {
  AR = EUnits.ARS,
  BR = EUnits.BRL,
}

//TODO: Create a single Tokens enum with all tokens
export enum ETokens {
  SOYA = 'SOYA',
  CORA = 'CORA',
  WHEA = 'WHEA',
}

export enum ETokensAR {
  SOYA = 'SOYA',
  CORA = 'CORA',
  WHEA = 'WHEA',
}

export enum ETokensBR {
  SOYB = 'SOYB',
  CORB = 'CORB',
  WHEB = 'WHEB',
}

export enum ETokensBRTranscription {
  SOYB = 'Soja',
  CORB = 'Milho',
}

export enum EPermission {
  VIEW_WALLET = 'VIEW_WALLET',
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION = 'MAKE_TOKENIZATIONS_AND_DETOKENIZATION',
  CREATE_PAYMENT_LINK = 'CREATE_PAYMENT_LINK',
  MAKE_PAYMENT_IN_KIND = 'MAKE_PAYMENT_IN_KIND',
  MAKE_TRANSACTIONS = 'MAKE_TRANSACTIONS',
  CREATE_INVITATION_LINK = 'CREATE_INVITATION_LINK',
  REMOVE_USERS = 'REMOVE_USERS',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
  REQUEST_CARDS = 'REQUEST_CARDS',
  MANAGE_ACCOUNTS = 'MANAGE_ACCOUNTS',
  ADD_USSERS_TO_BRANCH_OFFICES = 'ADD_USSERS_TO_BRANCH_OFFICES',
  HOME_XP = 'HOME_XP',
  MAKE_EXTERNAL_EXCHANGE = 'MAKE_EXTERNAL_EXCHANGE',
  REQUEST_LOANS = 'REQUEST_LOANS',
  REGISTER_BRANCHES = 'REGISTER_BRANCHES',
}

export enum EPermissionCategoryId {
  VIEW_WALLET = 1,
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION = 2,
  CREATE_PAYMENT_LINK = 3,
  PAYMENTS = 4,
  ADMINISTRATOR = 5,
  SUPER_ADMIN = 6,
  OTHER = 7,
}

export enum EPermissionCategory {
  VIEW_WALLET = 'VIEW_WALLET',
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION = 'MAKE_TOKENIZATIONS_AND_DETOKENIZATION',
  CREATE_PAYMENT_LINK = 'CREATE_PAYMENT_LINK',
  PAYMENTS = 'PAYMENTS',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SUPER_ADMIN = 'SUPER_ADMIN',
  OTHER = 'OTHER',
}

export enum EPermissionCategoryTranslated {
  ADMINISTRATOR = 'Administrador',
  VIEW_WALLET = 'Ver Billetera',
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION = 'Tokenizar / Destokenizar',
  PAYMENTS = 'Pagar con tokens',
  CREATE_PAYMENT_LINK = 'Cobrar con tokens',
  SUPER_ADMIN = 'Creador',
  OTHER = 'Otros',
}

export enum EPermissionCategoryColors {
  ADMINISTRATOR = 'error',
  VIEW_WALLET = 'gray',
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION = 'success',
  PAYMENTS = 'info',
  CREATE_PAYMENT_LINK = 'purple',
  SUPER_ADMIN = 'error',
  OTHER = 'gray',
}

export enum ETextPermissionTranslated {
  VIEW_WALLET = 'viewWalletText',
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION = 'tokenizationText',
  MAKE_PAYMENT_IN_KIND = 'payWithTokensText',
  CREATE_PAYMENT_LINK = 'recieveTokensText',
}

export enum EBusinessInviteStatusId {
  PENDING = 1,
  COMPLETED = 2,
  DENIED = 3,
}

export enum ECurrencyId {
  SOYA = 1,
  CORA = 2,
  WHEA = 3,
  ARS = 4,
  ARS_SOY = 5,
  SOYA_ARS = 7,
  CORA_ARS = 8,
  SOYB = 1,
  CORB = 2,
  WHEB = 3,
  BRL = 4,
  ABRC = 6,
  COFB = 7,
}

export enum ECurrencyName {
  SOYA = 'SOYA',
  CORA = 'CORA',
  WHEA = 'WHEA',
  ARS = 'ARS',
  ARS_SOY = 'ARS_SOY',
  SOYA_ARS = 'SOYA_ARS',
  CORA_ARS = 'CORA_ARS',
  ABRC = 'ABRC',
}
export enum ECurrencyNameBr {
  SOYB = 'SOYB',
  CORB = 'CORB',
  WHEB = 'WHEB',
  BRL = 'BRL',
  USD = 'USD',
}

export enum CurrencyNameBrTranslation {
  BRL = 'R$',
  USD = 'USD',
}

export enum EDocumentationType {
  CERTIFICATE = 'CERTIFICATE',
  CONTRACT = 'CONTRACT',
  OFFER_LETTER = 'OFFER_LETTER',
  BANK_PROOF = 'BANK_PROOF',
  PROOF_OF_ORIGIN = 'PROOF_OF_ORIGIN',
  CPR = 'CPR',
}
export enum EDetokenizationType {
  PARTIAL = 'PARTIAL',
  TOTAL = 'TOTAL',
}

export enum RealTokenizationStep {
  PROOF_OF_ORIGIN = 0,
  PROOF_OF_EXISTENCE = 1,
  PROOF_OF_LIQUIDITY = 2,
  VERIFICATION = 3,
  OFFER = 4,
  OFFER_VALIDATION = 5,
  SUMMARY = 6,
  TOKENIZATION_COMPLETE = 7,
}

export enum FutureTokenizationStep {
  ORIGIN_OF_GRAIN = 0,
  PRODUCTION_EXPECTATION = 1,
  PROOF_OF_LIQUIDITY = 2,
  VERIFICATION = 3,
  OFFER = 4,
  OFFER_VALIDATION = 5,
  SUMMARY = 6,
  TOKENIZATION_COMPLETE = 7,
}

export enum EAgreementType {
  PRICE_TO_FIX = 1,
  PRICE_TO_FIX_AGTK = 2,
  FIXED_PRICE = 3,
}

export enum EGrainContractStatus {
  PENDING = 'PENDING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  OFFER_LETTER = 'OFFER_LETTER',
  ASSIGNMENT_NOTIFICATION = 'ASSIGNMENT_NOTIFICATION',
  PENDING_BLOCKCHAIN = 'PENDING_BLOCKCHAIN',
  IN_PROGRESS_BLOCKCHAIN = 'IN_PROGRESS_BLOCKCHAIN',
  TOKENIZED = 'TOKENIZED',
  DETOKENIZATION_IN_PROGRESS = 'DETOKENIZATION_IN_PROGRESS',
  DETOKENIZED = 'DETOKENIZED',
  DELETED = 'DELETED',
  PENDING_SETTLEMENT = 'PENDING_SETTLEMENT',
  CHARGED_AND_PENDING_PAYMENT = 'CHARGED_AND_PENDING_PAYMENT',
  PAID_COMPLETED = 'PAID_COMPLETED',
  PRE_TOKENIZED = 'PRE_TOKENIZED',
}

export enum EGrainContractStatusId {
  PENDING = 1,
  PENDING_APPROVAL = 2,
  OFFER_LETTER = 3,
  ASSIGNMENT_NOTIFICATION = 4,
  PENDING_BLOCKCHAIN = 5,
  IN_PROGRESS_BLOCKCHAIN = 6,
  TOKENIZED = 7,
  DETOKENIZATION_IN_PROGRESS = 8,
  DETOKENIZED = 9,
  DELETED = 10,
  PENDING_SETTLEMENT = 11,
  CHARGED_AND_PENDING_PAYMENT = 12,
  PAID_COMPLETED = 13,
  PRE_TOKENIZED = 14,
}

export enum ETokenizationStatusTranslated {
  PENDING = 'pending',
  PENDING_APPROVAL = 'pendingApproval',
  OFFER_LETTER = 'requiredAction',
  ASSIGNMENT_NOTIFICATION = 'pendingApproval',
  PENDING_BLOCKCHAIN = 'inProgress',
  IN_PROGRESS_BLOCKCHAIN = 'inProgress',
  TOKENIZED = 'tokenized',
  DETOKENIZATION_IN_PROGRESS = 'inProgress',
  DETOKENIZED = 'detokenized',
  DELETED = 'deleted',
  PRE_TOKENIZED = 'preTokenized',
  PENDING_SETTLEMENT = 'tokenized',
  CHARGED_AND_PENDING_PAYMENT = 'tokenized',
  PAID_COMPLETED = 'tokenized',
}

export enum ETokenizationStatusColor {
  PENDING = 'warning',
  PENDING_APPROVAL = 'blue-light',
  OFFER_LETTER = 'warning',
  ASSIGNMENT_NOTIFICATION = 'blue-light',
  PENDING_BLOCKCHAIN = 'blue-light',
  IN_PROGRESS_BLOCKCHAIN = 'blue-light',
  TOKENIZED = 'success',
  DETOKENIZATION_IN_PROGRESS = 'blue-light',
  DETOKENIZED = 'gray',
  DELETED = 'error',
  PRE_TOKENIZED = 'orange',
  PENDING_SETTLEMENT = 'success',
  CHARGED_AND_PENDING_PAYMENT = 'success',
  PAID_COMPLETED = 'success',
}

export enum EBusinessUserPermissionColor {
  VIEW_WALLET = 'gray',
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION = 'success',
  CREATE_PAYMENT_LINK = 'purple',
  MAKE_PAYMENT_IN_KIND = 'info',
  MAKE_TRANSACTIONS = 'info',
  CREATE_INVITATION_LINK = 'error',
  REMOVE_USERS = 'error',
  MANAGE_PERMISSIONS = 'error',
  REQUEST_CARDS = 'error',
  MANAGE_ACCOUNTS = 'error',
  ADD_USSERS_TO_BRANCH_OFFICES = 'error',
  HOME_XP = 'error',
  MAKE_EXTERNAL_EXCHANGE = 'error',
  REQUEST_LOANS = 'error',
  REGISTER_BRANCHES = 'error',
}

export enum EBusinessUserPermissionTranslated {
  VIEW_WALLET = 'Billetera',
  MAKE_TOKENIZATIONS_AND_DETOKENIZATION = 'Tokenizar',
  CREATE_PAYMENT_LINK = 'Cobrar con tokens',
  MAKE_PAYMENT_IN_KIND = 'Pagar con tokens',
  MAKE_TRANSACTIONS = 'Pagar con tokens',
  CREATE_INVITATION_LINK = 'Administrador',
  REMOVE_USERS = 'Administrador',
  MANAGE_PERMISSIONS = 'Administrador',
  REQUEST_CARDS = 'Administrador',
  MANAGE_ACCOUNTS = 'Creador',
  ADD_USSERS_TO_BRANCH_OFFICES = 'Creador',
  HOME_XP = 'Creador',
  MAKE_EXTERNAL_EXCHANGE = 'Creador',
  REQUEST_LOANS = 'Creador',
  REGISTER_BRANCHES = 'Creador',
}

export enum EBusinessInviteStatusTranslated {
  PENDING = 'Invitación enviada',
  COMPLETED = 'Activo',
  DENIED = 'Eliminada',
  CREATOR = 'Creador',
  APPROVED = 'Aprobado',
}

export enum EBusinessInviteStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  DENIED = 'DENIED',
  CREATOR = 'CREATOR',
}

export enum EUserRole {
  ADMINISTRATOR = 'Administrador',
  USER_WITH_PERMISSIONS = 'Usuario con permisos',
}

export enum EBusinessTypeId {
  ORACLE = 1,
  FARMER = 2,
  MERCHANT = 3,
}

export enum EAcceptedTerms {
  ACCEPTED = 'Aceptados',
  PENDING = 'Pendientes',
}

export enum EParameter {
  OVERCOLLATERAL_STANDARD_PERCENTAGE = 'OVERCOLLATERAL_STANDARD_PERCENTAGE',
  OVERCOLLATERAL_FIXED_PERCENTAGE = 'OVERCOLLATERAL_FIXED_PERCENTAGE',
  MULTIPLIER = 'MULTIPLIER',
  TRANSFER_EXPIRATION_LIMIT = 'TRANSFER_EXPIRATION_LIMIT',
}

export enum EParameterId {
  TRANSFER_EXPIRATION_LIMIT = 26,
  RECEIVER_WARRANTY_TRANSFER_FEE_PERCENTAGE = 27,
  SENDER_WARRANTY_TRANSFER_FEE_PERCENTAGE = 28,
}

export enum EDocusignType {
  OFFER_LETTER = 1,
  ASSIGNMENT_NOTIFICATION = 2,
  PAYMENT_IN_KIND = 3,
  LOAN = 4,
  RESCISSION_NOTIFICATION = 5,
}

export enum EDocusignStatus {
  PENDING_USER = 1,
  PENDING_AGTK = 2,
  COMPLETED = 3,
  DECLINED = 4,
  PENDING_ORACLE = 5,
  PENDING_WITNESSES = 6,
}

export enum EActions {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum ERegisterExceptionMessages {
  UsernameExistsException = 'UsernameExistsException',
  InvalidPasswordException = 'InvalidPasswordException',
  UnfilledFields = 'UnfilledFields',
  InvalidConfirmPassword = 'InvalidConfirmPassword',
  DefaultError = 'DefaultError',
}

export enum ERegisterExceptionCodes {
  UsernameExistsException = 'UsernameExistsException',
  InvalidPasswordException = 'InvalidPasswordException',
}

export enum ELocalCurrency {
  BRL = 'BRL',
  ARS = 'ARS',
}

export enum ECurrencyNationality {
  BR = 'BRL',
  AR = 'ARS',
}

export enum EBenefitsListTranslation {
  LIQUIDITY = 'liquidity',
  SIMPLICITY = 'simplicity',
  AVAILABILITY = 'availability',
  LESS_INTERMIDIARIES = 'lessIntermediaries',
}

export enum EDocumentTypes {
  CDE = 1,
  AGREEMENT_DOC = 2,
  DNI_FRONT = 3,
  DNI_BACK = 4,
  LEGAL_POWER = 5,
  PAYMENT_REQUEST = 6,
  STATUTE_OR_SOCIAL_CONTRACT = 7,
  DESIGNATION_OF_AUTHORITIES = 8,
  OBLIGATED_SUBJECT = 9,
  BANK_ACCOUNT = 10,
  MERCHANT_FEE_BILL = 11,
  FARMER_FEE_BILL = 12,
  ECHECK_CERTIFICATE = 13,
  STATE_REGISTRATION = 14,
  CAR = 15,
  CREDIT_ASSIGNMENT = 16,
  PROOF_OF_ORIGIN = 17,
  SELFIE = 18,
  ESG = 19,
  CPR = 20,
  VAULT = 21,
  RESCISSION_NOTIFICATION = 22,
  PROOF_OF_HEALTH = 23,
  WARRANTY_TRANSFER = 24,
}

export enum ESignatureTypes {
  DOCUSIGN = 'DOCUSIGN',
  MANUAL = 'MANUAL',
}

export enum EPEPValues {
  IS_PEP = 1,
  IS_NOT_PEP = 0,
}
export enum ENeedsUifDocument {
  DOC_REQUIRED = 1,
  DOC_NOT_REQUIRED = 0,
}

export enum DocumentFormat {
  PDF = 'pdf',
  IMAGES = 'jpg | jpeg | png',
}

export enum EPayloadStatus {
  ENABLED = 1,
  DISABLED = 0,
}
export enum EPaymentRequestType {
  STANDARD = 'STANDARD',
  IN_KIND = 'IN_KIND',
  FUTURE_WARRANTY = 'FUTURE_WARRANTY',
  FIXED = 'FIXED',
}

export enum EStructureType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVUAL',
}

export enum EFullOnboardingLevelName {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  COMPLETED = 'COMPLETED',
}

export enum EBankAccountValidationStatus {
  PENDING = 1,
  CHANGE_REQUEST = 2,
  APPROVED = 3,
}

export enum EIdentificationTaxTypes {
  CUIL = 1,
  CUIT = 2,
}

// interfaces
export interface IGrainContract {
  id: number;
  uuid: string;
  farmerId: number;
  farmer: IBusiness;
  oracleId: number;
  oracle: IBusiness;
  currencyId: number;
  currency: ICurrency;
  networkId: number;
  network: INetwork;
  grainContractStatusId: number;
  grainContractStatus: IGrainContractStatus;
  healthRatio: Decimal | number | null;
  userId: number;
  user: IUser;
  amount: number | null;
  requestNumber: number | null;
  tonsDetokenized: number | null;
  tonsRequested: number | null;
  tonsVerified: number | null;
  tonsReserved: number | null;
  smartContractUuid: number | null;
  renovatedAt: number | null;
  createdAt: number | null;
  expirationDate: number;
  agreementDoc: IAgreementDoc;
  cdes: ICde[] | [];
  docusigns: IDocusign[] | [];
  percentReserved: number | null;
  isRiskApproved: number;
  farm: IFarm | null;
  farmId: number | null;
  documents: IDocument[];
  signatureType: ISignatureType;
  warehouse: IWarehouse;
  grainContractType: GrainContractTypes;
  cpr: Cpr;
  equivalences: any;
  grainContractsOperations: IGrainContractsOperation[];
  validationBy: GrainContractValidationBy | null;
  validationStatus: GrainContractValidationStatus | null;
  conversions: Conversions;
  priceAdjustment?: Decimal | null;
  healthControl?: Decimal;
  zendeskId: number | null;
  managerId: number | null;
  frozenAmount?: Decimal | null;
  isPretokenized?: boolean;
}

export interface Conversions {
  amountConversion: number;
  initialConversion: number;
}

export interface MovementConversion {
  amountConversion: number;
}

export interface Cpr {
  id: number;
  uuid: string;
  internalNumber: number;
  plantedArea: number;
  grainVolume: number;
  price: number;
  currency: FiatCurrencies;
  type: CprTypes;
  plantingDate: number;
  harvestDate: number;
  documentId: number;
  grainContractId: number;
  expirationDate: number;
  document: IDocument;
}

export interface ISignatureType {
  id: number;
  keyCode: string;
  name: string;
  description: string;
}
export interface IGrainContractStatus {
  id: number;
  name: EGrainContractStatus;
}

export interface IUpdateGrainContract {
  grainContractStatusId?: EGrainContractStatusId;
  expirationDate?: number;
  renovatedAt?: number;
  detokenizedAt?: number;
  tonsDetokenized?: number;
  amount?: number;
  isPartialTokenization?: boolean;
}

export interface IUpdateUser {
  businessId: number | undefined;
  userId: number | undefined;
  permissions: IPermission[] | undefined;
}

export interface IAgreementDoc {
  id: number;
  internalNumber: string;
  documentId: number | null;
  agreementType: IAgreementType;
  agreementTypeId: number;
  tons: number;
  expirationDate: number | null;
  grainContractId: number;
  price: number | null;
  document: IDocument;
  startDate: number;
  endDate: number;
  currency: FiatCurrencies | null;
  oracleId?: number;
  oracle?: IOracle;
  isPretokenized?: boolean;
  finalPrice?: Decimal;
}
export interface IAgreementType {
  id: number;
  name: string;
  description: string | null;
}
export interface IAgreementForm {
  agreementTypeId: string;
  oracleId?: string;
  oracle?: string;
  internalNumber: string;
  agreementTons: string;
  tons: string;
  limitRenewDate: Date | null;
  expirationDate: Date | null;
  price: string;
  agreementPrice?: string;
  documentId: number | null;
  preloadedFile?: { path: string; size: number; uuid: string } | null;
  startDate?: Date | null;
  endDate?: Date | null;
}

export interface CprForm {
  internalNumber: string;
  plantedArea: string;
  grainVolume: string;
  price: string;
  type: string;
  plantingDate: Date | null;
  harvestDate: Date | null;
  expirationDate: Date | null;
  documentId: number | null;
  preloadedFile?: { path: string; size: number; uuid: string } | null;
}

export interface BodyCpr {
  internalNumber: string;
  plantedArea: string;
  grainVolume: string;
  price: string;
  currency: string;
  type: string;
  plantingDate: number;
  documentId: number | null;
  expirationDate: number;
  grainContractId?: number | null;
}

export interface GrainTypeOption {
  label: string;
  text: string;
  value: number | string;
}

export interface IProofOfExistenceForm {
  addressNumber: string;
  address: string;
  state: string;
  city: string;
  documentId: number | null;
  preloadedFile?: { path: string; size: number; uuid: string } | null;
}

export interface IWarehouse {
  id: number;
  name: string;
  address: string;
  cda: string;
  city: ICity;
  addressNumber: string;
  latitude: string;
  longitud: string;
  state: string;
}

export interface ICity {
  id: number;
  name: string;
  geographicalDivision: IGeographicalDivision;
  geographicalDivisionId: number;
}

export interface IGeographicalDivision {
  id: number;
  name: string;
  isoCode: string;
}
export interface ICde {
  id: number;
  internalNumber: string;
  tons: number;
  grainContractId: number;
  cdeStatusId: number;
  document: IDocument;
  documentId: number | null;
}

export interface ICdeForm {
  id?: number;
  internalNumber: string;
  tons: string;
  documentId: number | null;
  action: TCdeAction;
  preloadedFile?: { path: string; size: number; uuid: string } | null;
}
export interface IDocument {
  id: number;
  name: string;
  filePath: string;
  mime: string | null;
  size: number;
  originalName: string;
  verifiedDate: number | null;
  sendDate: number | null;
  documentTypeId: number;
  uuid: string;
  customName: string | null;
  documentStatusId: DocumentStatus;
}

export interface ICreateDocumentDto {
  formData: FormData;
  documentTypeId: number;
}

export interface IDocusign {
  id: number;
  docusignStatusId: number;
  docusignTypeId: number;
  envelopUuid: string;
  grainContractId: number;
}

export interface IContractTerm {
  id: number;
  name: string;
  description: string | null;
  days: number;
  enabled: number;
}

export interface IBusiness {
  id: number;
  fiscalId: string | null;
  name: string;
  businessTypeId: number;
  balances: IBalance[];
  wallets: IWallet[];
  businessStructureTypeId: number;
  businessUser: IBusinessUser[];
  isTransactionable: number;
  isMerchant: number;
  businessBankAccounts: IBusinessBankAccount[];
  beneficiaryBusinesses: IFinalBeneficiary[];
  [UIF_RADIO]: number;
  mainActivity: string;
  documents: IDocumentPreview[];
  businessAddressId: number;
  validationStatusId?: number;
  validationMessage?: string;
  businessAddress: IBusinessAddress;
  businessUsers: IBusinessUser[];

  isPendingOpsReview: boolean;
  isPendingUserReview: boolean;
  businessActivityId?: number;
}

export interface IBusinessBankAccount {
  accountNumber: string;
  accountTypeId?: number;
  active: number;
  alias: string;
  businessId: number;
  cbu: string;
  document?: IDocumentPreview;
  documentId?: number;
  entityName?: string;
  entityNumber: string;
  id: number;
  requestMessage: null;
  validated: number;
  validationStatusId?: number;
  validationMessage?: string;
  subsidiaryNumber?: string;
  pix?: IPix | null;
}
export interface INetwork {
  addressWallet: string | null;
  enabled: number;
  id: number;
  name: keyof typeof ENetworkTransactionScan;
  port: number | null;
  subnet: string | null;
  testnet: string | null;
}

export interface IOracle {
  id: number;
  fiscalId: string | null;
  name: string;
  refererName: string;
  refererEmail: string;
  enabled: number;
}

export type TBreadcrumbOption = {
  label: string;
  to: string;
};

export interface UserPayfacDetails {
  motherName: string;
}

export interface UserAddress {
  city: ICity;
  cityId: number;
  complement: string;
  countryId: number;
  legalAddress: string;
  locationId: number;
  neighborhood: string;
  postalCode: string;
  streetNumber: string;
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  invitationStatus: EInvitationStatus;
  userRole: EUserRole;
  authUuid: string;
  createdAt: number;
  businessUsers: IBusinessUser[];
  pep: number;
  permissions: IPermission[];
  validationStatusId?: number;
  validationMessage?: string;
  identificationValue?: string;
  identificationTaxValue?: string;
  birthDate?: string;
  addresses: UserAddress[];
  userPayfacDetails?: UserPayfacDetails;
  documents?: IDocumentPreview[];
  userType: UserTypes;
  nationalityRelation: Nationality;
}
export enum UserTypes {
  NATIVE = 'NATIVE',
  MANAGER = 'MANAGER',
}

// TODO: fix backend to use same structure for all documents
// Then IDocumentPreviewForBusinessUser will be replaced by IDocumentPreview
// and we'll have to remap properties on the app
export interface IDocumentPreviewForBusinessUser {
  businessUserId: number;
  documentId: number;
  document: IDocumentForBusinessUser;
  documentTypeId: number;
}
// TODO: homologate the document Type no matter if its a user or business user document
// This type should not exist since we should have a FIXED structure for all documents.
// Now the service is returning a different structure if its a user document or a business-user-document.

export interface IDocumentForBusinessUser {
  documentStatus: { id: number; name: string; description: string };
  documentStatusId: number;
  documentType: {
    id: number;
    name: string;
    description: string;
  };
  documentTypeId: number;
  filePath: string;
  id: number;
  mime: string;
  name: string;
  originalName: string;
  sendDate: string;
  size: string;
  verifiedDate: string | null;
  validDate: string;
  uuid: string;
}

export interface IDocumentPreview {
  documentTypeId: number;
  filePath: string;
  id: number;
  mime: string;
  name: string;
  originalName: string;
  size: string;
  uuid: string;
}

export interface IToken {
  country: string;
  email: string;
  businessName: string;
  businessId: string;
  businessStructureTypeId: number;
  businessUserInviteId: number;
  isNewUser: boolean;
  iat: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export interface IAccountType {
  id: number;
  keyCode: string;
  name: string;
  enabled: number;
  entityName: string;
}

export interface IBankAccount {
  id: number;
  businessId: number;
  accountNumber: string;
  cbu: string;
  accountTypeId: number | null;
  active: number;
  entityName: string | null;
  entityNumber: string;
  alias: string;
  userId: number;
  documentId: number | null;
  document: IDocument | null;
  preloadedFile?: { path: string; size: number; uuid: string } | null;
  validationStatusId: number;
  pix: IPix | null;
  subsidiaryNumber: string | null;
}

export interface IUserKYCData {
  documents?: IDocumentPreview[];
  businessUsers?: IBusinessUser[];
  pep: number;
  id: number;
  identificationTaxValue?: string;
  validationStatusId?: number;
  validationMessage?: string;
}

export interface IBusinessUser {
  id: number;
  business: IBusiness;
  businessId: number;
  userId: number;
  user: IUser;
  businessUserDocuments?: IDocumentPreviewForBusinessUser[];
  isRegistered: number;
  isRepresentant: number;
  isCreator: number;
  permissions: IPermission[];
  email: string;
  businessUserInvites: IBusinessInvite[];
  businessInviteId: number;
  firstName: string;
  lastName: string;
  isActive: number;
  validationStatusId?: number;
  validationMessage?: string;
  validationStatus: Partial<IValidationStatus>;
  fullOnboardingLevel: Partial<IFullOnboardingLevel>;
  fullOnboardingLevelId: number;
  cardRequestDate: number | null;
  isOnboardingValidated: boolean;
  onboardingLevel3RequestId: number | null;
  onboardingLevel3Request: IOnboardingLevel3Request;
  onboardingLevel2RequestId: number | null;
  onboardingLevel2Request: IOnboardingLevel2Request;
  cards: ICard[];

  isPendingOpsReview: boolean;
  isPendingUserReview: boolean;
  tokenizationConditionsAcceptedAt: number | null;
}

export interface IRepresentant {
  id: number;
}

export interface UpdateBusinessUserDto {
  documentIds?: number[];
  fullOnboardingLevelId?: number;
  tokenizationConditionsAcceptedAt?: number;
  validationStatusId?: number;
  validationStatus?: Partial<IValidationStatus>;
  isPendingOpsReview?: boolean;
  isPendingUserReview?: boolean;
}

export interface IValidationStatus {
  id: number;
  name: string;
  description: string;
}

export interface IFullOnboardingLevel {
  id: number;
  level: string;
  description: string;
}
export interface IBusinessUserInviteUpdate {
  uuid: string;
  data: any;
}

export interface ISignUpForm {
  email: string;
  password: string;
  businessName: string;
  businessId: number;
  businessStructureTypeId: number;
  givenName: string;
  lastName: string;
}

export interface IBusinessInvite {
  id: number;
  uuid: string;
  businessId: number;
  businessUserId: number;
  businessUser: IBusinessUser;
  statusId: number;
  status: IBusinessInviteStatus;
  createdAt: string | null;
  isCreator: number;
}

interface UserInviteResponse {
  email: string;
}

interface BusinessUserInviteResponse {
  isActive: number;
  businessId: number;
  userId: number;
  user: UserInviteResponse;
}

export interface BusinessInvitesResponse {
  id: number;
  businessUser: BusinessUserInviteResponse;
}

export interface IBusinessInviteDetails {
  id: number;
  business: IBusiness;
  status: IBusinessInviteStatus;
  businessId: number;
  businessUserId: number;
  statusId: number;
  createdAt: string | null;
  isCreator: number;
  inviteeData: IToken;
}

export interface IBusinessInviteStatus {
  id: number;
  name: string;
  description: string;
  keyCode: string;
}
export interface IPermission {
  id: number;
  keyCode: string;
  name: string;
  description: string;
  permissionCategoryId: number;
  permissionCategory: IPermissionCategory;
  preload: number;
  sort: number;
}

export interface IPermissionCategory {
  id: number;
  keyCode: string;
  name: string;
  description: string;
}
export interface AuthRegisterDto {
  country: string;
  email: string;
  password: string;
  businessName: string;
  businessId: string;
  business_person_type_id:
    | EBusinessStructureTypes.PERSON
    | EBusinessStructureTypes.COMPANY;
  phoneTemp?: string;
  givenName: string;
  lastName: string;
  phone?: string;
}

export interface SignUpDto {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  country: ENationalities;
  businessFiscalId: string;
  businessPersonTypeId:
    | EBusinessStructureTypes.PERSON
    | EBusinessStructureTypes.COMPANY;
  businessName?: string;
  phone?: string;
}

export type BalanceType = 'tons_tokenized' | 'tons_fixed' | 'tons_activated';

export interface IExtendedRequest extends Request {
  performedBy?: string;
}

export interface IApiResponse<T> {
  errorMessage?: string;
  responseCode?: number;
  data?: T;
}

export interface IParameter {
  id: number;
  parameterTypeId?: number | null;
  name: string;
  value: number;
  enabled: number;
}

export interface ILink {
  id: number;
  name: string;
  to?: string;
  href?: string;
  url?: string;
  hide?: boolean;
}

export interface IToast {
  id: number;
  title: string;
  text: string;
  icon: string;
  theme: TIconTheme;
  color: TColor;
}

export enum ETransactionTypes {
  'TOKENIZATION' = 'tokenization',
  'DESTOKENIZATION' = 'detokenization',
}

export enum ETransactionTypesTranslated {
  'tokenization' = 'Tokenización',
  'destokenization' = 'Destokenización',
  'net_comission' = 'Comisión neta',
  'Interests' = 'Intereses',
  'destokenization_fee' = 'Tasa de destokenización',
  'tokenization_fee' = 'Tasa de tokenización',
  'tokenization_transfer' = 'Transferencia de tokenización',
  'transfer_to_account' = 'Transacción',
  'swap' = 'Convertir',
  'adjust' = 'Ajustar',
}

export enum ETransactionTypeKeys {
  'TOKENIZATION' = 'tokenization',
  'DESTOKENIZATION' = 'destokenization',
  'NET_COMISSION' = 'net_comission',
  'INTERESTS' = 'Interests',
  'DESTOKENIZATION_FEE' = 'destokenization_fee',
  'TOKENIZATION_FEE' = 'tokenization_fee',
  'TOKENIZATION_TRANSFER' = 'tokenization_transfer',
  'TRANSFER_TO_ACCOUNT' = 'transfer_to_account',
  'SWAP' = 'swap',
  'ADJUST' = 'adjust',
}

export enum EInvitationStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  DENIED = 'DENIED',
}

export enum ECreatorStatus {
  IS_CREATOR = 1,
  OTHER = 0,
}

export interface ICurrency {
  id: number;
  name: TokenCurrency;
  symbol: TokenCurrency;
  icon: string | null;
  rate: number;
  decimals: number;
  isCrypto: number;
  isActive: boolean;
}
export interface ICurrencyRateHistory {
  id: number;
  currencyId: number;
  currency: ICurrency;
  modificatedAt: number;
  rate: number;
}

export interface IWallet {
  id: number;
  address: string;
}

// export enum ETokenizationStatus {
//   PENDING = 'PENDING',
//   TOKENIZED = 'TOKENIZED',
//   DESTOKENIZED = 'DESTOKENIZED'
// }

export enum Oracle {
  Cargill = 'Cargill',
  Bunge = 'Bunge',
  LA_BRAGADENSE = 'La Bragadense',
}

export interface ITokenization {
  id: number;
  amount: string;
  createdAt: string;
  expirationDate: string;
  lockedAmount: string;
  tonsRequested: string;
  tonsDestokenized: string;
  tonsReserved: string;
  tonsVerified: string;
  networkId: number;
  userId: number;
  grainContractStatus: IGrainContractStatus;
  currency: ICurrency;
  oracle: IBusiness;
  farm: IFarm;
}

export interface IFarm {
  id: number;
  name: string;
  internalNumberState: string;
  internalNumberRural: string;
  farmOwnerTypeId: number;
  farmOwnerType: { name: string };
  registration: string;
  address: string;
  postalCode: string;
  cityId: number;
  businessId: number;
  city?: ICity;
  capacity: number | null;
  documents?: IDocument[] | [];
}

export enum EMovementTypesId {
  TOKENIZATION = 1,
  NET_COMISSION = 2,
  INTERESTS = 3,
  TOKENIZATION_FEE = 4,
  TOKENIZATION_TRANSFER = 5,
  ADJUSTMENT = 6,
  RETURN_TOKENIZATION_FEE = 7,
  DETOKENIZATION = 8,
  DETOKENIZATION_FEE = 9,
  TRANSFER = 10,
  PAYMENT_FEE = 11,
  LOAN_GRANTED = 12,
  LOAN_PAID = 13, // TODO: This is not used, remove it
  LOAN_REIMBURSMENT = 14,
  CRYPTO_ASSET_SETTLEMENT = 15,
  PAYMENT_WITH_CARD = 16,
  SWAP_POGR = 17,
  SWAP_COST = 18,
  GIFT = 19,
  PAYMENT_IN_WARRANTY = 20,
  PAYMENT_WARRANTY_CLAIM = 21,
  PAYMENT_CREDITS_FEE = 22,
  FREEZE_TOKENS = 23,
  UNFREEZE_TOKENS = 24,
  WARRANTY_TRANSFER_TOKEN_LOCK = 25,
  WARRANTY_TRANSFER = 26,
  TRANSFER_FEE = 27,
  CANCELED_WARRANTY_TRANSFER_TOKEN_UNLOCK = 28,
  REJECTED_WARRANTY_TRANSFER_TOKEN_UNLOCK = 29,
  EXPIRED_WARRANTY_TRANSFER_TOKEN_UNLOCK = 30,
  CREDIT_LINE_COLLATERALIZATION = 31,
  CREDIT_LINE_DISBURSEMENT = 32,
  CREDIT_LINE_LIQUIDITY_PROVIDED = 33,
  CREDIT_LINE_OPERATIVE_AMOUNT_RECOMPOSITION = 34,
  CREDIT_LINE_LIQUIDITY_RETURNED = 35,
}

export enum GrainType {
  FUTURE = 'Future',
  REAL = 'Real',
}

export interface Result {
  name: string;
  id: number;
}

export interface SelectedCurrency {
  name: string;
  value: number | string;
}

export interface ITransaction {
  network: INetwork;
  hash: string;
}

export interface IGrainContractsOperation {
  grainContract: IGrainContract;
  operation: IOperation;
}

export interface IOperation {
  id: number;
  amount: number;
  createdAt: string;
  operationType: OperationType;
  description: string;
  currencyId: number;
  currency: ICurrency;
  currencyRate: number;
  usdCurrencyRate: number;
  operationStatus: OperationStatus;
  originalAmount: number;
  fromBusinessUser: IBusinessUser;
  fromBusinessUserId: number;
  toBusinessUser: IBusinessUser;
  toBusinessUserId: number;
  transactions: ITransaction[];
  fee: number;
  localCurrencyRate: number;
  cardOperationId: number;
  originalCardOperationId: number;
  farmerFee: number;
  merchantFee: number;
  movements: IMovement[];
  paymentRequest: IPaymentRequest;
  grainContractsOperations: IGrainContractsOperation[];
  transfer?: { uuid: string };
  assetLockTransfer?: { uuid: string };
  assetUnlockTransfer?: { uuid: string };
}

export interface IPaymentRequestChangeLog {
  id: number;
  paymentRequestId: number;
  status: PaymentRequestStatus;
  createdAt: string;
}

export interface IPaymentRequest {
  id: number;
  amount: number;
  description: string;
  createdAt: string;
  currency: ICurrency;
  uuid: string;
  fromBusinessUser: IBusinessUser;
  fromBusinessUserId: number;
  toBusiness: IBusiness;
  toBusinessId: number;
  createdByUser: IUser;
  operation: IOperation | null;
  status: PaymentRequestStatus;
  paymentRequestChangeLogs: IPaymentRequestChangeLog[];
  document: IDocument;
  documentId: number;
  merchantFee: number;
  payerFee: number;
  loanId: number;
  loan: Loan;
  type: PaymentRequestType;
  warrantyMetadata: PaymentRequestWarrantyMetadata | null;
  fixedGrainContractMetadata: FixedGrainContractMetadata | null;
  expiresAt: number;
  forwardingMetadata?: ForwardingMetadata;
  redirectUrl: string | null;
}
export interface IPaymentRequestForwardings {
  toBusinessId: number;
  grossAmount: number;
  paymentRequestId: number;
  fromBusinessId: number;
  referenceCurrencyId: number;
  feePercentage: number;
  feeAmount: number;
  createdAt: string;
  updatedAt: string;
  tokenId: number;
  grossTokenAmount: number;
  tokenLocalRate: number;
  feeTokenAmount: number;
  originalOperationId: number;
  operationId: number;
  documentId: number;
  concept: string;
  id: number;
}

export interface PaymentRequestWarrantyMetadata {
  id: number;
  paymentRequestId: number;
  warrantyGrainContractId: number;
  claimabilityStatus: PaymentRequestWarrantyStatus;
  claimabilityDate: number;
  claimedDate: number | null;
  pricePerSaca: string; // Decimal
  paymentRequest: PaymentRequest;
  warrantyGrainContract: IGrainContract;
}

export interface FixedGrainContractMetadata {
  createdAt: string;
  updatedAt: string;
  fixedGrainContract: IGrainContract;
}

export interface IPayment {
  status: string;
  date?: number;
  userTo: string;
  fiscalId: string;
  comment: string;
  amountBRL: number;
  amountCrypto: number;
}

export interface INavigationLoginState {
  returnUrl: string;
}

export enum EPaymentRequestStatusTranslated {
  PENDING = 'pending',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

export enum EValidationStatus {
  PENDING = 'PENDING',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  APPROVED = 'APPROVED',
}

export enum EMerchantRequestStatusId {
  PENDING = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  DENIED = 4,
}

export interface ICreatePaymentRequestDto {
  amount: number;
  createdByUserId: number;
  fromBusinessId: number;
  toBusinessFiscalId: string;
  description: string;
  documentId: number | null;
  type: string | null;
  warrantyId: number | undefined;
  fixedGrainUuid: string | undefined;
  forwardings: IPaymentRequestForwardingsDto[];
}

export interface IPaymentRequestForwardingsDto {
  toBusinessFiscalId: string;
  grossAmount: string;
  concept: string | null;
  documentUuid: string | null;
}

export interface IUpdatePaymentRequestDto {
  paymentRequestStatusId: number;
}

export interface INotification {
  id: number;
  subject: string;
  body: string;
  read: boolean;
  link: string;
  businessId: number;
  createdAt: string;
}

export interface ICreateNewBusiness {
  userId: number;
  businessId: number;
  businessName: string;
  givenName: string;
  lastName: string;
  phone: string;
}

export interface IMerchantRequestStatus {
  id: number;
  name: string;
  description: string;
}
export interface IMerchantRequest {
  id: number;
  merchantRequestStatusId: number;
  createdByBusinessUserId: number;
  userAdminId?: number;
  createdAt: number;
  updatedAt: number;
  businessUser: IBusinessUser;
  merchantRequestStatus: IMerchantRequestStatus;
}

export interface IMovement {
  id: number;
  operationId: number;
  operation: IOperation;
  amount: number;
  createdAt: number;
  businessId: number;
  business: IBusiness;
  movementTypeId: number;
  movementType: IMovementType;
  currencyId: number;
  currency: ICurrency;
  amountFiat: number;
  movementConversion: MovementConversion;
  amountInSacas?: number;
}

export interface IMovementType {
  id: number;
  name: string;
  description: string;
}

export enum ENetworkTransactionScan {
  POLYGON = 'Polygonscan',
}

export enum ENetworkTransactionScanUrl {
  POLYGON = 'https://polygonscan.com/tx/',
}

export interface ICreateFullUser {
  authUuid: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  userIdOld?: string;
  pep?: number;
  pepVerified?: number;
  gender?: string;
  birthDate?: number;
  identificationValue?: string;
  identificationTypeId?: number;
  identificationTaxValue?: string;
  identificationTaxTypeId?: number;
  fiscalId: string;
  name: string;
  businessPersonTypeId: number;
}

export enum EBusinessStructureTypes { // TODO: Centralize in common-utils. Refactor imports.
  PERSON = 1,
  COMPANY = 2,
}

export enum EFullOnboardingLevel {
  LEVEL1 = 1,
  LEVEL2 = 2,
  LEVEL3 = 3,
}

export enum EBackOfficeValidationStatus {
  PENDING = 1,
  CHANGE_REQUEST = 2,
  APPROVED = 3,
}

export interface IFees {
  payerFee: Decimal;
  receiverFee: Decimal;
  payerFixedFee: Decimal;
  receiverFixedFee: Decimal;
}

export interface Loan {
  id: string;
  name: string;
  token: ILoanCurrency;
  warranty: boolean;
  tokens: number;
  amountToFinanceFiat: number;
  fiat: string;
  type: ELoanType;
  tokensLockedTotal: string;
  country: RegionCode;
  paymentRequestId: number;
  distributionAmount: number;
}

export enum CurrencyCode {
  AR = 'ARS',
  BR = 'BLR',
}

export enum ERiskLevels {
  HIGH_RISK = 20,
  LOW_RISK = 60,
}

export enum RiskLevelColors {
  HIGH_RISK = 'error',
  MEDIUM_RISK = 'warning',
  LOW_RISK = 'success',
  CANCELLED = 'gray',
}

export enum RiskLevelNames {
  HIGH_RISK = 'HIGH_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
  LOW_RISK = 'LOW_RISK',
}

export interface ILoanCurrency {
  name: CryptoCurrencySymbol;
}

export interface IGetLoansBalancesResponse {
  data: Loan[];
}
export interface IExchangeTokens {
  amount: string;
  unit: EUnits;
}

export interface IConversions {
  amount: number;
  unit: string;
}

export interface IBalance {
  id: number;
  name: string;
  symbol: CryptoCurrencySymbol;
  icon: string | null;
  rate: number;
  decimals: number;
  isCrypto: number;
  isActive: boolean;
  amount: string;
  conversions: IConversions[];
}

export interface FarmerOwnerType {
  description: string | null;
  id: number;
  keyCode: string;
  name: string;
}

export interface IPixType {
  id: number;
  name: string;
  description: string;
}

export interface IPix {
  createdAt: number;
  updatedAt: number;
  value: number;
  id: number;
  pixType: IPixType;
  pixTypeId: number;
  businessBankAccountId: number;
}

export enum EPixTypeId {
  EMAIL = 1,
  PHONE_NUMBER = 2,
  RANDOM_KEY = 3,
  FISCAL_ID = 4,
}

export interface IOnboardingLevel3Request {
  id: number | null;
  mainActivityId: number | null;
  bankName: string | null;
  bankCode: string | null;
  accountTypeId: number | null;
  agencyCode: string | null;
  accountNumber: string | null;
  pix: string | null;
  pixTypeId: string | null;
  validationStatusId: EValidationStatusId | null;
  businessValidationStatusId: number | null;
  businessValidationMessage: string | null;
  bankAccountValidationStatusId: number | null;
  bankAccountValidationMessage: string | null;
  beneficiariesValidationStatusId: number | null;
  beneficiariesValidationMessagae: string | null;
}

export interface IOnboardingLevel2Request {
  id: number | null;
  validationStatusId: EValidationStatusId;
  userValidationStatusId: number | null;
  userDocumentsValidationStatusId: number | null;
  businessUserDocumentsValidationStatusId: number | null;
  businessDocumentsValidationStatusId: number | null;
  businessAddressValidationStatusId: number | null;
}

export enum EAccountTypeId {
  SAVING_BOX = 1,
  CURRENT_ACCOUNT = 2,
}

export interface IBusinessActivity {
  id: number;
  name: string;
  keyCode: string;
  description: string;
}

export enum EValidationStatusId {
  PENDING = 1,
  CHANGE_REQUEST = 2,
  APPROVED = 3,
}

export interface ErrorResponse {
  message: string;
}

export interface TabItem {
  id: number | string;
  label: string;
  onClick: () => void;
  isActive: boolean;
}

export interface SelectOption {
  value: string | number;
  label: string;
}

export interface CurrencyOption {
  label: string;
  value: number | string;
}

export enum OpertationDescription {
  DETOKENIZATION = 'Manual Detokenization',
  DETOKENIZATION_EXPIRED = 'Automatic Detokenization - Contract Expired',
  DETOKENIZATION_COLATERAL = 'Automatic Detokenization - Low Collateral',
}

export interface OnboardingLevel2RequestDto {
  cuit?: string;
  pep?: number;
  idFrontFileId?: number | null;
  idBackFileId?: number | null;
  cityId?: number;
  geographicalDivisionId?: number;
  physicalAddress?: string;
  postalCode?: string;
  legalPowerFilesIds?: number[] | null;
  socialContractFilesIds?: number[] | null;
  statuteFilesIds?: number[] | null;
  authoritiesDesignationFilesIds?: number[] | null;
  tokenizationConditionsAcceptedAt?: number;
  isPendingOpsReview?: boolean;
  isPendingUserReview?: boolean;
}

export interface UpdateOnboardingLevel2RequestDto {
  businessUserId: number;
}

export interface UpdateOnboardingLevel3RequestDto {
  businessId: number;
}

export interface OnboardingLevel3RequestDto {
  mainActivity?: string;
  bankName?: string;
  cbu?: string;
  alias?: string;
  accountTypeId?: number;
  cbuDocumentId?: number;
  ddjjSobligated?: boolean;
  ddjjSobligatedDocumentId?: number | null;
  beneficiaries?: IFinalBeneficiary[];
  isPendingOpsReview?: boolean;
  isPendingUserReview?: boolean;
}
export enum Origin {
  TOKENIZATION = 'TOKENIZATION',
  PAYMENT = 'PAYMENT',
}

export enum WarrantyStatus {
  PENDING_LIQUIDITY = 'PENDING_LIQUIDITY',
  COMPLETED = 'COMPLETED',
}

export interface Warranty {
  id: number;
  requestNumber: number;
  currency: { name: string };
  farm: IFarm;
  healthControl: Decimal;
  expirationDate: number;
  origin: Origin;
  conversions: any;
  equivalences: any;
  status?: WarrantyStatus;
  claimabilityStatus?: PaymentRequestWarrantyStatus;
  grainContractStatusId?: EGrainContractStatusId;
}
export interface ExtendedWarranty {
  id: number;
  farmerId: number;
  farmer: IBusiness;
  oracleId: number;
  oracle: IBusiness;
  currencyId: number;
  currency: ICurrency;
  networkId: number;
  network: INetwork;
  grainContractStatusId: number;
  grainContractStatus: IGrainContractStatus;
  healthRatio: Decimal | number | null;
  userId: number;
  user: IUser;
  amount: number | null;
  requestNumber: number | null;
  tonsDetokenized: number | null;
  tonsRequested: number | null;
  tonsVerified: number | null;
  tonsReserved: number | null;
  smartContractUuid: number | null;
  renovatedAt: number | null;
  createdAt: number | null;
  expirationDate: number;
  agreementDoc: IAgreementDoc;
  cdes: ICde[] | [];
  docusigns: IDocusign[] | [];
  percentReserved: number | null;
  isRiskApproved: number;
  farm: IFarm | null;
  farmId: number | null;
  documents: IDocument[];
  signatureType: ISignatureType;
  warehouse: IWarehouse;
  grainContractType: GrainContractTypes;
  cpr: Cpr;
  grainContractsOperations: IGrainContractsOperation[];
  validationBy: GrainContractValidationBy | null;
  validationStatus: GrainContractValidationStatus | null;
  priceAdjustment?: string | null;
  healthControl?: Decimal;
  zendeskId: number | null;
  managerId: number | null;
  baseAmount?: Decimal;
  conversions?: any;
  equivalences?: any;
  status?: WarrantyStatus;
  uuid: string;
  origin: Origin;
}

interface BusinessInviteBaseParams {
  businessId: number;
  permissions: number[];
}

export interface BusinessInviteParams extends BusinessInviteBaseParams {
  email: string;
}

export interface ResendBusinessInviteParams extends BusinessInviteBaseParams {
  userId: number;
}
