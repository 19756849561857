import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import { Box, Spinner } from '@chakra-ui/react';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Modal } from '../Modal';

// For additional info regarding AR Flow:
// https://whimsical.com/onboarding-flows-navigation-ar-FcCf3YnhyXZ9fDpW1TRxc6

interface propState {
  showRequestModalSent: boolean;
}

const CompleteOnboardingBanner = () => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const location = useLocation();
  const state = location.state as propState;
  const showRequestModalSent = state ? state.showRequestModalSent : false;

  const [isOpenThankyouModal, setIsOpenThankyouModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (showRequestModalSent) {
      setIsOpenThankyouModal(true);
      setTimeout(() => setIsOpenThankyouModal(false), 5000);
    }
  }, [showRequestModalSent]);

  const {
    selectedBusinessUserWasInvited,
    selectedBusinessUserWasAdminInvited,
    isLoadingUser,
    selectedBusinessUserWasInvitedIsLoading,
    selectedBusinessUserWasAdminInvitedIsLoading,
  } = useUser();

  if (
    isLoadingUser ||
    selectedBusinessUserWasInvitedIsLoading ||
    selectedBusinessUserWasAdminInvitedIsLoading
  ) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  const OnboardingBannerInviteFlowAR = lazy(
    () => import('./component/AR/OnboardingBannerInviteFlow')
  );
  const OnboardingBannerInviteFlowBR = lazy(
    () => import('./component/BR/OnboardingBannerInviteFlow')
  );
  const OnboardingBannerSignUpFlowAR = lazy(
    () => import('./component/AR/OnboardingBannerSignUpFlow')
  );
  const OnboardingBannerSignUpFlowBR = lazy(
    () => import('./component/BR/OnboardingBannerSignUpFlow')
  );

  const localisedComponent = {
    [ENationalities.AR]: () => {
      return selectedBusinessUserWasInvited &&
        !selectedBusinessUserWasAdminInvited ? (
        <Suspense fallback={<Spinner />} key="invite_ar_flow">
          <OnboardingBannerInviteFlowAR />
        </Suspense>
      ) : (
        <Suspense fallback={<Spinner />} key="sign_up_ar_flow">
          <OnboardingBannerSignUpFlowAR />
        </Suspense>
      );
    },
    [ENationalities.BR]: () => {
      return selectedBusinessUserWasInvited &&
        !selectedBusinessUserWasAdminInvited ? (
        <Suspense fallback={<Spinner />} key="invite_br_flow">
          <OnboardingBannerInviteFlowBR />
        </Suspense>
      ) : (
        <Suspense fallback={<Spinner />} key="sign_up_br_flow">
          <OnboardingBannerSignUpFlowBR />
        </Suspense>
      );
    },
  };

  return (
    <>
      {localisedComponent[nationality]()}
      <Modal
        isOpen={isOpenThankyouModal}
        title={t('user.thankyouModal.title')}
        text={t('user.thankyouModal.text')}
        labelBtnConfirm={t('user.thankyouModal.labelBtnConfirm')}
        onClickConfirm={() => setIsOpenThankyouModal(false)}
        hidden
      />
    </>
  );
};

export default CompleteOnboardingBanner;
