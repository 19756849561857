import { Card, Text } from '@agrotoken-tech/ui';
import { Flex, Divider } from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';
import {
  CurrencyNameBrTranslation,
  EUnits,
  IGrainContract,
} from '@common/types';
import { formatNumber } from '@agrotoken/common-utils';
import { getCurrencySummaryTranslation } from '@comp/Tokenization/Summary/get-currency-summary-translation';
import DetokenizationModalSummaryTranslationParams from '@comp/Tokenization/Summary/currency-summary-translation.interface';

interface Props {
  grainContract: IGrainContract;
}

const BreakDownFixedGrain: FC<Props> = ({ grainContract }) => {
  const { farm, currency, grainContractType } = grainContract ?? {};

  const translatedCurrencyName = getCurrencySummaryTranslation({
    currencyName: currency?.name ?? '',
    grainContractType,
    farmName: farm?.name ?? '',
  } as DetokenizationModalSummaryTranslationParams);

  return (
    <Card variant="gray" padding="md">
      <Text size="md" variant="regular" mb="4">
        {t('tokenization.summary.breakDown.breakDownTitle')}
      </Text>
      <Flex justifyContent={'space-between'} mb="2">
        <Text size="sm" variant="regular">
          {t('tokenization.summary.breakDown.creationLabel')}
        </Text>
        <Text size="sm" variant="regular">
          {t(
            `grainContractDetails.fixedCurrencyText.${grainContract.currency.name}`
          )}
        </Text>
      </Flex>
      <Flex justifyContent={'space-between'}>
        <Text size="sm" variant="regular">
          {t('tokenization.summary.breakDown.farm')}
        </Text>
        <Text size="sm" variant="regular">
          {grainContract.farm?.name}
        </Text>
      </Flex>
      <Divider my="4" />
      <Flex justifyContent={'space-between'}>
        <Text size="lg" variant="medium" mb="2">
          {t('tokenization.summary.breakDown.tons')}
        </Text>
        <Text size="lg" variant="medium">
          {`${formatNumber(grainContract.tonsVerified, EUnits.TONS)} ${t(
            `${translatedCurrencyName}`
          )}`}
        </Text>
      </Flex>
      <Flex justifyContent={'space-between'}>
        <Text size="sm" variant="regular" mb="2">
          {t('tokenization.summary.breakDown.currencyEquivalenceLabel')}
        </Text>
        <Text size="sm" variant="regular">
          {CurrencyNameBrTranslation.BRL}{' '}
          {formatNumber(
            grainContract.equivalences.tonsVerified.BRL,
            EUnits.TONS
          )}
        </Text>
      </Flex>
      <Flex justifyContent={'space-between'}>
        <Text size="sm" variant="regular">
          {t('tokenization.summary.breakDown.sacasEquivalenceLabel')}
        </Text>
        <Text size="sm" variant="regular">
          {formatNumber(
            grainContract.equivalences.tonsVerified.SACAS,
            EUnits.SACAS
          )}{' '}
          {t('tokenization.summary.breakDown.sacas')}
        </Text>
      </Flex>
    </Card>
  );
};

export default BreakDownFixedGrain;
