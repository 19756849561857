import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { EWalletType } from '@common/enum';
import { useStepForm } from '@comp/Global/StepForm';
import { TransferTokensFormValues } from '@comp/TransferTokens';
import useGetConversionRate from '@hooks/useGetConversionRate';
import { getOperationStatus } from '@services/Operations';
import { swapPoGR, SwapPoGRPayload, SwapPoGRResponse } from '@services/Swap';
import { TOKENS_DEFAULT_DECIMALS } from '@src/common/const';
import { ErrorResponse, OPERATION_STATUSES } from '@src/common/types';
import { TokenCurrency } from '@agrotoken/common-utils';

export const useSwapSubmit = () => {
  const { handleNextStep, formValues } =
    useStepForm<TransferTokensFormValues>();
  const { tokenToLocalCurrency } = useGetConversionRate();
  const { token, amount } = formValues;

  const {
    mutate: executeSwap,
    isLoading,
    isSuccess,
    error,
    data,
  } = useMutation<SwapPoGRResponse, ErrorResponse, SwapPoGRPayload>(swapPoGR);

  const checkOperationStatus = async () => {
    if (!data?.uuid) {
      return;
    }

    return getOperationStatus(data.uuid);
  };

  // We want to poll periodically; this is a little suboptimal with react query though
  const { data: statusData } = useQuery(
    ['operationStatus', data?.uuid],
    checkOperationStatus,
    {
      refetchInterval: 2000,
    }
  );

  useEffect(() => {
    if (statusData?.status === OPERATION_STATUSES.SUCCESSFUL) {
      handleNextStep();
    }
  }, [statusData?.status]); // eslint-disable-line

  // Derived state & state-dependent functions

  const isAwaitingBlockchainConfirmation =
    isSuccess || statusData?.status === OPERATION_STATUSES.PENDING;
  const parsedAmount = Number(amount);

  const perpetuityPercentage = 0; // TODO: Fetch from DB
  const perpetuityAmount = roundValue({
    value: parsedAmount * (perpetuityPercentage / 100),
    // TODO: operation type should be defined for perpetuty amount in roundValue function
    operationType: EPaymentRequestOperationType.FARMER_FEE,
    scale: TOKENS_DEFAULT_DECIMALS,
  }).toNumber();

  const totalTokenAmount = parsedAmount - perpetuityAmount;
  const totalFiatAmount = tokenToLocalCurrency(
    parsedAmount - perpetuityAmount,
    token as TokenCurrency
  ); // TODO: This should be more general.

  const handleSubmit = async (): Promise<void> => {
    await executeSwap({
      amount: parsedAmount,
      currency: token,
      toWallet: EWalletType.EXTERNAL, // TODO: Remove hardcoded condition in the future.
    });
  };

  return {
    isSwapLoading: isLoading || isSuccess,
    swapError: error,
    isAwaitingBlockchainConfirmation,
    token,
    parsedAmount,
    perpetuityPercentage,
    perpetuityAmount,
    totalTokenAmount,
    totalFiatAmount,
    handleSubmit,
  };
};
