import {
  FULL_ONBOARDING_BANKING_AND_BILLING,
  FULL_ONBOARDING_KYC,
  HOME_PATH,
} from '@src/common/const';
import { useAccess } from '@src/context/accessContext';
import { StatusCodes } from 'http-status-codes';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@comp/Global/Loader';
import { Modal } from '@comp/Global/Modal';
import { ServerError } from '@comp/Global/ServerError';
import {
  EPermissionCategoryId,
  IPaymentRequest,
  PAYMENT_REQUEST_STATUSES,
} from '../../common/types';
import { useUser } from '../../context/userContext';
import {
  getPaymentRequest,
  getPaymentRequestDetail,
} from '../../services/PaymentRequests';
import {
  RequireCreatePaymentAccess,
  RequirePaymentAccess,
} from '../AccessControl';
import { ExpiredPayment, MakePayment } from './Payer';
import { CreatedPayment, PaymentDetails } from './Receiver';
import { Unauthorized } from './Unauthorized';
import { FiUserCheck } from 'react-icons/fi';

const PaymentRequestView: FC = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [paymentRequest, setPaymentRequest] = useState<
    IPaymentRequest | undefined
  >();
  const [isLoadingPaymentRequest, setIsLoadingPaymentRequest] = useState(false);
  const [isErrorPaymentRequest, setIsErrorPaymentRequest] = useState(false);
  const [errorPaymentRequest, setErrorPaymentRequest] = useState<any>();

  const { selectedBusinessId, isLoadingUser, currentUser } = useUser();

  const { isRepresentant, isTransactionable } = useAccess();

  const canViewPaymentDetail = currentUser?.businessUsers
    .find((bu) => bu.businessId === selectedBusinessId)
    ?.permissions.some(
      (perm) =>
        perm.id === EPermissionCategoryId.CREATE_PAYMENT_LINK ||
        perm.id === EPermissionCategoryId.PAYMENTS
    );

  const hasCreatePaymentPermission = useCallback(() => {
    const businessUser = currentUser?.businessUsers.find(
      (bu) => bu.businessId === selectedBusinessId
    );
    const isAuthorized =
      businessUser?.permissions.some(
        (perm) =>
          perm.permissionCategoryId ===
          EPermissionCategoryId.CREATE_PAYMENT_LINK
      ) &&
      (businessUser.isRepresentant || businessUser.isOnboardingValidated) &&
      businessUser.business.isMerchant &&
      businessUser.business.isTransactionable;
    return isAuthorized;
  }, [currentUser, selectedBusinessId]);

  const hasPaymentPermission = useCallback(() => {
    const businessUser = currentUser?.businessUsers.find(
      (bu) => bu.businessId === selectedBusinessId
    );
    const isAuthorized =
      businessUser?.permissions.some(
        (perm) => perm.permissionCategoryId === EPermissionCategoryId.PAYMENTS
      ) && businessUser.isRepresentant;
    return isAuthorized;
  }, [currentUser, selectedBusinessId]);

  const getPaymentRequestData = useCallback(async () => {
    setIsLoadingPaymentRequest(true);
    try {
      // paymentPermission is more restrictive, so is better check it first.
      if (hasPaymentPermission()) {
        const data = await getPaymentRequest(params.uuid!);
        setPaymentRequest(data);
      } else if (hasCreatePaymentPermission()) {
        const data = await getPaymentRequestDetail(params.uuid!);
        setPaymentRequest(data);
      }
    } catch (error) {
      setIsErrorPaymentRequest(true);
      setErrorPaymentRequest(error);
    } finally {
      setIsLoadingPaymentRequest(false);
    }
  }, [hasCreatePaymentPermission, hasPaymentPermission, params.uuid]);

  useEffect(() => {
    const fetchData = async () => {
      await getPaymentRequestData();
    };
    fetchData().catch((error) => {
      setIsErrorPaymentRequest(true);
      setErrorPaymentRequest(error);
    });
  }, [getPaymentRequestData]);

  if (isLoadingPaymentRequest || isLoadingUser) return <Loader />;

  if (!isTransactionable)
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_BANKING_AND_BILLING)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );

  if (!isRepresentant && !hasCreatePaymentPermission)
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_KYC)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  if (!canViewPaymentDetail)
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );

  const internalServerError =
    errorPaymentRequest?.response?.status === StatusCodes.INTERNAL_SERVER_ERROR;

  if (internalServerError || isErrorPaymentRequest || !paymentRequest)
    return (
      <ServerError
        title={t('serverError.title')}
        text={t('serverError.text')}
      />
    );

  const { toBusinessId, fromBusinessUser, status } = paymentRequest;

  if (
    selectedBusinessId !== toBusinessId &&
    selectedBusinessId !== fromBusinessUser.businessId
  ) {
    return <Unauthorized />;
  }
  // Payer View
  if (selectedBusinessId === toBusinessId) {
    switch (status) {
      case PAYMENT_REQUEST_STATUSES.PENDING:
      case PAYMENT_REQUEST_STATUSES.IN_PROGRESS:
        return (
          <RequirePaymentAccess paymentRequest={paymentRequest}>
            <MakePayment paymentRequest={paymentRequest} />
          </RequirePaymentAccess>
        );
      case PAYMENT_REQUEST_STATUSES.COMPLETED:
        return <Navigate to="success" />;
      case PAYMENT_REQUEST_STATUSES.EXPIRED:
        return <ExpiredPayment />;
      default:
        return null;
    }
  }

  // Receiver View
  if (selectedBusinessId === fromBusinessUser.businessId)
    switch (status) {
      case PAYMENT_REQUEST_STATUSES.PENDING:
        return (
          <RequireCreatePaymentAccess>
            <CreatedPayment paymentRequest={paymentRequest} />
          </RequireCreatePaymentAccess>
        );
      case PAYMENT_REQUEST_STATUSES.IN_PROGRESS:
      case PAYMENT_REQUEST_STATUSES.COMPLETED:
      case PAYMENT_REQUEST_STATUSES.EXPIRED:
        return (
          <RequireCreatePaymentAccess>
            <PaymentDetails paymentRequest={paymentRequest} />
          </RequireCreatePaymentAccess>
        );

      default:
        break;
    }
  return (
    <ServerError title={t('serverError.title')} text={t('serverError.text')} />
  );
};

export default PaymentRequestView;
