import { addDot } from './utils';

const ALLOWED_DOCUMENTS = ['pdf'];

const JPG_IMAGES = ['jpg', 'jpeg'];

const PNG_IMAGES = ['png'];

export const ALLOWED_IMAGES = [...JPG_IMAGES, ...PNG_IMAGES];

export const ACCEPTED_IMAGES_MIME_TYPES = {
  'image/jpeg': addDot(JPG_IMAGES),
  'image/png': addDot(PNG_IMAGES),
};

export const ACCEPTED_DOCUMENTS_MIME_TYPES = {
  'application/pdf': addDot(ALLOWED_DOCUMENTS),
};
export const MAXIMUM_FILE_SIZE_IN_MEGABYTES = 20;

export const MAXIMUM_FILE_SIZE_IN_BYTES =
  MAXIMUM_FILE_SIZE_IN_MEGABYTES * 1000 * 1000;

export const DROPZONE_ERRORS_BY_CODE: Record<string, string> = {
  'file-invalid-type': 'El archivo no es válido',
  'file-too-large': 'El archivo es demasiado grande',
  'file-too-small': 'El archivo es demasiado pequeño',
  'too-many-files': 'Demasiados archivos',
};
