import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { IPaymentRequest } from '@common/types';

export const estimatedFee = (
  amount: number,
  paymentRequest: IPaymentRequest,
  isPayer = false
) => {
  const fee = isPayer ? paymentRequest.payerFee : paymentRequest.merchantFee;
  const operationType = isPayer
    ? EPaymentRequestOperationType.FARMER_FEE
    : EPaymentRequestOperationType.MERCHANT_FEE;

  const estimatedFees = roundValue({
    value: amount * (fee / 100),
    operationType,
    scale: 2,
  }).toNumber();
  return estimatedFees;
};
