import { AgrotokenThemeProvider } from '@agrotoken-tech/ui';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import TagManager from 'react-gtm-module';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import packageJson from '../package.json';

import Content from './components/Content';
import { AuthProvider } from './context/authContext';
import { queryClient } from './queryClient';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const sentryIsEnabled = sentryDsn && sentryEnvironment;
const gtmID = process.env.REACT_APP_GTM_ID;

if (gtmID) {
  TagManager.initialize({ gtmId: gtmID });
}

if (sentryIsEnabled) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren as any,
          matchRoutes as any
        ),
      }),
    ],
    tracesSampleRate: 0.25,
    environment: sentryEnvironment,
    release: packageJson.version,
  });
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(
  Routes as any
);
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AgrotokenThemeProvider>
        <AuthProvider>
          <Content />
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </AgrotokenThemeProvider>
    </QueryClientProvider>
  );
}

export default Sentry.withProfiler(
  withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || '',
  })(App)
);
