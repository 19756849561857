import { Button } from '@chakra-ui/react';
import { BREAKPOINTS } from '@common/const';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { convertBytesToMb } from './utils';

interface Props {
  fileName: string;
  fileSize: number;
  onClickDelete: () => void;
  isLoading: boolean;
  disabled?: boolean;
}

export const SuccessPreview: FC<Props> = ({
  fileName,
  fileSize,
  onClickDelete,
  isLoading,
  disabled,
}) => {
  const formatFileSize = convertBytesToMb(fileSize).toLocaleString('es-AR');
  const { t } = useTranslation();

  return (
    <li className="border border-gray-200 p-4 mt-4 flex justify-between">
      <div className="flex">
        <Icon
          size="md"
          color="primary"
          theme="light-circle-outline"
          icon="file-text"
        />
        <div className="ml-3.5">
          <p className="text-sm text-gray-700 font-medium">{fileName}</p>
          <p className="text-sm text-gray-500 font-normal">{`${formatFileSize} MB`}</p>
        </div>
      </div>
      {isLoading ? (
        <Spinner variant="line-simple" size="sm" className="self-center" />
      ) : (
        <Button
          variant="linkPrimary"
          leftIcon={<FiTrash2 />}
          onClick={onClickDelete}
          disabled={disabled}
        >
          {window.innerWidth < BREAKPOINTS.md ? '' : t('btnDeleteFile')}
        </Button>
      )}
    </li>
  );
};
