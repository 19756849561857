import { Modal } from '@comp/Global/Modal';
import {
  BREAKPOINTS,
  FULL_ONBOARDING_BANKING_AND_BILLING,
  HOME_PATH,
} from '@src/common/const';
import { useAccess } from '@src/context/accessContext';
import { useUser } from '@src/context/userContext';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CongratsModal from '../CongratsModal';

interface Props {
  isModalOpen: boolean;
  handleCloseModal: () => void;
}
const SecondStepModal: VFC<Props> = ({ isModalOpen, handleCloseModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    selectedBusinessUserWasInvited,
    selectedBusinessUserWasAdminInvited,
  } = useUser();
  const { isThirdOnboardingLevel } = useAccess();
  let title =
    selectedBusinessUserWasInvited && !selectedBusinessUserWasAdminInvited
      ? t('onboardingLevelThree.successModal.titleTwoSteps')
      : t('onboardingLevelThree.successModal.titleThreeSteps');

  if (isThirdOnboardingLevel) {
    title = t('onboardingLevelThree.successModal.title');
  }

  const navigateToHome = () => {
    navigate(HOME_PATH);
  };

  const navigateToBankingAndBilling = () => {
    navigate(FULL_ONBOARDING_BANKING_AND_BILLING);
  };

  const actionsProps = {
    ...((selectedBusinessUserWasInvited || isThirdOnboardingLevel) &&
    !selectedBusinessUserWasAdminInvited
      ? {
          primaryActionLabel: t('onboardingLevelThree.backToMain'),
          primaryAction: navigateToHome,
        }
      : {
          primaryActionLabel: t('onboardingLevelThree.continueEdit'),
          primaryAction: navigateToBankingAndBilling,
          secondaryActionLabel: t('onboardingLevelThree.backToMain'),
          secondaryAction: navigateToHome,
        }),
  };
  const isMobile = window.innerWidth < BREAKPOINTS.md;

  if (isMobile) {
    return (
      <Modal
        isOpen={isModalOpen}
        onClickClose={handleCloseModal}
        title={title}
        text={t('onboardingLevelThree.successModal.description')}
        {...((selectedBusinessUserWasInvited || isThirdOnboardingLevel) &&
        !selectedBusinessUserWasAdminInvited
          ? {
              labelBtnConfirm: t('onboardingLevelThree.backToMain'),
              onClickConfirm: navigateToHome,
              confirmButtonFill: true,
              cancelButtonFill: true,
            }
          : {
              labelBtnConfirm: t('onboardingLevelThree.continueEdit'),
              onClickConfirm: navigateToBankingAndBilling,
              labelBtnClose: t('onboardingLevelThree.backToMain'),
              onClickClose: navigateToHome,
              confirmButtonFill: true,
              cancelButtonFill: true,
            })}
      />
    );
  } else {
    return (
      <CongratsModal
        size={
          selectedBusinessUserWasInvited && !selectedBusinessUserWasAdminInvited
            ? 'md'
            : 'lg'
        }
        title={title}
        description={t('onboardingLevelThree.successModal.description')}
        {...actionsProps}
        isModalOpen={isModalOpen}
        handleCloseModal={navigateToHome}
      />
    );
  }
};

export default SecondStepModal;
