import { Box, Flex, Text, useToken } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';

interface AlertProps {
  text: string;
  supportText?: string;
}

// TODO: This component is a temporary solution, an `Alert` component should be created in the component library
const Alert = ({ text, supportText }: AlertProps) => {
  const error700 = useToken('colors', 'error.700');

  return (
    <Flex
      p="4"
      borderRadius="4px"
      border="1px"
      borderColor="error.700"
      bgColor="error.25"
      columnGap="3"
    >
      <FiAlertCircle size="20" color={error700} />
      <Flex flexDirection="column" rowGap="1">
        <Text size="sm" variant="medium" color="gray.700">
          {text}
        </Text>
        {supportText && (
          <Text size="sm" variant="regular" color="gray.600">
            {supportText}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default Alert;
