import { Box, Flex, Text } from '@chakra-ui/react';
import Lottie from 'react-lottie';

import animationData from '../../../assets/lotties/40-add-card-outline.json';

interface BlockchainStateProps {
  title: string;
  subtitle: string;
  className?: string;
}

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

// TODO: On refactor this component should be located at `/src/components`

/**
 * A component to display a blockchain state with title and subtitle.
 *
 * @param {BlockchainStateProps} props - The props for the BlockchainState component.
 * @returns {JSX.Element} - The rendered component.
 */
export const BlockchainState = ({
  title,
  subtitle,
  className = '',
}: BlockchainStateProps) => {
  return (
    <Flex alignItems="center" className={className}>
      <Flex columnGap="3">
        <Lottie
          options={lottieOptions}
          height={44}
          width={44}
          isClickToPauseDisabled
        />
        <Box>
          <Text size="sm" color="gray.700" variant="medium">
            {title}
          </Text>
          <Text size="sm" color="gray.600">
            {subtitle}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
