import { ContextPhoneInput } from '@agrotoken-tech/ui';
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IToken } from '@src/common/types';
import { namesRegExp, passwordRegExp } from '@utils/regExp';
import { PHONE_COUNTRY_CODES } from '@agrotoken/common-utils';
interface Props {
  isLoading: boolean;
  inviteeData?: IToken;
}

const JoinBusinessFields: FC<Props> = ({ inviteeData, isLoading }) => {
  const { t } = useTranslation();
  const context = useFormContext();
  const {
    register,
    formState: { errors },
    watch,
  } = context;

  const isNameError = Boolean(errors.givenName);
  const isLastNameError = Boolean(errors.lastName);
  const isPasswordError = Boolean(errors.password);
  const isPasswordConfirmError = Boolean(errors['password-confirm']);
  const isConsentTermsError = Boolean(errors['consent-terms']);

  const emptyFieldMessage = t('specialSignUp.form.error.empty');
  const inputStyleReferer = useRef<HTMLInputElement>(null);

  const [defaultPhoneCountryCode, setDefaultPhoneCountryCode] =
    useState<string>('');

  useEffect(() => {
    setDefaultPhoneCountryCode(
      PHONE_COUNTRY_CODES[
        inviteeData?.country as keyof typeof PHONE_COUNTRY_CODES
      ]
    );
  }, [inviteeData?.country]);

  return (
    <>
      {isLoading && (
        <Flex justifyContent={'center'}>
          <Spinner />
        </Flex>
      )}
      {!isLoading && inviteeData && (
        <>
          <SimpleGrid
            columns={[1, 1, 2]}
            rowGap="4"
            columnGap="6"
            ref={inputStyleReferer}
          >
            <FormControl isRequired isReadOnly>
              <FormLabel htmlFor="email">
                {t('specialSignUp.label.email')}
              </FormLabel>
              <Input
                type="email"
                id="email"
                readOnly
                value={inviteeData.email ?? ''}
                disabled
              />
            </FormControl>
            <FormControl isRequired isReadOnly>
              <FormLabel htmlFor="organization">
                {t('specialSignUp.label.organization')}
              </FormLabel>
              <Input
                type="text"
                id="organization"
                readOnly
                value={inviteeData.businessName ?? ''}
                disabled
              />
            </FormControl>
            <FormControl isRequired isInvalid={isNameError}>
              <FormLabel htmlFor="givenName">
                {t('specialSignUp.label.name')}
              </FormLabel>
              <Input
                type="text"
                id="givenName"
                defaultValue={inviteeData.firstName ?? ''}
                placeholder={t('specialSignUp.placeholder.name')}
                {...register('givenName', {
                  required: emptyFieldMessage,
                  pattern: {
                    value: namesRegExp,
                    message: t('specialSignUp.form.error.name'),
                  },
                })}
              />
              {isNameError && (
                <FormErrorMessage>{errors.givenName?.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={isLastNameError}>
              <FormLabel htmlFor="lastName">
                {t('specialSignUp.label.surname')}
              </FormLabel>
              <Input
                type="text"
                id="lastName"
                defaultValue={inviteeData.lastName ?? ''}
                placeholder={t('specialSignUp.placeholder.surname')}
                {...register('lastName', {
                  required: emptyFieldMessage,
                  pattern: {
                    value: namesRegExp,
                    message: t('specialSignUp.form.error.name'),
                  },
                })}
              />
              {isLastNameError && (
                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              )}
            </FormControl>
            <GridItem>
              <ContextPhoneInput
                name="phone"
                label={t('specialSignUp.label.phone')}
                context={context}
                defaultCounty={inviteeData.country as string}
                value={inviteeData.phone ?? defaultPhoneCountryCode}
                options={{ required: emptyFieldMessage }}
              />
            </GridItem>

            <GridItem display={['none', 'none', 'block']}></GridItem>
            <FormControl isRequired isInvalid={isPasswordError}>
              <FormLabel htmlFor="password">
                {t('specialSignUp.label.password')}
              </FormLabel>
              <Input
                type="password"
                id="password"
                placeholder={t(
                  'softOnboarding.signUpScreen.form.passwordPlaceholder'
                )}
                {...register('password', {
                  required: emptyFieldMessage,
                  pattern: {
                    value: passwordRegExp,
                    message: t('specialSignUp.form.error.password'),
                  },
                })}
              />

              {isPasswordError ? (
                <FormErrorMessage whiteSpace="pre-line">
                  {errors.password?.message}
                </FormErrorMessage>
              ) : (
                <FormHelperText whiteSpace="pre-line">
                  {t('specialSignUp.form.hintMessage.password')}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={isPasswordConfirmError}>
              <FormLabel htmlFor="password-confirmation">
                {t('softOnboarding.signUpScreen.form.verifyPassword')}
              </FormLabel>
              <Input
                type="password"
                id="password-confirmation"
                placeholder={t(
                  'softOnboarding.signUpScreen.form.verifyPasswordPlaceholder'
                )}
                {...register('password-confirm', {
                  required: emptyFieldMessage,
                  validate: (pw) =>
                    pw === watch('password') ||
                    t('specialSignUp.form.error.confirmPassword'),
                })}
              />
              {isPasswordConfirmError && (
                <FormErrorMessage>
                  {errors['password-confirm']?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <Stack spacing={6} mt="6">
            <FormControl isInvalid={isConsentTermsError}>
              <Checkbox
                colorScheme="primary"
                {...register('consent-terms', {
                  required: t('specialSignUp.termsAndConsError'),
                  validate: (value) =>
                    value || t('specialSignUp.termsAndConsError'),
                })}
              >
                {t('softOnboarding.signUpScreen.form.accept')}
                <Link
                  fontWeight="medium"
                  color="primary.800"
                  href={t(
                    'softOnboarding.signUpScreen.form.termsAndConditionsLink'
                  )}
                  target="_blank"
                  textDecor="underline"
                >
                  {t('softOnboarding.signUpScreen.form.termsAndConditions')}
                </Link>
              </Checkbox>
              {isConsentTermsError && (
                <FormErrorMessage>
                  {errors['consent-terms']?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <Button
              variant="primary"
              py="2.5"
              px="4"
              w="fit-content"
              type="submit"
              isLoading={isLoading}
            >
              {t('specialSignUp.label.btnNext')}
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};

export default JoinBusinessFields;
