import { useQuery } from 'react-query';

import { IBalance } from '@common/types';
import { getBalanceByBusiness } from '@services/Balances';
import { useUser } from '@src/context/userContext';

const useBalances = () => {
  const { selectedBusinessId } = useUser();

  const { isLoading, data, isError, error, refetch } = useQuery<
    IBalance[] | undefined
  >(['balances', selectedBusinessId], getBalanceByBusiness, {
    enabled: !!selectedBusinessId,
  });

  return { isLoading, data, isError, error, refetch };
};

export default useBalances;
