import { EVariationDirection } from './types';

export const ICON_DATA = {
  [EVariationDirection.NEGATIVE]: {
    textColor: 'error.700',
    icon: 'arrow-down',
  },
  [EVariationDirection.POSITIVE]: {
    textColor: 'primary.700',
    icon: 'arrow-up',
  },
  [EVariationDirection.NEUTRAL]: {
    textColor: 'gray.500',
    icon: '',
  },
};
