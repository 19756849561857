import { Spinner, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@comp/Global/Modal';
import { HOME_PATH } from '@src/common/const';
import { useAccess } from '@src/context/accessContext';
import { useUser } from '@src/context/userContext';

const RequireWarrantyTransferAccess: FC = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canMakePayments, isRepresentant } = useAccess();
  const { selectedBusiness, selectedBusinessUser, selectedBusinessId } =
    useUser();

  if ((!selectedBusiness || !selectedBusinessUser) && selectedBusinessId)
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    );

  if (!canMakePayments || !isRepresentant) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  return <>{children}</>;
};

export default RequireWarrantyTransferAccess;
