import { Box, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import { VFC } from 'react';
import FeatherIcon from 'feather-icons-react';

interface Props {
  fullName: string;
  handleEdit: any;
}
const FinalBeneficiaryPreview: VFC<Props> = ({ fullName, handleEdit }) => {
  return (
    <Flex
      mt="6"
      p="4"
      border="1px"
      borderColor="gray.200"
      borderRadius="base"
      justify="space-between"
      key={fullName}
    >
      <Box>
        <Heading size="sm">Beneficiario final: {fullName}</Heading>
        <Text as="i" fontSize="sm">
          Podés agregar uno o más beneficiarios finales.
        </Text>
      </Box>
      <Box>
        <IconButton
          type="button"
          variant="ghost"
          mr="4"
          bg="white"
          borderColor="gray.200"
          aria-label="edit beneficiary"
          icon={<FeatherIcon icon="edit-2" />}
          onClick={handleEdit}
        />
        {/* 
				TODO implement delete beneficiary
				
				<IconButton
					type="button"
					variant="ghost"
					mr="4"
					bg="white"
					borderColor="gray.200"
					aria-label="edit beneficiary"
					icon={<FeatherIcon icon="trash-2" />}
					onClick={() => console.log('implement delete')}
				/> */}
      </Box>
    </Flex>
  );
};

export default FinalBeneficiaryPreview;
