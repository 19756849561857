import { Modal } from '@agrotoken-tech/ui';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Column } from 'react-table';
import { Mailto } from '@comp/Global/Mailto';
import { Toast } from '@comp/Global/Toast';
import { deleteBusinessInvite } from '@services/BusinessInvites';
import { USERS_PATH } from '../../common/const';
import {
  EBusinessInviteStatus,
  EBusinessInviteStatusTranslated,
  ECreatorStatus,
  EInvitationStatus,
  EPermissionCategory,
  EPermissionCategoryColors,
  EPermissionCategoryId,
  EPermissionCategoryTranslated,
  IBusinessUser,
  IPermission,
} from '../../common/types';
import { useUser } from '../../context/userContext';
import { getBusinessUsers } from '../../services/BusinessUsers';
import { Badge } from '../Global/Badge';
import { Container } from '../Global/Container';
import { Loader } from '../Global/Loader';
import { ServerError } from '../Global/ServerError';
import { Tooltip } from '../Global/Tooltip';
import { Table } from './Table';

interface propState {
  showInvitationToast: boolean;
  showEditionToast: boolean;
  showRequestModalSent: boolean;
}

export const Users = () => {
  const { t } = useTranslation();
  const { selectedBusiness, selectedBusinessId, currentUser } = useUser();

  const {
    isLoading,
    data: businessUsers,
    error: getBusinessUsersError,
    refetch,
  } = useQuery<IBusinessUser[] | undefined>(
    ['businessUsers', selectedBusinessId],
    getBusinessUsers
  );

  const {
    isOpen: isOpenDeleteModal,
    onClose: onCloseDeleteModal,
    onOpen: onOpenDeleteModal,
  } = useDisclosure();

  const [shouldShowToastInvitationSent, setShouldShowToastInvitationSent] =
    useState<boolean>(false);
  const [shouldShowEditionToast, setShouldShowEditionToast] =
    useState<boolean>(false);
  const [showToastDeleteUser, setShowToastDeleteUser] =
    useState<boolean>(false);
  const [selectedBusinessUser, setSelectedBusinessUser] = useState<
    IBusinessUser | undefined
  >(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as propState;
  const showInvitationToast = state ? state.showInvitationToast : false;
  const showEditionToast = state ? state.showEditionToast : false;

  const handleNewUserInvite = () => {
    navigate(`${USERS_PATH}/newUserInvite`);
  };

  const { mutate: mutateDeleteBusinessInvite, isLoading: isLoadingDelete } =
    useMutation(deleteBusinessInvite, {
      onSuccess: () => {
        onCloseDeleteModal();
        setSelectedBusinessUser(undefined);
        refetch();
        setShowToastDeleteUser(true);
      },
      onError: (_err: Error) => {
        onCloseDeleteModal();
        setSelectedBusinessUser(undefined);
      },
    });

  useEffect(() => {
    if (showInvitationToast) {
      setShouldShowToastInvitationSent(true);
      setTimeout(() => setShouldShowToastInvitationSent(false), 5000);
      window.history.replaceState({}, '');
    }
  }, [showInvitationToast]);

  useEffect(() => {
    if (showEditionToast) {
      setShouldShowEditionToast(true);
      setTimeout(() => setShouldShowEditionToast(false), 5000);
      window.history.replaceState({}, '');
    }
  }, [showEditionToast]);

  const openDeleteModal = (user: IBusinessUser) => {
    setSelectedBusinessUser(user);
    onOpenDeleteModal();
  };

  const handleDelete = () => {
    if (!selectedBusinessUser) return;

    const selectedInvitation = selectedBusinessUser.businessUserInvites.find(
      (bi) => bi.businessUserId === selectedBusinessUser.id
    );

    // Check that all invitations have an uuid value in database
    if (!selectedInvitation?.uuid) {
      // TODO: Review the invitations that don't have an uuid value in database, what to do with them
      console.error(
        `Invitation with id ${selectedInvitation?.id} not deleted because it doesn't have an uuid value in database.`
      );
      onCloseDeleteModal();
      return;
    }
    mutateDeleteBusinessInvite(selectedInvitation.uuid);
    onCloseDeleteModal();
  };

  const handleEditUser = (id: number) => {
    if (businessUsers) {
      navigate(`${USERS_PATH}/${id}`);
    } else {
      return;
    }
  };

  const columns: Column<IBusinessUser>[] = [
    {
      Header: t('users.table.columns.name'),
      id: 'fullName',
      accessor: 'user',
      Cell: (props: any) => (
        <p className="text-sm text-gray-500 font-normal">
          {props.row.original.user.firstName
            ? `${props.row.original.user.firstName} ${props.row.original.user.lastName}`
            : t('tokenizations.table.pendingInformation')}
        </p>
      ),
    },
    {
      id: 'email',
      Header: t('users.table.columns.email'),
      accessor: 'user',
      Cell: (props: any) => (
        <p className="text-sm text-gray-500 font-normal">
          {props.row.original.user.email
            ? `${props.row.original.user.email}`
            : '-'}
        </p>
      ),
    },
    {
      Header: t('users.table.columns.status'),
      accessor: 'businessUserInvites',
      Cell: (props: any) => (
        <div>
          <p className="text-sm text-gray-500 font-normal">
            {props.row.original.isCreator === ECreatorStatus.IS_CREATOR
              ? EBusinessInviteStatusTranslated.CREATOR
              : null}
          </p>
          <p className="text-sm text-gray-500 font-normal">
            {props.row.original.isOnboardingValidated
              ? EBusinessInviteStatusTranslated.APPROVED
              : EBusinessInviteStatusTranslated[
                  props.row.original.businessUserInvites?.find(
                    (bi: { status: { keyCode: EBusinessInviteStatus } }) =>
                      bi.status.keyCode !== EBusinessInviteStatus.DENIED
                  )?.status
                    .keyCode as keyof typeof EBusinessInviteStatusTranslated
                ]}
          </p>
        </div>
      ),
    },
    {
      Header: t('users.table.columns.permissions'),
      accessor: 'permissions',
      Cell: (props: any) =>
        props.row.original.permissions.some(
          (elem: any) =>
            elem.permissionCategory.keyCode === EPermissionCategory.SUPER_ADMIN
        ) ? (
          <Badge
            className="basis-1/2"
            size="sm"
            color={EPermissionCategoryColors.SUPER_ADMIN}
            label={EPermissionCategoryTranslated.SUPER_ADMIN}
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            {Array.from(
              new Set(
                props.row.original.permissions.map(
                  (element: IPermission) => element.permissionCategory.keyCode
                )
              )
            ).map((keyCode: any, index: number) => {
              return (
                <Badge
                  key={index}
                  className="basis-1/2"
                  size="sm"
                  color={
                    EPermissionCategoryColors[
                      keyCode as keyof typeof EPermissionCategoryColors
                    ]
                  }
                  label={
                    EPermissionCategoryTranslated[
                      keyCode as keyof typeof EPermissionCategoryTranslated
                    ]
                  }
                />
              );
            })}
          </div>
        ),
    },
    {
      Header: t('tokenizations.table.columns.actions'),
      accessor: 'id',
      Cell: (props: any) => (
        <div className="flex justify-end">
          <div className="flex items-center">
            <div
              className={`group relative ${
                props.row.original.userId === currentUser?.id ||
                props.row.original.permissions.find(
                  (pm: { permissionCategoryId: EPermissionCategoryId }) =>
                    pm.permissionCategoryId ===
                      EPermissionCategoryId.ADMINISTRATOR &&
                    !currentUser?.businessUsers
                      .find((bu) => bu.businessId === selectedBusinessId)
                      ?.permissions.some(
                        (pm) =>
                          pm.permissionCategoryId ===
                          EPermissionCategoryId.SUPER_ADMIN
                      )
                )
                  ? 'hidden'
                  : 'inline'
              }`}
            >
              <Tooltip
                className={'hidden group-hover:inline-block bottom-12 right-0'}
                theme="dark"
                text="Editar"
                arrow="bottom-right"
              />
              <IconButton
                aria-label="Edit user"
                icon={<FeatherIcon icon="edit-2" size="20" />}
                variant="ghost"
                size="md"
                color="gray.500"
                onClick={() => handleEditUser(props.row.original?.id)}
              />
            </div>
            {
              <div
                className={`group relative ${
                  props.row.original.userId === currentUser?.id ||
                  props.row.original.permissions.find(
                    (pm: { permissionCategoryId: EPermissionCategoryId }) =>
                      pm.permissionCategoryId ===
                        EPermissionCategoryId.ADMINISTRATOR &&
                      currentUser?.businessUsers.find(
                        (bu) => bu.businessId === selectedBusinessId
                      )?.isCreator !== ECreatorStatus.IS_CREATOR
                  )
                    ? 'hidden'
                    : 'inline'
                }`}
              >
                <IconButton
                  aria-label="Delete user"
                  icon={<FeatherIcon icon="trash-2" size="20" />}
                  variant="ghost"
                  size="md"
                  color="gray.500"
                  onClick={() => openDeleteModal(props.row.original)}
                />
                <Tooltip
                  className={
                    'hidden group-hover:inline-block bottom-12 right-0'
                  }
                  theme="dark"
                  text="Eliminar"
                  arrow="bottom-right"
                />
              </div>
            }
          </div>
        </div>
      ),
    },
  ];

  if (isLoading) return <Loader />;

  if (getBusinessUsersError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(getBusinessUsersError as any)?.message}
      />
    );
  }

  return (
    <div className="min-h-screen">
      <Container>
        <h1 className="display-md text-gray-900 font-medium mb-2">
          {t('users.table.title')}
        </h1>
        <p className="text-lg text-gray-500 font-normal mb-12">
          {t('users.table.subtitle')}
        </p>
        <Table
          columns={columns}
          data={businessUsers ?? []}
          onClick={handleNewUserInvite}
        />
        <span className="text-sm text-gray-500 font-normal">
          {t('users.footNote.note')}
        </span>
        <Mailto
          email={`${t('users.footNote.mail')}`}
          subject={`${t('users.footNote.subject')}`}
          className="text-sm text-primary-500 font-normal"
        >
          {t('users.footNote.mail')}
        </Mailto>
        <span className="text-sm text-gray-500 font-normal">
          {t('users.footNote.note2')}
        </span>
      </Container>
      <Modal
        isOpen={isOpenDeleteModal}
        title={`${t('users.deleteModal.title')}`}
        text={`${t('users.deleteModal.text1')} ${
          selectedBusinessUser?.user.email
        } ${t('users.deleteModal.text2')} ${selectedBusiness?.name} ${t(
          'users.deleteModal.text3'
        )}`}
        labelBtnClose={t('users.deleteModal.labelBtnClose')}
        labelBtnConfirm={t('users.deleteModal.labelBtnConfirm')}
        icon={FiAlertCircle}
        iconVariant="destructive"
        onClickConfirm={handleDelete}
        onClose={onCloseDeleteModal}
        isLoadingConfirmBtn={isLoadingDelete}
      />

      <Toast
        className="fixed top-6 right-6 z-50"
        show={shouldShowToastInvitationSent}
        onClickClose={() => setShouldShowToastInvitationSent(false)}
        title={t('user.invitation.toast.titleInvitationSent')}
        text={t('user.invitation.toast.textInvitationSent')}
        featuredIcon={FiCheckCircle}
        variant="primary"
      />
      <Toast
        className="fixed top-6 right-6 z-50"
        show={shouldShowEditionToast}
        onClickClose={() => setShouldShowEditionToast(false)}
        title={t('user.edition.toast.titleEdition')}
        text={t('user.edition.toast.textEdition')}
        featuredIcon={FiCheckCircle}
        variant="primary"
      />

      <Toast
        className="fixed top-6 right-6 z-50"
        show={showToastDeleteUser}
        onClickClose={() => setShowToastDeleteUser(false)}
        title={
          selectedBusinessUser &&
          selectedBusinessUser.businessUserInvites.find(
            (item) => item.status.keyCode === EInvitationStatus.COMPLETED
          )
            ? t('users.toast.titleDeleteuser')
            : t('users.toast.titleDeleteInviteduser')
        }
        text={`${t('users.toast.text1DeleteUser')} 
				${t('users.toast.text2DeleteUser')} ${selectedBusiness?.name} ${t(
          'users.toast.text3DeleteUser'
        )}`}
        featuredIcon={FiCheckCircle}
        variant="primary"
      />
    </div>
  );
};
