import { Flex, Heading, Stack } from '@chakra-ui/react';
import { IDocumentPreviewForBusinessUser } from '@src/common/types';
import { useUser } from '@src/context/userContext';
import { Dispatch, SetStateAction, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import DocOfRepresentative from '../DocOfRepresentative/DocOfRepresentative';
import { IncompleteFieldError } from '../types';
import AddressFields from './AddressFields/AddressFields';
import { useAddressFieldsEffects } from './AddressFields/useAddressFieldsEffects';

interface Props {
  selectedBusinessAddressId?: number;
}

const CompanyFields: VFC<Props> = ({ selectedBusinessAddressId }) => {
  const {
    selectedBusinessUserWasInvited,
    selectedBusinessUserWasAdminInvited,
  } = useUser();
  const { t } = useTranslation();
  const {
    addressRegistration,
    cities,
    cityRegistration,
    defaultFiscalAddress,
    defaultPostalCode,
    geographicalDivisionRegistration,
    geographicalDivisions,
    isLoadingCities,
    isLoadingGeoDivisions,
    postalCodeRegistration,
  } = useAddressFieldsEffects({
    businessAddressId: selectedBusinessAddressId,
    businessUserIsInvited: selectedBusinessUserWasInvited,
    businessUserIsAdminInvited: selectedBusinessUserWasAdminInvited,
  });

  return (
    <Stack spacing="12" mt="4">
      {(!selectedBusinessUserWasInvited ||
        selectedBusinessUserWasAdminInvited) && (
        <Flex flexDir="column">
          <Heading fontSize="2xl" mb="1.5">
            {t('fullOnboarding.lvl_2.KYC.fiscalAddress.title')}
          </Heading>
          <AddressFields
            addressRegistration={addressRegistration}
            cities={cities}
            cityRegistration={cityRegistration}
            defaultFiscalAddress={defaultFiscalAddress}
            defaultPostalCode={defaultPostalCode}
            geographicalDivisionRegistration={geographicalDivisionRegistration}
            geographicalDivisions={geographicalDivisions}
            isLoadingCities={isLoadingCities}
            isLoadingGeoDivisions={isLoadingGeoDivisions}
            postalCodeRegistration={postalCodeRegistration}
          />
        </Flex>
      )}
    </Stack>
  );
};

export default CompanyFields;
