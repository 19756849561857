import { ENationalities } from '@agrotoken/common-utils';
import { WarrantyCard } from '@agrotoken/frontend-utils';
import { CoinsIcon } from '@agrotoken-tech/ui';
import { Box, Text, Flex, Button, Icon, IconButton } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import { FC, ReactNode, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import {
  FiCopy,
  FiFile,
  FiFileText,
  FiHash,
  FiInfo,
  FiMessageSquare,
  FiRepeat,
  FiUser,
} from 'react-icons/fi';
import Lottie from 'react-lottie';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { PaymentRequestAsset, PaymentRequestType } from '@common/enum';
import {
  CryptoCurrencySymbol,
  ELocalCurrency,
  EUnits,
  IGrainContract,
  IPaymentRequest,
  Origin,
  PAYMENT_REQUEST_TYPES,
} from '@common/types';
import {
  calculateWarrantyGCNetPricePerSaca,
  formatNumber,
} from '@common/utils';
import { FullScreenLoader } from '@comp/FullScreenLoader';
import { Icon as GlobalIcon } from '@comp/Global/Icon';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { Title } from '@comp/Global/Title';
import {
  ItemIcon,
  PaymentLayout,
  TokensAmount,
} from '@comp/PaymentRequestView/Fragments';
import { PaymentCompleteSummary } from '@comp/PaymentRequestView/Fragments/PaymentCompleteSummary';
import PaymentRequestParameters from '@comp/PaymentRequestView/Fragments/PaymentRequestParameters/PaymentRequestParameters';
import { TokenAmount } from '@comp/PaymentRequestView/Fragments/TokenAmount';
import { FixedGrain } from '@features/cards/interfaces/fixedGrain';
import PaymentRequestFixedGrainCard from '@features/paymentRequest/FixedGrainSelector/PaymentRequestFixedGrainCard';
import {
  getDocumentUrl,
  getFixedGrainContractByPaymentRequestUuid,
  getFutureGrainContractsByPaymentRequestId,
  getPaymentRequest,
} from '@services/PaymentRequests';
import { useAuth } from '@src/context/authContext';
import animationData from '../../../../assets/lotties/134-celebration.json';
import { useRedirectUrl } from './useRedirectUrl';

export const PaymentSent: FC = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
  const { nationality } = useAuth();
  const { isRedirecting } = useRedirectUrl();

  const { isLoading: isLoadingPaymentRequest, data: paymentRequest } =
    useQuery<IPaymentRequest>(
      ['payments', uuid],
      () => getPaymentRequest(uuid ?? ''),
      { enabled: Boolean(uuid) }
    );

  const { data: grainContract } = useQuery(
    ['grainContract', paymentRequest?.id],
    async () =>
      paymentRequest && type === PaymentRequestType.FUTURE_WARRANTY
        ? await getFutureGrainContractsByPaymentRequestId(paymentRequest.id)
        : ({} as IGrainContract)
  );

  const { data: fixedGrainContract } = useQuery<FixedGrain | undefined>(
    ['fixedGrainContract', paymentRequest?.uuid],
    getFixedGrainContractByPaymentRequestUuid,
    {
      enabled:
        !!paymentRequest?.uuid &&
        paymentRequest.type === PaymentRequestType.FIXED,
    }
  );

  if (isLoadingPaymentRequest) {
    return <Loader />;
  }
  if (!paymentRequest || !paymentRequest.operation) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={t('serverError.text')}
      />
    );
  }
  const {
    id,
    description,
    document,
    toBusiness,
    fromBusinessUser,
    type,
    warrantyMetadata,
  } = paymentRequest;

  let localCurrency: ELocalCurrency;
  let units: EUnits;
  let tokensAmount: any;

  if (nationality === ENationalities.AR) {
    localCurrency = ELocalCurrency.ARS;
    units = EUnits.ARS;
  } else {
    localCurrency = ELocalCurrency.BRL;
    units = EUnits.BRL;
  }

  if (
    paymentRequest?.type !== PaymentRequestType.FUTURE_WARRANTY &&
    !warrantyMetadata?.warrantyGrainContractId &&
    paymentRequest.amount
  ) {
    tokensAmount = <TokensAmount amountFiat={paymentRequest.amount} />;
  } else {
    tokensAmount = t('payPaymentRequest.breakdown.warrantyId', {
      warrantyId: warrantyMetadata?.warrantyGrainContractId,
    });
  }
  const isPaymentInKind = type === PAYMENT_REQUEST_TYPES.IN_KIND;
  const paymentTypeTranslation = isPaymentInKind ? 'kind' : 'regular';

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleViewDocument = () => {
    const getUrl = async () => {
      setIsLoadingUrl(true);
      try {
        const url = await getDocumentUrl(id, document.uuid);
        window.open(url, '_blank');
      } catch (error) {
        /**
         * TODO: handle error
         */
      } finally {
        setIsLoadingUrl(false);
      }
    };
    getUrl();
  };

  let asset: PaymentRequestAsset;
  switch (paymentRequest.type) {
    case PaymentRequestType.FUTURE_WARRANTY:
      asset = PaymentRequestAsset.WARRANTY;
      break;
    case PaymentRequestType.FIXED:
      asset = PaymentRequestAsset.FIXED_GRAIN;
      break;
    case PaymentRequestType.IN_KIND:
    case PaymentRequestType.STANDARD:
    default:
      asset = PaymentRequestAsset.TOKENS;
      break;
  }

  const warrantyPricePerSaca = paymentRequest.warrantyMetadata?.pricePerSaca
    ? new Decimal(paymentRequest.warrantyMetadata?.pricePerSaca)
    : calculateWarrantyGCNetPricePerSaca(
        paymentRequest.warrantyMetadata?.warrantyGrainContract
      );

  const getGeneralDataElements = (): ReactNode[] => {
    const generalDataElements: ReactNode[] = [];

    generalDataElements.push(
      <ItemIcon
        icon={FiHash}
        label={t('payPaymentRequest.breakdown.transactionNumber')}
        value={
          <>
            {paymentRequest.uuid}
            <CopyToClipboard text={paymentRequest.uuid}>
              <IconButton variant="link" aria-label="copy" icon={<FiCopy />} />
            </CopyToClipboard>
          </>
        }
        className="mb-4"
      />
    );

    generalDataElements.push(
      <ItemIcon
        icon={FiUser}
        label={t(
          `payPaymentRequest.breakdown.to.${
            paymentRequest.operation ? 'paymentMake' : 'paymentSent'
          }`
        )}
        value={`${toBusiness.name} - ${toBusiness.fiscalId}`}
        className="mb-4"
      />
    );

    warrantyMetadata &&
      generalDataElements.push(
        <ItemIcon
          icon={FiFileText}
          label={t('payPaymentRequest.breakdown.warrantyLabel')}
          value={t('payPaymentRequest.breakdown.warranty', {
            grainContractFutureId:
              warrantyMetadata?.warrantyGrainContract.requestNumber,
          })}
          className="mb-4"
        />
      );

    generalDataElements.push(
      <ItemIcon
        icon={FiMessageSquare}
        label={<Text> {t('payPaymentRequest.breakdown.comments')}</Text>}
        value={<Box>{description ?? ''}</Box>}
        className="mb-4"
        isBold={false}
      />
    );

    generalDataElements.push(
      <ItemIcon
        icon={FiFile}
        label={t('payPaymentRequest.breakdown.file')}
        value={
          document ? (
            <Flex className="mt-2">
              <GlobalIcon
                size="sm"
                color="gray"
                theme="no-background"
                icon="download"
              />
              <Button
                size="sm"
                variant="linkPrimary"
                onClick={handleViewDocument}
                isLoading={isLoadingUrl}
              >
                <Text fontWeight={'bold'} color="#288703">
                  {document.originalName}
                </Text>
              </Button>
            </Flex>
          ) : null
        }
        className="mb-4"
      />
    );

    return generalDataElements;
  };

  const getPaymentDataElements = (): ReactNode[] => {
    const paymentDataElements: ReactNode[] = [];

    if (paymentRequest.type !== PaymentRequestType.FUTURE_WARRANTY) {
      paymentDataElements.push(
        <Box marginLeft="32px">
          <ItemIcon
            label={t('payPaymentRequest.breakdown.amountSent')}
            className="mb-4"
          />
        </Box>
      );
      paymentRequest?.operation &&
        paymentDataElements.push(
          <Box marginLeft="32px">
            <ItemIcon
              icon={FiUser}
              label={`${fromBusinessUser.business.name} - ${fromBusinessUser.business.fiscalId}`}
              value={
                paymentRequest.type !== PaymentRequestType.FIXED && (
                  <TokenAmount
                    amount={paymentRequest.operation.amount}
                    token={
                      paymentRequest.operation?.currency
                        .name as CryptoCurrencySymbol
                    }
                  />
                )
              }
              className="mb-4"
              isBold={false}
            />
          </Box>
        );

      paymentDataElements.push(
        <Box marginLeft="32px">
          <ItemIcon
            icon={CoinsIcon as IconType}
            label={t('payPaymentRequest.breakdown.localEquivalence')}
            value={formatNumber(paymentRequest.amount, units, true)}
            className="mb-4"
          />
        </Box>
      );
    } else if (paymentRequest.type === PaymentRequestType.FUTURE_WARRANTY) {
      paymentDataElements.push(
        <ItemIcon
          icon={FiRepeat}
          label={t('payPaymentRequest.breakdown.subtotal', {
            localCurrency,
          })}
          value={formatNumber(paymentRequest.amount, units, true)}
          className="mb-4"
        />
      );
    }

    return paymentDataElements;
  };

  return (
    <>
      <FullScreenLoader
        isLoading={isRedirecting}
        message={t('payPaymentRequest.isRedirecting')}
      />
      <PaymentLayout
        header={
          <div className="flex">
            <div className="inline-block mr-2">
              <Lottie
                options={lottieOptions}
                height={42}
                width={42}
                isClickToPauseDisabled
              />
            </div>
            <Title title={t(`paymentSent.title.${paymentTypeTranslation}`)} />
          </div>
        }
        main={
          <Box>
            <PaymentRequestParameters
              createAtBoundOperation={Number(
                paymentRequest.operation.createdAt
              )}
              paymentRequestStatus={paymentRequest.status}
              createdAtPaymentRequest={Number(paymentRequest.createdAt)}
              generalDataElements={getGeneralDataElements()}
              paymentDataElements={getPaymentDataElements()}
            />
            {type === PaymentRequestType.FUTURE_WARRANTY && (
              <>
                <div
                  className="mb-4 mt-4"
                  style={{
                    fontFamily: 'Larsseit',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    lineHeight: '30px',
                  }}
                >
                  Garantia com que o pagamento foi feito:
                </div>

                {grainContract === undefined ? (
                  <Loader />
                ) : (
                  <WarrantyCard
                    key={grainContract.requestNumber}
                    currencyName={
                      grainContract.currency.name as 'SOYB' | 'CORB'
                    }
                    requestNumber={grainContract.requestNumber!}
                    amountInSacas={grainContract.conversions.amountConversion}
                    initialAmountInSacas={
                      grainContract.conversions.initialConversion
                    }
                    farmCity={grainContract.farm?.city?.name ?? ''}
                    farmGeographicalDivision={
                      grainContract.farm?.city?.geographicalDivision.name ?? ''
                    }
                    expirationDate={grainContract.expirationDate}
                    healthControl={Number(grainContract.healthControl)}
                    status={'COMPLETED'}
                    origin={Origin.TOKENIZATION}
                    equivalencesAmountBrl={
                      grainContract.equivalences.amount[EUnits.BRL]
                    }
                  />
                )}
              </>
            )}
            {fixedGrainContract && (
              <Flex direction="column" gap="4" mt="4">
                <Text size="xl" variant="medium">
                  {t('payPaymentRequest.selectedAsset')}
                </Text>
                <PaymentRequestFixedGrainCard
                  showRadioButton={false}
                  grainContract={fixedGrainContract}
                ></PaymentRequestFixedGrainCard>
              </Flex>
            )}
          </Box>
        }
        rightSide={
          <>
            <PaymentCompleteSummary
              status={asset === undefined ? 'EMPTY' : 'READY'}
              paymentRequest={paymentRequest}
              paymentAsset={asset ?? PaymentRequestAsset.TOKENS}
              isPayer={true}
              selectedToken={
                asset === PaymentRequestAsset.TOKENS
                  ? (paymentRequest.operation?.currency
                      .name as CryptoCurrencySymbol)
                  : undefined
              }
              warrantyPricePerSaca={warrantyPricePerSaca}
              fixedFinalPrice={
                paymentRequest.fixedGrainContractMetadata?.fixedGrainContract
                  .agreementDoc.finalPrice
              }
            />
            {type === PaymentRequestType.FUTURE_WARRANTY && (
              <>
                <Icon boxSize="20px" color="#667085" mt="3px">
                  <FiInfo />
                </Icon>
                {t('payment.summary.warranty.payerSuccessInfo')}
              </>
            )}
          </>
        }
      />
    </>
  );
};
