import { Text, Box } from '@chakra-ui/react';
import {
  createContext,
  ReactChild,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { BackButton } from './BackButton';
import { CloseButton } from './CloseButton';

export * from './use-step-form';
export * from './TitleFormComponent';
export * from './StepTitle';

interface StepFormProps<T> {
  children: ReactChild[];
  onSubmit: () => void;
  onClose: () => void;
  formValues: T;
  setFormValues: Dispatch<SetStateAction<T>>;
  withCloseButton?: boolean;
}

export interface StepFormContextValue {
  setTitle: (title: string) => void;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  handleClose: () => void;
  formValues: FormValues;
  setFormValues: Dispatch<SetStateAction<FormValues>>;
}

export interface FormValues {
  [key: string]: unknown;
}

const defaultContextValue = {
  setTitle: () => null,
  handleNextStep: () => null,
  handlePreviousStep: () => null,
  handleClose: () => null,
  formValues: {},
  setFormValues: () => null,
};

export const StepFormContext =
  createContext<StepFormContextValue>(defaultContextValue);

/**
 * StepForm
 * 
 * Generic component to represent any form that has multiple steps.
 * It takes a generic value T which should be an object representation of the form state.
 * Internally, it uses the `useStepForm` hook to access state and context helpers.
 * 
 * To use this, set it up as:
 * 
 * ```
<StepForm {...props}>
<Step1 />
<Step2 />
<Step3 />
...
<StepN />
</StepForm>
 * ```

 * Where the steps must be passed in order of appearance, and can access form context via the provided `useStepForm` hook.
 *	
 * @param {StepFormProps<T>} props
 * @returns {FC}
 */
const StepForm = <T extends FormValues>(props: StepFormProps<T>) => {
  const {
    children,
    onClose,
    formValues,
    setFormValues,
    withCloseButton = true,
  } = props;

  // `children` are the step components, in order
  const totalSteps = children.length;
  const [step, setStep] = useState<number>(0);
  const [title, setTitle] = useState<string>('');

  const handleNextStep = (): void => {
    if (step === totalSteps - 1) {
      onClose();
    }
    setStep((prev) => prev + 1);
  };

  const handlePreviousStep = (): void => {
    setStep((prev) => prev - 1);
  };

  const currentStep = children[step];

  return (
    <StepFormContext.Provider
      value={{
        setTitle,
        handleNextStep,
        handlePreviousStep,
        formValues,
        handleClose: onClose,
        setFormValues: setFormValues as Dispatch<SetStateAction<FormValues>>,
      }}
    >
      {/* TODO: Add header controler for display intead steps */}
      {step !== totalSteps - 1 && (
        <Box textAlign="center" position="relative">
          {step !== 0 && <BackButton />}
          <Text fontFamily="Larsseit-Medium" fontSize="xl">
            {title}
          </Text>
          {withCloseButton && <CloseButton />}
        </Box>
      )}
      {currentStep}
    </StepFormContext.Provider>
  );
};

export default StepForm;
