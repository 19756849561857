import { ChangeEvent, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useOutletContext } from 'react-router-dom';
import { Checkbox } from '@comp/Global/Checkbox/Checkbox';
import { Input } from '@comp/Global/Input';
import { Loader } from '@comp/Global/Loader';
import { Modal } from '@comp/Global/Modal';
import { ServerError } from '@comp/Global/ServerError';
import { useAccess } from '@src/context/accessContext';
import { ENABLED, HOME_PATH, USERS_PATH } from '../../../common/const';
import {
  BusinessInvitesResponse,
  EMerchantRequestStatusId,
  EPayloadStatus,
  EPermission,
  EPermissionCategoryId,
  EUserRole,
  IBusinessInvite,
  IMerchantRequest,
  IPermission,
  TBreadcrumbOption,
} from '../../../common/types';
import { useUser } from '../../../context/userContext';
import {
  createBusinessInvite,
  getBusinessInvites,
  resendBusinessUserInvite,
} from '../../../services/BusinessInvites';
import {
  getAllPermissions,
  getDisplayPermissions,
} from '../../../services/Permissions';
import { BeMerchantRequestModal } from '../../AccessControl/BeMerchantRequestModal';
import Breadcrumb from '../../Global/Breadcrumb';
import { Container } from '../../Global/Container';
import { Title } from '../../Global/Title';
import { getMerchantRequest } from '@services/MerchantRequests';
import { Button } from '@agrotoken-tech/ui';
import { FiUserCheck } from 'react-icons/fi';

export const Invitation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [invitationEmail, setInvitationEmail] = useState<string>('');
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [shouldShowCheckboxError, setShouldShowCheckbox] =
    useState<boolean>(false);
  const [isOpenRequestMerchantModal, setIsOpenRequestMerchantModal] =
    useState<boolean>(false);
  const [isOpenDeniedMerchantModal, setIsOpenDeniedMerchantModal] =
    useState<boolean>(false);
  const [userRole, setUserRole] = useState<string | undefined>(
    EUserRole.USER_WITH_PERMISSIONS
  );
  const [isOpenValidationModal, setIsOpenValidationModal] =
    useState<boolean>(false);
  const [mutateError, setMutateError] = useState<string>('');
  const [existingInvitationError, setExistingInvitationError] =
    useState<boolean>(false);
  const [invitationPermissions, setInvitationPermissions] = useState<
    IPermission[]
  >([]);

  const { isRepresentant, isTransactionable, isMerchant } = useAccess();

  const breadcrumbOptions: TBreadcrumbOption[] = [
    { label: t('user.invitation.breadCrumbOpt1'), to: USERS_PATH },
    { label: `${t('user.invitation.breadCrumbOpt2')}`, to: '' },
  ];

  const emailError = t('user.invitation.emailError');

  const { setOptions } = useOutletContext() as any;

  //DB Calls//

  const { selectedBusiness, selectedBusinessId, isLoadingUser, errorUser } =
    useUser();

  const {
    isLoading: isLoadingBusinessInvites,
    error: getBusinessInvitesError,
    data: businessInvites,
  } = useQuery<BusinessInvitesResponse[] | undefined>(
    ['businessInvites', selectedBusinessId],
    getBusinessInvites,
    { enabled: !!selectedBusinessId }
  );

  const { isLoading: isLoadingPermissions, data: displayPermissions } =
    useQuery<IPermission[] | undefined>(['permissions'], getDisplayPermissions);

  const {
    isLoading: isLoadingAllPermissions,
    error: getAllPermissionsError,
    data: allPermissions,
  } = useQuery<IPermission[] | undefined>(
    ['allPermissions'],
    getAllPermissions
  );

  const { data: merchantRequest } = useQuery<IMerchantRequest | undefined>(
    ['merchantRequest'],
    getMerchantRequest
  );

  useEffect(() => {
    if (displayPermissions) {
      const firstPermissionPreload = displayPermissions.find(
        (element) => element.keyCode === EPermission.VIEW_WALLET
      );
      setInvitationPermissions([firstPermissionPreload!]);
    }
  }, [displayPermissions]);

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  //OnChange handlers//

  const onChangeInvitationEmail = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInvitationEmail(ev.currentTarget.value);
  };

  const handleAdminOnchange = (ev: {
    currentTarget: { value: SetStateAction<string | undefined> };
  }) => {
    setUserRole(ev.currentTarget.value);
    if (ev.currentTarget.value && allPermissions) {
      const adminOnlyPermissions = allPermissions?.filter(
        (item) =>
          item.permissionCategoryId === EPermissionCategoryId.ADMINISTRATOR
      );

      const basicPermissionsGroups: number[] = [];

      allPermissions.forEach((item) => {
        if (item.preload === EPayloadStatus.ENABLED) {
          basicPermissionsGroups.push(item.permissionCategoryId);
        }
      });

      const basicPermissions = allPermissions?.filter((item) =>
        basicPermissionsGroups.includes(item.permissionCategoryId)
      );
      setInvitationPermissions([...adminOnlyPermissions, ...basicPermissions]);
    }
  };

  const handleUserWithPermissionsOnChange = (ev: {
    currentTarget: { value: SetStateAction<string | undefined> };
  }) => {
    setUserRole(ev.currentTarget.value);
    setInvitationPermissions(
      invitationPermissions.filter(
        (elem) => elem.keyCode === EPermission.VIEW_WALLET
      )
    );
  };

  const handlePermissionOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    permission: IPermission
  ) => {
    if (e.target.checked) {
      const filteredPermissions = allPermissions?.filter(
        (item) => item.permissionCategoryId === permission.permissionCategoryId
      );
      setInvitationPermissions((prevState) => [
        ...prevState,
        ...filteredPermissions!,
      ]);
      return;
    }
    setInvitationPermissions((prevState) =>
      prevState.filter(
        (item) => permission.permissionCategoryId !== item.permissionCategoryId
      )
    );
  };

  const {
    mutate: mutateCreateBusinessInvite,
    isLoading: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation(createBusinessInvite, {
    onSuccess: () => {
      setMutateError('');
      navigate(`${USERS_PATH}`, { state: { showInvitationToast: true } });
    },
    onError: (err: Error) => {
      setMutateError(err.message);
    },
  });

  const {
    mutate: mutateResendBusinessUserInvite,
    isLoading: isLoadingResendMutate,
  } = useMutation(resendBusinessUserInvite, {
    onSuccess: () => {
      setMutateError('');
      navigate(`${USERS_PATH}`, { state: { showInvitationToast: true } });
    },
    onError: (err: Error) => {
      setMutateError(err.message);
    },
  });

  const handleSubmitCreateBusinessInvite = async (ev: any) => {
    ev.preventDefault(ev);
    setShouldShowError(true);
    const isEmailInvitationEmail = businessInvites?.some(
      (item) =>
        item.businessUser.user.email === invitationEmail &&
        item.businessUser.isActive === ENABLED
    );
    if (isEmailInvitationEmail) {
      setExistingInvitationError(true);
    }
    if (
      !businessInvites ||
      businessInvites.length === 0 ||
      !businessInvites.some(
        (item) => item.businessUser.user.email === invitationEmail
      )
    ) {
      if (
        isValidEmail(invitationEmail) &&
        (userRole !== null || undefined) &&
        selectedBusiness?.id
      ) {
        await mutateCreateBusinessInvite({
          businessId: selectedBusiness.id,
          email: invitationEmail,
          permissions: invitationPermissions.map((item) => item.id),
        });
      }
    } else if (
      businessInvites.some(
        (item) =>
          item.businessUser.user.email === invitationEmail &&
          item.businessUser.isActive !== ENABLED
      )
    ) {
      if (isValidEmail(invitationEmail) && (userRole !== null || undefined)) {
        const userId = Number(
          businessInvites.find(
            (bi) => bi.businessUser.user.email === invitationEmail
          )?.businessUser.userId
        );
        const businessId = Number(
          businessInvites.find(
            (bi) => bi.businessUser.user.email === invitationEmail
          )?.businessUser.businessId
        );
        await mutateResendBusinessUserInvite({
          userId: userId,
          businessId: businessId,
          permissions: invitationPermissions.map((item) => item.id),
        });
      }
    }
  };

  const handleCancel = () => {
    navigate(`${USERS_PATH}`);
  };

  const merchantRequestPending =
    merchantRequest &&
    (merchantRequest.merchantRequestStatusId ===
      EMerchantRequestStatusId.PENDING ||
      merchantRequest.merchantRequestStatusId ===
        EMerchantRequestStatusId.IN_PROGRESS) &&
    !isMerchant;

  const merchantRequestDenied =
    merchantRequest &&
    merchantRequest.merchantRequestStatusId ===
      EMerchantRequestStatusId.DENIED &&
    !isMerchant;

  if (
    isLoadingAllPermissions ||
    isLoadingPermissions ||
    isLoadingUser ||
    isLoadingBusinessInvites
  )
    return <Loader />;

  if (getAllPermissionsError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(getAllPermissionsError as any)?.message}
      />
    );
  }

  if (errorUser) {
    return (
      <ServerError title={t('serverError.title')} text={errorUser?.message} />
    );
  }

  if (getBusinessInvitesError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(getBusinessInvitesError as any)?.message}
      />
    );
  }

  return (
    <Container className="mb-28">
      <div className="flex justify-between items-center pt-4 pb-12">
        <Breadcrumb options={breadcrumbOptions} />
      </div>
      <div className="flex gap-x-12">
        <div className="">
          <Title
            title={t('user.invitation.title')}
            subtitle={`${t('user.invitation.text')} ${selectedBusiness?.name}`}
          />
          <div className="w-1/2">
            <p className="text-xl font-medium text-gray-900 mb-4 mt-8">
              {t('user.invitation.subtitle')}
            </p>
            <form
              id="invitationEmail"
              onSubmit={handleSubmitCreateBusinessInvite}
            >
              <Input
                className="mb-8"
                name="invitationEmail"
                label={t('user.invitation.mailLabel')}
                placeholder={t('user.invitation.mailPlaceholder')}
                fullWidth
                value={invitationEmail}
                type="text"
                onChange={onChangeInvitationEmail}
                isValid={isValidEmail(invitationEmail)}
                errorMessage={
                  !isValidEmail(invitationEmail) && invitationEmail
                    ? emailError
                    : t('user.invitation.emptyEmailError')
                }
                showError={shouldShowError}
              />
            </form>
          </div>
          <hr />
          <div className="mt-8">
            <p className="text-xl font-medium text-gray-900 mb-4 mt-8">
              {t('user.invitation.section1Title')}
            </p>
            <div className="flex justify-start gap-x-4">
              <div>
                <Checkbox
                  id="administrator"
                  onChange={(ev) => handleAdminOnchange(ev)}
                  checked={userRole === EUserRole.ADMINISTRATOR}
                  value={EUserRole.ADMINISTRATOR}
                  name="administrator"
                  type="radio"
                  required
                  label={t('user.invitation.check1')}
                  className="mb-4"
                  hintMessage={t('user.invitation.check1text')}
                  showError={shouldShowCheckboxError}
                />
                <Checkbox
                  id="userWithPermissions"
                  onChange={handleUserWithPermissionsOnChange}
                  checked={userRole === EUserRole.USER_WITH_PERMISSIONS}
                  value={EUserRole.USER_WITH_PERMISSIONS}
                  name="userWithPermissions"
                  type="radio"
                  required
                  label={t('user.invitation.check2')}
                  showError={shouldShowCheckboxError}
                  className={shouldShowCheckboxError ? 'mb-2' : 'mb-8'}
                  hintMessage={t('user.invitation.check2text')}
                />
                <p
                  className={
                    shouldShowCheckboxError
                      ? 'pl-6 mb-8 text-error-600 font-normal text-sm'
                      : 'hidden'
                  }
                >
                  {t('user.invitation.checkboxError')}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-8 mb-4">
            <p className="text-xl font-medium text-gray-900 mb-4 mt-8">
              {t('user.invitation.section2Title')}
            </p>
            <div className="">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
                {displayPermissions?.map((permission) => {
                  return (
                    <div
                      key={permission.id}
                      className={`${
                        EPermission.VIEW_WALLET === permission.keyCode ||
                        userRole === EUserRole.ADMINISTRATOR
                          ? 'bg-gray-50 '
                          : 'bg-white'
                      } border rounded border-gray-200 px-4 pt-4 pb-6`}
                    >
                      <Checkbox
                        id={permission.keyCode}
                        onChange={(e) =>
                          handlePermissionOnChange(e, permission)
                        }
                        checked={invitationPermissions?.some(
                          (item) =>
                            item.permissionCategoryId ===
                            permission.permissionCategoryId
                        )}
                        name={permission.keyCode}
                        disabled={
                          (permission.keyCode ===
                            EPermission.CREATE_PAYMENT_LINK &&
                            !isMerchant) ||
                          EPermission.VIEW_WALLET === permission.keyCode ||
                          userRole === EUserRole.ADMINISTRATOR
                            ? true
                            : false
                        }
                        type="checkbox"
                        required
                        label={t(`user.permission.${permission.keyCode}.label`)}
                        className="mb-4"
                      />

                      <p className="text-sm text-gray-500 mb-4">
                        {t(`user.permission.${permission.keyCode}.description`)}
                      </p>
                      {!isMerchant &&
                        isRepresentant &&
                        isTransactionable &&
                        permission.keyCode ===
                          EPermission.CREATE_PAYMENT_LINK && (
                          <>
                            <p className="text-sm font-normal text-gray-700 mb-4">
                              {t('user.invitation.recieveTokensFootnote')}
                            </p>
                            <Button
                              type="button"
                              variant="outline"
                              onClick={
                                merchantRequestPending
                                  ? () => setIsOpenValidationModal(true)
                                  : merchantRequestDenied
                                  ? () => setIsOpenDeniedMerchantModal(true)
                                  : () => setIsOpenRequestMerchantModal(true)
                              }
                            >
                              {!merchantRequest
                                ? t('user.invitation.recieveTokensBtnLabel')
                                : t(
                                    'user.invitation.recieveTokensBtnLabelDone'
                                  )}
                            </Button>
                          </>
                        )}
                      {/* //TODO: DEPENDS ON ONBOARDING STAGE  */}
                      <p className="text-sm font-normal text-gray-700">
                        {EPermission.VIEW_WALLET === permission.keyCode
                          ? t('user.invitation.viewWalletFootnote')
                          : ''}
                      </p>
                    </div>
                  );
                })}
              </div>
              <hr />
              <div className="flex w-1/2 gap-x-4 mt-8">
                <Button
                  form="invitationEmail"
                  type="submit"
                  variant="primary"
                  isLoading={isLoadingMutate || isLoadingResendMutate}
                >
                  {t('user.invitation.createNewBtnLabel')}
                </Button>
                <Button
                  onClick={handleCancel}
                  form="invitationEmail"
                  type="button"
                  variant="outline"
                >
                  {t('user.invitation.cancelNewBtnLabel')}
                </Button>
              </div>
              {mutateError && (
                <p className="mt-1.5 text-sm text-error-500">{mutateError}</p>
              )}
              {existingInvitationError && (
                <p className="mt-1.5 text-sm text-error-500">
                  {t('user.invitation.existingInvitationError')}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenDeniedMerchantModal}
        title={t('verificationPending.titleMerchantRequestDenied')}
        text={t('verificationPending.textMerchantRequestDenied')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => setIsOpenDeniedMerchantModal(false)}
        cancelButtonHidden
      />

      <Modal
        isOpen={isOpenValidationModal}
        title={t('verificationPending.titleMerchantRequestVerificationPending')}
        text={t('verificationPending.textMerchantRequestVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => setIsOpenValidationModal(false)}
        cancelButtonHidden
      />

      <BeMerchantRequestModal
        setIsOpenRequestMerchantModal={setIsOpenRequestMerchantModal}
        isOpen={isOpenRequestMerchantModal}
        title={t('user.requestMerchantModal.title')}
        text={`${t('user.requestMerchantModal.text1')} ${
          selectedBusiness?.name
        } ${t('user.requestMerchantModal.text2')} `}
        subtitle={t('user.requestMerchantModal.subtitle')}
        labelBtnClose={t('user.requestMerchantModal.cancelBtnLabel')}
        labelBtnConfirm={t('user.requestMerchantModal.sendBtnLabel')}
        onClickClose={() => setIsOpenRequestMerchantModal(false)}
        center
      />
    </Container>
  );
};
