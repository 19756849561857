import FeatherIcon from 'feather-icons-react';
import { FC, HTMLAttributes, ReactChild } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from '../Spinner';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: ReactChild;
  size?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  variant?: string;
  disabled?: boolean;
  iconPosition?: 'leading' | 'trailing' | 'dot' | 'only';
  icon?: string;
  type?: 'button' | 'submit';
  className?: string;
  onClick?: () => void;
  href?: string;
  to?: string | { pathname: string; state: { [key: string]: any } };
  isLoading?: boolean;
  form?: string;
  inline?: boolean;
}

const variants: { [key: string]: string } = {
  primary:
    'bg-primary-600 text-white border border-primary-600 shadow-xs hover:bg-primary-700 hover:border-primary-700 focus:shadow-focus-primary disabled:bg-primary-200 disabled:border-primary-200',
  secondary:
    'bg-primary-50 text-primary-700 border-primary-50 shadow-xs hover:bg-primary-100 hover:border-primary-100 focus:shadow-focus-primary disabled:bg-primary-25 disabled:border-primary-25 disabled:text-primary-300',
  'secondary-gray':
    'bg-white text-gray-700 border border border-gray-300 shadow-xs hover:bg-gray-50 hover:border-gray-300 focus:shadow-focus-secondary-gray disabled:bg-white disabled:border-gray-200 disabled:text-gray-300',
  'secondary-error':
    'bg-white text-error-700 border-error-300 shadow-xs hover:bg-error-50 hover:border-error-300 focus:shadow-focus-secondary-error disabled:bg-white disabled:border-error-200 disabled:text-error-300',
  'tertiary-error':
    'bg-error-600 text-white border-error-300 shadow-xs hover:bg-error-50 hover:border-error-300 focus:shadow-focus-secondary-error disabled:bg-error-50 disabled:border-error-25 disabled:text-white',
  tertiary:
    'bg-white text-primary-700 hover:bg-primary-50 disabled:text-gray-300 disabled:bg-white',
  'tertiary-gray':
    'bg-white text-gray-500 hover:bg-gray-50 disabled:text-gray-300 disabled:bg-white',
  error: 'rounded-full hover:bg-error-50  text-error-500',
  link: 'text-primary-700 hover:text-primary-800 disabled:text-primary-300',
  'link-warning':
    'text-warning-800 hover:text-warning-800 disabled:text-warning-300 ',
  'link-gray': 'text-gray-500 hover:text-gray-600 disabled:text-gray-300 ',
  'link-gray-secondary':
    'text-gray-500 hover:text-primary-600 hover:border-b-2 border-primary-600 hover:rounded-none hover:py-5 disabled:text-gray-300 ',
  'link-error': 'text-error-700 hover:text-error-800 disabled:text-gray-300 ',
};

const fontSizes: { [key: string]: string } = {
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-md',
  xl: 'text-md',
  '2xl': 'text-lg',
};

const paddings: { [key: string]: string } = {
  none: '',
  xs: 'px-3 py-1.5',
  sm: 'px-3.5 py-2',
  md: 'px-4 py-2.5',
  lg: 'px-4 py-2.5',
  xl: 'px-5 py-3',
  '2xl': 'px-7 py-4',
};

const paddingsOnly: { [key: string]: string } = {
  sm: 'p-2',
  md: 'p-2.5',
  lg: 'p-3',
  xl: 'p-3.5',
  '2xl': 'p-4',
};

/**
 * @deprecated Must use Chakra-ui Button instead. We're working on getting rid of this component.
 * @see https://chakra-ui.com/docs/form/button
 */
export const Button: FC<Props> = ({
  label,
  variant = '',
  size = 'md',
  disabled,
  icon = '',
  iconPosition = 'leading',
  type = 'button',
  className = '',
  onClick,
  href = '',
  to,
  isLoading,
  form,
  inline,
}) => {
  return href ? (
    <a
      href={href}
      className={`${variants[variant]} ${
        fontSizes[size]
      } inline-block rounded  ${
        !['link', 'link-gray', 'link-gray-secondary', 'link-warning'].includes(
          variant
        ) && iconPosition !== 'only'
          ? paddings[size]
          : ''
      } ${
        !['link', 'link-gray', 'link-gray-secondary', 'link-warning'].includes(
          variant
        ) && iconPosition === 'only'
          ? paddingsOnly[size]
          : ''
      } ${className}`}
    >
      <span
        className={`flex items-center justify-center ${
          icon && iconPosition === 'trailing' && 'flex-row-reverse'
        }`}
      >
        {icon !== null && iconPosition !== 'dot' && (
          <FeatherIcon
            icon={icon}
            className={`${size === '2xl' ? 'w-6 h-6' : 'w-5 h-5'} ${
              iconPosition === 'leading'
                ? 'mr-3'
                : iconPosition === 'trailing'
                ? 'ml-3'
                : ''
            }`}
          />
        )}
        {iconPosition !== 'only' && label}
      </span>
    </a>
  ) : to ? (
    <Link
      to={to}
      className={` ${variants[variant]} ${fontSizes[size]} rounded
				inline-block
			 ${
         !['link', 'link-gray', 'link-gray-secondary', 'link-warning'].includes(
           variant
         ) && iconPosition !== 'only'
           ? paddings[size]
           : ''
       } ${
        !['link', 'link-gray', 'link-gray-secondary', 'link-warning'].includes(
          variant
        ) && iconPosition === 'only'
          ? paddingsOnly[size]
          : ''
      } ${className}`}
    >
      <span
        className={`${inline ? 'inline' : 'flex items-center justify-center'} ${
          icon && iconPosition === 'trailing' && 'flex-row-reverse'
        }`}
      >
        {icon !== null && iconPosition !== 'dot' && (
          <FeatherIcon
            icon={icon}
            className={`${size === '2xl' ? 'w-6 h-6' : 'w-5 h-5'} ${
              iconPosition === 'leading'
                ? 'mr-3'
                : iconPosition === 'trailing'
                ? 'ml-3'
                : ''
            }`}
          />
        )}
        {iconPosition !== 'only' && label}
      </span>
    </Link>
  ) : (
    <button
      form={form}
      className={`${variants[variant]} ${fontSizes[size]} rounded ${
        inline ? 'inline' : 'flex items-center justify-center'
      }${icon && iconPosition === 'trailing' && 'flex-row-reverse'} ${
        !['link', 'link-gray', 'link-gray-secondary', 'link-warning'].includes(
          variant
        ) && iconPosition !== 'only'
          ? paddings[size]
          : ''
      } 
      ${
        !['link', 'link-gray', 'link-gray-secondary', 'link-warning'].includes(
          variant
        ) && iconPosition === 'only'
          ? paddingsOnly[size]
          : ''
      } ${className}`}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
    >
      {icon !== '' && iconPosition !== 'dot' && (
        <FeatherIcon
          icon={icon}
          className={`${
            size === '2xl' ? 'w-6 h-6' : size === 'xs' ? 'w-4 h-4' : 'w-5 h-5'
          } ${
            iconPosition === 'leading'
              ? 'mr-3'
              : iconPosition === 'trailing'
              ? 'ml-3'
              : ''
          }`}
        />
      )}
      {iconPosition !== 'only' && label}
      {isLoading && (
        <Spinner size="xs" className="ml-3" variant="line-simple" />
      )}
    </button>
  );
};
