import { AxiosError } from 'axios';

import { CreditsApiGenericError } from '@common/interfaces';
import { LoansErrorCode } from '../enums';

const errorHandler = (error: unknown): void => {
  // TODO: Handle error by error codes response
  if (error && typeof error === 'object') {
    const axiosError = error as AxiosError;

    const beneficiariesError = axiosError.response
      ?.data as CreditsApiGenericError;

    if ('errorCode' in beneficiariesError) {
      switch (beneficiariesError.errorCode) {
        case LoansErrorCode.NotEnoughBalance:
          throw new Error('loans.createNew.errors.notEnoughBalance');

        default:
          throw new Error('shared.errors.generic');
      }
    }
  }

  throw new Error('shared.errors.generic');
};

export default errorHandler;
