import { Divider, Heading, Text } from '@chakra-ui/react';
import {
  EDocumentTypes,
  IDocument,
  IDocumentForBusinessUser,
} from '@common/types';
import { useUser } from '@src/context/userContext';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MultipleFileUploader from '@comp/FullOnboarding/MultipleFileUploader';

interface Props {
  documents?: IDocument[] | IDocumentForBusinessUser[];
}
const RepresentativeDocs: FC<Props> = ({ documents }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.lvl_2.form.representativeDocs',
  });

  // TODO: Remove this dependency of (number | null)[]
  const [documentIds, setDocumentIds] = useState(
    (documents ?? []).map((d) => d.id)
  );

  const { selectedBusiness } = useUser();
  const businessName = selectedBusiness?.name || '';
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue('legalPowerFilesIds', documentIds);
    if (documentIds.length > 0) {
      clearErrors('legalPowerFilesIds');
    }
  }, [documentIds, setValue, clearErrors]);

  return (
    <>
      <Divider my="8" />
      <Text size="xl" variant="medium" mb="1.5">
        {t('title')}
      </Text>
      <Text size="sm" variant="regular">
        {t('description', { businessName })}
      </Text>
      <MultipleFileUploader
        description={t('label', { businessName })}
        documentTypeId={EDocumentTypes.LEGAL_POWER}
        documentIds={documentIds}
        setDocumentIds={setDocumentIds}
        defaultFiles={documents ?? []}
        isValid={!errors.legalPowerFilesIds}
      />
      <Divider my="8" />
    </>
  );
};

export default RepresentativeDocs;
