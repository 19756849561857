import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { Buffer } from 'buffer';

import {
  CryptoCurrencySymbol,
  IBusiness,
  IBusinessActivity,
  IFees,
} from '../common/types';
import { http } from '../http-common';

export const createNewBusiness = async ({
  userId,
  ...body
}: any): Promise<IBusiness | undefined> => {
  try {
    const resp = await http.post<IBusiness>(
      `/${PLATFORM_PREFIX}/business/${userId}/full-creation`,
      body
    );
    const dataResult: IBusiness | undefined = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const getBusinessById = async ({
  queryKey,
}: any): Promise<IBusiness> => {
  const [, id] = queryKey;
  // Handle the case where the id is not provided
  if (!id) throw new Error('Business id is required');

  return await http
    .get<IBusiness>(`/${PLATFORM_PREFIX}/business/${id}`)
    .then((resp: { data: any }) => {
      const business: IBusiness = resp.data;
      return business;
    });
};

export const getBusinessesByTypeId = ({
  queryKey,
}: any): Promise<IBusiness[] | undefined> => {
  const [, businessTypeId] = queryKey;
  return http
    .get<IBusiness[]>(`/business?businessTypeId=${businessTypeId}`)
    .then((resp) => {
      const dataResult: IBusiness[] | undefined = resp.data;
      return dataResult;
    });
};

export const getBusinessByFiscalId = async (
  fiscalId: string
): Promise<IBusiness> => {
  return await http
    .get<IBusiness>(`/${PLATFORM_PREFIX}/business/fiscalId/${fiscalId}`)
    .then((resp: { data: any }) => {
      const business: IBusiness = resp.data;
      return business;
    });
};

export const getTransactionableBusinessByFiscalId = async (
  fiscalId: string
): Promise<IBusiness> => {
  return await http
    .get<IBusiness>(
      `/${PLATFORM_PREFIX}/business/fiscalId/${fiscalId}?isTransactionable=1`
    )
    .then((resp: { data: any }) => {
      const business: IBusiness = resp.data;
      return business;
    });
};

export const updateBusiness = async (
  data: any
): Promise<IBusiness | undefined> => {
  const resp = await http.patch<IBusiness>(
    `/${PLATFORM_PREFIX}/business/${data.id}`,
    data.body
  );
  const dataResult: IBusiness = resp.data;
  return dataResult;
};

export const getBusinessFee = async (id: number): Promise<IFees> => {
  const resp = await http.get<IFees>(`/${PLATFORM_PREFIX}/business/fees/${id}`);
  const dataResult: IFees = resp.data;
  return dataResult;
};

export const getBusinessBalance = async ({
  queryKey,
}: any): Promise<Record<CryptoCurrencySymbol, number>> => {
  const [, businessId] = queryKey;
  return http
    .get<Record<CryptoCurrencySymbol, number>>(
      `/${PLATFORM_PREFIX}/business/${businessId}/balance`
    )
    .then((resp) => {
      const dataResult: Record<CryptoCurrencySymbol, number> = resp.data;
      return dataResult;
    });
};

export const getBusinessActivities = async (): Promise<IBusinessActivity[]> => {
  const response = await http.get<IBusinessActivity[]>(
    `/${PLATFORM_PREFIX}/business-activities`
  );
  return response.data;
};

export const getBusinessOnboardingLevel3Data = async (
  businessId: number
): Promise<Partial<IBusiness>> => {
  const response = await http.get(
    `/${PLATFORM_PREFIX}/business/${businessId}/onboarding-level-3`
  );
  return response.data;
};

export const getBusinesses = async (params: any): Promise<IBusiness[]> => {
  const result = await http.get<{ data: IBusiness[] }>(
    `/${PLATFORM_PREFIX}/business`,
    { params }
  );
  return result.data.data;
};

export const isFiscalIdAvailable = async (
  fiscalId: string,
  country: string
): Promise<boolean | undefined> => {
  if (!fiscalId) return;
  const obfuscatedFiscalId = Buffer.from(fiscalId).toString('base64');
  const resp = await http.get<boolean>(
    `/${PLATFORM_PREFIX}/business/check/${obfuscatedFiscalId}`,
    {
      headers: {
        'x-current-region': country,
      },
    }
  );
  return resp.data;
};
