import { Flex, Spinner, Text } from '@chakra-ui/react';

interface FullScreenLoaderProps {
  isLoading: boolean;
  message?: string;
}

/**
 * FullScreenLoader component displays a full-screen loader with optional message.
 *
 * @component
 *
 * @param {boolean} isLoading - Determines whether the loader should be displayed.
 * @param {string} [message] - Optional message to be displayed along with the loader.
 * @returns {JSX.Element|null} - The FullScreenLoader component.
 * @example
 * // Example usage:
 * <FullScreenLoader isLoading={true} message="Loading..." />
 */
export const FullScreenLoader = ({
  isLoading,
  message,
}: FullScreenLoaderProps) => {
  if (!isLoading) return null;

  return (
    <Flex
      position="fixed"
      inset="0"
      bgColor="white"
      zIndex="popover"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="6"
    >
      <Spinner size="lg" variant="secondary" />
      {message ? <Text size="md">{message}</Text> : null}
    </Flex>
  );
};
