import { Modal } from '@comp/Global/Modal';
import { BREAKPOINTS, HOME_PATH } from '@src/common/const';
import { VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import CongratsModal from '../CongratsModal';
import { useTranslation } from 'react-i18next';

interface Props {
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

const FinalStepModal: VFC<Props> = ({ isModalOpen, handleCloseModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isMobile = window.innerWidth < BREAKPOINTS.md;

  if (isMobile) {
    return (
      <Modal
        isOpen={isModalOpen}
        onClickClose={handleCloseModal}
        title={t('onboardingLevelThree.successModal.title')}
        text={t('onboardingLevelThree.successModal.description')}
        labelBtnConfirm="Aceptar"
        onClickConfirm={() => {
          navigate(HOME_PATH);
        }}
      />
    );
  } else {
    return (
      <CongratsModal
        title={t('onboardingLevelThree.successModal.title')}
        description={t('onboardingLevelThree.successModal.description')}
        primaryActionLabel={t(
          'onboardingLevelThree.successModal.primaryActionLabel'
        )}
        primaryAction={() => {
          navigate(HOME_PATH);
        }}
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      />
    );
  }
};

export default FinalStepModal;
