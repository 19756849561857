import { useQuery } from 'react-query';

import { IMovement } from '@common/types';
import { getMovementsByGrainContract } from '@services/Movements';

export const useTokenizationMovements = (tokenizationUuid: string) => {
  const { isLoading, error, data } = useQuery<IMovement[] | undefined>(
    ['movements', 'tokenization', tokenizationUuid],
    () => getMovementsByGrainContract(tokenizationUuid)
  );

  return {
    isLoading,
    error,
    data,
  };
};
