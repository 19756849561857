import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { TRANSLATIONS_ES } from './es/translations';
import { TRANSLATIONS_PT } from './pt/translations';

export const languages = getMultiRegionMap({
  [ENationalities.AR]: 'es',
  [ENationalities.BR]: 'pt',
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: 'es',
    resources: {
      es: {
        translation: TRANSLATIONS_ES,
      },
      pt: {
        translation: TRANSLATIONS_PT,
      },
    },
  });

export default i18n;
