import { FC } from 'react';

interface ErrorMessageProps {
  className?: string;
}
export const ErrorMessage: FC<ErrorMessageProps> = ({
  className = '',
  children,
}) => {
  return (
    <div className={`text-sm font-normal text-error-500 ${className}`}>
      {children}
    </div>
  );
};
