export const BarChartIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_21981_363999)">
      <path
        d="M9.66667 27.9985H5C4.44 27.9985 4 27.5452 4 26.9985V16.9985C4 16.4385 4.44 15.9985 5 15.9985H9.66667C10.2133 15.9985 10.6667 16.4385 10.6667 16.9985V26.9985C10.6667 27.5452 10.2133 27.9985 9.66667 27.9985ZM6 25.9985H8.66667V17.9985H6V25.9985Z"
        fill="white"
      />
      <path
        d="M27.0002 27.9989H22.3335C21.7868 27.9989 21.3335 27.5455 21.3335 26.9989V11.6655C21.3335 11.1189 21.7868 10.6655 22.3335 10.6655H27.0002C27.5468 10.6655 28.0002 11.1189 28.0002 11.6655V26.9989C28.0002 27.5455 27.5468 27.9989 27.0002 27.9989ZM23.3335 25.9989H26.0002V12.6655H23.3335V25.9989Z"
        fill="white"
      />
      <path
        d="M18.3332 27.9987H13.6665C13.1198 27.9987 12.6665 27.5454 12.6665 26.9987V6.33203C12.6665 5.77203 13.1198 5.33203 13.6665 5.33203H18.3332C18.8798 5.33203 19.3332 5.78536 19.3332 6.33203V26.9987C19.3332 27.5454 18.8798 27.9987 18.3332 27.9987ZM14.6665 25.9987H17.3332V7.33203H14.6665V25.9987Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_21981_363999">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
