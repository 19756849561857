import { Flex, Spinner } from '@chakra-ui/react';
import { FULL_ONBOARDING_LEVEL_2, HOME_PATH, SELECT_ORG } from '@common/const';
import { UserTypes } from '@common/types';
import AcceptTokenizationsTermsAndCondsModal from '@comp/AcceptTokenizationsTermsAndCondsModal';
import Level2CompletedModal from '@comp/FullOnboarding/v2/lvl-2/Level2CompletedModal';
import { Modal } from '@comp/Global/Modal';
import { useNavigateSelectOrg } from '@hooks/useNavigateSelectOrg';
import { useAccess } from '@src/context/accessContext';
import { useFullOnboardingAccess } from '@src/context/fullOnboardingAccessContext';
import { useUser } from '@src/context/userContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const RequireTokenizationAccess: FC = ({ children }) => {
  const { isRepresentant, canTokenize, hasAcceptedTokenizationTermsAndConds } =
    useAccess();
  const {
    hasNotSentOnboardingLevel2_v2,
    hasOnboardingLevel2ChangesRequested_v2,
    hasOnboardingLevel2PendingVerification_v2,
    hasNotSentOnboardingLevel3_v2,
  } = useFullOnboardingAccess();
  const {
    selectedBusiness,
    selectedBusinessUser,
    currentUser,
    selectedBusinessUserWasAdminInvited,
    selectedBusinessId,
  } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClickNavigateHome = () => navigate(HOME_PATH);

  useNavigateSelectOrg();

  if (
    currentUser?.userType === UserTypes.MANAGER &&
    (!selectedBusiness || !selectedBusinessUser)
  ) {
    navigate(SELECT_ORG);
    return <></>;
  }

  if ((!selectedBusiness || !selectedBusinessUser) && selectedBusinessId)
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    );

  if (!canTokenize) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={onClickNavigateHome}
        onClickClose={onClickNavigateHome}
      />
    );
  }

  if (hasNotSentOnboardingLevel2_v2 || hasOnboardingLevel2ChangesRequested_v2) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_LEVEL_2)}
        onClickClose={onClickNavigateHome}
      />
    );
  }

  if (
    hasOnboardingLevel2PendingVerification_v2 &&
    hasNotSentOnboardingLevel3_v2 &&
    !selectedBusinessUserWasAdminInvited
  ) {
    return <Level2CompletedModal onClose={onClickNavigateHome} isOpen />;
  }

  if (!isRepresentant) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={onClickNavigateHome}
        cancelButtonHidden
      />
    );
  }
  if (!hasAcceptedTokenizationTermsAndConds) {
    return <AcceptTokenizationsTermsAndCondsModal isOpen={true} />;
  }

  return <>{children}</>;
};

export default RequireTokenizationAccess;
