import { Box, Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FeaturedIcon } from '../Icon/FeaturedIcon';
import { FileError } from './FileError';
import { FileSuccess } from './FileSuccess';

import {
  ACCEPTED_DOCUMENTS_MIME_TYPES,
  MAXIMUM_FILE_SIZE_IN_BYTES,
} from './constants';
import { useFileUpload, UseFileUploadParams } from './useFileUpload';
import { convertBytesToMb } from './utils';
import { DocumentFormat } from '@common/types';

export const FileUpload = ({
  acceptedFiles = ACCEPTED_DOCUMENTS_MIME_TYPES,
  maxFileSize = MAXIMUM_FILE_SIZE_IN_BYTES,
  documentTypeId,
  onSuccessUploadedFileHandler,
  onSuccessDeleteFileHandler,
  documentUuid,
}: UseFileUploadParams) => {
  const { t } = useTranslation();

  const {
    dropzone,
    error,
    file,
    isLoadingUpload,
    isLoadingDelete,
    onDeleteFileHandler,
  } = useFileUpload({
    documentTypeId,
    onSuccessUploadedFileHandler,
    acceptedFiles,
    maxFileSize,
    onSuccessDeleteFileHandler,
    documentUuid,
  });

  const { getRootProps, getInputProps, open } = dropzone;

  const maximumSizeInMegaBytes = convertBytesToMb(
    maxFileSize ?? 0
  ).toLocaleString('es-AR');

  if (file && !error) {
    return (
      <FileSuccess
        fileName={file?.name}
        fileSize={file?.size}
        onDeleteFileHandler={onDeleteFileHandler}
        isLoading={isLoadingUpload || isLoadingDelete}
      />
    );
  }

  if (error) {
    return <FileError error={error} fileName={file?.name} onClick={open} />;
  }

  return (
    <Flex
      borderRadius="base"
      p="4"
      cursor="default"
      border="1px"
      borderStyle="dashed"
      borderColor="gray.200"
      {...getRootProps({ role: 'input' })}
    >
      <input {...(getInputProps() as object)} />
      <div className="text-xl flex mr-2">
        <FeaturedIcon
          size="md"
          color="gray"
          theme="light-circle-outline"
          icon="upload-cloud"
        />
      </div>
      <Box className="flex flex-col">
        <div>
          <Button
            size="md"
            variant="linkPrimary"
            onClick={open}
            color="gray.500"
            fontWeight="medium"
            fontSize="small"
          >
            {t('fileUpload.click')}
          </Button>
          <span className="mt-1 text-sm text-gray-500 font-normal ml-1">
            {t('fileUpload.drag')}
          </span>
        </div>
        <p className="mt-1 text-xs text-gray-500 font-normal">
          {t('fileUpload.format', {
            format: DocumentFormat.PDF,
            maxSize: maximumSizeInMegaBytes,
          })}
        </p>
      </Box>
      <p className="mt-1 text-xs text-gray-500 font-normal"></p>
    </Flex>
  );
};
