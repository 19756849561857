import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { ICity, IGeographicalDivision } from '@common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getCitiesByGeographicalDivisionId } from '@services/City';
import { getGeographicalDivisions } from '@services/GeographicalDivision';
import { ISelectItemProps } from './interfaces';

const SelectItem: FC<ISelectItemProps> = ({
  errors,
  setGeographicalDivisionId,
  setCityByGeographicalDivisionId,
  geographicalDivisionId,
  register,
}) => {
  const { t } = useTranslation();
  const {
    data: geographicalDivisions,
    isLoading: isLoadingGeographicalDivision,
  } = useQuery<IGeographicalDivision[]>(
    ['geographicalDivisionId'],
    async () => {
      return getGeographicalDivisions();
    }
  );

  const { data: citiesByGeographicalDivisionId, isLoading: isLoadingCities } =
    useQuery<ICity[]>(
      ['geographicalDivisionId', geographicalDivisionId],
      async () => {
        if (geographicalDivisionId)
          return await getCitiesByGeographicalDivisionId(
            geographicalDivisionId
          );
      }
    );

  return (
    <>
      <FormControl
        marginBottom={4}
        maxW={{ base: '100%', xl: '295px' }}
        isInvalid={Boolean(errors['geographicalDivisionId'])}
      >
        <FormLabel htmlFor="geographicalDivision">
          {t('card.shipmentCard.form.geographicalDivision')}
        </FormLabel>
        <Select
          id="geographicalDivision"
          placeholder={t(
            'card.shipmentCard.form.geographicalDivisionPlaceHolder'
          )}
          {...(isLoadingGeographicalDivision
            ? {
                icon: <Spinner color="primary.200" />,
                disabled: true,
              }
            : {})}
          {...register('geographicalDivisionId', {
            valueAsNumber: true,
            required: `${t('general.form.requiredMsg')}`,
            onChange: (ev) =>
              setGeographicalDivisionId(+ev.currentTarget.value),
          })}
        >
          {geographicalDivisions?.map((geographicalDivision) => (
            <option
              key={geographicalDivision.id}
              value={geographicalDivision.id}
            >
              {geographicalDivision.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {errors['geographicalDivisionId'] &&
            errors['geographicalDivisionId']?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        marginBottom={4}
        maxW={{ base: '100%', xl: '295px' }}
        isInvalid={Boolean(errors['city'])}
      >
        <FormLabel htmlFor="city">{t('card.shipmentCard.form.city')}</FormLabel>
        <Select
          marginTop={1.5}
          id="city"
          placeholder={t('card.shipmentCard.form.cityPlaceHolder')}
          disabled={!citiesByGeographicalDivisionId}
          {...(isLoadingCities
            ? { icon: <Spinner color="primary.200" /> }
            : {})}
          {...register(`${'city'}`, {
            valueAsNumber: true,
            required: `${t('general.form.requiredMsg')}`,
            onChange: (ev) =>
              setCityByGeographicalDivisionId(+ev.currentTarget.value),
          })}
        >
          {citiesByGeographicalDivisionId?.map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {errors['city'] && errors['city'].message}
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

export default SelectItem;
