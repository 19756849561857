import {
  formatDate,
  formatNumber,
  TokenCurrency,
  WarrantyHealthControl,
  WARRANTY_HEALTH_CONTROL,
} from '@agrotoken/common-utils';
import { Badge } from '@agrotoken-tech/ui';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiActivity } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { WARRANTY_PATH } from '@common/const';
import { EUnits, Origin } from '@common/types';
import { BaseCard } from '@comp/BaseCard';

type ColorScheme = React.ComponentProps<typeof Badge>['colorScheme'];

const BADGE_PROPS: Record<
  WarrantyHealthControl,
  { label: string; colorScheme: ColorScheme }
> = {
  [WARRANTY_HEALTH_CONTROL.BAD]: {
    label: 'badge.bad',
    colorScheme: 'error',
  },
  [WARRANTY_HEALTH_CONTROL.EXCELLENT]: {
    label: 'badge.excellent',
    colorScheme: 'success',
  },
};

interface WarrantyCardProps {
  amountSacas: number;
  amountFiat: number;
  currency: TokenCurrency;
  warrantyId: number;
  requestNumber: number;
  healthControl: WarrantyHealthControl;
  origin: Origin;
  expirationDate: number;
}

/**
 * React component for displaying a warranty card.
 * @param {number} amountSacas - The amount of SACAS for the warranty.
 * @param {number} amountFiat - The amount in fiat currency for the warranty.
 * @param {TokenCurrency} currency - The currency of the warranty.
 * @param {number} warrantyId - The unique identifier for the warranty.
 * @param {number} requestNumber - The request number associated with the warranty.
 * @param {WarrantyHealthControl} healthControl - The health control status of the warranty.
 * @param {Origin} origin - The origin of the warranty.
 * @param {number} expirationDate - The expiration date of the warranty.
 */
const WarrantyCard = ({
  amountSacas,
  amountFiat,
  currency,
  requestNumber,
  warrantyId,
  healthControl,
  origin,
  expirationDate,
}: WarrantyCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: 'home.warrantyCard' });

  /**
   * Event handler for clicking the warranty card. Navigates to the warranty details page.
   */
  const onClickHandler = () =>
    navigate(`${WARRANTY_PATH}/${warrantyId}/${origin}`);

  /**
   * Object that maps warranty health control statuses to their respective subtitles.
   * @type {Object}
   */
  const SUBTITLE = {
    [WARRANTY_HEALTH_CONTROL.EXCELLENT]: t('subtitle.amount', {
      amountSacas: formatNumber(amountSacas, EUnits.SACAS),
    }),
    [WARRANTY_HEALTH_CONTROL.BAD]: t('subtitle.blockedSacas'),
  };

  const title = t(`title.${currency}`, { requestNumber });
  const formattedExpirationDate = formatDate(expirationDate, 'DD/MM/YYYY');
  const isExcellentHealthControl =
    healthControl === WARRANTY_HEALTH_CONTROL.EXCELLENT;

  return (
    <BaseCard
      title={title}
      subtitle={SUBTITLE[healthControl]}
      variant="white"
      topRightElement={
        <Badge
          size="sm"
          colorScheme={BADGE_PROPS[healthControl].colorScheme}
          icon={FiActivity}
        >
          {t(BADGE_PROPS[healthControl].label)}
        </Badge>
      }
      bottomElements={
        <Box>
          {isExcellentHealthControl && (
            <Text variant="medium" size="sm" color="content-secondary" mb="1">
              {formatNumber(amountFiat, 'BRL', true)}
            </Text>
          )}
          <Text variant="regular" size="sm" color="content-secondary">
            {t('expirationDate', { expirationDate: formattedExpirationDate })}
          </Text>
        </Box>
      }
      onClick={onClickHandler}
    />
  );
};

export default WarrantyCard;
