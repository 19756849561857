import FeatherIcon from 'feather-icons-react';
import { HTMLAttributes, FC } from 'react';
import { TColor, TIconTheme, TSize } from '../types';

const sizes = {
  xs: 'p-1.5',
  sm: 'p-2',
  md: 'p-2.5',
  lg: 'p-3',
  xl: 'p-3.5',
  custom: '',
};
const colors = {
  primary: {
    'light-circle': 'bg-primary-100 text-primary-600',
    'light-circle-only': 'text-primary-600',
    'light-circle-outline': 'bg-primary-100 text-primary-600',
    'dark-circle': 'bg-primary-600 text-white',
    'light-square': 'bg-primary-50 text-primary-600',
    'mid-square': 'bg-primary-500 text-white',
    'dark-square': 'bg-primary-800 text-white',
    'no-background': 'text-primary-600',
    navbar: 'text-primary-500',
  },
  gray: {
    'light-circle': 'bg-gray-100 text-gray-600',
    'light-circle-only': 'text-gray-600',
    'light-circle-outline': 'bg-gray-100 text-gray-600',
    'dark-circle': 'bg-gray-600 text-white',
    'light-square': 'bg-gray-100 text-gray-600',
    'mid-square': 'bg-gray-500 text-white',
    'dark-square': 'bg-gray-800 text-white',
    'no-background': 'text-gray-500',
    navbar: 'text-gray-500',
  },
  error: {
    'light-circle': 'bg-error-100 text-error-600',
    'light-circle-only': 'text-error-600',
    'light-circle-outline': 'bg-error-100 text-error-600',
    'dark-circle': 'bg-error-600 text-white',
    'light-square': 'bg-error-100 text-error-600',
    'mid-square': 'bg-error-500 text-white',
    'dark-square': 'bg-error-800 text-white',
    'no-background': 'text-error-600',
    navbar: 'text-error-500',
  },
  warning: {
    'light-circle': 'bg-warning-100 text-warning-600',
    'light-circle-only': 'text-warning-600',
    'light-circle-outline': 'bg-warning-100 text-warning-600',
    'dark-circle': 'bg-warning-600 text-white',
    'light-square': 'bg-warning-100 text-warning-600',
    'mid-square': 'bg-warning-500 text-white',
    'dark-square': 'bg-warning-800 text-white',
    'no-background': 'text-warning-800',
    navbar: 'text-warning-500',
  },
  success: {
    'light-circle': 'bg-success-100 text-success-600',
    'light-circle-only': 'text-success-600',
    'light-circle-outline': 'bg-success-100 text-success-600',
    'dark-circle': 'bg-success-600 text-white',
    'light-square': 'bg-success-50 text-success-600',
    'mid-square': 'bg-success-500 text-white',
    'dark-square': 'bg-success-800 text-white',
    'no-background': 'text-success-600',
    navbar: 'text-success-500',
  },
};

const borderWidth = {
  xs: {
    'light-circle': 'border-0 rounded-full',
    'light-circle-only': 'border-0 rounded-full',
    'light-circle-outline': 'border-2 rounded-full',
    'dark-circle': 'border-2 rounded-full',
    'light-square': 'border-0 rounded-[4px]',
    'mid-square': 'border-0 rounded-[4px]',
    'dark-square': 'border-0 rounded-[4px]',
    'no-background': '',
    navbar: '',
  },
  sm: {
    'light-circle': 'border-0 rounded-full',
    'light-circle-only': 'border-0 rounded-full',
    'light-circle-outline': 'border-4 rounded-full',
    'dark-circle': 'border-4 rounded-full',
    'light-square': 'border-0 rounded-[6px]',
    'mid-square': 'border-0 rounded-[6px]',
    'dark-square': 'border-0 rounded-[6px]',
    'no-background': '',
    navbar: '',
  },
  md: {
    'light-circle': 'border-0 rounded-full',
    'light-circle-only': 'border-0 rounded-full',
    'light-circle-outline': 'border-6 rounded-full',
    'dark-circle': 'border-6 rounded-full',
    'light-square': 'border-0 rounded-[8px]',
    'mid-square': 'border-0 rounded-[8px]',
    'dark-square': 'border-0 rounded-[8px]',
    'no-background': '',
    navbar: '',
  },
  lg: {
    'light-circle': 'border-0 rounded-full',
    'light-circle-only': 'border-0 rounded-full',
    'light-circle-outline': 'border-8 rounded-full',
    'dark-circle': 'border-8 rounded-full',
    'light-square': 'border-0 rounded-[10px]',
    'mid-square': 'border-0 rounded-[10px]',
    'dark-square': 'border-0 rounded-[10px]',
    'no-background': '',
    navbar: '',
  },
  xl: {
    'light-circle': 'border-0 rounded-full',
    'light-circle-only': 'border-0 rounded-full',
    'light-circle-outline': 'border-10 rounded-full',
    'dark-circle': 'border-10 rounded-full',
    'light-square': 'border-0 rounded-[12px]',
    'mid-square': 'border-0 rounded-[12px]',
    'dark-square': 'border-0 rounded-[12px]',
    'no-background': '',
    navbar: '',
  },
  custom: {
    'light-circle': 'border-0 rounded-full',
    'light-circle-only': 'border-0 rounded-full',
    'light-circle-outline': 'border-4 rounded-full',
    'dark-circle': 'border-4 rounded-full',
    'light-square': 'border-0 rounded-[6px]',
    'mid-square': 'border-0 rounded-[6px]',
    'dark-square': 'border-0 rounded-[6px]',
    'no-background': '',
    navbar: '',
  },
};

const borderColors = {
  primary: {
    'light-circle': '',
    'light-circle-only': '',
    'light-circle-outline': 'border-primary-50',
    'dark-circle': 'border-primary-700',
    'light-square': '',
    'mid-square': '',
    'dark-square': '',
    'no-background': '',
    navbar: '',
  },
  gray: {
    'light-circle': '',
    'light-circle-only': '',
    'light-circle-outline': 'border-gray-50',
    'dark-circle': 'border-gray-700',
    'light-square': '',
    'mid-square': '',
    'dark-square': '',
    'no-background': '',
    navbar: '',
  },
  error: {
    'light-circle': '',
    'light-circle-only': '',
    'light-circle-outline': 'border-error-50',
    'dark-circle': 'border-error-700',
    'light-square': '',
    'mid-square': '',
    'dark-square': '',
    'no-background': '',
    navbar: '',
  },
  warning: {
    'light-circle': '',
    'light-circle-only': '',
    'light-circle-outline': 'border-warning-50',
    'dark-circle': 'border-warning-700',
    'light-square': '',
    'mid-square': '',
    'dark-square': '',
    'no-background': '',
    navbar: '',
  },
  success: {
    'light-circle': '',
    'light-circle-only': '',
    'light-circle-outline': 'border-success-50',
    'dark-circle': 'border-success-700',
    'light-square': '',
    'mid-square': '',
    'dark-square': '',
    'no-background': '',
    navbar: '',
  },
};

const iconSizes = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 28,
  custom: 16,
};

export interface Props extends HTMLAttributes<HTMLDivElement> {
  size: TSize;
  color: TColor;
  theme: TIconTheme;
  icon: string;
  className?: string;
  padding?: string;
}

export const Icon: FC<Props> = ({
  size,
  color,
  theme,
  icon,
  className,
  padding,
}) => {
  return (
    <div className={`inline-block ${className}`}>
      <div
        className={`flex justify-center items-center ${
          padding ? padding : sizes[size]
        } ${colors[color][theme]} ${borderWidth[size][theme]} ${
          borderColors[color][theme]
        }`}
      >
        <FeatherIcon icon={icon} size={iconSizes[size]} />
      </div>
    </div>
  );
};
