import { AgrotokenLogo } from '@agrotoken-tech/ui';
import { Center, Box, Wrap, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface LoanAuthorizationCardProps {
  children: ReactNode;
  logo?: string;
}

const LoanAuthorizationCard = ({
  children,
  logo,
}: LoanAuthorizationCardProps) => (
  <Center mt={{ base: '12', sm: '24' }}>
    <Box
      shadow={{ base: 'none', sm: 'xl' }}
      p="6"
      borderRadius="md"
      bgColor="white"
      maxW="400px"
    >
      <Wrap mb="8" spacingX="10" spacingY="5" alignItems="center">
        {logo && <Image h="28px" src={logo} alt="Lender logo" />}
        <AgrotokenLogo variant="imagotype" />
      </Wrap>
      {children}
    </Box>
  </Center>
);

export default LoanAuthorizationCard;
