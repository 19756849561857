import { FormErrorMessage } from '@agrotoken-tech/ui';
import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle, FiCheck } from 'react-icons/fi';

import { EDocumentTypes, IBusiness } from '@common/types';
import { FileUpload } from '@comp/Global/FileUpload';
import FormSectionCard from '@comp/FormSectionCard';

import { UseCreateWarrantyTransferValues } from '../hooks/useCreateWarrantyTransferForm';

interface GeneralFormDataSectionProps {
  form: UseFormReturn<UseCreateWarrantyTransferValues, object>;
  businessSearch: {
    data: IBusiness | undefined;
    isLoading: boolean;
    error: unknown;
  };
}

const GeneralFormDataSection = ({
  form,
  businessSearch,
}: GeneralFormDataSectionProps) => {
  const { setValue, register, watch, formState } = form;

  const onSuccessUploadedFileHandler = (data: { uuid: string }) => {
    setValue('document', data.uuid);
  };

  const onSuccessDeleteFileHandler = () => {
    setValue('document', '');
  };

  const document = watch('document');
  const fiscalId = watch('fiscalId');

  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.createWarrantyTransfer.form.generalDataSection',
  });

  return (
    <FormSectionCard title={t('title')}>
      <Flex flexDirection="column" rowGap="4">
        <Box>
          <FormControl isInvalid={!!formState.errors.fiscalId}>
            <FormLabel
              size="sm"
              variant="medium"
              color="gray.700"
              htmlFor="fiscalId"
            >
              {t('inputLabel')}
            </FormLabel>
            <Input
              {...register('fiscalId')}
              id="fiscalId"
              placeholder={t('inputPlaceholder', {
                fiscalPrefix: 'CPF o CNPJ',
              })}
            />
            <FormErrorMessage>
              {formState.errors.fiscalId?.message}
            </FormErrorMessage>
          </FormControl>
          {businessSearch.isLoading && (
            <Spinner size="sm" color="primary.300" mt="1.5" />
          )}
          {!businessSearch.isLoading && businessSearch.data !== undefined && (
            <Flex
              alignItems="center"
              mt="1.5"
              columnGap="1"
              color="primary.700"
            >
              <FiCheck size="16" />
              <Text size="sm" color="primary.700">
                {`${t('hintMessage')}${businessSearch.data?.name}`}
              </Text>
            </Flex>
          )}
          {!businessSearch.isLoading && businessSearch.error && fiscalId && (
            <Flex alignItems="center" columnGap="1" mt="1.5" color="error.500">
              <FiAlertCircle size="16" />
              <Text size="sm" color="error.500">
                {t('error')}
              </Text>
            </Flex>
          )}
        </Box>
        <FormControl isInvalid={!!formState.errors.comments}>
          <FormLabel
            size="sm"
            variant="medium"
            color="gray.700"
            htmlFor="comments"
          >
            {t('observationsLabel')}
          </FormLabel>
          <Input
            {...register('comments')}
            id="comments"
            placeholder={t('observationsPlaceholder')}
            maxLength={90}
          />
          <FormErrorMessage>
            {formState.errors.comments?.message}
          </FormErrorMessage>
          <FormHelperText>{t('observationsHintMessage')}</FormHelperText>
        </FormControl>
        <FormControl isInvalid={!!formState.errors.comments}>
          <FormLabel size="sm" variant="medium" color="gray.700">
            {t('dragAndDropLabel')}
          </FormLabel>
          <FileUpload
            documentTypeId={EDocumentTypes.WARRANTY_TRANSFER}
            onSuccessUploadedFileHandler={onSuccessUploadedFileHandler}
            onSuccessDeleteFileHandler={onSuccessDeleteFileHandler}
            documentUuid={document}
          />
        </FormControl>
      </Flex>
    </FormSectionCard>
  );
};
export default GeneralFormDataSection;
