import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { http } from '../http-common';

const baseUrl = `/${PLATFORM_PREFIX}/docusign/`;
export const viewAndSignDocusign = (data: {
  docusignId: number;
  body: object;
}): Promise<any> => {
  return http
    .post<any>(`${baseUrl}${data.docusignId}`, data.body)
    .then((resp) => {
      const dataResult: any = resp.data;
      return dataResult;
    });
};

export const viewDocusign = (data: any, id: number): Promise<any> => {
  return http.post<any>(`${baseUrl}${id}`, data).then((resp) => {
    const dataResult: any = resp.data;
    return dataResult;
  });
};
