import { FC, HTMLAttributes } from 'react';

interface buttonGroupItem {
  id: number | string;
  label: string;
  onClick: () => void;
  isActive: boolean;
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  list: buttonGroupItem[];
  className?: string;
}

export const ButtonGroup: FC<Props> = ({ list, className = '' }) => {
  return (
    <div className={`flex border-y border-gray-300 ${className}`}>
      {list.map((elem) => (
        <div key={elem.id} className="border-r first:border-l border-gray-300">
          <button
            type="button"
            onClick={elem.onClick}
            className={` px-4 py-2 text-sm font-medium ${
              elem.isActive ? 'text-gray-900 bg-gray-50' : 'text-gray-500'
            }`}
          >
            {elem.label}
          </button>
        </div>
      ))}
    </div>
  );
};
