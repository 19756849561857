import { getMultiRegionMap } from '@agrotoken/common-utils';
import {
  Show,
  Hide,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  Flex,
  Divider,
} from '@chakra-ui/react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { IBusinessUser, ILink, UserTypes } from '@common/types';

import { SelectOrg } from '@comp/SelectOrg';
import TransferTokens from '@comp/TransferTokens';
import { getNotifications } from '@services/Notifications';
import { useUser } from '@src/context/userContext';
import agtkSmallLogo from '../../../assets/logos/agtkLogoSimple.svg';
import agrotoken from '../../../assets/logos/logo_standard.svg';

import {
  CARD_PATH,
  CREATE_NEW_BUSINESS_PATH,
  CREDITS_PATH,
  ENABLED,
  HOME_PATH,
  LOGIN_PATH,
  NOTIFICATIONS_PATH,
  PAYMENT_PATH,
  TOKENIZATIONS_PATH,
  USERS_PATH,
  WALLET_PATH,
} from '../../../common/const';
import { useAuth } from '../../../context/authContext';
import { Button } from '../Button';
import { Icon } from '../Icon';
import NavItem from './NavItem';

export const Navbar: FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const { nationality } = useAuth();
  const [isOpenSelectOrgModal, setIsOpenSelectOrgModal] =
    useState<boolean>(false);
  const [isOpenTransferModal, setIsOpenTransferModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();
  const { sendTokensToRippioOption, platformFrontendShouldShowCreditsTable } =
    useFlags();
  const {
    currentUser,
    selectedBusinessId,
    selectedBusiness,
    setSelectedBusiness,
    setSelectedBusinessId,
  } = useUser();
  const userIsManager = currentUser?.userType === UserTypes.MANAGER;

  const creditsLink: ILink = {
    id: 7,
    name: t('navbar.links.credits'),
    url: CREDITS_PATH,
    hide: userIsManager,
  };
  const creditsNavbarOption = getMultiRegionMap({
    AR: platformFrontendShouldShowCreditsTable ? creditsLink : null,
    BR: null,
  });

  const { data: notifications } = useQuery(
    ['notifications'],
    () => getNotifications(selectedBusinessId?.toString() || ''),
    {
      enabled: !!currentUser?.authUuid && !!selectedBusinessId,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  const handleChangeBusinessOrg = (bu: IBusinessUser) => {
    setSelectedBusiness(bu.business);
    setSelectedBusinessId(bu.businessId);
    navigate(`${HOME_PATH}`);
  };

  const handleSettings = () => {
    navigate(`${HOME_PATH}/account-settings`);
  };
  const handleChangeOrganization = () => {
    setIsOpenSelectOrgModal(true);
  };
  const handleLogout = () => {
    localStorage.clear();
    auth.signOut();
    navigate(LOGIN_PATH);
  };

  const LINKS: ILink[] = [
    {
      id: 1,
      name: t('navbar.links.home'),
      url: HOME_PATH,
      hide: userIsManager,
    },
    {
      id: 2,
      name: t('navbar.links.tokenizations'),
      url: TOKENIZATIONS_PATH,
    },
    {
      id: 3,
      name: t('navbar.links.wallet'),
      url: WALLET_PATH,
      hide: userIsManager,
    },
    {
      id: 4,
      name: t('navbar.links.paymentRequests'),
      url: PAYMENT_PATH,
      hide: userIsManager,
    },
    {
      id: 5,
      name: t('navbar.links.userAdmin'),
      url: USERS_PATH,
      hide: userIsManager,
    },
  ];

  LINKS.push({
    id: 6,
    name: t('navbar.links.card'),
    url: CARD_PATH,
    hide: userIsManager,
  });

  if (creditsNavbarOption[nationality] !== null) {
    LINKS.push(creditsNavbarOption[nationality] as ILink);
  }

  const list = [
    {
      id: 1,
      list: {
        id: 1,
        label: `${t('dropdown.configuration')}`,
        icon: 'settings',
        handleClick: handleSettings,
        hide: userIsManager,
      },
    },
    {
      id: 3,
      list: {
        id: 3,
        label: `${t('dropdown.logOut')}`,
        icon: 'log-out',
        handleClick: handleLogout,
      },
    },
  ];

  const handleCreateOrg = () => {
    navigate(CREATE_NEW_BUSINESS_PATH);
  };

  const activeBusinessUsers = currentUser?.businessUsers.filter(
    (bu) => bu.isActive === ENABLED && bu.isRegistered === ENABLED
  );
  const notSeeNotificationsCount =
    notifications?.meta.deliveredCount + notifications?.meta.sentCount;

  return (
    <div className="top-0">
      <nav className="px-8 py-3 md:py-0 bg-white shadow-xs flex flex-col items-start md:flex-row md:justify-between md:items-center w-full">
        <div className="flex flex-col md:flex-row md:items-center w-full">
          <div className="flex justify-between items-center">
            <Link to={HOME_PATH}>
              <>
                <Show above="md">
                  <img src={agrotoken} alt="" className="inline-block" />
                </Show>
                <Show below="md">
                  <img src={agtkSmallLogo} alt="" className="inline-block" />
                </Show>
              </>
            </Link>
            <Button
              icon="menu"
              iconPosition="only"
              className="justify-self-end md:hidden h-min"
              onClick={() => setShow((prev) => !prev)}
            />
          </div>
          <div className="hidden md:flex md:items-center md:justify-between w-full text-sm">
            <ul className="list-none flex flex-col md:flex-row md:ml-7 md:items-center md:gap-6">
              {LINKS.map((link) => {
                if (!link.url || link.hide) return <></>;
                return (
                  <li className="font-medium text-gray-500" key={link.id}>
                    <NavItem to={link.url} label={link.name} />
                  </li>
                );
              })}
            </ul>
            <div className="flex items-center">
              {
                <>
                  {sendTokensToRippioOption && !userIsManager && (
                    <Button
                      size="xs"
                      variant="secondary-gray"
                      label="Enviar Tokens"
                      icon="send"
                      onClick={() => setIsOpenTransferModal(true)}
                      className="mr-2 font-[700]"
                    />
                  )}
                  {!userIsManager && (
                    <>
                      <Menu>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              fontFamily="Larsseit-Medium"
                              color="gray.500"
                              borderRadius="4px"
                              paddingX="2"
                              paddingY="1"
                              className="mx-2"
                              _hover={{
                                bg: 'gray.50',
                                color: 'gray.900',
                              }}
                            >
                              <Flex alignItems="center">
                                {selectedBusiness?.name}
                                {isOpen ? (
                                  <Icon
                                    className="p-0 text-gray-500"
                                    size={'sm'}
                                    color={'gray'}
                                    icon={'chevron-up'}
                                    theme={'navbar'}
                                  />
                                ) : (
                                  <Icon
                                    className="p-0 text-gray-500"
                                    size={'sm'}
                                    color={'gray'}
                                    icon={'chevron-down'}
                                    theme={'navbar'}
                                  />
                                )}
                              </Flex>
                            </MenuButton>
                            <MenuList py={'0px'} width="max-content">
                              <MenuItem
                                py="2.5"
                                px="4"
                                bgColor="white"
                                _hover={{ bg: 'white' }}
                                _focus={{ bg: 'white' }}
                              >
                                <>
                                  <Text
                                    color="gray.700"
                                    fontSize="sm"
                                    fontFamily="Larsseit-Medium"
                                  >
                                    {t('dropdown.selectOrganization')}
                                  </Text>
                                </>
                              </MenuItem>
                              <Divider />
                              {activeBusinessUsers?.map((bu) => (
                                <MenuItem
                                  _focus={{ bg: 'white' }}
                                  _hover={{ bg: 'blue-gray.25' }}
                                  fontSize="sm"
                                  key={bu.id}
                                  onClick={() => handleChangeBusinessOrg(bu)}
                                  alignItems="center"
                                  justifyContent="space-between"
                                  paddingY={
                                    bu.businessId !== selectedBusinessId
                                      ? '2.5'
                                      : '0'
                                  }
                                  paddingLeft={'4'}
                                  paddingRight={
                                    bu.businessId === selectedBusinessId
                                      ? '1.5'
                                      : '4'
                                  }
                                  bgColor={
                                    bu.businessId === selectedBusinessId
                                      ? 'gray.50'
                                      : 'white'
                                  }
                                >
                                  <Text color="gray.600" fontSize="sm">
                                    {bu.business.name}
                                  </Text>
                                  {bu.businessId === selectedBusinessId && (
                                    <Icon
                                      size="md"
                                      color="primary"
                                      theme="no-background"
                                      icon="check"
                                    />
                                  )}
                                </MenuItem>
                              ))}
                              <Divider />
                              <MenuItem
                                _focus={{ bg: 'white' }}
                                _hover={{ bg: 'blue-gray.25' }}
                                fontSize="sm"
                                color="gray.600"
                                alignItems="center"
                                paddingLeft={'2'}
                                paddingRight={'4'}
                                paddingY="0.5"
                                onClick={() => handleCreateOrg()}
                              >
                                <Icon
                                  size="sm"
                                  color="gray"
                                  theme="no-background"
                                  icon="plus-circle"
                                  className="p-0"
                                />
                                {t('dropdown.createNew')}
                              </MenuItem>
                            </MenuList>
                          </>
                        )}
                      </Menu>

                      <NavLink
                        to={NOTIFICATIONS_PATH}
                        className={({ isActive }) =>
                          'rounded-md mr-4 relative ' +
                          (isActive && 'bg-[#F2F4F7]')
                        }
                      >
                        <Icon
                          size={'md'}
                          color={'gray'}
                          icon="bell"
                          theme={'navbar'}
                          className="hover:bg-gray-50"
                        />
                        {notifications && notSeeNotificationsCount > 0 && (
                          <span className="badge absolute bottom-5 left-5 rounded-full text-white w-4 h-4 text-sm bg-[#E62B0A] flex justify-center items-center">
                            {notSeeNotificationsCount}
                          </span>
                        )}
                      </NavLink>
                    </>
                  )}
                  <Menu>
                    <MenuButton
                      color="primary.800"
                      fontSize="sm"
                      fontFamily="Larsseit-Medium"
                      bg="primary.50"
                      rounded="full"
                      w="12"
                      h="12"
                      border="6px"
                      borderColor="white"
                      borderStyle="solid"
                      alignItems="center"
                      _hover={{
                        bg: 'primary.100',
                      }}
                      _active={{
                        border: '6px',
                        borderColor: 'primary.50',
                        borderStyle: 'solid',
                      }}
                    >
                      {currentUser?.firstName.slice(0, 1).toUpperCase()}
                      {currentUser?.lastName.slice(0, 1).toUpperCase()}
                    </MenuButton>
                    <MenuList
                      boxShadow={
                        '0px 0px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)'
                      }
                      py={'0px'}
                      mt="1"
                    >
                      <div className="px-4 py-3">
                        <>
                          <Text
                            color="gray.700"
                            fontSize="sm"
                            fontFamily="Larsseit-Medium"
                            mb="1"
                          >
                            {currentUser?.firstName} {currentUser?.lastName}
                          </Text>
                          <Text color="gray.500" fontSize="sm">
                            {currentUser?.email}
                          </Text>
                        </>
                      </div>

                      {list.map(
                        (item) =>
                          !item.list.hide && (
                            <MenuItem
                              fontSize="sm"
                              key={item.id}
                              onClick={item.list.handleClick}
                              paddingLeft="2"
                              paddingRight="4"
                              paddingY={'1'}
                              _focus={{ bg: 'white' }}
                              _hover={{ bg: 'gray.50' }}
                            >
                              <Icon
                                className="pr-1"
                                size={'sm'}
                                color={'gray'}
                                icon={item.list.icon}
                                theme={'no-background'}
                              />
                              {item.list.label}
                            </MenuItem>
                          )
                      )}
                    </MenuList>
                  </Menu>
                </>
              }
            </div>
          </div>
        </div>
      </nav>
      {show && (
        <div className="px-8 md:hidden w-full mb-4 absolute bg-white shadow-sm z-50">
          <div className="py-3">
            <Text color="gray.700" fontSize="sm" fontFamily="Larsseit-Medium">
              {currentUser?.firstName} {currentUser?.lastName}
            </Text>
            <Text color="gray.500" fontSize="sm">
              {currentUser?.email}
            </Text>
            <Text color="gray.500" fontSize="sm">
              {selectedBusiness?.name}
            </Text>
          </div>
          <Divider my="2" />
          <ul className="list-none flex flex-col w-full">
            {LINKS.map((link, index) => {
              if (!link.url) return null;
              return (
                <li className={'text-md  text-gray-600'} key={link.id}>
                  <NavLink
                    onClick={() => setShow((prev) => !prev)}
                    to={link.url}
                    className={'text-gray-600 py-2 block'}
                  >
                    <div>{link.name}</div>
                  </NavLink>
                </li>
              );
            })}
            <Divider my="2" />
            <NavLink
              to={NOTIFICATIONS_PATH}
              className={'text-gray-600 py-2 block'}
            >
              <div>{t('navbar.links.notifications')}</div>
            </NavLink>
            {list.map(
              (item) =>
                !item.list.hide && (
                  <li className={'text-md text-gray-600 py-2'} key={item.id}>
                    <Text onClick={item.list.handleClick} textColor="gray.600">
                      <div>{item.list.label}</div>
                    </Text>
                  </li>
                )
            )}
          </ul>
        </div>
      )}
      <SelectOrg
        canClose
        isOpen={isOpenSelectOrgModal}
        setIsOpen={setIsOpenSelectOrgModal}
      />
      {sendTokensToRippioOption && (
        <TransferTokens
          isOpen={isOpenTransferModal}
          handleClose={() => setIsOpenTransferModal(false)}
        />
      )}
    </div>
  );
};

export default Navbar;
