import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import { IAccountType } from '@common/types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getBankAccountTypes } from '@services/BankAccounts';
import PixForm from './PixForm';
import { useAuth } from '@src/context/authContext';

interface Props {
  validationMessage: string | null | undefined;
}
const BankAccountForm = () => {
  const { nationality } = useAuth();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboardingLevelThree',
  });

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const {
    isError: isErrorAccountTypes,
    isLoading: isLoadingAccountTypes,
    data: accountTypes,
  } = useQuery<IAccountType[]>(['accountTypes'], getBankAccountTypes);

  if (isErrorAccountTypes) return <div>error</div>;

  // if (isLoadingAccountTypes) return <Spinner />;

  return (
    <Flex flexDir="column">
      <Box>
        <Flex flexDir="column" gap="2" mb="4">
          <Box>
            <Heading fontSize="xl" mb="2">
              {t('title')}
            </Heading>
            <Text fontWeight="normal">
              {t(`bankAccountInfo.${nationality}.subtitle`)}
            </Text>
          </Box>
          <Grid
            templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
            columnGap="6"
            rowGap="4"
          >
            <FormControl mr="4" isInvalid={Boolean(errors.bankName)}>
              <FormLabel htmlFor="bankName">
                {t(`bankAccountInfo.${nationality}.bankName.label`)}
              </FormLabel>
              <Input
                id="bankName"
                placeholder={t(
                  `bankAccountInfo.${nationality}.bankName.placeholder`
                )}
                {...register('bankName', {
                  required: t('requiredMsg'),
                })}
              />
              <FormErrorMessage>{errors.bankName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl mr="4" isInvalid={Boolean(errors.bankCode)}>
              <FormLabel htmlFor="bankCode">
                {t(`bankAccountInfo.${nationality}.bankCode.label`)}
              </FormLabel>
              <Input
                id="bankCode"
                placeholder={t(
                  `bankAccountInfo.${nationality}.bankCode.placeholder`
                )}
                {...register('bankCode', {
                  required: t('requiredMsg'),
                  maxLength: {
                    value: 3,
                    message: t(
                      `bankAccountInfo.${nationality}.bankCode.errorMsg`
                    ),
                  },
                  minLength: {
                    value: 3,
                    message: t(
                      `bankAccountInfo.${nationality}.bankCode.errorMsg`
                    ),
                  },
                })}
              />
              <FormErrorMessage>{errors.bankCode?.message}</FormErrorMessage>
            </FormControl>
            <FormControl mr="4" isInvalid={Boolean(errors.accountTypeId)}>
              <FormLabel htmlFor="accountTypeId">
                {t(`bankAccountInfo.${nationality}.accountTypeId.label`)}
              </FormLabel>
              <Controller
                control={control}
                name="accountTypeId"
                defaultValue=""
                rules={{ required: t('requiredMsg') }}
                render={({ field }) => (
                  <Select
                    id="accountTypeId"
                    {...field}
                    placeholder={t(
                      `bankAccountInfo.${nationality}.accountTypeId.placeholder`
                    )}
                  >
                    {accountTypes?.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Select>
                )}
              ></Controller>
              <FormErrorMessage>
                {errors.accountTypeId?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mr="4" isInvalid={Boolean(errors.subsidiaryNumber)}>
              <FormLabel htmlFor="subsidiaryNumber">
                {t(`bankAccountInfo.${nationality}.agencyCode.label`)}
              </FormLabel>
              <Input
                id="subsidiaryNumber"
                placeholder={t(
                  `bankAccountInfo.${nationality}.agencyCode.placeholder`
                )}
                {...register('subsidiaryNumber', {
                  required: t('requiredMsg'),
                  minLength: {
                    value: 3,
                    message: t(
                      `bankAccountInfo.${nationality}.agencyCode.errorMsg`
                    ),
                  },
                  maxLength: {
                    value: 8,
                    message: t(
                      `bankAccountInfo.${nationality}.agencyCode.errorMsg`
                    ),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.subsidiaryNumber?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mr="4" isInvalid={Boolean(errors.accountNumber)}>
              <FormLabel htmlFor="accountNumber">
                {t(`bankAccountInfo.${nationality}.accountNumber.label`)}
              </FormLabel>
              <Input
                id="accountNumber"
                placeholder={t(
                  `bankAccountInfo.${nationality}.accountNumber.placeholder`
                )}
                {...register('accountNumber', {
                  required: t('requiredMsg'),
                  minLength: {
                    value: 3,
                    message: t(
                      `bankAccountInfo.${nationality}.accountNumber.errorMsg`
                    ),
                  },
                  maxLength: {
                    value: 20,
                    message: t(
                      `bankAccountInfo.${nationality}.accountNumber.errorMsg`
                    ),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.accountNumber?.message}
              </FormErrorMessage>
            </FormControl>
          </Grid>
        </Flex>
        <PixForm />
      </Box>
    </Flex>
  );
};

export default BankAccountForm;
