export const CARDS_TRANSLATIONS_ES = {
  cardsRequest: {
    linkButton: 'Completar datos',
    onboardingTitle:
      'No completaste los datos necesarios para pedir una tarjeta, completalos acá',
    appRedirectionTitle:
      'Por favor descargá la aplicación para realizar la solicitud',
  },
  cardList: {
    header: {
      downloadButton: 'Descargar resumen',
      requestButton: 'Solicitar tarjeta',
      title: 'Tarjetas',
    },
    main: {
      myCardsTitle: 'Mis tarjetas',
      myCardDescription: 'Terminada en ****',
      expiration: 'Vence en: ',
      activation: 'Activar en App ',
      cardStatus: {
        ACTIVE: 'Activo',
        INACTIVE: 'Inactivo',
        PAUSED: 'Pausada',
        EXPIRED: 'Vencida',
      },
      cardType: {
        physical: 'Física',
        virtual: 'Virtual',
      },
    },
  },
};

export type CardsTranslationsType = typeof CARDS_TRANSLATIONS_ES;
