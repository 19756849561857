import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { FC, HTMLAttributes } from 'react';
import { Button } from '@agrotoken-tech/ui';
import { FULL_ONBOARDING_KYC } from '@common/const';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
  icon?: string;
  buttonLabel?: string;
  className?: string;
  hasButton?: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const TextBox: FC<Props> = ({
  title,
  description,
  icon,
  buttonLabel,
  className,
  hasButton,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      border="1px solid"
      padding="4"
      borderColor="gray.300"
      borderRadius="4px"
      backgroundColor="gray.25"
      className={className}
    >
      <Flex gap={3} align="top">
        <FeatherIcon icon={icon} color="gray.600" size="20" />
        {children}
        <Box>
          <Text fontSize="md" color="gray.700" fontFamily="Larsseit-Medium">
            {title}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {description}
          </Text>
          {hasButton && (
            <Button
              onClick={() => {
                window.location.href = FULL_ONBOARDING_KYC;
              }}
              variant={'link'}
            >
              {buttonLabel}
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
