import { Badge, Flex, Text } from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import get from 'lodash.get';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { CREDITS_PATH } from '@common/const';
import { TokenIcon } from '@comp/Global/TokenIcon';
import { Loan } from '@def/loan';
import { useUser } from '@src/context/userContext';
import { mapLenderTypeToLoanType } from '../mapLenderTypeToLoanType';
import { statusWithColors } from '../mapLoanStatusToStatusAndColor';
import { BaseTable } from './BaseTable';

interface Props {
  data: Loan[];
  alreadyPaid: boolean;
}

export const CreditsPageTable: FC<Props> = ({ data, alreadyPaid }) => {
  const { t } = useTranslation();
  const { selectedBusinessId } = useUser();
  const navigate = useNavigate();
  const { platformFrontendShouldShowCreditsDetail } = useFlags();
  const emptyFn = () => void 0;
  const openDetails = platformFrontendShouldShowCreditsDetail
    ? (id: string) => {
        navigate(`${CREDITS_PATH}/${id}`);
      }
    : emptyFn;

  const dateAccessor = alreadyPaid ? 'paidAt' : 'insertedAt';
  const movementsColumnsAr: Column<any>[] = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: (props: any) => {
          const movement = props.row.original;
          const { id } = movement;
          return <p className="text-sm text-gray-900 font-medium">{id}</p>;
        },
      },
      {
        Header: 'TIPO',
        accessor: 'type',
        Cell: (props: {
          row: {
            original: { lenderType: keyof typeof mapLenderTypeToLoanType };
          };
        }) => {
          const { lenderType } = props.row.original;

          return (
            <Text
              className={'text-sm font-normal text-gray-600'}
              textTransform="capitalize"
            >
              {mapLenderTypeToLoanType[lenderType]}
            </Text>
          );
        },
      },
      {
        Header: 'MONTO',
        accessor: 'amountToFinance',
        Cell: (props: { row: { original: Loan } }) => {
          const credit = props.row.original;

          return (
            <p className="text-sm text-gray-900 font-normal">
              {`${credit.fiatCurrency} ${credit.amountToFinance}`}
            </p>
          );
        },
      },
      {
        Header: 'TOKENS BLOQUEADOS',
        accessor: 'lockedTokens',
        Cell: (props: any) => {
          const credit = props.row.original;
          const lockedTokens = credit.totalLockedAmount;
          const token = credit.cryptoCurrency;

          return (
            <Flex
              className="text-sm font-normal"
              color="gray.900"
              alignItems="center"
            >
              <TokenIcon size="sm" token={token} className="ml-2 mr-1" />
              <Text>{lockedTokens}</Text> &nbsp;
              <Text textTransform="uppercase">{token}</Text>
            </Flex>
          );
        },
      },
      {
        Header: 'ENTIDAD FINANCIERA',
        accessor: 'lenderName',
        Cell: (props: {
          row: {
            original: {
              lenderName: string;
            };
          };
        }) => {
          const { lenderName } = props.row.original;

          return (
            <p className="text-sm text-gray-900 font-normal">{lenderName}</p>
          );
        },
      },
      {
        Header: 'STATUS',
        id: 'status',
        accessor: 'status',
        Cell: (props: {
          row: { original: { status: keyof typeof statusWithColors } };
        }) => {
          const status = props.row.original.status;
          const badgeColorScheme = get(
            statusWithColors,
            `${status}.color`,
            'gray'
          );
          const badgeText = get(statusWithColors, `${status}.text`, status);
          return (
            <Badge
              size="sm"
              variant="subtle"
              px="2"
              colorScheme={badgeColorScheme}
              textTransform="none"
            >
              {badgeText}
            </Badge>
          );
        },
      },

      {
        Header: alreadyPaid ? 'FECHA DE PAGO' : 'FECHA DE CREACION',
        accessor: dateAccessor,
        id: dateAccessor,
        Cell: (props: any) => {
          const credit = props.row.original;
          const formattedDate = moment(credit[dateAccessor]).format(
            'HH:mm - DD/MM/YYYY'
          );

          return (
            <p className="text-sm text-gray-900 font-normal">{formattedDate}</p>
          );
        },
      },
    ],
    [selectedBusinessId, t]
  );

  return (
    <BaseTable
      columns={movementsColumnsAr}
      data={data}
      showPagination={false}
      openDetails={openDetails}
    />
  );
};
