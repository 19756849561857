import { ENationalities, WEIGHT_UNITS } from '@agrotoken/common-utils';
import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { formatNumber, getLocalToken } from '@common/utils';
import { TokenIcon } from '@comp/Global/TokenIcon';
import useGetConversion from '@hooks/useGetConversion';
import useGetLocalCurrency from '@hooks/useGetLocalCurrency';
import { EUnits, TCurrencyName } from '@src/common/types';
import { useAuth } from '@src/context/authContext';

interface TokensAmountProps {
  amountFiat: number;
  className?: string;
  showCurrencyLabel?: boolean;
}
export const TokensAmount: FC<TokensAmountProps> = ({
  amountFiat,
  className = '',
  showCurrencyLabel,
}) => {
  const { nationality } = useAuth();
  const { localCurrency } = useGetLocalCurrency();
  const formatAmount = (
    value: number,
    operationType: EPaymentRequestOperationType
  ): string => {
    const roundedValue = roundValue({
      value,
      operationType,
      scale: 4,
    }).toNumber();
    return formatNumber(roundedValue, EUnits.CRYPTO);
  };

  const tokenSoy = getLocalToken(nationality).tokenSoy;
  const tokenCor = getLocalToken(nationality).tokenCor;
  const tokenWhe = getLocalToken(nationality).tokenWhe;

  const amountTokenSOYFormatted = formatAmount(
    useGetConversion(amountFiat, localCurrency, tokenSoy, WEIGHT_UNITS.TONS),
    EPaymentRequestOperationType.TOKENS_TO_PAY_BY_FARMER
  );

  const amountTokenCORFormatted = formatAmount(
    useGetConversion(amountFiat, localCurrency, tokenCor, WEIGHT_UNITS.TONS),
    EPaymentRequestOperationType.TOKENS_TO_PAY_BY_FARMER
  );

  const amountTokenWHEFormatted = formatAmount(
    useGetConversion(amountFiat, localCurrency, tokenWhe, WEIGHT_UNITS.TONS),
    EPaymentRequestOperationType.TOKENS_TO_PAY_BY_FARMER
  );

  const isBrlNationality = nationality === ENationalities.BR;
  return (
    <Box className={`flex gap-3 ${className}`}>
      <Box className="flex items-center">
        <TokenIcon size="sm" token={tokenSoy as TCurrencyName} />
        <span className="text-md text-gray-700 font-normal ml-1">
          {`${amountTokenSOYFormatted}`} {showCurrencyLabel && ` ${tokenSoy}`}
        </span>
      </Box>
      <Box className="flex items-center">
        {!showCurrencyLabel && tokenCor && <> {' / '}</>}
      </Box>
      <Box className="flex items-center">
        <TokenIcon size="sm" token={tokenCor as TCurrencyName} />
        <span className="text-md text-gray-700 font-normal ml-1">
          {`${amountTokenCORFormatted} `} {showCurrencyLabel && ` ${tokenCor}`}
        </span>
      </Box>
      <Box className="flex items-center">
        {!showCurrencyLabel && tokenWhe && !isBrlNationality && <> {' / '}</>}
      </Box>
      {!isBrlNationality && (
        <Box className="flex items-center ">
          <TokenIcon size="sm" token={tokenWhe as TCurrencyName} />
          <span className="text-md text-gray-700 font-normal ml-1">
            {`${amountTokenWHEFormatted}`} {showCurrencyLabel && ` ${tokenWhe}`}
          </span>
        </Box>
      )}
    </Box>
  );
};
