import { Card } from '@agrotoken-tech/ui';
// eslint-disable-next-line import/named
import { Box, Flex, StyleProps, Text } from '@chakra-ui/react';

type CardProps = React.ComponentProps<typeof Card>;
type Variant = CardProps['variant'];
type Padding = CardProps['padding'];

interface BaseCardProps extends StyleProps {
  title: string;
  subtitle: React.ReactNode;
  variant?: Variant;
  padding?: Padding;
  topRightElement?: React.ReactNode;
  bottomElements?: React.ReactNode;
  onClick?: () => void;
}

/**
 * Renders a base card component with optional title, subtitle, and additional elements.
 *
 * @param {string} title - The title of the card.
 * @param {React.ReactNode} subtitle - The subtitle of the card.
 * @param {Variant} variant - The variant of the card.
 * @param {Padding} padding - The padding of the card.
 * @param {React.ReactNode} topRightElement - An element to be displayed at the top right of the card.
 * @param {React.ReactNode} bottomElements - Additional elements to be displayed below the card's content.
 */
const BaseCard = ({
  title,
  subtitle,
  topRightElement,
  bottomElements,
  onClick,
  variant = 'white',
  padding = 'sm',
  ...otherProps
}: BaseCardProps) => {
  return (
    <Card
      padding={padding}
      variant={variant}
      minH="40"
      justifyContent="space-between"
      onClick={onClick}
      {...otherProps}
    >
      <>
        <Flex justifyContent="space-between">
          <Box>
            <Text size="sm" variant="regular" color="content-secondary" mb="2">
              {title}
            </Text>
            <Text size="lg" variant="medium" color="content-secondary">
              {subtitle}
            </Text>
          </Box>
          {topRightElement}
        </Flex>
        {bottomElements}
      </>
    </Card>
  );
};

export default BaseCard;
