import { Box, Flex } from '@chakra-ui/react';

import { Container } from '@comp/Global/Container';

interface CardGenericLayoutProps {
  header?: React.ReactNode;
  main?: React.ReactNode;
  aside?: React.ReactNode;
}

// TODO: This component is a duplicate, but will substitute PaymentLayout component on the refactor of PaymentRequests
const CardGenericLayout = ({ aside, header, main }: CardGenericLayoutProps) => {
  return (
    <Container>
      <Box as="header">{header}</Box>
      <Flex gap="8" flexDirection={{ base: 'column', lg: 'row' }}>
        <Box as="main" flexGrow={1}>
          {main}
        </Box>
        <Box as="aside" maxWidth={{ base: '100%', lg: '405px' }} width="100%">
          {aside}
        </Box>
      </Flex>
    </Container>
  );
};

export default CardGenericLayout;
