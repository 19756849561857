import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import {
  IBusinessUser,
  IRepresentant,
  IUser,
  UpdateBusinessUserDto,
} from '../common/types';
import { http } from '../http-common';

export const getBusinessUsers = ({
  queryKey,
}: any): Promise<IBusinessUser[] | []> => {
  try {
    const [, businessId] = queryKey;
    return http
      .get<IBusinessUser[]>(
        `/${PLATFORM_PREFIX}/business-users/business/${businessId}/members`
      )
      .then((resp) => {
        const dataResult: IBusinessUser[] | [] = resp.data;
        return dataResult;
      });
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const getBusinessUsersAsManager = ({
  queryKey,
}: any): Promise<IBusinessUser[] | []> => {
  try {
    const [, businessId] = queryKey;
    return http
      .get<IBusinessUser[]>(
        `/${PLATFORM_PREFIX}/business-users/manager/business/${businessId}/members`
      )
      .then((resp) => {
        const dataResult: IBusinessUser[] | [] = resp.data;
        return dataResult;
      });
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const getBusinessUser = ({ queryKey }: any): Promise<IBusinessUser> => {
  try {
    const [, id] = queryKey;
    return http
      .get<IBusinessUser>(`/${PLATFORM_PREFIX}/business-users/${id}`)
      .then((resp) => {
        const dataResult: IBusinessUser = resp.data;
        return dataResult;
      });
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const getBusinessUserAsManager = ({
  queryKey,
}: any): Promise<IBusinessUser> => {
  try {
    const [, id] = queryKey;
    return http
      .get<IBusinessUser>(`/${PLATFORM_PREFIX}/business-users/manager/${id}`)
      .then((resp) => {
        const dataResult: IBusinessUser = resp.data;
        return dataResult;
      });
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const updateBusinessUser = async (data: {
  id: number;
  body: Partial<UpdateBusinessUserDto>;
}): Promise<boolean> => {
  if (!data.id) {
    console.warn('`updateBusinessUser` was called with no id provided');
    return false;
  }

  try {
    const resp = await http.patch<IBusinessUser>(
      `/${PLATFORM_PREFIX}/business-users/${data.id}`,
      data.body
    );
    return resp.status === 200 || resp.status === 204;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const updateBusinessUserPermissions = async (
  data: any
): Promise<boolean> => {
  if (!data.id) {
    console.warn('`updateBusinessUser` was called with no id provided');
    return false;
  }

  try {
    const resp = await http.patch<void>(
      `/${PLATFORM_PREFIX}/business-users/${data.id}/permissions`,
      data.body
    );
    return resp.status === 200;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const deleteUser = async (id: number): Promise<IUser | undefined> => {
  try {
    const resp = await http.delete<IUser>(
      `/${PLATFORM_PREFIX}}/business-users/${id}`
    );
    const dataResult: IUser = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const findRepresentantByBusinessUser = async (): Promise<
  Record<string, never> | IRepresentant
> => {
  try {
    const resp = await http.get(
      `/${PLATFORM_PREFIX}/business-users/onboarding/representant`
    );
    const dataResult = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data?.message);
  }
};
