import { useMutation } from 'react-query';

import { downloadDocument } from '@services/Documents';

const useDownloadDocument = () => {
  const { mutate, isLoading, error } = useMutation(
    ['documents', 'download'],
    (uuid: string) => downloadDocument(uuid)
  );

  return { mutate, isLoading, error };
};

export default useDownloadDocument;
