import { checkCuitTypeAndLength } from '@agrotoken/common-utils';
import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Select,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { useEffect, useRef, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { PEP_RADIO } from '@comp/FullOnboarding/KYC/consts';
import PEPRadio from '@comp/FullOnboarding/PEPRadio';
import { getGeographicalDivisions } from '@services/GeographicalDivision';
import { getCities, getCountries } from '@services/Shared';
import { managementPositions, maritalStatuses } from '../consts';

interface Props {
  prefix: string;
  shouldSetFieldsRequired: boolean;
  canEdit: boolean;
}

const BeneficiaryForm: VFC<Props> = ({
  prefix,
  shouldSetFieldsRequired,
  canEdit,
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  /** TODO añadir traducciones a todo */
  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboardingLevelThree.finalBeneficiaries.beneficiaryForm',
  });
  const requiredFieldError = t('errors.required');

  const nationalityCountryId = watch(`${prefix}.nationality`);
  const geographicalDivisionId = watch(`${prefix}.geographicalDivision`);

  const { data: countries, isLoading: isLoadingCountries } = useQuery(
    'getCountries',
    getCountries
  );

  const { data: geographicalDivisions, isLoading: isLoadingGeoDivisions } =
    useQuery(['geographicalDivisions', nationalityCountryId], async () => {
      if (nationalityCountryId) {
        return getGeographicalDivisions(Number(nationalityCountryId));
      }
    });
  const { data: cities, isLoading: isLoadingCities } = useQuery(
    ['getCities', geographicalDivisionId],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      if (id) {
        return getCities(Number(id));
      }
    }
  );

  const shouldSetDefaultGeographicalDivisionId = useRef(true);
  useEffect(() => {
    const shouldSetDefaultGeographicalDivision =
      geographicalDivisions && shouldSetDefaultGeographicalDivisionId.current;
    if (shouldSetDefaultGeographicalDivision) {
      setValue(
        `${prefix}.geographicalDivision`,
        watch(`${prefix}.geographicalDivision`)
      );
      shouldSetDefaultGeographicalDivisionId.current = false;
    }
  }, [geographicalDivisions, cities, prefix]);

  const shouldSetDefaultCityId = useRef(true);
  useEffect(() => {
    const shouldSetDefaultCity =
      geographicalDivisions && cities && shouldSetDefaultCityId.current;
    if (shouldSetDefaultCity) {
      setValue(`${prefix}.cityId`, watch(`${prefix}.cityId`));
      shouldSetDefaultCityId.current = false;
    }
  }, [geographicalDivisions, cities, prefix]);

  const errorsByPrefix = get(errors, prefix, {}) as any;
  const invalidCuitError = t('fields.identificationTaxValue.error');
  const disableEdit = !canEdit;
  return (
    <>
      <Flex flexDir="column" mt="16">
        <Heading fontSize="2xl">{t('title')}</Heading>
        <Text mb="2">{t('description')}</Text>

        <Flex flexDir="column">
          <SimpleGrid columns={[1, 1, 2]} gap="4">
            <FormControl isInvalid={Boolean(errorsByPrefix.name)}>
              <FormLabel htmlFor="name">{t('fields.name.label')}</FormLabel>
              <Input
                id="name"
                h="12"
                {...register(`${prefix}.name`, {
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              />
              {errorsByPrefix.name && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errorsByPrefix.surname)}>
              <FormLabel htmlFor="surname">
                {t('fields.surname.label')}
              </FormLabel>
              <Input
                type="tel"
                id="surname"
                h="12"
                {...register(`${prefix}.surname`, {
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              />
              {errorsByPrefix.surname && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={Boolean(errorsByPrefix.identificationValue)}
            >
              <FormLabel htmlFor="identificationValue">
                {t('fields.identificationValue.label')}
              </FormLabel>
              <Input
                id="identificationValue"
                h="12"
                {...register(`${prefix}.identificationValue`, {
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              />
              {errorsByPrefix.identificationValue && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errorsByPrefix.maritalStatusId)}>
              <FormLabel htmlFor="maritalStatusId">
                {t('fields.maritalStatus.label')}
              </FormLabel>
              <Select
                id="maritalStatusId"
                placeholder={t('fields.maritalStatus.placeholder')}
                size="lg"
                {...register(`${prefix}.maritalStatusId`, {
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              >
                {maritalStatuses.map(({ name, keyCode }) => (
                  <option value={keyCode} key={keyCode}>
                    {name}
                  </option>
                ))}
              </Select>
              {errorsByPrefix.maritalStatusId && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              flex={['1', '1', '0.5']}
              isInvalid={Boolean(errorsByPrefix.nationality)}
            >
              <FormLabel htmlFor="nationality">
                {t('fields.nationality.label')}
              </FormLabel>
              <Select
                id="nationality"
                size="lg"
                placeholder={t('fields.nationality.placeholder')}
                {...(isLoadingCountries
                  ? { icon: <Spinner color="primary.200" />, disabled: true }
                  : {})}
                {...register(`${prefix}.nationality`, {
                  valueAsNumber: true,
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              >
                {countries?.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Select>
              {errorsByPrefix.nationality && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isInvalid={Boolean(errorsByPrefix.identificationTaxValue)}
            >
              <FormLabel htmlFor="identificationTaxValue">
                {t('fields.identificationTaxValue.label')}
              </FormLabel>
              <Input
                id="identificationTaxValue"
                h="12"
                {...register(`${prefix}.identificationTaxValue`, {
                  validate: (value: string) =>
                    !shouldSetFieldsRequired ||
                    checkCuitTypeAndLength(value) ||
                    invalidCuitError,
                })}
                disabled={disableEdit}
              />
              {errorsByPrefix.identificationTaxValue && (
                <FormErrorMessage>
                  {errorsByPrefix.identificationTaxValue.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={Boolean(errorsByPrefix.address)}>
              <FormLabel htmlFor="address">
                {t('fields.address.label')}
              </FormLabel>
              <Input
                id="address"
                h="12"
                {...register(`${prefix}.address`, {
                  required: shouldSetFieldsRequired,
                  maxLength: {
                    value: 300,
                    message: t('fields.address.errors.maxLength', { max: 300 }),
                  },
                })}
                disabled={disableEdit}
              />
              {errorsByPrefix.address && (
                <FormErrorMessage>
                  {errorsByPrefix?.address?.message || requiredFieldError}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errorsByPrefix.postalCode)}>
              <FormLabel htmlFor="postalCode">
                {t('fields.postalCode.label')}
              </FormLabel>
              <Input
                type="tel"
                id="postalCode"
                h="12"
                {...register(`${prefix}.postalCode`, {
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              />
              {errorsByPrefix.postalCode && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={Boolean(errorsByPrefix.geographicalDivision)}
            >
              <FormLabel htmlFor="geographicalDivision">
                {t('fields.geographicalDivision.label')}
              </FormLabel>
              <Select
                id="geographicalDivision"
                size="lg"
                {...(isLoadingGeoDivisions
                  ? { icon: <Spinner color="primary.200" />, disabled: true }
                  : {})}
                placeholder="Seleccione una provincia"
                {...register(`${prefix}.geographicalDivision`, {
                  valueAsNumber: true,
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              >
                {geographicalDivisions?.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Select>
              {errorsByPrefix.geographicalDivision && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errorsByPrefix.cityId)}>
              <FormLabel htmlFor="cityId">{t('fields.city.label')}</FormLabel>
              <Select
                id="cityId"
                size="lg"
                placeholder="Seleccione una ciudad"
                {...(isLoadingCities
                  ? { icon: <Spinner color="primary.200" />, disabled: true }
                  : {})}
                {...register(`${prefix}.cityId`, {
                  valueAsNumber: true,
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              >
                {cities?.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Select>

              {errorsByPrefix.cityId && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              isInvalid={Boolean(errorsByPrefix.participationPercentage)}
            >
              <FormLabel htmlFor="participationPercentage">
                {t('fields.participationPercentage.label')}
              </FormLabel>
              <NumberInput>
                <Input
                  id="participationPercentage"
                  h="12"
                  type="number"
                  {...register(`${prefix}.participationPercentage`, {
                    required: shouldSetFieldsRequired,
                    valueAsNumber: true,
                    max: {
                      value: 100,
                      message: t(
                        'fields.participationPercentage.errors.minLength',
                        {
                          min: 0,
                          max: 100,
                        }
                      ),
                    },
                    min: {
                      value: 0,
                      message: t(
                        'fields.participationPercentage.errors.maxLength',
                        {
                          min: 0,
                          max: 100,
                        }
                      ),
                    },
                  })}
                  disabled={disableEdit}
                />
              </NumberInput>
              {errorsByPrefix.participationPercentage && (
                <FormErrorMessage>
                  {errorsByPrefix.participationPercentage?.message ||
                    requiredFieldError}
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl
              isInvalid={Boolean(errorsByPrefix.managementPositionId)}
            >
              <FormLabel htmlFor="managementPositionId">
                {t('fields.managementPosition.label')}
              </FormLabel>
              <Select
                id="managementPositionId"
                size="lg"
                placeholder={t('fields.managementPosition.placeholder')}
                {...register(`${prefix}.managementPositionId`, {
                  required: shouldSetFieldsRequired,
                })}
                disabled={disableEdit}
              >
                {managementPositions.map(({ keyCode, name }) => (
                  <option value={keyCode} key={keyCode}>
                    {name}
                  </option>
                ))}
              </Select>
              {errorsByPrefix.managementPositionId && (
                <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>

          <Flex>
            <PEPRadio
              register={register}
              name={`${prefix}.${PEP_RADIO}`}
              title={t('fields.pep.label')}
              disabled={disableEdit}
            />
          </Flex>
        </Flex>
      </Flex>
      <Divider my="6" />
    </>
  );
};
export default BeneficiaryForm;
