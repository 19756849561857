import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { VFC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  formRegisterProps: UseFormRegisterReturn<string>;
  error: any;
  disabled?: boolean;
}
const CuitOrCuil: VFC<Props> = ({
  formRegisterProps,
  error,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const hasError = Boolean(error);
  return (
    <FormControl mr="4" isInvalid={Boolean(hasError)}>
      <FormLabel htmlFor="cuit">
        {t('fullOnboarding.lvl_2.KYC.fiscalId.label')}
      </FormLabel>
      <Input
        id="cuit"
        h="12"
        placeholder={t('fullOnboarding.lvl_2.KYC.fiscalId.placeholder')}
        {...formRegisterProps}
        disabled={disabled}
      />
      {hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default CuitOrCuil;
