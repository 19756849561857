import {
  formatNumber,
  TokenCurrency,
  TOKEN_CURRENCIES,
} from '@agrotoken/common-utils';
import { Badge, CornToken, SoyToken, WheatToken } from '@agrotoken-tech/ui';
import { Flex, Text } from '@chakra-ui/react';
import Decimal from 'decimal.js';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle, FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { TOKENIZATIONS_PATH } from '@common/const';
import { GrainContractTypes } from '@common/enum';
import { EUnits } from '@common/types';
import { BaseCard } from '@comp/BaseCard';

/**
 * A mapping of token currency codes to their corresponding token icons.
 * @type {Object}
 */
const TOKEN_ICON = {
  [TOKEN_CURRENCIES.SOYA]: <SoyToken size="xs" />,
  [TOKEN_CURRENCIES.SOYB]: <SoyToken size="xs" />,
  [TOKEN_CURRENCIES.CORA]: <CornToken size="xs" />,
  [TOKEN_CURRENCIES.CORB]: <CornToken size="xs" />,
  [TOKEN_CURRENCIES.WHEA]: <WheatToken size="xs" />,
  [TOKEN_CURRENCIES.WHEB]: <></>,
  [TOKEN_CURRENCIES.ABRC]: <></>,
  [TOKEN_CURRENCIES.COFB]: <></>,
};

interface PreTokenizedCardProps {
  amountTons: Decimal;
  amountSacas: Decimal;
  currency: TokenCurrency;
  grainContractId: number;
  grainContractType: GrainContractTypes;
  requestNumber: number;
  isProofOfLiquidityCompleted: boolean;
}

/**
 * React component for displaying a pre-tokenized card.
 * @param {Decimal} amountSacas - The amount of Sacas of the pre-tokenization.
 * @param {Decimal} amountTons - The amount of Tons of the pre-tokenization.
 * @param {TokenCurrency} currency - The token currency.
 * @param {number} grainContractId - The identifier of the grain contract.
 * @param {GrainContractTypes} grainContractType - The type of grain contract.
 * @param {number} requestNumber - The grain contract request number.
 * @param {boolean} isProofOfLiquidityCompleted - A boolean indicating whether the proof of liquidity is completed
 */
const PreTokenizedCard = ({
  amountSacas,
  amountTons,
  currency,
  grainContractId,
  grainContractType,
  requestNumber,
  isProofOfLiquidityCompleted,
}: PreTokenizedCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'home.preTokenizedCard',
  });

  const amount =
    grainContractType === GrainContractTypes.REAL ? amountTons : amountSacas;
  const formattedAmount = formatNumber(amount, EUnits.TONS);

  /**
   * Event handler for clicking the "Continue Tokenization" button. Navigates to the proof of liquidity page.
   */
  const onClickContinueTokenizationHandler = () => {
    if (isProofOfLiquidityCompleted) {
      navigate(`${TOKENIZATIONS_PATH}/${grainContractId}/confirm`);
    } else {
      navigate(`${TOKENIZATIONS_PATH}/${grainContractId}/proof-of-liquidity`);
    }
  };

  /**
   * Object that maps the grain contract type to its corresponding subtitle.
   * @type {Object}
   */
  const SUBTITLE = {
    [GrainContractTypes.FUTURE]: t('subtitle.amount', {
      amount: formattedAmount,
    }),
    [GrainContractTypes.REAL]: (
      <Flex columnGap="1" alignItems="center">
        {formattedAmount}
        {TOKEN_ICON[currency]}
      </Flex>
    ),
    [GrainContractTypes.VIRTUAL]: '',
    [GrainContractTypes.FIXED_GRAIN]: t('subtitle.amount', {
      amount: formattedAmount,
    }),
  };

  /**
   * Object that maps the grain contract type to its corresponding title.
   * @type {Object}
   */
  const TITLE = {
    [GrainContractTypes.FUTURE]: t(`title.future.${currency}`, {
      requestNumber,
    }),
    [GrainContractTypes.REAL]: `${currency}`,
    [GrainContractTypes.VIRTUAL]: '',
    [GrainContractTypes.FIXED_GRAIN]: t(`title.fixedGrain.${currency}`, {
      requestNumber,
    }),
  };

  return (
    <BaseCard
      title={TITLE[grainContractType]}
      subtitle={SUBTITLE[grainContractType]}
      variant="white"
      topRightElement={
        <Badge size="sm" colorScheme="warning" icon={FiAlertCircle}>
          {t('badge')}
        </Badge>
      }
      bottomElements={
        <Text
          variant="medium"
          size="sm"
          color="content-secondary"
          display="flex"
          alignItems="center"
          columnGap="1"
        >
          {t('cta')}
          <FiArrowRight />
        </Text>
      }
      onClick={onClickContinueTokenizationHandler}
    />
  );
};

export default PreTokenizedCard;
