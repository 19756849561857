import { ENationalities } from '@agrotoken/common-utils';
import { useAuth } from '@src/context/authContext';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const usePostalCodeConditionsByNationality = () => {
  const { nationality } = useAuth();
  const { t } = useTranslation();
  const isRequired = `${t('general.form.requiredMsg')}`;

  let postalCodeSchema;

  switch (nationality) {
    case ENationalities.AR:
      postalCodeSchema = yup
        .string()
        .matches(/^[0-9]+$/, t('card.shipmentCard.form.errors.onlyNumbers'))
        .required(isRequired)
        .min(4, t('card.shipmentCard.form.errors.postalCode'))
        .max(4, t('card.shipmentCard.form.errors.postalCode'));
      break;
    case ENationalities.BR:
      postalCodeSchema = yup.number().required(isRequired);
      break;
    default:
      postalCodeSchema = yup.string().required(isRequired);
  }
  return {
    postalCodeSchema,
  };
};
