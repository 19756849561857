import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { EWalletType } from '@common/enum';
import { http } from '../http-common';

export interface SwapPoGRPayload {
  amount: number; // up to four decimals
  currency: string;
  toWallet: EWalletType;
}

export interface SwapPoGRResponse {
  uuid: string;
}

export const swapPoGR = async (
  payload: SwapPoGRPayload
): Promise<SwapPoGRResponse> => {
  const response = await http.post<SwapPoGRResponse>(
    `/${PLATFORM_PREFIX}/swap/perpetual`,
    payload
  );
  return response.data;
};
