import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { errorHandler } from '@features/loans/utils';
import { http } from '@src/http-common';

const authorizeLoanPayment = async (id: string) => {
  try {
    const response = await http.put(
      `/${PLATFORM_PREFIX}/loans/${id}/authorize`
    );

    return response;
  } catch (error) {
    errorHandler(error);
  }
};

export default authorizeLoanPayment;
