import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import Decimal from 'decimal.js';

import { FixedGrain } from '@features/cards/interfaces/fixedGrain';
import {
  GetFixedTokenizationsResponse,
  GetPreTokenizationsResponse,
} from '@features/tokenizations';

import {
  ECurrencyId,
  ExtendedWarranty,
  IGrainContract,
  Warranty,
} from '../common/types';
import { http } from '../http-common';

export const getGrainContracts = (): Promise<IGrainContract[] | []> => {
  return http
    .get<IGrainContract[]>(`/${PLATFORM_PREFIX}/grain-contracts`)
    .then((resp) => {
      const dataResult: IGrainContract[] | [] = resp.data;
      return dataResult;
    });
};

export const getWarranties = (): Promise<Warranty[] | []> => {
  return http
    .get<any[]>(`/${PLATFORM_PREFIX}/grain-contracts/business/warranties`)
    .then((resp) => {
      const dataResult: Warranty[] | [] = resp.data;
      return dataResult;
    });
};

export const getGrainContractsForExcel = (): Promise<any> => {
  return http
    .get<IGrainContract[]>(`/${PLATFORM_PREFIX}/grain-contracts/historic`, {
      responseType: 'arraybuffer',
    })
    .then((resp) => {
      const dataResult: any = resp.data;
      return dataResult;
    });
};

export const getValidFutureGrainContractsByFiscalId = (
  farmerFiscalId: string
): Promise<IGrainContract[] | []> => {
  const futures = http
    .get<IGrainContract[]>(
      `/${PLATFORM_PREFIX}/grain-contracts/fiscal-id/${farmerFiscalId}/futures`
    )
    .then((resp) => {
      const dataResult: IGrainContract[] | [] = resp.data;
      return dataResult;
    });
  return futures;
};

export const getValidFixedGrainContractsByFiscalId = (
  farmerFiscalId: string
): Promise<FixedGrain[] | []> => {
  const fixedGrain = http
    .get<FixedGrain[]>(
      `/${PLATFORM_PREFIX}/grain-contracts/fiscal-id/${farmerFiscalId}/fixed-grain`
    )
    .then((resp) => {
      const dataResult: FixedGrain[] | [] = resp.data;
      return dataResult;
    });
  return fixedGrain;
};

export const getGrainContract = ({
  queryKey,
}: any): Promise<IGrainContract> => {
  const [, id] = queryKey;
  return http
    .get<IGrainContract>(`/${PLATFORM_PREFIX}/grain-contracts/${id}`)
    .then((resp) => {
      const dataResult: IGrainContract = resp.data;
      return dataResult;
    });
};

export const getGrainContractForTransfer = ({
  queryKey,
}: any): Promise<IGrainContract> => {
  const [, id] = queryKey;
  return http
    .get<IGrainContract>(`/${PLATFORM_PREFIX}/transfers/grain-contract/${id}`)
    .then((resp) => {
      const dataResult: IGrainContract = resp.data;
      return dataResult;
    });
};

export const getWarranty = ({ queryKey }: any): Promise<ExtendedWarranty> => {
  const [, id, origin, fiscalId] = queryKey;
  const params = { origin: origin, fiscalId: fiscalId };

  return http
    .get<ExtendedWarranty>(
      `/${PLATFORM_PREFIX}/grain-contracts/warranty/${id}`,
      { params }
    )
    .then((resp) => {
      const dataResult: ExtendedWarranty = resp.data;
      return dataResult;
    });
};

export const createGrainContract = async (
  grainContractData: any
): Promise<IGrainContract | undefined> => {
  try {
    const resp = await http.post<IGrainContract>(
      `/${PLATFORM_PREFIX}/grain-contracts`,
      grainContractData
    );
    const dataResult: IGrainContract | undefined = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const updateGrainContract = async (
  data: any
): Promise<IGrainContract | undefined> => {
  const resp = await http.patch<IGrainContract>(
    `/${PLATFORM_PREFIX}/grain-contracts/${data.id}`,
    data.body
  );
  const dataResult: IGrainContract = resp.data;
  return dataResult;
};

export const sendGrainContractRequest = async (
  id: number
): Promise<IGrainContract | undefined> => {
  const resp = await http.patch<IGrainContract>(
    `/${PLATFORM_PREFIX}/grain-contracts/${id}/send-request`
  );
  const dataResult: IGrainContract = resp.data;
  return dataResult;
};

export const detokenizeGrainContract = async (
  data: any
): Promise<IGrainContract | undefined> => {
  const resp = await http.post<IGrainContract>(
    `/${PLATFORM_PREFIX}/grain-contracts/${data.id}/detokenize`,
    data.body
  );
  const dataResult: IGrainContract = resp.data;
  return dataResult;
};

export const deleteGrainContract = async (
  id: number
): Promise<IGrainContract | undefined> => {
  const resp = await http.delete<IGrainContract>(
    `/${PLATFORM_PREFIX}/grain-contracts/${id}`
  );
  const dataResult: IGrainContract = resp.data;
  return dataResult;
};

export const findAmountToCoverPayment = async (
  merchantBusinessUserId: number,
  currencyId: ECurrencyId,
  userUuid: string,
  paymentRequestUuid: string
): Promise<IGrainContract> => {
  return http
    .get<IGrainContract>(
      `/${PLATFORM_PREFIX}/grain-contracts/total-amount/oracle/${merchantBusinessUserId}/currency/${currencyId}/user/${userUuid}/payment-request/${paymentRequestUuid}`
    )
    .then((resp) => {
      const dataResult: IGrainContract = resp.data;
      return dataResult;
    });
};

export const createAndViewDocusign = async (data: {
  body: object;
  docusignTypeId: number;
}): Promise<any | undefined> => {
  const resp = await http.post<any>(
    `/${PLATFORM_PREFIX}/docusign/create-view/${data.docusignTypeId}`,
    data.body
  );
  const dataResult: any | undefined = resp.data;
  return dataResult;
};

export const getWarrantyPricePerSaca = (
  warrantyId: number
): Promise<Decimal> => {
  return http
    .get<Decimal>(
      `/${PLATFORM_PREFIX}/grain-contracts/warranties/${warrantyId}/price-per-saca`
    )
    .then((resp) => {
      return resp.data;
    });
};

export const getPreTokenizations = async (): Promise<
  GetPreTokenizationsResponse[] | undefined
> => {
  try {
    const { data } = await http.get<GetPreTokenizationsResponse[]>(
      `/${PLATFORM_PREFIX}/grain-contracts/pre-tokenized`
    );

    return data;
  } catch (error) {
    // TODO: Handle error
  }
};

export const getFixedTokenizations = async (): Promise<
  GetFixedTokenizationsResponse[] | undefined
> => {
  try {
    const { data } = await http.get<GetFixedTokenizationsResponse[]>(
      `/${PLATFORM_PREFIX}/grain-contracts/business/fixed-grain`
    );

    return data;
  } catch (error) {
    // TODO: Handle error
  }
};
