import { v4 as uuidv4 } from 'uuid';

import { PAYMENT_FORWARDINGS_DEFAULT_VALUE } from '@comp/PaymentRequestView/CreatePaymentRequest/constants';
import { PaymentForwardingFormData } from '@comp/PaymentRequestView/CreatePaymentRequest/types';

const M2M_MAX_FORWARDINGS = 2; // TODO: Get this value from parameters

interface UseForwardPaymentParams {
  forwardings: PaymentForwardingFormData[];
  setForwardings: React.Dispatch<
    React.SetStateAction<PaymentForwardingFormData[]>
  >;
}

const useForwardPayment = ({
  forwardings,
  setForwardings,
}: UseForwardPaymentParams) => {
  const isAddForwardingDisabled = forwardings.length >= M2M_MAX_FORWARDINGS;

  const onChangeIsForwardingCheckboxHandler = () => {
    if (forwardings.length > 0) {
      setForwardings([]);
    } else {
      setForwardings([
        { ...PAYMENT_FORWARDINGS_DEFAULT_VALUE, uuid: uuidv4() },
      ]);
    }
  };

  const onClickAddNewForwardingHandler = () => {
    if (isAddForwardingDisabled) return;

    setForwardings([
      ...forwardings,
      { ...PAYMENT_FORWARDINGS_DEFAULT_VALUE, uuid: uuidv4() },
    ]);
  };

  return {
    isForwarding: forwardings.length > 0,
    onChangeIsForwardingCheckboxHandler,
    onClickAddNewForwardingHandler,
    isAddForwardingDisabled,
  };
};

export default useForwardPayment;
