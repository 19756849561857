import { FeaturedIcons } from '@agrotoken-tech/ui';
import { Button } from '@chakra-ui/react';
import { FC, HTMLAttributes, ReactNode } from 'react';
import { type IconType } from 'react-icons';
import { TColor, TSize } from '../../Global/types';
import { Checkbox } from '../Checkbox';
import { Icon } from '../Icon';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  title?: string;
  text?: string;
  iconName?: string;
  icon?: IconType;
  iconColor?: TColor;
  center?: boolean;
  labelBtnClose?: string;
  labelBtnConfirm?: string;
  onClickClose?: () => void;
  onClickConfirm?: () => void;
  onClickContent?: Element;
  isLoading?: boolean;
  children?: ReactNode;
  checkboxLabel?: string;
  checkboxValue?: string;
  checkboxOnChange?: () => void;
  variant?: string;
  hidden?: boolean;
  cancelButtonHidden?: boolean;
  error?: string;
  size?: TSize;
  form?: string;
  disabled?: boolean;
  noSpaceAdded?: boolean;
  confirmButtonFill?: boolean;
  cancelButtonFill?: boolean;
}
export const Modal: FC<Props> = ({
  isOpen,
  title,
  text,
  iconName,
  icon,
  iconColor = 'success',
  center,
  labelBtnClose = 'Cancelar',
  labelBtnConfirm = 'Confirmar',
  onClickClose,
  onClickConfirm,
  isLoading,
  children,
  checkboxLabel = '',
  checkboxValue,
  checkboxOnChange,
  variant,
  hidden,
  cancelButtonHidden,
  error,
  size = 'lg',
  form,
  disabled,
  noSpaceAdded,
  confirmButtonFill = false,
  cancelButtonFill = false,
}) => {
  if (!isOpen) return null;

  return (
    <div className="relative">
      <div className="fixed inset-0 bg-[#34405499] overflow-auto z-40 h-screen flex items-end sm:items-center justify-center">
        <div
          className={`p-6 bg-white w-full h-fit mx-4 mb-20 sm:my-auto rounded-md z-50 shadow-xl ${
            checkboxLabel && checkboxValue && checkboxOnChange
              ? 'max-w-xl'
              : 'max-w-md'
          } ${center && 'text-center'} ${
            checkboxLabel && checkboxValue && checkboxOnChange
              ? 'text-center sm:text-left'
              : ''
          }`}
        >
          <div
            className={`${
              checkboxLabel && checkboxValue && checkboxOnChange
                ? 'sm:flex sm:gap-x-6'
                : ''
            }`}
          >
            {iconName && (
              <Icon
                size={size}
                color={iconColor}
                theme="light-circle-outline"
                icon={iconName}
                className="mb-5"
              />
            )}
            {icon && (
              <div className="mb-5">
                <FeaturedIcons
                  shape="circle"
                  variant="success"
                  size={'md'}
                  icon={icon}
                />
              </div>
            )}
            <div
              className={`whitespace-pre-line flex flex-col ${
                noSpaceAdded ? '' : 'gap-y-4'
              }`}
            >
              <h2 className="text-lg font-medium text-gray-900 mb-2">
                {title}
              </h2>
              <p className=" text-md font-normal text-gray-700">{text}</p>
              {children}
              <div
                className={`mt-8 ${
                  checkboxLabel && checkboxValue && checkboxOnChange
                    ? 'sm:flex items-center justify-between'
                    : ''
                }`}
              >
                {checkboxLabel && checkboxValue && checkboxOnChange && (
                  <Checkbox
                    name={checkboxValue}
                    label={checkboxLabel}
                    onChange={checkboxOnChange}
                    value={checkboxValue}
                    className="hidden sm:flex"
                  />
                )}
                <div className="flex gap-3 flex-wrap sm:flex-nowrap">
                  <Button
                    disabled={disabled}
                    variant={variant ? variant : 'primary'}
                    size={'lg'}
                    type="submit"
                    className={`${
                      checkboxLabel && checkboxValue && checkboxOnChange
                        ? 'w-full sm:w-auto'
                        : ''
                    } ${hidden ? 'w-fit' : 'w-full'} sm:order-last`}
                    isLoading={isLoading}
                    onClick={onClickConfirm}
                    form={form}
                    width={confirmButtonFill ? '100%' : 'auto'}
                  >
                    {labelBtnConfirm}
                  </Button>
                  {!cancelButtonHidden && (
                    <Button
                      variant="secondary"
                      size="lg"
                      type="button"
                      className={`${
                        checkboxLabel && checkboxValue && checkboxOnChange
                          ? 'w-full sm:w-auto'
                          : hidden
                          ? 'hidden'
                          : ''
                      } w-full sm:order-first`}
                      onClick={onClickClose}
                      disabled={isLoading}
                      width={cancelButtonFill ? '100%' : 'auto'}
                    >
                      {labelBtnClose}
                    </Button>
                  )}
                </div>
              </div>
              {error && (
                <p className="text-sm text-error-500 font-normal mt-1">
                  {error}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
