import { FULL_ONBOARDING_LEVEL_2, HOME_PATH } from '@src/common/const';
import { useAccess } from '@src/context/accessContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@comp/Global/Modal';
import { FiUserCheck } from 'react-icons/fi';

const RequirePaymentAccess: FC = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canMakePayments, isRepresentant } = useAccess();

  if (!canMakePayments) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (!isRepresentant) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_LEVEL_2)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  return <>{children}</>;
};

export default RequirePaymentAccess;
