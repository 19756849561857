export const statusWithColors = {
  created: { color: 'primary', text: 'Creado' },
  collateralized: { color: 'blue', text: 'Colateralizado' },
  active: { color: 'success', text: 'Activo' },
  cancelled: { color: 'error', text: 'Cancelado' },
  paid_fiat_due: { color: 'success', text: 'Pago en moneda local realizado' },
  paid_token_due: { color: 'success', text: 'Pago en tokens realizado' },
  awaiting_user_authorization: {
    color: 'info',
    text: 'Confirmación anticipada de pago en tokens',
  },
  paid_low_collateral: {
    color: 'success',
    text: 'Pago bajo colateral',
  },
  paid_fiat_early: {
    color: 'success',
    text: 'Pago anticipado en moneda local',
  },
  paid_token_early: { color: 'success', text: 'Pago anticipado en tokens' },
  paid_token_low_collateral: {
    color: 'warning',
    text: 'Pago en tokens con colateral bajo',
  },
  finished: { color: 'blue-gray', text: 'Finalizado' },
  fix_payment: { color: 'orange', text: 'Corregir pago' },
  expired: { color: 'orange', text: 'Expirado' },
} as const;
