import { ENationalities } from '@agrotoken/common-utils';
import { Box, Flex, Text } from '@chakra-ui/react';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { TokenIcon } from '@comp/Global/TokenIcon';
import { ETokensAR, ETokensBR, TCurrencyName } from '../../../common/types';
import { useAuth } from '../../../context/authContext';

interface TokenProps {
  token: ETokensAR | ETokensBR;
}

const TOKENS_FOR_REGION = {
  [ENationalities.AR]: [ETokensAR.SOYA, ETokensAR.CORA, ETokensAR.WHEA],
  [ENationalities.BR]: [ETokensBR.SOYB, ETokensBR.CORB],
};

const Token: VFC<TokenProps> = (props) => {
  const { token } = props;

  return (
    <div className="flex items-center">
      <TokenIcon token={token as TCurrencyName} size="sm" className="mr-1" />
      <span className="text-gray-700 text-md font-normal">{token}</span>
    </div>
  );
};

export const Tokens: VFC = () => {
  const { nationality } = useAuth();
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Flex alignContent={'center'} alignItems={'center'}>
        <Flex gap={4}>
          {TOKENS_FOR_REGION[nationality].map((token) => {
            return <Token key={token} token={token} />;
          })}
        </Flex>
        <Text fontSize="sm" color="gray.700" ml="8px">
          {t('createPayment.form.asset.tokens')}
        </Text>
      </Flex>
    </Box>
  );
};
