import { TransfersTranslationsType } from '../es';

export const TRANSFERS_TRANSLATIONS_PT: TransfersTranslationsType = {
  transferWarrantyButton: 'Transferir garantia',
  createWarrantyTransfer: {
    heading: {
      title: 'Solicitar transferência de garantia',
      subtitle:
        'Transfira um valor de sua garantia para outros clientes Agrotoken',
      expiration: 'Solicitação expira em',
    },
    form: {
      errors: {
        fiscalId: {
          typeError: 'Este campo deve ser uma string alfanumérica',
          required: 'Este campo é obrigatório',
        },
        comments: {
          typeError: 'Este campo deve ser uma string alfanumérica',
        },
        amount: {
          typeError: 'Este campo deve ser um número',
          required: 'Este campo é obrigatório',
          moreThan0: 'A quantidade deve ser maior que 0',
          lessThan:
            'O valor total da transferência não pode ser maior do que o valor total da garantia.',
        },
        file: 'O tamanho do arquivo excede o permitido',
      },
      button: 'Solicitar',
      generalDataSection: {
        title: '1. Dados gerais',
        inputLabel: 'Para quem você deseja enviar a solicitação?',
        inputPlaceholder: 'Digite um número de {{fiscalPrefix}}',
        observationsLabel: 'Observações (opcional)',
        observationsPlaceholder: 'Digite uma observação ou comentário',
        observationsHintMessage: 'Digite um máximo de 90 caracteres.',
        dragAndDropLabel: 'Anexar arquivo (opcional)',
        dragAndDropPlaceholderLink: 'Clique aqui',
        dragAndDropDragFile: 'ou arraste o arquivo',
        dragAndDropFormat: 'Formatos permitidos: PDF (tamanho máximo: 20MB)',
        error: 'O CPF/CNPJ não existe',
        hintMessage: 'Usuário: ',
      },
      selectedWarrantyToTransfer: {
        title: '2. Garantia a transferir',
      },
      transferDataSection: {
        title: '3. Dados de transferência',
        inputLabel: 'Valor a ser transferido',
        inputPlaceholder: 'Digite o valor que deseja transferir',
        inputLeftAddon: 'BRL',
        error: 'A quantidade deve ser maior que 0',
      },
    },
  },
  warrantyBreadcrumb: {
    actual: {
      add: 'Solicitar transferência de garantia',
      detail: 'Transferência de garantia',
    },
    before: 'Garantia de {{assetName}} #{{requestNumber}}',
  },
  warrantySummary: {
    topTitle: 'Resumo:',
    transferAmount: 'Total da transferência',
    equivalence: 'Equivalencia',
    bottomTitle: 'Detalhamento:',
    fee: {
      text: 'Comissão da transação ({{feePercentage, number}}%)',
      tooltip: 'Debita-se dos tokens recebidos desta transferência',
    },
    total: 'Total',
    emptyState:
      'Ao preencher as informações, você poderá ver um resumo de sua solicitação',
    termsAndConditionsAccept: 'Li e aceito os',
    termsAndConditions: 'termos e condições',
    termsAndConditionsLink:
      'https://agrotoken.notion.site/Termos-e-Condi-es-Espec-ficas-de-Tokeniza-o-para-os-modelos-de-Gr-o-Produzido-e-Gr-o-em-Produ-o-a2ecbb030bd7458097e3790c51850de2',
    termsAndConditionsError: 'Você deve aceitar os termos e condições de uso.',
    request: 'Solicitar',
    errors: {
      unknown: 'Ocorreu um erro ao tentar criar a transferência',
    },
  },
  details: {
    errorMessages: {
      defaultError: 'Ocorreu um erro ao executar esta transação',
    },
    acceptModal: {
      title: 'Você tem certeza de que deseja aceitar a transferência?',
      text: 'Ao aceitar a transferência, a ação não poderá ser revertida.',
      labelBtnConfirm: 'Aceitar',
      labelBtnClose: 'Cancelar',
    },
    rejectModal: {
      title: 'Você tem certeza de que deseja recusar a transferência?',
      text: 'Ao recusar a transferência, a ação não poderá ser revertida nem poderá mais acessar os detalhes da garantia.',
      labelBtnConfirm: 'Recusar',
      labelBtnClose: 'Cancelar',
    },
    cancelModal: {
      title:
        'Você tem certeza de que deseja cancelar a solicitação de transferência?',
      text: 'Cancelando a solicitação de transferência, a ação não poderá ser revertida e o destinatário da transferência não poderá recebê-la. Caso deseje criar uma nova transferência com esta garantia, será necessário criá-la do início.',
      labelBtnConfirm: 'Cancelar transferência',
      labelBtnClose: 'Voltar',
    },
    buttons: {
      accept: 'Aceitar',
      reject: 'Recusar',
      cancel: 'Cancelar transferência',
    },
    heading: 'Transferência de garantia',
    badges: {
      cancelled: 'Cancelado',
      error: 'Error',
      expired: 'Expirado',
      pendingApproval: 'Pendente de confirmação',
      processing: 'Processamento em blockchain',
      rejected: 'Recusada',
      successful: 'Concluída',
    },
    operationDetail: {
      title: 'Detalhes da transação',
      topElementsTitle: 'Dados gerais',
      bottomElementsTitle: 'Dados de transferência',
      createdAt: 'Data de criação:',
      acceptedAt: 'Transferido: {{acceptedAt}}',
      transactionNumber: 'Número da transação:',
      business: {
        receiver: 'Receptor da transferência:',
        sender: 'Criador da transferência:',
      },
      warranty: {
        label: 'Garantia:',
        value: 'Garantia de {{assetName}} #{{requestNumber}}',
      },
      comment: 'Observações:',
      document: 'Arquivo anexado:',
      total: 'Total da transferência:',
    },
    blockchainState: {
      title: 'A transação está sendo processada na Blockchain.',
      subtitle: 'Este processo pode demorar alguns segundos.',
    },
    alerts: {
      sender: {
        cancelled: {
          text: 'Você cancelou a transfêrencia de garantia.',
        },
        expired: {
          text: 'O envio da transfêrencia expirou',
          supportText:
            'Pasaram 48 horas sem que o usuario receptor aceite a transfêrencia.',
        },
        rejected: {
          text: 'O usuario receptor recusou a transfêrencia.',
        },
      },
      receiver: {
        cancelled: {
          text: 'O usuario transmissor cancelou a transfêrencia.',
        },
        expired: {
          text: 'O envio da transfêrencia expirou',
          supportText: 'Pasaram 48 horas sem que você aceite a transfêrencia.',
        },
        rejected: {
          text: 'Você recusou a transfêrencia de garantia.',
        },
      },
    },
  },
  unauthorized: {
    heading: 'Esta transferência não foi atribuída à sua conta',
    text: 'Apenas o destinatário tem acesso à transferência. Entre em contato com a pessoa que enviou este link.',
    buttonLabel: 'Ir à página principal',
  },
};
