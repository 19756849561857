import {
  checkCnpjTypeAndLength,
  checkCpfTypeAndLength,
  checkCuitTypeAndLength,
  ENationalities,
} from '@agrotoken/common-utils';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { EBusinessStructureTypes } from '@common/types';
import { VFC } from 'react';
import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Watch } from '@comp/FullOnboarding/types';
import { TRegister } from '../types';
import { FormValues } from './GeneralInfoForm';
import { isFiscalIdAvailable } from '@services/Businesses';

interface Props {
  register: TRegister;
  errors: FormState<FormValues>['errors'];
  watch: Watch;
  businessType: EBusinessStructureTypes;
}

const FiscalIdField: VFC<Props> = ({
  register,
  errors,
  watch,
  businessType,
}) => {
  const { t } = useTranslation();
  const countryWatchKey = 'country';

  const isInvalidFiscalIdError = t(
    `softOnboarding.signUpScreen.form.fiscalIdValidationError.${businessType}.${watch(
      countryWatchKey
    )}`
  );

  const isRegisteredFiscalIdError = t(
    `softOnboarding.signUpScreen.form.businessAlreadyRegisteredError.${businessType}`
  );

  const fiscalIdValidations: Record<
    EBusinessStructureTypes,
    Record<ENationalities, (value: string) => boolean>
  > = {
    [EBusinessStructureTypes.COMPANY]: {
      [ENationalities.AR]: checkCuitTypeAndLength,
      [ENationalities.BR]: checkCnpjTypeAndLength,
    },
    [EBusinessStructureTypes.PERSON]: {
      [ENationalities.AR]: checkCuitTypeAndLength,
      [ENationalities.BR]: checkCpfTypeAndLength,
    },
  };

  const fiscalIdValidation =
    fiscalIdValidations[businessType as EBusinessStructureTypes][
      watch(countryWatchKey) as ENationalities
    ];

  return (
    <FormControl isInvalid={!!errors.businessId}>
      <FormLabel htmlFor="fiscalId">
        {t(
          `softOnboarding.signUpScreen.form.fiscalIdType.${businessType}.${watch(
            countryWatchKey
          )}`
        )}
      </FormLabel>
      <Input
        id="fiscalId"
        placeholder={t(
          `softOnboarding.signUpScreen.form.fiscalIdTypePlaceholder.${businessType}.${watch(
            countryWatchKey
          )}`
        )}
        h="12"
        {...register('businessId', {
          required: `${t('shared.required')}`,
          validate: {
            isValid: (value) => {
              return fiscalIdValidation(value) ? true : isInvalidFiscalIdError;
            },
            isNotRegistered: async (value) => {
              const isAvailable = await isFiscalIdAvailable(
                value,
                watch(countryWatchKey)
              );
              return isAvailable || isRegisteredFiscalIdError;
            },
          },
        })}
      />
      {errors.businessId && (
        <FormErrorMessage>{errors.businessId.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FiscalIdField;
