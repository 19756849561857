const addDot = (arr: string[]) => arr.map((str) => '.' + str);
const allowedDocuments = ['pdf'];
const jpgImages = ['jpg', 'jpeg'];
const pngImages = ['png'];
export const allowedImages = [...jpgImages, ...pngImages];
export const acceptedImagesMimeTypes = {
  'image/jpeg': addDot(jpgImages),
  'image/png': addDot(pngImages),
};
export const acceptedDocumentMimeTypes = {
  'application/pdf': addDot(allowedDocuments),
};
export const maxSizeFileMb = 20;
export const maxSizeFileBytes = maxSizeFileMb * 1000 * 1000;
