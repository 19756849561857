import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { IGeographicalDivision } from '@def/geographicalDivision';
import { http } from '@src/http-common';

export const getGeographicalDivisions = async (
  countryId?: number
): Promise<IGeographicalDivision[]> => {
  const countryParam = countryId ? `?countryId=${countryId}` : '';

  const { data } = await http.get<IGeographicalDivision[]>(
    `/${PLATFORM_PREFIX}/geographical-divisions${countryParam}`
  );
  return data;
};
