import { Button, Flex, Text, Image, Icon } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import NewCard from '@src/components/svgs/card-new.svg';
import { useCards } from '@src/context/cardsContext';
import { useAccess } from '@src/context/accessContext';
import GetItOnAppStore from '@src/assets/logos/getItOnAppStore.svg';
import GetItOnGooglePlay from '@src/assets/logos/getItOnGooglePlay.svg';
import { TextBox } from '../../components/TextBox';

const CanRequestCard = ({}) => {
  const { showCardShipmentForm, setShowCardShipmentForm } = useCards();
  const { isArUser } = useAccess();

  const { t } = useTranslation();
  return (
    <>
      <Flex
        fontFamily="Larsseit"
        fontStyle="normal"
        fontWeight="400"
        fontSize="16px"
        lineHeight="24px"
        color="gray.700"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Flex marginBottom="24px" alignItems="center">
          <FeatherIcon
            icon="check-circle"
            border="1.33726px solid gray.400"
            color="gray.400"
            size="16"
          />
          <Text marginLeft="9.33px">{t('card.detail.first')}</Text>
        </Flex>
        <Flex marginBottom="24px" alignItems="center">
          <FeatherIcon icon="check-circle" color="gray.400" size="16" />
          <Text marginLeft="9.33px">{t('card.detail.second')}</Text>
        </Flex>
        <Flex alignItems="center">
          <FeatherIcon icon="check-circle" color="gray.400" size="16" />
          <Text marginLeft="9.33px">{t('card.detail.third')}</Text>
        </Flex>
      </Flex>
      {isArUser ? (
        <Button
          padding="16px 28px"
          gap="8px"
          width="171px"
          height="60px"
          background="primary.300"
          border="1px solid primary.300"
          borderRadius="3px"
          marginY={8}
          onClick={() => setShowCardShipmentForm((prev: boolean) => !prev)}
        >
          <Image src={NewCard} />

          <Text
            fontFamily="Larsseit"
            fontStyle="normal"
            fontWeight="medium"
            fontSize="18px"
            lineHeight="28px"
            color="gray.900"
          >
            {t('card.applyNow')}
          </Text>
        </Button>
      ) : (
        <>
          <Flex gap={4} marginY={8}>
            <Image src={GetItOnAppStore} />
            <Image src={GetItOnGooglePlay} />
          </Flex>
          <TextBox
            icon="info"
            title={t('cards.cardsRequest.appRedirectionTitle')}
          />
        </>
      )}
    </>
  );
};

export default CanRequestCard;
