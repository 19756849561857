/* eslint-disable import/no-unresolved */
import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import {
  Box,
  Flex,
  Text,
  Divider,
  Button,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { formatNumber } from '@common/utils';
import { TOKENS_DEFAULT_DECIMALS } from '@src/common/const';
import { EUnits, TCurrencyName } from '@src/common/types';
import Feathericon from 'feather-icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useStepForm } from '@comp/Global/StepForm';
import { TokenIcon } from '@comp/Global/TokenIcon';
import { TransferTokensFormValues } from '@comp/TransferTokens';
import animationData from '../../../../assets/lotties/134-celebration-green.json';

const lottieOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const SuccessStep = () => {
  const { handleClose, formValues, setFormValues } =
    useStepForm<TransferTokensFormValues>();
  const { token, amount } = formValues;

  const [isLoadingSimulation, setIsLoadingSimulation] = useState(true);

  // This here is to give the user a better "transition" feeling
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoadingSimulation(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [setFormValues]);

  const parsedAmount = Number(amount);
  const exchange = 'Ripio';
  const wallet = '0x12skfjffeh3324343dbbfb';
  const perpetuityPercentage = 0;
  const { t } = useTranslation();

  const perpetuityAmount = roundValue({
    value: parsedAmount * (perpetuityPercentage / 100),
    // TODO: operation type should be defined for perpetuty amount in roundValue function
    operationType: EPaymentRequestOperationType.FARMER_FEE,
    scale: TOKENS_DEFAULT_DECIMALS,
  }).toNumber();

  const totalTokenAmount = parsedAmount - perpetuityAmount;

  return (
    <>
      {isLoadingSimulation && (
        <Center
          position="absolute"
          inset="0"
          bgColor="white"
          zIndex="popover"
          borderRadius="md"
        >
          <Spinner size="lg" speed="0.75s" color="primary.300" />
        </Center>
      )}
      <Flex justifyContent="center" marginTop="6">
        <Lottie
          options={lottieOptions}
          height={44}
          width={44}
          isClickToPauseDisabled
          isPaused={isLoadingSimulation}
        />
      </Flex>

      <Flex
        justifyContent="center"
        marginTop="5"
        fontSize="3xl"
        fontFamily="Larsseit-Medium"
        color="gray.900"
        alignItems="center"
      >
        <Box marginRight="2.5" marginBottom="1">
          <Feathericon icon="check-circle" size="20" />
        </Box>
        <Text marginRight="2">
          {formatNumber(totalTokenAmount, EUnits.CRYPTO)}
        </Text>
        <Text>{token}</Text>
      </Flex>

      <Text
        textAlign="center"
        marginTop="1"
        fontFamily="Larsseit"
        fontSize="lg"
        color="gray.700"
      >
        {t('transferTokens.successStep.title')}
      </Text>

      <Flex
        marginTop="6"
        justifyContent="space-between"
        fontFamily="Larsseit"
        fontSize="md"
      >
        <Text>{t('transferTokens.successStep.subtotal')}</Text>
        <Flex alignItems="center">
          <Text marginRight="2">
            {formatNumber(parsedAmount, EUnits.CRYPTO)}
          </Text>

          <TokenIcon token={token as TCurrencyName} size="sm" />
          <Text ml="1">{token}</Text>
        </Flex>
      </Flex>

      <Flex
        marginTop="2"
        justifyContent="space-between"
        fontFamily="Larsseit"
        fontSize="md"
      >
        <Text>{t('transferTokens.successStep.ipp')}</Text>
        <Flex alignItems="center">
          <Text marginRight="2">
            - {formatNumber(perpetuityAmount, EUnits.CRYPTO)}{' '}
          </Text>

          <TokenIcon token={token as TCurrencyName} size="sm" />
          <Text ml="1">{token}</Text>
        </Flex>
      </Flex>

      <Divider marginTop="6" background="gray.200" height="1px" />

      <Flex marginTop="6" justifyContent="space-between">
        <Text fontFamily="Larsseit-Medium" fontSize="xl">
          {t('transferTokens.successStep.total')}
        </Text>
        <Flex alignItems="center">
          <Text marginRight="2" fontFamily="Larsseit-Medium" fontSize="xl">
            {formatNumber(totalTokenAmount, EUnits.CRYPTO)}
          </Text>

          <TokenIcon token={token as TCurrencyName} size="sm" />
          <Text ml="1">{token}</Text>
        </Flex>
      </Flex>

      <Flex
        marginTop="2"
        justifyContent="space-between"
        fontFamily="Larsseit"
        fontSize="md"
      >
        <Text>{t('transferTokens.successStep.exchange')}</Text>
        <Text>{exchange}</Text>
      </Flex>

      {/* <Flex
				marginTop="2"
				justifyContent="space-between"
				fontFamily="Larsseit"
				fontSize="md"
			>
				<Text>{t('transferTokens.successStep.wallet')}</Text>
				<Text>{wallet}</Text>
			</Flex> */}

      <Button
        variant="primary"
        size="lg"
        marginTop="6"
        isFullWidth
        onClick={handleClose}
      >
        {t('transferTokens.successStep.btnLabel')}
      </Button>

      <Text marginTop="4" fontFamily="Larsseit" fontSize="md" color="gray.700">
        {t('transferTokens.successStep.sentMail')}
      </Text>
    </>
  );
};
