import image from '../assets/images/login.png';

const LoginLayout: React.FC = (props) => {
  const { children } = props;

  return (
    <>
      <div className="flex h-screen">
        <div className="max-w-sm m-auto px-4">{children}</div>
        <div className="hidden lg:block w-1/2">
          <img
            src={image}
            alt="Log In"
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    </>
  );
};

export default LoginLayout;
