import { useMutation } from 'react-query';

import { createTransfer } from '@services/transfers/createTransfer';

import { CreateTransferDto } from '../interfaces';

const useCreateTransfer = () => {
  const { mutate, isLoading, error } = useMutation(
    ['transfer', 'create'],
    (body: CreateTransferDto) => createTransfer(body)
  );

  return { mutate, isLoading, error };
};

export default useCreateTransfer;
