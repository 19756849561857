import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { EDocumentTypes } from '@src/common/types';
import { VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TYPE_OF_LEGAL_POWER_DOC } from './consts';

interface Props {
  defaultValue:
    | EDocumentTypes.LEGAL_POWER
    | EDocumentTypes.STATUTE_OR_SOCIAL_CONTRACT;
}

const TypeOfDocument: VFC<Props> = ({ defaultValue }) => {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <RadioGroup mt="4" defaultValue={String(defaultValue)} defaultChecked>
      <Stack>
        <Text fontSize="sm" fontWeight="medium" mb="1.5">
          {t('fullOnboarding.lvl_2.KYC.representativeDocs.label')}
        </Text>
        <Radio
          size="md"
          value={String(EDocumentTypes.LEGAL_POWER)}
          colorScheme="primary"
          {...register(TYPE_OF_LEGAL_POWER_DOC, { valueAsNumber: true })}
        >
          {t('fullOnboarding.lvl_2.KYC.representativeDocs.options.power')}
        </Radio>
        <Radio
          size="md"
          value={String(EDocumentTypes.STATUTE_OR_SOCIAL_CONTRACT)}
          colorScheme="primary"
          {...register(TYPE_OF_LEGAL_POWER_DOC, { valueAsNumber: true })}
        >
          {t(
            'fullOnboarding.lvl_2.KYC.representativeDocs.options.socialContractAndDesignationOfAuthorities'
          )}
        </Radio>
      </Stack>
    </RadioGroup>
  );
};

export default TypeOfDocument;
