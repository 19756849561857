import {
  ENationalities,
  ISOCurrency,
  ISO_CURRENCIES,
} from '@agrotoken/common-utils';
import { useAuth } from '@src/context/authContext';

export const useGetLocalCurrency = () => {
  const { nationality } = useAuth();

  const localCurrency: ISOCurrency =
    nationality === ENationalities.AR ? ISO_CURRENCIES.ARS : ISO_CURRENCIES.BRL;

  return { localCurrency };
};

export default useGetLocalCurrency;
