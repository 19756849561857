import React, { FC, HTMLAttributes, ReactNode } from 'react';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  email: string;
  subject: string;
  body?: string;
  children?: ReactNode;
  className?: string;
}

export const Mailto: FC<Props> = ({
  email,
  subject = '',
  body = '',
  children,
  className,
}) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return (
    <a className={className} href={`mailto:${email}${params}`}>
      {children}
    </a>
  );
};
