import { getMultiRegionMap } from '@agrotoken/common-utils';
import { Card } from '@agrotoken-tech/ui';
import React, { Suspense, FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@src/context/authContext';
import {
  RealTokenizationStep,
  IGrainContract,
  FutureTokenizationStep,
} from '../../../common/types';
import { Box } from '@chakra-ui/react';

const GrainContractDetails_AR = React.lazy(
  () => import('../../AR/Global/GrainContractDetails')
);
const GrainContractDetails_BR = React.lazy(
  () => import('../../BR/Global/GrainContractDetails')
);

const grainContractDetails = getMultiRegionMap({
  AR: GrainContractDetails_AR,
  BR: GrainContractDetails_BR,
});

export interface Props extends HTMLAttributes<HTMLDivElement> {
  grainContract: IGrainContract;
  currentStep: RealTokenizationStep | FutureTokenizationStep;
  cdeTons?: number;
}

export const GrainContractDetails: FC<Props> = ({
  grainContract,
  currentStep,
  cdeTons,
}) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const GrainContractDetailsComponent = grainContractDetails[nationality];

  // TODO: verificar que no tengan decimales las toneladas

  return (
    <Box marginTop="12">
      <Card variant="white">
        <h3 className="text-sm font-medium text-gray-500">
          {t('grainContractDetails.title')}
        </h3>
        <Suspense fallback={<div>Loading...</div>}>
          <GrainContractDetailsComponent
            grainContract={grainContract}
            currentStep={currentStep}
            cdeTons={cdeTons}
          />
        </Suspense>
      </Card>
    </Box>
  );
};
