import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { http } from '@src/http-common';

export const getNotifications = (businessId: string): Promise<any> => {
  return http
    .get<any>(`/${PLATFORM_PREFIX}/users/feed/${businessId}`)
    .then(({ data }) => {
      return {
        ...data,
        data: data.data.map(
          ({ blocks, sentAt, seenAt, data: notificationData }: any) => {
            return {
              title: blocks.find((block: any) => block.name === 'title')
                ?.content,
              body: blocks.find((block: any) => block.name === 'body')?.content,
              actionTitle: blocks.find(
                (block: any) => block.name === 'action-title'
              )?.content,
              actionUrl: blocks.find(
                (block: any) => block.name === 'action-url'
              )?.content,
              createdAt: sentAt,
              seenAt,
              data: notificationData,
            };
          }
        ),
      };
    });
};

export const markAllNotificationsAsRead = (
  userId: string,
  businessId: string
): Promise<any> => {
  return http.post<any>(
    `/${PLATFORM_PREFIX}/users/${userId}/notifications/batch/seen?businessId=${businessId}`
  );
};
