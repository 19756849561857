import { Box } from '@chakra-ui/react';
import SecondStep from '@src/features/cards/cardRequestProcess/SecondStep';
import { useCards } from '@src/context/cardsContext';
import FirstStep from './FirstStep';
import { useAccess } from '@src/context/accessContext';
import { FC } from 'react';

const CardRequestProcess = () => {
  const { showCardShipmentForm, setShowCardShipmentForm } = useCards();
  const { canRequestCard } = useAccess();

  return (
    <>
      <Box
        display={{ base: 'none', sm: 'flex' }}
        flexFlow="column"
        height="100%"
        justifyContent="center"
        flex="1 1 auto"
        paddingBottom="-2rem"
      >
        {showCardShipmentForm && canRequestCard ? (
          <SecondStep />
        ) : (
          <FirstStep />
        )}
      </Box>
    </>
  );
};

export default CardRequestProcess;
