import { ENationalities } from '@agrotoken/common-utils';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import type { ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import get from 'lodash.get';
import { createContext, ReactNode, useContext, useState } from 'react';
import { signUpUser } from '@services/Users';
import i18n, { languages } from '@src/translations/i18n';
import { AuthRegisterDto, SignUpDto } from '../common/types';

type CognitoUserGetSession = CognitoUser['getSession'];
type CognitoUserGetSessionF1Params = Parameters<CognitoUserGetSession>[0];

interface AuthContextType {
  userPool: CognitoUserPool;
  signIn(username: string, password: string): Promise<unknown>;
  signOut(): void;
  isAuth(): Promise<boolean>;
  getCurrentUser(): CognitoUser | null;
  signUp: (user: AuthRegisterDto) => Promise<CognitoUser>;
  signUpInCoreApi: (user: SignUpDto) => Promise<void>;
  confirmUser: (email: string, verification: string) => Promise<any>;
  resendUserConfirmation: (email: string) => Promise<any>;
  nationality: ENationalities;
  isBrazilRegion: boolean;
  changePassword: (newPassword: string) => Promise<any>;
}

const AuthContext = createContext<AuthContextType>(null!);

export const poolData: ICognitoUserPoolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
};

export const getCurrentUser = (): CognitoUser | null => {
  const userPool = new CognitoUserPool(poolData);
  return userPool.getCurrentUser();
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const userPool = new CognitoUserPool(poolData);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [sessionUserAttributes, setSessionUserAttributes] = useState();
  const [nationality, setNationality] = useState<ENationalities>(
    ENationalities.AR
  );

  const isAuth = (): Promise<boolean> => {
    return new Promise<any>((resolve, reject) => {
      const currentUser = getCurrentUser();
      if (!currentUser) {
        reject(null);
        return;
      }
      currentUser.getSession(function (
        err: Parameters<CognitoUserGetSessionF1Params>[0],
        session: Parameters<CognitoUserGetSessionF1Params>[1]
      ) {
        if (err) {
          reject(err);
          return;
        }
        if (session) {
          const decodePayload = session.getIdToken().decodePayload();
          const nationalityFromToken = get(
            decodePayload,
            'custom:country_code',
            ENationalities.AR
          );

          setNationality(nationalityFromToken);
          i18n.changeLanguage(
            languages[nationalityFromToken as ENationalities]
          );
          resolve(session.isValid());
        }
      });
    });
  };

  const signIn = (username: string, password: string) => {
    const user: CognitoUser = new CognitoUser({
      Username: username,
      Pool: new CognitoUserPool(poolData),
    });
    setCognitoUser(user);
    const authDetails: AuthenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: async (data: CognitoUserSession) => {
          const decodePayload = data.getIdToken().decodePayload();
          const nationalityFromToken = get(
            decodePayload,
            'custom:country_code',
            ENationalities.AR
          );
          setNationality(nationalityFromToken);
          resolve(data);
        },
        onFailure: (err: any) => {
          reject(err);
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          setSessionUserAttributes(userAttributes);
          reject('NEW_PASSWORD_REQUIRED');
        },
      });
    });
  };

  // TODO: Deprecate once user-invited flow is implemented.
  /**
   * @deprecated in favor of backend sign-up flow
   */
  const signUp = (authRegisterDto: AuthRegisterDto): Promise<CognitoUser> => {
    const {
      country,
      email,
      businessName,
      businessId,
      password,
      business_person_type_id,
      phoneTemp,
      givenName,
      lastName,
    } = authRegisterDto;
    const userPool = new CognitoUserPool(poolData);

    return new Promise((resolve, reject) => {
      return userPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({
            Name: 'custom:country_code',
            Value: String(country),
          }),
          new CognitoUserAttribute({
            Name: 'custom:business_structureid',
            Value: String(business_person_type_id),
          }),
          new CognitoUserAttribute({
            Name: 'custom:business_name',
            Value: String(businessName),
          }),
          new CognitoUserAttribute({
            Name: 'custom:business_id',
            Value: String(businessId),
          }),
          new CognitoUserAttribute({
            Name: 'given_name',
            Value: givenName,
          }),
          new CognitoUserAttribute({
            Name: 'family_name',
            Value: lastName,
          }),
          new CognitoUserAttribute({ Name: 'email', Value: email }),
          new CognitoUserAttribute({
            Name: 'custom:phone_temp',
            Value: phoneTemp || '',
          }),
        ],
        [],
        (err, result) => {
          if (!result) {
            reject(err);
          } else {
            resolve(result.user);
          }
        }
      );
    });
  };

  /**
   * Creates a user in core-api and cognito, including business/business-user if required
   */
  const signUpInCoreApi = async (signUpDto: SignUpDto): Promise<void> => {
    await signUpUser(signUpDto);
  };

  const signOut = () => {
    const currentUser: CognitoUser | null = getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
  };

  const confirmUser = (email: string, verification: string) => {
    const userPool = new CognitoUserPool(poolData);
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((success, error) => {
      const callback = (err: any, result: any) => {
        if (err) {
          error(err);
          return;
        }
        success(result);
      };

      user.confirmRegistration(verification, true, callback);
    });
  };

  const resendUserConfirmation = (email: string) => {
    const userPool = new CognitoUserPool(poolData);
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((success, error) => {
      const callback = (err: any, result: any) => {
        if (err) {
          error(err);
          return;
        }
        success(result);
      };

      user.resendConfirmationCode(callback);
    });
  };
  const changePassword = async (newPassword: string) => {
    return new Promise((resolve, reject) => {
      cognitoUser?.completeNewPasswordChallenge(
        newPassword,
        sessionUserAttributes,
        {
          onSuccess: (data) => resolve(data),
          onFailure: (err) => reject(err),
        }
      );
    });
  };

  const value = {
    userPool,
    isAuth,
    signIn,
    signOut,
    getCurrentUser,
    signUp,
    signUpInCoreApi,
    confirmUser,
    resendUserConfirmation,
    nationality,
    isBrazilRegion: nationality === ENationalities.BR,
    changePassword,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
