import { t } from 'i18next';
import { VFC } from 'react';
import { useQuery } from 'react-query';
import { IBusinessUser, IRepresentant } from '@common/types';
import { ServerError } from '@comp/Global/ServerError';
import { findRepresentantByBusinessUser } from '@services/BusinessUsers';
import LevelTwoCommon from '../common';
import { OnboardingLevel2Props } from '../types';
import RepresentativeDocs from './RepresentativeDocs';
import SocialContract from './SocialContract';
import UserAddressSection from './UserAddressSection';

const LevelTwoBusiness: VFC<OnboardingLevel2Props> = ({
  defaultValues,
  changeRequestMessages = [],
  selectedBusiness,
  currentUser,
}) => {
  const { data: representant, error } = useQuery<
    Record<string, never> | IRepresentant
  >(['findRepresentantByUserId'], findRepresentantByBusinessUser);

  if (error)
    return (
      <ServerError
        title={t('serverError.title')}
        text={(error as any)?.message}
      />
    );
  return (
    <LevelTwoCommon
      defaultValues={defaultValues}
      representativeSection={
        <RepresentativeDocs
          documents={
            defaultValues.legalPowerDocuments ??
            currentUser?.businessUsers[0]?.businessUserDocuments?.map(
              (d) => d?.document
            )
          }
        />
      }
      socialContractSection={
        <SocialContract
          documents={
            defaultValues.socialContractDocuments ?? selectedBusiness?.documents
          }
        />
      }
      userAddressSection={
        <UserAddressSection disabled={Boolean(representant?.id)} />
      }
      changeRequestMessages={changeRequestMessages}
      hasRepresentant={Boolean(representant?.id)}
      selectedBusiness={selectedBusiness}
    />
  );
};

export default LevelTwoBusiness;
