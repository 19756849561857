import get from 'lodash.get';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { IBusinessAddress } from '@comp/FullOnboarding/types';
import { getBusinessAddress } from '@services/BusinessAddress';
import { getGeographicalDivisions } from '@services/GeographicalDivision';
import { getCities } from '@services/Shared';

const registrationPrefix = 'businessAddress.';
export const useAddressFieldsEffects = ({
  businessAddressId,
  businessUserIsInvited,
  businessUserIsAdminInvited,
}: {
  businessAddressId?: number;
  businessUserIsInvited: boolean;
  businessUserIsAdminInvited: boolean;
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.lvl_2.form',
  });

  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext();

  const isFieldRequired = !businessUserIsInvited || businessUserIsAdminInvited;

  const addressRegistration = register(`${registrationPrefix}fiscalAddresses`, {
    required: isFieldRequired,
    maxLength: {
      value: 300,
      message: t('errors.maxLength', {
        max: 300,
      }),
    },
  });
  const postalCodeRegistration = register(`${registrationPrefix}postalCode`, {
    required: isFieldRequired,
  });
  const geographicalDivisionRegistration = register(
    `${registrationPrefix}geographicalDivisionId`,
    {
      valueAsNumber: true,
      required: isFieldRequired,
    }
  );
  const cityRegistration = register(`${registrationPrefix}cityId`, {
    valueAsNumber: true,
    required: isFieldRequired,
  });
  const shouldSetDefaultGeographicalDivisionId = useRef(true);
  const shouldSetDefaultCityId = useRef(true);
  const { data: businessAddress } = useQuery<IBusinessAddress | undefined>(
    ['businessAddress', businessAddressId],
    getBusinessAddress
  );

  const defaultFiscalAddress = get(
    businessAddress,
    addressRegistration.name,
    undefined
  );
  const defaultPostalCode = get(
    businessAddress,
    postalCodeRegistration.name,
    undefined
  );

  const geographicalDivisionId = watch(geographicalDivisionRegistration.name);

  const { data: geographicalDivisions, isLoading: isLoadingGeoDivisions } =
    useQuery(['geographicalDivisions'], async () => {
      return getGeographicalDivisions();
    });

  const { data: cities, isLoading: isLoadingCities } = useQuery(
    ['getCities', geographicalDivisionId],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      if (id) {
        return getCities(Number(id));
      }
    }
  );
  useEffect(() => {
    const shouldSetDefaultGeographicalDivision =
      businessAddress?.id &&
      geographicalDivisions &&
      businessAddress?.geographicalDivisionId &&
      shouldSetDefaultGeographicalDivisionId.current;

    if (shouldSetDefaultGeographicalDivision) {
      setValue(
        geographicalDivisionRegistration.name,
        businessAddress?.geographicalDivisionId
      );
      shouldSetDefaultGeographicalDivisionId.current = false;
    }
  }, [geographicalDivisions, cities, businessAddress?.id]);

  useEffect(() => {
    const shouldSetDefaultCity =
      businessAddress?.id &&
      businessAddress?.geographicalDivisionId &&
      businessAddress?.cityId &&
      geographicalDivisions &&
      cities &&
      shouldSetDefaultCityId.current;

    if (shouldSetDefaultCity) {
      setValue(cityRegistration.name, businessAddress.cityId);
      shouldSetDefaultCityId.current = false;
    }
  }, [geographicalDivisions, cities, businessAddress?.id]);

  useEffect(() => {
    if (businessAddress?.fiscalAddresses) {
      setValue(addressRegistration.name, businessAddress.fiscalAddresses);
    }
    if (businessAddress?.postalCode) {
      setValue(postalCodeRegistration.name, businessAddress.postalCode);
    }
  }, [businessAddress?.id]);

  return {
    isLoadingGeoDivisions,
    isLoadingCities,
    cities,
    geographicalDivisions,
    errors,
    geographicalDivisionRegistration,
    cityRegistration,
    addressRegistration,
    postalCodeRegistration,
    defaultFiscalAddress,
    defaultPostalCode,
  };
};
