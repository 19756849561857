import { ENationalities } from '@agrotoken/common-utils';
import { CoinsIcon } from '@agrotoken-tech/ui';
import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { FC, ReactNode, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import {
  FiCopy,
  FiFile,
  FiFileText,
  FiHash,
  FiMenu,
  FiMessageSquare,
  FiUser,
} from 'react-icons/fi';
import { PaymentRequestType } from '@common/enum';
import { PaymentRequestForwarding } from '@common/types/payment-request-forwarding';
import { formatNumber } from '@common/utils';
import { Icon } from '@comp/Global/Icon';
import { getDocumentUrl } from '@services/PaymentRequests';
import { useAuth } from '@src/context/authContext';
import {
  CryptoCurrencySymbol,
  EUnits,
  PAYMENT_REQUEST_STATUSES,
} from '../../../../common/types';
import { ItemIcon } from '../ItemIcon';
import PaymentRequestParameters from '../PaymentRequestParameters';
import PaymentRequestParametersDetailsForwardings from '../PaymentRequestParameters/Fragments/PaymentRequestParametersDetailsForwardings';
import { TokenAmount } from '../TokenAmount';
import { TokensAmount } from '../TokensAmount';

interface Props {
  paymentRequest: PaymentRequestForwarding;
}
export const BreakDown: FC<Props> = ({ paymentRequest }) => {
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
  const {
    id,
    toBusiness,
    document,
    warrantyMetadata,
    fixedGrainContractMetadata,
    operation,
    forwardingMetadata,
  } = paymentRequest;

  const { t } = useTranslation();
  const { nationality } = useAuth();

  let units: EUnits;
  let tokensAmount: any;
  // TODO : Mejorar esto
  if (nationality === ENationalities.AR) {
    units = EUnits.ARS;
  } else {
    units = EUnits.BRL;
  }

  if (
    paymentRequest?.type !== PaymentRequestType.FUTURE_WARRANTY &&
    !warrantyMetadata?.warrantyGrainContractId &&
    paymentRequest.amount
  ) {
    if (!operation) {
      tokensAmount = <TokensAmount amountFiat={paymentRequest.amount} />;
    } else {
      tokensAmount = (
        <TokenAmount
          amount={operation?.amount ?? 0}
          token={operation?.currency.name as CryptoCurrencySymbol}
        />
      );
    }
  } else {
    tokensAmount = t('payPaymentRequest.breakdown.warrantyId', {
      warrantyId: warrantyMetadata?.warrantyGrainContractId,
    });
  }
  const handleViewDocument = async () => {
    setIsLoadingUrl(true);
    try {
      const url = await getDocumentUrl(id, document.uuid);
      window.open(url, '_blank');
    } catch (error) {
      /**
       * TODO: handle error
       */
    } finally {
      setIsLoadingUrl(false);
    }
  };

  const getGeneralDataElements = (): ReactNode[] => {
    const generalDataElements: ReactNode[] = [];

    generalDataElements.push(
      <ItemIcon
        icon={FiHash}
        label={t('payPaymentRequest.breakdown.transactionNumber')}
        value={
          <>
            {paymentRequest.uuid}
            <CopyToClipboard text={paymentRequest.uuid}>
              <IconButton variant="link" aria-label="copy" icon={<FiCopy />} />
            </CopyToClipboard>
          </>
        }
        className="mb-4"
      />
    );

    generalDataElements.push(
      <ItemIcon
        icon={FiUser}
        label={t(
          `payPaymentRequest.breakdown.to.${
            operation?.createdAt ? 'paymentMake' : 'paymentSent'
          }`
        )}
        value={`${toBusiness.name} - ${toBusiness.fiscalId}`}
        className="mb-4"
      />
    );

    /* Warranty Id */
    warrantyMetadata && //TODO: Arreglar aca que rediriga a la garantia
      generalDataElements.push(
        <ItemIcon
          icon={FiFileText}
          label={t('payPaymentRequest.breakdown.warrantyLabel')}
          value={t('payPaymentRequest.breakdown.warranty', {
            grainContractFutureId:
              warrantyMetadata?.warrantyGrainContract.requestNumber,
          })}
          className="mb-4"
        />
      );

    fixedGrainContractMetadata &&
      generalDataElements.push(
        <ItemIcon
          icon={FiFileText}
          label={t('payPaymentRequest.breakdown.fixedGrainLabel')}
          value={t(
            `payPaymentRequest.breakdown.fixedGrain.${fixedGrainContractMetadata.fixedGrainContract.currency.name}`,
            {
              fixedGrainRequestNumber:
                fixedGrainContractMetadata.fixedGrainContract.requestNumber,
            }
          )}
          className="mb-4"
        />
      );

    generalDataElements.push(
      <ItemIcon
        icon={FiMessageSquare}
        label={<Text> {t('payPaymentRequest.breakdown.comments')}</Text>}
        value={<Box>{paymentRequest.description ?? ''}</Box>}
        className="mb-4"
        isBold={false}
      />
    );

    generalDataElements.push(
      <ItemIcon
        icon={FiFile}
        label={t('payPaymentRequest.breakdown.file')}
        value={
          paymentRequest?.document ? (
            <Flex className="mt-2">
              <Icon
                size="sm"
                color="gray"
                theme="no-background"
                icon="download"
              />
              <Button
                size="sm"
                variant="linkPrimary"
                onClick={handleViewDocument}
                isLoading={isLoadingUrl}
              >
                <Text fontWeight={'bold'} color="#288703">
                  {paymentRequest.document.originalName}
                </Text>
              </Button>
            </Flex>
          ) : null
        }
        className="mb-4"
      />
    );

    return generalDataElements;
  };

  const getPaymentDataElements = (): ReactNode[] => {
    const paymentDataElements: ReactNode[] = [];
    paymentDataElements.push(
      <ItemIcon
        icon={CoinsIcon as IconType}
        label={t('payPaymentRequest.breakdown.equivalence')}
        value={<Text>{formatNumber(paymentRequest.amount, units, true)}</Text>}
        className="mb-4"
      />
    );

    if (forwardingMetadata && forwardingMetadata?.forwardings?.length) {
      paymentDataElements.push(
        <ItemIcon
          icon={FiMenu}
          label={t('payPaymentRequest.breakdown.transactionType')}
          value={t('payPaymentRequest.breakdown.paymentWithForwarding')}
          className="mb-4"
        />
      );
      paymentDataElements.push(
        <PaymentRequestParametersDetailsForwardings
          units={EUnits.ARS}
          handleViewDocument={handleViewDocument}
          isLoadingDownloadLink={isLoadingUrl}
          forwardingMetadata={forwardingMetadata}
          isPaymentRequestCompleted={
            paymentRequest?.status === PAYMENT_REQUEST_STATUSES.COMPLETED
          }
        />
      );
    }
    return paymentDataElements;
  };

  return (
    <PaymentRequestParameters
      createAtBoundOperation={Number(operation?.createdAt ?? 0)}
      paymentRequestStatus={paymentRequest.status}
      createdAtPaymentRequest={Number(paymentRequest.createdAt)}
      generalDataElements={getGeneralDataElements()}
      paymentDataElements={getPaymentDataElements()}
    />
  );
};
