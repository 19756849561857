import { TokenCurrency } from '@agrotoken/common-utils';
import { Badge, Heading } from '@agrotoken-tech/ui';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Origin } from '@common/types';
import { TransactionExpirationTimer } from '@comp/TransactionExpirationTimer';

import { WarrantyTransferBreadcrumb } from '../../components';
import { TransferStatus, TRANSFER_STATUSES } from '../../types';
import { getBadgePropsByStatus } from '../utils';

interface WarrantyTransferDetailHeaderProps {
  status: TransferStatus;
  expirationTimestamp: number;
  isLoading: boolean;
  isSender: boolean;
  origin: Origin;
  assetName: TokenCurrency;
  requestNumber: number;
  isInBlockchain: boolean;
}

const WarrantyTransferDetailHeader = ({
  expirationTimestamp,
  status,
  isLoading,
  assetName,
  isSender,
  origin,
  requestNumber,
  isInBlockchain,
}: WarrantyTransferDetailHeaderProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'transfers.details' });
  const badgeProps = getBadgePropsByStatus(status, isInBlockchain);

  const isTransferPendingApproval =
    status === TRANSFER_STATUSES.PENDING_APPROVAL;

  const shouldShowBreadcrumb = !isLoading && isSender;
  const shouldShowTimer =
    !isInBlockchain && !isLoading && isTransferPendingApproval;

  return (
    <>
      {shouldShowBreadcrumb && (
        <Box mb="7">
          <WarrantyTransferBreadcrumb
            assetName={assetName}
            origin={origin}
            requestNumber={requestNumber}
            isTransferCreation={false}
          />
        </Box>
      )}
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'initial', md: 'center' }}
        columnGap="4"
        rowGap="2"
        mb="2"
      >
        <Heading size="md" color="gray.900" variant="medium">
          {t('heading')}
        </Heading>
        {!isLoading && (
          <Badge size="sm" colorScheme={badgeProps.colorScheme}>
            {t(badgeProps.label)}
          </Badge>
        )}
        {shouldShowTimer && (
          <TransactionExpirationTimer
            expirationTimestamp={expirationTimestamp}
          />
        )}
      </Flex>
    </>
  );
};

export default WarrantyTransferDetailHeader;
