import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@comp/Global/Icon';

export const EmptyState: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="p-10 min-h-[225px] flex items-center justify-center text-center border border-gray-200 bg-gray-50 rounded">
      <div>
        <Icon size="xl" color="gray" theme="light-circle" icon="refresh-cw" />
        <p className="mt-5 text-md text-gray-900 font-normal">
          {t('payPaymentRequest.emptyState')}
        </p>
      </div>
    </div>
  );
};
