export const EXTERNAL_CARD_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  BLOCKED: 'BLOCKED',
  DISABLED: 'DISABLED',
  EMBOSSED: 'EMBOSSED',
  CREATED: 'CREATED',
  PENDING_ACTIVATION: 'PENDING_ACTIVATION',
  PAUSED: 'PAUSED',
};

export type ExternalCardStatus = keyof typeof EXTERNAL_CARD_STATUS;
export const externalCardStatuses = Object.values(EXTERNAL_CARD_STATUS);

export const CARD_STATUS_UI = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PAUSED: 'PAUSED',
  DISABLED: 'DISABLED',
} as const;

export type CardStatusUi = keyof typeof CARD_STATUS_UI;
export const cardStatusesUi = Object.values(CARD_STATUS_UI);
