import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { IWarehouse } from '../common/types';
import { http } from '../http-common';

export const searchWarehouses = async ({
  queryKey,
}: any): Promise<IWarehouse[] | []> => {
  try {
    const [, searchDataValue] = queryKey;
    const resp = await http.get<IWarehouse[]>(
      `/${PLATFORM_PREFIX}/warehouses/search?name=${searchDataValue}`
    );
    const dataResult = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};
