import { ENationalities } from '@agrotoken/common-utils';
import { useQuery } from 'react-query';

import { GetFixedTokenizationsResponse } from '@features/tokenizations';
import { getFixedTokenizations } from '@services/GrainContracts';
import { useAuth } from '@src/context/authContext';

/**
 * Custom hook for fetching fixed tokenizations.
 * @returns {Object} An object containing isLoading, data, isError, and error.
 */

const useFixedTokenizations = () => {
  const { nationality } = useAuth();

  const query = useQuery<GetFixedTokenizationsResponse[] | undefined>(
    ['tokenizations', 'fixed-grain'],
    getFixedTokenizations,
    {
      enabled: nationality === ENationalities.BR,
    }
  );

  return query;
};

export default useFixedTokenizations;
