import { Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SignupNotice: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Box mt="6">
      <Button
        as={Link}
        to="/sign-up"
        variant="linkPrimary"
        size="sm"
        fontSize="md"
      >
        {t('login.signUpMessage')} &nbsp;
        {t('login.signUpLabel')}
      </Button>
    </Box>
  );
};

export default SignupNotice;
