import { Box, Heading, Text } from '@chakra-ui/react';
import { EUnits, IBalance, IConversions } from '@common/types';
import { formatNumber } from '@common/utils';
import { FC } from 'react';
import { TokenCardBackground } from '@comp/Global/TokenCard';
import { TokenIcon } from '@comp/Global/TokenIcon';

export interface Props {
  balance: IBalance;
}

const TokenCard: FC<Props> = ({ balance }) => {
  return (
    <Box width={{ base: '100%', sm: '33%' }} position="relative">
      <TokenCardBackground token={balance.name} />
      <Box left={0} padding={4} position="absolute" top={0}>
        <TokenIcon className="mb-3" token={balance?.symbol} size="xl" />
        <Text color="gray.600" size="xs">{`TOTAL ${balance?.name}`}</Text>
        <Heading mb="8" size="xs" variant="medium">{`${formatNumber(
          +balance.amount,
          EUnits.CRYPTO
        )}`}</Heading>
        <Text size="md">{`≈ ${
          balance.conversions.find(
            (item: IConversions) => item.unit === EUnits.ARS
          )?.amount
        } ${EUnits.ARS} `}</Text>
      </Box>
    </Box>
  );
};
export default TokenCard;
