import { createContext } from 'react';
import { useParams } from 'react-router-dom';

import { useCollateralizeLoanDetails } from '../hooks';
import { GetLoanCollateralizationDetailsResponse } from '../interfaces';

interface CollateralizeLoanDataContextType {
  collateralizeLoanDetails?: GetLoanCollateralizationDetailsResponse;
  isLoadingCollateralizeLoanDetails: boolean;
}

export const CollateralizeLoanDataContext =
  createContext<CollateralizeLoanDataContextType>(
    {} as CollateralizeLoanDataContextType
  );

interface CollateralizeLoanDataProviderProps {
  children?: JSX.Element | JSX.Element[];
}

const CollateralizeLoanDataProvider = ({
  children,
}: CollateralizeLoanDataProviderProps) => {
  const { loanUuid } = useParams();

  const {
    data: collateralizeLoanDetails,
    isLoading: isLoadingCollateralizeLoanDetails,
  } = useCollateralizeLoanDetails(loanUuid ?? '');

  return (
    <CollateralizeLoanDataContext.Provider
      value={{
        collateralizeLoanDetails,
        isLoadingCollateralizeLoanDetails,
      }}
    >
      {children}
    </CollateralizeLoanDataContext.Provider>
  );
};

export default CollateralizeLoanDataProvider;
