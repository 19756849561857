import { Box, Text } from '@chakra-ui/react';

interface TransactionFormCardProps {
  title: string;
  children: React.ReactNode;
}

/**
 * @name FormSectionCard
 * @description A card component used in segmented forms with different sections
 * @param {string} title The title for the form section
 * @param {React.ReactNode} children Children to display inside of the card
 * @returns {React.ReactElement} The react component
 */
const FormSectionCard = ({ children, title }: TransactionFormCardProps) => {
  return (
    <Box
      p="6"
      bgColor="white"
      border="1px"
      borderColor="gray.200"
      rounded="4px"
    >
      <Text mb="4" size="md" variant="medium" color="gray.900">
        {title}
      </Text>
      {children}
    </Box>
  );
};

export default FormSectionCard;
