import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import successImg from '../../assets/images/signUpSuccess.png';
import successImgMobile from '../../assets/images/signUpSuccesMobile.png';
import agrotoken from '../../assets/logos/logo_standard.svg';
import { Button, FeaturedIcons, Heading } from '@agrotoken-tech/ui';
import { BREAKPOINTS } from '@common/const';
import { Link } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';
import { useAuth } from '@src/context/authContext';
import { ENationalities } from '@agrotoken/common-utils';

const SignUpSuccess: FC = () => {
  const { t } = useTranslation();
  const { nationality } = useAuth();

  const isMobile = window.innerWidth < BREAKPOINTS.md;

  return (
    <div className="h-screen flex flex-col items-center justify-between">
      <div className="max-w-[400px]">
        <div className="flex flex-col items-center pt-24">
          <img src={agrotoken} alt="logo agrotoken" className="mb-16" />
          <FeaturedIcons
            shape="circle"
            variant="success"
            size="md"
            icon={FiCheckCircle}
          />
          <Heading
            className="mb-3 text-center display-sm font-semibold text-gray-900 mt-6"
            as={isMobile ? 'h6' : undefined}
            size={isMobile ? 'xs' : undefined}
          >
            {t('signUpSuccess.title')}
          </Heading>
          <p className="text-center text-md font-normal text-gray-500">
            {t('signUpSuccess.subtitle')}
          </p>
          <Button
            className="mt-4"
            variant="primary"
            size={isMobile ? 'lg' : 'md'}
            as={Link}
            to="/"
          >
            {t('signUpSuccess.btnLabel')}
          </Button>
        </div>
      </div>
      {isMobile ? (
        <div>
          <img src={successImgMobile} alt="tokens" className="object-cover" />
        </div>
      ) : (
        <div className="overflow-y-hidden hidden sm:block max-w-[850px]">
          <img src={successImg} alt="tokens" className="object-cover" />
        </div>
      )}
    </div>
  );
};

export default SignUpSuccess;
