import { ENationalities } from '@agrotoken/common-utils';
import { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { COMMON_QUERY_PARAMS } from '@common/constants';
import { Loader } from '@comp/Global/Loader';
import SignupNotice from '@comp/Login/SignupNotice';
import {
  getBusinessInviteDetailsByUuid,
  updateBusinessUserInvite,
} from '@services/BusinessInvites';
import image from '../../../assets/images/login.png';
import agrotoken from '../../../assets/logos/logo_standard.svg';
import {
  DELETED_INVITATION,
  JOIN_BUSINESS,
  SELECT_ORG,
} from '../../../common/const';
import {
  EBusinessInviteStatusId,
  IBusinessInviteDetails,
  INavigationLoginState,
} from '../../../common/types';
import { useAuth } from '../../../context/authContext';
import Form from './Form';

const LoginToBusiness: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [searchParams] = useSearchParams();
  const [_, setMutateError] = useState<string>('');
  const { t } = useTranslation();
  const auth = useAuth();
  const inviteUuid = searchParams.get(
    COMMON_QUERY_PARAMS.INVITE_UUID
  ) as string;
  const region = searchParams.get(COMMON_QUERY_PARAMS.REGION) as ENationalities;
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as INavigationLoginState;

  const {
    isLoading: _isLoadingInvite,
    isError: _isErrorInvite,
    data: businessInvite,
  } = useQuery<IBusinessInviteDetails | undefined>(
    ['businessInvite', region, inviteUuid],
    getBusinessInviteDetailsByUuid
  );

  useEffect(() => {
    if (!auth) return;
    auth.signOut();
  }, [auth]);

  const {
    mutate: mutateUpdateBusinessUserInvite,
    isLoading: isLoadingBusinessUser,
  } = useMutation(updateBusinessUserInvite, {
    onSuccess: () => {
      setMutateError('');
      if (state?.returnUrl) return navigate(state.returnUrl);
      navigate(SELECT_ORG);
    },
    onError: (err: Error) => {
      setMutateError(err.message);
    },
  });

  //TODO: Change condition to keycodes

  if (businessInvite?.statusId === EBusinessInviteStatusId.DENIED) {
    navigate(`${JOIN_BUSINESS}${DELETED_INVITATION}`);
  }

  const handleSubmit = (ev: any) => {
    ev.preventDefault();
    if (email && password) {
      setIsLoading(true);
      if (!auth) return;
      auth.signIn(email, password).then(
        (_: any) => {
          if (businessInvite) {
            if (businessInvite?.business?.fiscalId) {
              localStorage.setItem('cuit', businessInvite?.business?.fiscalId); // TODO: Refactor to a context.
            }

            mutateUpdateBusinessUserInvite({
              uuid: inviteUuid,
              region,
            });
          }

          setIsAuthorized(true);
          setIsLoading(false);

          navigate(SELECT_ORG);
        },
        () => {
          setIsAuthorized(false);
          setIsLoading(false);
          setPassword('');
        }
      );
    }
  };

  if (isLoadingBusinessUser) return <Loader />;

  return (
    <div className="flex h-screen">
      <div className="max-w-sm m-auto px-4">
        <div className="mb-8 w-full flex">
          <img
            src={agrotoken}
            alt="Logo Agrotoken"
            className="inline-block max-h-6"
          />
        </div>
        <div className="mb-8">
          <h1 className="display-md font-semibold text-gray-900 mb-2">
            {t('specialLogin.title', {
              name: businessInvite?.inviteeData?.businessName,
            })}
          </h1>
          <p className="text-md text-gray-500">{t('login.subtitle')}</p>
        </div>
        <Form
          onSubmit={handleSubmit}
          isLoading={isLoading}
          email={businessInvite?.inviteeData?.email ?? ''}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          isAuthorized={isAuthorized}
        />
        <SignupNotice />
      </div>
      <div className="hidden lg:block w-1/2">
        <img src={image} alt="Log In" className="object-cover w-full h-full" />
      </div>
    </div>
  );
};

export default LoginToBusiness;
