export * from './CoraSvg';
export * from './SoyaSvg';
export * from './UsdSvg';
export * from './WheaSvg';

export const tokenSizes = {
  sm: '16',
  md: '20',
  lg: '24',
  xl: '28',
};

export interface TokenSvgProps {
  size: 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
}
