export const TRANSFER_STATUSES = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PROCESSING: 'PROCESSING',
  SUCCESSFUL: 'SUCCESSFUL',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
} as const;

export type TransferStatus = keyof typeof TRANSFER_STATUSES;
export const transferStatuses = Object.values(TRANSFER_STATUSES);
