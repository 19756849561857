import { getMultiRegionMap } from '@agrotoken/common-utils';
import { Spinner } from '@chakra-ui/react';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, useLocation } from 'react-router-dom';
import LoansRouter from '@features/loans/LoansRouter';
import { SentryRoutes } from '@src/App';
import * as brasilAccessControl from '@src/components/BR/AccessControl';
import { CardsProvider } from '@src/context/cardsContext';
import { FullOnboardingAccessProvider } from '@src/context/fullOnboardingAccessContext';
import UserCardsContainer from '@src/features/cards/UserCardsContainer';
import TransfersRouter from '@src/features/transfers/router/TransfersRouter';
import {
  CARD_PATH,
  CREDITS_DETAIL_PATH,
  CREDITS_PATH,
  DELETED_INVITATION,
  DETOKENIZATIONS_PATH,
  FULL_ONBOARDING_BANKING_AND_BILLING,
  FULL_ONBOARDING_KYC,
  FULL_ONBOARDING_LEVEL_2,
  FULL_ONBOARDING_LEVEL_3,
  FULL_ONBOARDING_REPRESENTATIVE_DOCS,
  HOME_PATH,
  JOIN_BUSINESS,
  LOGIN_PATH,
  NOTIFICATIONS_PATH,
  PAYMENT_PATH,
  RESET_PASSWORD_PATH,
  SELECT_ORG,
  TOKENIZATIONS_PATH,
  USERS_PATH,
  WALLET_PATH,
  WARRANTY_PATH,
} from '../common/const';
import { EPaymentRequestType, INavigationLoginState } from '../common/types';
import ResetPasswordPage from '../components/ResetPassword';
import ResetPasswordVerificationPage from '../components/ResetPasswordVerification';
import { AccessProvider } from '../context/accessContext';
import { useAuth } from '../context/authContext';
import { RatesProvider } from '../context/ratesContext';
import { UserProvider } from '../context/userContext';
import * as argentinaAccessControl from './AccessControl';
import { AccountConfig } from './AccountConfig';
import { ProductionExpectation } from './BR/ProductionExpectation/ProductionExpectation';
import { CreditDetailsPage } from './CreditsDetailsPage';
import { CreditsPage } from './CreditsPage';
import FullOnboardingKYC from './FullOnboarding';
import BankingAndBilling from './FullOnboarding/BankingAndBilling';
import RepresentativeDocsPage from './FullOnboarding/RepresentativeDocsPage';
import OnboardingLevel2FormContainer from './FullOnboarding/v2/lvl-2';
import FullOnboardingLvl3 from './FullOnboarding/v2/lvl-3';
import { Loader } from './Global/Loader';
import { Home } from './Home';
import DeletedInvitation from './JoinBusiness/DeletedInvitation';
import LoginToBusiness from './JoinBusiness/LoginToBusiness';
import SignUpToBusiness from './JoinBusiness/signUpToBusiness';
import Layout from './Layout';
import Login from './Login';
import NotFound from './NotFound';
import { Notifications } from './Notifications';
import PaymentRequestView from './PaymentRequestView';
import CreatePaymentRequest from './PaymentRequestView/CreatePaymentRequest';
import { PaymentSent } from './PaymentRequestView/Payer/PaymentSent';
import ForwardingView from './PaymentRequestView/Receiver/Forwardings';
import PaymentRequests from './PaymentRequests';
import { ProofOfExistence } from './ProofOfExistence';
import ProofOfLiquidity from './ProofOfLiquidity';
import NewPasswordRequired from './ResetPasswordVerification/NewPasswordRequired';
import { ScrollToTop } from './ScrollToTop/ScrollToTop';
import SignUpSuccess from './SignUpSuccess';
import EmailConfirmationPage from './SoftOnboarding/EmailConfirmation';
import GeneralInfoPage from './SoftOnboarding/GeneralInfoForm';
import CreateNewBusiness from './SoftOnboarding/GeneralInfoForm/CreateNewBusiness';
import Success from './Success';
import DetokenizationSuccess from './Success/Detokenization';
import { Confirm } from './Tokenization/Confirm';
import { Summary } from './Tokenization/Summary';
import { Tokenizations } from './Tokenizations';
import Unauthorized from './Unauthorized';
import { Edition } from './User/Edition';
import { Invitation } from './User/Invitation';
import { Users } from './Users';
import { Wallet } from './Wallet';
import Warranty from './Warranty';

const NewTokenization = React.lazy(
  () => import('./NewTokenization/NewTokenization')
);

const accessControlByRegion = getMultiRegionMap({
  AR: argentinaAccessControl,
  BR: brasilAccessControl,
});

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (!auth) return;
    auth.isAuth().then(
      () => {
        setIsLoading(false);
        setIsAuth(true);
      },
      () => {
        setIsLoading(false);
        setIsAuth(false);
      }
    );
    return () => {
      setIsLoading(false);
      setIsAuth(false);
    };
    // I'm setting the dependency array  as [Boolean(auth)] since we just care if auth has a value
  }, [Boolean(auth)]);

  if (isLoading) return null;

  if (!isAuth) {
    const loginNavigationState = {
      returnUrl: location.pathname,
    } as INavigationLoginState;
    return <Navigate to={LOGIN_PATH} replace state={loginNavigationState} />;
  }
  return <>{children}</>;
}

const Content = () => {
  const { nationality } = useAuth();
  const accessControl = accessControlByRegion[nationality];
  const {
    RequireTokenizationAccess,
    RequireAdminAccess,
    RequireCreatePaymentAccess,
    RequireOnboardingLevelTwoAccess,
    RequireOnboardingLevelThreeAccess,
    RequirePaymentAccess,
    RequireHomeAccess,
    RequireCreditsAccess,
  } = accessControl;

  return (
    <BrowserRouter>
      <ScrollToTop>
        <SentryRoutes>
          <Route path={LOGIN_PATH} element={<Login />} />
          <Route
            path={`${JOIN_BUSINESS}/login`}
            element={<LoginToBusiness />}
          />
          <Route
            path={`${JOIN_BUSINESS}/sign-up`}
            element={<SignUpToBusiness />}
          />
          <Route
            path={`${JOIN_BUSINESS}${DELETED_INVITATION}`}
            element={<DeletedInvitation />}
          />
          <Route
            path={'/create-new-business'}
            element={
              <RequireAuth>
                <UserProvider>
                  <AccessProvider>
                    <CreateNewBusiness />
                  </AccessProvider>
                </UserProvider>
              </RequireAuth>
            }
          />
          <Route path="/sign-up" element={<GeneralInfoPage />} />
          <Route path={RESET_PASSWORD_PATH} element={<ResetPasswordPage />} />
          <Route
            path={`${RESET_PASSWORD_PATH}/verification`}
            element={<ResetPasswordVerificationPage />}
          />
          <Route
            path={`${RESET_PASSWORD_PATH}/new-password-required`}
            element={<NewPasswordRequired />}
          />

          <Route
            path="/email-confirmation/:encodedEmail"
            element={<EmailConfirmationPage />}
          />
          <Route path="/sign-up-success" element={<SignUpSuccess />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <RatesProvider>
                  <UserProvider>
                    <CardsProvider>
                      <AccessProvider>
                        <FullOnboardingAccessProvider>
                          <Layout />
                        </FullOnboardingAccessProvider>
                      </AccessProvider>
                    </CardsProvider>
                  </UserProvider>
                </RatesProvider>
              </RequireAuth>
            }
          >
            <Route
              path={FULL_ONBOARDING_KYC}
              element={
                <RequireOnboardingLevelTwoAccess>
                  <FullOnboardingKYC />
                </RequireOnboardingLevelTwoAccess>
              }
            />
            <Route
              path={FULL_ONBOARDING_LEVEL_2}
              element={
                <RequireOnboardingLevelTwoAccess>
                  <OnboardingLevel2FormContainer />
                </RequireOnboardingLevelTwoAccess>
              }
            />
            <Route
              path={FULL_ONBOARDING_REPRESENTATIVE_DOCS}
              element={<RepresentativeDocsPage />}
            />
            <Route
              path={SELECT_ORG}
              element={
                <div className="mb-12">
                  <Loader />
                </div>
              }
            />
            <Route
              path={FULL_ONBOARDING_BANKING_AND_BILLING}
              element={
                <RequireOnboardingLevelThreeAccess>
                  <BankingAndBilling />
                </RequireOnboardingLevelThreeAccess>
              }
            />
            <Route
              path={HOME_PATH}
              element={
                <RequireHomeAccess>
                  <Home />
                </RequireHomeAccess>
              }
            />
            <Route
              path={`${HOME_PATH}/account-settings`}
              element={
                <RequireHomeAccess>
                  <AccountConfig />
                </RequireHomeAccess>
              }
            />
            <Route path={WALLET_PATH} element={<Wallet />} />
            <Route path={CARD_PATH} element={<UserCardsContainer />} />
            <Route
              path={CREDITS_PATH}
              element={
                <RequireCreditsAccess>
                  <CreditsPage />
                </RequireCreditsAccess>
              }
            />
            <Route
              path={`${CREDITS_DETAIL_PATH}`}
              element={
                <RequireCreditsAccess>
                  <CreditDetailsPage />{' '}
                </RequireCreditsAccess>
              }
            />
            <Route
              path={`${WARRANTY_PATH}/:id/:origin`}
              element={<Warranty />}
            />
            <Route
              path={TOKENIZATIONS_PATH}
              element={
                <RequireTokenizationAccess>
                  <Tokenizations />
                </RequireTokenizationAccess>
              }
            />
            <Route path={NOTIFICATIONS_PATH} element={<Notifications />} />
            <Route
              path={`${TOKENIZATIONS_PATH}/:id`}
              element={
                <RequireTokenizationAccess>
                  <Summary />
                </RequireTokenizationAccess>
              }
            />
            <Route
              path={`${TOKENIZATIONS_PATH}/:id/new-tokenization`}
              element={
                <RequireTokenizationAccess>
                  <Suspense fallback={<Spinner />}>
                    <NewTokenization />
                  </Suspense>
                </RequireTokenizationAccess>
              }
            />
            <Route
              path={`${TOKENIZATIONS_PATH}/new-tokenization`}
              element={
                <RequireTokenizationAccess>
                  <Suspense fallback={<Spinner />}>
                    <NewTokenization />
                  </Suspense>
                </RequireTokenizationAccess>
              }
            />
            <Route
              path={`${TOKENIZATIONS_PATH}/:id/proof-of-existence`}
              element={
                <RequireTokenizationAccess>
                  <ProofOfExistence />
                </RequireTokenizationAccess>
              }
            />
            <Route
              path={`${TOKENIZATIONS_PATH}/:id/production-expectation`}
              element={
                <RequireTokenizationAccess>
                  <ProductionExpectation />
                </RequireTokenizationAccess>
              }
            />
            <Route
              path={`${TOKENIZATIONS_PATH}/:id/proof-of-liquidity`}
              element={
                <RequireTokenizationAccess>
                  <ProofOfLiquidity />
                </RequireTokenizationAccess>
              }
            />
            <Route
              path={`${TOKENIZATIONS_PATH}/:id/confirm`}
              element={
                <RequireTokenizationAccess>
                  <Confirm />
                </RequireTokenizationAccess>
              }
            />
            <Route
              path={`${TOKENIZATIONS_PATH}/:id/success`}
              element={
                <RequireTokenizationAccess>
                  <Success />
                </RequireTokenizationAccess>
              }
            />
            <Route
              path={USERS_PATH}
              element={
                <RequireAdminAccess>
                  <Users />
                </RequireAdminAccess>
              }
            />
            <Route
              path={`${USERS_PATH}/newUserInvite`}
              element={
                <RequireAdminAccess>
                  <Invitation />
                </RequireAdminAccess>
              }
            />
            <Route
              path={`${USERS_PATH}/:id`}
              element={
                <RequireAdminAccess>
                  <Edition />
                </RequireAdminAccess>
              }
            />
            <Route
              path={`${PAYMENT_PATH}`}
              element={
                <RequireCreatePaymentAccess>
                  <PaymentRequests />
                </RequireCreatePaymentAccess>
              }
            />
            <Route
              path={`${PAYMENT_PATH}/create-payment`}
              element={
                <RequireCreatePaymentAccess>
                  <CreatePaymentRequest />
                </RequireCreatePaymentAccess>
              }
            />
            <Route
              path={`${PAYMENT_PATH}/create-payment-in-kind`}
              element={
                <RequireCreatePaymentAccess>
                  <CreatePaymentRequest
                    paymentRequestType={EPaymentRequestType.IN_KIND}
                  />
                </RequireCreatePaymentAccess>
              }
            />
            <Route
              path={`${PAYMENT_PATH}/:uuid`}
              element={<PaymentRequestView />}
            />
            <Route
              path={`${PAYMENT_PATH}/forwardings/:uuid`}
              element={<ForwardingView />}
            />
            <Route
              path={FULL_ONBOARDING_LEVEL_3}
              element={
                <RequireOnboardingLevelThreeAccess>
                  <FullOnboardingLvl3 />
                </RequireOnboardingLevelThreeAccess>
              }
            />
            <Route
              path={`${PAYMENT_PATH}/:uuid/success`}
              element={
                <RequirePaymentAccess>
                  <PaymentSent />
                </RequirePaymentAccess>
              }
            />
            <Route
              path={`${DETOKENIZATIONS_PATH}/:id/success`}
              element={
                <RequireTokenizationAccess>
                  <DetokenizationSuccess />
                </RequireTokenizationAccess>
              }
            />
            {TransfersRouter}
            {LoansRouter}
          </Route>
          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Content;
