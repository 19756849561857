import { Flex, Text, Image, Box, useBreakpointValue } from '@chakra-ui/react';
import { FC } from 'react';
import { Card } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import VisaLogo from '@src/components/svgs/visa.svg';
import VisaLogoWhite from '@src/components/svgs/visaWhite.svg';
import { CARD_TYPE } from '../../types';
import CardDetail from './CardDetail';

interface Props {
  card: Card;
}

const CardListItem: FC<Props> = ({ card }) => {
  const { t } = useTranslation();

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  return (
    <>
      <Flex
        display={isMobile ? 'inline-block' : 'inline-flex'}
        justifyContent={'space-between'}
        key={card.id}
        paddingY={4}
        paddingX={3}
        borderBottom="1px solid"
        borderBottomColor="gray.200"
      >
        <Flex gap={3} align="center">
          <Image
            src={
              card.type.toUpperCase() === CARD_TYPE.PHYSICAL
                ? VisaLogo
                : VisaLogoWhite
            }
          />

          <Text fontSize="md" color="gray.900" whiteSpace={'nowrap'}>
            {t('cards.cardList.main.myCardDescription')}
            {card.lastFour}
          </Text>
        </Flex>

        <CardDetail card={card} />
      </Flex>
    </>
  );
};

export default CardListItem;
