import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { IPermission } from '../common/types';
import { http } from '../http-common';

export const getDisplayPermissions = (): Promise<IPermission[] | []> => {
  return http
    .get<IPermission[]>(`/${PLATFORM_PREFIX}/permissions?preload=true`)
    .then((resp) => {
      const dataResult: IPermission[] | [] = resp.data;
      return dataResult;
    });
};

export const getAllPermissions = (): Promise<IPermission[] | []> => {
  return http
    .get<IPermission[]>(`/${PLATFORM_PREFIX}/permissions`)
    .then((resp) => {
      const dataResult: IPermission[] | [] = resp.data;
      return dataResult;
    });
};
