import { VFC } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Flex } from '@chakra-ui/react';

const itemSize = {
  sm: '24px',
  md: '28px',
  lg: '32px',
};

const iconSizes = {
  check: { sm: 16, md: 18, lg: 20 },
  circle: { sm: 8, md: 10, lg: 12 },
  x: { sm: 12, md: 14, lg: 16 },
};

interface Props {
  icon: keyof typeof iconSizes;
  iconColor: string;
  iconBackgroundColor: string;
  size: keyof typeof itemSize;
}

const TimeLineIcon: VFC<Props> = ({
  icon,
  iconColor,
  iconBackgroundColor,
  size,
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      w={itemSize[size]}
      h={itemSize[size]}
      minH={itemSize[size]}
      color={iconColor}
      bgColor={iconBackgroundColor}
      rounded="full"
    >
      <FeatherIcon
        icon={icon}
        size={iconSizes[icon][size]}
        fill={icon === 'circle' ? 'currentColor' : 'none'}
      />
    </Flex>
  );
};

export default TimeLineIcon;
