import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import {
  FULL_ONBOARDING_BANKING_AND_BILLING,
  FULL_ONBOARDING_KYC,
  FULL_ONBOARDING_LEVEL_2,
  FULL_ONBOARDING_LEVEL_3,
} from '@src/common/const';
import {
  EBackOfficeValidationStatus,
  IBusiness,
  IBusinessUser,
  IUser,
} from '@src/common/types';

const onboardingLevelTwoPath = getMultiRegionMap({
  AR: FULL_ONBOARDING_KYC,
  BR: FULL_ONBOARDING_LEVEL_2, // we're starting v2 onboarding in Brazil
});
const onboardingLevelThreePath = getMultiRegionMap({
  AR: FULL_ONBOARDING_BANKING_AND_BILLING,
  BR: FULL_ONBOARDING_LEVEL_3,
});

export const determineIfShouldShowChangeRequestBanner = ({
  currentUser,
  selectedBusinessUser,
  business,
  navigate,
  selectedBusinessUserWasInvited,
  nationality,
}: {
  currentUser: IUser | null;
  selectedBusinessUser: IBusinessUser | null;
  business?: IBusiness;
  navigate: (path: string) => void;
  selectedBusinessUserWasInvited: boolean;
  nationality: ENationalities;
}): {
  shouldShowChangeRequestedBanner: boolean;
  navigateToOnboardingStepWithChangeRequests(): void;
} => {
  const onboardingLevelTwo = onboardingLevelTwoPath[nationality];
  const onboardingLevelThree = onboardingLevelThreePath[nationality];
  const userHasChangeRequest =
    currentUser?.validationStatusId ===
    EBackOfficeValidationStatus.CHANGE_REQUEST;

  const businessUserHasChangeRequest =
    selectedBusinessUser?.validationStatusId ===
    EBackOfficeValidationStatus.CHANGE_REQUEST;

  const businessAddressHasChangeRequest =
    business?.businessAddress?.validationStatusId ===
    EBackOfficeValidationStatus.CHANGE_REQUEST;

  const hasChangeRequestOnKYC =
    userHasChangeRequest ||
    businessUserHasChangeRequest ||
    businessAddressHasChangeRequest;

  const businessHasChangeRequest =
    business?.validationStatusId === EBackOfficeValidationStatus.CHANGE_REQUEST;

  const businessBankAccountHasChangeRequest = (
    business?.businessBankAccounts ?? []
  ).some(
    ({ validationStatusId }) =>
      validationStatusId === EBackOfficeValidationStatus.CHANGE_REQUEST
  );

  const userHasPermissionToBankingAndBilling = !selectedBusinessUserWasInvited;
  const hasChangeRequestOnBankingAndBilling =
    userHasPermissionToBankingAndBilling &&
    (businessHasChangeRequest || businessBankAccountHasChangeRequest);

  const shouldShowChangeRequestedBanner = Boolean(
    hasChangeRequestOnKYC || hasChangeRequestOnBankingAndBilling
  );

  const onlyHasChangeRequestOnBankingAndBilling =
    !hasChangeRequestOnKYC && hasChangeRequestOnBankingAndBilling;

  const navigateToOnboardingStepWithChangeRequests = () =>
    onlyHasChangeRequestOnBankingAndBilling
      ? navigate(onboardingLevelThree)
      : navigate(onboardingLevelTwo);

  return {
    navigateToOnboardingStepWithChangeRequests,
    shouldShowChangeRequestedBanner,
  };
};
