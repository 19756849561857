import { DocumentFormat, EDocumentTypes, IDocument } from '@common/types';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';

import {
  createDocument,
  deleteDocument,
  uploadImage as uploadImageService,
} from '../../../services/Documents';
import { ALLOWED_IMAGES, DROPZONE_ERRORS_BY_CODE } from './constants';

interface CustomFile extends File {
  preview?: string;
}

export interface UseFileUploadParams {
  documentTypeId: EDocumentTypes;
  onSuccessUploadedFileHandler: (data: IDocument) => void;
  onSuccessDeleteFileHandler: () => void;
  acceptedFiles?: Record<string, string[]>;
  maxFileSize?: number;
  documentUuid?: string | null;
}

export const useFileUpload = ({
  acceptedFiles,
  maxFileSize,
  documentTypeId,
  onSuccessUploadedFileHandler,
  onSuccessDeleteFileHandler,
  documentUuid,
}: UseFileUploadParams) => {
  const [file, setFile] = useState<CustomFile | null>();
  const [error, setError] = useState<string>('');

  const { mutate: uploadDocument, isLoading: isLoadingUploadDocument } =
    useMutation(createDocument, {
      onSuccess: (data?: IDocument) => {
        setError('');
        if (!data) return;

        onSuccessUploadedFileHandler(data);
      },
      onError: (err: Error) => {
        setError(err.message);
      },
    });

  const { mutate: uploadImage, isLoading: isLoadingUploadImage } = useMutation(
    uploadImageService,
    {
      onSuccess: (data?: IDocument) => {
        setError('');
        if (!data) return;

        onSuccessUploadedFileHandler(data);
      },
      onError: (err: Error) => {
        setError(err.message);
      },
    }
  );

  const { mutate: deleteFile, isLoading: isLoadingDelete } = useMutation(
    deleteDocument,
    {
      onSuccess: () => {
        setError('');
        setFile(null);
        onSuccessDeleteFileHandler();
      },
      onError: (err: Error) => {
        setError(err.message);
      },
    }
  );

  const onDeleteFileHandler = () => {
    if (!documentUuid) return;

    deleteFile(documentUuid);
  };

  const dropzone = useDropzone({
    accept: acceptedFiles,
    maxSize: maxFileSize,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        const errorMessages = fileRejections.map(({ errors, file }, index) => {
          const isFirstError = !index;
          const fileName = file.name;
          const errorMessagesForFileName = errors.map(
            ({ code }: { code: string }) => DROPZONE_ERRORS_BY_CODE[code]
          );

          if (isFirstError) {
            setFile(file);
          }

          return `${fileName}: ${errorMessagesForFileName.join(' , ')}`;
        });

        setError(errorMessages.join(' , '));
      }

      acceptedFiles.forEach(async (accFile: any) => {
        const [, fileType] = accFile.type.split('/');
        const areImagesAllowed = ALLOWED_IMAGES.includes(fileType);
        const reader = new FileReader();

        reader.onload = () => {
          setError('');
          setFile(
            Object.assign(accFile, {
              preview: URL.createObjectURL(accFile),
            })
          );
        };
        reader.readAsArrayBuffer(accFile);

        const formData = new FormData();
        formData.append('file', accFile);
        if (areImagesAllowed) {
          await uploadImage({ formData, documentTypeId });
        } else {
          await uploadDocument({ formData, documentTypeId });
        }
      });
    },
    maxFiles: 1,
    noClick: true,
  });

  useEffect(() => {
    URL.revokeObjectURL(file?.preview as string);
  }, [file]);

  return {
    dropzone,
    file,
    error,
    isLoadingUpload: isLoadingUploadDocument || isLoadingUploadImage,
    onDeleteFileHandler,
    isLoadingDelete,
  };
};
