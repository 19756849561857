import { ENationalities } from '@agrotoken/common-utils';
import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import FeatherIcon from 'feather-icons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentRequestAsset } from '@common/enum';
import {
  CryptoCurrencySymbol,
  ETokens,
  EUnits,
  IPaymentRequest,
} from '@common/types';
import { Forwarding } from '@common/types/payment-request-forwarding';
import { formatNumber, getLocalCurrency } from '@common/utils';
import { GenericSummary, SummaryElement } from '@comp/GenericSummary';
import { EmptyState } from '@comp/GenericSummary/EmptyState';
import { useGetConversionRate } from '@hooks/index';
import { useAuth } from '@src/context/authContext';
import { TokenAmount } from '../TokenAmount';
import { TokensAmount } from '../TokensAmount';

interface PaymentCompleteSummaryProps {
  status: 'READY' | 'LOADING' | 'EMPTY';
  paymentAsset: PaymentRequestAsset;
  paymentRequest: IPaymentRequest;
  isPayer: boolean;
  selectedToken?: CryptoCurrencySymbol;
  warrantyPricePerSaca?: Decimal;
  // TODO: DELETE ' forwarding' AFTER HOOKS REFACTOR
  forwarding?: Forwarding;
  fixedFinalPrice?: Decimal;
}

export const PaymentCompleteSummary: FC<PaymentCompleteSummaryProps> = ({
  status,
  paymentRequest,
  paymentAsset,
  isPayer,
  selectedToken,
  warrantyPricePerSaca,
  // TODO: DELETE 'forwarding' AFTER HOOKS REFACTOR
  forwarding,
  fixedFinalPrice,
}) => {
  //TODO: separate in two different files(payer and merchant) and use hooks to getBottom and getTop elements.

  const { t } = useTranslation();
  const { tokenToLocalCurrency } = useGetConversionRate();
  const { nationality } = useAuth();
  const localCurrency = getLocalCurrency(nationality) as unknown as EUnits;
  const showAmountsInSacas =
    (paymentAsset === PaymentRequestAsset.WARRANTY ||
      paymentAsset === PaymentRequestAsset.FIXED_GRAIN) &&
    isPayer;
  const showAmountsInTokens = !!(
    paymentAsset === PaymentRequestAsset.TOKENS && selectedToken
  );

  const isPaymentForwarding =
    paymentRequest?.forwardingMetadata?.forwardings &&
    paymentRequest?.forwardingMetadata?.forwardings?.length > 0;

  let subtotalAmount = 0;
  let subtotalAmountEquivalence = 0;
  let feeAmount = 0;
  let feeAmountFiat = 0;
  let totalAmount = 0;
  let totalAmountFiatEquivalence = 0;
  let pricePerSaca: Decimal | undefined;

  if (paymentRequest.operation) {
    const conversionRate = paymentRequest.operation.currencyRate ?? 1;

    if (isPayer) {
      if (showAmountsInTokens) {
        subtotalAmount = Number(paymentRequest.operation.amount);

        feeAmount = roundValue({
          value:
            subtotalAmount * (Number(paymentRequest.operation.farmerFee) / 100),
          operationType: EPaymentRequestOperationType.FARMER_FEE,
          scale: 4,
        }).toNumber();

        totalAmount = subtotalAmount + feeAmount;

        totalAmountFiatEquivalence = roundValue({
          value: Number(paymentRequest.amount) + feeAmount * conversionRate,
          operationType: EPaymentRequestOperationType.FARMER_FEE,
          scale: 4,
        }).toNumber();
      }
      if (showAmountsInSacas) {
        pricePerSaca =
          paymentAsset === PaymentRequestAsset.WARRANTY
            ? new Decimal(warrantyPricePerSaca ?? 1)
            : new Decimal(fixedFinalPrice ?? 1);

        subtotalAmountEquivalence = roundValue({
          value: new Decimal(paymentRequest.amount)
            .div(pricePerSaca)
            .toNumber(),
          operationType: EPaymentRequestOperationType.TOKENS_TO_PAY_BY_FARMER,
          scale: 2,
        }).toNumber();

        subtotalAmount = Number(paymentRequest.amount);

        feeAmount = roundValue({
          value:
            subtotalAmount * (Number(paymentRequest.operation.farmerFee) / 100),
          operationType: EPaymentRequestOperationType.FARMER_FEE,
          scale: 4,
        }).toNumber();

        totalAmount = subtotalAmount + feeAmount;

        totalAmountFiatEquivalence = new Decimal(totalAmount)
          .times(pricePerSaca)
          .toNumber();
      }
    }

    if (!isPayer) {
      if (showAmountsInTokens) {
        subtotalAmount = Number(
          // TODO: DELETE ' forwarding?.grossTokenAmount ??' AFTER HOOKS REFACTOR
          forwarding?.grossTokenAmount ?? paymentRequest.operation.amount
        );

        feeAmount = roundValue({
          value:
            subtotalAmount *
            (Number(
              // TODO: DELETE 'forwarding?.feePercentage ??' AFTER HOOKS REFACTOR
              forwarding?.feePercentage ?? paymentRequest.operation.merchantFee
            ) /
              100),
          operationType: EPaymentRequestOperationType.MERCHANT_FEE,
          scale: 4,
        }).toNumber();

        feeAmountFiat = tokenToLocalCurrency(
          feeAmount,
          paymentRequest.currency?.name
        );

        totalAmount = subtotalAmount - feeAmount;

        totalAmountFiatEquivalence = forwarding
          ? roundValue({
              value:
                Number(forwarding?.grossAmount) - feeAmount * conversionRate,
              operationType:
                EPaymentRequestOperationType.TOKENS_TO_RECEIVE_BY_MERCHANT,
              scale: 2,
            }).toNumber()
          : paymentRequest.amount;
      } else {
        // TODO: DELETE 'forwarding?.grossAmount ??' AFTER HOOKS REFACTOR
        subtotalAmount = forwarding
          ? Number(forwarding?.grossAmount)
          : paymentRequest.amount;
        feeAmount = roundValue({
          value:
            (Number(subtotalAmount) *
              Number(
                forwarding
                  ? forwarding.feePercentage
                  : paymentRequest.operation.merchantFee
              )) /
            100,
          operationType: EPaymentRequestOperationType.MERCHANT_FEE,
          scale: 4,
        }).toNumber();
        totalAmount = subtotalAmount - feeAmount;
        totalAmountFiatEquivalence = totalAmount;
      }
    }
  } else {
    return <EmptyState />;
  }

  // TODO: Fix classes for left side paragraphs.
  const getTopElements = (): SummaryElement[] => {
    let subtotalElementRight;
    let taxElementRight;
    if (showAmountsInTokens) {
      subtotalElementRight = (
        <Text size="lg" variant="medium" color="gray.900">
          <TokenAmount amount={subtotalAmount} token={selectedToken} />{' '}
        </Text>
      );
      taxElementRight = (
        <Flex alignContent={'center'}>
          <Text size="sm" color="gray.700">
            <TokenAmount amount={feeAmount} token={selectedToken} />
          </Text>
        </Flex>
      );
    } else {
      const formattedAmount = formatNumber(subtotalAmount, EUnits.BRL, true);
      const formattedTax = formatNumber(feeAmount, EUnits.BRL, true);
      subtotalElementRight = (
        <Text size="lg" variant="medium" color="gray.900">
          {formattedAmount}
        </Text>
      );
      taxElementRight = (
        <Text size="sm" color="gray.700">
          {formattedTax}
        </Text>
      );
    }

    if (showAmountsInSacas && pricePerSaca) {
      const formattedAmount = formatNumber(subtotalAmount, EUnits.BRL, true);
      const formattedTax = formatNumber(
        new Decimal(feeAmount).div(pricePerSaca).toNumber(),
        EUnits.SACAS
      );
      subtotalElementRight = (
        <Text size="lg" variant="medium" color="gray.900">
          {formattedAmount}
        </Text>
      );
      taxElementRight = (
        <Text size="sm" color="gray.700">
          {formattedTax} sacas
        </Text>
      );
    }

    const subtotalElement: SummaryElement = {
      left: (
        <Text size="lg" variant="medium" color="gray.900">
          {t(
            showAmountsInTokens && isPayer
              ? 'payment.summary.subtotal'
              : 'payment.summary.localSubtotal'
          )}
        </Text>
      ),
      right: (
        <Text size="lg" variant="medium" color="gray.900">
          {subtotalElementRight}
        </Text>
      ),
    };

    if (isPayer) {
      if (showAmountsInTokens) {
        const taxElement: SummaryElement = {
          left: (
            <>
              <Box className="flex">
                <Text size="sm" color="gray.700">
                  {t('payment.summary.internalTax') +
                    ` (${paymentRequest.payerFee}` +
                    '%)'}
                </Text>
                <Tooltip
                  label={t('payment.summary.internalTaxToolTip')}
                  hasArrow
                  placement="top"
                  gutter={16}
                  shouldWrapChildren
                  bg="white"
                  textColor={'gray.500'}
                >
                  <Icon bgSize={4} color="gray.500" padding="0" marginLeft={1}>
                    {<FeatherIcon icon="alert-circle" size="24" />}
                  </Icon>
                </Tooltip>
              </Box>
            </>
          ),
          right: (
            <Text size="sm" color="gray.700">
              {taxElementRight}
            </Text>
          ),
        };

        return [subtotalElement, taxElement];
      }

      const equivalenceElement: SummaryElement = {
        left: (
          <Text size="sm" color="gray.700">
            {t('payment.summary.equivalence')}
          </Text>
        ),
        right: (
          <Text size="sm" color="gray.700">
            {formatNumber(
              new Decimal(subtotalAmount).div(pricePerSaca ?? 1).toNumber(),
              EUnits.SACAS,
              true
            )}
          </Text>
        ),
      };

      return [subtotalElement, equivalenceElement];
    } else {
      const equivalence: SummaryElement = {
        left:
          nationality === ENationalities.BR ? (
            <>
              <Box className="flex">
                <Text size="sm" color="gray.700">
                  {t('payment.summary.equivalence')}
                </Text>
                <Tooltip
                  label={t('payment.summary.equivalenceToolTip')}
                  hasArrow
                  placement="top"
                  gutter={16}
                  shouldWrapChildren
                  bg="white"
                  textColor={'gray.500'}
                >
                  <Icon bgSize={4} color="gray.500" padding="0" marginLeft={1}>
                    {<FeatherIcon icon="alert-circle" size="24" />}
                  </Icon>
                </Tooltip>
              </Box>
            </>
          ) : (
            <Text size="sm" color="gray.700">
              {t('payment.summary.equivalenceInLocalCurrency')}
            </Text>
          ),

        right: <></>,
      };

      let rightEquivalenceElement = <></>;
      if (
        paymentAsset === PaymentRequestAsset.WARRANTY &&
        paymentRequest.warrantyMetadata?.warrantyGrainContract.agreementDoc
          .price
      ) {
        if (isPayer) {
          // Equivalence shown in local currency.
          rightEquivalenceElement =
            nationality === ENationalities.AR ? (
              <Text size="sm" color="gray.700">
                {localCurrency}{' '}
                {formatNumber(totalAmountFiatEquivalence, localCurrency, true)}
              </Text>
            ) : (
              <Text size="sm" color="gray.700">
                {formatNumber(totalAmountFiatEquivalence, localCurrency, true)}
              </Text>
            );
        } else {
          const totalAmountToSacas =
            totalAmountFiatEquivalence /
            (warrantyPricePerSaca?.toNumber() ?? 1);
          rightEquivalenceElement = (
            <Text size="sm" color="gray.700">
              {formatNumber(totalAmountToSacas, EUnits.SACAS, true)}
            </Text>
          );
        }
      }
      if (paymentAsset === PaymentRequestAsset.TOKENS) {
        if (paymentRequest.operation?.currency) {
          // Show equivalence in local currency
          rightEquivalenceElement =
            nationality === ENationalities.AR ? (
              <Text size="sm" color="gray.700">
                {localCurrency}{' '}
                {formatNumber(totalAmountFiatEquivalence, localCurrency, true)}
              </Text>
            ) : (
              <Text size="sm" color="gray.700">
                {formatNumber(totalAmountFiatEquivalence, localCurrency, true)}
              </Text>
            );
        } else {
          // Show equivalences in all tokens
          equivalence.left = isPayer ? (
            <>
              <Text size="sm" color="gray.700">
                {t('payment.summary.equivalenceInLocalCurrency')}
              </Text>
            </>
          ) : (
            <>
              <Box className="flex">
                <Text size="sm" color="gray.700">
                  {t('payment.summary.equivalence')}
                </Text>
                <Tooltip
                  label={t('payment.summary.equivalenceToolTip')}
                  hasArrow
                  placement="top"
                  gutter={16}
                  shouldWrapChildren
                  bg="white"
                  textColor={'gray.500'}
                >
                  <Icon bgSize={4} color="gray.500" padding="0" marginLeft={1}>
                    {<FeatherIcon icon="alert-circle" size="24" />}
                  </Icon>
                </Tooltip>
              </Box>
            </>
          );
          rightEquivalenceElement = (
            <Text size="sm" color="gray.700">
              <TokensAmount amountFiat={totalAmountFiatEquivalence} />
            </Text>
          );
        }
      }
      equivalence.right = rightEquivalenceElement;
      return [subtotalElement, equivalence];
    }
  };

  // TODO: Fix classes for left side paragraphs.
  const getBottomElements = (): SummaryElement[] => {
    const bottomElements = [];
    // TODO: DELETE ' if (forwarding) { ... }' AFTER HOOKS REFACTOR
    if (forwarding) {
      const subtotalAmount: SummaryElement = {
        left: (
          <Text size="sm" color="gray.700">
            {t('Subtotal')}
          </Text>
        ),
        right: (
          <Text>
            <Text size="lg" variant="medium" color="gray.900">
              <TokenAmount
                amount={
                  Number(forwarding?.grossTokenAmount) -
                  Number(forwarding?.feeTokenAmount)
                }
                token={forwarding.tokenName as CryptoCurrencySymbol}
              />
            </Text>
          </Text>
        ),
      };

      bottomElements.push(subtotalAmount);
    }

    if (
      isPaymentForwarding &&
      !isPayer &&
      paymentAsset === PaymentRequestAsset.TOKENS
    ) {
      const formattedForwardedTotalAmountFiat = formatNumber(
        paymentRequest.forwardingMetadata?.totalAmount,
        localCurrency,
        true
      );
      const totalForwardedElement: SummaryElement = {
        left: (
          <Text size="sm" color="gray.700">
            {t('payment.summary.m2m.totalForwarded')}
          </Text>
        ),
        right: (
          <Box className="flex">
            <Text mr={1} size="sm" color="gray.700">
              <TokenAmount
                amount={
                  paymentRequest.forwardingMetadata?.totalTokenAmount ?? 0
                }
                token={selectedToken ?? ETokens.SOYA}
              />
            </Text>
            <Text size="sm" color="gray.700">
              {`(${formattedForwardedTotalAmountFiat})`}
            </Text>
          </Box>
        ),
      };
      bottomElements.push(totalForwardedElement);
    }

    const equivalence: SummaryElement = {
      left: (
        <Text size="sm" color="gray.700">
          {t('payment.summary.equivalenceInLocalCurrency')}
        </Text>
      ),

      right: <></>,
    };

    let rightEquivalenceElement = <></>;

    if (
      (paymentAsset === PaymentRequestAsset.WARRANTY && warrantyPricePerSaca) ||
      paymentAsset === PaymentRequestAsset.FIXED_GRAIN
    ) {
      if (isPayer) {
        const feeElement: SummaryElement = {
          left: (
            <Text size="sm" color="gray.700">
              {`${t('payment.summary.internalTax')} (${formatNumber(
                paymentRequest.payerFee,
                EUnits.PARAM
              )}%)`}
            </Text>
          ),
          right: (
            <Text size="sm" color="gray.700">
              {formatNumber(feeAmount, localCurrency, true)}
            </Text>
          ),
        };

        bottomElements.push(feeElement);
      }
    }

    if (paymentAsset === PaymentRequestAsset.TOKENS) {
      if (paymentRequest.operation?.currency) {
        // Show equivalence in local currency
        rightEquivalenceElement =
          nationality === ENationalities.AR ? (
            <Text size="sm" color="gray.700">
              {localCurrency}
              {formatNumber(totalAmountFiatEquivalence, localCurrency, true)}
            </Text>
          ) : (
            <Text size="sm" color="gray.700">
              {formatNumber(totalAmountFiatEquivalence, localCurrency, true)}
            </Text>
          );

        if (isPayer) {
          equivalence.right = rightEquivalenceElement;
          bottomElements.push(equivalence);
        }
      } else {
        // Show equivalences in all tokens
        equivalence.left = (
          <>
            <Text size="sm" color="gray.700">
              {t('payment.summary.equivalenceInLocalCurrency')}
            </Text>
          </>
        );
        rightEquivalenceElement = (
          <Text size="sm" color="gray.700">
            <TokensAmount amountFiat={totalAmountFiatEquivalence} />
          </Text>
        );
      }
    }

    let subtotalElementRight;
    let taxElementRight;
    if (!isPayer) {
      if (showAmountsInTokens) {
        const formattedFeeAmountFiat = formatNumber(
          feeAmountFiat,
          localCurrency,
          true
        );
        taxElementRight = isPaymentForwarding ? (
          <Flex alignContent={'center'}>
            <Text size="sm" color="gray.700" mr={1}>
              <TokenAmount amount={feeAmount} token={selectedToken} />
            </Text>
            <Text
              size="sm"
              color="gray.700"
            >{`(${formattedFeeAmountFiat})`}</Text>
          </Flex>
        ) : (
          <Flex alignContent={'center'}>
            <Text size="sm" color="gray.700" mr={1}>
              <TokenAmount amount={feeAmount} token={selectedToken} />
            </Text>
            {/* // TODO: DELETE '!forwarding ?' AFTER HOOKS REFACTOR */}
            {!forwarding ? (
              <Text size="sm" color="gray.700">
                {`(${formattedFeeAmountFiat})`}
              </Text>
            ) : null}
          </Flex>
        );
      } else {
        const formattedAmount = formatNumber(subtotalAmount, EUnits.BRL, true);
        const formattedTax = formatNumber(feeAmount, EUnits.BRL, true);
        subtotalElementRight = (
          <Text size="lg" variant="medium" color="gray.900">
            {formattedAmount}
          </Text>
        );
        taxElementRight = (
          <Text size="sm" color="gray.700">
            {formattedTax}
          </Text>
        );
      }

      if (showAmountsInSacas && warrantyPricePerSaca) {
        const formattedAmount = formatNumber(subtotalAmount, EUnits.SACAS);
        const formattedTax = formatNumber(
          feeAmount / warrantyPricePerSaca.toNumber(),
          EUnits.SACAS
        );
        subtotalElementRight = (
          <Text size="lg" variant="medium" color="gray.900">
            {formattedAmount} sacas
          </Text>
        );
        taxElementRight = (
          <Text size="sm" color="gray.700">
            {formattedTax} sacas
          </Text>
        );
      }

      const taxElement: SummaryElement = {
        left: isPayer ? (
          <Flex>
            <Text size="sm" color="gray.700">
              {t('payment.summary.internalTax') +
                ` (${paymentRequest.payerFee}` +
                '%)'}
            </Text>
            <Tooltip
              label={t('payment.summary.internalTaxToolTip')}
              hasArrow
              placement="top"
              gutter={16}
              shouldWrapChildren
              bg="white"
              textColor={'gray.500'}
            >
              <Icon bgSize={4} color="gray.500" padding="0" marginLeft={1}>
                {<FeatherIcon icon="alert-circle" size="24" />}
              </Icon>
            </Tooltip>
          </Flex>
        ) : (
          <Flex>
            <Text size="sm" color="gray.700">
              {t('payment.summary.internalTax') +
                ` (${
                  // TODO: DELETE 'forwarding?.feePercentage ??' AFTER HOOKS REFACTOR
                  forwarding?.feePercentage ?? paymentRequest.merchantFee
                }` +
                '%)'}
            </Text>
            <Tooltip
              label={t('payment.summary.internalTaxToolTip')}
              hasArrow
              placement="top"
              gutter={16}
              shouldWrapChildren
              bg="white"
              textColor={'gray.500'}
            >
              <Icon bgSize={4} color="gray.500" padding="0" marginLeft={1}>
                {<FeatherIcon icon="alert-circle" size="24" />}
              </Icon>
            </Tooltip>
          </Flex>
        ),
        right: <Text>{taxElementRight}</Text>,
      };
      taxElement.right = taxElementRight;
      bottomElements.push(taxElement);
    }
    if (
      isPaymentForwarding &&
      !isPayer &&
      paymentAsset === PaymentRequestAsset.TOKENS
    ) {
      const forwardingsTotalAmountTokens =
        paymentRequest.forwardingMetadata?.totalTokenAmount ?? 0;

      const forwardingsTotalAmountFiat =
        paymentRequest.forwardingMetadata?.totalAmount ?? 0;

      const recivedAfterForwardedAmountTokens =
        subtotalAmount - feeAmount - forwardingsTotalAmountTokens;

      const recivedAfterForwardedAmountFiat =
        paymentRequest.amount - feeAmountFiat - forwardingsTotalAmountFiat;

      const receivedAfterForwardElement: SummaryElement = {
        left: (
          <Text size="sm" color="gray.700">
            {t('payment.summary.m2m.totalReceivedAfterForward')}
          </Text>
        ),
        right: (
          <Flex alignItems="center">
            <Text mr={1} size="sm" color="gray.700">
              <TokenAmount
                amount={recivedAfterForwardedAmountTokens}
                token={selectedToken ?? ETokens.SOYA}
              />
            </Text>
            <Text size="sm" color="gray.700">
              {`(${formatNumber(
                recivedAfterForwardedAmountFiat,
                localCurrency,
                true
              )})`}
            </Text>
          </Flex>
        ),
      };

      bottomElements.push(receivedAfterForwardElement);
    }
    return bottomElements;
  };

  const getotalText = () => {
    if (showAmountsInSacas || !isPayer) {
      return (
        <Text size="sm" variant="medium" color="gray.700" mb="4">
          {t('payment.summary.details')}
        </Text>
      );
    }

    return (
      <Text size="lg" variant="medium" color="gray.900">
        {t('payment.summary.totalToPay')}
      </Text>
    );
  };

  const getTotalAmount = () => {
    if (!isPayer) return null;

    if (showAmountsInSacas) return null;

    return (
      <TokenAmount
        // TODO: DELETE 'forwarding?.grossAmount ??' AFTER HOOKS REFACTOR
        amount={forwarding ? Number(forwarding?.grossAmount) : totalAmount}
        token={selectedToken as CryptoCurrencySymbol}
      />
    );
  };

  return (
    <>
      {paymentRequest.operation && (
        <Box>
          <GenericSummary
            status={status}
            title={
              <Text size="sm" variant="medium" color="gray.700">
                {t('payment.summary.title')}
              </Text>
            }
            totalText={getotalText()}
            totalAmount={getTotalAmount()}
            topElements={getTopElements()}
            bottomElements={getBottomElements()}
            createdDate={Number(paymentRequest.operation.createdAt)}
          />
        </Box>
      )}
    </>
  );
};
