import { Box, Text } from '@chakra-ui/react';
import { PaymentRequestStatus } from '@common/types';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentRequestParametersTitle from './Fragments/PaymentRequestParametersTitle';

interface PaymentRequestParametersProps {
  paymentRequestStatus?: PaymentRequestStatus;
  createAtBoundOperation?: number;
  generalDataElements: ReactNode[];
  paymentDataElements: ReactNode[];
  createdAtPaymentRequest?: number;
}
export const PaymentRequestParameters: FC<PaymentRequestParametersProps> = (
  props
) => {
  const {
    paymentRequestStatus,
    generalDataElements,
    paymentDataElements,
    createAtBoundOperation,
    createdAtPaymentRequest,
  } = props;
  const { t } = useTranslation();

  return (
    <Box className="border border-gray-200 rounded p-6">
      <PaymentRequestParametersTitle
        paymentRequestStatus={paymentRequestStatus}
        createdAtBoundOperation={createAtBoundOperation ?? 0}
        creatAtPaymentRequest={createdAtPaymentRequest ?? 0}
      />
      <hr className="mt-5 mb-5" />

      {/* General Data */}
      <Text marginBottom={3}>
        {t('payPaymentRequest.breakdown.generalData')}
      </Text>
      {generalDataElements.map((element, index) => (
        <Box key={index}>{element}</Box>
      ))}

      <hr className="mt-5 mb-5" />

      {/* Payment Data */}
      <Text marginBottom={3}>
        {t('payPaymentRequest.breakdown.paymentDetails')}
      </Text>
      {paymentDataElements.map((element, index) => (
        <Box key={index}>{element}</Box>
      ))}
    </Box>
  );
};

export default PaymentRequestParameters;
