import { Flex, Text, Tooltip, useToken } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';

import { EUnits } from '@common/types';
import { formatNumber } from '@common/utils';
import { SummaryDetail } from '@comp/SummaryDetail';

interface WarrantyTransferSummaryDetailProps {
  transferAmountFiat: number;
  transferAmountSacas: number;
  feePercentage: number;
  feeAmountFiat: number;
  feeAmountSacas: number;
  totalAmountFiat: number;
  totalAmountSacas: number;
  isSender: boolean;
  isEmpty?: boolean;
  isLoading?: boolean;
}

const WarrantyTransferSummaryDetail = ({
  feeAmountFiat,
  feeAmountSacas,
  feePercentage,
  transferAmountFiat,
  transferAmountSacas,
  totalAmountFiat,
  totalAmountSacas,
  isSender,
  isEmpty,
  isLoading,
}: WarrantyTransferSummaryDetailProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.warrantySummary',
  });

  const gray500 = useToken('colors', 'gray.500');

  const feeAmountSign = isSender ? '+' : '-';

  const transferAmountElement = {
    left: (
      <Text size="lg" color="gray.900" variant="medium">
        {t('transferAmount')}
      </Text>
    ),
    right: (
      <Text size="lg" color="gray.900" variant="medium">
        {formatNumber(transferAmountFiat, EUnits.BRL, true)}
      </Text>
    ),
  };

  const equivalenceElement = {
    left: (
      <Text size="sm" color="gray.700" variant="regular">
        {t('equivalence')}
      </Text>
    ),
    right: (
      <Text size="sm" color="gray.700" variant="regular">
        {formatNumber(transferAmountSacas, EUnits.SACAS, true)}
      </Text>
    ),
  };

  const feeElement = {
    left: (
      <Flex columnGap="1">
        <Text size="sm" color="gray.700" variant="regular">
          {t('fee.text', { feePercentage })}
        </Text>
        {!isSender && (
          <Tooltip
            label={t('fee.tooltip')}
            hasArrow
            placement="top"
            shouldWrapChildren
            bg="white"
            textColor="gray.700"
          >
            <FiInfo size="16" color={gray500} />
          </Tooltip>
        )}
      </Flex>
    ),
    right: (
      <Text size="sm" color="gray.700" variant="regular">
        {`${feeAmountSign}${formatNumber(feeAmountSacas, EUnits.SACAS, true)}`}
        <Text as="span" size="sm" color="gray.500" variant="regular">
          {` (${feeAmountSign}${formatNumber(
            feeAmountFiat,
            EUnits.BRL,
            true
          )})`}
        </Text>
      </Text>
    ),
  };

  const totalAmountElement = {
    left: (
      <Text size="sm" color="gray.700" variant="regular">
        {t('total')}
      </Text>
    ),
    right: (
      <Text size="sm" color="gray.700" variant="regular">
        {formatNumber(totalAmountSacas, EUnits.SACAS, true)}
        <Text as="span" size="sm" color="gray.500" variant="regular">
          {` (${formatNumber(totalAmountFiat, EUnits.BRL, true)})`}
        </Text>
      </Text>
    ),
  };

  const topElements = [transferAmountElement, equivalenceElement];
  const bottomElements = [feeElement, totalAmountElement];

  return (
    <SummaryDetail
      topTitle={t('topTitle')}
      bottomTitle={t('bottomTitle')}
      topElements={topElements}
      bottomElements={bottomElements}
      isEmpty={isEmpty}
      isLoading={isLoading}
    />
  );
};

export default WarrantyTransferSummaryDetail;
