import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as yup from 'yup';

import useDebounce from '@hooks/useDebounce';
import { getTransactionableBusinessByFiscalId } from '@services/Businesses';
import { previewTransfer } from '@services/transfers';

import { TRANSFER_ASSET_TYPES } from '../../types';
import useCreateWarrantyTransferData from './useCreateWarrantyTransferData';
import { CreateTransferDto } from '../interfaces';

export interface UseCreateWarrantyTransferValues {
  fiscalId: string;
  comments: string;
  amountFiat: string;
  document: string;
  hasAcceptedTermsAndConds: boolean;
}

const useCreateWarrantyTransferForm = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.createWarrantyTransfer.form.errors',
  });

  const { warranty, senderTransferFeePercentage } =
    useCreateWarrantyTransferData();

  const maxAmount = warranty?.equivalences.amount['BRL'] ?? 0;

  const formSchemaValidation = yup.object().shape({
    fiscalId: yup
      .string()
      .typeError(t('fiscalId.typeError'))
      .required(t('fiscalId.required')),
    comments: yup.string().typeError(t('comments.typeError')),
    amountFiat: yup
      .number()
      .typeError(t('amount.required'))
      .required(t('amount.required'))
      .moreThan(0, t('amount.moreThan0'))
      .test('lessThanVariableAmount', t('amount.lessThan'), (value) => {
        if (!value) return false;
        const feeAmount = (value * (senderTransferFeePercentage ?? 0)) / 100;

        return value + feeAmount <= maxAmount;
      }),
  });

  const form = useForm<UseCreateWarrantyTransferValues>({
    resolver: yupResolver(formSchemaValidation) as Resolver<
      UseCreateWarrantyTransferValues,
      unknown
    >,
    defaultValues: {
      hasAcceptedTermsAndConds: false,
    },
  });

  const { amountFiat, fiscalId } = form.watch();

  // Handle business fetch data by debouncing `fiscalId` input
  const debouncedFiscalId = useDebounce(fiscalId, 1000);

  const business = useQuery(
    ['business', debouncedFiscalId],
    () => getTransactionableBusinessByFiscalId(debouncedFiscalId),
    {
      enabled: !!debouncedFiscalId,
      retry: false,
    }
  );

  // Handle transfer preview fetch data by debouncing `amountFiat` input
  const debouncedAmount = useDebounce(amountFiat, 1000);

  const dto: CreateTransferDto = {
    amountFiat: parseFloat(debouncedAmount),
    assetType: TRANSFER_ASSET_TYPES.WARRANTY,
    toBusinessFiscalId: fiscalId,
    warrantyUuid: warranty?.uuid,
  };

  const transferPreview = useQuery(
    ['transfer', 'preview', debouncedAmount],
    () => previewTransfer(dto),
    {
      enabled: !!debouncedAmount && !!debouncedFiscalId && !!business.data,
      retry: false,
    }
  );

  return {
    form,
    businessSearch: {
      data: business.data,
      isLoading: business.isLoading,
      error: business.error,
    },
    preview: {
      data: transferPreview.data,
      isLoading: transferPreview.isLoading,
      error: transferPreview.error,
    },
    debouncedAmount,
    debouncedFiscalId,
  };
};
export default useCreateWarrantyTransferForm;
