import React, { FC, HTMLAttributes } from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { TColor, TIconTheme, TSize } from '../types';
import { Ilustration } from './Ilustration';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  color: TColor;
  theme: TIconTheme;
  title: string;
  text: string;
  icon?: string;
  size?: 'sm' | 'md' | 'lg';
  cancelLabel?: string;
  cancelOnClick?: () => void;
  acceptLabel?: string;
  acceptOnClick?: () => void;
  ilustration: 'box' | 'cloud';
  className?: string;
}

const titleStyles = {
  sm: 'text-md font-medium text-gray-900 mb-4',
  md: 'text-lg font-medium text-gray-900 mb-4',
  lg: 'text-xl font-medium text-gray-900 mb-4',
};

const textStyles = {
  sm: 'text-sm text-gray-500',
  md: 'text-sm text-gray-500',
  lg: 'text-md text-gray-500',
};

const buttonSizes: { [key: string]: 'sm' | 'md' | 'lg' | 'xl' | '2xl' } = {
  sm: 'md',
  md: 'md',
  lg: 'lg',
};

const iconSizes: { [key: string]: TSize } = {
  sm: 'lg',
  md: 'xl',
  lg: 'xl',
};

const iconStyles = {
  sm: 'mb-4',
  md: 'mb-5',
  lg: 'mt-5',
};

export const EmptyState: FC<Props> = ({
  color = 'primary',
  theme = 'light-circle',
  title,
  text,
  icon,
  size = 'md',
  cancelLabel,
  cancelOnClick,
  acceptLabel,
  acceptOnClick,
  ilustration,
  className = '',
}) => {
  return (
    <div
      className={`flex flex-col items-center max-w-[352px] w-full mx-auto ${className}`}
    >
      {ilustration ? (
        <Ilustration
          size={size}
          icon={icon}
          color={color}
          variant={ilustration}
          className="mb-6"
        />
      ) : (
        icon && (
          <Icon
            icon={icon}
            size={iconSizes[size]}
            color={color}
            theme={theme}
            className={`${iconStyles[size]} mb-5`}
          />
        )
      )}
      <h2 className={titleStyles[size]}>{title}</h2>
      <p className={`${textStyles[size]} text-center`}>{text}</p>
      <div className="grid md:grid-cols-2 gap-3 w-full md:w-auto">
        {cancelLabel && cancelOnClick && (
          <Button
            label={cancelLabel}
            variant="secondary-gray"
            size={buttonSizes[size]}
            onClick={cancelOnClick}
            className="order-2 md:order-1"
          />
        )}
        {acceptLabel && acceptOnClick && (
          <Button
            label={acceptLabel}
            variant="primary"
            size={buttonSizes[size]}
            icon="plus"
            onClick={acceptOnClick}
            className="order-1 md:order-2"
          />
        )}
      </div>
    </div>
  );
};
