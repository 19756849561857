import React, { FC } from 'react';
import { tokenSizes, TokenSvgProps } from '..';

export const CoraSvg: FC<TokenSvgProps> = (props) => {
  const { size, color = '#F6BF4C' } = props;

  return (
    <svg
      width={tokenSizes[size]}
      height={tokenSizes[size]}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3177_237162)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.3334 16.0082C32.3334 24.8489 25.1701 32.016 16.3335 32.016C7.49691 32.016 0.333374 24.8489 0.333374 16.0082C0.333374 7.16713 7.49691 0 16.3335 0C25.1701 0 32.3334 7.16713 32.3334 16.0082Z"
          fill="#EBB23C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3006 3.67036C18.5109 3.80992 18.6932 3.98744 18.8391 4.19303H19.3316C19.9902 4.19303 20.5263 3.65672 20.5263 2.9977V2.83516C20.5263 2.17667 19.9902 1.64062 19.3316 1.64062H19.0873C19.0972 1.72111 19.1036 1.80292 19.1036 1.8866V2.05476C19.1036 2.71351 18.7875 3.29847 18.3006 3.67036Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.33 4.97656H19.1707C19.1958 5.10382 19.2126 5.23375 19.2126 5.36823V5.53639C19.2126 6.16787 18.9235 6.73305 18.4709 7.10654C18.6547 7.22017 18.8164 7.3648 18.9553 7.52896H20.0675C20.7262 7.52896 21.2619 6.99292 21.2619 6.3339V6.17162C21.2619 5.5126 20.7262 4.97656 20.0675 4.97656H19.33Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0674 8.31091H19.3694C19.4143 8.478 19.4384 8.65365 19.4384 8.83491V9.02928C19.4384 9.70434 19.1067 10.3019 18.599 10.6722C18.6781 10.7299 18.7513 10.7949 18.8214 10.8633H20.9385C21.5972 10.8633 22.133 10.327 22.133 9.66798V9.5057C22.133 8.84694 21.5972 8.31091 20.9385 8.31091H20.0674Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4082 12.8401C22.4082 12.1813 21.8724 11.6453 21.2138 11.6453H19.3221C19.3961 11.8552 19.4384 12.08 19.4384 12.3153V12.5094C19.4384 13.1844 19.1067 13.7822 18.599 14.1523C18.6979 14.1787 18.8 14.1977 18.9069 14.1977H21.2138C21.8724 14.1977 22.4082 13.6617 22.4082 13.0024V12.8401Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7521 17.5072C18.7964 17.5144 18.8392 17.5243 18.8849 17.5267C18.9105 17.5257 18.9359 17.5227 18.9618 17.5227H19.9837C20.1226 17.4086 20.2624 17.2939 20.4043 17.1754C21.3545 16.3838 21.8951 15.8093 22.1647 15.2508C21.9595 15.0839 21.7013 14.9797 21.4167 14.9797H19.2648C19.3755 15.2297 19.4399 15.5042 19.4399 15.7951V15.9892C19.4399 16.5939 19.1724 17.1348 18.7521 17.5072Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6113 3.30603H17.0695C17.7595 3.30603 18.3209 2.7446 18.3209 2.0543V1.88613C18.3209 1.19583 17.7595 0.634399 17.0695 0.634399H15.6113C14.9213 0.634399 14.3599 1.19583 14.3599 1.88613V2.0543C14.3599 2.7446 14.9213 3.30603 15.6113 3.30603Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5004 6.78751H17.1798C17.8698 6.78751 18.4312 6.22607 18.4312 5.5355V5.36734C18.4312 4.67704 17.8698 4.1156 17.1798 4.1156H15.5004C14.8104 4.1156 14.249 4.67704 14.249 5.36734V5.5355C14.249 6.22607 14.8104 6.78751 15.5004 6.78751Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.024 9.02893C14.024 9.71923 14.5852 10.2806 15.2751 10.2806H17.4062C18.0961 10.2806 18.6576 9.71923 18.6576 9.02893V8.83456C18.6576 8.14452 18.0961 7.58282 17.4062 7.58282H15.2751C14.5852 7.58282 14.024 8.14452 14.024 8.83456V9.02893Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.024 12.5089C14.024 13.1989 14.5852 13.7606 15.2751 13.7606H17.4062C18.0961 13.7606 18.6576 13.1989 18.6576 12.5089V12.3148C18.6576 11.6242 18.0961 11.0625 17.4062 11.0625H15.2751C14.5852 11.0625 14.024 11.6242 14.024 12.3148V12.5089Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.024 15.9899C14.024 16.6585 14.5515 17.2015 15.2113 17.2354C15.2329 17.2346 15.2538 17.2322 15.2751 17.2322H17.4062C17.4276 17.2322 17.4487 17.2346 17.4701 17.2354C18.1298 17.2015 18.6576 16.6585 18.6576 15.9899V15.7958C18.6576 15.1055 18.0961 14.5438 17.4062 14.5438H15.2751C14.5852 14.5438 14.024 15.1055 14.024 15.7958V15.9899Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8471 19.9648C16.0291 20.1025 16.198 20.2311 16.3597 20.3543C16.5155 20.2348 16.6809 20.1083 16.8556 19.9749L16.9123 19.9319C17.3927 19.5656 17.9317 19.1544 18.5148 18.6983C18.3157 18.3142 17.9272 18.0439 17.4705 18.0203C17.4488 18.0193 17.4285 18.0139 17.4066 18.0139H15.2756C15.2537 18.0139 15.2333 18.0193 15.2117 18.0203C14.7601 18.0436 14.375 18.3083 14.1741 18.686C14.7665 19.1461 15.3135 19.5605 15.8009 19.9295L15.8471 19.9648Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3684 3.67036C14.1581 3.80992 13.9759 3.98744 13.83 4.19303H13.3373C12.6786 4.19303 12.1428 3.65672 12.1428 2.9977V2.83516C12.1428 2.17667 12.6786 1.64062 13.3373 1.64062H13.5818C13.5721 1.72111 13.5655 1.80292 13.5655 1.8866V2.05476C13.5655 2.71351 13.8816 3.29847 14.3684 3.67036Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3401 4.97498H13.4996C13.4748 5.10224 13.4577 5.23217 13.4577 5.36665V5.53481C13.4577 6.16629 13.7468 6.73147 14.1995 7.10496C14.0156 7.21859 13.8542 7.36322 13.7153 7.52738H12.6028C11.9442 7.52738 11.4084 6.99134 11.4084 6.33232V6.17004C11.4084 5.51102 11.9442 4.97498 12.6028 4.97498H13.3401Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6005 8.31091H13.2985C13.2536 8.478 13.2292 8.65365 13.2292 8.83491V9.02928C13.2292 9.70434 13.5611 10.3019 14.0688 10.6722C13.9897 10.7299 13.9165 10.7949 13.8465 10.8633H11.7294C11.0707 10.8633 10.5349 10.327 10.5349 9.66798V9.5057C10.5349 8.84694 11.0707 8.31091 11.7294 8.31091H12.6005Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.26 12.8403C10.26 12.1815 10.7958 11.6455 11.4545 11.6455H13.3461C13.2724 11.8554 13.2296 12.0802 13.2296 12.3155V12.5096C13.2296 13.1846 13.5615 13.7824 14.0692 14.1525C13.9703 14.1789 13.8685 14.1979 13.7614 14.1979H11.4545C10.7958 14.1979 10.26 13.6619 10.26 13.0026V12.8403Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.916 17.5072C13.8722 17.5144 13.8292 17.5243 13.7837 17.5267C13.7578 17.5257 13.7324 17.5227 13.7065 17.5227H12.6847C12.5457 17.4086 12.406 17.2939 12.2641 17.1754C11.3139 16.3838 10.7733 15.8093 10.5037 15.2508C10.7091 15.0839 10.9673 14.9797 11.2519 14.9797H13.4035C13.2929 15.2297 13.2282 15.5042 13.2282 15.7951V15.9892C13.2282 16.5939 13.4959 17.1348 13.916 17.5072Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9584 26.7094C11.9718 24.8093 13.1906 22.8753 15.5811 20.9619L15.6043 20.9434C14.7028 20.2601 13.0858 19.0193 11.7035 17.8761C10.194 16.6276 9.37629 15.7547 9.37629 14.3303C9.37629 14.2565 9.30788 14.1969 9.22344 14.1969C9.13927 14.1969 9.07086 14.2565 9.07086 14.3303V20.3547L11.215 22.5529L9.07086 21.3054V23.7824C9.07086 25.5341 10.2378 26.6522 11.4988 27.5999C11.7236 27.7689 11.9528 27.9443 12.1824 28.1215C12.0306 27.6654 11.955 27.1944 11.9584 26.7094Z"
          fill="#FFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.532 21.3971C23.0905 21.772 22.6347 22.1147 22.1743 22.4468C21.2596 23.1119 20.2907 23.7124 19.4003 24.3444C18.4736 24.9909 17.5931 25.6472 16.8147 26.4418C16.4299 26.8383 16.07 27.2631 15.7851 27.7374C15.5019 28.2092 15.2849 28.7386 15.2683 29.3027C15.2373 28.7389 15.4118 28.1772 15.6619 27.6703C15.9134 27.1593 16.2461 26.6934 16.6063 26.2546C17.328 25.3804 18.1946 24.6152 19.0494 23.9068C19.9374 23.1756 20.8336 22.5296 21.6837 21.8126C22.1077 21.4578 22.5232 21.0953 22.9104 20.7154C23.156 20.4726 23.3909 20.2203 23.5988 19.9628V14.3303C23.5988 14.2565 23.5312 14.1969 23.4476 14.1969C23.3636 14.1969 23.2958 14.2565 23.2958 14.3303C23.2958 15.7547 22.484 16.6276 20.9851 17.8761C19.6475 18.9902 18.4359 19.9144 17.4627 20.6568C16.9662 21.036 16.5373 21.3629 16.2151 21.622L16.1496 21.6747C13.9843 23.4082 12.8807 25.1042 12.8694 26.7158C12.861 27.9293 13.4865 29.061 14.7283 30.0799C14.8835 30.2074 16.199 31.3308 16.2119 31.3421C16.2413 31.3672 16.2795 31.38 16.3177 31.38C16.3524 31.38 16.3874 31.3693 16.416 31.3482C16.4433 31.3276 19.1702 29.281 21.1936 27.5959C22.461 26.5399 23.5988 25.4537 23.5988 23.7824V21.3378C23.5766 21.3576 23.5544 21.3776 23.532 21.3971Z"
          fill="#FFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3177_237162">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.333374)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
