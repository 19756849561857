/* eslint-disable no-console */
import { checkCuitTypeAndLength } from '@agrotoken/common-utils';
import { Select } from '@agrotoken-tech/ui';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
  Divider,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import { Dispatch, SetStateAction, VFC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import {
  EBusinessStructureTypes,
  EDocumentTypes,
  IDocumentPreview,
  IDocumentPreviewForBusinessUser,
} from '@src/common/types';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import FileUploader from '../FileUploader';
import PEPRadio from '../PEPRadio';
import ChangeRequestedAlert from '../common/ChangeRequestedAlert';
import { IncompleteFieldError } from '../types';
import useNationalities from '../useNationalities';
import CompanyFields from './CompanyFields';
import CuitOrCuil from './Fields/CuitOrCuil';
import RepresentativeDocsFields from './RepresentativeDocsFields';
import SecondStepModal from './SecondStepModal';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';

interface Props {
  frontPersonDocumentId: number | null;
  setFrontPersonDocumentId: Dispatch<SetStateAction<number | null>>;
  backPersonDocumentId: number | null;
  setBackPersonDocumentId: Dispatch<SetStateAction<number | null>>;
  statuteDocumentId: number | null;
  setStatuteDocumentId: Dispatch<SetStateAction<number | null>>;
  authoritiesDocumentId: number | null;
  setAuthoritiesDocumentId: Dispatch<SetStateAction<number | null>>;
  legalPowerDocumentIds: number[];
  setLegalPowerDocumentIds: Dispatch<SetStateAction<number[]>>;
  setNationalityId: Dispatch<SetStateAction<number | null>>;
  missingFileError: IncompleteFieldError['errors'];
  isModalOpen: boolean;
  handleCloseModal: () => void;
  defaultUserDocuments: IDocumentPreview[];
  defaultBusinessUserDocuments: IDocumentPreviewForBusinessUser[];
  selectedBusinessAddressId?: number;
  hasChangeRequests?: boolean;
  changeRequestMessages?: string[];
  shouldShowRepresentativeDocsSection: boolean;
  shouldShowGeneralDataSection: boolean;
  shouldShowCompanyData: boolean;
  hasRepresentant?: boolean;
}

/**
 *  Q: Whats KYC?
 *  A: Know Your Customer
 */
const KYC: VFC<Props> = ({
  frontPersonDocumentId,
  setFrontPersonDocumentId,
  backPersonDocumentId,
  setBackPersonDocumentId,
  statuteDocumentId,
  setStatuteDocumentId,
  authoritiesDocumentId,
  setAuthoritiesDocumentId,
  legalPowerDocumentIds,
  setLegalPowerDocumentIds,
  setNationalityId,
  missingFileError,
  handleCloseModal,
  isModalOpen,
  defaultUserDocuments,
  defaultBusinessUserDocuments,
  selectedBusinessAddressId,
  hasChangeRequests,
  changeRequestMessages,
  shouldShowRepresentativeDocsSection,
  shouldShowGeneralDataSection,
  shouldShowCompanyData,
  hasRepresentant,
}) => {
  const { t } = useTranslation();
  const { isBrazilRegion } = useAuth();

  const defaultFrontPersonDocument = defaultUserDocuments.find(
    ({ documentTypeId }) => documentTypeId === EDocumentTypes.DNI_FRONT
  );
  const defaultBackPersonDocument = defaultUserDocuments.find(
    ({ documentTypeId }) => documentTypeId === EDocumentTypes.DNI_BACK
  );
  const {
    register,
    formState: { errors },
    control,
    formState,
    watch,
  } = useFormContext();
  const {
    selectedBusinessUserWasInvited,
    selectedBusiness,
    selectedBusinessUserWasAdminInvited,
    currentUser,
  } = useUser();
  const { nationalities, isLoading, error } = useNationalities();

  const isSelectedBusinessACompany =
    selectedBusiness?.businessStructureTypeId ===
    EBusinessStructureTypes.COMPANY;

  const shouldShowCuitOrCuil =
    (selectedBusinessUserWasInvited && !selectedBusinessUserWasAdminInvited) ||
    isSelectedBusinessACompany;

  const invalidCuitError = t('fullOnboarding.lvl_2.KYC.fiscalId.error');

  const nationality = watch('nationality');

  useEffect(() => {
    if (nationality) setNationalityId(Number(nationality.value));
  }, [nationality, setNationalityId]);

  if (isLoading)
    return (
      <Flex direction="column" w="full">
        <Loader />
      </Flex>
    );
  if (error)
    return (
      <ServerError
        title={t('serverError.title')}
        text={(error as any)?.message ?? t('serverError.text')}
      />
    );

  return (
    <Flex
      px={{ base: '0', md: '4' }}
      pr={{ base: '5', md: '0' }}
      flexDir="column"
      w="full"
    >
      <SecondStepModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      />
      {shouldShowGeneralDataSection && (
        <>
          <Heading size="sm" py="1.5">
            {t('fullOnboarding.lvl_2.KYC.title')}
          </Heading>
          <Text size="sm">{t('fullOnboarding.lvl_2.KYC.subtitle')}</Text>
        </>
      )}
      {hasChangeRequests && changeRequestMessages && (
        <ChangeRequestedAlert messages={changeRequestMessages} />
      )}
      <Stack spacing="12" mt="4">
        <SimpleGrid columns={[1, 1, 2]} gap="6" mt="8">
          {nationalities && (
            <FormControl isInvalid={Boolean(errors.nationality)}>
              <FormLabel htmlFor="nationality">
                {t('fullOnboarding.lvl_2.form.nationality.label')}
              </FormLabel>
              <Select
                id="nationality"
                register={register}
                registerOptions={{
                  required: t('fullOnboarding.lvl_2.form.errors.required'),
                }}
                options={nationalities?.map((nationality) => ({
                  value: nationality.id.toString(),
                  label: t(
                    `fullOnboarding.lvl_2.countries.${nationality.isoCode2}`
                  ),
                }))}
                isSearchable={true}
                isDisabled={hasRepresentant}
                control={control}
                formState={formState}
                leftIcon={FiSearch}
                label={''}
                placeholder={t(
                  'fullOnboarding.lvl_2.form.nationality.placeholder'
                )}
                selectName={'nationality'}
                isClearable={true}
                //TODO: When the rest of the inputs are integrated into the component library it should not be necessary to pass the height as a prop
                height="48px"
              />
            </FormControl>
          )}
          {shouldShowCuitOrCuil && shouldShowGeneralDataSection && (
            <CuitOrCuil
              formRegisterProps={register('identificationTaxValue', {
                required: hasRepresentant
                  ? false
                  : t('fullOnboarding.lvl_2.form.errors.required'),
                validate: (value: string) => {
                  if (!hasRepresentant) {
                    return checkCuitTypeAndLength(value) || invalidCuitError;
                  }
                },
              })}
              error={errors.identificationTaxValue}
              disabled={hasRepresentant}
            />
          )}
        </SimpleGrid>

        {shouldShowGeneralDataSection && (
          <>
            <PEPRadio
              title={t('fullOnboarding.lvl_2.form.pep.label')}
              disabled={hasRepresentant}
            />

            <Flex flexDirection="column">
              <FileUploader
                title={t('fullOnboarding.lvl_2.KYC.identification.title')}
                documentId={frontPersonDocumentId}
                setDocumentId={setFrontPersonDocumentId}
                description={t('fullOnboarding.lvl_2.KYC.identification.front')}
                documentTypeId={EDocumentTypes.DNI_FRONT}
                isValid={!missingFileError.frontPersonDocumentId}
                allowImages={isBrazilRegion}
                defaultFile={defaultFrontPersonDocument}
                disabled={hasRepresentant}
              />

              <FileUploader
                documentId={backPersonDocumentId}
                setDocumentId={setBackPersonDocumentId}
                description={t('fullOnboarding.lvl_2.KYC.identification.back')}
                documentTypeId={EDocumentTypes.DNI_BACK}
                isValid={!missingFileError.backPersonDocumentId}
                allowImages={isBrazilRegion}
                defaultFile={defaultBackPersonDocument}
                disabled={hasRepresentant}
              />
            </Flex>
          </>
        )}
        {shouldShowRepresentativeDocsSection && (
          <RepresentativeDocsFields
            statuteDocumentId={statuteDocumentId}
            setStatuteDocumentId={setStatuteDocumentId}
            authoritiesDocumentId={authoritiesDocumentId}
            setAuthoritiesDocumentId={setAuthoritiesDocumentId}
            legalPowerDocumentIds={legalPowerDocumentIds}
            setLegalPowerDocumentIds={setLegalPowerDocumentIds}
            missingFileError={missingFileError}
            defaultBusinessUserDocuments={defaultBusinessUserDocuments}
          />
        )}
        {shouldShowCompanyData && (
          <CompanyFields
            selectedBusinessAddressId={selectedBusinessAddressId}
          />
        )}
      </Stack>
      <Divider my="6" />
    </Flex>
  );
};

export default KYC;
