import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';
import { IGetLoansBalancesResponse } from '@common/types';
import { getBalances } from '@services/Balances';
import { useUser } from '@src/context/userContext';

export const useGetBalances = () => {
  const { currentUser, selectedBusinessId } = useUser();
  const { collateralizedTokensSectionBalance } = useFlags();

  const { isLoading, data, isError } = useQuery<
    IGetLoansBalancesResponse | undefined
  >(['beneficiaryId', currentUser?.id], getBalances, {
    enabled: !!selectedBusinessId && !!collateralizedTokensSectionBalance,
  });

  return {
    isLoading,
    data,
    isError,
  };
};

export default useGetBalances;
