export const WALLET_AR = {
  balanceTable: {
    columns: {
      balance: 'Balance',
      price: 'Precio token',
      sacas: 'Sacas',
      token: 'Activo',
    },
    emptyState: {
      subtitle: 'Cuando los tengas, los verás acá',
      title: '¡No tenés balance!',
    },
    loadingError: 'Error al cargar el balance',
    subtitle: 'Resumen de tus activos',
    title: 'Billetera',
  },
  header: {
    subtitle: '',
    title: 'Movimientos',
  },
  movementsTable: {
    columns: {
      amount: 'Cantidad',
      business: 'Usuário',
      category: 'Categoría',
      date: 'Fecha',
      linkCreator: 'Creador link',
      status: 'Estado',
      transactionType: 'Tipo de transacción',
      user: 'Usuario interno',
      value: 'Valorización',
      type: 'Tipo',
      sacas: 'Sacas',
    },
    detailsButtonLabel: 'Detalle',
    download: 'Descargar en CSV',
    downloadBalance: 'Descargar estado de cuenta',
    downloadBalanceFileName: 'descarga_estado_cuenta',
    emptyState: {
      subtitle: 'Cuando hagas un movimiento, lo verás acá',
      title: '¡No tenés movimientos!',
    },
    loadingError: 'Error al cargar los movimientos',
    status: {
      placeholder: 'Filtrar por status',
      completed: 'Completado',
      expired: 'Expirado',
      failed: 'Fallido',
      liquidated: 'Liquidado',
      pending: 'Pendiente',
      notClaimable: 'Liquidez pendente',
      claimAvailable: 'Claim disponível',
      claimed: 'Completado',
      claimable: 'Claim disponível',
      cancel: 'Cancelada',
      rejected: 'Rechazada',
      all: 'Todo',
    },
    subtitle: 'Acá podés ver todos los movimientos de tu cuenta.',
    tabs: {
      all: 'Todo',
      guarantees: 'Garantías',
      agrotokens: 'Agrotokens',
      fixedGrains: 'Granos fijados',
    },
    title: 'Movimientos',
    transactionType: {
      creditCardBlockedReturn: 'Retorno por bloqueo de pago con tarjeta',
      detokenization: 'Destokenización',
      fee: 'Comisión de transacción',
      gift: 'Beneficio',
      loans: {
        granted: 'Bloqueo de tokens',
        reimbursement: 'Devolución de garantía',
        fee: 'Comisión agrotoken',
        loanPaid: 'Pago enviado',
      },
      payment: {
        cryptoAssetSettlement: 'Conversión a {{localCurrency}}',
        received: 'Pago recibido',
        sent: 'Pago enviado',
      },
      paymentInKind: {
        cryptoAssetSettlement: 'Liquidación de activos',
        received: 'Pago en especie recibido',
        sent: 'Pago en especie enviado',
      },
      forwarding: {
        sent: 'Pago reenviado',
        received: 'Pago recibido',
      },
      paymentWithCard: 'Pago con tarjeta',
      cardAuthorizationHold: 'Bloqueo de pago con tarjeta',
      tokenization: 'Tokenización de agrotokens',
      swapPogr: 'Pase a perpetuidad',
      unknown: 'Nombre de transacción no disponible',
      warranty: 'Tokenizacion',
      warrantyId: 'Garantia #{{number}}',
      warrantyClaim: 'Liquidación solicitada',
      collateralPaymentReceived: 'Pago de garantía recibido',
      paymentWithFeitoGuarantee: 'Pago realizado con garantía',
      freezeTokens: 'Bloqueo',
      unfreezeTokens: 'Desbloqueo',
      warrantyTransfer: {
        sent: 'Transferencia de garantía enviada',
        received: 'Transferencia de garantía recibida',
      },
    },
    tooltipMessage: {
      cancelWarrantyTransfer:
        'Ha cancelado la transferencia. Los tokens se han desbloqueado y están disponibles nuevamente en su cuenta.',
      rejectedWarrantyTransfer:
        'El usuario receptor rechazó la transferencia. Los tokens se han desbloqueado y están disponibles nuevamente en su cuenta.',
      expiredWarrantyTransfer:
        'Se agotó el tiempo de transferencia sin respuesta del destinatario. Los tokens se han desbloqueado y están disponibles nuevamente en su cuenta.',
      createdWarrantyTransfer:
        'Sus tokens han sido bloqueados hasta que el destinatario de la transferencia los acepte o la solicitud expire.',
    },
  },
  tokenCards: {
    subtitle: 'Tokens disponibles:',
    title: 'Tokens disponibles',
    SACAS: 'Sacas',
  },
  warrantyTokens: {
    badge: 'Nueva funcionalidad',
    cardTitle: 'Link de pago en garantía',
    loadingError: 'Error al cargar los présatmos',
    more: 'Ver más',
    subtitle:
      'Resumen de tus tokens que están siendo utilizados como garantías.',
    title: 'Tokens en garantía',
  },
  warrantySection: {
    subtitle: 'Resumen de sus tokens utilizadas como garantía.',
    title: 'Tokens de garantía',
    tooltipPF: 'Equivalencia basada en el valor del contrato de tokenización',
    tooltipPAF:
      'Equivalencia calculada en tiempo real en función de la localización del grano: {{city}}, {{geographicalDivision}}',
    fixCondition: 'Condición de sujeción',
    fixedPrice: 'Precio fijo',
    fixedPriceDescription:
      'Equivalencia calculada en tiempo real en función de la ubicación del grano',
    priceToFixAGTK: 'Precio a fijar Agrotoken',
    priceToFixAGTKDescription: 'Completar',
    priceToFix: 'Precio a arreglar',
    priceToFixDescription:
      'Equivalencia basada en el valor del contrato de tokenización.',
    availableTons: 'Toneladas disponibles',
    availableSacas: 'Sacas disponibles',
    expireDate: 'Fecha de vencimiento',
  },
};
