import { ENationalities } from '@agrotoken/common-utils';
import { Box, Spinner } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ServerError } from '@comp/Global/ServerError';
import { findRepresentantByBusinessUser } from '@services/BusinessUsers';
import { FULL_ONBOARDING_LEVEL_2 } from '@src/common/const';
import {
  EDocumentTypes,
  EPEPValues,
  IBusiness,
  IBusinessUser,
  IRepresentant,
} from '@src/common/types';
import { useAuth } from '@src/context/authContext';
import { TYPE_OF_LEGAL_POWER_DOC } from './DocOfRepresentative/consts';
import KYC from './KYC/KYC';
import { ARGENTINA_COUNTRY_ID, PEP_RADIO } from './KYC/consts';
import FullOnboardingProgress from './common/FullOnboardingProgress';
import Form from './common/layouts/Form';
import useFullOnboardingData from './useFullOnboardingData';
import { useUser } from '@src/context/userContext';

/**
 * TODO: Check if theres no changes when user clicks save and prevent the update with same data
 * TODO: Add loading state when fetching already existing user data
 *  Q: Whats KYC?
 *  A: Know Your Customer
 */

/**
 * TODO: This component (entire FullOnboarding folder) does not reflect if its the AR or BR flow.
 * We know (internally) that `v2` A.K.A BR (and soon to be new AR flow also). We need to specify this better.
 */
const FullOnboardingKYC = () => {
  // General
  const { nationality } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    formMethods,
    formActions,
    flags,
    userData,
    businessData,
    businessUserData,
    modalUtils,
    changeRequestData,
  } = useFullOnboardingData();

  const { reset, handleSubmit, onSubmit, handleCompleteLater } = formActions;

  const {
    isCompany,
    isLoading,
    businessUserHasLegalPower,
    shouldShowGeneralData,
    shouldShowCompanyData,
    shouldShowRepresentativeDocsSection,
  } = flags;

  const {
    currentUserId,
    userStoredData,
    frontPersonDocumentId,
    setFrontPersonDocumentId,
    backPersonDocumentId,
    setBackPersonDocumentId,
    defaultUserDocuments,
    setDefaultUserDocuments,
    setNationalityId,
  } = userData;

  const { currentUser } = useUser();

  const {
    selectedBusinessUser,
    statuteDocumentId,
    setStatuteDocumentId,
    authoritiesDocumentId,
    setAuthoritiesDocumentId,
    legalPowerDocumentIds,
    setLegalPowerDocumentIds,
    missingFileError,
    defaultBusinessUserDocuments,
    businessUserDocuments,
    setDefaultBusinessUserDocuments,
  } = businessUserData;

  const { selectedBusinessAddressId } = businessData;

  const { isModalOpen, handleCloseModal } = modalUtils;

  const { hasChangeRequests, userChangeRequestMessages } = changeRequestData;

  // General
  const isReady = useMemo(() => {
    if (
      !userStoredData ||
      !currentUserId ||
      isLoading ||
      !selectedBusinessUser
    ) {
      return false;
    }

    reset({
      [PEP_RADIO]: userStoredData.pep
        ? String(userStoredData.pep)
        : String(EPEPValues.IS_NOT_PEP),
      [TYPE_OF_LEGAL_POWER_DOC]: businessUserHasLegalPower
        ? String(EDocumentTypes.LEGAL_POWER)
        : String(EDocumentTypes.STATUTE_OR_SOCIAL_CONTRACT),
      identificationTaxValue: userStoredData.identificationTaxValue,
      businessAddress: {
        countryId: ARGENTINA_COUNTRY_ID,
      },
      nationality: currentUser?.nationalityRelation
        ? {
            value: currentUser?.nationalityRelation.id.toString(),
            label: t(
              `fullOnboarding.lvl_2.countries.${currentUser?.nationalityRelation.isoCode2}`
            ),
          }
        : undefined,
    });

    setDefaultUserDocuments(userStoredData?.documents || []);
    setDefaultBusinessUserDocuments(businessUserDocuments || []);

    return true;
  }, [userStoredData, currentUserId, isLoading, selectedBusinessUser]);

  useEffect(() => {
    if (nationality === ENationalities.BR) navigate(FULL_ONBOARDING_LEVEL_2);
  }, [nationality]);

  const { data: representant, error } = useQuery<
    Record<string, never> | IRepresentant
  >(['findRepresentantByUserId'], findRepresentantByBusinessUser);

  if (error)
    return (
      <ServerError
        title={t('serverError.title')}
        text={(error as any)?.message}
      />
    );

  return isReady ? (
    <FormProvider {...formMethods}>
      <Form
        handleSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        rightSideContent={
          <FullOnboardingProgress isCompany={isCompany} currentStep={2} />
        }
        secondaryAction={handleCompleteLater}
        currentStep={2}
      >
        <KYC
          frontPersonDocumentId={frontPersonDocumentId}
          setFrontPersonDocumentId={setFrontPersonDocumentId}
          backPersonDocumentId={backPersonDocumentId}
          setBackPersonDocumentId={setBackPersonDocumentId}
          statuteDocumentId={statuteDocumentId}
          setStatuteDocumentId={setStatuteDocumentId}
          authoritiesDocumentId={authoritiesDocumentId}
          setAuthoritiesDocumentId={setAuthoritiesDocumentId}
          legalPowerDocumentIds={legalPowerDocumentIds}
          setLegalPowerDocumentIds={setLegalPowerDocumentIds}
          setNationalityId={setNationalityId}
          missingFileError={missingFileError}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          defaultUserDocuments={defaultUserDocuments}
          defaultBusinessUserDocuments={defaultBusinessUserDocuments}
          selectedBusinessAddressId={selectedBusinessAddressId}
          hasChangeRequests={hasChangeRequests}
          changeRequestMessages={userChangeRequestMessages}
          shouldShowRepresentativeDocsSection={
            shouldShowRepresentativeDocsSection
          }
          shouldShowGeneralDataSection={shouldShowGeneralData}
          shouldShowCompanyData={shouldShowCompanyData}
          hasRepresentant={Boolean(representant?.id)}
        />
      </Form>
    </FormProvider>
  ) : (
    <Box className="m-auto py-10">
      <Spinner />
    </Box>
  );
};

export default FullOnboardingKYC;
