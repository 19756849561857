import { TokenCurrency } from '@agrotoken/common-utils';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { formatNumber } from '@common/utils';
import { useStepForm, StepTitle } from '@comp/Global/StepForm';
import { TransferTokensFormValues } from '@comp/TransferTokens';
import { useGetConversionRate } from '@hooks/index';
import { getBusinessBalance } from '@services/Businesses';
import { MINIMUM_TOKENS_TO_TRANSFER } from '@src/common/const';
import { CryptoCurrencySymbol, EUnits } from '@src/common/types';
import { useUser } from '@src/context/userContext';
import { TokenAmountSelector } from './TokenAmountSelector';

export const TokenAmountStep = () => {
  const { handleNextStep, formValues } =
    useStepForm<TransferTokensFormValues>();
  const { token, amount } = formValues;
  const { tokenToLocalCurrency } = useGetConversionRate();
  const tokenRate = tokenToLocalCurrency(1, token as TokenCurrency);

  const { t } = useTranslation();
  const { selectedBusinessId } = useUser();

  const { isLoading: isLoadingBalance, data: balance } = useQuery<
    Record<CryptoCurrencySymbol, number>
  >(['balance', selectedBusinessId], getBusinessBalance, {
    enabled: !!selectedBusinessId,
  });

  const haveEnoughTokens = balance
    ? Number(amount) <= balance[token as CryptoCurrencySymbol]
    : false;
  const isMinimumAmount = Number(amount) >= MINIMUM_TOKENS_TO_TRANSFER;

  const customHandleNextStep = () => {
    if (!haveEnoughTokens || !isMinimumAmount) return;
    handleNextStep();
  };

  return (
    <Box position="relative">
      <StepTitle>{t('transferTokens.tokenAmountStep.title')}</StepTitle>
      <Text
        fontSize="md"
        fontWeight="normal"
        textColor="gray.700"
        textAlign="center"
      >
        {t('transferTokens.tokenAmountStep.subtitle')}
      </Text>
      <TokenAmountSelector
        className="mt-6"
        {...{ balance, isLoadingBalance }}
      />
      <Button
        variant="primary"
        onClick={customHandleNextStep}
        size="lg"
        w="full"
        mt="6"
        mb="4"
        disabled={!haveEnoughTokens || !isMinimumAmount}
      >
        {t('transferTokens.tokenAmountStep.btnLabel')}
      </Button>
      <Flex
        justifyContent="space-between"
        textColor="gray.700"
        fontWeight="normal"
        fontSize="sm"
      >
        <Text>{t('transferTokens.tokenAmountStep.rate')}</Text>
        <Text>{`1 ${token} = ${formatNumber(tokenRate, EUnits.ARS)} ${t(
          'localCurrency'
        )}`}</Text>
      </Flex>
    </Box>
  );
};
