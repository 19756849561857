import { ENationalities, PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { CURRENT_REGION_HEADER } from '@common/const';
import { PaymentRequestStatus } from '@common/types/payment-request';
import { Forwarding } from '@common/types/payment-request-forwarding';
import { FixedGrain } from '@features/cards/interfaces/fixedGrain';
import {
  ICreatePaymentRequestDto,
  IGrainContract,
  IPaymentRequest,
} from '../common/types';
import { http } from '../http-common';

export const createPaymentRequest = async (
  dto: ICreatePaymentRequestDto
): Promise<IPaymentRequest> => {
  return http
    .post<IPaymentRequest>(`/${PLATFORM_PREFIX}/payment-requests`, dto)
    .then((resp: { data: IPaymentRequest }) => {
      const paymentRequest: IPaymentRequest = resp.data;
      return paymentRequest;
    })
    .catch((error: any) => {
      throw new Error(error.response.data?.message);
    });
};

export const getPaymentRequestsFromBusiness = ({
  queryKey,
}: any): Promise<IPaymentRequest[]> => {
  const [, id] = queryKey;
  return http
    .get<IPaymentRequest[]>(
      `/${PLATFORM_PREFIX}/payment-requests/business/${id}`
    )
    .then((resp: { data: IPaymentRequest[] }) => {
      const paymentRequests: IPaymentRequest[] = resp.data;
      return paymentRequests;
    })
    .catch((error: any) => {
      throw new Error(error.response.data?.message);
    });
};

export const getFutureGrainContractsByPaymentRequestId = (
  paymentRequestId: number
): Promise<IGrainContract> => {
  return http
    .get<IGrainContract>(
      `/${PLATFORM_PREFIX}/payment-requests/${paymentRequestId}/warranty-metadata`
    )
    .then((resp) => {
      const dataResult: IGrainContract = resp.data;
      return dataResult;
    });
};

export const getFixedGrainContractByPaymentRequestUuid = ({
  queryKey,
}: any): Promise<FixedGrain> => {
  const [, uuid] = queryKey;

  return http
    .get<FixedGrain>(
      `/${PLATFORM_PREFIX}/payment-requests/${uuid}/fixed-metadata`
    )
    .then((resp) => {
      const dataResult: FixedGrain = resp.data;
      return dataResult;
    });
};

export const getPaymentRequest = (uuid: string): Promise<IPaymentRequest> => {
  return http
    .get<IPaymentRequest>(`/${PLATFORM_PREFIX}/payment-requests/${uuid}/lite`)
    .then((resp: { data: IPaymentRequest }) => {
      const paymentRequest: IPaymentRequest = resp.data;
      return paymentRequest;
    });
};

export const getPaymentRequestDetail = (
  uuid: string
): Promise<IPaymentRequest> => {
  return http
    .get<IPaymentRequest>(
      `/${PLATFORM_PREFIX}/payment-requests/${uuid}/payment-detail/lite`
    )
    .then((resp: { data: IPaymentRequest }) => {
      const paymentRequest: IPaymentRequest = resp.data;
      return paymentRequest;
    });
};

export const getForwardingsDetail = async (
  uuid: string
): Promise<Forwarding> => {
  return await http
    .get<Forwarding>(`/${PLATFORM_PREFIX}/payment-requests/forwarding/${uuid}`)
    .then((resp: { data: any }) => {
      const paymentRequest: Forwarding = resp.data;
      return paymentRequest;
    });
};

export const makePayment = (uuid: string, cryptoId?: number) => {
  return async (): Promise<void> => {
    return http
      .patch<void>(`/${PLATFORM_PREFIX}/payment-requests/${uuid}/pay`, {
        currencyId: cryptoId,
      })
      .then((resp: any) => {
        return resp;
      });
  };
};

export const getPaymentFixation = async (
  paymentUuid: string,
  userUuid: string,
  currencyId: number,
  region: ENationalities
): Promise<{
  reserve: number;
  feeReserve: number;
}> => {
  return http
    .get<{
      reserve: number;
      feeReserve: number;
    }>(
      `/${PLATFORM_PREFIX}/payment-requests/${paymentUuid}/user/${userUuid}/currency/${currencyId}`,
      { headers: { [CURRENT_REGION_HEADER]: region } }
    )
    .then(
      (resp: {
        data: {
          reserve: number;
          feeReserve: number;
        };
      }) => {
        const dataResult: {
          reserve: number;
          feeReserve: number;
        } = resp.data;
        return dataResult;
      }
    );
};

export const getPaymentRequestStatus = (
  uuid: string
): Promise<{ status: PaymentRequestStatus }> => {
  return http
    .get<{ status: PaymentRequestStatus }>(
      `/${PLATFORM_PREFIX}/payment-requests/${uuid}/status`
    )
    .then((resp: { data: { status: PaymentRequestStatus } }) => {
      const dataResult: {
        status: PaymentRequestStatus;
      } = resp.data;
      return dataResult;
    });
};

export const getDocumentUrl = (
  id: number,
  documentUuid: string
): Promise<string | undefined> => {
  return http
    .get<string | undefined>(
      `/${PLATFORM_PREFIX}/payment-requests/${id}/documents/${documentUuid}/download`
    )
    .then((resp) => {
      const dataResult: string | undefined = resp.data;
      return dataResult;
    })
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};
