import { useEffect, useMemo, useRef } from 'react';

import { OperationStatus, OPERATION_STATUSES } from '@common/types';

import { TransferStatus, TRANSFER_STATUSES } from '../../types';

interface UseIsInBlockchainCheckerParams {
  refetch: () => void;
  status?: TransferStatus;
  assetLockOperation?: { status: OperationStatus };
  assetUnlockOperation?: { status: OperationStatus };
  operation?: { status: OperationStatus };
}

const useIsInBlockchainChecker = ({
  status,
  assetLockOperation,
  assetUnlockOperation,
  operation,
  refetch,
}: UseIsInBlockchainCheckerParams) => {
  const isInBlockchain = useMemo(() => {
    switch (status) {
      case TRANSFER_STATUSES.CANCELLED:
      case TRANSFER_STATUSES.EXPIRED:
      case TRANSFER_STATUSES.REJECTED:
        return assetUnlockOperation?.status === OPERATION_STATUSES.PENDING;

      case TRANSFER_STATUSES.PENDING_APPROVAL:
        return assetLockOperation?.status === OPERATION_STATUSES.PENDING;

      case TRANSFER_STATUSES.PROCESSING:
      case TRANSFER_STATUSES.SUCCESSFUL:
        return operation?.status === OPERATION_STATUSES.PENDING;

      default:
        return false;
    }
  }, [assetLockOperation, assetUnlockOperation, operation, status]);

  const intervalRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (!isInBlockchain) return;

    const interval = setInterval(async () => {
      refetch();
    }, 6000);

    intervalRef.current = interval;

    return () => clearInterval(intervalRef.current);
  }, [isInBlockchain, refetch]);

  return { isInBlockchain };
};

export default useIsInBlockchainChecker;
