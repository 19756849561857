import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { CityType, CountryType } from '@def/shared';
import { http } from '../http-common';

export const getCountries = async (): Promise<CountryType[]> => {
  const result = await http.get<CountryType[]>(`/${PLATFORM_PREFIX}/countries`);
  return result.data;
};

export const getCities = async (
  geographicalDivisionId: number
): Promise<CityType[]> => {
  const result = await http.get<CityType[]>(
    `/${PLATFORM_PREFIX}/cities?geographical_division_id=${geographicalDivisionId}`
  );
  return result.data;
};
