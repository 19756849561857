import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import Decimal from 'decimal.js';
import { TOKENS_DEFAULT_DECIMALS } from '@common/const';
import { ETokens, ETokensBR } from '@common/types';
import { useGetConversion, useGetLocalCurrency } from '@hooks/index';
import { WEIGHT_UNITS } from '@agrotoken/common-utils';

export const useCalculateTokensToPayByFarmer = (params: {
  localAmount: Decimal;
  selectedToken: ETokensBR | ETokens;
  isFee?: boolean;
}): Decimal => {
  const { localCurrency } = useGetLocalCurrency();
  const { localAmount, selectedToken, isFee = false } = params;

  const convertedPrice = useGetConversion(
    localAmount.toNumber(),
    localCurrency,
    selectedToken,
    WEIGHT_UNITS.TONS
  );

  return roundValue({
    value: convertedPrice,
    operationType: isFee
      ? EPaymentRequestOperationType.FARMER_FEE
      : EPaymentRequestOperationType.TOKENS_TO_PAY_BY_FARMER,
    scale: TOKENS_DEFAULT_DECIMALS,
  });
};

export default useCalculateTokensToPayByFarmer;
