import {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { FC, HTMLAttributes, ChangeEvent, ReactNode } from 'react';

export interface Props extends HTMLAttributes<HTMLInputElement> {
  name: string;
  type?: 'text' | 'password' | 'date' | 'number' | 'email';
  label?: ReactNode;
  placeholder?: string;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  autoComplete?: 'on' | 'off';
  icon?: string;
  iconColor?: string;
  hintMessage?: string | object | null;
  errorMessage?: string | boolean;
  showError?: boolean;
  value?: string | number | undefined;
  isValid?: boolean;
  className?: string;
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (ev: ChangeEvent<HTMLInputElement>) => void;
  hasBullets?: boolean;
  canErase?: boolean;
  handleEraseClick?: () => void;
  maxLength?: number;
}

/**
 * @deprecated Must use Chakra-ui Input instead. We're working on getting rid of this component.
 * @see https://chakra-ui.com/docs/form/input
 */
export const Input: FC<Props> = ({
  type = 'text',
  label,
  name,
  placeholder,
  fullWidth = false,
  required = true,
  autoComplete = 'off',
  disabled = false,
  icon,
  hintMessage,
  errorMessage,
  showError = false,
  value,
  isValid = true,
  className = 'rounded placeholder:text-gray-500',
  hasBullets,
  onChange,
  onBlur,
  onFocus,
  onInput,
  canErase,
  handleEraseClick,
  iconColor,
  maxLength,
}) => {
  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={`block text-gray-700 text-sm font-medium ${
          label ? 'mb-1.5' : ''
        }`}
      >
        {label}
      </label>
      <div className="relative inline">
        <InputGroup>
          {icon && (
            <InputLeftElement>
              <FeatherIcon
                icon={icon}
                className={`absolute inset-y-0 left-4 ${
                  iconColor ? iconColor : 'text-gray-500'
                } my-auto`}
                size="20"
              />
            </InputLeftElement>
          )}
          <ChakraInput
            type={type}
            id={name}
            name={name}
            placeholder={placeholder}
            className={`${className} border py-2.5 pr-3.5 text-md text-gray-900 shadow-xs focus-visible:outline-0 disabled:bg-gray-50 disabled:text-gray-500 ${
              !showError || isValid
                ? 'border-gray-300 focus-visible:shadow-focus-primary focus:shadow-focus-primary focus:border-primary-300 focus-visible:border-primary-300'
                : !isValid &&
                  showError &&
                  'border-error-300 focus-visible:shadow-focus-error focus:shadow-focus-error'
            } ${icon ? 'pl-11' : 'pl-3.5'} ${fullWidth && 'w-full'} 
          `}
            aria-required={required}
            autoComplete={autoComplete}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onInput={onInput}
            onWheel={(e) => e.currentTarget.blur()}
            value={value}
            maxLength={maxLength}
          />
          {canErase && (
            <InputRightElement>
              <button type="button" onClick={handleEraseClick}>
                <FeatherIcon
                  icon="x"
                  className="absolute inset-y-0 right-3.5 text-gray-500 my-auto"
                  size="16"
                />
              </button>
            </InputRightElement>
          )}
        </InputGroup>

        {!isValid && showError && (
          <FeatherIcon
            icon="alert-circle"
            className="absolute inset-y-0 right-3.5 text-error-500 my-auto"
            size="16"
          />
        )}
      </div>
      {hintMessage && !showError && hasBullets ? (
        <p className={'mt-1.5 text-sm text-gray-500'}>{hintMessage}</p>
      ) : hintMessage && !showError ? (
        <p className={'mt-1.5 text-sm text-gray-500'}>{hintMessage}</p>
      ) : (
        !isValid &&
        errorMessage &&
        showError && (
          <p className="mt-1.5 text-sm text-error-500">{errorMessage}</p>
        )
      )}
    </div>
  );
};
