import { Flex, Text } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import React, { FC } from 'react';
import { ICON_DATA } from './const';
import { EVariationDirection } from './types';

interface PriceVariationProps {
  priceVariation: number;
}

const PriceVariation: FC<PriceVariationProps> = (props) => {
  const { priceVariation } = props;

  const variationDirection =
    priceVariation < 0
      ? EVariationDirection.NEGATIVE
      : priceVariation > 0
      ? EVariationDirection.POSITIVE
      : EVariationDirection.NEUTRAL;

  const { icon, textColor } = ICON_DATA[variationDirection];

  const formattedPriceVariation = priceVariation.toLocaleString('es-AR', {
    maximumFractionDigits: 2,
  });

  return (
    <Flex
      alignItems="center"
      columnGap="0.5"
      textColor={textColor}
      fontSize="sm"
    >
      <FeatherIcon icon={icon} size={14} strokeWidth={2} />
      <Text as="span">{`${
        variationDirection === EVariationDirection.POSITIVE ? '+' : ''
      }${formattedPriceVariation}%`}</Text>
    </Flex>
  );
};

export default PriceVariation;
