import { formatNumber } from '@common/utils';
import { FC, FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidateDetokenizeForm } from '@hooks/useValidateForm';
import {
  EDetokenizationType,
  EUnits,
  IGrainContract,
} from '../../../common/types';
import { Checkbox } from '../../Global/Checkbox';
import { Input } from '../../Global/Input';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Modal } from '@agrotoken-tech/ui';

interface Props {
  grainContract: IGrainContract;
  title?: string;
  text?: string;
  isOpen: boolean;
  icon?: string;
  isLoading?: boolean;
  setIsOpen: React.Dispatch<boolean>;
  onSubmit?: (ev: FormEvent) => void;
  handleDetokenizationSubmit?: () => void;
  detokenizationType: string;
  setDetokenizationType: React.Dispatch<React.SetStateAction<string>>;
  tokensToDetokenize: string;
  setTokensToDetokenize: React.Dispatch<React.SetStateAction<string>>;
  shouldShowError: boolean;
}
export const DetokenizeModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  grainContract,
  detokenizationType,
  setDetokenizationType,
  tokensToDetokenize,
  setTokensToDetokenize,
  handleDetokenizationSubmit,
  onSubmit,
  isLoading,
  shouldShowError,
}) => {
  const { t } = useTranslation();
  const { isValidTokens, isValidTokensMin, isValidTokensMax } =
    useValidateDetokenizeForm(+tokensToDetokenize, grainContract.amount);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const getQuantityToDetokenizeError = () => {
    const tonsErrors = [
      {
        validation: !isValidTokens,
        error: t('tokenization.summary.detokenizationModal.emptyError'),
      },
      {
        validation: !isValidTokensMin,
        error: t('tokenization.summary.detokenizationModal.minError'),
      },
      {
        validation: !isValidTokensMax,
        error: t('tokenization.summary.detokenizationModal.maxError'),
      },
    ];
    return tonsErrors.find((tonError) => tonError.validation)?.error;
  };

  //TODO: Integrate Inputs and Checkboxes with Agrotoken UI or Chakra

  return (
    <Modal
      title={t('tokenization.summary.detokenizationModal.title')}
      variantBtnConfirm={'primary'}
      size={'xl'}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <>
        <Text
          flex={'justify-start'}
          size="sm"
          variant="regular"
          color={'gray.500'}
          mb={4}
        >
          {t('tokenization.summary.detokenizationModal.text')}
        </Text>
        <div className="mb-3">
          <form onSubmit={onSubmit}>
            <Text
              flex={'justify-start'}
              size="sm"
              variant="medium"
              color={'gray.700'}
              mb={2}
            >
              {t('tokenization.summary.detokenizationModal.modalTitle')}
            </Text>
            <Flex justifyContent={'start'} rowGap={4}>
              <Box>
                <Checkbox
                  id="partialDestokenization"
                  onChange={(ev) =>
                    setDetokenizationType(ev.currentTarget.value)
                  }
                  checked={detokenizationType === EDetokenizationType.PARTIAL}
                  value={EDetokenizationType.PARTIAL}
                  name="partialdetokenization"
                  type="radio"
                  required
                  label="Parcial"
                  className="mb-2"
                />
                <Checkbox
                  id="totalDestokenization"
                  onChange={(ev) =>
                    setDetokenizationType(ev.currentTarget.value)
                  }
                  checked={detokenizationType === EDetokenizationType.TOTAL}
                  value={EDetokenizationType.TOTAL}
                  name="totaldetokenization"
                  type="radio"
                  required
                  label="Total"
                  className="mb-4"
                />
              </Box>
            </Flex>
            {detokenizationType === EDetokenizationType.PARTIAL && (
              <>
                <Input
                  name="quantityToDetokenize"
                  type="number"
                  fullWidth
                  placeholder={t(
                    'tokenization.summary.detokenizationModal.inputPlaceholder'
                  )}
                  value={tokensToDetokenize ?? ''}
                  onChange={(ev) =>
                    setTokensToDetokenize(ev.currentTarget.value)
                  }
                  showError={shouldShowError}
                  isValid={
                    isValidTokens && isValidTokensMin && isValidTokensMax
                  }
                  errorMessage={getQuantityToDetokenizeError()}
                />
                <Text
                  mt={1.5}
                  fontSize="sm"
                  variant="regular"
                  color={'gray.500'}
                >
                  {`${t(
                    'tokenization.summary.detokenizationModal.hintMessage'
                  )} ${formatNumber(grainContract.amount!, EUnits.CRYPTO)} ${
                    grainContract?.currency.name
                  } ${t(
                    'tokenization.summary.detokenizationModal.hintMessage2'
                  )}`}
                </Text>
              </>
            )}
            <Button
              variant="secondary"
              onClick={handleDetokenizationSubmit}
              mt="4"
              type="submit"
              isLoading={isLoading}
            >
              {t('tokenization.summary.detokenizationModal.btnDetokenizeLabel')}
            </Button>
          </form>
        </div>
      </>
    </Modal>
  );
};
