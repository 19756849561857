import React, { FC, HTMLAttributes } from 'react';
import agrotoken from '../../assets/logos/logo_standard.svg';
import image from '../../assets/images/cloud.png';
import { Heading, Text } from '@chakra-ui/react';

export const DeletedInvitation = () => {
  return (
    <div className="flex h-screen">
      <div className="max-w-sm m-auto">
        <div className="mb-8 w-full flex">
          <img src={agrotoken} alt="" className="inline-block max-h-6" />
        </div>
        <div className="mb-8">
          <Heading as="h3" size="lg" my="1">
            Esta invitación fue eliminada
          </Heading>
          <Text fontSize="lg" color="gray.700" mb="8">
            Si tienes alguna consulta contáctanos a soporte@agrotoken.io
          </Text>
        </div>
      </div>
      <div className="hidden lg:block max-w-sm m-auto">
        <img src={image} alt="Log In" />
      </div>
    </div>
  );
};

export default DeletedInvitation;
