import { ComponentTranslationType } from '../../es/partials';

const COMPONENTS_TRANSLATIONS_PT: ComponentTranslationType = {
  summaryDetail: {
    emptyState:
      'Ao preencher as informações, você poderá ver um resumo de sua solicitação.',
  },
  transactionExpirationTimer: {
    text: 'Expira em',
  },
};

export default COMPONENTS_TRANSLATIONS_PT;
