import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';
import { RATES_REFETCH_INTERVAL } from '@common/const';
import { useGetAllCurrenciesByRegion } from '@hooks/index';
import { PricingCurrency } from '../common/types';

interface Props {
  children: ReactNode;
}

interface RatesContextProps {
  isErrorRates: boolean;
  isLoadingRates: boolean;
  currencies: PricingCurrency[];
  getRates: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
  errorRates: {
    message: string;
  } | null;
}

const RatesContext = React.createContext<RatesContextProps>(null!);

export const useRates = () => {
  return useContext(RatesContext);
};

export const RatesProvider = ({ children }: Props) => {
  const intervalRef = useRef<NodeJS.Timeout | undefined>();
  const {
    isLoading: isLoadingRates,
    isError: isErrorRates,
    errorRates,
    currencies,
    setCurrencies,
    refetch,
  } = useGetAllCurrenciesByRegion();

  useEffect(() => {
    refetch();
    const interval = setInterval(() => {
      refetch();
    }, RATES_REFETCH_INTERVAL);

    intervalRef.current = interval;

    return () => {
      clearInterval(intervalRef.current);
      setCurrencies([]);
    };
  }, []);

  const value = {
    getRates: refetch,
    isErrorRates,
    isLoadingRates,
    currencies,
    errorRates,
  };

  return (
    <RatesContext.Provider value={value}>{children}</RatesContext.Provider>
  );
};
