export const CoinsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="4" fill="#DBF5D4" />
    <g clipPath="url(#clip0_21981_363396)">
      <path
        d="M10.711 8.07901C12.1493 6.64005 14.482 6.64061 15.9209 8.07901C17.3597 9.51741 17.3597 11.8503 15.9209 13.2893M14.3687 13.3154C14.3687 15.3503 12.7191 17 10.6843 17C8.64954 17 7 15.3503 7 13.3154C7 11.2804 8.64954 9.63075 10.6843 9.63075C12.7191 9.63075 14.3687 11.2804 14.3687 13.3154Z"
        stroke="#288703"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_21981_363396">
        <rect width="12" height="12" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);
