import { useQuery } from 'react-query';
import { IMovement } from '@common/types';
import { getMovementsForWallet } from '@services/Movements';
import { useUser } from '@src/context/userContext';

export const useGetMovementsForWallet = () => {
  const { currentUser, selectedBusinessId } = useUser();

  const { isLoading, data, isError } = useQuery<IMovement[] | null>(
    ['movements', currentUser, selectedBusinessId],
    async () => {
      if (!currentUser || !selectedBusinessId) return null;
      const movements = await getMovementsForWallet();
      return movements.reverse();
    }
  );

  return {
    isLoading,
    data,
    isError,
  };
};

export default useGetMovementsForWallet;
