import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import { Button, Box, Flex, Text } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import FeatherIcon from 'feather-icons-react';
import React, { FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  DETOKENIZATIONS_PATH,
  SUCCESS_PATH,
  TOKENIZATIONS_PATH,
} from '@common/const';
import { GrainContractTypes } from '@common/enum';
import {
  FutureTokenizationStep,
  RealTokenizationStep,
  EGrainContractStatus,
  EDocusignType,
  IGrainContract,
  IGrainContractStatus,
  UserTypes,
  OpertationDescription,
} from '@common/types';
import { isPendingDocusignRescission } from '@common/utils';
import BreakDownFixedGrain from '@comp/BR/Tokenization/BreakDownFixedGrain';
import { TimeLine } from '@comp/Global/TimeLine';
import { createAndViewDocusign } from '@services/GrainContracts';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import { Actions } from './Actions';

interface Props {
  isPendingApproval: boolean;
  isFutureGrain: boolean;
  grainContract: IGrainContract;
  isOfferLetterPendingUser: boolean;
  isOfferLetterPendingAgtk: boolean;
  isOfferLetterSigningComplete: boolean;
  isNotificationLetterPending: boolean;
  isBlockchainStatus: boolean;
  isTokenizationCompleted: boolean;
  setIsLoadingOfferLetter: React.Dispatch<React.SetStateAction<boolean>>;
  isDocuSignCreditAssignment: boolean;
  lastOperation: string | undefined;
  grainContractStatus: IGrainContractStatus;
  setIsOpenMoreInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenDetokenizeModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenRenovationModal: React.Dispatch<React.SetStateAction<boolean>>;
  canRenewDate: () => boolean;
  expiredControl: () => {
    isExpired: boolean;
    date: string;
  };
  isUserCreator: boolean;
}

const SideContent: FC<Props> = ({
  isPendingApproval,
  isFutureGrain,
  grainContract,
  isOfferLetterPendingUser,
  isOfferLetterPendingAgtk,
  isOfferLetterSigningComplete,
  isNotificationLetterPending,
  isBlockchainStatus,
  isTokenizationCompleted,
  setIsLoadingOfferLetter,
  isDocuSignCreditAssignment,
  lastOperation,
  grainContractStatus,
  setIsOpenMoreInfo,
  setIsOpenDetokenizeModal,
  setIsOpenRenovationModal,
  canRenewDate,
  expiredControl,
  isUserCreator,
}) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();

  const { currentUser } = useUser();
  const { id } = useParams();

  const availableTons = new Decimal(grainContract.amount ?? 0).add(
    grainContract.frozenAmount ?? 0
  );
  const tonsCompletlyUsed = availableTons.equals(
    new Decimal(grainContract.tonsVerified ?? 0)
  );

  const Breakdown_Ar = React.lazy(
    () => import('../../AR/Tokenization/BreakDown')
  );
  const Breakdown_Br = React.lazy(
    () => import('../../BR/Tokenization/BreakDown')
  );

  const breakDown = getMultiRegionMap({
    AR: Breakdown_Ar,
    BR: Breakdown_Br,
  });
  const BreakDownComponent = breakDown[nationality];

  const {
    mutate: mutateCreateAndViewDocusign,
    isLoading: isLoadingCreateAndViewDocusign,
  } = useMutation(createAndViewDocusign, {
    onSuccess: (data) => {
      window.location.replace(data?.url);
    },
  });
  const handleCreateAndSignLetter = async () => {
    if (!id) return;
    setIsLoadingOfferLetter(true);
    const body = {
      sign: true,
      returnUrl: `${
        process.env.REACT_APP_BASE_URL
      }${TOKENIZATIONS_PATH}/${id}${SUCCESS_PATH}?region=${nationality.toLowerCase()}`,
      userId: currentUser?.id,
      grainContractId: parseInt(id),
    };
    const response = await mutateCreateAndViewDocusign({
      body,
      docusignTypeId: EDocusignType.OFFER_LETTER,
    });
    setIsLoadingOfferLetter(false);
  };

  const handleCreateAndSignRescission = async () => {
    if (!id) return;

    const body = {
      sign: true,
      returnUrl: `${
        process.env.REACT_APP_BASE_URL
      }${DETOKENIZATIONS_PATH}/${id}${SUCCESS_PATH}?region=${nationality.toLowerCase()}`,
      userId: currentUser?.id,
      grainContractId: parseInt(id),
    };
    const response = await mutateCreateAndViewDocusign({
      body,
      docusignTypeId: EDocusignType.RESCISSION_NOTIFICATION,
    });
  };

  return (
    <div>
      {isPendingApproval && !isFutureGrain && (
        <div>
          <TimeLine
            currentStep={
              isFutureGrain
                ? FutureTokenizationStep.SUMMARY
                : RealTokenizationStep.SUMMARY
            }
            grainContract={grainContract}
          />
        </div>
      )}
      {(isOfferLetterPendingUser ||
        isOfferLetterPendingAgtk ||
        isOfferLetterSigningComplete ||
        isNotificationLetterPending ||
        isBlockchainStatus ||
        (isPendingApproval && isFutureGrain) ||
        isTokenizationCompleted) && (
        <Suspense fallback={<div>Loading...</div>}>
          {grainContract.grainContractType ===
          GrainContractTypes.FIXED_GRAIN ? (
            <BreakDownFixedGrain grainContract={grainContract} />
          ) : (
            <BreakDownComponent
              isTokenizationCompleted={isTokenizationCompleted}
              isFutureGrain={isFutureGrain}
              grainContract={grainContract}
            />
          )}
        </Suspense>
      )}

      {isUserCreator &&
        nationality === ENationalities.AR &&
        isOfferLetterPendingUser && (
          <>
            <Button
              mt="4"
              w="full"
              variant="primary"
              size="lg"
              isLoading={isLoadingCreateAndViewDocusign}
              onClick={() => handleCreateAndSignLetter()}
            >
              {t('tokenization.summary.btnLabel')}
            </Button>
          </>
        )}
      {isUserCreator &&
        nationality === ENationalities.BR &&
        isOfferLetterPendingUser &&
        isDocuSignCreditAssignment && (
          <Button
            isLoading={isLoadingCreateAndViewDocusign}
            onClick={() => handleCreateAndSignLetter()}
            w="full"
            size="lg"
            mt="6"
            variant="primary"
          >
            {t('tokenization.summary.btnLabel')}
          </Button>
        )}
      {nationality === ENationalities.BR &&
        lastOperation === OpertationDescription.DETOKENIZATION_COLATERAL && (
          <Box className="mt-6 border p-6">
            <Text mb={4}>
              {t('wallet.movementsTable.transactionType.detokenization')}
            </Text>
            <Flex gap={2}>
              <FeatherIcon icon="info" size={20} className="inline" />
              <Flex flexDirection="column" width="100%" alignItems={'start'}>
                <Text className="italic">
                  {t('tokenization.summary.collateralDetokization')}
                </Text>
                <Button variant={'link'} rightIcon={<FiArrowRight />} mt={2}>
                  {t('tokenization.summary.new')}
                </Button>
              </Flex>
            </Flex>
          </Box>
        )}
      {nationality === ENationalities.BR &&
        isPendingDocusignRescission(
          grainContract,
          isDocuSignCreditAssignment
        ) &&
        isUserCreator && (
          <Button
            isLoading={isLoadingCreateAndViewDocusign}
            onClick={() => handleCreateAndSignRescission()}
            w="full"
            size="lg"
            mt="3"
            variant="primary"
          >
            {t('detokenization.summary.btnLabel')}
          </Button>
        )}
      {(grainContractStatus.name === EGrainContractStatus.TOKENIZED ||
        grainContractStatus.name ===
          EGrainContractStatus.DETOKENIZATION_IN_PROGRESS) &&
        !expiredControl().isExpired &&
        currentUser?.userType !== UserTypes.MANAGER && (
          <Actions
            isFutureGrain={isFutureGrain}
            tonsCompletlyUsed={tonsCompletlyUsed}
            onClickMoreInfo={() => setIsOpenMoreInfo(true)}
            onClickDetokenize={() => setIsOpenDetokenizeModal(true)}
            onClickRenew={() => setIsOpenRenovationModal(true)}
            canRenew={canRenewDate()}
            className="mt-6"
            grainContract={grainContract}
            expiredControl={expiredControl()}
          />
        )}
    </div>
  );
};

export default SideContent;
