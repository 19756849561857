import { HOME_PATH, SUPPORT_EMAIL } from '@common/const';
import { useAccess } from '@src/context/accessContext';
import { useAuth } from '@src/context/authContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@comp/Global/Modal';
import { useFullOnboardingAccess } from '@src/context/fullOnboardingAccessContext';
import { FiCheckCircle, FiUserCheck } from 'react-icons/fi';

const RequireOnboardingLevelThreeAccess: FC = ({ children }) => {
  const {
    hasOnboardingLevel3Completed_v2,
    hasOnboardingLevel3PendingVerification_v2,
  } = useFullOnboardingAccess();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { nationality } = useAuth();

  if (hasOnboardingLevel3PendingVerification_v2) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiCheckCircle}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (hasOnboardingLevel3Completed_v2) {
    return (
      <Modal
        isOpen={true}
        title={t('onboardingLevelThreeAccessModal.titleNoPermissions')}
        labelBtnConfirm={t('onboardingLevelThreeAccessModal.confirmBtn')}
        iconName="alert-circle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      >
        <p>
          <span className="text-md font-normal text-gray-700">
            {t('onboardingLevelThreeAccessModal.textNoPermissions')}
          </span>{' '}
          <a
            className="text-md font-bold underline text-primary-700"
            href={`mailto:${SUPPORT_EMAIL[nationality]}`}
          >
            {SUPPORT_EMAIL[nationality]}
          </a>
        </p>
      </Modal>
    );
  }
  return <>{children}</>;
};

export default RequireOnboardingLevelThreeAccess;
