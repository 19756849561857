import FeatherIcon from 'feather-icons-react';
import { FC, HTMLAttributes, ReactNode } from 'react';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  size: 'sm' | 'md' | 'lg';
  color:
    | 'gray'
    | 'primary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'purple'
    | 'cancelled';
  label?: string;
  icon?: ReactNode;
  iconPosition?: 'leading' | 'trailing' | 'only';
  iconSize?: number;
}

const colors: { [key: string]: string } = {
  gray: 'bg-gray-100 text-gray-700',
  primary: 'bg-primary-50 text-primary-700',
  error: 'bg-error-50 text-error-700',
  warning: 'bg-warning-50 text-warning-700',
  success: 'bg-success-50 text-success-700',
  info: 'bg-info-50 text-blue-700',
  purple: 'bg-purple-50 text-purple-700',
};

const fontSizes: { [key: string]: string } = {
  sm: 'text-xs font-normal',
  md: 'text-sm font-medium',
  lg: 'text-sm font-medium',
};

const paddings: { [key: string]: string } = {
  sm: 'px-2 py-0.5',
  md: 'px-2.5 py-0.5',
  lg: 'px-3 py-1',
};

const paddingsOnly: { [key: string]: string } = {
  sm: 'p-1.5',
  md: 'p-2',
  lg: 'p-2.5',
};

export const Badge: FC<Props> = ({
  size,
  color,
  label,
  icon,
  iconPosition,
  iconSize = 7.5,
}) => {
  return (
    <div
      className={`whitespace-nowrap ${colors[color]} ${
        fontSizes[size]
      } w-fit rounded-full flex items-center ${
        iconPosition !== 'only' ? paddings[size] : paddingsOnly[size]
      } ${iconPosition === 'trailing' && 'flex-row-reverse'}`}
    >
      {icon && typeof icon === 'string' ? (
        <FeatherIcon
          icon={icon}
          size={iconSize}
          fill={icon === 'circle' ? 'currentColor' : 'none'}
        />
      ) : (
        <>{icon}</>
      )}
      {iconPosition !== 'only' && (
        <span
          className={`${
            icon && iconPosition === 'leading'
              ? 'ml-1'
              : icon && iconPosition === 'trailing'
              ? 'mr-1'
              : ''
          }`}
        >
          {label}
        </span>
      )}
    </div>
  );
};
