import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  text: string;
  linkLabel: string;
  to: string;
}

export const Shortcut: FC<Props> = ({ title, text, linkLabel, to }) => {
  const navigate = useNavigate();
  return (
    <Flex
      direction="column"
      justify="space-between"
      p="4"
      backgroundColor="white"
      borderColor="gray.200"
      borderWidth="1px"
      borderRadius="4px"
      alignItems="start"
    >
      <Box>
        <Text
          mb="2"
          fontSize="lg"
          color="gray.900"
          fontFamily="Larsseit-Medium"
        >
          {title}
        </Text>
        <Text fontSize="md" color="gray.700" mb="2">
          {text}
        </Text>
      </Box>
      <Button
        variant="link"
        onClick={() => navigate(to)}
        rightIcon={<FiArrowRight />}
      >
        {linkLabel}
      </Button>
    </Flex>
  );
};
