import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { ReactNode, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from './authContext';
import { useUser } from './userContext';

interface CardsContextType {
  shouldShowCardsMenu?: boolean;
  setShouldShowCardsMenu?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingShouldShowCardMenu?: boolean;
  setShowCardShipmentForm: React.Dispatch<React.SetStateAction<boolean>>;
  showCardShipmentForm: boolean;
}

interface Props {
  children: ReactNode;
}

const CardsContext = React.createContext<any>(null);

export const useCards = () => {
  return useContext(CardsContext);
};

export const CardsProvider = ({ children }: Props) => {
  const { isAuth, nationality } = useAuth();
  const { requestCardFlowInPlatformCards } = useFlags();
  const userContext = useUser();

  const [shouldShowCardsMenu, setShouldShowCardsMenu] =
    useState<boolean>(false);
  const [showCardShipmentForm, setShowCardShipmentForm] =
    useState<boolean>(false);

  const selectedBusinessUserId = userContext?.selectedBusinessUserId;

  const value = {
    shouldShowCardsMenu,
    setShouldShowCardsMenu,
    showCardShipmentForm,
    setShowCardShipmentForm,
  };

  return (
    <CardsContext.Provider value={value}>{children}</CardsContext.Provider>
  );
};
