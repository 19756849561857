import { TokenCurrency } from '@agrotoken/common-utils';
import { Flex, Heading, Text, useToken } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiClock } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import { Origin } from '@common/types';

import { WarrantyTransferBreadcrumb } from '../../components';
import { useCreateWarrantyTransferData } from '../hooks';

const CreateWarrantyTransferHeader = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.createWarrantyTransfer.heading',
  });

  const { gcRequestNumber } = useParams();
  const { origin, warranty, transferExpirationLimitHours, isLoadingWarranty } =
    useCreateWarrantyTransferData();

  const gray600 = useToken('colors', 'gray.600');

  return (
    <>
      {!isLoadingWarranty && (
        <WarrantyTransferBreadcrumb
          assetName={warranty?.currency.name as TokenCurrency}
          requestNumber={Number(gcRequestNumber)}
          origin={origin as Origin}
          isTransferCreation
        />
      )}
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'initial', md: 'center' }}
        columnGap="4"
        rowGap="2"
        mt="5"
        mb="2"
      >
        <Heading size="md" color="gray.900" variant="medium">
          {t('title')}
        </Heading>
        <Flex columnGap="1">
          <FiClock size="20" color={gray600} />
          <Text size="sm" color="gray.600" variant="regular">
            {t('expiration')}
            <Text as="span" size="sm" color="gray.600" variant="medium">
              {` ${transferExpirationLimitHours}h`}
            </Text>
          </Text>
        </Flex>
      </Flex>
      <Text size="md" color="gray.600" variant="regular">
        {t('subtitle')}
      </Text>
    </>
  );
};

export default CreateWarrantyTransferHeader;
