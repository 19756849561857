import { Heading, Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@comp/Global/Loader';
import { IResponseCreateCardShippingAddress } from '@def/shippingAddress';
import AppExample from '../../../../assets/images/appExample.svg';
import GetItOnAppStore from '../../../../assets/logos/getItOnAppStore.svg';
import GetItOnGooglePlay from '../../../../assets/logos/getItOnGooglePlay.svg';
import CardRequestedSummaryItem from './components/CardRequestedSummaryItem';

interface IThirdStepProps {
  shippingAddress: IResponseCreateCardShippingAddress;
}
const ThirdStep: FC<IThirdStepProps> = ({ shippingAddress }) => {
  const { t } = useTranslation();

  return (
    <>
      {!shippingAddress ? (
        <Loader />
      ) : (
        <Flex
          flexDirection="column"
          maxWidth={{ base: '80%', md: '706px' }}
          margin="auto"
          justifyContent="center"
          marginTop="48px"
          display={{ base: 'none', sm: 'flex' }}
        >
          <Box
            textAlign="center"
            fontFamily="Larsseit"
            fontStyle="normal"
            marginBottom={6}
          >
            <Heading fontWeight="medium" fontSize="36px" lineHeight="44px">
              {t('card.cardRequested.title')}
            </Heading>
            <Heading fontWeight="400" fontSize="18px" lineHeight="28px">
              {t('card.cardRequested.subtitle')}
            </Heading>
          </Box>
          <Box
            background="#FFDFB5"
            fontFamily="Larsseit"
            fontStyle="normal"
            borderRadius="4px"
          >
            <Flex paddingTop="21px" gap="52px" alignItems="center" paddingX={4}>
              <Image src={AppExample} display={{ base: 'none', md: 'flex' }} />
              <Box marginRight="29px">
                <Heading fontWeight="medium" fontSize="xl" lineHeight="30px">
                  {t('card.cardRequested.advertisingDescription.title')}
                </Heading>
                <Heading fontWeight="normal" fontSize="lg" lineHeight="28px">
                  {t('card.cardRequested.advertisingDescription.description')}
                </Heading>
                <Flex
                  marginTop={6}
                  gap={4}
                  marginBottom={{ base: '15px', md: '0' }}
                >
                  <Link
                    href={process.env.REACT_APP_ANDROID_STORE_URL}
                    target="_blank"
                  >
                    <Image src={GetItOnGooglePlay} />
                  </Link>
                  <Link
                    href={process.env.REACT_APP_APPLE_STORE_URL}
                    target="_blank"
                  >
                    <Image src={GetItOnAppStore} />
                  </Link>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box
            borderRadius="4px"
            fontFamily="Larsseit"
            fontStyle="normal"
            backgroundColor="gray.50"
            marginTop="32px"
            padding={4}
            textAlign="left"
          >
            <Heading
              fontWeight="medium"
              fontSize="16px"
              lineHeight="24px"
              marginTop={4}
            >
              {t('card.cardRequested.shipmentDescription.title')}
            </Heading>
            <Flex>
              <CardRequestedSummaryItem
                title={`${t('card.shipmentCard.form.street')}`}
                content={shippingAddress.legalAddress}
              />
              <CardRequestedSummaryItem
                title={`${t('card.shipmentCard.form.streetNumber')}`}
                content={shippingAddress.streetNumber}
              />
            </Flex>
            <hr />
            <Flex>
              <CardRequestedSummaryItem
                title={`${t('card.shipmentCard.form.geographicalDivision')}`}
                content={shippingAddress.geographicalDivisionName}
              />
              <CardRequestedSummaryItem
                title={`${t('card.shipmentCard.form.city')}`}
                content={shippingAddress.cityName}
              />
            </Flex>
            <hr />
            <CardRequestedSummaryItem
              title={`${t('card.shipmentCard.form.postalCode')}`}
              content={shippingAddress.postalCode}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default ThirdStep;
