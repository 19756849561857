import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { Cpr } from '@common/types';
import { http } from '@src/http-common';

// export const createCpr = (data: any): Promise<Cpr> => {
//   return http
//     .post<Cpr>(`grain-contracts/${data.grainContractId}/cprs`, data.body)
//     .then((resp) => {
//       const dataResult: Cpr = resp.data;
//       return dataResult;
//     });
// };
export const createCpr = (data: any): Promise<Cpr> => {
  return http.post<Cpr>(`/${PLATFORM_PREFIX}/cprs`, data.body).then((resp) => {
    const dataResult: Cpr = resp.data;
    return dataResult;
  });
};
export const updateCpr = (data: any): Promise<Cpr> => {
  return http
    .patch<Cpr>(`/${PLATFORM_PREFIX}/cprs/${data.cprUuid}`, data.body)
    .then((resp) => {
      const dataResult: Cpr = resp.data;
      return dataResult;
    });
};
