import { Box, Spinner } from '@chakra-ui/react';
import { HOME_PATH } from '@src/common/const';
import {
  EBackOfficeValidationStatus,
  EBusinessStructureTypes,
  EDocumentTypes,
  EFullOnboardingLevel,
  IBusiness,
  IDocumentPreviewForBusinessUser,
  IUserKYCData,
  UpdateBusinessUserDto,
} from '@src/common/types';
import { useAccess } from '@src/context/accessContext';
import { useUser } from '@src/context/userContext';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { updateBusinessUser } from '@services/BusinessUsers';
import { getUser } from '@services/Users';
import { TYPE_OF_LEGAL_POWER_DOC } from '../DocOfRepresentative/consts';
import RepresentativeDocsFields from '../KYC/RepresentativeDocsFields';
import Form from '../common/layouts/Form';
import { IncompleteFieldError } from '../types';
import { getIncompleteFieldsForRepDocsDataLevel2 } from '../validations';
import { getTodayTimestamp } from '@common/utils';

const RepresentativeDocsPage = () => {
  const user = useUser();
  const navigate = useNavigate();
  const { canOnlyCreatePaymentLink, canOnlyViewWallet } = useAccess();
  let { selectedBusinessUserWasInvited } = user;
  selectedBusinessUserWasInvited =
    selectedBusinessUserWasInvited && !user.selectedBusinessUserWasAdminInvited;
  const currentUserId = user.currentUser?.id;
  const { data: userStoredData, isLoading: userStoredDataLoading } = useQuery<
    IUserKYCData | undefined
  >(['user', currentUserId], getUser);

  const formMethods = useForm();
  const { handleSubmit, watch, reset } = formMethods;

  const [statuteDocumentId, setStatuteDocumentId] = useState<number | null>(
    null
  );
  const [authoritiesDocumentId, setAuthoritiesDocumentId] = useState<
    number | null
  >(null);

  const [legalPowerDocumentIds, setLegalPowerDocumentIds] = useState<number[]>(
    []
  );

  const [defaultBusinessUserDocuments, setDefaultBusinessUserDocuments] =
    useState<IDocumentPreviewForBusinessUser[]>([]);

  const [missingFileError, setMissingFileError] = useState<
    IncompleteFieldError['errors']
  >({});

  const [isLoading, setIsLoading] = useState(false);

  const selectedBusiness: IBusiness | null = user.selectedBusiness;
  const selectedBusinessUser = userStoredData?.businessUsers?.find(
    ({ businessId }) => businessId === selectedBusiness?.id
  );
  const selectedBusinessUserId = selectedBusinessUser?.id;
  const businessUserDocuments =
    selectedBusinessUser?.businessUserDocuments || [];

  const isCompany =
    EBusinessStructureTypes.COMPANY ===
    selectedBusiness?.businessStructureTypeId;

  const isPerson =
    EBusinessStructureTypes.PERSON ===
    selectedBusiness?.businessStructureTypeId;

  const selectedBusinessUserIsCreator = !selectedBusinessUserWasInvited;

  const personDonsNotNeedRepresentativeDocs =
    isPerson &&
    (selectedBusinessUserIsCreator ||
      canOnlyViewWallet ||
      canOnlyCreatePaymentLink);

  const companyDoesNotNeedRepresentativeDocs =
    isCompany &&
    selectedBusinessUserWasInvited &&
    (canOnlyCreatePaymentLink || canOnlyViewWallet);

  const shouldHideLegalPowerSection =
    companyDoesNotNeedRepresentativeDocs || personDonsNotNeedRepresentativeDocs;

  const getIncompleteFields = () => {
    let fields: IncompleteFieldError = { errors: {} };
    if (!shouldHideLegalPowerSection) {
      // Check Rep docs data
      fields = {
        errors: {
          ...fields.errors,
          ...getIncompleteFieldsForRepDocsDataLevel2({
            [TYPE_OF_LEGAL_POWER_DOC]: typeOfLegalPower,
            legalPowerDocumentIds,
            statuteDocumentId,
            authoritiesDocumentId,
          }).errors,
        },
      };
    }
    setMissingFileError(fields.errors);
    return fields;
  };

  const isReady = useMemo(() => {
    if (
      !userStoredData ||
      !currentUserId ||
      isLoading ||
      userStoredDataLoading ||
      !selectedBusinessUser
    ) {
      return false;
    }

    setDefaultBusinessUserDocuments(businessUserDocuments || []);
    const businessUserLegalPowerDocument =
      businessUserDocuments?.filter(
        ({ document }) =>
          document.documentType.id === EDocumentTypes.LEGAL_POWER
      ) || [];

    const businessUserHasLegalPower = businessUserLegalPowerDocument.length > 0;

    reset({
      [TYPE_OF_LEGAL_POWER_DOC]: businessUserHasLegalPower
        ? String(EDocumentTypes.LEGAL_POWER)
        : String(EDocumentTypes.STATUTE_OR_SOCIAL_CONTRACT),
    });

    return true;
  }, [
    userStoredData,
    currentUserId,
    isLoading,
    userStoredDataLoading,
    selectedBusinessUser,
  ]);

  const onSubmit = () => {
    const fields = getIncompleteFields();
    const hasErrors = Object.keys(fields.errors).length > 0;
    if (!hasErrors) {
      save()
        .finally(() => setIsLoading(false))
        .then(() => navigate(HOME_PATH));
    }
  };

  const handleCompleteLater = () => {
    // TODO: We need to model the 'draft' mode, that lets the user save a preview but without confirming that the
    // onboarding is ready to be reviewed.
    navigate(HOME_PATH);
  };

  const typeOfLegalPower = Number(watch(TYPE_OF_LEGAL_POWER_DOC));
  const businessRequiredDocuments =
    typeOfLegalPower === EDocumentTypes.LEGAL_POWER
      ? legalPowerDocumentIds
      : [statuteDocumentId, authoritiesDocumentId];

  const save = async () => {
    const today = getTodayTimestamp();

    if (selectedBusinessUserId) {
      const body: UpdateBusinessUserDto = {
        ...(businessRequiredDocuments.length
          ? { documentIds: businessRequiredDocuments as number[] }
          : {}),
        validationStatusId: EBackOfficeValidationStatus.PENDING,
        ...(selectedBusinessUser.fullOnboardingLevelId ===
        EFullOnboardingLevel.LEVEL1
          ? {
              fullOnboardingLevelId: EFullOnboardingLevel.LEVEL2,
            }
          : {}),
      };
      await updateBusinessUser({
        id: Number(selectedBusinessUserId),
        body,
      });
    }
  };

  return isReady ? (
    <FormProvider {...formMethods}>
      <Form
        handleSubmit={handleSubmit(onSubmit)}
        isLoading={isLoading}
        secondaryAction={handleCompleteLater}
        currentStep={2}
      >
        <RepresentativeDocsFields
          statuteDocumentId={statuteDocumentId}
          setStatuteDocumentId={setStatuteDocumentId}
          authoritiesDocumentId={authoritiesDocumentId}
          setAuthoritiesDocumentId={setAuthoritiesDocumentId}
          legalPowerDocumentIds={legalPowerDocumentIds}
          setLegalPowerDocumentIds={setLegalPowerDocumentIds}
          missingFileError={missingFileError}
          defaultBusinessUserDocuments={defaultBusinessUserDocuments}
        />
      </Form>
    </FormProvider>
  ) : (
    <Box className="m-auto py-10">
      <Spinner />
    </Box>
  );
};

export default RepresentativeDocsPage;
