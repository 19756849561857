import { useQuery } from 'react-query';
import { Nationality } from '@def/nationality';
import { getNationatilities } from '@services/Nationalities';

const useNationalities = () => {
  const {
    data: nationalities,
    isLoading,
    error,
  } = useQuery<Nationality[] | undefined>(
    ['nationalities'],
    getNationatilities
  );

  return {
    nationalities,
    isLoading,
    error,
  };
};

export default useNationalities;
