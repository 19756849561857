import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { http } from '@src/http-common';

export interface IViacepResponse {
  postalCode: string;
  address: string;
  neighborhood: string;
  complement: string;
  geographicalDivisionId: number;
  cityId: number;
}

export const getViacepData = async (cep: string): Promise<IViacepResponse> => {
  const response = await http.get(
    `/${PLATFORM_PREFIX}/integrations/viacep/${cep}`
  );
  return response.data;
};
