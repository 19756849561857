import { ENationalities, PLATFORM_PREFIX } from '@agrotoken/common-utils';
import {
  BusinessInviteParams,
  BusinessInvitesResponse,
  IBusinessInvite,
  IBusinessInviteDetails,
  ResendBusinessInviteParams,
} from '../../src/common/types';
import { COMMON_HEADERS, http, httpBusinessInvites } from '../http-common';

export const createBusinessInvite = async (
  businessInviteParams: BusinessInviteParams
): Promise<void> => {
  try {
    const { businessId, email, permissions } = businessInviteParams;

    await http.post<IBusinessInvite>(
      `/${PLATFORM_PREFIX}/business-user-invites/full-creation/${businessId}`,
      {
        email,
        permissions,
      }
    );
  } catch (error: any) {
    throw new Error(error.response.data?.message);
  }
};

export const getBusinessInvites = ({
  queryKey,
}: any): Promise<BusinessInvitesResponse[] | []> => {
  const [, businessId] = queryKey;
  return http
    .get<BusinessInvitesResponse[]>(
      `/${PLATFORM_PREFIX}/business-user-invites/business/${businessId}`
    )
    .then((resp) => {
      const dataResult: BusinessInvitesResponse[] | [] = resp.data;
      return dataResult;
    });
};

export const getBusinessInviteDetailsByUuid = async ({
  queryKey,
}: any): Promise<IBusinessInviteDetails> => {
  const [_, region, inviteUuid] = queryKey;
  return httpBusinessInvites
    .get<IBusinessInviteDetails>(`/business-user-invites/${inviteUuid}`, {
      headers: {
        'Content-Type': 'application/json',
        [COMMON_HEADERS.CURRENT_REGION]: region,
      },
    })
    .then((resp) => {
      const dataResult: IBusinessInviteDetails = resp.data;
      return dataResult;
    });
};

export const getBusinessUserWasInvited = async ({
  queryKey,
}: any): Promise<boolean> => {
  const [, businessUserId, businessId] = queryKey;
  // we return true since it has less permissions when is invited
  if (!businessUserId || !businessId) return true;
  return http
    .get<boolean>(
      `/${PLATFORM_PREFIX}/business-user-invites/check-is-invited/${businessUserId}/${businessId}`
    )
    .then((resp) => {
      const dataResult: boolean = resp.data;
      return dataResult;
    });
};

export const getBusinessUserWasAdminInvited = async ({
  queryKey,
}: any): Promise<boolean> => {
  const [, businessUserId, businessId] = queryKey;
  if (!businessUserId || !businessId) return true;
  return http
    .get<boolean>(
      `/${PLATFORM_PREFIX}/business-user-invites/check-is-admin-invited/${businessUserId}/${businessId}`
    )
    .then((resp) => {
      const dataResult: boolean = resp.data;
      return dataResult;
    });
};

export const updateBusinessUserInvite = async (params: {
  uuid: string;
  region: ENationalities;
  data?: any;
}): Promise<IBusinessInvite | undefined> => {
  try {
    // TODO: Review, this endpoint is public for now.
    const resp = await httpBusinessInvites.patch<IBusinessInvite>(
      `/business-user-invites/${params.uuid}/confirm`,
      params.data,
      {
        headers: {
          'Content-Type': 'application/json',
          [COMMON_HEADERS.CURRENT_REGION]: params.region,
        },
      }
    );
    const dataResult: IBusinessInvite = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const resendBusinessUserInvite = async (
  resendBusinessInviteParams: ResendBusinessInviteParams
): Promise<void> => {
  try {
    const { businessId, userId, permissions } = resendBusinessInviteParams;

    await http.patch<IBusinessInvite>(
      `/${PLATFORM_PREFIX}/business-user-invites/full-creation/${businessId}`,
      {
        userId,
        permissions,
      }
    );
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

export const deleteBusinessInvite = async (
  uuid: string
): Promise<IBusinessInvite | undefined> => {
  try {
    const resp = await http.delete<IBusinessInvite>(
      `/${PLATFORM_PREFIX}/business-user-invites/${uuid}`
    );
    const dataResult: IBusinessInvite = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};
