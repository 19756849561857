import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import {
  EDocumentTypes,
  ENeedsUifDocument,
  IDocumentPreview,
} from '@src/common/types';
import { VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FileUploader from '../FileUploader';
import { UIF_RADIO } from './consts';

interface Props {
  uifDocumentId: number | null;
  setUifDocumentId: React.Dispatch<React.SetStateAction<number | null>>;
  defaultUifDocument?: IDocumentPreview;
}
/**
 *  Q: Whats UIF?
 *  A: https://www.argentina.gob.ar/uif
 *     "We are the body in charge of the analysis,
 *     treatment and dissemination of financial
 *     intelligence information to prevent money laundering
 *     and the financing of terrorism"
 */
const UIFRadio: VFC<Props> = ({
  uifDocumentId,
  setUifDocumentId,
  defaultUifDocument,
}) => {
  const { register, watch } = useFormContext();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboardingLevelThree.uifObligated',
  });
  const value = watch(UIF_RADIO)
    ? String(watch(UIF_RADIO))
    : String(ENeedsUifDocument.DOC_NOT_REQUIRED);

  const shouldShowFileUploader =
    Number(value) === ENeedsUifDocument.DOC_REQUIRED;

  return (
    <>
      <RadioGroup mt="6" defaultValue={value} defaultChecked>
        <Stack>
          <Text fontSize="sm" fontWeight="medium">
            {t('label')}
          </Text>
          <Radio
            size="md"
            value={String(ENeedsUifDocument.DOC_REQUIRED)}
            colorScheme="primary"
            {...register(UIF_RADIO, { required: true, valueAsNumber: true })}
          >
            {t('yes')}
          </Radio>
          <Radio
            size="md"
            value={String(ENeedsUifDocument.DOC_NOT_REQUIRED)}
            colorScheme="primary"
            {...register(UIF_RADIO, { required: true, valueAsNumber: true })}
          >
            {t('no')}
          </Radio>
        </Stack>
      </RadioGroup>
      {shouldShowFileUploader && (
        <FileUploader
          documentId={uifDocumentId}
          setDocumentId={setUifDocumentId}
          description={t('uifDocumentLabel')}
          documentTypeId={EDocumentTypes.OBLIGATED_SUBJECT}
          defaultFile={defaultUifDocument}
        />
      )}
    </>
  );
};

export default UIFRadio;
