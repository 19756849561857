import { CreateTransferDto } from '@transfers/add/interfaces';

import { httpPlatform } from '../../http-common';

export const createTransfer = async (
  dto: CreateTransferDto
): Promise<{ uuid: string } | undefined> => {
  try {
    const { data } = await httpPlatform.post<Promise<{ uuid: string }>>(
      '/transfers',
      dto
    );
    return data;
  } catch (error) {
    // TODO: Handle error
  }
};
