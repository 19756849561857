import { Flex, Text } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  expirationTimestamp: number;
}
const PaymentRequestExpirationTimer: FC<Props> = ({ expirationTimestamp }) => {
  const now = moment().unix();
  const secondsRemaining = expirationTimestamp - now;
  const wholeHoursRemaining = Math.floor(secondsRemaining / 3600);
  const minutesRemainingInSecond =
    secondsRemaining - wholeHoursRemaining * 3600;
  const wholeMinutesRemaining = Math.floor(minutesRemainingInSecond / 60);
  const { t } = useTranslation();
  return (
    <Flex alignItems={'center'} gap={1} height={'16px'} lineHeight={'16px'}>
      <FeatherIcon icon="clock" size="16" />
      <Text>{t('paymentRequestDetails.remainingTime')}</Text>
      <Text fontWeight={'bold'}>
        {expirationTimestamp > now
          ? `${wholeHoursRemaining}h ${wholeMinutesRemaining}m`
          : ''}
      </Text>
    </Flex>
  );
};

export default PaymentRequestExpirationTimer;
