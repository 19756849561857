import { AgrotokenLogo } from '@agrotoken-tech/ui';
import {
  Button,
  Flex,
  Heading,
  Text,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import image from '../../assets/images/login.png';
import { BREAKPOINTS } from '@common/const';

interface Props {
  title?: string;
  subTitle?: string;
  banner?: ReactNode;
  allowBack?: boolean;
}
const BasePage: FC<Props> = ({
  children,
  title,
  subTitle,
  banner = null,
  allowBack = true,
}) => {
  const shouldRenderImage = useBreakpointValue([false, false, true]);
  const navigation = useNavigate();
  const goBack = () => navigation(-1);
  const { t } = useTranslation();

  const isMobile = window.innerWidth < BREAKPOINTS.md;

  return (
    <Flex
      h="full"
      py={['6', '6', '0']}
      minH={['full', 'full', 'full', '100vh']}
    >
      <Flex
        direction="column"
        w="full"
        px={['6', '6', '28']}
        py={['0', '0', '6']}
        justify="center"
      >
        <Flex mb={8}>
          <AgrotokenLogo variant="imagotype" />
        </Flex>
        {banner}
        {allowBack && (
          <Button
            variant="linkPrimary"
            fontWeight="medium"
            fontSize="sm"
            w="fit-content"
            mb="8"
            onClick={goBack}
          >
            {'<- '} {t('general.goBack')}
          </Button>
        )}
        {title && (
          <Heading
            mb="2"
            as={isMobile ? 'h6' : undefined}
            size={isMobile ? 'xs' : undefined}
          >
            {title}
          </Heading>
        )}
        {subTitle && (
          <Text mb="8" color="gray.500">
            {subTitle}
          </Text>
        )}
        {children}
      </Flex>
      {shouldRenderImage && (
        <Image src={image} maxW="30%" objectFit="cover" objectPosition="left" />
      )}
    </Flex>
  );
};

export default BasePage;
