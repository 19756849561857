import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import {
  Badge,
  Box,
  Flex,
  Hide,
  Show,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import moment from 'moment';
import React, { FC, FormEvent, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { FiCheckCircle, FiChevronLeft, FiXCircle } from 'react-icons/fi';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { useOutletContext } from 'react-router-dom';
import {
  GrainContractTypes,
  GrainContractValidationStatus,
} from '@common/enum';
import { Alert } from '@comp/Global/Alert';
import { Mailto } from '@comp/Global/Mailto';
import { Modal } from '@comp/Global/Modal';
import RealGrainMovements from '@comp/Tokenization/Summary/RealGrainMovements';
import { useValidateDetokenizeForm } from '@hooks/useValidateForm';

import { downloadDocument } from '@services/Documents';
import { useAuth } from '@src/context/authContext';
import {
  getFutureTokenizationStep,
  getRealTokenizationStep,
} from '@utils/getTokenizationStep';
import {
  SUCCESS_PATH,
  TOKENIZATIONS_PATH,
  DETOKENIZATIONS_PATH,
} from '../../../common/const';
import {
  IGrainContract,
  RealTokenizationStep,
  TBreadcrumbOption,
  EGrainContractStatus,
  EDetokenizationType,
  IUpdateGrainContract,
  EGrainContractStatusId,
  EDocusignType,
  EDocusignStatus,
  ESignatureTypes,
  FutureTokenizationStep,
  EDocumentTypes,
} from '../../../common/types';
import {
  alertContent,
  getTodayTimestamp,
  isAlmostExpired,
  isPendingDocusignRescission,
} from '../../../common/utils';
import { useUser } from '../../../context/userContext';
import { viewDocusign } from '../../../services/Docusign';
import {
  createAndViewDocusign,
  detokenizeGrainContract,
  getGrainContract,
  updateGrainContract,
} from '../../../services/GrainContracts';
import FutureMovements from '../../BR/Tokenization/Summary/FutureMovements';
import { Container } from '../../Global/Container';
import { Loader } from '../../Global/Loader';
import { ServerError } from '../../Global/ServerError';
import { Title } from '../../Global/Title';
import { Toast } from '../../Global/Toast';
import { DetokenizeModal } from './DetokenizeModal';
import SideContent from './SideContent';
import { getCurrencySummaryTranslation } from './get-currency-summary-translation';
import CurrencySummaryTranslationParams from './currency-summary-translation.interface';
import FixedGrainMovements from './FixedGrainMovements';

const TokenizationDetails_Ar = React.lazy(
  () => import('../../AR/Tokenization/Summary/TokenizationDetails')
);
const TokenizationDetails_Br = React.lazy(
  () => import('../../BR/Tokenization/TokenizationDetails')
);

const TermsAndConds_Ar = React.lazy(
  () => import('../../AR/Global/TermsAndConsModal/index')
);

const tokenizationDetails = getMultiRegionMap({
  AR: TokenizationDetails_Ar,
  BR: TokenizationDetails_Br,
});

const Documentation_Ar = React.lazy(
  () => import('../../AR/Tokenization/Documentation')
);
const Documentation_Br = React.lazy(
  () => import('../../BR/Tokenization/Documentation')
);

const documentation = getMultiRegionMap({
  AR: Documentation_Ar,
  BR: Documentation_Br,
});

export const Summary = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { nationality } = useAuth();
  const TokenizationDetailsComponent = tokenizationDetails[nationality];

  const [isOpenDetokenizeModal, setIsOpenDetokenizeModal] =
    useState<boolean>(false);
  const [isOpenRenovationModal, setIsOpenRenovationModal] =
    useState<boolean>(false);
  const [isOpenMoreInfo, setIsOpenMoreInfo] = useState<boolean>(false);
  const [toast, setToast] = useState<{
    title: string;
    text: string;
    featuredIcon: IconType;
    variant: 'primary' | 'destructive';
    isOpen: boolean;
  }>({
    title: '',
    text: '',
    featuredIcon: FiCheckCircle,
    variant: 'primary',
    isOpen: false,
  });
  const [isOpenCollateralDetails, setisOpenCollateralDetails] =
    useState<boolean>(false);
  const [tokensToDetokenize, setTokensToDetokenize] = useState<string>('');
  const [detokenizationType, setdetokenizationType] = useState<string>(
    EDetokenizationType.PARTIAL
  );
  const [shouldShowDetokenizeError, setShouldShowDetokenizeError] =
    useState(false);
  const [lastOperation, setLastOperation] = useState<string | undefined>('');

  const { setOptions } = useOutletContext() as any;
  const { currentUser } = useUser();

  const {
    isLoading: isLoadingGrainContract,
    error: grainContractError,
    data: grainContract,
    refetch,
  } = useQuery<IGrainContract | undefined>(
    ['grainContract', id],
    getGrainContract
  );

  const {
    mutate: mutateDetokenizeGrainContract,
    isLoading: isLoadingDetokenize,
  } = useMutation(detokenizeGrainContract, {
    onSuccess: (data) => {
      if (nationality === ENationalities.BR) {
        window.location.replace(
          `${
            process.env.REACT_APP_BASE_URL
          }${DETOKENIZATIONS_PATH}/${id}${SUCCESS_PATH}?region=${nationality.toLowerCase()}`
        );
      }

      setIsOpenDetokenizeModal(false);
      setTokensToDetokenize('');
      setToast({
        title: t('toasts.destokenization.success.title'),
        text: t('toasts.destokenization.success.text'),
        featuredIcon: FiCheckCircle,
        variant: 'primary',
        isOpen: true,
      });
      refetch();
      setShouldShowDetokenizeError(false);
    },
    onError: (_err: Error) => {
      setIsOpenDetokenizeModal(false);
      setShouldShowDetokenizeError(false);
      setToast({
        title: t('toasts.destokenization.error.title'),
        text: t('toasts.destokenization.error.text'),
        featuredIcon: FiXCircle,
        variant: 'destructive',
        isOpen: true,
      });
    },
  });
  const [isLoadingOfferLetter, setIsLoadingOfferLetter] = useState(false);
  const [isOpenTermsModal, setIsOpenTermsModal] = useState<boolean>(false);
  const [isLoadingDetokenizationDocusign, setLoadingDetokenizationDocusign] =
    useState(false);

  const [hasOfferLetterDocument, setHasOfferLetterDocument] =
    useState<boolean>(false);
  const isFutureGrain =
    grainContract?.grainContractType === GrainContractTypes.FUTURE;
  const Documentation = documentation[nationality];
  const [tabIndex, setTabIndex] = useState<number>(0);

  const { mutate: mutateRenovateGrainContract, isLoading: isLoadingRenovate } =
    useMutation(updateGrainContract, {
      onSuccess: () => {
        setIsOpenRenovationModal(false);
        refetch();
        setToast({
          title: t('toasts.renovation.success.title'),
          text: t('toasts.renovation.success.text'),
          featuredIcon: FiCheckCircle,
          variant: 'primary',
          isOpen: true,
        });
      },
      onError: (_err: Error) => {
        setToast({
          title: t('toasts.renovation.error.title'),
          text: t('toasts.renovation.error.text'),
          featuredIcon: FiXCircle,
          variant: 'destructive',
          isOpen: true,
        });
      },
    });

  const { isValidTokensToDetokenize } = useValidateDetokenizeForm(
    +tokensToDetokenize,
    grainContract ? grainContract.amount : null
  );

  const { farm, currency, grainContractType } = grainContract ?? {};

  const currencyTranslations = getCurrencySummaryTranslation({
    currencyName: currency?.name ?? '',
    grainContractType,
    farmName: farm?.name ?? '',
  } as CurrencySummaryTranslationParams);

  useEffect(() => {
    if (grainContract && !isFutureGrain) {
      const tokenizationStep = getRealTokenizationStep(
        grainContract,
        nationality
      );
      switch (tokenizationStep) {
        case RealTokenizationStep.PROOF_OF_EXISTENCE:
          navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-existence`);
          break;
        case RealTokenizationStep.PROOF_OF_LIQUIDITY:
          navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
          break;
        case RealTokenizationStep.VERIFICATION:
          if (
            grainContract.grainContractStatusId ===
            EGrainContractStatusId.PENDING
          ) {
            navigate(`${TOKENIZATIONS_PATH}/${id}/confirm`);
          }
          break;
        default:
          break;
      }
    }
  }, [grainContract, navigate, isFutureGrain, id]);

  useEffect(() => {
    if (grainContract && isFutureGrain) {
      const tokenizationStep = getFutureTokenizationStep(
        grainContract,
        nationality
      );
      switch (tokenizationStep) {
        case FutureTokenizationStep.PRODUCTION_EXPECTATION:
          navigate(`${TOKENIZATIONS_PATH}/${id}/production-expectation`);
          break;
        case FutureTokenizationStep.PROOF_OF_LIQUIDITY:
          navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
          break;
        case FutureTokenizationStep.VERIFICATION:
          if (
            grainContract.grainContractStatusId ===
            EGrainContractStatusId.PENDING
          ) {
            navigate(`${TOKENIZATIONS_PATH}/${id}/confirm`);
          }
          break;
        default:
          break;
      }
    }
  }, [grainContract, navigate, isFutureGrain, id]);

  useEffect(() => {
    if (
      grainContract?.documents.some(
        (item) => item.documentTypeId === EDocumentTypes.CREDIT_ASSIGNMENT
      )
    )
      setHasOfferLetterDocument(true);
    const firstOperation = grainContract?.grainContractsOperations.reduce(
      (highest, grainContractsOperation) => {
        const itemCreatedAt = parseInt(
          grainContractsOperation.operation.createdAt
        );
        return itemCreatedAt > highest ? itemCreatedAt : highest;
      },
      0
    );
    const operation = grainContract?.grainContractsOperations.find(
      (grainContractsOperations) => {
        return (
          parseInt(grainContractsOperations.operation.createdAt) ===
          firstOperation
        );
      }
    )?.operation;
    setLastOperation(operation?.description);
  }, [grainContract]);

  useEffect(() => {
    const breadcrumbOptions: TBreadcrumbOption[] = [
      {
        label: t('tokenization.summary.breadCrumbOpt1'),
        to: TOKENIZATIONS_PATH,
      },
      {
        label: `${t('tokenization.summary.breadCrumbOpt2')}${
          grainContract?.requestNumber || ''
        }`,
        to: '',
      },
    ];
    setOptions(breadcrumbOptions);
    return () => {
      setOptions([]);
    };
  }, [grainContract?.requestNumber, setOptions, t]);

  if (isLoadingGrainContract) return <Loader />;

  if (grainContractError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(grainContractError as any)?.message}
      />
    );
  }

  if (!grainContract) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={t('serverError.text')}
      />
    );
  }

  const {
    amount,
    agreementDoc,
    grainContractStatus,
    expirationDate,
    renovatedAt,
    signatureType,
    docusigns,
  } = grainContract;

  const handleRenovateGrainContract = () => {
    if (!agreementDoc || !agreementDoc.expirationDate) return;
    const body: IUpdateGrainContract = {
      expirationDate: +agreementDoc.expirationDate,
      renovatedAt: getTodayTimestamp(),
    };
    mutateRenovateGrainContract({
      id,
      body,
    });
  };

  const docusignOfferLetter = grainContract?.docusigns.find(
    (docusign) => docusign.docusignTypeId === EDocusignType.OFFER_LETTER
  );
  const hasOfferLetterDocusign = !!docusignOfferLetter;

  const getDocument = async (uuid: string) => {
    const document = await downloadDocument(uuid);
    return document;
  };

  const offerLetterDocUuid = grainContract?.documents.find(
    (item) => item.documentTypeId === EDocumentTypes.CREDIT_ASSIGNMENT
  )?.uuid;

  const handleViewDocusign = async () => {
    if (hasOfferLetterDocusign) {
      setIsLoadingOfferLetter(true);
      const response = await viewDocusign(
        { sign: false, returnUrl: window.location.href },
        docusignOfferLetter?.id
      ).finally(() => setIsLoadingOfferLetter(false));
      window.open(response.url);
    }
    if (hasOfferLetterDocument && offerLetterDocUuid) {
      setIsLoadingOfferLetter(true);
      const url = await getDocument(offerLetterDocUuid).finally(() =>
        setIsLoadingOfferLetter(false)
      );
      window.open(url);
    }
  };

  const frozenAmount = new Decimal(grainContract.frozenAmount ?? 0);
  const remainingAmount = new Decimal(grainContract.amount ?? 0);
  const availableDetokenizationAmount = remainingAmount.plus(frozenAmount);

  const handleDetokenizationSubmit = async (ev: FormEvent) => {
    ev.preventDefault();
    if (!id || !amount) return;
    if (isPartialTokenization && !isValidTokensToDetokenize) {
      setShouldShowDetokenizeError(true);
      return;
    }
    const body: IUpdateGrainContract = {
      amount: isPartialTokenization
        ? +tokensToDetokenize
        : +availableDetokenizationAmount,
      isPartialTokenization: detokenizationType === EDetokenizationType.PARTIAL,
    };
    mutateDetokenizeGrainContract({ id: parseInt(id), body });
  };

  const handleDetokenization = async () => {
    if (!id || !amount) return;
    const body: IUpdateGrainContract = {
      amount: +availableDetokenizationAmount,
    };
    mutateDetokenizeGrainContract({ id: parseInt(id), body });
  };
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const handleTokenizationMovementsByGrainContractType = (
    grainContractType: GrainContractTypes
  ) => {
    switch (grainContractType) {
      case GrainContractTypes.REAL:
        return <RealGrainMovements tokenizationUuid={grainContract.uuid} />;
        break;
      case GrainContractTypes.FUTURE:
        return <FutureMovements tokenizationUuid={grainContract.uuid} />;

        break;
      case GrainContractTypes.FIXED_GRAIN:
        return <FixedGrainMovements tokenizationUuid={grainContract.uuid} />;

      default:
        <FutureMovements tokenizationUuid={grainContract.uuid} />;
    }
  };

  const movementsByGrainContractType =
    handleTokenizationMovementsByGrainContractType(
      grainContract.grainContractType
    );

  const canRenewDate = () => {
    if (
      !grainContract ||
      !agreementDoc ||
      !agreementDoc.expirationDate ||
      !grainContractStatus ||
      !expirationDate
    )
      return false;
    return (
      grainContractStatus.name === EGrainContractStatus.TOKENIZED &&
      expirationDate !== agreementDoc.expirationDate &&
      !renovatedAt &&
      isAlmostExpired(expirationDate)
    );
  };
  const isPartialTokenization =
    detokenizationType === EDetokenizationType.PARTIAL;

  const isPendingApproval =
    grainContractStatus.name === EGrainContractStatus.PENDING_APPROVAL &&
    !(
      grainContract.validationStatus === GrainContractValidationStatus.APPROVED
    );
  const isOfferLetterPendingUser =
    (grainContractStatus.name === EGrainContractStatus.PENDING_APPROVAL &&
      grainContract.validationStatus ===
        GrainContractValidationStatus.APPROVED) ||
    (grainContractStatus.name === EGrainContractStatus.OFFER_LETTER &&
      docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_USER);

  const isOfferLetterPendingAgtk =
    grainContractStatus.name === EGrainContractStatus.OFFER_LETTER &&
    (docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_AGTK ||
      docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_ORACLE ||
      docusigns[0]?.docusignStatusId === EDocusignStatus.PENDING_WITNESSES);

  const isOfferLetterSigningComplete =
    grainContractStatus.name === EGrainContractStatus.OFFER_LETTER &&
    docusigns[0]?.docusignStatusId === EDocusignStatus.COMPLETED;

  const isNotificationLetterPending =
    grainContractStatus.name === EGrainContractStatus.ASSIGNMENT_NOTIFICATION;

  const isBlockchainStatus =
    grainContractStatus.name === EGrainContractStatus.PENDING_BLOCKCHAIN ||
    grainContractStatus.name === EGrainContractStatus.IN_PROGRESS_BLOCKCHAIN;

  const isTokenizationCompleted = [
    EGrainContractStatus.TOKENIZED,
    EGrainContractStatus.DETOKENIZATION_IN_PROGRESS,
    EGrainContractStatus.DETOKENIZED,
    EGrainContractStatus.PENDING_SETTLEMENT,
    EGrainContractStatus.CHARGED_AND_PENDING_PAYMENT,
    EGrainContractStatus.PAID_COMPLETED,
  ].includes(grainContractStatus.name);
  const isDocuSignCreditAssignment =
    signatureType?.keyCode === ESignatureTypes.DOCUSIGN;

  const { title, supportingText } = alertContent(
    grainContract,
    docusigns,
    nationality,
    isFutureGrain,
    currentUser?.id
  );

  const isRealPendingOfferLetterCreation =
    !isFutureGrain &&
    grainContract.grainContractStatus?.name ===
      EGrainContractStatus.PENDING_APPROVAL &&
    grainContract.validationStatus === GrainContractValidationStatus.APPROVED;

  const expiredControl = () => {
    const today = moment();

    const unoperableWeekDays = [
      { day: 7, anticipation: 2 },
      { day: 1, anticipation: 3 },
    ];

    const anticipationDays =
      unoperableWeekDays.find(
        (unoperable) =>
          unoperable.day ===
          moment.unix(grainContract.expirationDate).isoWeekday()
      )?.anticipation ?? 1;

    const expirationDateForRegions = {
      [ENationalities.AR]: moment
        .unix(grainContract.expirationDate)
        .startOf('day'),
      [ENationalities.BR]: moment
        .unix(grainContract.expirationDate)
        .subtract(anticipationDays, 'days')
        .startOf('day'),
    };

    return {
      isExpired: today.isSameOrAfter(expirationDateForRegions[nationality]),
      date: expirationDateForRegions[nationality].format('DD/MM/YYYY'),
    };
  };

  const handleDetokenizationDocusign = () => {
    if (!id || !amount) {
      return;
    }

    setLoadingDetokenizationDocusign(true);
    detokenizeGrainContract({
      id: +id,
      body: {
        amount: +availableDetokenizationAmount,
      },
    })
      .then(() => {
        return createAndViewDocusign({
          body: {
            sign: true,
            returnUrl: `${
              process.env.REACT_APP_BASE_URL
            }${DETOKENIZATIONS_PATH}/${id}${SUCCESS_PATH}?region=${nationality.toLowerCase()}`,
            userId: currentUser?.id,
            grainContractId: parseInt(id),
          },
          docusignTypeId: EDocusignType.RESCISSION_NOTIFICATION,
        }).then((data) => {
          window.location.replace(data?.url);
        });
      })
      .catch(() => {
        setToast({
          title: t('toasts.destokenization.error.title'),
          text: t('toasts.destokenization.error.text'),
          featuredIcon: FiXCircle,
          variant: 'destructive',
          isOpen: true,
        });
      })
      .finally(() => {
        setLoadingDetokenizationDocusign(false);
        setIsOpenDetokenizeModal(false);
        setShouldShowDetokenizeError(false);
      });
  };
  const isUserCreator = currentUser?.id === grainContract.userId;

  return (
    <Container>
      <Hide above="xl">
        <Flex
          alignItems="center"
          justifyContent="center"
          mt="8"
          position="relative"
        >
          <Box position="absolute" left="0">
            <FiChevronLeft color="gray.500" onClick={() => navigate(-1)} />
          </Box>
          <Flex gap="2" flexWrap="wrap" alignItems={'start'}>
            {(isFutureGrain || isRealPendingOfferLetterCreation) && (
              <Text color="gray.700" variant="medium">
                {isTokenizationCompleted
                  ? [
                      t('tokenization.futureSummaryTitle'),
                      t(`tokenization.${grainContract.currency.name}`),
                    ].join(' ')
                  : t('tokenization.summaryTitle')}{' '}
                #{grainContract.requestNumber}
              </Text>
            )}
            {!isFutureGrain && !isRealPendingOfferLetterCreation && (
              <Text color="gray.700" variant="medium">
                {t('tokenization.summaryTitle')} #{grainContract.requestNumber}
              </Text>
            )}
            {grainContract.managerId && (
              <Badge
                bgColor={'gray.100'}
                textColor={'gray.700'}
                outline="none"
                boxShadow="none"
                textTransform={'initial'}
                px="2"
                py="0.5"
                marginTop="4"
              >
                {t('tokenization.createdByAgrotoken', {
                  requestNumber: grainContract.requestNumber,
                })}
              </Badge>
            )}
          </Flex>
        </Flex>
      </Hide>
      <Show above="xl">
        {(isFutureGrain || isRealPendingOfferLetterCreation) && (
          <Title
            title={
              isTokenizationCompleted
                ? [
                    t('tokenization.futureSummaryTitle'),
                    t(`tokenization.${grainContract.currency.name}`),
                  ].join(' ')
                : t('tokenization.summaryTitle')
            }
            requestNumber={grainContract ? grainContract.requestNumber : null}
            subtitle={
              isTokenizationCompleted
                ? t('tokenization.futureSummarySubtitle')
                : ''
            }
            managerId={grainContract.managerId}
          />
        )}
      </Show>
      <Show above="xl">
        {!isFutureGrain && !isRealPendingOfferLetterCreation && (
          <Title
            title={t('tokenization.summaryTitle')}
            requestNumber={grainContract ? grainContract.requestNumber : null}
            subtitle={
              nationality === ENationalities.BR
                ? ''
                : t('tokenization.subtitle')
            }
            managerId={grainContract.managerId}
          />
        )}
      </Show>

      <div>
        <Flex mb="2" flexDirection="column">
          {isTokenizationCompleted && (
            <Tabs onChange={handleTabsChange} my={5}>
              <TabList>
                <Tab
                  _focus={{
                    outline: 'none',
                  }}
                  _selected={{
                    color: 'primary.700',
                    bg: 'white',
                    borderColor: 'primary.700',
                  }}
                >
                  {t('tokenization.informationTab')}
                </Tab>
                <Tab
                  _focus={{
                    outline: 'none',
                  }}
                  _selected={{
                    color: 'primary.700',
                    bg: 'white',
                    borderColor: 'primary.700',
                  }}
                >
                  {t('tokenization.movementsTab')}
                </Tab>
              </TabList>
            </Tabs>
          )}
          {tabIndex === 0 && (
            <div
              className={`flex gap-x-8 align-center ${
                !isTokenizationCompleted ? 'mt-8' : ''
              }`}
            >
              <div className="xl:w-7/12 ">
                {nationality === ENationalities.AR &&
                  grainContractStatus.name !==
                    EGrainContractStatus.PENDING_APPROVAL && (
                    <Alert
                      color={
                        isTokenizationCompleted
                          ? 'primary'
                          : isOfferLetterPendingAgtk
                          ? 'blue'
                          : 'white'
                      }
                      text={title}
                      supportText={supportingText}
                    />
                  )}
                {nationality === ENationalities.AR &&
                  grainContractStatus.name ===
                    EGrainContractStatus.PENDING_APPROVAL && (
                    <Alert
                      text={title}
                      supportText={supportingText}
                      color={'white'}
                    >
                      {!isOfferLetterPendingUser && (
                        <>
                          <button
                            className="text-sm font-medium text-primary-700 hover:text-primary-800 disabled:text-primary-300"
                            onClick={() => setisOpenCollateralDetails(true)}
                          >
                            {t('tokenization.confirm.alertLink')}
                          </button>
                          <span className="text-sm font-normal text-gray-700">
                            {t('tokenization.summary.pendingApproval.text2')}{' '}
                          </span>
                        </>
                      )}
                    </Alert>
                  )}
                {nationality === ENationalities.BR && (
                  <Alert
                    color={
                      isPendingDocusignRescission(
                        grainContract,
                        isDocuSignCreditAssignment
                      )
                        ? 'white'
                        : isTokenizationCompleted
                        ? 'primary'
                        : isOfferLetterPendingAgtk ||
                          isOfferLetterSigningComplete ||
                          isNotificationLetterPending ||
                          isBlockchainStatus
                        ? 'blue'
                        : 'white'
                    }
                    text={title}
                    supportText={supportingText}
                  />
                )}
                {nationality === ENationalities.AR && (
                  <p className="text-xl font-medium text-gray-900 mb-4 mt-8 hidden lg:block">
                    {t('tokenization.summary.generalData')}
                  </p>
                )}
                <div className="xl:hidden">
                  <SideContent
                    isPendingApproval={isPendingApproval}
                    isFutureGrain={isFutureGrain}
                    grainContract={grainContract}
                    isOfferLetterPendingUser={isOfferLetterPendingUser}
                    isOfferLetterPendingAgtk={isOfferLetterPendingAgtk}
                    isOfferLetterSigningComplete={isOfferLetterSigningComplete}
                    isNotificationLetterPending={isNotificationLetterPending}
                    isBlockchainStatus={isBlockchainStatus}
                    isTokenizationCompleted={isTokenizationCompleted}
                    setIsLoadingOfferLetter={setIsLoadingOfferLetter}
                    isDocuSignCreditAssignment={isDocuSignCreditAssignment}
                    lastOperation={lastOperation}
                    grainContractStatus={grainContractStatus}
                    setIsOpenMoreInfo={setIsOpenMoreInfo}
                    setIsOpenDetokenizeModal={setIsOpenDetokenizeModal}
                    setIsOpenRenovationModal={setIsOpenRenovationModal}
                    canRenewDate={canRenewDate}
                    expiredControl={expiredControl}
                    isUserCreator={isUserCreator}
                  />
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                  <TokenizationDetailsComponent
                    isFutureGrain={isFutureGrain}
                    grainContract={grainContract}
                  />
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                  <Documentation
                    grainContract={grainContract}
                    handleViewDocusign={handleViewDocusign}
                    hasOfferLetterDocusign={
                      hasOfferLetterDocusign || hasOfferLetterDocument
                    }
                    isLoadingOfferLetter={isLoadingOfferLetter}
                    isFutureGrain={isFutureGrain}
                  />
                </Suspense>
              </div>
              <div className="w-5/12 hidden xl:block">
                <SideContent
                  isPendingApproval={isPendingApproval}
                  isFutureGrain={isFutureGrain}
                  grainContract={grainContract}
                  isOfferLetterPendingUser={isOfferLetterPendingUser}
                  isOfferLetterPendingAgtk={isOfferLetterPendingAgtk}
                  isOfferLetterSigningComplete={isOfferLetterSigningComplete}
                  isNotificationLetterPending={isNotificationLetterPending}
                  isBlockchainStatus={isBlockchainStatus}
                  isTokenizationCompleted={isTokenizationCompleted}
                  setIsLoadingOfferLetter={setIsLoadingOfferLetter}
                  isDocuSignCreditAssignment={isDocuSignCreditAssignment}
                  lastOperation={lastOperation}
                  grainContractStatus={grainContractStatus}
                  setIsOpenMoreInfo={setIsOpenMoreInfo}
                  setIsOpenDetokenizeModal={setIsOpenDetokenizeModal}
                  setIsOpenRenovationModal={setIsOpenRenovationModal}
                  canRenewDate={canRenewDate}
                  expiredControl={expiredControl}
                  isUserCreator={isUserCreator}
                />
              </div>
            </div>
          )}
          {tabIndex === 1 && movementsByGrainContractType}
        </Flex>
      </div>
      {nationality === ENationalities.AR && (
        <DetokenizeModal
          title={t('tokenization.summary.actions.detokenize')}
          text={t('tokenization.summary.detokenization.text')}
          isOpen={isOpenDetokenizeModal}
          icon="file-text"
          setIsOpen={setIsOpenDetokenizeModal}
          grainContract={grainContract}
          onSubmit={(ev: FormEvent) => handleDetokenizationSubmit(ev)}
          detokenizationType={detokenizationType}
          setDetokenizationType={setdetokenizationType}
          tokensToDetokenize={tokensToDetokenize}
          setTokensToDetokenize={setTokensToDetokenize}
          isLoading={isLoadingDetokenize}
          shouldShowError={shouldShowDetokenizeError}
        />
      )}
      {nationality === ENationalities.BR && (
        <>
          <Modal
            isOpen={isOpenDetokenizeModal}
            title={t(
              'tokenization.summary.detokenizationModal.onlyTotalModalTitle'
            )}
            text={`${t(
              'tokenization.summary.detokenizationModal.onlyTotaltext'
            )}${availableDetokenizationAmount}  ${t(
              `${currencyTranslations}`
            )}`}
            labelBtnClose={t(
              'tokenization.summary.detokenizationModal.labelBtnClose'
            )}
            labelBtnConfirm={t(
              'tokenization.summary.detokenizationModal.btnDetokenizeLabel'
            )}
            onClickConfirm={
              isDocuSignCreditAssignment
                ? handleDetokenizationDocusign
                : handleDetokenization
            }
            onClickClose={() => setIsOpenDetokenizeModal(false)}
            isLoading={
              isDocuSignCreditAssignment
                ? isLoadingDetokenizationDocusign
                : isLoadingDetokenize
            }
            cancelButtonHidden={false}
          />

          <Modal
            isOpen={isOpenMoreInfo}
            iconName="info"
            variant="primary"
            iconColor="gray"
            title={t('tokenization.summary.moreInfoModal.title')}
            text={t('tokenization.summary.moreInfoModal.text')}
            onClickConfirm={() => setIsOpenMoreInfo(false)}
            cancelButtonHidden={true}
            labelBtnConfirm={t('tokenization.summary.moreInfoModal.confirm')}
            confirmButtonFill={true}
          >
            <p className="text-md font-normal text-gray-700 ">
              {t('tokenization.summary.moreInfoModal.text2')}
            </p>
            <div>
              <p className="text-md font-normal text-gray-700">
                {t('tokenization.summary.moreInfoModal.text3')}
              </p>
              <Mailto
                className="text-md font-normal text-primary-700"
                email={t('tokenization.summary.moreInfoModal.mail')}
                subject={''}
              >
                {t('tokenization.summary.moreInfoModal.mail')}
              </Mailto>
            </div>
          </Modal>
        </>
      )}
      <Toast
        className="fixed top-6 right-6 z-50"
        show={toast.isOpen}
        onClickClose={() => setToast({ ...toast, isOpen: false })}
        title={toast.title}
        text={toast.text}
        featuredIcon={toast.featuredIcon}
        variant={toast.variant}
      />
      <Modal
        isOpen={isOpenRenovationModal}
        title={t('tokenizations.table.renovationModal.title')}
        text={t('tokenizations.table.renovationModal.text')}
        labelBtnClose={t('tokenizations.table.renovationModal.labelBtnClose')}
        labelBtnConfirm={t(
          'tokenizations.table.renovationModal.labelBtnConfirm'
        )}
        iconName="check"
        iconColor="success"
        onClickConfirm={handleRenovateGrainContract}
        onClickClose={() => setIsOpenRenovationModal(false)}
        center
        isLoading={isLoadingRenovate}
      />
      <Modal
        isOpen={isOpenCollateralDetails}
        iconName="info"
        variant="primary"
        iconColor="gray"
        labelBtnConfirm={t(
          'tokenization.summary.collateralDetails.labelConfirm'
        )}
        title={t('tokenization.summary.collateralDetails.title')}
        text={t('tokenization.summary.collateralDetails.text')}
        onClickConfirm={() => setisOpenCollateralDetails(false)}
        cancelButtonHidden
      >
        <p className="text-md font-normal text-gray-700 ">
          {t('tokenization.summary.collateralDetails.text2')}
        </p>
        <div>
          <p className="text-md font-normal text-gray-700">
            {t('tokenization.summary.collateralDetails.text3')}
          </p>
          <Mailto
            className="text-md font-normal text-primary-700"
            email={t('tokenization.summary.collateralDetails.mail')}
            subject={''}
          >
            {t('tokenization.summary.collateralDetails.mail')}
          </Mailto>
        </div>
      </Modal>
      {nationality === ENationalities.AR && (
        <Suspense fallback={<div>Loading...</div>}>
          <TermsAndConds_Ar
            title={t('tokenization.confirm.termsAndCondsTitle')}
            isOpen={isOpenTermsModal}
            icon="file-text"
            setIsOpen={setIsOpenTermsModal}
            canClose
          />
        </Suspense>
      )}
    </Container>
  );
};
