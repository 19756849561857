import { Badge, Divider, Tooltip } from '@chakra-ui/react';
import { IBusinessUser, UserTypes } from '@src/common/types';
import { useAuth } from '@src/context/authContext';
import FeatherIcon from 'feather-icons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { determineIfShouldShowChangeRequestBanner } from '@comp/Home/utils';
import { SelectManagerOrg } from '@comp/SelectManagerOrg';
import {
  CREATE_NEW_BUSINESS_PATH,
  ENABLED,
  HOME_PATH,
  LOGIN_PATH,
  SELECT_ORG,
} from '../../common/const';
import { useUser } from '../../context/userContext';
import { Button } from '../Global/Button';
import { Icon } from '../Global/Icon';

interface Props {
  isOpen: boolean;
  canClose?: boolean;
  setIsOpen?: React.Dispatch<boolean>;
}
export const SelectOrg: FC<Props> = ({
  isOpen = false,
  setIsOpen,
  canClose = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const localStorageState = localStorage.getItem('stateUrl');

  const [loading, setLoading] = useState(true);

  const [availableBusinessUsers, setAvailableBusinessUsers] = useState<
    { bUser: IBusinessUser; hasPendingChanges: boolean }[]
  >([]);

  const auth = useAuth();

  const {
    currentUser,
    errorUser,
    selectedBusinessId,
    setSelectedBusiness,
    setSelectedBusinessId,
    selectedBusinessUserWasInvited,
  } = useUser();

  const handleLogout = () => {
    localStorage.clear();
    auth.signOut();
    navigate(LOGIN_PATH);
  };

  const handleChangeOrg = (bu: IBusinessUser) => {
    setSelectedBusiness(bu?.business);
    setSelectedBusinessId(bu.businessId);
    setIsOpen && setIsOpen(false);
    navigate(`${HOME_PATH}`);
  };

  const handleCreateOrg = () => {
    setIsOpen && setIsOpen(false);
    navigate(CREATE_NEW_BUSINESS_PATH);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Fetch available Business Users, check if were invited, and also, if the related businesses
  // have pending changes, to show properly
  useEffect(() => {
    const processBusinessUsersData = async () => {
      if (currentUser && !isOpen) {
        setLoading(true);
        const bUsers: { bUser: IBusinessUser; hasPendingChanges: boolean }[] =
          [];
        try {
          for (
            let index = 0;
            index < (currentUser?.businessUsers ?? []).length;
            index++
          ) {
            const bUser = currentUser?.businessUsers[index];
            if (
              bUser &&
              bUser.isActive === ENABLED &&
              bUser.isRegistered === ENABLED
            ) {
              const { shouldShowChangeRequestedBanner } =
                determineIfShouldShowChangeRequestBanner({
                  nationality: auth.nationality,
                  currentUser,
                  selectedBusinessUser: bUser,
                  business: bUser?.business,
                  navigate,
                  selectedBusinessUserWasInvited,
                });

              bUsers.push({
                bUser,
                hasPendingChanges: shouldShowChangeRequestedBanner,
              });
            }
          }
        } catch {
          setLoading(false);
        }
        setAvailableBusinessUsers(bUsers);

        const currentUserBusinesses = bUsers
          .map((a) => a.bUser)
          .map((bu) => bu.business);

        // Check if exists a currently selected business, and if it's not in currentUserBusinesses then
        // auto select the first one.
        if (
          selectedBusinessId &&
          !currentUserBusinesses
            .map((c) => c.id)
            .includes(selectedBusinessId) &&
          currentUser.userType !== UserTypes.MANAGER
        ) {
          if (currentUserBusinesses.length > 0) {
            setSelectedBusinessId(currentUserBusinesses[0].id);
            setSelectedBusiness(currentUserBusinesses[0]);
            navigate(HOME_PATH);
            return;
          } else {
            setSelectedBusinessId(null);
            setSelectedBusiness(null);
            navigate(SELECT_ORG);
            return;
          }
        }

        if (location.pathname.includes(SELECT_ORG)) {
          // Only execute this when the route is select organization.
          if (
            (currentUserBusinesses.length === 0 ||
              currentUserBusinesses.length > 1) &&
            !isOpen &&
            setIsOpen
          ) {
            setIsOpen(true);
          } else if (currentUserBusinesses.length === 1) {
            // If user has only one business, and is different than the selected (might be due to being
            // undefined or bad logout handling), auto select it, and redirect to home.

            setSelectedBusinessId(currentUserBusinesses[0].id);
            setSelectedBusiness(currentUserBusinesses[0]);
            if (localStorageState && localStorageState !== 'undefined') {
              navigate(`${localStorageState}`);
            } else {
              navigate(HOME_PATH);
            }
            setIsOpen && setIsOpen(false);
            return;
          }
        }
        setLoading(false);
      }
      setLoading(false);
    };
    processBusinessUsersData();
  }, [currentUser, selectedBusinessId, isOpen, location.pathname]);

  if (!isOpen) {
    return null;
  }

  if (errorUser) {
    return (
      <ServerError title={t('serverError.title')} text={errorUser?.message} />
    );
  }

  return (
    <div>
      {currentUser?.userType === UserTypes.MANAGER && (
        <SelectManagerOrg setIsOpen={setIsOpen} />
      )}
      {currentUser?.userType !== UserTypes.MANAGER && (
        <div className="relative">
          <div className="fixed inset-0 bg-[#3440547f] overflow-auto z-40 h-screen">
            <div className="p-6 bg-white w-fit h-fit mx-auto absolute inset-x-0 top-12 rounded-md z-50 shadow-xl">
              <div className="flex justify-between">
                <Icon
                  size="md"
                  color="gray"
                  theme="light-circle-outline"
                  icon="grid"
                  className="mb-5"
                />
                {canClose && !location.pathname.includes(SELECT_ORG) && (
                  <button
                    className="p-2 m-2"
                    onClick={() => setIsOpen && setIsOpen(false)}
                  >
                    <FeatherIcon icon="x" color="text-gray-900" size={16} />
                  </button>
                )}
              </div>
              {availableBusinessUsers.length > 0 && (
                <>
                  <h2 className="text-md font-medium text-gray-900 mb-2 flex justify-start">
                    {t('changeOrg.title')}
                  </h2>
                  <p className="text-sm font-normal text-gray-700 flex justify-start pb-5">
                    {t('changeOrg.text')}
                  </p>
                </>
              )}
              {loading ? (
                <div className="mb-12">
                  <Loader />
                </div>
              ) : (
                availableBusinessUsers.map((data) => {
                  const bu = data.bUser;
                  return (
                    <button
                      key={bu.id}
                      onClick={() => handleChangeOrg(bu)}
                      className={`border-gray-100 border-2 shadow-xs rounded flex items-center justify-between p-4 mb-4 w-full ${
                        bu.businessId === selectedBusinessId ? 'bg-gray-50' : ''
                      }`}
                    >
                      <div className="flex items-center">
                        <p className="text-md font-medium text-gray-900 flex justify-start">
                          {bu.business.name}
                          {data.hasPendingChanges && (
                            <span className="flex items-center ml-2">
                              <Tooltip
                                label={t('changeOrg.pendingChanges')}
                                hasArrow
                                shouldWrapChildren
                              >
                                <FeatherIcon
                                  icon="alert-circle"
                                  color="#DA8702"
                                  size={12}
                                />
                              </Tooltip>
                            </span>
                          )}
                        </p>
                      </div>
                      {bu.businessId === selectedBusinessId ? (
                        <Badge
                          variant="subtle"
                          colorScheme={'primary'}
                          fontSize="xs"
                          ml={8}
                        >
                          {t('changeOrg.selected')}
                        </Badge>
                      ) : (
                        <FeatherIcon
                          icon="arrow-right"
                          size={20}
                          color="text-gray-900"
                        />
                      )}
                    </button>
                  );
                })
              )}
              <Button
                variant="link-gray"
                className="flex items-center w-full"
                icon="plus-circle"
                label="Crear una organización nueva"
                onClick={() => handleCreateOrg()}
              />
              {availableBusinessUsers.length === 0 && (
                <>
                  <Divider my={4} />
                  <Button
                    variant="link"
                    className="flex items-center w-full"
                    label={t('dropdown.logOut')}
                    onClick={handleLogout}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
