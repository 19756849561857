import { useState } from 'react';
import { ICardCreateResponse } from '@def/card';
import ThirdStep from '../ThirdStep/ThirdStep';
import SecondStepFormContainer from './components';

const SecondStep = () => {
  const [userHasRequestedCard, setUserHasRequestedCard] =
    useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<ICardCreateResponse>();

  return (
    <>
      {userHasRequestedCard ? (
        shippingAddress && (
          <ThirdStep shippingAddress={shippingAddress.shippingAdress} />
        )
      ) : (
        <SecondStepFormContainer
          setUserHasRequestedCard={setUserHasRequestedCard}
          setShippingAddress={setShippingAddress}
        />
      )}
    </>
  );
};

export default SecondStep;
