import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { EUnits, PricingCurrency } from '@common/types';
import { formatNumber } from '@common/utils';
import { TokenIcon } from '@comp/Global/TokenIcon';
import useGetLocalCurrency from '@hooks/useGetLocalCurrency';
import { getCurrencyPrefix } from '../utils';
import PriceVariation from './PriceVariation';
import { WeightUnit, ReferenceCurrency } from '@agrotoken/common-utils';

const Rate: FC<{ currency: PricingCurrency }> = (props) => {
  const { currency } = props;
  const { price, name, priceVariation, referenceCurrency, weightUnits } =
    currency;
  const { localCurrency } = useGetLocalCurrency();

  const currencyPrefix = getCurrencyPrefix(
    referenceCurrency as ReferenceCurrency,
    weightUnits as WeightUnit
  );

  const isLocalCurrency = name === localCurrency;

  const formattedRatePrice = formatNumber(price, EUnits.FIAT);

  return (
    <Flex alignItems="center" columnGap={2} paddingY={1} paddingX={2}>
      <TokenIcon token={name} size="sm" />
      <Text textColor="gray.900" as="span" variant="medium">
        {`${currencyPrefix} ${formattedRatePrice}`}
      </Text>

      {!isLocalCurrency && (
        <PriceVariation priceVariation={priceVariation ?? 0} />
      )}
    </Flex>
  );
};

export default Rate;
