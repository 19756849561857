import { PaymentForwardingFormData } from './types';

export const PAYMENT_FORWARDINGS_DEFAULT_VALUE: Omit<
  PaymentForwardingFormData,
  'uuid'
> = {
  amount: '',
  fiscalId: '',
  concept: '',
  documentUuid: null,
};
