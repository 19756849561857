import { Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BottomLinks: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-start items-center gap-1 mt-8">
        <Text size="sm" color="gray.500">
          {t('resetPassword.login.message')}
        </Text>
        <Link to="/">
          <Button variant="linkPrimary" size="nm" color="primary.600">
            {t('resetPassword.login.link')}
          </Button>
        </Link>
      </div>
      <div className="flex justify-start items-center gap-1 mt-4">
        <Text size="sm" color="gray.500">
          {t('resetPassword.signup.message')}
        </Text>
        <Link to="/sign-up">
          <Button variant="linkPrimary" size="nm" color="primary.600">
            {t('resetPassword.signup.link')}
          </Button>
        </Link>
      </div>
    </>
  );
};

export default BottomLinks;
