import { Button } from '@agrotoken-tech/ui';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { IUser } from '@src/common/types';
import FeatherIcon from 'feather-icons-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  Column,
  useGlobalFilter,
  Row,
  IdType,
} from 'react-table';
import { EmptyState } from '../../Global/EmptyState';

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  onClick: () => void;
}

export function Table<T extends object>({
  columns,
  data,
  onClick,
}: TableProps<T>): React.ReactElement {
  const globalFilterFunction = useCallback(
    (rows: Row<T>[], _ids: IdType<T>[], query: string) => {
      return rows.filter((row) => {
        if (query === '') return row;
        const userEmail: IUser | null = row.values['email'];
        const emailQuery = userEmail
          ? userEmail.email.toLowerCase().includes(query.toLowerCase())
          : null;
        const userFullName: IUser | null = row.values['fullName'];
        const firstNameQuery =
          userFullName && userFullName.firstName
            ? userFullName.firstName.toLowerCase().includes(query.toLowerCase())
            : null;
        const lastNameQuery =
          userFullName && userFullName.lastName
            ? userFullName.lastName.toLowerCase().includes(query.toLowerCase())
            : null;

        return firstNameQuery || lastNameQuery || emailQuery;
      });
    },
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    pageCount,
    setGlobalFilter,
  } = useTable<T>(
    { columns, data, globalFilter: globalFilterFunction, onClick },
    useGlobalFilter,
    usePagination
  );
  const { t } = useTranslation();
  const { pageIndex } = state;

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  return (
    <div className="border border-gray-200 shadow-md overflow-x-scroll bg-white mb-4">
      <div className="flex flex-col md:flex-row justify-between items-center px-5 py-4 border-b border-gray-200">
        <div className="w-full md:w-1/2">
          <h3 className="text-lg text-gray-900 font-medium">
            {t('users.table.tableTitle')}
          </h3>
        </div>
        <Flex
          flexDirection={['column', 'column', 'row']}
          gap="3"
          w="full"
          maxW={['full', 'full', '50%']}
        >
          <InputGroup w="full">
            <InputLeftElement color="gray.500">
              <FeatherIcon icon="search" size="20" />
            </InputLeftElement>
            <Input
              name="user-firstName"
              placeholder={t('users.table.searcherPlaceholder')}
              onChange={handleFilterInputChange}
            />
          </InputGroup>
          <Box w={['full', 'full', 'auto']}>
            <Button
              variant="primary"
              leftIcon={<FeatherIcon icon="user-plus" size="20" />}
              onClick={onClick}
              w="full"
            >
              {t('users.table.inviteUserLabel')}
            </Button>
          </Box>
        </Flex>
      </div>

      {data && (
        <>
          <ChakraTable {...getTableProps()} className="w-full">
            <Thead>
              {headerGroups.map((headerGroup: any, index: number) => (
                <Tr key={`A${index}`} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, i: number) => (
                    <Th key={`B${i}`} {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row: any, index: number) => {
                prepareRow(row);
                return (
                  <Tr key={`C${index}`} {...row.getRowProps()}>
                    {row.cells.map((cell: any, i: number) => {
                      return (
                        <Td key={`D${i}`} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </ChakraTable>
        </>
      )}

      {data.length === 0 && (
        <EmptyState
          color="primary"
          theme="light-circle"
          title={t('users.table.emptyTitle')}
          text={t('users.table.emptyText')}
          ilustration="cloud"
          className="py-6"
        />
      )}
      {pageCount > 1 && (
        <div className="flex justify-between items-center px-5 py-4">
          <Button
            onClick={() => previousPage()}
            size="sm"
            variant="outline"
            disabled={!canPreviousPage}
            leftIcon={<FeatherIcon icon="arrow-left" />}
          >
            {t('users.btnPrevLabel')}
          </Button>
          <span className="mx-3">{`Página ${pageIndex + 1} de ${
            pageOptions.length
          }`}</span>
          <Button
            onClick={() => nextPage()}
            size="sm"
            variant="outline"
            disabled={!canNextPage}
            rightIcon={<FeatherIcon icon="arrow-right" />}
          >
            {t('users.btnNextLabel')}
          </Button>
        </div>
      )}
    </div>
  );
}
