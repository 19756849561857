import { useQuery } from 'react-query';

import getTransfer from '@services/transfers/getTransfer';

const useTransferDetail = (uuid: string) => {
  const { data, isLoading, error, refetch } = useQuery(
    ['transfers', uuid],
    () => getTransfer(uuid)
  );

  return { data, isLoading, error, refetch };
};

export default useTransferDetail;
