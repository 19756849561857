import { Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { CalendarIcon } from './Svg/CalendarIcon';
import { CoinsIcon } from './Svg/CoinsIcon';
import { DollarIcon } from './Svg/DollarIcon';
import { IdIcon } from './Svg/IdIcon';
import { PersonRightArrowIcon } from './Svg/PersonRightArrowIcon';

const icons = {
  id: <IdIcon />,
  calendar: <CalendarIcon />,
  coins: <CoinsIcon />,
  dollar: <DollarIcon />,
  personArrow: <PersonRightArrowIcon />,
} as const;

interface Props {
  label: string;
  iconKey: keyof typeof icons;
  value: string | ReactNode;
}
export const SummaryItem = ({ iconKey, label, value }: Props) => {
  return (
    <Flex>
      <Flex>
        {icons[iconKey]}
        <Text ml="12px">{label}</Text>
      </Flex>
      <Text fontFamily="Larsseit-Medium" fontWeight="500">
        {value}
      </Text>
    </Flex>
  );
};
