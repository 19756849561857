export const PersonRightArrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="4" fill="#DBF5D4" />
    <path
      d="M15.5 16.5L17 15M17 15L15.5 13.5M17 15H14M12 13.75H9.75C9.05222 13.75 8.70333 13.75 8.41943 13.8361C7.78023 14.03 7.28002 14.5302 7.08612 15.1694C7 15.4533 7 15.8022 7 16.5M13.25 9.75C13.25 10.9926 12.2426 12 11 12C9.75736 12 8.75 10.9926 8.75 9.75C8.75 8.50736 9.75736 7.5 11 7.5C12.2426 7.5 13.25 8.50736 13.25 9.75Z"
      stroke="#288703"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
