import { Box, Heading, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { Icon } from '@comp/Global/Icon';
import { TColor } from '@comp/Global/types';

export interface TitleProps {
  title: string;
  icon?: string;
  iconColor?: TColor;
  subtitle?: string;
}

export const Title: FC<TitleProps> = (props: TitleProps) => {
  const { title, subtitle, icon, iconColor } = props;
  const titleMarginLeft = icon ? 'ml-4' : 'auto';
  return (
    <Box className="flex">
      {icon && iconColor && (
        <Icon size="lg" theme="light-square" color={iconColor} icon={icon} />
      )}
      <Box className={titleMarginLeft}>
        <Heading color="gray.900" mb="1">
          {title}
        </Heading>
        <Text className="text-md text-gray-700 font-normal">{subtitle}</Text>
      </Box>
    </Box>
  );
};
