import { getMultiRegionMap } from '@agrotoken/common-utils';
import { IGrainContract } from '@common/types';
import { useAuth } from '@src/context/authContext';
import { AxiosError } from 'axios';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { Spinner } from '@comp/Global/Spinner';
import { getGrainContract } from '@services/GrainContracts';

const SuccessBR = lazy(() => import('../../BR/Success/Detokenization'));

function DetokenizationSuccess() {
  const { id } = useParams();
  const { nationality } = useAuth();
  const { t } = useTranslation();

  const {
    isLoading: isLoadingGrainContract,
    error: grainContractError,
    data: grainContract,
  } = useQuery<IGrainContract | undefined, AxiosError>(
    ['grainContract', id],
    getGrainContract
  );

  if (isLoadingGrainContract) return <Loader />;

  if (grainContractError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={grainContractError.message}
      />
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <SuccessBR grainContract={grainContract} />
    </Suspense>
  );
}

export default DetokenizationSuccess;
