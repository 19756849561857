import { CardsTranslationsType } from '../es';

export const CARDS_TRANSLATIONS_PT: CardsTranslationsType = {
  cardsRequest: {
    onboardingTitle:
      'Você não preencheu os dados necessários para solicitar um cartão',
    appRedirectionTitle:
      'Por favor vá para o aplicativo para fazer esta solicitação.',
    linkButton: 'Preencher dados',
  },
  cardList: {
    header: {
      downloadButton: 'Baixar resumo',
      requestButton: 'Solicitar cartāo',
      title: 'Cartões',
    },
    main: {
      myCardsTitle: 'Meus cartões',
      myCardDescription: 'Terminada em ****',
      expiration: 'Vence em:',
      activation: 'Ativar no aplicativo',
      cardStatus: {
        ACTIVE: 'Ativo',
        INACTIVE: 'Inativo',
        PAUSED: 'Pausada',
        EXPIRED: 'Expirada',
      },
      cardType: {
        physical: 'Física',
        virtual: 'Virtual',
      },
    },
  },
};
