import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { IBusinessActivity } from '@common/types';
import { getBusinessActivities } from '@services/Businesses';

const MainActivity: VFC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboardingLevelThree',
  });

  const {
    isError,
    isLoading,
    data: activities,
  } = useQuery<IBusinessActivity[]>(
    ['businessActivities'],
    getBusinessActivities
  );

  if (isError) return <div>Error loading business activities</div>; // TODO
  return (
    <FormControl isInvalid={Boolean(errors.mainActivity)}>
      <FormLabel htmlFor="businessActivityId">
        {t('mainActivity.label')}
      </FormLabel>
      <Select
        id="businessActivityId"
        placeholder={t('mainActivity.placeholder')}
        maxW={{ base: '100%', md: '50%' }}
        borderRadius="base"
        size="lg"
        {...register('businessActivityId', { required: t('requiredMsg') })}
      >
        {activities?.map((activity) => (
          <option key={activity.id} value={activity.id}>
            {activity.name}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{errors.mainActivity?.message}</FormErrorMessage>
    </FormControl>
  );
};
export default MainActivity;
