import { httpPlatform } from '../../http-common';

export const cancelTransfer = async (uuid: string): Promise<unknown> => {
  try {
    const { data } = await httpPlatform.post<Promise<unknown>>(
      `/transfers/${uuid}/cancel`
    );

    return data;
  } catch (error) {
    // TODO: Handle error
  }
};
