import { Flex, Heading, Text } from '@chakra-ui/react';
import { Input } from '@comp/Global/Input';
import { FC, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { getBusinessById } from '@services/Businesses';
import {
  IAccountType,
  IBankAccount,
  IBusiness,
  IPix,
} from '../../common/types';
import { useUser } from '../../context/userContext';
import { Container } from '../Global/Container';
import { Button } from '@comp/Global/Button';
import {
  CURRENT_USER_REFETCH_INTERVAL,
  FULL_ONBOARDING_KYC,
  HOME_PATH,
} from '../../common/const';
import {
  getBankAccountTypes,
  getOneBankAccountByBusiness,
  updateBusinessBankAccount,
} from '@services/BankAccounts';
import { Modal } from '@comp/Global/Modal';
import { currentUserCreator } from '@common/utils';
import { RequireAdminAccess } from '@comp/AccessControl';
import {
  ENationalities,
  getAccountNumberFromCbu,
  getMultiRegionMap,
} from '@agrotoken/common-utils';
import React from 'react';
import { useAuth } from '@src/context/authContext';
import { FiUserCheck } from 'react-icons/fi';

const BusinessForm_Ar = React.lazy(
  () => import('../AR/AccountConfig/BusinessForm')
);
const BusinessForm_Br = React.lazy(
  () => import('../BR/AccountConfig/BusinessForm')
);

const businessFormComponent = getMultiRegionMap({
  AR: BusinessForm_Ar,
  BR: BusinessForm_Br,
});
export const AccountConfig: FC = () => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const BusinessFormData = businessFormComponent[nationality];
  const [isOpenBankAccountEdit, setIsOpenBankAccountEdit] =
    useState<boolean>(false);
  const [personalData, setPersonalData] = useState<boolean>(true);
  const [businessData, setBusinessData] = useState<boolean>(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false);
  const [shouldEditBusinessData, setShouldEditBusinessData] = useState(false);
  const [accountTypeOptions, setAccountTypeOptions] = useState<
    { label: string; value: number | string }[]
  >([{ label: t('accountConfig.labelSelectOption'), value: '' }]);
  const [bankName, setBankName] = useState<string | null>('');
  const [cbu, setCbu] = useState<string | null>('');
  const [accountTypeId, setAccountTypeId] = useState<number | null>(null);
  const [alias, setAlias] = useState<string | null>('');
  const [bankEntityNumber, setBankEntityNumber] = useState<string | null>(null);
  const [documentId, setDocumentId] = useState<number | null>(null);
  const [preloadedFile, setPreloadedFile] = useState<{
    path: string;
    size: number;
    uuid: string;
  } | null>(null);
  const [accountNumber, setAccountNumber] = useState<string | null>(null);
  const [pix, setPix] = useState<IPix | null>(null);
  const [subsidiaryNumber, setSubsidiaryNumber] = useState<string | null>(null);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isOpenPermissionModal, setIsOpenPermissionModal] =
    useState<boolean>(false);

  const { currentUser, selectedBusinessId, errorUser, isLoadingUser } =
    useUser();

  const currentUserIsCreator = Boolean(
    currentUser &&
      selectedBusinessId &&
      currentUserCreator(currentUser, selectedBusinessId)
  );

  const navigate = useNavigate();
  const {
    isLoading,
    error: getBusinessByIdError,
    data: business,
  } = useQuery<IBusiness | undefined>(
    ['business', selectedBusinessId],
    getBusinessById,
    { enabled: !!selectedBusinessId }
  );

  const {
    isLoading: isLoadingAccountTypes,
    error: getAccountTypesError,
    data: accountTypes,
  } = useQuery<IAccountType[] | undefined>(
    ['accountTypes'],
    getBankAccountTypes
  );

  const {
    isLoading: isLoadingBankAccount,
    error: bankAccountError,
    data: bankAccount,
    refetch,
  } = useQuery<IBankAccount | undefined>(
    ['bankAccount', selectedBusinessId],
    getOneBankAccountByBusiness,
    {
      refetchInterval: CURRENT_USER_REFETCH_INTERVAL,
      enabled: currentUserIsCreator,
    }
  );

  useEffect(() => {
    if (accountTypes) {
      const nextAccountTypes = accountTypes.map(({ name, id, keyCode }) => ({
        key: keyCode,
        label: name,
        value: id,
      }));
      setAccountTypeOptions((prev) => [...prev, ...nextAccountTypes]);
    }
  }, [accountTypes]);

  useEffect(() => {
    if (!bankAccount) return;
    setBankName(bankAccount.entityName);
    setCbu(bankAccount.cbu);
    setAlias(bankAccount.alias);
    setAccountTypeId(bankAccount.accountTypeId);
    setBankEntityNumber(bankAccount.entityNumber);
    setSubsidiaryNumber(bankAccount.subsidiaryNumber);
    setAccountNumber(bankAccount.accountNumber);
    setPix(bankAccount.pix);
    setDocumentId(bankAccount.documentId);
    setPreloadedFile(
      bankAccount.document
        ? {
            path: bankAccount.document.originalName,
            size: bankAccount.document.size,
            uuid: bankAccount.document.uuid,
          }
        : null
    );
  }, [bankAccount]);

  const handlePersonalData = () => {
    setBusinessData(false);
    setPersonalData(true);
  };
  const handleBusinessData = () => {
    if (currentUserIsCreator) {
      setPersonalData(false);
      setBusinessData(true);
    } else {
      setIsOpenPermissionModal(true);
    }
  };

  const handleEditData = () => {
    setShouldEditBusinessData(true);
  };

  const {
    mutate: mutateUpdateBusinessBankAccount,
    isLoading: isLoadingMutate,
    error: mutateUpdateBusinessBankAccountError,
  } = useMutation(updateBusinessBankAccount, {
    onSuccess: () => {
      setIsOpenBankAccountEdit(false);
      refetch();
    },
  });

  const handleOpenConfirmModal = () => {
    if (!isValidForm) {
      setShouldShowError(true);
      return;
    } else {
      setIsOpenBankAccountEdit(true);
    }
  };

  const handleSubmitAr = async (ev: any, id: number) => {
    setShouldEditBusinessData(false);
    ev.preventDefault(ev);

    const body = {
      cbu: cbu,
      accountNumber: cbu && getAccountNumberFromCbu(cbu),
      alias: alias,
    };
    mutateUpdateBusinessBankAccount({
      id: id,
      body,
    });
  };

  const handleSubmitBr = async (ev: any, id: number) => {
    setShouldEditBusinessData(false);
    ev.preventDefault(ev);

    const body = {
      entityName: bankName,
      accountNumber: accountNumber,
      subsidiaryNumber: subsidiaryNumber,
      accountTypeId: accountTypeId,
      entityNumber: bankEntityNumber,
      pix: pix,
    };
    mutateUpdateBusinessBankAccount({
      id: id,
      body,
    });
  };

  if (
    isLoading ||
    isLoadingUser ||
    isLoadingBankAccount ||
    isLoadingAccountTypes
  )
    return <Loader />;

  if (getBusinessByIdError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(getBusinessByIdError as any)?.message}
      />
    );
  }

  if (errorUser) {
    return (
      <ServerError title={t('serverError.title')} text={errorUser?.message} />
    );
  }

  if (mutateUpdateBusinessBankAccountError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(mutateUpdateBusinessBankAccountError as any)?.message}
      />
    );
  }

  if (bankAccountError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(bankAccountError as any)?.message}
      />
    );
  }
  if (getAccountTypesError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(getAccountTypesError as any)?.message}
      />
    );
  }

  if (!currentUser || !selectedBusinessId)
    return (
      <ServerError
        title={t('serverError.title')}
        text={t('serverError.text')}
      />
    );

  return (
    <>
      <Container className="pb-20">
        <Heading size="lg" mb="2">
          {t('accountConfig.title')}
        </Heading>
        <Text fontSize="lg" color="gray.900" mb="8">
          {business?.name}
        </Text>
        <Flex>
          <Button
            label={t('accountConfig.personalDetails')}
            variant={personalData ? 'link' : 'link-gray'}
            onClick={handlePersonalData}
            size="sm"
            className={`pb-3 mr-2 border-b-2 rounded-none  ${
              personalData ? 'border-primary-700 ' : 'border-white'
            }`}
          />
          <Button
            label={t('accountConfig.businessDetails')}
            variant={businessData ? 'link' : 'link-gray'}
            onClick={handleBusinessData}
            size="sm"
            className={`pb-3 mr-2 border-b-2 rounded-none  ${
              businessData ? 'border-primary-700' : 'border-white'
            }`}
          />
        </Flex>
        <hr />

        {personalData && (
          <form id="personalData" className="mb-8">
            <div>
              <Heading size="md" mt="10" mb="2">
                {t('accountConfig.sectionTitle1')}
              </Heading>
              <Text fontSize="md" color="gray.900" mb="8">
                {t('accountConfig.text')}
              </Text>
              <div className="grid md:grid-cols-2 gap-x-6 gap-y-8 mb-8 w-3/5">
                <Input
                  name="firstName"
                  label={t('accountConfig.labelFirstName')}
                  placeholder={t('accountConfig.placeHolderFirstName')}
                  fullWidth
                  value={currentUser.firstName}
                  type="text"
                  disabled
                />
                <Input
                  name="lastName"
                  label={t('accountConfig.labelLastName')}
                  placeholder={t('accountConfig.placeHolderLastName')}
                  fullWidth
                  value={currentUser.lastName}
                  type="text"
                  disabled
                />
                <Input
                  name="email"
                  label={t('accountConfig.labelEmail')}
                  placeholder={t('accountConfig.placeHolderEmail')}
                  fullWidth
                  value={currentUser.email}
                  type="email"
                  disabled
                />
                <Input
                  name="phone"
                  label={t('accountConfig.labelPhone')}
                  placeholder={t('accountConfig.placeHolderPhone')}
                  fullWidth
                  value={currentUser.phone}
                  type="text"
                  disabled
                />
              </div>
            </div>
          </form>
        )}

        {businessData && (
          <RequireAdminAccess>
            <>
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <BusinessFormData
                    bankAccount={bankAccount}
                    subsidiaryNumber={subsidiaryNumber}
                    setSubsidiaryNumber={setSubsidiaryNumber}
                    setIsValidForm={setIsValidForm}
                    accountNumber={accountNumber}
                    setAccountNumber={setAccountNumber}
                    preloadedFile={preloadedFile}
                    bankName={bankName}
                    setBankName={setBankName}
                    cbu={cbu}
                    setCbu={setCbu}
                    bankEntityNumber={bankEntityNumber}
                    setBankEntityNumber={setBankEntityNumber}
                    accountTypeId={accountTypeId}
                    setAccountTypeId={setAccountTypeId}
                    alias={alias}
                    setAlias={setAlias}
                    pix={pix}
                    onSubmit={(ev: any) => {
                      nationality === ENationalities.AR
                        ? handleSubmitAr(ev, bankAccount!.id)
                        : handleSubmitBr(ev, bankAccount!.id);
                    }}
                    currentUserCreator={currentUserCreator(
                      currentUser,
                      selectedBusinessId
                    )}
                    shouldEditBusinessData={shouldEditBusinessData}
                    accountTypeOptions={accountTypeOptions}
                    business={business}
                    shouldShowError={shouldShowError}
                    className="mt-8"
                  />
                </Suspense>
              </>
              <Modal
                isOpen={isOpenVerificationModal}
                title={t('verifyIdentity.titleVerifyIdentity')}
                text={t('verifyIdentity.textVerifyIdentity')}
                labelBtnConfirm={t('verifyIdentity.confirmBtn')}
                icon={FiUserCheck}
                onClickConfirm={() => navigate(FULL_ONBOARDING_KYC)}
                onClickClose={() => navigate(HOME_PATH)}
              />
            </>
          </RequireAdminAccess>
        )}
      </Container>
      {(businessData || personalData) && (
        <footer className="bg-gray-50 w-full py-4 px-28 fixed bottom-0 inset-x-0 ">
          <div className="flex">
            {(personalData || (businessData && shouldEditBusinessData)) && (
              <Button
                disabled={!currentUserCreator(currentUser, selectedBusinessId)}
                className="ml-auto"
                variant="primary"
                type="button"
                id="businessData"
                label={t('accountConfig.labelBtn')}
                form="businessForm"
                onClick={() => handleOpenConfirmModal()}
              />
            )}
            {businessData && !shouldEditBusinessData && (
              <Button
                disabled={!currentUserCreator(currentUser, selectedBusinessId)}
                className="ml-auto"
                variant={'primary'}
                onClick={() => {
                  handleEditData();
                }}
                type={'button'}
                id={'businessData'}
                label={t('accountConfig.labelBtnEdit')}
              />
            )}
          </div>
        </footer>
      )}
      <Modal
        size="sm"
        isOpen={isOpenBankAccountEdit}
        title={t('accountConfig.accountEditTitle')}
        text={t('accountConfig.accountEditText')}
        labelBtnConfirm={t('accountConfig.confirmBtn')}
        labelBtnClose={t('accountConfig.cancelBtnLabel')}
        iconName="file-text"
        iconColor="gray"
        form="businessForm"
        onClickClose={() => setIsOpenBankAccountEdit(false)}
        isLoading={isLoadingMutate}
      />
      <Modal
        isOpen={isOpenPermissionModal}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    </>
  );
};
