import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { IParameter } from '@common/types';
import { http } from '@src/http-common';

export const getParameterById = async ({
  queryKey,
}: any): Promise<IParameter> => {
  const [, id] = queryKey;
  return await http
    .get<IParameter>(`/${PLATFORM_PREFIX}/parameters/${id}`)
    .then((resp) => {
      const parameter: IParameter = resp.data;
      return parameter;
    });
};
