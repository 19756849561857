import { Flex, Text } from '@chakra-ui/react';
import { CryptoCurrencySymbol, EUnits } from '@common/types';
import { formatNumber } from '@common/utils';
import { FC } from 'react';
import { TokenIcon } from '@comp/Global/TokenIcon';

export interface TokenAmountProps {
  amount: number;
  token: CryptoCurrencySymbol;
}

export const TokenAmount: FC<TokenAmountProps> = ({ amount, token }) => {
  return (
    <Flex gap={1} alignItems={'center'}>
      <TokenIcon size="sm" token={token} />
      <Text>{formatNumber(amount, EUnits.CRYPTO)}</Text>
      <Text>{token}</Text>
    </Flex>
  );
};
