import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  DocumentFormat,
  IBankAccount,
  ICde,
  ICdeForm,
  IDocument,
} from '@src/common/types';
import {
  createDocument,
  deleteDocument,
  uploadImage,
} from '../../../services/Documents';

import { FeaturedIcon } from '../Icon/FeaturedIcon';
import { ErrorPreview } from './ErrorPreview';
import { SuccessPreview } from './SuccessPreview';
import {
  acceptedDocumentMimeTypes,
  acceptedImagesMimeTypes,
  allowedImages,
  maxSizeFileMb,
} from './consts';
import { FiAlertCircle } from 'react-icons/fi';

export interface Props extends HTMLAttributes<HTMLInputElement> {
  isValid: boolean;
  shouldShowError: boolean;
  documentTypeId: number;
  cdesForm?: ICdeForm[];
  setCdesForm?: React.Dispatch<React.SetStateAction<ICdeForm[]>>;
  index?: keyof ICde[];
  className?: string;
  setDocumentId?:
    | React.Dispatch<React.SetStateAction<number | null>>
    | ((documentId: number | null) => void);
  documentId?: number | null;
  deleteCallback?: (...args: any[]) => void;
  maxFileSize?: number; // max file size in bytes
  allowImages?: boolean;
  bankAccountForm?: IBankAccount;
  setBankAccountForm?: React.Dispatch<React.SetStateAction<number | null>>;
  preloadedFile?: { path: string; size: number; uuid: string } | null;
  disabled?: boolean;
  registrationProps?: any;
}

const dropzoneErrorsByCode = {
  'file-invalid-type': 'El archivo no es válido',
  'file-too-large': 'El archivo es demasiado grande',
  'file-too-small': 'El archivo es demasiado pequeño',
  'too-many-files': 'Demasiados archivos',
};

export const DragAndDrop: FC<Props> = ({
  cdesForm,
  setCdesForm,
  index,
  className = '',
  isValid = true,
  shouldShowError = false,
  setDocumentId,
  documentTypeId,
  deleteCallback,
  maxFileSize = Infinity,
  allowImages = false,
  preloadedFile,
  disabled,
  registrationProps,
}) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<any>(undefined);
  const [uuid, setUuid] = useState<string>('');
  const [error, setError] = useState<string>('');
  const dropzoneAcceptedFiles = {
    ...(allowImages ? acceptedImagesMimeTypes : acceptedDocumentMimeTypes),
  };

  const { mutate: mutateUploadDocument, isLoading: isLoadingDocument } =
    useMutation(createDocument, {
      onSuccess: (data: IDocument | undefined) => {
        setError('');
        if (!data) return;
        if (cdesForm && setCdesForm) {
          const result = cdesForm.map((el, i) => {
            const { id, uuid, originalName, size } = data;
            return i === index
              ? {
                  ...el,
                  documentId: id,
                  preloadedFile: {
                    path: originalName,
                    size: size,
                    uuid: uuid,
                  },
                }
              : el;
          });

          setCdesForm(result);
        }
        if (setDocumentId) {
          setDocumentId(data.id);
        }
        setUuid(data.uuid);
      },
      onError: (err: Error) => {
        setError(err.message);
      },
    });

  const { mutate: mutateUploadImage, isLoading: isLoadingUploadImage } =
    useMutation(uploadImage, {
      onSuccess: (data: IDocument | undefined) => {
        setError('');
        if (!data) return;
        if (cdesForm && setCdesForm) {
          const { id, uuid, originalName, size } = data;
          const result = cdesForm.map((el, i) =>
            i === index
              ? {
                  ...el,
                  documentId: data.id,
                  preloadedFile: {
                    path: originalName,
                    size: size,
                    uuid: uuid,
                  },
                }
              : el
          );
          setCdesForm(result);
        }
        if (setDocumentId) {
          setDocumentId(data.id);
        }
        setUuid(data.uuid);
      },
      onError: (err: Error) => {
        setError(err.message);
      },
    });

  const { mutate: mutateDeleteDocument, isLoading: isLoadingDelete } =
    useMutation(deleteDocument, {
      onSuccess: (_data: IDocument | undefined) => {
        setFile(undefined);
        deleteCallback?.();
      },
    });
  const isLoading =
    isLoadingDocument || isLoadingUploadImage || isLoadingDelete;
  useEffect(() => {
    cdesForm?.forEach((cde, i) => {
      if (i === index && cde.preloadedFile) setFile(cde.preloadedFile);
      if (i === index && cde.documentId === null) setFile(null);
    });
  }, [cdesForm, index]);

  useEffect(() => {
    if (preloadedFile) setFile(preloadedFile);
  }, [preloadedFile]);

  const handleDocumentDelete = async () => {
    // Ensure that the 'index' variable is declared and defined
    const validIndex = typeof index === 'number' && index >= 0;

    // Use optional chaining and nullish coalescing for cleaner code
    const docUuid = validIndex
      ? cdesForm?.[index]?.preloadedFile?.uuid
      : preloadedFile?.uuid ?? uuid ?? null;

    // Check if docUuid exists before proceeding
    if (!docUuid) {
      console.error('No document UUID found. Aborting deletion.');
      return;
    }

    // Use a try-catch block for better error handling
    try {
      await mutateDeleteDocument(docUuid);
    } catch (error) {
      console.error('Error deleting document:', error);
      // Handle the error as needed
    }
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: dropzoneAcceptedFiles,
    maxSize: maxFileSize,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        const errorMessages = fileRejections.map(({ errors, file }, index) => {
          const isFirstError = !index;
          const fileName = file.name;
          const errorMessagesForFileName = errors.map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore-next-line
            ({ code }: { code: string }) => dropzoneErrorsByCode[code]
          );

          if (isFirstError) {
            setFile(file);
          }

          return `${fileName}: ${errorMessagesForFileName.join(' , ')}`;
        });

        setError(errorMessages.join(' , '));
      }

      acceptedFiles.forEach(async (accFile: any) => {
        const reader = new FileReader();

        reader.onload = () => {
          setError('');
          setFile(
            Object.assign(accFile, {
              preview: URL.createObjectURL(accFile),
            })
          );
        };
        reader.readAsArrayBuffer(accFile);

        const formData = new FormData();
        formData.append('file', accFile);
        if (allowImages) {
          await mutateUploadImage({ formData, documentTypeId });
        } else {
          await mutateUploadDocument({ formData, documentTypeId });
        }
      });
    },
    maxFiles: 1,
    noClick: true,
  });

  useEffect(() => {
    URL.revokeObjectURL(file?.preview);
  }, [file]);

  return (
    <>
      {!file && (
        <div>
          <Box
            borderRadius="base"
            {...getRootProps({
              role: 'input',
              className: `border p-4 flex flex-y cursor-default border-dashed display-grid ${
                isValid || !shouldShowError
                  ? 'border-gray-200'
                  : !isValid && shouldShowError
                  ? 'border-error-200'
                  : ''
              } ${className}`,
            })}
          >
            <input {...registrationProps} {...getInputProps()} />
            <div className="text-xl flex mr-2">
              <FeaturedIcon
                size="md"
                color="gray"
                theme="light-circle-outline"
                icon="upload-cloud"
              />
            </div>
            <Box className="flex flex-col">
              <div>
                <Button
                  size="md"
                  variant="linkPrimary"
                  onClick={open}
                  color="gray"
                  fontWeight="medium"
                  fontSize="small"
                  disabled={disabled}
                >
                  {' '}
                  {t('dragAndDrop.click')}
                </Button>
                <span className="mt-1 text-sm text-gray-500 font-normal ml-1">
                  {t('dragAndDrop.drag')}
                </span>
              </div>
              <p className="mt-1 text-xs text-gray-500 font-normal">
                {`${t('dragAndDrop.format')} ${
                  allowImages ? DocumentFormat.IMAGES : DocumentFormat.PDF
                }`}
                {' ('}
                {`${t('dragAndDrop.maxSize')} ${maxSizeFileMb}MB)`}
              </p>
            </Box>
            <p className="mt-1 text-xs text-gray-500 font-normal"></p>
          </Box>
          {!isValid && shouldShowError && (
            <Flex columnGap="1" mt="1.5" color="error.500">
              <Flex alignItems={'top'} minWidth={4}>
                <FiAlertCircle color="error.500" />
              </Flex>
              <Text color="error.500" variant="regular" size="sm">
                {t('dragAndDrop.emptyError')}
              </Text>
            </Flex>
          )}
        </div>
      )}
      {file && !error && (
        <SuccessPreview
          fileName={file.path}
          fileSize={file.size}
          onClickDelete={handleDocumentDelete}
          isLoading={isLoading}
          disabled={disabled}
        />
      )}
      {error && (
        <ErrorPreview error={error} fileName={file.path} onClick={open} />
      )}
    </>
  );
};
