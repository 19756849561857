import { Box, Checkbox, Link, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface TermsAndConditionsProps {
  setHasAcceptedTerms: React.Dispatch<React.SetStateAction<boolean>>;
  hasAcceptedTerms: boolean;
  isDisabled?: boolean;
}
const TermsAndConditions: FC<TermsAndConditionsProps> = (
  props: TermsAndConditionsProps
) => {
  const { t } = useTranslation();
  const { setHasAcceptedTerms, hasAcceptedTerms, isDisabled } = props;
  return (
    <Box className="flex items-center" marginY={3}>
      <Checkbox
        className=""
        id="terms"
        onChange={() => setHasAcceptedTerms((prev) => !prev)}
        checked={hasAcceptedTerms}
        name="terms"
        type="checkbox"
        required
        isDisabled={isDisabled}
      />
      <Box className="flex flex-col ml-2">
        <Box className="flex items-center">
          <Text
            mr="1"
            fontWeight="400"
            fontSize="sm"
            color={isDisabled ? 'gray.300' : 'gray.700'}
          >
            {t('general.accept')}
          </Text>
          <Link
            className="font-medium text-sm"
            href={t('tokenization.termsAndConditionsLink')}
            target="_blank"
            rel="noreferrer"
          >
            <Text
              opacity={isDisabled ? 0.5 : 1}
              pointerEvents={isDisabled ? 'none' : 'auto'}
              color="primary.700"
              fontWeight="400"
              fontSize="sm"
              display="inline-block"
              mr="2"
              textDecorationLine={'underline'}
            >
              {t('general.termsAndConditions')}
            </Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
