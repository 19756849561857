import { Box, Divider, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import EmptyState from './EmptyState';

interface SummaryDetailElement {
  left: JSX.Element | JSX.Element[];
  right: JSX.Element | JSX.Element[];
}

interface SummaryDetailProps {
  topElements: SummaryDetailElement[];
  bottomElements: SummaryDetailElement[];
  topTitle: string;
  bottomTitle: string;
  isEmpty?: boolean;
  isLoading?: boolean;
}

/**
 * @name SummaryDetail
 * @description A general summary component used to display transaction information
 * @param {SummaryDetailElement[]} topElements Elements to display at the top of the summary, separating between left and right elements
 * @param {SummaryDetailElement[]} bottomElements Elements to display at the bottom of the summary, separating between left and right elements
 * @param {string} topTitle The title for the top part of the summary
 * @param {string} bottomTitle The title for the bottom part of the summary
 * @param {boolean} isEmpty Determines if the component display an `empty` state or not
 * @param {boolean} isLoading Determines if the component display a `loading` state or not
 * @returns {React.ReactElement} The react component
 */
export const SummaryDetail = ({
  bottomElements,
  topElements,
  bottomTitle,
  topTitle,
  isEmpty,
  isLoading,
}: SummaryDetailProps) => {
  const { t } = useTranslation();

  if (isEmpty) {
    return <EmptyState />;
  }

  return (
    <Box
      as="section"
      p="6"
      border="1px"
      borderRadius="4px"
      borderColor="gray.200"
      bgColor="gray.50"
    >
      <Skeleton isLoaded={!isLoading}>
        <Text size="sm" variant="medium" color="gray.700" mb="4">
          {topTitle}
        </Text>
      </Skeleton>
      <Flex direction="column" rowGap="2">
        {topElements.map((element, index) => (
          <Skeleton key={index} isLoaded={!isLoading}>
            <Flex justifyContent="space-between" alignItems="center">
              {element.left}
              {element.right}
            </Flex>
          </Skeleton>
        ))}
      </Flex>
      <Divider my="6" />
      <Skeleton isLoaded={!isLoading}>
        <Text size="sm" variant="medium" color="gray.700" mb="4">
          {bottomTitle}
        </Text>
      </Skeleton>
      <Flex direction="column" rowGap="2">
        {bottomElements.map((element, index) => (
          <Skeleton key={index} isLoaded={!isLoading}>
            <Flex justifyContent="space-between" alignItems="center">
              {element.left}
              {element.right}
            </Flex>
          </Skeleton>
        ))}
      </Flex>
    </Box>
  );
};
