import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Icon } from '@comp/Global/Icon';
import LoanAuthorizationCard from '@comp/LoanAuthorizationCard';
import { LenderType } from '@features/loans/enums';
import { getLenderTypeTranslation } from '@features/loans/utils';

const PaymentAuthorizationSuccessLayout = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const { logo, lenderType } = location.state;

  const translation = getLenderTypeTranslation(lenderType as LenderType);

  return (
    <LoanAuthorizationCard logo={logo}>
      <Box color="primary.700" mb="4">
        <Icon
          size={'lg'}
          color={'success'}
          theme={'no-background'}
          icon="check-circle"
        />
      </Box>
      <Text size="lg" color="gray.900" mb="2">
        {t(`${translation}.paymentAuthorization.success.heading`)}
      </Text>
      <Text size="md" color="gray.700">
        {t(`${translation}.paymentAuthorization.success.subheading`)}
      </Text>
    </LoanAuthorizationCard>
  );
};

export default PaymentAuthorizationSuccessLayout;
