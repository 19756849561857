import { Badge, CornIcon, SoyIcon, RadioButton } from '@agrotoken-tech/ui';
import {
  Flex,
  Text,
  Icon,
  Button,
  Divider,
  Tooltip,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { EUnits, IGrainContract } from '@common/types';
import { formatNumber } from '@common/utils';
import { formatDate } from '@common/utils/format-date';
import Warranty from '@comp/Warranty';
import { useTranslation } from 'react-i18next';
import { FiFileText, FiHelpCircle } from 'react-icons/fi';

export interface WarrantyCardProps {
  currencyName: 'SOYB' | 'CORB';
  requestNumber: number;
  expirationDate: number;
  farmCity: string;
  farmGeographicalDivision: string;
  healthControl: number;
  amountInSacas: number;
  equivalencesAmountBrl: number;
  selectedWarranty: IGrainContract | undefined;
  id: number;
  toBusinessFiscalId: string;
}

const PaymentRequestWarrantyCard = ({
  currencyName,
  requestNumber,
  expirationDate,
  farmCity,
  farmGeographicalDivision,
  healthControl,
  amountInSacas,
  equivalencesAmountBrl,
  selectedWarranty,
  id,
  toBusinessFiscalId,
}: WarrantyCardProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mapCurrencyToIcon = {
    SOYB: <Icon as={SoyIcon} color={'gray.500'} mr="1" boxSize={'16px'} />,
    CORB: <Icon as={CornIcon} color={'gray.500'} mr="1" boxSize={'16px'} />,
  };
  const fields = [
    {
      label: t('paymentRequestWarrantyCard.price'),
      data: (
        <Flex alignItems={'center'} gap={1}>
          R$ {formatNumber(equivalencesAmountBrl, EUnits.BRL)}
          <Tooltip
            label={'Equivalência com base no valor do contrato de tokenização.'}
            hasArrow
            placement="top"
            gutter={16}
            shouldWrapChildren
          >
            <Icon as={FiHelpCircle} color="gray.400" mb={'-0.5'} />
          </Tooltip>
        </Flex>
      ),
    },
    {
      label: t('paymentRequestWarrantyCard.expirationDate'),
      data: formatDate(expirationDate, 'DD/MM/YYYY'),
    },
    {
      label: t('paymentRequestWarrantyCard.availableSacas'),
      data: (
        <>
          {mapCurrencyToIcon[currencyName]}
          {formatNumber(amountInSacas, EUnits.SACAS)}
        </>
      ),
    },
    {
      label: t('paymentRequestWarrantyCard.score'),
      data: (
        <Badge colorScheme={healthControl ? 'success' : 'error'}>
          {healthControl
            ? t('paymentRequestWarrantyCard.excellent')
            : t('paymentRequestWarrantyCard.ruim')}
        </Badge>
      ),
    },

    {
      label: t('paymentRequestWarrantyCard.farm'),
      data: `${farmCity}, ${farmGeographicalDivision}`,
    },
  ];
  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={'xl'}>
        <DrawerOverlay />
        <DrawerContent padding={'8'}>
          <DrawerHeader>
            <DrawerCloseButton _focus={{ outline: 'none' }} />
          </DrawerHeader>
          <DrawerBody>
            <Warranty
              paymentRequestSelectWarrantyId={id}
              farmerFiscalId={toBusinessFiscalId}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex
        padding={4}
        flexDirection="column"
        borderRadius={'4px'}
        borderColor="gray.200"
        borderWidth={'1px'}
      >
        <Flex justifyContent={'space-between'}>
          <Flex gap="5px">
            <RadioButton value={id} />
            <Icon as={FiFileText} color="gray.700" boxSize={'20px'}></Icon>
            <Text
              color={'gray.900'}
              fontSize="sm"
              fontFamily={'Larsseit-Medium'}
            >
              {t(`paymentRequestWarrantyCard.title.${[currencyName]}`)}{' '}
              {`#${requestNumber}`}
            </Text>
          </Flex>
          <Button onClick={onOpen} variant="linkPrimary">
            {t('paymentRequestWarrantyCard.viewMore')}
          </Button>
        </Flex>
        <Divider color="gray.200" my={4} />

        <Flex flexWrap={'wrap'} rowGap="3">
          {fields.map((field) => (
            <Flex minWidth={['100%', '100%', '50%']} key={field.label}>
              <Text
                textColor="gray.900"
                fontSize={'sm'}
                fontFamily="Larsseit-Medium"
                mr="1"
              >
                {field.label}
              </Text>
              <Text textColor="gray.500" fontSize={'sm'}>
                {field.data}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default PaymentRequestWarrantyCard;
