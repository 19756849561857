import { ENationalities } from '@agrotoken/common-utils';
import { useState, FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { COMMON_QUERY_PARAMS } from '@common/constants';
import BasePage from '@comp/SoftOnboarding/BasePage';
import {
  getBusinessInviteDetailsByUuid,
  updateBusinessUserInvite,
} from '@services/BusinessInvites';
import { DELETED_INVITATION, JOIN_BUSINESS } from '../../../common/const';

import {
  AuthRegisterDto,
  EBusinessInviteStatusId,
  IBusinessInviteDetails,
  SignUpDto,
} from '../../../common/types';
import { useAuth } from '../../../context/authContext';
import JoinBusinessFields from './JoinBusinessFields';

const SignUpToBusiness: FC = () => {
  const formContext = useForm<AuthRegisterDto>();
  const { handleSubmit, setValue } = formContext;
  const [isSingUpLoading, setIsSignUpLoading] = useState(false);

  const [_mutateError, setMutateError] = useState<string>('');
  const [searchParams] = useSearchParams();
  const inviteUuid = searchParams.get(
    COMMON_QUERY_PARAMS.INVITE_UUID
  ) as string;
  const region = searchParams.get(COMMON_QUERY_PARAMS.REGION) as ENationalities;
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isLoading: isLoadingInvite,
    isError: _isErrorInvite,
    data: businessInvite,
  } = useQuery<IBusinessInviteDetails | undefined>(
    ['businessInvite', region, inviteUuid],
    getBusinessInviteDetailsByUuid
  );

  const {
    mutate: mutateUpdateBusinessUserInvite,
    isLoading: isLoadingBusinessUser,
  } = useMutation(updateBusinessUserInvite, {
    onSuccess: () => {
      setMutateError('');
    },
    onError: (err: Error) => {
      setMutateError(err.message);
    },
  });

  //TODO: Change condition to keycodes

  if (businessInvite?.statusId === EBusinessInviteStatusId.DENIED) {
    navigate(`${JOIN_BUSINESS}${DELETED_INVITATION}`);
  }

  useEffect(() => {
    auth.signOut();
  }, []);

  const handleSendForm = async (data: AuthRegisterDto) => {
    setIsSignUpLoading(true);

    const { password, givenName, lastName, phone } = data;

    if (!businessInvite?.business?.fiscalId || !businessInvite?.inviteeData) {
      console.error('Invalid invite uuid'); // TODO: Properly show an error message or screen.
      return;
    }

    const cognitoPayload: SignUpDto = {
      businessFiscalId: businessInvite?.business?.fiscalId,
      country: businessInvite?.inviteeData?.country as ENationalities,
      email: businessInvite?.inviteeData?.email,
      businessName: businessInvite?.inviteeData?.businessName,
      businessPersonTypeId:
        businessInvite?.inviteeData?.businessStructureTypeId,
      password,
      firstName: givenName,
      lastName,
      phone,
    };

    try {
      await auth.signUpInCoreApi(cognitoPayload).finally(() => {
        setIsSignUpLoading(false);
      });

      const encodedEmail = btoa(cognitoPayload.email);
      const inviteDataToUpdate = {
        firstName: givenName,
        lastName,
        phone,
      };
      mutateUpdateBusinessUserInvite({
        uuid: inviteUuid,
        data: inviteDataToUpdate,
        region: businessInvite?.inviteeData?.country as ENationalities,
      });
      navigate(`/email-confirmation/${encodedEmail}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setValue('phone', businessInvite?.inviteeData?.phone as string);
    setValue('givenName', businessInvite?.inviteeData?.firstName as string);
    setValue('lastName', businessInvite?.inviteeData?.lastName as string);
  }, [businessInvite?.inviteeData, setValue]);

  const isLoading = isSingUpLoading || isLoadingInvite || isLoadingBusinessUser;
  return (
    <BasePage
      title={t('specialSignUp.title')}
      subTitle={t('specialSignUp.text')}
      allowBack={false}
    >
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(handleSendForm)} noValidate>
          <JoinBusinessFields
            isLoading={isLoading}
            inviteeData={businessInvite?.inviteeData}
          />
        </form>
      </FormProvider>
    </BasePage>
  );
};
export default SignUpToBusiness;
