import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';
import { Toast } from '@comp/Global/Toast';
import { TRANSFER_TOAST_DURATION_IN_MILLISECONDS } from '../constants';

interface ErrorTransferToastProps {
  isOpen: boolean;
  onClose: () => void;
}

const ErrorTransferToast = (props: ErrorTransferToastProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.details',
  });
  const { isOpen, onClose } = props;

  return (
    <Toast
      className="fixed top-6 right-6 z-50"
      show={isOpen}
      onClickClose={onClose}
      title={t('errorMessages.defaultError')}
      featuredIcon={FiAlertCircle}
      variant="destructive"
      duration={TRANSFER_TOAST_DURATION_IN_MILLISECONDS}
    />
  );
};

export default ErrorTransferToast;
