import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { Loan } from '@def/loan';
import { http } from '../http-common';

export const getLoans = (): Promise<Loan[]> => {
  return http.get(`/${PLATFORM_PREFIX}/loans`).then((resp) => {
    const dataResult = resp.data;
    return dataResult;
  });
};

export const getLoanDetail = (id: string): Promise<Loan> => {
  return http.get(`/${PLATFORM_PREFIX}/loans/${id}`).then((resp) => {
    const dataResult = resp.data;
    return dataResult;
  });
};
