import { Flex } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useStepForm } from '.';

export const BackButton: React.FC = () => {
  const { handlePreviousStep } = useStepForm();

  return (
    <Flex
      position="absolute"
      left="0"
      top="0"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      cursor="pointer"
    >
      <FeatherIcon icon="arrow-left" size={20} onClick={handlePreviousStep} />
    </Flex>
  );
};
