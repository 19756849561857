import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { VFC } from 'react';
import FeatherIcon from 'feather-icons-react';

interface Props {
  handleAddFinalBeneficiary(): void;
}

const AddBeneficiary: VFC<Props> = ({ handleAddFinalBeneficiary }) => {
  return (
    <Flex
      mt="6"
      p="4"
      border="1px"
      borderColor="gray.200"
      borderRadius="base"
      justify="space-between"
      flexDir={{ base: 'column', md: 'row' }}
    >
      <Box>
        <Heading size="sm">Beneficiario final</Heading>
        <Text as="i" fontSize="sm">
          Podés agregar uno más beneficiarios finales.
        </Text>
      </Box>
      <Button
        type="button"
        variant="ghost"
        mr="4"
        bg="white"
        borderColor="gray.200"
        leftIcon={<FeatherIcon icon="plus" />}
        onClick={handleAddFinalBeneficiary}
      >
        Agregar beneficiario
      </Button>
    </Flex>
  );
};

export default AddBeneficiary;
