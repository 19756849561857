import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { IUser, SignUpDto } from '../common/types';
import { COMMON_HEADERS, http } from '../http-common';

export const signUpUser = async (signUpDto: SignUpDto): Promise<void> => {
  return await http.post(
    `/${PLATFORM_PREFIX}/users/sign-up`,
    {
      ...signUpDto,
      country: undefined, // Country is not part of the DTO
    },
    {
      headers: {
        'Content-Type': 'application/json',
        [COMMON_HEADERS.CURRENT_REGION]: signUpDto.country,
      },
    }
  );
};

export const getCurrentUser = async (): Promise<IUser> => {
  return await http
    .get<IUser>(`/${PLATFORM_PREFIX}/users/current`)
    .then((resp) => {
      const user: IUser = resp.data;
      return user;
    });
};

export const updateUser = async (data: any): Promise<IUser | undefined> => {
  const resp = await http.patch<IUser>(
    `/${PLATFORM_PREFIX}/users/${data.id}`,
    data.body
  );
  const dataResult: IUser = resp.data;
  return dataResult;
};

export const getUser = async ({
  queryKey,
}: any): Promise<IUser | undefined> => {
  const [, id] = queryKey;
  if (!id) return;
  return http.get<IUser>(`/${PLATFORM_PREFIX}/users/${id}`).then((resp) => {
    const user: IUser = resp.data;
    return user;
  });
};
