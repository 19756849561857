export enum RegionCode {
  Argentina = 'AR',
  Brazil = 'BR',
}

export enum ECurrencySymbol {
  BRL = 'BRL',
  ARS = 'ARS',
  USD = 'USD',
  SOYA = 'SOYA',
  CORA = 'CORA',
  WHEA = 'WHEA',
  SOYB = 'SOYB',
  CORB = 'CORB',
  ARS_SOY = 'ARS_SOY',
  SOYA_ARS = 'SOYA_ARS',
  CORA_ARS = 'CORA_ARS',
  ABRC = 'ABRC',
}

export enum CurrencyValue {
  SOYB = 1,
  CORB = 2,
}

export enum FiatCurrencies {
  BRL = 'BRL',
  USD = 'USD',
}
