import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import { Button, Flex, Link, Show, Text } from '@chakra-ui/react';
import { GrainContractTypes } from '@common/enum';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import React, { useState, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft } from 'react-icons/fi';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { Checkbox } from '@comp/Global/Checkbox';
import { Mailto } from '@comp/Global/Mailto';
import { Modal } from '@comp/Global/Modal';
import { TermsAndConsModal } from '@comp/Global/TermsAndConsModal';
import { TOKENIZATIONS_PATH } from '../../../common/const';
import {
  IGrainContract,
  RealTokenizationStep,
  EGrainContractStatusId,
  FutureTokenizationStep,
  UserTypes,
} from '../../../common/types';
import {
  getGrainContract,
  sendGrainContractRequest,
} from '../../../services/GrainContracts';
import BreakDown from '../../BR/Tokenization/BreakDown';
import { ActionFooter } from '../../Global/ActionFooter';
import { Container } from '../../Global/Container';
import { Loader } from '../../Global/Loader';
import { ServerError } from '../../Global/ServerError';
import { TimeLine } from '../../Global/TimeLine';
import { Title } from '../../Global/Title';
import { Alert } from '@comp/Global/Alert';

const TokenizationDetails_Ar = React.lazy(
  () => import('../../AR/Tokenization/Confirm/TokenizationDetails')
);
const TokenizationDetails_Br = React.lazy(
  () => import('../../BR/Tokenization/TokenizationDetails')
);

const Documentation_Ar = React.lazy(
  () => import('../../AR/Tokenization/Documentation')
);
const Documentation_Br = React.lazy(
  () => import('../../BR/Tokenization/Documentation')
);

const documentation = getMultiRegionMap({
  AR: Documentation_Ar,
  BR: Documentation_Br,
});

const tokenizationDetails = getMultiRegionMap({
  AR: TokenizationDetails_Ar,
  BR: TokenizationDetails_Br,
});

export const Confirm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpenCollateralDetails, setisOpenCollateralDetails] =
    useState<boolean>(false);
  const { currentUser } = useUser();

  const {
    isLoading: isLoadingGrainContract,
    error: getGrainContractError,
    data: grainContract,
  } = useQuery<IGrainContract | undefined>(
    ['grainContract', id],
    getGrainContract
  );

  const { isPretokenized, grainContractType, agreementDoc } =
    grainContract ?? {};
  const isFutureGrain = grainContractType === GrainContractTypes.FUTURE;
  const [mutateError, setMutateError] = useState<string>('');
  const [cdeTons, setCdeTons] = useState<number>(0);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const { nationality } = useAuth();
  const TokenizationDetailsComponent = tokenizationDetails[nationality];
  const Documentation = documentation[nationality];

  const [shouldShowCheckboxError, setShouldShowCheckboxError] =
    useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  useEffect(() => {
    if (
      grainContract &&
      grainContract.grainContractStatusId !== EGrainContractStatusId.PENDING &&
      grainContract.grainContractStatusId !==
        EGrainContractStatusId.PRE_TOKENIZED
    ) {
      navigate(`${TOKENIZATIONS_PATH}/${id}`);
    }
  }, [grainContract, navigate, id]);

  useEffect(() => {
    if (grainContract && grainContract.cdes.length > 0) {
      grainContract.cdes.forEach((cde) => {
        setCdeTons((prev) => prev + Number(cde.tons));
      });
    }
    return () => {
      setCdeTons(0);
    };
  }, [grainContract]);
  const userIsManager = currentUser?.userType === UserTypes.MANAGER;

  const handlePreviousStep = () => {
    navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
  };

  const handleCheckbox = (e: any) => {
    setAcceptTerms(e.target.checked);
    setShouldShowCheckboxError(false);
  };

  const isGrainContractStatusPending =
    grainContract?.grainContractStatusId === EGrainContractStatusId.PENDING;
  const isGrainContractStatusPretokenized =
    grainContract?.grainContractStatusId ===
    EGrainContractStatusId.PRE_TOKENIZED;

  const { mutate: mutateSendGrainContractRequest, isLoading: isLoadingMutate } =
    useMutation(sendGrainContractRequest, {
      onSuccess: () => {
        setMutateError('');
        setIsLoadingSubmit(false);
        navigate(`${TOKENIZATIONS_PATH}/${id}/success`);
      },
      onError: (err: Error) => {
        setMutateError(err.message);
      },
    });

  const handleConfirmSubmit = async () => {
    if (!id) return;
    if (
      !isGrainContractStatusPending &&
      !isGrainContractStatusPretokenized &&
      !isPretokenized
    ) {
      setMutateError(t('tokenization.confirm.mutateError.alreadySent'));
      return;
    }
    setIsLoadingSubmit(true);
    mutateSendGrainContractRequest(+id);
  };

  if (isLoadingGrainContract) return <Loader />;

  if (getGrainContractError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(getGrainContractError as any)?.message}
      />
    );
  }

  if (!grainContract)
    return (
      <ServerError
        title={t('serverError.title')}
        text={t('serverError.text')}
      />
    );

  return (
    <Container className="pb-20">
      <Button
        variant="linkPrimary"
        leftIcon={<FiArrowLeft />}
        onClick={handlePreviousStep}
        my="8"
      >
        {t('tokenization.backButton')}
      </Button>
      <Title
        title={t('tokenization.title')}
        subtitle={isFutureGrain ? '' : t('tokenization.subtitle')}
      />

      <div className="flex gap-x-12 mt-9">
        <div className={isFutureGrain ? 'xl:w-3/5' : 'xl:w-3/4'}>
          {!!isPretokenized && (
            <Alert
              color="warning"
              text={t('tokenization.confirm.pretokenization.alert.title')}
              supportText={t(
                'tokenization.confirm.pretokenization.alert.description'
              )}
            />
          )}
          {!isFutureGrain && !isPretokenized && (
            <Alert text={t('tokenization.confirm.alertText')} color={'gray'}>
              <button
                className="text-sm font-medium text-primary-700 hover:text-primary-800 disabled:text-primary-300"
                onClick={() => setisOpenCollateralDetails(true)}
              >
                {t('tokenization.confirm.alertLink')}
              </button>
              <span className="text-sm font-medium text-gray-700">
                {t('tokenization.confirm.alertText2')}{' '}
              </span>
            </Alert>
          )}
          <Suspense fallback={<div>Loading...</div>}>
            <TokenizationDetailsComponent
              isFutureGrain={isFutureGrain}
              grainContract={grainContract}
              isPretokenized={isPretokenized}
            />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <Documentation
              isFutureGrain={isFutureGrain}
              grainContract={grainContract}
            />
          </Suspense>
        </div>
        <Show above="xl">
          {!isFutureGrain && (
            <div className={'w-1/4'}>
              <TimeLine
                currentStep={
                  isFutureGrain
                    ? FutureTokenizationStep.SUMMARY
                    : RealTokenizationStep.SUMMARY
                }
                grainContract={grainContract}
              />
            </div>
          )}
        </Show>
        {nationality === ENationalities.BR && isFutureGrain && (
          <div className="w-2/5">
            <BreakDown
              isFutureGrain={isFutureGrain}
              grainContract={grainContract}
              isPretokenized={isPretokenized}
            />
            <>
              {!userIsManager && (
                <div className="flex mt-4">
                  <Checkbox
                    className=""
                    id="terms"
                    onChange={handleCheckbox}
                    checked={acceptTerms}
                    name="terms"
                    type="checkbox"
                    required
                    showError={shouldShowCheckboxError && !acceptTerms}
                  />
                  <div className="flex flex-col ml-2">
                    <div className="flex items-center">
                      <span className="text-sm font-medium text-gray-700 mr-1">
                        {t('softOnboarding.signUpScreen.form.accept')}{' '}
                      </span>
                      <Link
                        className="font-medium text-sm"
                        href={t('tokenization.termsAndConditionsLink')}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Text
                          color="primary.700"
                          fontWeight="medium"
                          fontSize="sm"
                          display="inline-block"
                          mr="2"
                        >
                          {t(
                            'softOnboarding.signUpScreen.form.termsAndConditions'
                          )}
                        </Text>
                      </Link>
                    </div>
                    <p
                      className={
                        shouldShowCheckboxError && !acceptTerms
                          ? 'text-error-600 font-normal text-sm'
                          : 'hidden'
                      }
                    >
                      {t('specialSignUp.termsAndConsError')}
                    </p>
                  </div>
                </div>
              )}

              <Button
                w="full"
                mt="4"
                variant="primary"
                size="lg"
                disabled={!acceptTerms && !userIsManager}
                isLoading={isLoadingSubmit}
                onClick={() => handleConfirmSubmit()}
              >
                {t('tokenization.confirm.btnLabel')}
              </Button>
            </>
          </div>
        )}
      </div>
      {!isFutureGrain && (
        <>
          <ActionFooter
            onClick={handleConfirmSubmit}
            step={
              isFutureGrain
                ? FutureTokenizationStep.SUMMARY
                : RealTokenizationStep.SUMMARY
            }
            error={mutateError}
            isLoading={isLoadingMutate}
            btnLabel={t('tokenization.confirm.actionFooterBtnLabel')}
          />
        </>
      )}
      <Modal
        isOpen={isOpenCollateralDetails}
        iconName="info"
        variant="primary"
        iconColor="gray"
        labelBtnConfirm={t(
          'tokenization.summary.collateralDetails.labelConfirm'
        )}
        title={t('tokenization.summary.collateralDetails.title')}
        text={t('tokenization.summary.collateralDetails.text')}
        onClickConfirm={() => setisOpenCollateralDetails(false)}
        cancelButtonHidden
      >
        <p className="text-md font-normal text-gray-700 ">
          {t('tokenization.summary.collateralDetails.text2')}
        </p>
        <div>
          <p className="text-md font-normal text-gray-700">
            {t('tokenization.summary.collateralDetails.text3')}
          </p>
          <Mailto
            className="text-md font-normal text-primary-700"
            email={t('tokenization.summary.collateralDetails.mail')}
            subject={''}
          >
            {t('tokenization.summary.collateralDetails.mail')}
          </Mailto>
        </div>
      </Modal>
    </Container>
  );
};
