import { ENationalities } from '@agrotoken/common-utils';
import { Button, Flex } from '@chakra-ui/react';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import React from 'react';
import { FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FutureTokenizationStep,
  RealTokenizationStep,
  UserTypes,
} from '../../../common/types';
import { Container } from '../Container';

interface ActionFooterProps {
  onClick?: () => void;
  error?: string;
  onClickTerms?: () => void;
  step?: RealTokenizationStep | FutureTokenizationStep;
  isLoading?: boolean;
  text?: string;
  link?: string;
  btnLabel?: string;
  shouldShowError?: boolean;
}

export const ActionFooter: FC<ActionFooterProps> = ({
  shouldShowError,
  children,
  onClick,
  onClickTerms,
  step,
  error,
  isLoading,
  text,
  link,
  btnLabel,
}) => {
  const { t } = useTranslation();

  if (
    step === RealTokenizationStep.PROOF_OF_EXISTENCE ||
    step === RealTokenizationStep.PROOF_OF_LIQUIDITY ||
    step === FutureTokenizationStep.PRODUCTION_EXPECTATION ||
    step === FutureTokenizationStep.PROOF_OF_LIQUIDITY
  ) {
    return (
      <footer className="py-4 bg-gray-50 border border-gray-100 rounded fixed bottom-0 inset-x-0">
        <Container>
          <Flex flexDirection="column" alignItems="end">
            <Button variant="primary" onClick={onClick} isLoading={isLoading}>
              {t('btnNextStepLabel')}
            </Button>
            {error && (
              <p className="mt-1.5 text-sm text-error-500 text-right">
                {error}
              </p>
            )}
          </Flex>
        </Container>
      </footer>
    );
  }
  if (step === RealTokenizationStep.SUMMARY) {
    return (
      <footer className="py-4 bg-gray-50 border border-gray-100 rounded fixed bottom-0 inset-x-0">
        <Container>
          <div className="flex items-center justify-end flex-wrap">
            <div className="mb-2 xl:mb-0">
              <p className="text-sm text-gray-700 font-normal mr-2 inline">
                {text}
              </p>
              <button
                className="text-gray-900 underline hover:text-gray-900 disabled:text-gray-700  text-sm rounded"
                onClick={onClickTerms}
                type="button"
              >
                {link}
              </button>
            </div>

            <Button
              variant="primary"
              ml="8"
              onClick={onClick}
              isLoading={isLoading}
            >
              {btnLabel}
            </Button>
          </div>
          {error && (
            <p className="mt-1.5 text-sm text-error-500 text-right">{error}</p>
          )}
        </Container>
      </footer>
    );
  }
  return (
    <footer className="py-4 bg-gray-50 border border-gray-100 rounded fixed bottom-0 inset-x-0">
      <Container>{children}</Container>
    </footer>
  );
};
