import { IGrainContract } from '@common/types';
import { Decimal } from 'decimal.js';

// TODO: Deprecate in favor of backend value, once we've migrated values for old payment requests.
export const calculateWarrantyGCNetPricePerSaca = (
  selectedWarranty?: IGrainContract
) => {
  if (!selectedWarranty || !selectedWarranty.amount) {
    return;
  }
  const warrantyValuation = new Decimal(
    selectedWarranty?.equivalences?.amount['BRL']
  );
  const remainingTons = new Decimal(selectedWarranty.amount);
  const remainingSacas = remainingTons.mul(1000).div(60);
  const pricePerSaca = warrantyValuation.div(remainingSacas);
  return pricePerSaca;
};
