import { CURRENCIES, Currency, TokenCurrency } from '@agrotoken/common-utils';
import { useGetConversion, useGetLocalCurrency } from '.';

export const useGetConversionRate = () => {
  const { localCurrency } = useGetLocalCurrency();

  // Review this code, maybe is better to send this from backend
  const rateConversionSOYA = useGetConversion(
    1,
    CURRENCIES.SOYA,
    localCurrency as Currency
  );
  const rateConversionCORA = useGetConversion(
    1,
    CURRENCIES.CORA,
    localCurrency as Currency
  );
  const rateConversionWHEA = useGetConversion(
    1,
    CURRENCIES.WHEA,
    localCurrency as Currency
  );
  const rateConversionSOYB = useGetConversion(
    1,
    CURRENCIES.SOYB,
    localCurrency as Currency
  );
  const rateConversionCORB = useGetConversion(
    1,
    CURRENCIES.CORB,
    localCurrency as Currency
  );

  const rates: Record<TokenCurrency, number> = {
    [CURRENCIES.SOYA]: rateConversionSOYA,
    [CURRENCIES.WHEA]: rateConversionWHEA,
    [CURRENCIES.CORA]: rateConversionCORA,
    [CURRENCIES.SOYB]: rateConversionSOYB,
    [CURRENCIES.CORB]: rateConversionCORB,
    [CURRENCIES.ABRC]: 0,
    [CURRENCIES.WHEB]: 0,
    [CURRENCIES.COFB]: 0,
  };

  const tokenToLocalCurrency = (
    amount: number,
    sourceCurrency: TokenCurrency
  ) => {
    return Math.abs(amount * rates[sourceCurrency]);
  };

  return { tokenToLocalCurrency };
};

export default useGetConversionRate;
