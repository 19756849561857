import { getMultiRegionMap } from '@agrotoken/common-utils';
import { Button, Show } from '@chakra-ui/react';
import { parseDate } from '@common/utils';
import { useAuth } from '@src/context/authContext';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft } from 'react-icons/fi';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { createCpr, updateCpr } from '@services/Cprs';
import { getDocumentByUuid } from '@services/Documents';
import { TOKENIZATIONS_PATH, EMPTY_CPR } from '../../../common/const';
import {
  IGrainContract,
  EGrainContractStatusId,
  FutureTokenizationStep,
  CprForm,
  ECurrencyNameBr,
  BodyCpr,
  IDocument,
} from '../../../common/types';
import { getGrainContract } from '../../../services/GrainContracts';
import { Form } from '../../BR/ProductionExpectation/Form';
import { ActionFooter } from '../../Global/ActionFooter';
import { Container } from '../../Global/Container';
import { GrainContractDetails } from '../../Global/GrainContractDetails';
import { Loader } from '../../Global/Loader';
import { ServerError } from '../../Global/ServerError';
import { TimeLine } from '../../Global/TimeLine';
import { Title } from '../../Global/Title';
import { DocumentStatus } from '@common/types/document-status';

const getCprFromLocalStorage = (id: string | undefined) => {
  const cprForms = localStorage.getItem('cprForms');
  if (cprForms && id) {
    const cprFormFromLocalStorage = JSON.parse(cprForms)[id];
    if (cprFormFromLocalStorage) {
      return {
        cprForm: {
          ...cprFormFromLocalStorage,
          expirationDate: cprFormFromLocalStorage.expirationDate
            ? moment(cprFormFromLocalStorage.expirationDate).toDate()
            : null,
          plantingDate: cprFormFromLocalStorage.plantingDate
            ? moment(cprFormFromLocalStorage.plantingDate).toDate()
            : null,
        },
        hasLocalStorage: true,
      };
    }
  }
  return { cprForm: EMPTY_CPR, hasLocalStorage: false };
};

export const ProductionExpectation = () => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const { id } = useParams();

  const navigate = useNavigate();
  const [currencySelected, setCurrencySelected] = useState<string>(
    ECurrencyNameBr.BRL
  );
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [mutateError, setMutateError] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { cprForm: cprFromStorage, hasLocalStorage } =
    getCprFromLocalStorage(id);
  const [cprForm, setCprForm] = useState<CprForm>(cprFromStorage);

  const { data: currentDocument } = useQuery<IDocument | undefined>(
    ['document', cprForm.preloadedFile?.uuid],
    ({ queryKey }) => {
      if (cprForm.preloadedFile?.uuid) {
        return getDocumentByUuid({ queryKey });
      }
    }
  );

  const {
    isLoading,
    error: grainContractError,
    data: grainContract,
  } = useQuery<IGrainContract | undefined>(
    ['grainContract', id],
    getGrainContract
  );

  const { cpr } = grainContract ?? {};

  const isCprDocumentApproved =
    cpr?.document?.documentStatusId === DocumentStatus.APPROVED;

  const onChangeCprForm = (newForm: CprForm) => {
    setCprForm(newForm);
    if (id) {
      const localStorageCprForms = localStorage.getItem('cprForms');
      const cprForms = JSON.parse(localStorageCprForms ?? '{}');

      const newCprForms = {
        ...cprForms,
        [id]: newForm,
      };

      localStorage.setItem('cprForms', JSON.stringify(newCprForms));
    }
  };

  useEffect(() => {
    if (!grainContract || !grainContract.cpr) {
      return; // Exit early if grainContract or cpr is not available
    }

    const {
      internalNumber,
      plantedArea,
      grainVolume,
      price,
      type,
      plantingDate,
      harvestDate,
      expirationDate,
      documentId,
      document,
      currency,
    } = grainContract.cpr;

    const result: CprForm = {
      internalNumber: internalNumber.toString(),
      plantedArea: plantedArea.toString(),
      grainVolume: grainVolume.toString(),
      price: price.toString(),
      type,
      plantingDate: moment.unix(plantingDate).toDate(),
      harvestDate: moment.unix(harvestDate).toDate(),
      expirationDate: moment.unix(expirationDate).toDate(),
      documentId,
      preloadedFile: document
        ? {
            path: document.originalName,
            size: document.size,
            uuid: document.uuid,
          }
        : null,
    };

    setIsEditing(true);
    setCurrencySelected(currency?.toString());
    onChangeCprForm(result);
  }, [grainContract]);

  useEffect(() => {
    if (currentDocument) {
      const newValues = {
        ...cprForm,
        preloadedFile: {
          path: currentDocument.originalName,
          size: currentDocument.size,
          uuid: currentDocument.uuid,
        },
      };

      onChangeCprForm({
        ...newValues,
      });
    }
  }, [document]);

  const setCprFormDocumentId = (documentId: number | null) => {
    onChangeCprForm({
      ...cprForm,
      documentId,
    });
  };

  const { mutate: mutateCreateCpr, isLoading: isLoadingCreate } = useMutation(
    createCpr,
    {
      onSuccess: () => {
        setMutateError('');
        navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
      },
      onError: (err: Error) => {
        setMutateError(err.message);
      },
    }
  );

  const { mutate: mutateUpdateCpr, isLoading: isLoadingUpdate } = useMutation(
    updateCpr,
    {
      onSuccess: () => {
        setMutateError('');
        navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
      },
      onError: (err: Error) => {
        setMutateError(err.message);
      },
    }
  );

  const handlePreviousStep = () => {
    const previousStep = getMultiRegionMap({
      AR: '/tokenizations',
      BR: grainContract
        ? `${TOKENIZATIONS_PATH}/${grainContract.id}/new-tokenization`
        : `${TOKENIZATIONS_PATH}/new-tokenization`,
    });
    navigate(previousStep[nationality]);
  };

  const { grainContractStatusId, isPretokenized } = grainContract ?? {};

  const shouldAllowNextStep =
    grainContractStatusId === EGrainContractStatusId.PENDING ||
    (grainContractStatusId === EGrainContractStatusId.PENDING_APPROVAL &&
      isPretokenized);

  const isPretokenizedStatus =
    grainContract?.grainContractStatusId ===
    EGrainContractStatusId.PRE_TOKENIZED;

  const handleNextStep = async () => {
    if (!grainContract) return;
    if (!isValidForm) {
      setShouldShowError(true);
      return;
    }

    if (isPretokenizedStatus) {
      navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
    }

    if (!shouldAllowNextStep) {
      setMutateError(t('tokenization.proofOfLiquidity.mutateError'));
      return;
    }
    const {
      internalNumber,
      plantedArea,
      grainVolume,
      price,
      type,
      plantingDate,
      expirationDate,
      documentId,
    } = cprForm;
    if (!expirationDate || !plantingDate) return;

    const body: BodyCpr = {
      internalNumber,
      plantedArea: plantedArea,
      grainVolume: grainVolume,
      price: price,
      currency: currencySelected,
      type: type,
      plantingDate: parseDate(plantingDate),
      documentId: documentId,
      expirationDate: parseDate(expirationDate),
    };
    if (!isEditing) {
      body.grainContractId = grainContract.id;
      mutateCreateCpr({ grainContractId: grainContract.id, body });
    }
    if (isEditing && grainContract.cpr)
      mutateUpdateCpr({
        cprUuid: grainContract.cpr?.uuid,
        body,
      });
    const localStorageCprForms = localStorage.getItem('cprForms');
    const cprForms = JSON.parse(localStorageCprForms ?? '{}');
    if (cprForms && id) {
      delete cprForms[id];
      localStorage.setItem('cprForms', JSON.stringify(cprForms));
    }
  };

  if (isLoading) return <Loader />;

  if (grainContractError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(grainContractError as any)?.message}
      />
    );
  }

  return (
    <Container className="pb-20">
      <Button
        variant="linkPrimary"
        leftIcon={<FiArrowLeft />}
        onClick={handlePreviousStep}
        my="8"
      >
        {t('btnPrevLabel')}
      </Button>
      <div className="flex gap-x-12">
        <div className="xl:w-3/4">
          <Title
            title={t('tokenization.productionExpectation.title')}
            subtitle={t('tokenization.productionExpectation.text')}
          />
          <Form
            currencySelected={currencySelected}
            setCurrencySelected={setCurrencySelected}
            setShouldShowError={setShouldShowError}
            onChangeCprForm={onChangeCprForm}
            cprForm={cprForm}
            shouldShowError={shouldShowError}
            grainContract={grainContract}
            setIsValidForm={setIsValidForm}
            setCprFormDocumentId={setCprFormDocumentId}
            cprFormDocumentId={cprForm.documentId}
            isDisabled={isPretokenizedStatus || isCprDocumentApproved}
          />
        </div>
        <Show above="xl">
          <div className="w-1/4">
            <TimeLine
              currentStep={FutureTokenizationStep.PRODUCTION_EXPECTATION}
              grainContract={grainContract!}
            />
            <GrainContractDetails
              grainContract={grainContract!}
              currentStep={FutureTokenizationStep.PRODUCTION_EXPECTATION}
            />
          </div>
        </Show>
      </div>
      <ActionFooter
        onClick={handleNextStep}
        step={FutureTokenizationStep.PRODUCTION_EXPECTATION}
        error={mutateError}
        isLoading={isLoadingCreate}
      />
    </Container>
  );
};
