import moment from 'moment';

/**
 * Calculates the time remaining until a given target timestamp.
 * @param targetTimestamp - The target timestamp in seconds.
 * @returns An object with the remaining hours and minutes until the target timestamp.
 */
const calculateTimeRemaining = (
  targetTimestamp: number
): { hours: number; minutes: number } => {
  const now = moment().unix();
  const timeDiffInSeconds = targetTimestamp - now;

  if (timeDiffInSeconds <= 0) {
    return { hours: 0, minutes: 0 };
  }

  const secondsInHour = 60 * 60;
  const remainingHours = Math.floor(timeDiffInSeconds / secondsInHour);
  const remainingMinutes = Math.floor((timeDiffInSeconds % secondsInHour) / 60);

  return { hours: remainingHours, minutes: remainingMinutes };
};

export default calculateTimeRemaining;
