import { useMutation } from 'react-query';

import { authorizeLoanPayment } from '@services/loans';

const useAuthorizeLoanPayment = () => {
  const { mutate, isLoading, error } = useMutation(
    ['loans', 'authorize'],
    (id: string) => authorizeLoanPayment(id)
  );

  return { mutate, isLoading, error };
};

export default useAuthorizeLoanPayment;
