import FeatherIcon from 'feather-icons-react';
import { FC, HTMLAttributes } from 'react';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  color: 'gray' | 'primary' | 'error' | 'warning' | 'info';
  icon: string;
  iconSize?: number;
  hasConector?: boolean;
  text: string;
  className?: string;
  size: 'sm' | 'md' | 'lg';
  bigIcon?: boolean;
}

const colors: { [key: string]: string } = {
  gray: 'bg-gray-100 text-gray-200',
  primary: 'bg-primary-50 text-primary-700',
  error: 'bg-error-700 text-white',
};

const colorsConector: { [key: string]: string } = {
  gray: 'bg-gray-200',
  primary: 'bg-primary-600',
};

const paddings: { [key: string]: string } = {
  sm: 'p-1',
  md: 'p-3',
  lg: 'p-4',
};

export const RoundIcon: FC<Props> = ({
  icon,
  hasConector,
  color,
  text,
  className,
  size,
  bigIcon,
}) => {
  return (
    <div
      className={`${
        bigIcon
          ? 'flex flex-col items-center -ml-1'
          : 'flex flex-col items-center'
      }`}
    >
      <div className="flex">
        <div className="flex flex-col items-center mr-3">
          <div
            className={`${colors[color]} ${paddings[size]} ${
              bigIcon
                ? 'inline-block rounded-full'
                : 'inline-block rounded-full my-1'
            }`}
          >
            <FeatherIcon
              icon={icon}
              size={icon === 'circle' ? 8 : 24}
              fill={icon === 'circle' ? 'currentColor' : 'none'}
            />
          </div>
          {hasConector && (
            <div
              className={`${
                bigIcon ? 'bg-gray-200' : colorsConector[color]
              } h-[1.125rem] w-[0.125rem]`}
            />
          )}
        </div>
        <div className="pt-3">
          <p className={className}>{text}</p>
        </div>
      </div>
    </div>
  );
};
