import { TransferStatus, TRANSFER_STATUSES } from '../../types';

type BadgeColorScheme =
  | 'gray'
  | 'error'
  | 'warning'
  | 'success'
  | 'blue-light'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'rose'
  | 'orange'
  | 'blue-gray';

const getBadgePropsByStatus = (
  status: TransferStatus,
  isInBlockchain: boolean
): { label: string; colorScheme: BadgeColorScheme } => {
  if (isInBlockchain) {
    return { label: 'badges.processing', colorScheme: 'blue-light' };
  }

  switch (status) {
    case TRANSFER_STATUSES.CANCELLED:
      return { label: 'badges.cancelled', colorScheme: 'error' };
    case TRANSFER_STATUSES.ERROR:
      return { label: 'badges.error', colorScheme: 'error' };
    case TRANSFER_STATUSES.EXPIRED:
      return { label: 'badges.expired', colorScheme: 'error' };
    case TRANSFER_STATUSES.PENDING_APPROVAL:
      return { label: 'badges.pendingApproval', colorScheme: 'warning' };
    case TRANSFER_STATUSES.REJECTED:
      return { label: 'badges.rejected', colorScheme: 'error' };
    case TRANSFER_STATUSES.PROCESSING:
      return { label: 'badges.processing', colorScheme: 'blue-light' };
    case TRANSFER_STATUSES.SUCCESSFUL:
      return { label: 'badges.successful', colorScheme: 'success' };
    default:
      return { label: 'Unknown', colorScheme: 'gray' };
  }
};

export default getBadgePropsByStatus;
