import { Box, Show, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import TimeLine, {
  ITimeLineItem,
  TimeLineItemStatus,
} from '@comp/TimeLine/TimeLine';
import { VFC } from 'react';

interface Props {
  level: number;
}

const levelOne: ITimeLineItem = {
  text: 'onboardingLevelThree.progress.step1',
  status: TimeLineItemStatus.COMPLETED,
};
const levelTwoStatus: ITimeLineItem[] = [
  {
    text: 'onboardingLevelThree.progress.step2',
    status: TimeLineItemStatus.ACTIVE,
  },
  {
    text: 'onboardingLevelThree.progress.step3',
    status: TimeLineItemStatus.PENDING,
  },
];
const levelThreeStatus: ITimeLineItem[] = [
  {
    text: 'onboardingLevelThree.progress.step2',
    status: TimeLineItemStatus.COMPLETED,
  },
  {
    text: 'onboardingLevelThree.progress.step3',
    status: TimeLineItemStatus.ACTIVE,
  },
];

const ProgressIndicator: VFC<Props> = ({ level }) => {
  const { t } = useTranslation();

  const items: ITimeLineItem[] = [
    levelOne,
    ...(level === 2 ? levelTwoStatus : levelThreeStatus),
  ].map(({ text, ...rest }) => ({ text: t(text), ...rest }));

  return (
    <Box>
      <Show above="md">
        <Text size="lg" variant="medium">
          {t('onboardingLevelThree.progress.title')}
        </Text>
      </Show>
      <TimeLine steps={items} />
    </Box>
  );
};

export default ProgressIndicator;
