import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { IPaymentRequest } from '../../../../common/types';
import { BreakDown, ShareLink } from '../../Fragments';

interface Props {
  paymentRequest: IPaymentRequest;
  showShareLink: boolean;
}

export const Details: FC<Props> = ({
  paymentRequest,
  showShareLink = true,
}) => {
  const params = useParams();
  const paymentUrl = `${process.env.REACT_APP_BASE_URL}/payment-requests/${params.uuid}`;

  return (
    <>
      {showShareLink && <ShareLink link={paymentUrl} />}
      <BreakDown paymentRequest={paymentRequest} />
    </>
  );
};
