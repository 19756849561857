import { Flex } from '@chakra-ui/react';
import { ECurrencyName } from '@common/types';
import { TokenSummaryItem } from '../LockedTokensSummary/LockedTokenSummaryItem';

interface Props {
  token: ECurrencyName;
  agrotokenFee: string;
  agrotokenFeePercentage: string;
  agrotokenFeeInFiat: string;
  returnOfTokens: string;
}
export const FiatPaymentDetail = ({
  token,
  agrotokenFee,
  agrotokenFeePercentage,
  agrotokenFeeInFiat,
  returnOfTokens,
}: Props) => (
  <Flex flexDir="column">
    <TokenSummaryItem
      label="Devolución de tokens"
      token={token}
      value={returnOfTokens}
    />
    <TokenSummaryItem
      label={`Comisión Agrotoken (${agrotokenFeePercentage}%) (${agrotokenFeeInFiat})`}
      token={token}
      value={agrotokenFee}
    />
  </Flex>
);
