import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from '@common/const';
import {
  EBackOfficeValidationStatus,
  EBusinessStructureTypes,
  EDocumentTypes,
  EFullOnboardingLevel,
  EIdentificationTaxTypes,
  IBusiness,
  IDocumentPreview,
  IDocumentPreviewForBusinessUser,
  IUserKYCData,
  OnboardingLevel2RequestDto,
  UpdateBusinessUserDto,
} from '@common/types';
import { updateBusinessUser } from '@services/BusinessUsers';
import { updateBusiness } from '@services/Businesses';
import {
  saveOnboardingLevel2,
  updateOnboardingLevel2,
} from '@services/FullOnboarding';
import { getUser, updateUser } from '@services/Users';
import { useAccess } from '@src/context/accessContext';
import { useUser } from '@src/context/userContext';
import { TYPE_OF_LEGAL_POWER_DOC } from './DocOfRepresentative/consts';
import { PEP_RADIO } from './KYC/consts';
import { getUserRequestedChangesFromBackOffice } from './common/utils';
import { IncompleteFieldError } from './types';
import {
  getIncompleteFieldsForGeneralDataLevel2,
  getIncompleteFieldsForRepDocsDataLevel2,
} from './validations';

const useFullOnboardingData = () => {
  // Data Hooks
  const navigate = useNavigate();
  const {
    setSelectedBusinessUser,
    selectedBusinessUserWasInvited,
    setSelectedBusinessUserId,
    selectedBusinessUserWasAdminInvited,
    currentUser,
    selectedBusiness,
  } = useUser();
  const currentUserId = currentUser?.id;

  const { data: userStoredData } = useQuery<IUserKYCData | undefined>(
    ['user', currentUserId],
    getUser
  );

  const {
    canOnlyCreatePaymentLink,
    canOnlyViewWallet,
    isRepresentant,
    businessHasRepresentant,
  } = useAccess();

  // Form related
  const formMethods = useForm();
  const { watch, handleSubmit, reset } = formMethods;

  // States
  const [defaultUserDocuments, setDefaultUserDocuments] = useState<
    IDocumentPreview[]
  >([]);

  const [defaultBusinessUserDocuments, setDefaultBusinessUserDocuments] =
    useState<IDocumentPreviewForBusinessUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [frontPersonDocumentId, setFrontPersonDocumentId] = useState<
    number | null
  >(null);
  const [backPersonDocumentId, setBackPersonDocumentId] = useState<
    number | null
  >(null);
  const [legalPowerDocumentIds, setLegalPowerDocumentIds] = useState<number[]>(
    []
  );
  const [statuteDocumentId, setStatuteDocumentId] = useState<number | null>(
    null
  );
  const [authoritiesDocumentId, setAuthoritiesDocumentId] = useState<
    number | null
  >(null);

  const [nationalityId, setNationalityId] = useState<number | null>(null);
  const [missingFileError, setMissingFileError] = useState<
    IncompleteFieldError['errors']
  >({});

  // Modals
  const {
    isOpen: isModalOpen,
    onOpen: handleOpenModal,
    onClose: handleCloseModal,
  } = useDisclosure();

  // Effects
  const pep = Number(watch(PEP_RADIO));
  const typeOfLegalPower = Number(watch(TYPE_OF_LEGAL_POWER_DOC));

  // Variables init
  const selectedBusinessUser = userStoredData?.businessUsers?.find(
    // TODO: This should come from the hook, review why we're not using that one.
    ({ businessId }) => businessId === selectedBusiness?.id
  );

  const personRequiredDocuments = [frontPersonDocumentId, backPersonDocumentId];
  const businessRequiredDocuments =
    typeOfLegalPower === EDocumentTypes.LEGAL_POWER
      ? legalPowerDocumentIds
      : [statuteDocumentId, authoritiesDocumentId];

  const requestedChanges =
    userStoredData &&
    selectedBusinessUser &&
    selectedBusiness &&
    getUserRequestedChangesFromBackOffice(
      userStoredData,
      selectedBusinessUser,
      selectedBusiness
    );

  // TODO: Review this, since might cause trouble if any attribute in requestedChanges can't
  // be undefined.
  const {
    hasChangeRequests,
    userChangeRequestMessages,
    businessAddressHasChangesRequest,
  } = requestedChanges || {};

  const isCompany =
    EBusinessStructureTypes.COMPANY ===
    selectedBusiness?.businessStructureTypeId;

  const isPerson =
    EBusinessStructureTypes.PERSON ===
    selectedBusiness?.businessStructureTypeId;

  const selectedBusinessUserIsCreator =
    !selectedBusinessUserWasInvited || selectedBusinessUserWasAdminInvited;

  const representantUserNeedsToChangeBusinessAddress =
    isRepresentant && businessAddressHasChangesRequest;

  const shouldShowRepresentativeDocsSection =
    !(
      isCompany &&
      !selectedBusinessUserIsCreator &&
      (canOnlyCreatePaymentLink || canOnlyViewWallet)
    ) &&
    !(
      isPerson &&
      (selectedBusinessUserIsCreator ||
        canOnlyViewWallet ||
        canOnlyCreatePaymentLink)
    ) &&
    !representantUserNeedsToChangeBusinessAddress;

  const selectedBusinessAddressId =
    selectedBusinessUser?.business?.businessAddressId;

  const selectedBusinessUserId = selectedBusinessUser?.id;
  const businessUserDocuments =
    selectedBusinessUser?.businessUserDocuments || [];

  const businessUserLegalPowerDocument =
    businessUserDocuments?.filter(
      ({ document }) => document.documentType.id === EDocumentTypes.LEGAL_POWER
    ) || [];

  const businessUserHasLegalPower =
    businessUserLegalPowerDocument.length > 0 ||
    legalPowerDocumentIds?.length > 0;

  const shouldShowGeneralData =
    !selectedBusinessUser?.isOnboardingValidated &&
    !representantUserNeedsToChangeBusinessAddress;

  const shouldShowCompanyData =
    isCompany &&
    (!businessHasRepresentant ||
      (isRepresentant && representantUserNeedsToChangeBusinessAddress));

  // Form actions
  const getIncompleteFields = () => {
    let fields: IncompleteFieldError = { errors: {} };
    if (shouldShowGeneralData) {
      // Check basic stuff like DNI files, CUIT, etc
      fields = {
        errors: {
          ...fields.errors,
          ...getIncompleteFieldsForGeneralDataLevel2({
            pep,
            frontPersonDocumentId,
            backPersonDocumentId,
          }).errors,
        },
      };
    }

    if (shouldShowRepresentativeDocsSection) {
      // Check Rep docs data
      fields = {
        errors: {
          ...fields.errors,
          ...getIncompleteFieldsForRepDocsDataLevel2({
            [TYPE_OF_LEGAL_POWER_DOC]: typeOfLegalPower,
            legalPowerDocumentIds,
            statuteDocumentId,
            authoritiesDocumentId,
          }).errors,
        },
      };
    }
    setMissingFileError(fields.errors);
    return fields;
  };

  const onSubmit = async () => {
    const fields = getIncompleteFields();
    const hasErrors = Boolean(Object.keys(fields.errors).length);
    if (!hasErrors) {
      await saveChanges({ isSaveButton: true });
      handleOpenModal();
    }
  };

  const saveChanges = async (options: { isSaveButton?: boolean } = {}) => {
    // TODO: Unused for now, will be used once we allow 'draft' mode.
    const { isSaveButton } = options;

    const businessAddress = watch('businessAddress');
    const identificationTaxValue = watch('identificationTaxValue');
    const { cityId, geographicalDivisionId, fiscalAddresses, postalCode } =
      businessAddress ?? {};

    // TODO: Continue refactor! =)
    // if (!currentUserId || !selectedBusinessUserId) {
    //   // TODO: Use a Toast. Implement generic one?
    //   throw new Error('No current user or business user');
    // }

    // const payload: OnboardingLevel2RequestDto = {
    //   businessUserId: selectedBusinessUserId,
    //   cuit: identificationTaxValue,
    //   pep,
    //   idFrontFileId: frontPersonDocumentId,
    //   idBackFileId: backPersonDocumentId,
    //   countryId: ARGENTINA_COUNTRY_ID,
    //   geographicalDivisionId,
    //   physicalAddress: fiscalAddresses,
    //   postalCode,
    //   legalPowerFilesIds: legalPowerDocumentIds,
    //   statuteFilesIds: statuteDocumentId ? [statuteDocumentId] : [],
    //   authoritiesDesignationFilesIds: authoritiesDocumentId
    //     ? [authoritiesDocumentId]
    //     : [],
    // };

    // setIsLoading(true);
    // await createOnboardingLevel2(payload)
    //   .then((d) => {
    //     const { businessUser } = d ?? {};

    //     if (businessUser) {
    //       setSelectedBusinessUser(businessUser);
    //       setSelectedBusinessUserId(businessUser.id);
    //     }
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });

    // TODO: Delete from here
    setIsLoading(true);
    await updateUser({
      id: currentUserId,
      body: {
        identificationTaxValue: identificationTaxValue,
        identificationTaxTypeId: EIdentificationTaxTypes.CUIT,
        pep,
        ...(personRequiredDocuments.length
          ? {
              documentIds: personRequiredDocuments,
            }
          : {}),
        nationalityId: nationalityId,
      },
    });
    if (!selectedBusinessUserId) {
      console.error(
        'No selected business-user-id when trying to update business-user'
      );
    }

    if (selectedBusinessUserId) {
      const body: UpdateBusinessUserDto = {
        isPendingOpsReview: true,
        isPendingUserReview: false,
      };

      if (businessRequiredDocuments.length) {
        body.documentIds = businessRequiredDocuments as number[];
      }

      if (
        isSaveButton &&
        selectedBusinessUser.fullOnboardingLevelId ===
          EFullOnboardingLevel.LEVEL1
      ) {
        body.fullOnboardingLevelId = EFullOnboardingLevel.LEVEL2;
      }

      const isBusinessUserUpdated = await updateBusinessUser({
        id: Number(selectedBusinessUserId),
        body,
      });
      if (isBusinessUserUpdated) {
        setSelectedBusinessUser({ ...selectedBusinessUser, ...body });
        setSelectedBusinessUserId(Number(selectedBusinessUserId));
      }

      if (
        (!selectedBusinessUserWasInvited ||
          selectedBusinessUserWasAdminInvited) &&
        businessAddress &&
        selectedBusiness?.id
      ) {
        await updateBusiness({
          id: selectedBusiness.id,
          body: {
            businessAddress,
            isPendingOpsReview: true,
            isPendingUserReview: false,
          },
        });
      }
      // Save data in onboarding_level2_requests table
      const { cityId, geographicalDivisionId, fiscalAddresses, postalCode } =
        businessAddress ?? {};
      const requestData: OnboardingLevel2RequestDto = {
        ...(identificationTaxValue && { cuit: identificationTaxValue }),
        ...(pep && { pep }),
        ...(frontPersonDocumentId && { idFrontFileId: frontPersonDocumentId }),
        ...(backPersonDocumentId && { idBackFileId: backPersonDocumentId }),
        ...(cityId && { cityId }),
        ...(geographicalDivisionId && { geographicalDivisionId }),
        ...(fiscalAddresses && { physicalAddress: fiscalAddresses }),
        ...(postalCode && { postalCode }),
        ...(typeOfLegalPower === EDocumentTypes.LEGAL_POWER
          ? legalPowerDocumentIds && {
              legalPowerFilesIds: legalPowerDocumentIds,
              statuteFilesIds: null,
              authoritiesDesignationFilesIds: null,
            }
          : statuteDocumentId &&
            authoritiesDocumentId && {
              statuteFilesIds: [statuteDocumentId],
              authoritiesDesignationFilesIds: [authoritiesDocumentId],
              legalPowerFilesIds: null,
            }),
        tokenizationConditionsAcceptedAt: moment().unix(),
        nationalityId: nationalityId,
      };

      await saveOnboardingLevel2(requestData);

      // We are doing this to update all validationStatus to PENDING.
      if (hasChangeRequests)
        await updateOnboardingLevel2({
          businessUserId: selectedBusinessUserId,
        });
    }
    setIsLoading(false);
    // TODO: Delete to here
  };

  const handleCompleteLater = async () => {
    // TODO: We need to model the 'draft' mode, that lets the user save a preview but without confirming that the
    // onboarding is ready to be reviewed.
    navigate(HOME_PATH);
  };

  return {
    formMethods,
    formActions: {
      handleSubmit,
      onSubmit,
      reset,
      handleCompleteLater,
    },
    flags: {
      isCompany,
      isLoading,
      businessUserHasLegalPower,
      shouldShowGeneralData: !!shouldShowGeneralData,
      shouldShowCompanyData: !!shouldShowCompanyData,
      shouldShowRepresentativeDocsSection:
        !!shouldShowRepresentativeDocsSection,
    },
    userData: {
      currentUserId,
      userStoredData,
      frontPersonDocumentId,
      setFrontPersonDocumentId,
      backPersonDocumentId,
      setBackPersonDocumentId,
      defaultUserDocuments,
      setDefaultUserDocuments,
      nationalityId,
      setNationalityId,
    },
    businessUserData: {
      selectedBusinessUser,
      statuteDocumentId,
      setStatuteDocumentId,
      authoritiesDocumentId,
      setAuthoritiesDocumentId,
      legalPowerDocumentIds,
      setLegalPowerDocumentIds,
      missingFileError,
      defaultBusinessUserDocuments,
      setDefaultBusinessUserDocuments,
      businessUserDocuments,
    },
    businessData: { selectedBusinessAddressId },
    modalUtils: { isModalOpen, handleCloseModal },
    changeRequestData: { hasChangeRequests, userChangeRequestMessages },
  };
};

export default useFullOnboardingData;
