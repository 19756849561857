import { REGEX } from '@agrotoken/common-utils';
import { Select } from '@agrotoken-tech/ui';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import get from 'lodash.get';
import { useEffect, useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import {
  EBusinessStructureTypes,
  EDocumentTypes,
  IBusiness,
  IDocument,
} from '@common/types';
import AddressFields from '@comp/BR/FullOnboarding/Level2/AddressFields';
import FileUploader from '@comp/FullOnboarding/FileUploader';
import PEPRadio from '@comp/FullOnboarding/PEPRadio';
import ChangeRequestedAlert from '@comp/FullOnboarding/common/ChangeRequestedAlert';
import useNationalities from '@comp/FullOnboarding/useNationalities';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import { ServerError } from '@comp/Global/ServerError';
import { Loader } from '@comp/Global/Loader';

interface Props {
  defaultValues: {
    frontPersonDocument?: IDocument;
    backPersonDocument?: IDocument;
  };
  representativeSection?: React.ReactNode;
  socialContractSection?: React.ReactNode;
  userAddressSection?: React.ReactNode;
  businessAddressSection?: React.ReactNode;
  shouldShowCPFField?: boolean;
  changeRequestMessages?: string[];
  hasRepresentant?: boolean;
  selectedBusiness?: IBusiness;
}

const LevelTwoCommon: VFC<Props> = ({
  defaultValues,
  representativeSection = null,
  socialContractSection = null,
  userAddressSection = null,
  shouldShowCPFField = true,
  changeRequestMessages = [],
  hasRepresentant = false,
  selectedBusiness,
}) => {
  let { selectedBusinessUserWasInvited } = useUser();
  const { currentUser, selectedBusinessUserWasAdminInvited } = useUser();
  selectedBusinessUserWasInvited =
    selectedBusinessUserWasInvited && !selectedBusinessUserWasAdminInvited;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.lvl_2',
  });

  const { setValue } = useFormContext();
  const { isBrazilRegion } = useAuth();
  const { nationalities, isLoading, error } = useNationalities();

  const [frontPersonDocumentId, setFrontPersonDocumentId] = useState<
    number | null
  >(defaultValues.frontPersonDocument?.id ?? null);
  const [backPersonDocumentId, setBackPersonDocumentId] = useState<
    number | null
  >(defaultValues.backPersonDocument?.id ?? null);

  useEffect(() => {
    setValue('personIdDocumentFront', frontPersonDocumentId);
  }, [frontPersonDocumentId, setValue]);

  useEffect(() => {
    setValue('personIdDocumentBack', backPersonDocumentId);
  }, [backPersonDocumentId, setValue]);

  const {
    register,
    formState: { errors },
    control,
    formState,
  } = useFormContext();

  const maxBirthDate = new Date().toISOString().split('T')[0];

  const requiredFieldError = t('form.errors.required');
  const motherNameError = get(errors, 'motherName', false);

  if (isLoading)
    return (
      <Flex direction="column" w="full">
        <Loader />
      </Flex>
    );
  if (error)
    return (
      <ServerError
        title={t('serverError.title')}
        text={(error as any)?.message ?? t('serverError.text')}
      />
    );
  return (
    <Flex direction="column" w="full">
      <Heading size="sm" variant="medium" py="1.5">
        {t('title')}
      </Heading>
      <Text size="sm" variant="regular">
        {t('subtitle')}
      </Text>
      {changeRequestMessages && changeRequestMessages.length > 0 && (
        <ChangeRequestedAlert messages={changeRequestMessages} />
      )}
      <SimpleGrid columns={[1, 1, 2]} gap="6" mt="8">
        {shouldShowCPFField && (
          <FormControl mr="4" isInvalid={Boolean(errors.cpf)}>
            <FormLabel htmlFor="cpf">{t('form.CPF.label')}</FormLabel>
            <Input
              id="cpf"
              h="12"
              {...register('cpf', {
                required:
                  !selectedBusinessUserWasInvited && !hasRepresentant
                    ? t('form.errors.required')
                    : false,
                maxLength: {
                  value: 11,
                  message: t('form.errors.cpf'),
                },
                minLength: {
                  value: 11,
                  message: t('form.errors.cpf'),
                },
                pattern: {
                  value: new RegExp(REGEX.NUMERIC),
                  message: t('form.errors.onlyNumbers'),
                },
              })}
              isDisabled={hasRepresentant}
            />
            <FormErrorMessage>{errors.cpf?.message}</FormErrorMessage>
          </FormControl>
        )}
        <FormControl isInvalid={Boolean(errors.birthDate)}>
          <FormLabel htmlFor="birthDate">{t('form.birthDate.label')}</FormLabel>
          <Input
            id="birthDate"
            h="12"
            type="date"
            max={maxBirthDate}
            {...register('birthDate', { required: !hasRepresentant })}
            isDisabled={hasRepresentant}
          />
          <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.motherName)}>
          <FormLabel htmlFor="motherName">
            {t('form.motherName.label')}
          </FormLabel>
          <Input
            id="motherName"
            h="12"
            {...register('motherName', {
              required: !hasRepresentant ? t('form.errors.required') : false,
              maxLength: 200,
              pattern: {
                value: new RegExp(REGEX.TWO_WORDS),
                message: t('form.errors.motherName'),
              },
            })}
            defaultValue={currentUser?.userPayfacDetails?.motherName}
            isDisabled={hasRepresentant}
          />
          <FormErrorMessage>
            {motherNameError && motherNameError.message}
          </FormErrorMessage>
        </FormControl>
        {nationalities && (
          <FormControl isInvalid={Boolean(errors.nationality)}>
            <FormLabel htmlFor="nationality">
              {t('form.nationality.label')}
            </FormLabel>
            <Select
              id="nationality"
              register={register}
              registerOptions={{ required: t('form.errors.required') }}
              options={nationalities?.map((nationality) => ({
                value: nationality.id.toString(),
                label: t(`countries.${nationality.isoCode2}`),
              }))}
              isSearchable={true}
              isDisabled={hasRepresentant}
              control={control}
              formState={formState}
              leftIcon={FiSearch}
              label={''}
              placeholder={t('form.nationality.placeholder')}
              selectName={'nationality'}
              isClearable={true}
              //TODO: When the rest of the inputs are integrated into the component library it should not be necessary to pass the height as a prop
              height="48px"
            />
          </FormControl>
        )}
      </SimpleGrid>
      <Flex flexDirection="column" gap="6" mt="6">
        <FileUploader
          documentId={frontPersonDocumentId}
          setDocumentId={setFrontPersonDocumentId}
          description={t('form.personIdDocument.front')}
          documentTypeId={EDocumentTypes.DNI_FRONT}
          isValid={Boolean(!errors.personIdDocumentFront)}
          allowImages={isBrazilRegion}
          defaultFile={defaultValues?.frontPersonDocument}
          registrationProps={register('personIdDocumentFront', {
            validate: (value) => Boolean(value || frontPersonDocumentId),
          })}
          disabled={hasRepresentant}
        />
        <FileUploader
          documentId={backPersonDocumentId}
          setDocumentId={setBackPersonDocumentId}
          description={t('form.personIdDocument.back')}
          documentTypeId={EDocumentTypes.DNI_BACK}
          isValid={Boolean(
            !errors.personIdDocumentBack || backPersonDocumentId
          )}
          allowImages={isBrazilRegion}
          defaultFile={defaultValues?.backPersonDocument}
          registrationProps={register('personIdDocumentBack', {
            validate: (value) => Boolean(value || backPersonDocumentId),
          })}
          disabled={hasRepresentant}
        />

        <PEPRadio title={t('form.pep.label')} disabled={hasRepresentant} />

        {userAddressSection}

        {representativeSection}

        {!selectedBusinessUserWasInvited && (
          <Flex flexDir="column">
            <Text size="xl" variant="medium" mb="1.5">
              {selectedBusiness?.businessStructureTypeId ===
              EBusinessStructureTypes.COMPANY
                ? t('form.address.businessAddressTitle')
                : t('form.address.userAddressTitle')}
            </Text>
            {selectedBusiness?.businessAddress ? (
              <AddressFields
                defaultCityId={
                  selectedBusiness?.businessAddress?.cityId ?? undefined
                }
                defaultGeographicalDivisionId={
                  selectedBusiness?.businessAddress?.geographicalDivisionId ??
                  undefined
                }
                defaultFiscalAddress={
                  selectedBusiness?.businessAddress?.fiscalAddresses ??
                  undefined
                }
                defaultStreetNumber={
                  selectedBusiness?.businessAddress?.streetNumber ?? undefined
                }
                defaultNeighborhood={
                  selectedBusiness?.businessAddress?.neighborhood ?? undefined
                }
                defaultComplement={
                  selectedBusiness?.businessAddress?.complement ?? undefined
                }
                defaultPostalCode={
                  selectedBusiness?.businessAddress?.postalCode ?? undefined
                }
              />
            ) : (
              <AddressFields />
            )}
            {socialContractSection}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default LevelTwoCommon;
