import { useTranslation } from 'react-i18next';

import { Container } from '@comp/Global/Container';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { Spinner } from '@comp/Global/Spinner';
import { useTokenizationMovements } from '@comp/Tokenization/Summary/useTokenizationMovements';
import { useNavigateSelectOrg } from '@hooks/useNavigateSelectOrg';
import { useUser } from '@src/context/userContext';

import FutureGrainMovements from './FutureGrainMovements';

interface FutureMovementsProps {
  tokenizationUuid: string;
}

const FutureMovements = ({ tokenizationUuid }: FutureMovementsProps) => {
  const { t } = useTranslation();

  const { errorUser } = useUser();

  useNavigateSelectOrg();

  const {
    data: grainContractMovements,
    error: grainContractMovementsError,
    isLoading: isLoadingGrainContractMovements,
  } = useTokenizationMovements(tokenizationUuid);

  const filteredMovements = grainContractMovements?.filter(
    (grainContractMovement) => +grainContractMovement.amount !== 0
  );

  if (isLoadingGrainContractMovements) return <Loader />;

  if (grainContractMovementsError) {
    return (
      <ServerError title={t('serverError.title')} text={errorUser?.message} />
    );
  }

  return (
    <Container>
      <div className="border border-gray-200 shadow-md overflow-x-auto bg-white">
        <div className="py-5 px-6 bg-white border-b border-gray-200 flex gap-4 flex-col md:flex-row md:justify-between md:items-center">
          <div>
            <p className="text-lg text-gray-900 font-medium mb-1">
              {t('tokenization.summary.futureMovementsTitle')}
            </p>
            <p className="text-sm text-gray-500 font-normal">
              {t('tokenization.summary.futureMovementsSubTitle')}
            </p>
          </div>
        </div>
        {isLoadingGrainContractMovements && (
          <Spinner className="m-auto py-10" />
        )}
        {grainContractMovementsError && (
          <p className="text-center py-10">
            {t('wallet.movementsTable.loadingError')}
          </p>
        )}
        {filteredMovements && (
          <FutureGrainMovements filteredMovements={filteredMovements} />
        )}
      </div>
    </Container>
  );
};
export default FutureMovements;
