import { ENationalities } from '@agrotoken/common-utils';
import { Button } from '@agrotoken-tech/ui';
import {
  Checkbox,
  FormControl,
  Link,
  Stack,
  FormErrorMessage,
  Heading,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { languages } from '@src/translations/i18n';
import {
  AuthRegisterDto,
  EBusinessStructureTypes,
  ERegisterExceptionCodes,
  ERegisterExceptionMessages,
} from '../../../common/types';
import { useAuth } from '../../../context/authContext';
import { ICognitoError } from '../types';
import CommonFields from './CommonFields';
import CompanyFields from './CompanyFields';
import CountrySelector from './CountrySelector';
import PersonFields from './PersonFields';
import ProducerTypeSelector from './TypeOfProducer';

interface Props {
  createNewBusinessFunction?: any;
}
export interface FormValues extends AuthRegisterDto {
  'password-confirm': string;
  'consent-terms': boolean;
}

const GeneralInfoForm: FC<Props> = ({ createNewBusinessFunction }) => {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [errorsFromCognito, setErrorsFromCognito] = useState<ICognitoError>({});

  /* if no region provided in the queryString (?region=<REGION-CODE>) the value will be defined
     relying on the language to define the `country` field default value
   */
  const isBrazilian = i18n.language === languages[ENationalities.BR];

  useEffect(() => {
    if (!auth) return;
    auth
      .isAuth()
      .then(() => setIsAuthenticated(true))
      .catch((error: Error) => {
        setIsAuthenticated(false);
        console.error(error);
      });
  }, [auth]);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      country: isBrazilian ? ENationalities.BR : ENationalities.AR,
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { errors },
    setValue,
  } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    // omit consent and password confirmation from cognito payload
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      'consent-terms': _consent,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      'password-confirm': _pwConfirm,
      ...formPayload
    } = data;
    const payload = {
      email: formPayload.email,
      firstName: formPayload.givenName,
      lastName: formPayload.lastName,
      password: formPayload.password,
      phone: formPayload.phoneTemp,
      businessName: formPayload.businessName,
      businessFiscalId: formPayload.businessId,
      businessPersonTypeId: Number(formPayload.business_person_type_id),
      country: formPayload.country as ENationalities,
    };

    if (!isAuthenticated) {
      try {
        setErrorsFromCognito({});
        setIsLoading(true);

        await auth.signUpInCoreApi(payload);
        setIsLoading(false);
        const encodedEmail = btoa(payload.email);

        navigate(`/email-confirmation/${encodedEmail}`, {
          state: {
            password: payload.password,
          },
        });
      } catch (err: any) {
        setIsLoading(false);
        const errCode = err?.response?.data?.message;

        if (errCode === ERegisterExceptionCodes.InvalidPasswordException) {
          setErrorsFromCognito((prev) => ({
            ...prev,
            password: t(
              `signUp.form.errorMessages.${ERegisterExceptionMessages.InvalidPasswordException}`
            ),
          }));
        } else if (
          errCode === ERegisterExceptionCodes.UsernameExistsException
        ) {
          setErrorsFromCognito((prev) => ({
            ...prev,
            email: t(
              `signUp.form.errorMessages.${ERegisterExceptionMessages.UsernameExistsException}`
            ),
          }));
        }
        setErrorsFromCognito((prev) => ({
          ...prev,
          default: t(
            `signUp.form.errorMessages.${ERegisterExceptionMessages.DefaultError}`
          ),
        }));
      }
    } else {
      setIsLoading(true);
      const {
        businessName,
        givenName,
        lastName,
        businessId: fiscalId,
        business_person_type_id,
      } = watch();
      const businessStructureTypeId = Number(business_person_type_id);
      const name =
        businessStructureTypeId === EBusinessStructureTypes.COMPANY
          ? businessName
          : givenName + ' ' + lastName;
      createNewBusinessFunction({
        name,
        fiscalId,
        businessStructureTypeId,
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const { country } = watch();
  const isBusiness =
    watch('business_person_type_id') &&
    Number(watch('business_person_type_id')) ===
      EBusinessStructureTypes.COMPANY;
  const isChecked = Boolean;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
    xl: false,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isBusiness ? (
          <Heading
            mt={isMobile ? '2' : '6'}
            mb="6"
            as={isMobile ? 'h6' : undefined}
            size={isMobile ? 'xs' : undefined}
          >
            {t('softOnboarding.signUpScreen.companyTitle')}
          </Heading>
        ) : (
          <Heading
            mt={isMobile ? '2' : '6'}
            mb="6"
            as={isMobile ? 'h6' : undefined}
            size={isMobile ? 'xs' : undefined}
          >
            {t('softOnboarding.signUpScreen.individualTitle')}
          </Heading>
        )}

        <Stack spacing={6}>
          <Flex
            gap={'6'}
            direction={['column', 'column', 'column', 'column', 'row']}
          >
            <CountrySelector />
            <ProducerTypeSelector setValue={setValue} control={control} />
          </Flex>

          {isBusiness ? (
            <CompanyFields
              register={register}
              watch={watch}
              errors={errors}
              setError={setError}
            />
          ) : (
            <PersonFields register={register} errors={errors} watch={watch} />
          )}
          <CommonFields errorsFromCognito={errorsFromCognito} />
        </Stack>

        <Stack spacing={8} mt="8">
          <FormControl isInvalid={Boolean(errors['consent-terms'])}>
            <Checkbox
              colorScheme="primary"
              {...register('consent-terms', {
                required: true,
                validate: isChecked,
              })}
            >
              {t('softOnboarding.signUpScreen.form.accept')}
              <Link
                fontWeight="medium"
                color="primary.800"
                textDecor={'underline'}
                href={t(
                  'softOnboarding.signUpScreen.form.termsAndConditionsLink'
                )}
                target="_blank"
              >
                {t('softOnboarding.signUpScreen.form.termsAndConditions')}
              </Link>
            </Checkbox>
            {errors['consent-terms'] && (
              <FormErrorMessage>
                {t('softOnboarding.signUpScreen.form.termsAndConditionsError')}
              </FormErrorMessage>
            )}
          </FormControl>
          {errorsFromCognito.default && (
            <FormControl isInvalid={Boolean(errorsFromCognito.default)}>
              <FormErrorMessage>{errorsFromCognito.default}</FormErrorMessage>
            </FormControl>
          )}
          <Button
            variant="primary"
            w={isMobile ? '100%' : 'fit-content'}
            type="submit"
            size={isMobile ? 'lg' : 'md'}
            isLoading={isLoading}
          >
            {t('softOnboarding.signUpScreen.form.createAccount')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default GeneralInfoForm;
