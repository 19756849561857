import { Button } from '@agrotoken-tech/ui';
import { SimpleGrid } from '@chakra-ui/react';
import {
  EBenefitsListTranslation,
  EPermission,
  IMerchantRequest,
} from '@src/common/types';
import { useAccess } from '@src/context/accessContext';
import { useUser } from '@src/context/userContext';
import React, { FC, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@comp/Global/Icon';
import { Modal } from '@comp/Global/Modal';
import { createMerchantRequest } from '@services/MerchantRequests';
import { TColor } from '../Global/types';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  text?: string;
  iconName?: string;
  iconColor?: TColor;
  center?: boolean;
  labelBtnClose?: string;
  labelBtnConfirm?: string;
  onClickClose?: () => void;
  isLoading?: boolean;
  checkboxLabel?: string;
  checkboxValue?: string;
  checkboxOnChange?: () => void;
  setIsOpenRequestMerchantModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetch?: () => void;
}
export const BeMerchantRequestModal: FC<Props> = ({
  isOpen,
  title,
  subtitle,
  text,
  labelBtnClose = 'Cancelar',
  labelBtnConfirm = 'Confirmar',
  onClickClose,
  isLoading,
  checkboxLabel = '',
  checkboxValue,
  checkboxOnChange,
  setIsOpenRequestMerchantModal,
  refetch,
}) => {
  const [mutateError, setMutateError] = useState<string>('');
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [isOpenThankyouModal, setIsOpenThankyouModal] =
    useState<boolean>(false);
  const { currentUser, selectedBusinessId, selectedBusinessUser } = useUser();
  const { isRepresentant } = useAccess();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const benefitsList: EBenefitsListTranslation[] = [
    t(`user.requestMerchantModal.${EBenefitsListTranslation.LIQUIDITY}`),
    t(`user.requestMerchantModal.${EBenefitsListTranslation.SIMPLICITY}`),
    t(`user.requestMerchantModal.${EBenefitsListTranslation.AVAILABILITY}`),
    t(
      `user.requestMerchantModal.${EBenefitsListTranslation.LESS_INTERMIDIARIES}`
    ),
  ];

  const {
    mutate: mutateCreateMerchantRequest,
    isLoading: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation(createMerchantRequest, {
    onSuccess: (data: IMerchantRequest | undefined) => {
      if (!data) return;
      setMutateError('');
      setIsOpenThankyouModal(true);
    },
    onError: (err: Error) => {
      setMutateError(err.message);
    },
  });

  const handleRequestBeMerchant = async () => {
    setShouldShowError(true);
    mutateCreateMerchantRequest({
      createdByBusinessUserId: currentUser?.businessUsers.find(
        (bu) => bu.businessId === selectedBusinessId
      )?.id,
    });
  };

  const handleConfirm = () => {
    setIsOpenThankyouModal(false);
    setIsOpenRequestMerchantModal(false);
    if (refetch) refetch();
  };

  if (!isOpen) return null;

  // TODO: refactor, start using chakra
  return (
    <div className={`${!isOpen ? 'hidden' : ''} relative`}>
      <div className="fixed inset-0 bg-[#34405499] overflow-auto z-40 h-screen flex items-end sm:items-center justify-center">
        <div
          className={`p-6 bg-white w-full h-fit mx-4 mb-20 sm:my-auto rounded-md z-50 shadow-xl ${
            checkboxLabel && checkboxValue && checkboxOnChange
              ? 'max-w-xl'
              : 'max-w-md'
          } ${
            checkboxLabel && checkboxValue && checkboxOnChange
              ? 'text-center sm:text-left'
              : ''
          }`}
        >
          <div
            className={`${
              checkboxLabel && checkboxValue && checkboxOnChange
                ? 'sm:flex sm:gap-x-6'
                : ''
            }`}
          >
            <div>
              <h2 className="text-lg font-medium text-gray-900 mb-2">
                {title}
              </h2>
              <p className="text-md font-normal text-gray-700">{text}</p>
              <h4 className="text-md font-medium text-gray-900 my-4">
                {subtitle}
              </h4>
              <ul>
                {benefitsList?.map(
                  (item: EBenefitsListTranslation, index: number) => (
                    <li key={index} className="mb-4">
                      <Icon
                        size={'xs'}
                        color={'primary'}
                        theme={'light-circle'}
                        icon={'check'}
                        className="mr-3"
                      />
                      {item}
                    </li>
                  )
                )}
              </ul>
              <div
                className={`mt-8 ${
                  checkboxLabel && checkboxValue && checkboxOnChange
                    ? 'sm:flex items-center justify-between'
                    : ''
                }`}
              >
                <SimpleGrid gap="3" columns={[1, 1, 2]}>
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    disabled={
                      selectedBusinessUser?.isRepresentant === null ||
                      !selectedBusinessUser?.permissions.find(
                        (perm) =>
                          perm.keyCode === EPermission.CREATE_PAYMENT_LINK
                      )
                    }
                    isLoading={isLoadingMutate}
                    onClick={handleRequestBeMerchant}
                  >
                    {labelBtnConfirm}
                  </Button>
                  <Button
                    variant="outline"
                    size="lg"
                    type="button"
                    onClick={onClickClose}
                    disabled={isLoading}
                  >
                    {labelBtnClose}
                  </Button>
                </SimpleGrid>
                {shouldShowError && mutateError && (
                  <p className="mt-1.5 text-sm text-error-500 text-left">
                    {mutateError}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenThankyouModal}
        title={t('user.thankyouModal.title')}
        text={t('user.thankyouModal.text')}
        labelBtnConfirm={t('user.thankyouModal.labelBtnConfirm')}
        onClickConfirm={handleConfirm}
        hidden
      />
    </div>
  );
};
