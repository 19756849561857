import { Button, Flex, Icon, Progress, Text } from '@chakra-ui/react';
import { HEALTH_STATUS_O } from '@common/const';
import { Decimal } from 'decimal.js';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText } from 'react-icons/fi';
import { Icon as CustomIcon } from '@comp/Global/Icon';
import { Mailto } from '@comp/Global/Mailto';
import { Modal } from '@comp/Global/Modal';
import {
  EUnits,
  IGrainContract,
  IParameter,
  TCurrencyName,
} from '../../../common/types';
import { calculateTonsReserved, formatNumber } from '../../../common/utils';
import { TokenIcon } from '../../Global/TokenIcon';
import { TOKEN_CURRENCIES } from '@agrotoken/common-utils';

interface Props {
  grainContract: IGrainContract;
  parameters?: IParameter[];
  className?: string;
  isFutureGrain: boolean;
  isTokenizationCompleted?: boolean;
  isPretokenized?: boolean;
}
const BreakDown: FC<Props> = ({
  grainContract,
  className,
  isFutureGrain,
  isTokenizationCompleted,
  isPretokenized,
}) => {
  const { t } = useTranslation();
  const [isOpenCollateralDetails, setisOpenCollateralDetails] =
    useState<boolean>(false);
  //TODO: Change to useDisclosure
  const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);

  const {
    tonsRequested,
    currency,
    tonsVerified,
    tonsReserved,
    percentReserved,
    healthControl,
    cpr,
  } = grainContract;

  const noHealthStatus = Number(healthControl) === HEALTH_STATUS_O;

  const tonsToCalculate = tonsVerified || tonsRequested;
  const hasPercentReserved = !!percentReserved;

  const availableTons = grainContract.amount;
  const availableTonsInSacas = grainContract.conversions?.amountConversion;
  const initialTonsInSacas = grainContract.conversions?.initialConversion;
  const tonsCompletlyUsed = availableTons === grainContract.tonsVerified;
  const availableSacasPercentage =
    availableTonsInSacas &&
    initialTonsInSacas &&
    (availableTonsInSacas / initialTonsInSacas) * 100;

  const availableTonsPercentage =
    availableTons &&
    grainContract.tonsVerified &&
    parseFloat(
      formatNumber(
        (availableTons / grainContract.tonsVerified) * 100,
        EUnits.TONS
      )
    );

  let calculatedTonsReserved: Decimal;

  if (hasPercentReserved && tonsToCalculate) {
    calculatedTonsReserved = calculateTonsReserved(
      tonsToCalculate,
      +percentReserved
    );
  }

  const tonsReservedToUse = tonsReserved ? new Decimal(tonsReserved!) : null;

  const tokens =
    tonsReservedToUse && tonsToCalculate
      ? new Decimal(tonsToCalculate).minus(tonsReservedToUse)
      : null;

  const formattedTonsReserved = formatNumber(
    Number(tonsReservedToUse),
    EUnits.TONS
  );
  const formattedTonsToCalculate = formatNumber(tonsToCalculate, EUnits.TONS);
  const formattedTokens = formatNumber(Number(tokens), EUnits.CRYPTO);

  const handleOpenInfoModal = () => {
    setIsOpenInfoModal(true);
  };

  return (
    <section className={`${className}`}>
      <div className="bg-gray-50 rounded p-6 border border-gray-200">
        <p
          className={`text-md font-normal text-gray-900 ${
            isFutureGrain ? 'mb-1.5' : ''
          }`}
        >
          {t('tokenization.summary.breakDown.breakDownTitle')}
        </p>
        {!isFutureGrain && (
          <>
            <div className="border-b border-gray-200 py-4">
              <Flex mb="2" justifyContent="space-between" flexWrap="wrap">
                <div>
                  <span className="text-sm font-normal text-gray-700 mr-1">
                    {t('tokenization.summary.breakDown.tonsToTokens')}
                  </span>
                  <span className="text-sm font-normal text-gray-900">
                    ({formattedTonsToCalculate} tons)
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-sm font-normal text-gray-700">
                    {formattedTonsToCalculate}
                  </span>
                  <TokenIcon
                    size="sm"
                    token={currency.symbol as TCurrencyName}
                    className="ml-2 mr-0.5"
                  />
                  <span className="text-sm font-normal text-gray-700">
                    {currency.name}
                  </span>
                </div>
              </Flex>
              <Flex mb="2" justifyContent="space-between" flexWrap={'wrap'}>
                <Flex>
                  <div>
                    <span className="text-sm font-normal text-gray-700 ">
                      {t('tokenization.summary.breakDown.tonsToReserve')}
                      &nbsp;
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Button
                      onClick={() => setisOpenCollateralDetails(true)}
                      variant="linkPrimary"
                      mr={1}
                      textColor={'primary.700'}
                      size="sm"
                    >
                      {t('tokenization.summary.breakDown.percentageAmountText')}
                    </Button>
                    <span className="text-sm font-normal text-gray-700">(</span>
                    <span className="text-sm font-normal text-gray-900">
                      {grainContract.percentReserved}%
                    </span>
                    <span className="text-sm font-normal text-gray-700">)</span>
                  </div>
                </Flex>
                <div className="flex items-center">
                  <span className="text-sm font-normal text-gray-700">
                    - {formattedTonsReserved}
                  </span>
                  <TokenIcon
                    size="sm"
                    token={currency.symbol as TCurrencyName}
                    className="ml-2 mr-0.5"
                  />
                  <span className="text-sm font-normal text-gray-700">
                    {currency.name}
                  </span>
                </div>
              </Flex>
              <Flex justifyContent={'space-between'} flexWrap="wrap">
                <div>
                  <span className="text-sm font-normal text-gray-700 mr-1">
                    {t('tokenization.summary.breakDown.fee')}
                  </span>
                  <span className="text-sm font-normal text-gray-900">
                    {t('tokenization.summary.breakDown.feeSubsidized')}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-sm font-normal text-gray-700">0</span>
                  <TokenIcon
                    size="sm"
                    token={currency.symbol as TCurrencyName}
                    className="ml-2 mr-0.5"
                  />
                  <span className="text-sm font-normal text-gray-700">
                    {currency.name}
                  </span>
                </div>
              </Flex>
            </div>
            <Flex mt="4" justifyContent="space-between" flexWrap="wrap">
              <div>
                <span className="text-lg font-medium text-gray-900 mr-1">
                  {t('tokenization.summary.breakDown.tokensToRecieve')}
                </span>
              </div>
              <div className="flex items-center">
                <span className="text-lg font-medium text-gray-900">
                  {formattedTokens}
                </span>
                <>
                  <TokenIcon
                    size="md"
                    token={currency.symbol as TCurrencyName}
                    className="ml-2 mr-0.5"
                  />
                  <span className="text-lg font-normal text-gray-700">
                    {currency.name}
                  </span>
                </>
              </div>
            </Flex>
          </>
        )}
        {isFutureGrain && (
          <>
            <Flex
              direction={'column'}
              pb={4}
              borderBottom={'1px solid gray.200'}
            >
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-sm font-normal text-gray-700 mr-1">
                    {isTokenizationCompleted
                      ? t('tokenization.summary.breakDown.creationLabelDone')
                      : t('tokenization.summary.breakDown.creationLabel')}
                  </span>
                </div>
                <div className="flex items-center">
                  <Icon as={FiFileText} color="primary.700" size="md" mr="1" />
                  <span className="text-sm  text-gray-700">
                    {t(
                      `tokenization.newTokenization.rightSide.sectionOne.${grainContract.currency.name}`
                    )}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm font-normal text-gray-700 mr-1">
                  {t('tokenization.summary.breakDown.farm')}
                </span>
                <div className="flex items-center">
                  <span className="text-sm  text-gray-700">
                    {grainContract.farm?.name}
                  </span>
                </div>
              </div>
            </Flex>
            {isTokenizationCompleted && (
              <div className="border-b border-gray-200 pb-4">
                <div className="flex mb-2 mt-4 items-center justify-between">
                  <span className="text-sm font-normal text-gray-700 mr-1">
                    {t('tokenization.summary.breakDown.initialTonsLabel')}
                  </span>
                  <div className="flex items-center">
                    <span className="text-sm  text-gray-700">
                      {grainContract.tonsVerified}{' '}
                      {t('tokenization.summary.breakDown.tons')}
                    </span>
                  </div>
                </div>
                <div className="flex mb-2 justify-between">
                  <span className="text-sm font-normal text-gray-700 mr-1">
                    {t(
                      'tokenization.summary.breakDown.currencyEquivalenceLabel'
                    )}
                  </span>
                  <div className="flex items-center">
                    <span className="text-sm font-normal text-gray-700">
                      R$
                      {formatNumber(
                        grainContract.equivalences.tonsVerified[EUnits.BRL],
                        EUnits.BRL
                      )}
                    </span>
                  </div>
                </div>
                {currency.symbol !== TOKEN_CURRENCIES.COFB && (
                  <div className="flex items-center justify-between">
                    <div>
                      <span className="text-sm font-normal text-gray-700 mr-1">
                        {t(
                          'tokenization.summary.breakDown.tokensEquivalenceLabel'
                        )}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <TokenIcon
                        size="sm"
                        token={currency.symbol as TCurrencyName}
                        className="ml-2 mr-0.5"
                      />
                      <span className="text-sm text-gray-700">
                        {formatNumber(
                          grainContract.equivalences.tonsVerified[
                            grainContract.currency.name
                          ],
                          EUnits.CRYPTO
                        )}{' '}
                        {grainContract.currency.name}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div>
              <div className="flex mb-2 mt-4 items-center justify-between">
                <span className="text-md font-medium text-gray-900 mr-1">
                  {isTokenizationCompleted
                    ? t('tokenization.summary.breakDown.currentTons')
                    : t('tokenization.summary.breakDown.warrentyTons')}
                </span>
                <div className="flex items-center">
                  <span className="text-md font-medium text-gray-900">
                    {isTokenizationCompleted && !noHealthStatus
                      ? `${formatNumber(
                          availableTonsInSacas,
                          EUnits.SACAS
                        )} / ${formatNumber(
                          initialTonsInSacas,
                          EUnits.SACAS
                        )} (${formatNumber(
                          availableSacasPercentage,
                          EUnits.PARAM
                        )}%)`
                      : isTokenizationCompleted && noHealthStatus
                      ? `${HEALTH_STATUS_O} / ${formatNumber(
                          initialTonsInSacas,
                          EUnits.SACAS
                        )} (${HEALTH_STATUS_O}%)`
                      : isPretokenized
                      ? `${grainContract.cpr.grainVolume} ${t(
                          'tokenization.summary.breakDown.tons'
                        )}`
                      : `${
                          grainContract.tonsVerified ??
                          grainContract.tonsRequested
                        } ${t('tokenization.summary.breakDown.tons')}`}
                  </span>
                </div>
              </div>
              <>
                {isTokenizationCompleted && (
                  <>
                    <Progress
                      mt={2}
                      value={availableTonsPercentage!}
                      size="md"
                      borderRadius={'8px'}
                      height="10px"
                      bgColor="gray.200"
                      colorScheme={'success'}
                      sx={
                        noHealthStatus
                          ? { div: { bg: 'gray.300' } }
                          : { div: { bg: 'success.600' } }
                      }
                    />
                    {noHealthStatus && (
                      <Flex mt="4" alignItems="center">
                        <CustomIcon
                          size="custom"
                          padding="0"
                          color="gray"
                          theme="no-background"
                          icon="alert-circle"
                          className="pt-0"
                        />
                        <Text
                          paddingLeft={2.5}
                          fontSize="sm"
                          color="gray.900"
                          fontFamily="Larsseit-Medium"
                        >
                          {t('tokenization.summary.breakDown.breakDownAlert')}
                        </Text>
                        <Text
                          as={'button'}
                          onClick={() => handleOpenInfoModal()}
                          paddingLeft={2.5}
                          fontSize="sm"
                          color="gray.900"
                          fontFamily="Larsseit-Medium"
                          textDecoration={'underline'}
                        >
                          {t(
                            'tokenization.summary.breakDown.breakDownAlertLink'
                          )}
                        </Text>
                      </Flex>
                    )}
                    {tonsCompletlyUsed && (
                      <Flex mt="4" alignItems="top">
                        <CustomIcon
                          size="custom"
                          padding="0"
                          color="warning"
                          theme="no-background"
                          icon="info"
                          className="pt-0"
                        />
                        <Text
                          paddingLeft={2.5}
                          fontSize="sm"
                          color="warning.800"
                          fontFamily="Larsseit-Medium"
                        >
                          {t('tokenization.summary.breakDown.tonsAlert')}
                        </Text>
                      </Flex>
                    )}
                  </>
                )}
              </>
            </div>
          </>
        )}
      </div>
      <Modal
        noSpaceAdded
        isOpen={isOpenInfoModal}
        variant="primary"
        iconColor="gray"
        title={t('tokenization.summary.breakDown.breakDownModalTitle')}
        text={[
          t('tokenization.summary.breakDown.breakDownModalText1'),
          t('tokenization.summary.breakDown.breakDownModalText2'),
        ].join(' ')}
        cancelButtonHidden
        labelBtnConfirm={t(
          'tokenization.summary.collateralDetails.labelConfirm'
        )}
        onClickConfirm={() => setIsOpenInfoModal(false)}
        confirmButtonFill={true}
      >
        <Mailto
          className="text-md font-normal text-primary-700 underline underline-offset-4"
          email={t('tokenization.summary.collateralDetails.mail')}
          subject={''}
        >
          {t('tokenization.summary.breakDown.breakDownContactUs')}
        </Mailto>
      </Modal>
      <Modal
        isOpen={isOpenCollateralDetails}
        iconName="info"
        variant="primary"
        iconColor="gray"
        labelBtnConfirm={t(
          'tokenization.summary.collateralDetails.labelConfirm'
        )}
        title={t('tokenization.summary.collateralDetails.title')}
        text={t('tokenization.summary.collateralDetails.text')}
        onClickConfirm={() => setisOpenCollateralDetails(false)}
        cancelButtonHidden
      >
        <p className="text-md font-normal text-gray-700 ">
          {t('tokenization.summary.collateralDetails.text2')}
        </p>
        <div>
          <p className="text-md font-normal text-gray-700">
            {t('tokenization.summary.collateralDetails.text3')}
          </p>
          <Mailto
            className="text-md font-normal text-primary-700"
            email={t('tokenization.summary.collateralDetails.mail')}
            subject={''}
          >
            {t('tokenization.summary.collateralDetails.mail')}
          </Mailto>
        </div>
      </Modal>
    </section>
  );
};

export default BreakDown;
