import {
  formatDate,
  formatNumber,
  TokenCurrency,
} from '@agrotoken/common-utils';
import { Box, Text } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import { useTranslation } from 'react-i18next';

import { EUnits } from '@common/types';
import { BaseCard } from '@comp/BaseCard';

interface FixedGrainCardProps {
  amountSacas: Decimal;
  amountFiat: Decimal;
  currency: TokenCurrency;
  requestNumber: number | null;
  expirationDate: string;
}

/**
 * React component for displaying a fixed grain card.
 * @param {Decimal} amountSacas - The amount of SACAS for the fixed grain.
 * @param {Decimal} amountFiat - The amount in fiat currency for the fixed grain.
 * @param {TokenCurrency} currency - The currency of the fixed grain.
 * @param {number} requestNumber - The request number associated with the fixed grain.
 * @param {string} expirationDate - The expiration date of the fixed grain.
 */
const FixedGrainCard = ({
  amountSacas,
  amountFiat,
  currency,
  requestNumber,
  expirationDate,
}: FixedGrainCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'home.fixedGrainCard' });

  const title = t(`title.${currency}`, { requestNumber });
  const formattedExpirationDate = formatDate(expirationDate, 'DD/MM/YYYY');

  return (
    <BaseCard
      title={title}
      subtitle={t('subtitle.amount', {
        amountSacas: formatNumber(amountSacas, EUnits.SACAS),
      })}
      variant="white"
      bottomElements={
        <Box>
          <Text variant="medium" size="sm" color="content-secondary" mb="1">
            {formatNumber(amountFiat, 'BRL', true)}
          </Text>
          <Text variant="regular" size="sm" color="content-secondary">
            {t('expirationDate', { expirationDate: formattedExpirationDate })}
          </Text>
        </Box>
      }
    />
  );
};

export default FixedGrainCard;
