import { Divider, Flex, Text } from '@chakra-ui/react';
import { ECurrencyName } from '@common/types';
import { TokenSummaryItem } from '../LockedTokensSummary/LockedTokenSummaryItem';
import { FiatPaymentDetail } from './FiatPaymentDetail';

interface Props {
  isTokenPayment: boolean;
  token: ECurrencyName;
}
export const PaymentSummary = ({ isTokenPayment, token }: Props) => {
  return (
    <Flex
      p="24px"
      borderRadius="4px"
      border="1px solid"
      borderColor="gray.200"
      gap="16px"
      flexDir="column"
    >
      <Text fontFamily="Larsseit-Medium" fontWeight="500" fontSize="16px">
        Resumen del pago
      </Text>
      {isTokenPayment ? (
        <>
          {/* TODO: Fix harcoded values: */}
          <Flex flexDir="column" gap="8px">
            <TokenSummaryItem
              label="Capital desembolsado"
              labelDetail="ARS 200.000"
              token={token}
              value="25,6824"
            />
            <TokenSummaryItem
              label="Comisión de Agrotoken"
              labelDetail="1.5%"
              token={token}
              value="0,05"
            />
            <TokenSummaryItem
              label="Cálculo de interéses a fin de plazo"
              labelDetail="1.5%"
              token={token}
              value="1,05"
            />
            <TokenSummaryItem
              label="Aforo"
              labelDetail="5.5%"
              token={token}
              value="1,05"
            />
          </Flex>
          <Divider />
        </>
      ) : (
        <FiatPaymentDetail
          //   TODO: Fix harcoded values
          token={token!}
          agrotokenFee="100"
          agrotokenFeePercentage="1.5"
          agrotokenFeeInFiat="ARS 200.000"
          returnOfTokens="1230"
        />
      )}
    </Flex>
  );
};
