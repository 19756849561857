import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { EDocumentTypes } from '@common/types';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import { FileUpload } from '@comp/Global/FileUpload';
import { Input } from '@comp/Global/Input';
import { PaymentForwardingFormData } from '@comp/PaymentRequestView/CreatePaymentRequest/types';

import { useForwardPaymentForm } from './hooks';

interface ForwardPaymentFormProps {
  forwardings: PaymentForwardingFormData[];
  setForwardings: React.Dispatch<
    React.SetStateAction<PaymentForwardingFormData[]>
  >;
  index: number;
  payerFiscalId: string;
  amountToReceive: number;
}

const ForwardPaymentForm = ({
  forwardings,
  setForwardings,
  index,
  payerFiscalId,
  amountToReceive,
}: ForwardPaymentFormProps) => {
  const { t } = useTranslation();

  const {
    business,
    businessError,
    isLoadingBusiness,
    amountError,
    onChangeAmountHandler,
    onChangeConceptHandler,
    onChangeFiscalIdHandler,
    onClickDeleteUserHandler,
    onSuccessDeleteFileHandler,
    onSuccessUploadedFileHandler,
  } = useForwardPaymentForm({
    forwardings,
    setForwardings,
    index,
    payerFiscalId,
    amountToReceive,
  });

  return (
    <>
      <Flex alignItems="end">
        <Grid templateColumns="repeat(2, 1fr)" mt="4" gap="4" width="100%">
          <Input
            name="fiscalId"
            type="text"
            label={t('createPayment.form.forwardings.fiscalId.label', {
              fiscalPrefix: 'CUIT',
              value: index + 1,
            })}
            placeholder={t(
              'createPayment.form.forwardings.fiscalId.placeholder',
              {
                fiscalPrefix: 'CUIT',
              }
            )}
            value={forwardings[index].fiscalId}
            onChange={(ev) => onChangeFiscalIdHandler(ev, index)}
            fullWidth
          />
          <Input
            name="amount"
            type="number"
            label={t('createPayment.form.forwardings.amount.label')}
            placeholder={t('createPayment.form.forwardings.amount.placeholder')}
            value={forwardings[index].amount}
            onChange={(ev) => onChangeAmountHandler(ev, index)}
            fullWidth
          />
        </Grid>
        <Button
          variant="linkPrimary"
          mb="3.5"
          ml="4"
          onClick={() => onClickDeleteUserHandler(index)}
        >
          <FeatherIcon icon="trash-2" size={22} />
        </Button>
      </Flex>
      {isLoadingBusiness && !businessError && (
        <Spinner size="sm" color="primary.300" mt="2" />
      )}
      {business && !businessError && (
        <Flex mt="2" columnGap="1" color="primary.700">
          <Box color="primary.700" mt="1">
            <FeatherIcon icon="check" size={16} />
          </Box>
          <Text fontSize="sm" color="primary.700">
            {t('createPayment.form.forwardings.fiscalId.businessFound', {
              fiscalPrefix: 'CUIT',
              business: business.name,
            })}
          </Text>
        </Flex>
      )}
      {businessError && (
        <Flex columnGap="1" mt="2">
          <Box color="error.500" mt="1">
            <FeatherIcon icon="alert-circle" size={16} />
          </Box>
          <Text fontSize="sm" color="error.500">
            {businessError}
          </Text>
        </Flex>
      )}
      {amountError && (
        <Flex columnGap="1" mt="2">
          <Box color="error.500" mt="1">
            <FeatherIcon icon="alert-circle" size={16} />
          </Box>
          <Text fontSize="sm" color="error.500">
            {amountError}
          </Text>
        </Flex>
      )}
      <Box mt="4">
        <Input
          name="concept"
          type="text"
          label={t('createPayment.form.forwardings.concept.label')}
          placeholder={t('createPayment.form.forwardings.concept.placeholder')}
          value={forwardings[index].concept}
          fullWidth
          hintMessage={t('createPayment.form.forwardings.concept.hintText')}
          onChange={(ev) => onChangeConceptHandler(ev, index)}
          maxLength={90}
        />
      </Box>
      <Text className="text-sm font-medium text-gray-700 mt-4 mb-1.5">
        {t('createPayment.form.forwardings.document')}
      </Text>
      <FileUpload
        documentTypeId={EDocumentTypes.PAYMENT_REQUEST}
        onSuccessUploadedFileHandler={(data) =>
          onSuccessUploadedFileHandler(data, index)
        }
        onSuccessDeleteFileHandler={() => onSuccessDeleteFileHandler(index)}
        documentUuid={forwardings[index].documentUuid}
      />
      {index !== forwardings.length - 1 && (
        <Divider mt="8" mb="4" borderColor="gray.200" />
      )}
    </>
  );
};

export default ForwardPaymentForm;
