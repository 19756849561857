import { EmptyState } from '@agrotoken-tech/ui';
import { Button, Flex, RadioGroup, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Loader } from '@comp/Global/Loader';
import { getValidFixedGrainContractsByFiscalId } from '@services/GrainContracts';
import PaymentRequestFixedGrainCard from './PaymentRequestFixedGrainCard';
import { FixedGrainSelectorProps } from './interfaces';

const FixedGrainSelector: FC<FixedGrainSelectorProps> = (
  props: FixedGrainSelectorProps
) => {
  const {
    setSelectedFixedGrain,
    toBusinessFiscalId,
    selectedFixedGrain,
    setIsChecked,
  } = props;
  const { data: fixedGrainContracts, isLoading: isLoadingfixedGrainContract } =
    useQuery(['selectedFixedGrain', toBusinessFiscalId], () => {
      setSelectedFixedGrain(undefined);
      return getValidFixedGrainContractsByFiscalId(toBusinessFiscalId);
    });
  const { t } = useTranslation();

  if (isLoadingfixedGrainContract) return <Loader size={'sm'} />;
  if (!fixedGrainContracts || fixedGrainContracts.length === 0)
    return (
      <>
        <EmptyState
          title={t('fixedGrainSelector.emptyState.title')}
          description={t('fixedGrainSelector.emptyState.description1')}
          size={'sm'}
          fontSize="sm"
          color={'gray.500'}
        >
          {t('fixedGrainSelector.emptyState.description2')}
        </EmptyState>
      </>
    );

  return (
    <>
      <Text size="sm" variant="medium" color="gray.700" mb="3" mt="6">
        {t('fixedGrainSelector.title')}
      </Text>
      <Flex flexDir="column" width={'100%'}>
        {isLoadingfixedGrainContract && <Loader />}
        <RadioGroup value={selectedFixedGrain?.id}>
          {!isLoadingfixedGrainContract &&
            fixedGrainContracts?.map((fixedGrainContract) => (
              <Button
                key={fixedGrainContract.id}
                marginBottom={6}
                onClick={() => {
                  setSelectedFixedGrain(fixedGrainContract);
                  setIsChecked(true);
                }}
                h="100%"
                w="100%"
                variant="unstyled"
                _focus={{ outline: 'none' }}
              >
                {fixedGrainContracts && (
                  <PaymentRequestFixedGrainCard
                    grainContract={fixedGrainContract}
                    showRadioButton={true}
                  />
                )}
              </Button>
            ))}
        </RadioGroup>
      </Flex>
    </>
  );
};

export default FixedGrainSelector;
