import { Divider, Flex } from '@chakra-ui/react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useState, useRef, useEffect, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import { Toast } from '../Global/Toast';
import Layout from './Layout';
import NewPasswordForm from './NewPasswordForm';
import ResendEmailForm from './ResendEmailForm';
import VerificationCodeInput from './VerificationCodeInput';

export interface FormValues {
  password: string;
  passwordConfirmation: string;
  verificationCode: string;
}

const INVALID_VERIFICATION_CODE = 'CodeMismatchException';

const EmailVerificationPage: React.VFC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(state.email || '');
  const [isResendingCode, setIsResendingCode] = useState<boolean>(false);

  const { register, handleSubmit, formState, setValue, setError, watch } =
    useForm<FormValues>({
      reValidateMode: 'onSubmit',
      defaultValues: {
        password: '',
        passwordConfirmation: '',
        verificationCode: '',
      },
    });

  const password = useRef('');
  password.current = watch('password', '');

  // setFormValue is set up as a thunk for ease of use
  const setFormValue = (key: keyof FormValues) => (value: string) =>
    setValue(key, value);

  const handleResendCode = (): void => {
    /**
     * TODO:
     * This is a temporary solution to prevent users from submitting without an email.
     * We should add a validation rule to the form to prevent this.
     */
    if (!email) {
      return;
    }

    setIsResendingCode(true);

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: auth.userPool,
    });

    cognitoUser.forgotPassword({
      onSuccess: (_data) => {
        setIsResendingCode(false);
      },
      onFailure: (_err) => {
        setShowToast(true);
        setIsResendingCode(false);
      },
    });
  };

  const onSubmit = (data: FormValues): void => {
    setIsLoading(true);

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: auth.userPool,
    });

    const { verificationCode, password } = data;

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: (_data) => {
        navigate('/');
        setIsLoading(false);
      },
      onFailure: (err) => {
        if (err.name === INVALID_VERIFICATION_CODE) {
          setError('verificationCode', {
            type: 'InvalidCode',
            message: t(
              'resetPasswordVerification.newPasswordForm.verificationCode.errors.invalid'
            ),
          });
        } else {
          setShowToast(true);
        }
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Layout>
        <Flex direction="column">
          <form onSubmit={handleSubmit(onSubmit)}>
            <VerificationCodeInput
              error={formState.errors.verificationCode}
              setPin={setFormValue('verificationCode')}
            />
            <Divider my="6" />
            <ResendEmailForm
              email={email}
              handleResendCode={handleResendCode}
              setEmail={setEmail}
              isLoading={isResendingCode}
            />
            <Divider my="6" />
            <NewPasswordForm
              {...{
                isLoading,
                register,
                password: password.current,
                errors: formState.errors,
              }}
            />
          </form>
        </Flex>
      </Layout>
      <Toast
        className="fixed top-6 right-6 z-50"
        show={showToast}
        onClickClose={() => setShowToast(false)}
        title={t('toasts.serverError.title')}
        text={t('toasts.serverError.text')}
        featuredIcon={FiAlertCircle}
        variant="destructive"
      />
    </>
  );
};

export default EmailVerificationPage;
