import { IBusinessAddress } from '@comp/FullOnboarding/types';
import { http } from '../http-common';
import { PLATFORM_PREFIX } from '@agrotoken/common-utils';

export const getBusinessAddress = async ({
  queryKey,
}: any): Promise<IBusinessAddress | undefined> => {
  try {
    const [, businessAddressId] = queryKey;
    if (!businessAddressId) {
      console.warn(
        'Calling `getBusinessAddress`with no businessAddressId provided'
      );
      return;
    }

    return http
      .get<IBusinessAddress>(
        `/${PLATFORM_PREFIX}/business-addresses/${businessAddressId}`
      )
      .then((resp) => {
        const dataResult: IBusinessAddress = resp.data;
        return dataResult;
      });
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};
