import {
  CreateTransferDto,
  PreviewTransferResponse,
} from '@transfers/add/interfaces';

import { httpPlatform } from '../../http-common';

export const previewTransfer = async (
  dto: CreateTransferDto
): Promise<PreviewTransferResponse | undefined> => {
  try {
    const { data } = await httpPlatform.post<Promise<PreviewTransferResponse>>(
      '/transfers/preview',
      dto
    );
    return data;
  } catch (error) {
    // TODO: Handle error
  }
};
