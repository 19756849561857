import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';

import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { convertBytesToMb } from './utils';

interface FileSuccessProps {
  fileName?: string;
  fileSize?: number;
  onDeleteFileHandler: () => void;
  isLoading: boolean;
}

export const FileSuccess = ({
  fileName,
  fileSize,
  onDeleteFileHandler,
  isLoading,
}: FileSuccessProps) => {
  const { t } = useTranslation();

  const formatFileSize = convertBytesToMb(fileSize ?? 0).toLocaleString(
    'es-AR'
  );

  return (
    <Flex
      borderRadius="base"
      border="1px"
      borderColor="gray.200"
      p="4"
      mt="4"
      justifyContent="space-between"
    >
      <Flex>
        <Icon
          size="md"
          color="primary"
          theme="light-circle-outline"
          icon="file-text"
        />
        <Box ml="3.5">
          <Text fontSize="sm" color="gray.700" className="font-medium">
            {fileName}
          </Text>
          <Text fontSize="sm" color="gray.500" className="font-normal">
            {`${formatFileSize} MB`}
          </Text>
        </Box>
      </Flex>
      {isLoading ? (
        <Spinner variant="line-simple" size="sm" className="self-center" />
      ) : (
        <Button
          variant="linkPrimary"
          leftIcon={<FiTrash2 />}
          onClick={onDeleteFileHandler}
        >
          {t('fileUpload.buttons.delete')}
        </Button>
      )}
    </Flex>
  );
};
