import {
  EValidationStatusId,
  IOnboardingLevel2Request,
  IOnboardingLevel3Request,
} from '@common/types';
import React, { FC, useContext, ReactNode, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getLvl2Request, getLvl3Request } from '@services/FullOnboarding';
import { useUser } from './userContext';

interface FullOnboardingAccessContextType {
  hasOnboardingLevel3Completed_v2: boolean;
  hasOnboardingLevel3PendingVerification_v2: boolean;
  hasOnboardingLevel3ChangesRequested_v2: boolean;
  hasNotSentOnboardingLevel3_v2: boolean;
  hasOnboardingLevel2Completed_v2: boolean;
  hasOnboardingLevel2PendingVerification_v2: boolean;
  hasOnboardingLevel2ChangesRequested_v2: boolean;
  hasNotSentOnboardingLevel2_v2: boolean;
  fetchRequests: () => Promise<void>;
}

interface Props {
  children: ReactNode;
}

const FullOnboardingAccessContext =
  React.createContext<FullOnboardingAccessContextType>(null!);

export const useFullOnboardingAccess = () => {
  return useContext(FullOnboardingAccessContext);
};

export const FullOnboardingAccessProvider: FC<Props> = ({ children }) => {
  const location = useLocation();
  const { selectedBusinessUser, selectedBusiness, selectedBusinessUserId } =
    useUser();
  //permission management

  const [onboardingLevel2Request, setOnboardingLevel2Request] =
    useState<IOnboardingLevel2Request>();
  const [onboardingLevel3Request, setOnboardingLevel3Request] =
    useState<IOnboardingLevel3Request>();

  const fetchRequests = async () => {
    const level3 = await getLvl3Request();
    const level2 = await getLvl2Request();
    setOnboardingLevel3Request(level3);
    setOnboardingLevel2Request(level2);
  };

  useEffect(() => {
    if (selectedBusiness && selectedBusinessUserId) {
      fetchRequests();
    }
  }, [location.pathname, selectedBusiness, selectedBusinessUserId]);

  // v2
  // level2
  const hasOnboardingLevel2Completed_v2 = Boolean(
    selectedBusinessUser?.isRepresentant
  );

  const hasOnboardingLevel2PendingVerification_v2 =
    !hasOnboardingLevel2Completed_v2 &&
    onboardingLevel2Request?.validationStatusId === EValidationStatusId.PENDING;

  const hasOnboardingLevel2ChangesRequested_v2 =
    !hasOnboardingLevel2Completed_v2 &&
    onboardingLevel2Request?.validationStatusId ===
      EValidationStatusId.CHANGE_REQUEST;

  const hasNotSentOnboardingLevel2_v2 =
    !hasOnboardingLevel2Completed_v2 &&
    !hasOnboardingLevel2ChangesRequested_v2 &&
    (!onboardingLevel2Request || !onboardingLevel2Request?.validationStatusId);

  // level3
  const hasOnboardingLevel3Completed_v2 = Boolean(
    selectedBusiness?.isTransactionable
  );
  const hasOnboardingLevel3PendingVerification_v2 =
    !hasOnboardingLevel3Completed_v2 &&
    onboardingLevel3Request?.validationStatusId === EValidationStatusId.PENDING;

  const hasOnboardingLevel3ChangesRequested_v2 =
    !hasOnboardingLevel3Completed_v2 &&
    onboardingLevel3Request?.validationStatusId ===
      EValidationStatusId.CHANGE_REQUEST;

  const hasNotSentOnboardingLevel3_v2 =
    !hasOnboardingLevel3Completed_v2 &&
    !hasOnboardingLevel3ChangesRequested_v2 &&
    (!onboardingLevel3Request || !onboardingLevel3Request?.validationStatusId);

  const value = {
    hasOnboardingLevel3Completed_v2,
    hasOnboardingLevel3PendingVerification_v2,
    hasOnboardingLevel3ChangesRequested_v2,
    hasNotSentOnboardingLevel3_v2,
    hasOnboardingLevel2Completed_v2,
    hasOnboardingLevel2PendingVerification_v2,
    hasOnboardingLevel2ChangesRequested_v2,
    hasNotSentOnboardingLevel2_v2,
    fetchRequests,
  };

  return (
    <FullOnboardingAccessContext.Provider value={value}>
      {children}
    </FullOnboardingAccessContext.Provider>
  );
};
