import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { ICde } from '../common/types';
import { http } from '../http-common';

export const createCdes = (data: any): Promise<ICde[]> => {
  return http
    .post<ICde[]>(
      `/${PLATFORM_PREFIX}/grain-contracts/${data.id}/cdes`,
      data.body
    )
    .then((resp) => {
      const dataResult: ICde[] = resp.data;
      return dataResult;
    });
};

export const updateCdes = (data: any): Promise<ICde[]> => {
  return http
    .patch<ICde[]>(
      `/${PLATFORM_PREFIX}/grain-contracts/${data.id}/cdes`,
      data.body
    )
    .then((resp) => {
      const dataResult: ICde[] = resp.data;
      return dataResult;
    });
};
