import { http } from '@src/http-common';
import { ICity } from '@def/city';
import { PLATFORM_PREFIX } from '@agrotoken/common-utils';

export const getCitiesByGeographicalDivisionId = async (
  geographicalDivisionId: number
): Promise<ICity[] | any> => {
  if (!geographicalDivisionId) return [];
  const { data } = await http.get<ICity>(
    `/${PLATFORM_PREFIX}/cities?geographical_division_id=${geographicalDivisionId}`
  );

  return data;
};
