import { ENationalities } from '@agrotoken/common-utils';
import { useQuery } from 'react-query';

import { GetPreTokenizationsResponse } from '@features/tokenizations';
import { getPreTokenizations } from '@services/GrainContracts';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';

const usePreTokenized = () => {
  const { selectedBusinessId } = useUser();
  const { nationality } = useAuth();

  const { data, isLoading, isError, error } = useQuery<
    GetPreTokenizationsResponse[] | undefined
  >(['preTokenized', selectedBusinessId], getPreTokenizations, {
    enabled: nationality === ENationalities.BR && !!selectedBusinessId,
  });

  return { isLoading, data, isError, error };
};

export default usePreTokenized;
