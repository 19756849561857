import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import { useAuth } from '@src/context/authContext';
import { FC, HTMLAttributes, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EGrainContractStatus,
  FutureTokenizationStep,
  IGrainContract,
  RealTokenizationStep,
} from '../../../common/types';
import { RoundIcon } from './RoundIcon';
import React from 'react';
import { GrainContractTypes } from '@common/enum';

const CompleteTimeLine_AR = React.lazy(
  () => import('../../AR/Global/TimeLine/CompleteTimeLine')
);
const CompleteTimeLine_BR = React.lazy(
  () => import('../../BR/Global/TimeLine/CompleteTimeLine')
);

const completeTimeLine = getMultiRegionMap({
  AR: CompleteTimeLine_AR,
  BR: CompleteTimeLine_BR,
});

export interface Props extends HTMLAttributes<HTMLDivElement> {
  currentStep: RealTokenizationStep | FutureTokenizationStep;
  grainContract: IGrainContract;
}

export const TimeLine: FC<Props> = ({ currentStep, grainContract }) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const { grainContractStatus, grainContractType } = grainContract;
  const CompleteTimeLine = completeTimeLine[nationality];
  const isRealTimeline = grainContractType === GrainContractTypes.REAL;
  const isFixedTimeline = grainContractType === GrainContractTypes.FIXED_GRAIN;
  const isFutureTimeline = grainContractType === GrainContractTypes.FUTURE;

  //UPLOAD TOKENIZATION PROCESS TIMELINE REAL//

  const isProofOfOriginStep =
    currentStep === RealTokenizationStep.PROOF_OF_ORIGIN;
  const isCertificateStep =
    currentStep === RealTokenizationStep.PROOF_OF_EXISTENCE;
  const isContractStep =
    currentStep === RealTokenizationStep.PROOF_OF_LIQUIDITY;
  const isSummaryStep = currentStep === RealTokenizationStep.SUMMARY;
  const proofOfOriginStepComplete =
    currentStep > RealTokenizationStep.PROOF_OF_ORIGIN;
  const certificateStepCompleted =
    currentStep > RealTokenizationStep.PROOF_OF_EXISTENCE;
  const contractStepCompleted =
    currentStep > RealTokenizationStep.PROOF_OF_LIQUIDITY;

  //UPLOAD TOKENIZATION PROCESS TIMELINE FUTURE//

  const isOriginOfGrainStep =
    currentStep === FutureTokenizationStep.ORIGIN_OF_GRAIN;
  const isProductionExpectationStep =
    currentStep === FutureTokenizationStep.PRODUCTION_EXPECTATION;
  const isProofOfAgreementStep =
    currentStep === FutureTokenizationStep.PROOF_OF_LIQUIDITY;
  const isFutureSummaryStep = currentStep === FutureTokenizationStep.SUMMARY;
  const productionExpectationStepComplete =
    currentStep > FutureTokenizationStep.PRODUCTION_EXPECTATION;
  const proofOfAgreementStepCompleted =
    currentStep > FutureTokenizationStep.PROOF_OF_LIQUIDITY;

  const isPretokenized =
    grainContractStatus.name === EGrainContractStatus.PRE_TOKENIZED;

  const useProcessTimeLine =
    grainContractStatus.name === EGrainContractStatus.PENDING || isPretokenized;

  return (
    <>
      <div>
        <h3 className="text-lg font-medium pb-3">{t('timeline.title')}</h3>
        {useProcessTimeLine && (isRealTimeline || isFixedTimeline) ? (
          <div className="flex flex-col items-start">
            {nationality === ENationalities.BR && (
              <RoundIcon
                bigIcon={isProofOfOriginStep}
                size={`${isProofOfOriginStep ? 'lg' : 'sm'}`}
                icon={proofOfOriginStepComplete ? 'check' : 'circle'}
                hasConector
                text={t('timeline.firstStep')}
                color={
                  isProofOfOriginStep || proofOfOriginStepComplete
                    ? 'primary'
                    : 'gray'
                }
                className={`${
                  isProofOfOriginStep
                    ? 'text-sm font-medium text-primary-700 inline-block'
                    : 'text-sm font-medium text-gray-700 inline-block'
                }`}
              />
            )}
            <RoundIcon
              bigIcon={isCertificateStep}
              size={`${isCertificateStep ? 'lg' : 'sm'}`}
              icon={certificateStepCompleted ? 'check' : 'circle'}
              hasConector
              text={t('timeline.secondStep')}
              color={
                isCertificateStep || certificateStepCompleted
                  ? 'primary'
                  : 'gray'
              }
              className={`${
                isCertificateStep
                  ? 'text-sm font-medium text-primary-700 inline-block'
                  : 'text-sm font-medium text-gray-700 inline-block'
              }`}
            />
            <RoundIcon
              bigIcon={isContractStep || (isPretokenized && !isSummaryStep)}
              size={`${
                isContractStep || (isPretokenized && !isSummaryStep)
                  ? 'lg'
                  : contractStepCompleted
                  ? 'sm'
                  : 'md'
              }`}
              icon={isSummaryStep ? 'check' : 'circle'}
              hasConector
              text={t('timeline.thirdStep')}
              color={
                isContractStep || isPretokenized || contractStepCompleted
                  ? 'primary'
                  : 'gray'
              }
              className={`${
                isContractStep || isPretokenized
                  ? 'text-sm font-medium text-primary-700 inline-block'
                  : 'text-sm font-medium text-gray-700 inline-block'
              }`}
            />
            <RoundIcon
              bigIcon={isSummaryStep}
              size={`${isSummaryStep ? 'lg' : 'md'}`}
              icon={'circle'}
              text={t('timeline.summaryStep')}
              color={isSummaryStep ? 'primary' : 'gray'}
              className={`${
                isSummaryStep
                  ? 'text-sm font-medium text-primary-700 inline-block'
                  : 'text-sm font-medium text-gray-700 inline-block'
              }`}
            />
          </div>
        ) : useProcessTimeLine && isFutureTimeline ? (
          <div className="flex flex-col items-start">
            <RoundIcon
              size="sm"
              icon="check"
              hasConector
              text={t('timeline.firstStepFutureGrain')}
              color="primary"
              className="text-sm font-medium text-gray-700 inline-block"
            />
            <RoundIcon
              bigIcon={isProductionExpectationStep}
              size={`${isProductionExpectationStep ? 'lg' : 'sm'}`}
              icon={productionExpectationStepComplete ? 'check' : 'circle'}
              hasConector
              text={t('timeline.secondStepFutureGrain')}
              color={
                isProductionExpectationStep || productionExpectationStepComplete
                  ? 'primary'
                  : 'gray'
              }
              className={`${
                isProductionExpectationStep
                  ? 'text-sm font-medium text-primary-700 inline-block'
                  : 'text-sm font-medium text-gray-700 inline-block'
              }`}
            />
            <RoundIcon
              bigIcon={isProofOfAgreementStep}
              size={`${
                isProofOfAgreementStep
                  ? 'lg'
                  : proofOfAgreementStepCompleted
                  ? 'sm'
                  : 'md'
              }`}
              icon={isFutureSummaryStep ? 'check' : 'circle'}
              hasConector
              text={t('timeline.thirdStepFutureGrain')}
              color={
                isProofOfAgreementStep || proofOfAgreementStepCompleted
                  ? 'primary'
                  : 'gray'
              }
              className={`${
                isProofOfAgreementStep
                  ? 'text-sm font-medium text-primary-700 inline-block'
                  : 'text-sm font-medium text-gray-700 inline-block'
              }`}
            />
            <RoundIcon
              bigIcon={isFutureSummaryStep}
              size={`${isFutureSummaryStep ? 'lg' : 'md'}`}
              icon={'circle'}
              text={t('timeline.summaryStep')}
              color={isFutureSummaryStep ? 'primary' : 'gray'}
              className={`${
                isFutureSummaryStep
                  ? 'text-sm font-medium text-primary-700 inline-block'
                  : 'text-sm font-medium text-gray-700 inline-block'
              }`}
            />
          </div>
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <CompleteTimeLine
              grainContract={grainContract}
              currentStep={currentStep}
            />
          </Suspense>
        )}
      </div>
    </>
  );
};
