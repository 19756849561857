import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ilustration from '../../../../assets/images/payment-illustration.svg';
import { PAYMENT_PATH } from '../../../../common/const';
import { Container } from '../../../Global/Container';

export const ExpiredPayment: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Flex
        gap="6"
        alignItems="center"
        justifyContent="space-evenly"
        flexDir={{ base: 'column', md: 'row' }}
        mt="24"
      >
        <Box>
          <Heading color="gray.900" mb="6">
            {t('payment.expired.title')}
          </Heading>
          <Text fontWeight="normal" fontSize="xl" color="gray.500" mb="12">
            {t('payment.expired.text1')}
          </Text>
          <Button
            variant="primary"
            size="md"
            onClick={() => navigate(PAYMENT_PATH)}
          >
            {t('payment.expired.btnLabel')}
          </Button>
        </Box>
        <Box>
          <Image
            src={ilustration}
            alt="expired"
            display={{ base: 'none', md: 'inline-block' }}
          />
        </Box>
      </Flex>
    </Container>
  );
};
