import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { Nationality } from '@def/nationality';
import { http } from '@src/http-common';

export const getNationatilities = (): Promise<Nationality[]> => {
  return http
    .get<Nationality[]>(`/${PLATFORM_PREFIX}/nationalities`)
    .then((resp) => {
      const dataResult: Nationality[] = resp.data;
      return dataResult;
    });
};
