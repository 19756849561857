import { FeaturedIcons } from '@agrotoken-tech/ui';
import { Box, Flex } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { type IconType } from 'react-icons';
import { Button } from '../Button';

export interface Props {
  className?: string;
  show: boolean;
  onClickClose: () => void;
  title: string;
  text?: string;
  featuredIcon: IconType;
  variant: 'primary' | 'destructive' | 'warning';
  duration?: number;
}

export const Toast: FC<Props> = ({
  className,
  show,
  onClickClose,
  title,
  text,
  featuredIcon,
  variant,
  duration,
}) => {
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        onClickClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Box
      className={`p-4 shadow-lg bg-white max-w-[440px] rounded z-50 ${className} ${
        show ? 'block' : 'hidden'
      }`}
    >
      <Flex alignItems="center">
        <Box w="10">
          <FeaturedIcons
            shape="circle"
            variant={variant ?? 'success'}
            size={'sm'}
            icon={featuredIcon}
          />
        </Box>
        <Box paddingX={4}>
          <p className="text-sm font-medium text-gray-900">{title}</p>
          <p className="text-sm text-gray-500">{text}</p>
        </Box>
        <Box>
          <Button
            variant="tertiary-gray"
            size="sm"
            icon="x"
            iconPosition="only"
            onClick={onClickClose}
          />
        </Box>
      </Flex>
    </Box>
  );
};
