import { Alert } from '@comp/Global/Alert';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  messages: string[];
}
const ChangeRequestedAlert: VFC<Props> = ({ messages = [] }) => {
  const { t } = useTranslation();
  return (
    <>
      <Alert
        color="warning"
        text={t('fullOnboarding.changeRequestedAlert.text')}
        supportText={messages.map((message) => '• ' + message).join('\n')}
        className="mt-6 whitespace-pre"
      />
    </>
  );
};
export default ChangeRequestedAlert;
