export const OPERATION_TYPES = {
  ALL: 'ALL',
  TOKENIZATION: 'TOKENIZATION',
  DETOKENIZATION: 'DETOKENIZATION',
  PAYMENT: 'PAYMENT',
  PAYMENT_IN_KIND: 'PAYMENT_IN_KIND',
  PAYMENT_WITH_CARD: 'PAYMENT_WITH_CARD',
  CREDIT_CARD_BLOCKED_RETURN: 'CREDIT_CARD_BLOCKED_RETURN',
  LOANS: 'LOANS',
  SWAP_POGR: 'SWAP_POGR',
  GIFT: 'GIFT',
  PAYMENT_IN_WARRANTIES: 'PAYMENT_IN_WARRANTIES',
  PAYMENT_WARRANTY_CLAIM: 'PAYMENT_WARRANTY_CLAIM',
  CARD_PAYMENT_AUTHORIZATION_HOLD: 'CARD_PAYMENT_AUTHORIZATION_HOLD',
  MERCHANT_TO_MERCHANT_PAYMENT_FORWARDING:
    'MERCHANT_TO_MERCHANT_PAYMENT_FORWARDING',
  MERCHANT_TO_MERCHANT_PAYMENT_FORWARDING_SETTLEMENT:
    'MERCHANT_TO_MERCHANT_PAYMENT_FORWARDING_SETTLEMENT',
  FREEZE_TOKENS: 'FREEZE_TOKENS',
  UNFREEZE_TOKENS: 'UNFREEZE_TOKENS',
  ASSET_LOCK: 'ASSET_LOCK',
  WARRANTY_TRANSFER: 'WARRANTY_TRANSFER',
  ASSET_UNLOCK: 'ASSET_UNLOCK',
  CREDIT_LINE_COLLATERALIZATION: 'CREDIT_LINE_COLLATERALIZATION',
  CREDIT_SETTLEMENT: 'CREDIT_SETTLEMENT',
  PARTIAL_CREDIT_SETTLEMENT: 'PARTIAL_CREDIT_SETTLEMENT',
} as const;

export type OperationType =
  typeof OPERATION_TYPES[keyof typeof OPERATION_TYPES];
export const operationTypes = Object.values(OPERATION_TYPES);

export const OPERATION_STATUSES = {
  PENDING: 'PENDING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
  LIQUIDATED: 'LIQUIDATED',
  EXPIRED: 'EXPIRED',
} as const;

export type OperationStatus =
  typeof OPERATION_STATUSES[keyof typeof OPERATION_STATUSES];
export const operationStatuses = Object.values(OPERATION_STATUSES);
