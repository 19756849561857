import { Checkbox, Flex, FormLabel, Link, Text } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { UseCreateWarrantyTransferValues } from '../hooks/useCreateWarrantyTransferForm';

interface TermsAndConditionsProps {
  form: UseFormReturn<UseCreateWarrantyTransferValues, object>;
}

const HAS_ACCEPTED_TERMS_AND_CONDITIONS = 'hasAcceptedTermsAndConds';

const TermsAndConditions = ({ form }: TermsAndConditionsProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.warrantySummary',
  });

  const { setValue, getValues } = form;

  const onChangeHandler = () => {
    const hasAcceptedTermsAndConds = getValues(
      HAS_ACCEPTED_TERMS_AND_CONDITIONS
    );
    setValue(HAS_ACCEPTED_TERMS_AND_CONDITIONS, !hasAcceptedTermsAndConds);
  };

  return (
    <Flex alignItems="center" columnGap="2">
      <Checkbox
        id="terms"
        onChange={onChangeHandler}
        checked={getValues(HAS_ACCEPTED_TERMS_AND_CONDITIONS)}
        name="terms"
        type="checkbox"
        required
      />
      <Flex alignItems="center" columnGap="1">
        <FormLabel m="0" htmlFor="terms">
          <Text color="gray.700" size="sm" variant="medium">
            {t('termsAndConditionsAccept')}
          </Text>
        </FormLabel>
        <Link
          href={t('termsAndConditionsLink')}
          target="_blank"
          rel="noreferrer"
        >
          <Text
            color="gray.500"
            size="sm"
            textDecorationLine={'underline'}
            variant="medium"
          >
            {t('termsAndConditions')}
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};

export default TermsAndConditions;
