import { Flex, Box, Text, Show } from '@chakra-ui/react';
import { VFC } from 'react';
import TimeLineIcon from './TimeLineIcon';

export enum TimeLineItemStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  ACTIVE = 'active',
  CANCELED = 'canceled',
}
export interface ITimeLineItem {
  text: string;
  status: TimeLineItemStatus;
  date?: string | null;
}

interface Props {
  steps: ITimeLineItem[];
  title?: string;
}

const mapStatusToIcon = {
  completed: 'check',
  active: 'circle',
  pending: 'circle',
  canceled: 'x',
};

const TimeLine: VFC<Props> = ({ steps, title }) => {
  return (
    <Box>
      <Show above="md">
        <Text color="gray.900" mb="3" size="lg" variant="medium">
          {title}
        </Text>
      </Show>
      {steps.map((step, index) => {
        const isPending = step.status === TimeLineItemStatus.PENDING;
        const statusIcon = mapStatusToIcon[step.status] as
          | 'check'
          | 'circle'
          | 'x';

        const iconColor = isPending
          ? 'background-secondary-default'
          : 'background-primary-default';
        const iconBackgroundColor = isPending
          ? 'border-color-disabled'
          : 'background-gray-default';
        const key = btoa(step.status + step.text);
        const hasDate = Boolean(step.date);
        const isCompleted = step.status === TimeLineItemStatus.COMPLETED;
        const textColor = isPending ? 'content-disabled' : 'content-primary';
        const dateColor = isPending ? 'content-disabled' : 'content-primary';
        const isLastElement = index === steps.length - 1;
        const lineColor = isCompleted
          ? 'content-primary'
          : 'border-color-disabled';
        return (
          <Flex key={key}>
            <Flex flexDir="column" align="center">
              {statusIcon && (
                <TimeLineIcon
                  icon={statusIcon}
                  size="md"
                  iconColor={iconColor}
                  iconBackgroundColor={iconBackgroundColor}
                />
              )}
              {!isLastElement && (
                <Flex
                  backgroundColor={lineColor}
                  width={'0.5'}
                  height="full"
                  my={'1'}
                />
              )}
            </Flex>
            <Flex flexDir="column" ml="3" mb="6">
              <Text size="sm" color={textColor} variant="medium">
                {step.text}
              </Text>
              {hasDate && (
                <Text size="sm" variant="regular" color={dateColor}>
                  {step.date}
                </Text>
              )}
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};

export default TimeLine;
