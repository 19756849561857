import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@comp/Global/Icon';

interface EmptyStateProps {
  isPayer?: boolean;
}
export const EmptyState: FC<EmptyStateProps> = (props: EmptyStateProps) => {
  const { t } = useTranslation();
  const { isPayer } = props;
  return (
    <div className="p-10 min-h-[225px] flex items-center justify-center text-center border border-gray-200 bg-gray-50 rounded">
      <div>
        <Icon size="xl" color="gray" theme="light-circle" icon="refresh-cw" />
        {isPayer ? (
          <Text className="mt-5 text-md text-gray-900 font-normal">
            {t('payPaymentRequest.emptyState')}
          </Text>
        ) : (
          <Text className="mt-5 text-md text-gray-900 font-normal">
            {t('payPaymentRequest.emptyStateMerchant')}
          </Text>
        )}
      </div>
    </div>
  );
};
