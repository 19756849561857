import { Flex, Spinner } from '@chakra-ui/react';
import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { ReactNode } from 'react-dom/node_modules/@types/react';
import { useTranslation } from 'react-i18next';
import { FiUserCheck } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AcceptTokenizationsTermsAndCondsModal from '@comp/AcceptTokenizationsTermsAndCondsModal';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { useNavigateSelectOrg } from '@hooks/index';
import { getMerchantRequest } from '@services/MerchantRequests';
import {
  FULL_ONBOARDING_BANKING_AND_BILLING,
  FULL_ONBOARDING_KYC,
  HOME_PATH,
  SELECT_ORG,
  SUPPORT_EMAIL,
  TOKENIZATIONS_PATH,
} from '@src/common/const';
import {
  IMerchantRequest,
  IPaymentRequest,
  PAYMENT_REQUEST_TYPES,
  UserTypes,
} from '@src/common/types';
import { useAccess } from '@src/context/accessContext';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import { Modal } from '../Global/Modal';
import { BeMerchantRequestModal } from './BeMerchantRequestModal';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: JSX.Element | ReactNode;
  paymentRequest?: IPaymentRequest;
}

export const RequireTokenizationAccess: FC<Props> = ({ children }) => {
  const {
    isRepresentant,
    canTokenize,
    isSecondOnboardingLevel,
    isThirdOnboardingLevel,
    hasAcceptedTokenizationTermsAndConds,
  } = useAccess();
  const {
    selectedBusiness,
    selectedBusinessUser,
    currentUser,
    selectedBusinessId,
  } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (
    currentUser?.userType === UserTypes.MANAGER &&
    (!selectedBusiness || !selectedBusinessUser)
  ) {
    navigate(SELECT_ORG);
    return <></>;
  }

  if ((!selectedBusiness || !selectedBusinessUser) && selectedBusinessId)
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    );

  if (!canTokenize) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }
  if (
    (!isRepresentant && isSecondOnboardingLevel) ||
    (!isRepresentant && isThirdOnboardingLevel)
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (!isRepresentant) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_KYC)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }
  if (!hasAcceptedTokenizationTermsAndConds) {
    return <AcceptTokenizationsTermsAndCondsModal isOpen={true} />;
  }

  return <>{children}</>;
};

export const RequireCreatePaymentAccess: FC<Props> = ({ children }) => {
  const [isOpenRequestMerchantModal, setIsOpenRequestMerchantModal] =
    useState<boolean>(true);
  const {
    isMerchant,
    canCreatePaymentLink,
    isRepresentant,
    isTransactionable,
    isFirstOnboardingLevel,
    isSecondOnboardingLevel,
    isThirdOnboardingLevel,
    isOnboardingValidated,
  } = useAccess();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    selectedBusiness,
    selectedBusinessUser,
    selectedBusinessUserWasInvited,
    selectedBusinessUserWasAdminInvited,
    currentUser,
    selectedBusinessId,
  } = useUser();

  useNavigateSelectOrg();

  const {
    isLoading,
    data: merchantRequest,
    error,
    refetch,
  } = useQuery<IMerchantRequest | undefined>(
    ['merchantRequest'],
    getMerchantRequest
  );

  if (isLoading) return <Loader />;
  if (error)
    return (
      <ServerError
        title={t('serverError.title')}
        text={(error as any)?.message}
      />
    );

  if (currentUser?.userType === UserTypes.MANAGER) {
    navigate(TOKENIZATIONS_PATH);
  }

  if ((!selectedBusiness || !selectedBusinessUser) && selectedBusinessId)
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    );

  if (!canCreatePaymentLink) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (
    !isRepresentant &&
    selectedBusinessUserWasInvited &&
    isFirstOnboardingLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_KYC)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (
    !isTransactionable &&
    selectedBusinessUserWasAdminInvited &&
    isFirstOnboardingLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (
    !isRepresentant &&
    isSecondOnboardingLevel &&
    selectedBusinessUserWasAdminInvited
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_BANKING_AND_BILLING)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (
    !isRepresentant &&
    selectedBusinessUserWasInvited &&
    !isOnboardingValidated
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (
    !isRepresentant &&
    !selectedBusinessUserWasInvited &&
    isThirdOnboardingLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (
    !isRepresentant &&
    !selectedBusinessUserWasInvited &&
    isSecondOnboardingLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_BANKING_AND_BILLING)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (!isRepresentant && !isOnboardingValidated) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_KYC)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (
    isRepresentant &&
    !isTransactionable &&
    isThirdOnboardingLevel &&
    !selectedBusinessUserWasInvited &&
    selectedBusinessUserWasAdminInvited
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (
    !isTransactionable &&
    selectedBusinessUserWasAdminInvited &&
    isThirdOnboardingLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (!isTransactionable) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_BANKING_AND_BILLING)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (merchantRequest && !isMerchant) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleMerchantRequestVerificationPending')}
        text={t('verificationPending.textMerchantRequestVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (isRepresentant && isTransactionable && !isMerchant) {
    return (
      <BeMerchantRequestModal
        refetch={refetch}
        setIsOpenRequestMerchantModal={setIsOpenRequestMerchantModal}
        isOpen={isOpenRequestMerchantModal}
        title={t('user.requestMerchantModal.title')}
        text={`${t('user.requestMerchantModal.text1')} ${
          selectedBusiness?.name
        } ${t('user.requestMerchantModal.text2')} `}
        subtitle={t('user.requestMerchantModal.subtitle')}
        labelBtnClose={t('user.requestMerchantModal.cancelBtnLabel')}
        labelBtnConfirm={t('user.requestMerchantModal.sendBtnLabel')}
        onClickClose={() => navigate(`${HOME_PATH}`)}
        center
      />
    );
  }

  return <>{children}</>;
};

export const RequireAdminAccess: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isTransactionable,
    isRepresentant,
    canAdminUsers,
    isSecondOnboardingLevel,
    isThirdOnboardingLevel,
    isFirstOnboardingLevel,
  } = useAccess();

  const {
    currentUser,
    selectedBusinessUserWasAdminInvited,
    selectedBusiness,
    selectedBusinessUser,
    selectedBusinessId,
  } = useUser();

  if (currentUser?.userType === UserTypes.MANAGER) {
    navigate(TOKENIZATIONS_PATH);
    return <></>;
  }

  if ((!selectedBusiness || !selectedBusinessUser) && selectedBusinessId)
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    );

  if (!canAdminUsers) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (!isRepresentant && isThirdOnboardingLevel) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (!isRepresentant && isSecondOnboardingLevel) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_BANKING_AND_BILLING)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }
  if (!isRepresentant) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_KYC)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (
    !isTransactionable &&
    selectedBusinessUserWasAdminInvited &&
    isFirstOnboardingLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (
    !isTransactionable &&
    selectedBusinessUserWasAdminInvited &&
    isThirdOnboardingLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (!isTransactionable) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_BANKING_AND_BILLING)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  return <>{children}</>;
};

export const RequirePaymentAccess: FC<Props> = ({
  children,
  paymentRequest,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canMakePayments, isRepresentant, isTransactionable } = useAccess();
  const { currentUser } = useUser();

  if (!canMakePayments) {
    return (
      <Modal
        isOpen={true}
        title={t('noAccessModal.titleNoPermissions')}
        text={t('noAccessModal.textNoPermissions')}
        labelBtnConfirm={t('noAccessModal.confirmBtn')}
        iconName="alert-triangle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  if (currentUser?.userType === UserTypes.MANAGER) {
    navigate(TOKENIZATIONS_PATH);
    return <></>;
  }

  if (
    !isRepresentant &&
    !isTransactionable &&
    paymentRequest?.type !== PAYMENT_REQUEST_TYPES.IN_KIND
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() =>
          navigate(
            !isTransactionable
              ? FULL_ONBOARDING_BANKING_AND_BILLING
              : FULL_ONBOARDING_KYC
          )
        }
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }
  if (
    !isRepresentant &&
    paymentRequest?.type === PAYMENT_REQUEST_TYPES.IN_KIND
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verifyIdentity.titleVerifyIdentity')}
        text={t('verifyIdentity.textVerifyIdentity')}
        labelBtnConfirm={t('verifyIdentity.confirmBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(FULL_ONBOARDING_KYC)}
        onClickClose={() => navigate(HOME_PATH)}
      />
    );
  }

  return <>{children}</>;
};

export const RequireOnboardingLevelTwoAccess: FC<Props> = ({ children }) => {
  const {
    isRepresentant,
    isSecondOnboardingLevel,
    isThirdOnboardingLevel,
    hasChangeRequestedInSecondLevel,
  } = useAccess();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { nationality } = useAuth();

  if (
    !isRepresentant &&
    (isSecondOnboardingLevel || isThirdOnboardingLevel) &&
    !hasChangeRequestedInSecondLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  // TODO: We add this !hasChangeRequestedInSecondLevel condition because
  // there is a possibility of the business user being representant already (means Level2 approved)
  // BUT then there is a change needed for business address. This technically belongs to Business (therefore level 3),
  // but is completed in Level2 because it's needed for Tokenization. This needs to be reviewed asap
  if (isRepresentant && !hasChangeRequestedInSecondLevel) {
    return (
      <Modal
        isOpen={true}
        title={t('onboardingLevelTwoAccessModal.titleNoPermissions')}
        labelBtnConfirm={t('onboardingLevelTwoAccessModal.confirmBtn')}
        iconName="alert-circle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      >
        <p>
          <span className="text-md font-normal text-gray-700">
            {t('onboardingLevelTwoAccessModal.textNoPermissions')}
          </span>{' '}
          <a
            className="text-md font-bold underline text-primary-700"
            href={`mailto:${SUPPORT_EMAIL[nationality]}`}
          >
            {SUPPORT_EMAIL[nationality]}
          </a>
        </p>
      </Modal>
    );
  }
  return <>{children}</>;
};

export const RequireOnboardingLevelThreeAccess: FC<Props> = ({ children }) => {
  const {
    isTransactionable,
    isThirdOnboardingLevel,
    hasChangeRequestedInThirdLevel,
  } = useAccess();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { nationality } = useAuth();

  if (
    !isTransactionable &&
    isThirdOnboardingLevel &&
    !hasChangeRequestedInThirdLevel
  ) {
    return (
      <Modal
        isOpen={true}
        title={t('verificationPending.titleVerificationPending')}
        text={t('verificationPending.textVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => navigate(HOME_PATH)}
        cancelButtonHidden
      />
    );
  }

  if (isTransactionable) {
    return (
      <Modal
        isOpen={true}
        title={t('onboardingLevelThreeAccessModal.titleNoPermissions')}
        labelBtnConfirm={t('onboardingLevelThreeAccessModal.confirmBtn')}
        iconName="alert-circle"
        iconColor="warning"
        cancelButtonHidden
        onClickConfirm={() => navigate(HOME_PATH)}
        onClickClose={() => navigate(HOME_PATH)}
      >
        <p>
          <span className="text-md font-normal text-gray-700">
            {t('onboardingLevelThreeAccessModal.textNoPermissions')}
          </span>{' '}
          <a
            className="text-md font-bold underline text-primary-700"
            href={`mailto:${SUPPORT_EMAIL[nationality]}`}
          >
            {SUPPORT_EMAIL[nationality]}
          </a>
        </p>
      </Modal>
    );
  }
  return <>{children}</>;
};
export const RequireHomeAccess: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { currentUser } = useUser();

  if (currentUser?.userType === UserTypes.MANAGER) {
    navigate(TOKENIZATIONS_PATH);
    return <></>;
  }
  return <>{children}</>;
};

export const RequireCreditsAccess: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { currentUser } = useUser();

  if (currentUser?.userType === UserTypes.MANAGER) {
    navigate(TOKENIZATIONS_PATH);
  }
  return <>{children}</>;
};
