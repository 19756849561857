import isEqual from 'lodash/isEqual';
import React, { ReactNode, useEffect, useState, useContext, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Toast } from '@comp/Global/Toast';
import {
  getBusinessUserWasAdminInvited,
  getBusinessUserWasInvited,
} from '@services/BusinessInvites';
import { getBusinessUserAsManager } from '@services/BusinessUsers';
import { getBusinessById } from '@services/Businesses';
import { IBusiness, IBusinessUser, IUser, UserTypes } from '../common/types';
import useLocalStorageState from '../hooks/useLocalStorageState';
import { getCurrentUser } from '../services/Users';
import { useAuth } from './authContext';

interface UserContextType {
  currentUser: IUser | null;
  selectedBusinessId: number | null;
  setSelectedBusinessId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedBusiness: IBusiness | null;
  setSelectedBusiness: React.Dispatch<React.SetStateAction<IBusiness | null>>;
  selectedBusinessUser: IBusinessUser | null;
  setSelectedBusinessUser: React.Dispatch<
    React.SetStateAction<IBusinessUser | null>
  >;
  selectedBusinessUserId: number | null;
  setSelectedBusinessUserId: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setCurrentUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  isErrorUser: boolean;
  isLoadingUser: boolean;
  selectedBusinessUserWasInvited: boolean;
  selectedBusinessUserWasInvitedIsLoading: boolean;
  selectedBusinessUserWasAdminInvited: boolean;
  selectedBusinessUserWasAdminInvitedIsLoading: boolean;
  errorUser: { message: string } | null;
  userHasBeenOnboardedBefore: boolean;
}

interface Props {
  children: ReactNode;
}

const UserContext = React.createContext<UserContextType>(null!);

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }: Props) => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [isErrorUser, setIsErrorUser] = useState(false);
  const [errorUser, setErrorUser] = useState<{ message: string } | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] =
    useLocalStorageState<number>('selectedBusinessId', null); //TODO: eliminar pero chequear previamente que todo lo que hoy usa el id pasarlo al business.id
  const [selectedBusiness, setSelectedBusiness] = useState<IBusiness | null>(
    null
  );
  const [selectedBusinessUser, setSelectedBusinessUser] =
    useState<IBusinessUser | null>(null);
  const [selectedBusinessUserId, setSelectedBusinessUserId] =
    useLocalStorageState<number>('selectedBusinessUserId', null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const location = useLocation();
  const {
    data: selectedBusinessUserWasInvited = false,
    isLoading: selectedBusinessUserWasInvitedIsLoading,
  } = useQuery<boolean>(
    ['businessUserWasInvited', selectedBusinessUserId, selectedBusinessId],
    getBusinessUserWasInvited,
    { enabled: !!selectedBusinessId && !!selectedBusinessUserId }
  );

  const {
    data: selectedBusinessUserWasAdminInvited = false,
    isLoading: selectedBusinessUserWasAdminInvitedIsLoading,
  } = useQuery<boolean>(
    ['businessUserWasAdminInvited', selectedBusinessUserId, selectedBusinessId],
    getBusinessUserWasAdminInvited,
    { enabled: !!selectedBusinessId && !!selectedBusinessUserId }
  );

  const { data: businessStoredData, refetch: refetchBusiness } = useQuery<
    IBusiness | undefined
  >(['business', selectedBusinessId], getBusinessById, {
    enabled: !!selectedBusinessId,
  });

  const {
    data: businessUserStoredData,
    refetch: refetchBusinessUserAsManager,
  } = useQuery<IBusinessUser | undefined>(
    ['businessUser', selectedBusinessUserId],
    getBusinessUserAsManager,
    {
      enabled:
        !!selectedBusinessUserId && currentUser?.userType === UserTypes.MANAGER,
    }
  );

  useEffect(() => {
    if (businessStoredData) {
      setSelectedBusiness(businessStoredData);
    }
  }, [businessStoredData]);

  useEffect(() => {
    if (businessUserStoredData) {
      setSelectedBusinessUser(businessUserStoredData);
    }
  }, [businessUserStoredData]);

  useEffect(() => {
    if (!auth) return;
    auth
      .isAuth()
      .then(() => setIsAuthenticated(true))
      .catch((error: Error) => {
        console.error(error);
      });
  }, [auth]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      setIsErrorUser(false);
      setErrorUser(null);
      setIsLoadingUser(true);
      try {
        const user = await getCurrentUser();
        if (!isEqual(currentUser, user)) {
          setCurrentUser(user);
        }
        if (
          user.userType === UserTypes.MANAGER &&
          selectedBusinessUserId !== null
        ) {
          refetchBusinessUserAsManager();
        }
      } catch (error) {
        setIsErrorUser(true);
        setErrorUser(error as { message: string });
      } finally {
        setIsLoadingUser(false);
      }
    };
    fetchCurrentUser();
    refetchBusiness();
  }, [location.pathname]);

  useEffect(() => {
    if (!currentUser || !selectedBusinessId) return;

    const businessUserFound = currentUser.businessUsers.find(
      (bu) => bu.businessId === selectedBusinessId
    );
    if (businessUserFound) {
      setSelectedBusinessUserId(businessUserFound.id);
      setSelectedBusinessUser(businessUserFound);
    }
  }, [currentUser, selectedBusinessId]);

  const setBusinessThroughQueryParam = (businessId: number) => {
    // Get the requested business and set it in the storage.
    setIsLoadingUser(true);
    getBusinessById({ queryKey: ['business', businessId] })
      .then((d) => {
        setSelectedBusinessId(businessId);
        setSelectedBusiness(d);
        setShowToast(true);
      })
      .catch((e) => {
        // Show error toast
        console.log(e);
      })
      .finally(() => {
        // Cleanup query url
        // Set loading false
        setIsLoadingUser(false);
        searchParams.delete('validateBusinessId');
        setSearchParams(searchParams);
      });
  };

  useEffect(() => {
    // Check if a `businessId` exists in queryParam.
    // If it's different than the selectedBusinessId, then set it and tell the user it has changed.
    const validationParam = searchParams.get('validateBusinessId');
    if (validationParam && parseInt(validationParam) !== selectedBusinessId) {
      setBusinessThroughQueryParam(parseInt(validationParam));
    }
  }, []);

  // If user has IdentificationValue or IdentificationTaxValue, it means that
  // it has been approved in some sign up process.
  const userHasBeenOnboardedBefore =
    !!currentUser?.identificationValue && !!currentUser?.identificationTaxValue;

  const value = {
    currentUser,
    setCurrentUser,
    selectedBusinessId,
    setSelectedBusinessId,
    selectedBusiness,
    setSelectedBusiness,
    setSelectedBusinessUser,
    setSelectedBusinessUserId,
    selectedBusinessUserId,
    selectedBusinessUser,
    isErrorUser,
    isLoadingUser,
    selectedBusinessUserWasInvited,
    selectedBusinessUserWasInvitedIsLoading,
    selectedBusinessUserWasAdminInvited,
    selectedBusinessUserWasAdminInvitedIsLoading,
    errorUser,
    userHasBeenOnboardedBefore,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
      <Toast
        className="fixed top-6 right-6 z-50"
        show={showToast}
        onClickClose={() => setShowToast(false)}
        title={t('toasts.businessSwitch.success.title')}
        text={t('toasts.businessSwitch.success.text', {
          businessName: selectedBusiness?.name,
        })}
        featuredIcon={FiCheckCircle}
        variant="warning"
        duration={3000}
      />
    </UserContext.Provider>
  );
};
