import { FeaturedIcons } from '@agrotoken-tech/ui';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiRefreshCcw } from 'react-icons/fi';

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <Flex
      as="section"
      p="12"
      border="1px"
      borderRadius="4px"
      borderColor="gray.200"
      bgColor="gray.50"
      flexDirection="column"
      alignItems="center"
      rowGap="4"
    >
      <FeaturedIcons
        size="lg"
        variant="primary"
        shape="circle"
        icon={FiRefreshCcw}
      />
      <Text size="sm" variant="regular" color="gray.700" textAlign="center">
        {t('components.summaryDetail.emptyState')}
      </Text>
    </Flex>
  );
};

export default EmptyState;
