import { Flex, Text } from '@chakra-ui/react';
import { TCurrencyName } from '@common/types';
import { TokenIcon } from '@comp/Global/TokenIcon';

interface Props {
  value: string | number;
  token: TCurrencyName;
}
export const TokenValue = ({ value, token }: Props) => {
  return (
    <Flex alignItems="center">
      <Text ml="8px">{value}</Text>
      <TokenIcon size="sm" token={token} className="ml-2 mr-1" />
      <Text>{token}</Text>
    </Flex>
  );
};
