import {
  ENationalities,
  TRANSLATIONS_PT as TRANSLATIONS_PT_COMMON,
} from '@agrotoken/common-utils';
import { EBusinessStructureTypes } from '@common/types';
import {
  CREDITS_TRANSLATIONS_PT,
  ENDORSEMENTS_TRANSLATIONS_PT,
  LOANS_TRANSLATIONS_PT,
} from '@features/loans/translations';
import { CARDS_TRANSLATIONS_PT } from '@src/features/cards/translations/pt';
import { TRANSFERS_TRANSLATIONS_PT } from '@src/features/transfers/translations/pt';

import { translationsObject } from '../es/translations';
import { COMPONENTS_TRANSLATIONS_PT, WALLET_PT } from './partials';

const { countries } = TRANSLATIONS_PT_COMMON;

// TODO centralize all tyc links so that they are not repeated in more than one place
export const TRANSLATIONS_PT: translationsObject = {
  cards: CARDS_TRANSLATIONS_PT,
  components: COMPONENTS_TRANSLATIONS_PT,
  grain: {
    SOYA: 'Soja',
    SOYB: 'Soja',
    CORA: 'Milho',
    CORB: 'Milho',
    WHEA: 'Trigo',
    COFB: 'Café',
  },
  notFound: {
    title: '404 erro',
    subtitle: 'Tivemos um problema com esta página..',
    description:
      'Desculpe, a página que você está procurando não existe ou está quebrada. Estamos trabalhando para resolver esse problema.',
    imageDescription: 'Broken tractor',
  },
  shared: {
    backButton: 'Voltar atrás',
    btnLabel: 'Ir à página principal',
    required: 'Este campo é obrigatório',
  },
  transfers: TRANSFERS_TRANSLATIONS_PT,
  loans: LOANS_TRANSLATIONS_PT,
  credits: CREDITS_TRANSLATIONS_PT,
  endorsements: ENDORSEMENTS_TRANSLATIONS_PT,
  accountConfig: {
    accountEditText:
      'Lembre-se de que validaremos as novas informações antes de atualizá-las.',
    accountEditTitle: 'Tem certeza de que deseja alterar os dados bancários?',
    accountModification: 'Para alterações na conta bancária, contacte-nos em',
    alert: {
      supportText:
        'Até que os novos dados bancários sejam validados, a organização continuará operando com a última conta bancária validada pela Agrotoken',
      text: 'Alteração de dados bancários',
    },
    bannerText: 'Aqui você pode editar os dados bancários.',
    bannerTitle: 'Dados bancários de ',
    businessDetails: 'Dados da organização',
    cancelBtnLabel: 'Cancelar',
    confirmBtn: 'confirme',
    errors: {
      accountNumber: 'Este campo requer de 3 caracteres a un máximo de 20',
      accountType: 'Você deve selecionar uma opção',
      alias: 'Este campo requer pelo menos 3 caracteres',
      bankName:
        'Este campo deve conter apenas letras e pelo menos 3 caracteres',
      cbu: 'CBU inválido',
      empty: 'O campo é obrigatório e não pode ficar vazio',
      entityNumber: 'O número do banco não está correto',
      entityNumberBr: 'Este campo requer 3 caracteres',
      pix: 'O campo é obrigatório e não pode ficar vazio',
      subsidiary: 'Este campo requer so numeros e de 3 a 20 caracteres',
    },
    labelAccount: 'CBU',
    labelAccountNumber: 'Número da conta',
    labelAccountType: 'Tipo de conta',
    labelAgency: 'Código de agência',
    labelAlias: 'Alias',
    labelBankCode: 'Código do banco',
    labelBankEntity: 'Número da entidade bancária',
    labelBankName: 'Nome do banco',
    labelBtn: 'Guardar mudanças',
    labelBtnEdit: 'Editar dados bancários',
    labelEmail: 'Correio eletrônico',
    labelFirstName: 'Nome',
    labelLastName: 'Sobrenome',
    labelPhone: 'Número de telefone',
    labelPix: 'Pix',
    labelSelectOption: 'Selecione uma opção',
    mail: ' soporte@agrotoken.io ',
    personalDetails: 'Dados pessoais',
    placeHolderAccount: 'Digite o número da conta',
    placeHolderAccountType: 'Selecione o tipo de conta',
    placeHolderAgency: 'Ex. 02900001-00000000058382',
    placeHolderAlias: 'Digite o apelido',
    placeHolderBankCode: 'Ex.60746948',
    placeHolderBankName: 'Digite o nome do seu banco',
    placeHolderEmail: 'Digite seu e-mail',
    placeHolderEntityNumber: 'Digite o número do banco',
    placeHolderFirstName: 'Digite seu nome',
    placeHolderLastName: 'Digite seu sobrenome',
    placeHolderPhone: 'Coloque seu número',
    sectionText2: 'Aqui você pode editar os dados bancários',
    sectionTitle1: 'Dados gerais',
    sectionTitle2: 'Dados bancários de',
    text: 'Aqui você encontra as informações gerais que identificam a conta no Agrotoken.',
    title: 'Configurações da conta',
    WIPtext: 'Estamos trabalhando nesta seção, entre em contato',
    WIPTitle: 'Em construção',
  },
  bnplTermsAndConditions: {
    text: [
      '4.4 El Productor podrá utilizar sus Tokens para entregarlos en garantía del cumplimiento de las siguientes operaciones de crédito:',
      '4.4.1. El Productor podrá solicitar el producto “Préstamo”, en cuyo caso:',
      'a. El Productor y el Prestador de Servicios Financieros deberán acordar entre ellos en forma individual y por fuera del Sitio Web, los términos y condiciones de la asistencia crediticia. El otorgamiento de la referida asistencia será de exclusiva y excluyente competencia del Prestador de Servicios Financieros.',
      'b. Una vez acordados los términos con el Prestador de Servicios Financieros, Tech Demeter proveerá al Prestador de Servicios Financieros la información necesaria sobre los Tokens del Productor que serán  bloqueados en garantía de la operación de crédito, siendo que el Productor presta su total consentimiento a dicha divulgación de información al Prestador de Servicios Financieros a tales fines por parte de Tech Demeter.',
      'c. Si el Prestador de Servicios Financieros acepta la garantía ofrecida por el Productor y éste cumple con los demás requisitos de contratación requeridos por el Prestador de Servicios Financieros, ambos instrumentarán el contrato de préstamo y la garantía sobre los Tokens, utilizando los documentos que el Prestador de Servicios Financieros disponga para ello, lo cual deberá ser notificado a Tech Demeter.',
      'd. La garantía sobre los Tokens será programada en un Smart Contract, para que se retiren y bloqueen los respectivos Tokens de la billetera del Productor y queden alojados en una billetera Escrow.',
      'e. En caso de que el Productor abone el crédito de acuerdo a lo pactado con el Prestador de Servicios Financieros, llegada la fecha de vencimiento del Smart Contract, los Tokens afectados a la garantía serán devueltos a la billetera del Productor.',
      'f. También el Productor podrá pagar, ya sea al vencimiento del préstamo o por adelantado antes del vencimiento del préstamo, con pesos argentinos provenientes de la liquidación de los Tokens. En este caso se ejecutará el Smart Contract, y los Tokens serán desbloqueados, transfiriéndose (a) los Tokens que representen el saldo del préstamo de la billetera Escrow  a la billetera del Banco en Tech Demeter y (b) los Tokens excedentes serán devueltos automáticamente a la billetera del Productor en Tech Demeter.',
      'g. En caso de que el Productor incurra en cualquier causal de incumplimiento de la operación de crédito, y una vez que Tech Demeter reciba la notificación del Banco, se transferirán los Tokens correspondientes a la billetera del Banco abierta en Tech Demeter. Una vez que el Banco reciba el dinero, lo aplicará a la cancelación del crédito.',
      '4.4.2. El Productor podrá solicitar el producto “Compra ahora, Fija después” para financiar la compra de bienes o servicios, en cuyo caso:',
      'a. En la oportunidad señalada en 4.1. de estos Términos y Condiciones, y a los efectos de financiar la compra de bienes o servicios, el Productor podrá aceptar las condiciones de asistencia crediticia (tasa y plazo) ofrecidas por el propio Merchant ó por un Prestador de Servicios Financieros a través de la plataforma o la app de Tech Demeter.',
      'b. Una vez que el Productor acepte las condiciones del préstamo ofrecidas por el Merchant ó por el Prestador de Servicios Financieros a través de la plataforma de Tech Demeter, Tech Demeter procederá a bloquear en garantía los Tokens correspondientes, y se retirarán los mismos de la billetera del Productor quedando alojados en una billetera Escrow, lo cual será programado en un Smart Contract.',
      'Asimismo, Tech Demeter notificará al Merchant ó al Prestador de Servicios Financieros la información necesaria sobre los Tokens del Productor que fueron bloqueados en garantía del cumplimiento de la operación de crédito otorgada.',
      'c. El Productor podrá cancelar el préstamo a la fecha de vencimiento del mismo con la liquidación de los Tokens o podrá cancelar anticipadamente el préstamo mediante la liquidación de los Tokens en la fecha que elija el Productor, abonando los intereses acumulados a esa fecha. En este último caso, el Productor acepta y reconoce que al presionar el botón “Fijar Ahora” se enviará automáticamente al Oráculo, una instrucción de fijación de precio respecto de la cantidad de Tokens correspondientes para cancelar el préstamo otorgado por el Merchant ó por el Prestador de Servicios Financieros.',
      'd. El Productor también acepta y reconoce que al presionar el botón “Fijar Ahora” (además de fijar el precio de los granos con más el porcentaje de aforo) o a la fecha de vencimiento del préstamo, Tech Demeter ejerce el derecho de cobro del Boleto cedido oportunamente. En caso que el monto recibido de la liquidación sea mayor al monto efectivo de la operación, Tech Demeter transferirá el saldo resultante al CBU indicado por el Productor, dentro del plazo de 2 (dos) días hábiles.',
      'e. El monto de la liquidación de los Tokens correspondientes al préstamo otorgado, será acreditada en la cuenta del Merchant ó del Prestador de Servicios Financieros, en su caso.',
    ],
    title:
      'Terminos y condiciones particulares del producto Préstamos y Compra ahora, Fija después',
  },
  breadCrumb: {
    createPaymentInKind: 'Criação de registro de pagamento em espécie',
    createPaymentRequest: 'Criação de link de pagamento',
    home: 'Início',
    pay: 'Faça um pagamento',
    paymentRequestDetails: 'Detalhes de pagamento',
    paymentRequests: 'Pagamentos',
    tokenizations: 'Tokenizações',
  },
  btnAddLabel: 'Adicionar',
  btnDeleteLabel: 'Excluir',
  btnNextLabel: 'Próximo',
  btnNextStepLabel: 'Próximo passo',
  btnPrevLabel: 'Voltar',
  btnSaveLabel: 'Salvar',
  btnCancelLabel: 'Cancelar',
  viacepInvalid: 'O CEP inserido não é válido. Por favor tente novamente.',
  btnDeleteFile: 'Excluir arquivo',
  card: {
    title: 'A forma mais prática e segura de levar seus grãos pelo mundo.',
    subTitle:
      'Compre o que quiser com seus grãos, desde um café até um trator, usando seu cartão Visa financiado com agrotokens.',
    applyNow: 'Aplique agora',
    detail: {
      first: 'Você tem total liquidez da sua produção',
      second: 'Você sempre pode ficar em sua colheita',
      third: 'Compre o que quiser em mais de 80 milhões de lojas',
    },
    revisionCard: {
      userIsNotBusinessRepresentantTitle:
        'Você não pode solicitar um cartão, pois não é administrador de sua conta.',
      userIsNotBusinessRepresentantTitleDescription: '',
    },
    descriptionVideo: {
      itIsEasy: {
        title: 'É facil',
        description: 'Use sua produção tokenizada diretamente do seu cartão.',
      },
      whenAndWhere: {
        title: 'Quando e onde você quiser',
        description: 'Compre em mais de 80 milhões de lojas em todo o mundo.',
      },
    },
    shipmentCard: {
      title: '¿Para onde enviamos o cartão físico?',
      subtitle:
        'Deixe-nos os seus dados para que lhe possamos enviar o cartão.',
      form: {
        street: 'Rua',
        streetPlaceHolder: 'Entre na sua rua',
        streetNumber: 'Número da porta',
        streetNumberPlaceHolder: 'Digite o número da sua porta',
        apartment: 'Apartamento',
        apartmentPlaceHolder: 'Digite o seu apartamento',
        floor: 'Andar',
        floorPlaceHolder: 'Digite o seu andar',
        geographicalDivision: 'Província',
        geographicalDivisionPlaceHolder: 'Digite sua província',
        city: 'Cidade',
        cityPlaceHolder: 'Digite sua cidade',
        postalCode: 'Código postal',
        postalCodePlaceHolder: 'Coloque seu código Postal',
        applyFor: 'candidatar-se a',
        maybeLater: 'Talvez mais tarde',
        errors: {
          postalCode: 'Código postal errado',
          onlyNumbers: 'Este campo só aceita números.',
        },
      },
    },
    cardInMobile: {
      message:
        'Para uma melhor experiência, recomendamos que você entre pelo seu computador.',
      backButton: 'Voltar atrás',
    },
    cardRequested: {
      title: 'Você solicitou o cartão com sucesso',
      subtitle:
        'Nós lhe enviaremos um e-mail quando seu cartão estiver a caminho.',
      advertisingDescription: {
        title: 'Entretanto...',
        description:
          'Demos-lhe um cartão virtual para que o possa utilizar enquanto o físico chega, baixe a app para o visualizar',
      },
      shipmentDescription: {
        title: 'Dados de entrega do cartão físico:',
      },
    },
  },
  changeOrg: {
    member: 'membro da organização',
    members: 'membros da organização',
    newOrg: 'Criar uma nova organização',
    pendingChanges: 'Há dados pendentes para esta organização',
    selected: 'Selecionado',
    text: 'Clique em uma dessas organizações para fazer login',
    title: 'Selecione uma organização',
  },
  createOrg: {
    businessCuit: 'CUIT da empresa',
    businessCuitPlaceholder: 'Ej: 27-12345678-0',
    businessLabel: 'Business',
    businessName: 'Razão social',
    businessNamePlaceholder: 'Digite o nome da empresa',
    formErrors: {
      businessName:
        'Você deve incluir apenas letras e um mínimo de 3 caracteres',
      cuit: 'Você deve incluir apenas números e um mínimo de 11 caracteres',
      representantLastName:
        'Você deve incluir apenas letras e um mínimo de 3 caracteres',
      representantName:
        'Você deve incluir apenas letras e um mínimo de 3 caracteres',
    },
    individualLabel: 'Individual (Monotributista, Responsável Registrado)',
    representantLastName: 'Sobrenome do representante',
    representantLastNamePlaceholder: 'Digite seu sobrenome',
    representantName: 'Nome representativo',
    representantNamePlaceholder: 'Digite seu nome',
    subtitle: 'Que tipo de empresa você quer criar?',
    title: 'Dados gerais da empresa',
  },
  selectManagerOrg: {
    title: 'Gerenciamento de tokenização',
    supportingText:
      'Pesquise o usuário comercial que você gerenciará para ajudar o usuário com a tokenização.',
    searchBusinessUnit: {
      label: 'Pesquisar a business unit',
      placeholder: 'Ej: Agro SA',
    },
    searchBusinessUser: {
      label: 'Pesquisar business user',
      placeholder: 'Ej: Juan Perez',
    },
    confirm: 'Confirmar',
    noOptions: 'Não há resultados para a pesquisa',
  },
  createPayment: {
    alert:
      'Os tokens que você recebe serão automaticamente convertidos em reais e depositados em sua conta bancária.',
    footerText: {
      kind: 'Ao criar o registro de pagamento em espécie, concordo que li e entendi o seguinte',
      regular:
        'Ao criar o link de pagamento, aceito que li e compreendi o seguinte',
    },
    form: {
      amount: {
        title: 'Dados de pagamento',
        label: {
          kind: 'Valor do registro de pagamento em espécie',
          regular: 'Valor do link de pagamento',
        },
        error: 'O valor deve ser maior que zero',
        placeholder: {
          kind: 'Escreva o valor do registro de pagamento em espécie',
          regular: 'Escreva o valor do link de pagamento',
        },
        hintMessage: {
          fee: 'A taxa de {{amount}}% será deduzida deste valor.',
        },
        allowedTokens: 'O destinatário pode pagar com qualquer agrotoken.',
        automaticConversion:
          'Os tokens que você receber serão alterados para pesos automaticamente e serão depositados em seu CBU.',
      },
      asset: {
        title: 'Selecione o ativo',
        tokens: 'O destinatário pode pagar com qualquer agrotoken.',
      },
      comment: {
        hintMessage: 'Lembre-se que os pagamentos são sempre em',
        label: 'Conceito (opcional)',
        placeholder: 'Digite um conceito',
        subtitle: 'Digite um máximo de 90 caracteres.',
      },
      file: {
        label: 'Anexar arquivo (opcional)',
      },
      submitBtnLabel: {
        kind: 'Criar registro de pagamento em espécie',
        regular: 'Criar link de pagamento',
      },
      user: {
        title: 'Dados gerais',
        error: 'O CPF/CNPJ não existe',
        hintMessage: 'Usuário: ',
        label: {
          kind: 'Para quem você deseja enviar um registro de pagamento em espécie?',
          regular: 'Para quem você deseja enviar o link de pagamento?',
        },
        placeholder: 'Digite um número de CPF/CNPJ',
        serverError: 'Ocorreu um erro. Por favor tente novamente mais tarde.',
      },
      details: {
        title: 'Detalhes (Opcional)',
      },
      forwardings: {
        badge: {
          label: 'Nova funcionalidade',
          tooltip:
            'Essa funcionalidade permite encaminhar parte ou a totalidade dos tokens recebidos do pagamento para outras organizações, inserindo seu {{fiscalPrefix}}.',
        },
        checkbox: 'Encaminhar parte do pagamento para uma ou mais organizações',
        fiscalId: {
          label: '{{fiscalPrefix}} Organização #{{value, number}}',
          placeholder: 'Digite um número de {{fiscalPrefix}}...',
          businessFound: 'O {{fiscalPrefix}} inserido pertence a {{business}}',
          errors: {
            notFound:
              'O {{fiscalPrefix}} que você digitou não corresponde a nenhuma conta registrada. Verifique as informações e insira-as novamente.',
            unknown: 'Ocorreu um erro desconhecido',
            repeat:
              'O {{fiscalPrefix}} digitado é repetido nos usuários para encaminhar o pagamento',
            equalPayer:
              'O {{fiscalPrefix}} inserido corresponde ao destinatário do link de pagamento',
            equalCreator:
              'Você não pode enviar um link de pagamento para seu próprio {{fiscalPrefix}}. Insira um {{fiscalPrefix}} diferente para continuar.',
          },
        },
        amount: {
          label: 'Valor',
          placeholder: 'Insira um valor...',
          error:
            'O valor que você está tentando reenviar excede o valor total do link de pagamento. Verifique se o valor é menor que {{fiatCurrency}} {{amountToReceive, number}}',
        },
        concept: {
          label: 'Conceito (opcional)',
          placeholder: 'Digite um conceito...',
          hintText: 'Digite no máximo 90 caracteres.',
        },
        document: 'Anexo (opcional)',
        addUser: 'Adicionar outro usuário',
      },
    },
    title: {
      kind: 'Criar registro de pagamento em espécie',
      regular: 'Criar link de pagamento',
    },
  },
  dragAndDrop: {
    click: 'Clique aqui',
    drag: 'ou arraste o arquivo',
    emptyError: 'Este campo é obrigatório',
    format: 'Formatos permitidos:',
    maxSize: 'tamanho máximo:',
  },
  fileUpload: {
    click: 'Clique aqui',
    drag: 'ou arraste o arquivo',
    emptyError: 'Este campo é obrigatório',
    format: 'Formatos permitidos: {{format}} (tamanho máximo: {{maxSize}}MB)',
    buttons: { delete: 'Excluir arquivo', tryAgain: 'Tente novamente' },
  },
  dropdown: {
    changeOrg: 'Alterar organização',
    configuration: 'Configurações da conta',
    createNew: 'Criar nova organização',
    logOut: 'Fechar sessão',
    myAccount: 'Minha Conta',
    selectOrganization: 'Selecione organização',
  },
  fullOnboarding: {
    changeRequestedAlert: {
      text: 'Você tem alguns dados para revisar',
    },
    lvl_2: {
      countries,
      form: {
        nationality: {
          label: 'Nacionalidade',
          placeholder: 'Escolha sua nacionalidade',
        },
        address: {
          addressLabel: 'Endereço',
          businessAddressTitle: 'Endereço Fiscal da Empresa',
          cityLabel: 'Cidade',
          cityPlaceholder: 'Selecione uma cidade',
          complementLabel: 'Complemento',
          neighborhoodLabel: 'Bairro',
          postalCodeLabel: 'CEP',
          stateLabel: 'Estado',
          statePlaceholder: 'Estado',
          streetLabel: 'Rua',
          streetNumberLabel: 'Número',
          userAddressTitle: 'Endereço',
          legalRepresentativeAddressTitle: 'Endereço Representante Legal',
        },
        birthDate: {
          label: 'Data de nascimento',
          placeholder: 'Selecione a data',
        },
        CPF: {
          label: 'Digite seu CPF ',
        },
        errors: {
          required: 'Este campo é obrigatório',
          maxLength: 'Este campo tem no máximo {{max}} caracteres',
          onlyNumbers: 'Este campo aceita apenas números',
          cpf: 'O CPF deve ter 11 dígitos',
          motherName: 'Este campo deve conter pelo menos duas palavras',
          address: {
            defaultFormat:
              'Este campo aceita apenas letras, números, espaços, hífens, ponto e vírgulas',
            streetName:
              'Este campo aceita apenas letras, números, espaços, hífens, pontos, vírgulas, numerais, pontos duplos, ordinais ou aspas simples.',
            postalCodeCEP:
              'Este campo aceita apenas números, no formato XXXXXXXX (sem hífen)',
          },
        },
        motherName: { label: 'Nome da mãe' },
        pep: {
          label: 'Declaro sob juramento que:',
          no: 'Não sou uma pessoa politicamente exposta (PEP)',
          yes: 'Sou pessoa politicamente exposta (PEP)',
        },
        personIdDocument: {
          back: 'Adicione aqui uma foto do seu RG/CNH - Verso',
          front: 'Adicione aqui uma foto do seu RG/CNH - Frente',
        },
        representativeDocs: {
          description:
            'Envie a documentação que autoriza você a agir em nome de {{businessName}}. Você pode usar poder certificado por notário público, estatuto, ato de designação de autoridades ou contrato entre parceiros.',
          label:
            'Anexar procuração - faça o upload da documentação que autoriza você a agir em nome de {{businessName}}',
          title: 'Documentos do representante legal',
        },
        socialContract: {
          title: 'Contrato social',
          label: 'Anexar contrato social em nome de {{businessName}}',
        },
        viacep: {
          errors: {
            invalid: 'CEP inválido',
          },
        },
      },
      KYC: {
        title: 'Para começar a usar sua conta, precisamos de mais informações',
        subtitle:
          'Precisamos que você anexe algumas informações para validar sua identidade no Agrotoken.',
        fiscalId: {
          label: 'Digite seu CUIL/CUIT',
          placeholder: 'Digite seu CUIL/CUIT',
          error: 'O CUIT deve ter 11 dígitos.',
        },
        identification: {
          title: 'DNI:',
          back: 'Adicione aqui uma foto do seu RG/CNH - Verso',
          front: 'Adicione aqui uma foto do seu RG/CNH - Frente',
        },
        representativeDocs: {
          title: 'Documentação do representante:',
          subtitle:
            'Carregue a documentação que o autoriza a agir em nome da empresa. Você pode usar uma procuração certificada por um notário público, estatuto, ato de designação de autoridade ou contrato entre sócios.',
          label: 'Que documentação você usará para provar seu status legal?',
          options: {
            power: 'Poder',
            socialContractAndDesignationOfAuthorities:
              'Estatuto ou contrato social e acto de nomeação de autoridades',
          },
          powerLabel:
            'Anexar poder - Faça o upload da documentação que o autoriza a agir em nome da empresa',
          socialContractLabel: 'Anexar estatuto ou contrato social',
          designationOfAuthoritiesLabel:
            'Anexar ato de designação de autoridades',
        },
        fiscalAddress: {
          title: 'Endereço fiscal da empresa:',
        },
      },
      Level2CompletedModal: {
        description:
          'Levará aproximadamente entre 24 e 48 horas para concluir a validação das informações enviadas.',
        primaryAction: 'Continue completando seu perfil',
        secondaryAction: 'Voltar para a página principal',
        title: 'Você completou 2/3 etapas.',
      },
      subtitle: 'Por favor preencha e adicione as informações a seguir',
      title:
        'Você está há um passo de usar sua conta, precisamos agora validar sua identidade em nossa plataforma.',
    },
    common: {
      actions: {
        save: 'Salve',
        completeLatter: 'Completar mais tarde',
        accept: 'Li e concordo com os ',
        termsAndConditions: 'termos e condições',
        termsAndConditionsLink:
          'https://agrotoken.notion.site/Termos-e-Condi-es-Espec-ficas-de-Tokeniza-o-e-Transa-o-a2ecbb030bd7458097e3790c51850de2',
      },
    },
    multipleFileUploader: {
      addNewDocument: 'Carregar outro arquivo',
    },
  },
  general: {
    noResultsFound: 'Nenhum resultado encontrado',
    goBack: 'Voltar',
    accept: 'Li e concordo com os ',
    termsAndConditions: 'termos e condições.',
    termsAndConditionsLink:
      'https://agrotoken.notion.site/Termos-e-Condi-es-Gerais-b8ca9fde001f438e9e52ffbb42317a4c',
    form: {
      requiredMsg: 'Este campo é obrigatório',
      errors: {
        maxCharacters: 'Este campo aceita no máximo {{max}} caracteres',
        specialCharacters: 'Este campo no aceita caracteres especiais',
      },
    },
    grainContractTypes: {
      REAL: 'Grão entregue',
      VIRTUAL: 'Grão virtual',
      FUTURE: 'Grão futuro',
      FIXED_GRAIN: 'Grão fixado',
    },
  },
  generalError: 'Ocorreu um erro. Por favor tente novamente mais tarde.',
  grainContractDetails: {
    warrentyLabel: 'Garantia de',
    currencyLabel: 'Você receberá:',
    currencyText: 'Agrotokens ',
    futureCurrencyText: {
      SOYB: 'Garantia de Soja',
      CORB: 'Garantia de Milho',
      COFB: 'Garantia de Café',
    },
    fixedCurrencyText: {
      SOYB: 'Soja Fixada',
      CORB: 'Milho Fixado',
    },
    currency: 'Tipo de grão',
    oracle: 'Comprador do grão:',
    grainContractCondition: 'Condição dos grãos:',
    farmLabel: 'Fazenda',
    title: 'Detalhes de tokenização:',
    tons: 't.',
    tonsInCde: 'Toneladas na prova de existência:',
  },
  home: {
    banner: {
      btnLabel: 'Continuar com a verificação',
      text: 'Para que você possa ter acesso de 100% das funcionalidades da plataforma Agrotoken, é necessário continuar com a verificação da sua conta, no botão abaixo:',
      title:
        'Precisamos verificar alguns dados para você usar o Agrotoken ao máximo',
    },
    changeRequestBanner: {
      btnLabel: 'Revisar dados',
      text: 'Para que você possa ter acesso de 100% das funcionalidades da plataforma Agrotoken, é necessário continuar com a verificação da sua conta, no botão abaixo:',
      title:
        'Precisamos que você revise as informações enviadas para concluir a habilitação de sua conta',
    },
    header: {
      subtitle:
        'Desejamos boas vindas à Agrotoken. Aqui você pode ver um resumo de todos os seus ativos.',
      title: 'Olá',
    },
    qatarBanner: {
      btnLabel: 'Completar registro',
      btnLabelcard: 'Solicite o cartão',
      hashtag: 'COOL',
      text: 'Preenche seus dados e siga as etapas para participar do sorteio\nde uma viagem à Copa do Mundo da FIFA Qatar 2022™.',
      textHome:
        'Aproximando-se do Qatar 2022™. Preencha os detalhes da sua conta para tokenizar.',
      textOnboardingDone:
        'Você está a um passo de participar do sorteio. Solicite seu cartão virtual Agrotoken e pronto!',
      title: 'Com a Visa sendo #Agri',
      title2: 'tor é um movimento voador',
    },
    qatarModal: {
      checkboxError: 'Os termos e condições devem ser aceitos',
      checkboxLabel1a: 'Eu aceito o ',
      checkboxLabel1b: ' do cartão Visa Agrotoken',
      checkboxLabel2a: 'Eu aceito o ',
      checkboxLabel2b: ' do sorteio da FIFA Qatar 2022™',
      checkboxTermsAndConds: 'termos e Condições',
      labelBtnCancel: 'Talvez mais tarde',
      labelBtnConfirm: 'Mandar',
      termsAndConditionsVisaLink:
        'https://agrotoken.notion.site/Termos-e-Condi-es-Espec-ficas-de-Tokeniza-o-para-os-modelos-de-Gr-o-Produzido-e-Gr-o-em-Produ-o-a2ecbb030bd7458097e3790c51850de2',
      text: 'Solicite seu cartão e comece a participar do sorteio da Copa do Mundo Qatar 2022™',
      title: 'Solicite seu cartão',
    },
    qatarThankyouModal: {
      labelBtn: 'Voltar ao início',
      text: 'Você já está participando para poder viajar para a Copa do Mundo FIFA Qatar 2022™',
      title: 'Muito obrigado por solicitar seu cartão!',
    },
    shortcut: {
      payment: {
        linkLabel: 'Criar link de pagamento',
        text: 'Crie links de pagamento para cobrar com agrotokens.',
        title: 'Pagamentos',
      },
      title: 'Atalhos',
      tokenization: {
        linkLabel: 'Criar tokenizaçao',
        text: 'Tokenize seus grãos e veja o resumo de suas tokenizações históricas.',
        title: 'Tokenizações',
      },
    },
    wallet: {
      btnLabel: 'Vá para minha carteira',
      title: 'Resumo da carteira',
    },
    actives: 'Ativos',
    warranties: {
      showAll: 'Mostrar todas',
      title: 'Garantias',
      emptyTitle: 'Você não possui garantias ativas',
      emptySubtitle:
        // eslint-disable-next-line quotes
        "Clique na caixa de seleção 'Mostrar todas' para ver as inativas ou crie uma nova tokenização grão futuro.",
    },
    tokens: 'Tokens',
    warrantyCard: {
      badge: {
        excellent: 'Excelente',
        bad: 'Ruim',
      },
      subtitle: {
        amount: '{{amountSacas}} Sacas',
        blockedSacas: 'Sacas bloqueadas',
      },
      title: {
        SOYB: 'Garantia de Soja #{{requestNumber}}',
        CORB: 'Garantia de Milho #{{requestNumber}}',
        COFB: 'Garantia de Café #{{requestNumber}}',
      },
      expirationDate: 'Vence no {{expirationDate}}',
    },
    preTokenizedCard: {
      badge: 'Pendente',
      subtitle: {
        amount: '{{amount}} Sacas',
      },
      title: {
        future: {
          SOYB: 'Garantia de Soja #{{requestNumber}}',
          CORB: 'Garantia de Milho #{{requestNumber}}',
          COFB: 'Garantia de Café #{{requestNumber}}',
        },
        fixedGrain: {
          SOYB: 'Soja fixada #{{requestNumber}}',
          CORB: 'Milho fixado #{{requestNumber}}',
        },
      },
      cta: 'Continuar tokenização',
    },
    newTokenizationCard: {
      title: 'Nova tokenização',
      subtitle: 'Adicione um ativo à sua carteira',
      cta: 'Iniciar tokenização',
    },
    fixedGrainCard: {
      subtitle: {
        amount: '{{amountSacas}} Sacas',
      },
      title: {
        SOYB: 'Soja Fixada #{{requestNumber}}',
        CORB: 'Milho Fixado #{{requestNumber}}',
      },
      expirationDate: 'Vence no {{expirationDate}}',
    },
  },
  localCurrency: 'BRL',
  login: {
    form: {
      buttonLabel: 'Acesse a plataforma',
      email: {
        label: 'E-mail',
        placeholder: 'Digite seu e-mail',
      },
      invalidCredentialsMessage: 'Nome de usuário ou senha inválidos',
      password: {
        label: 'Senha',
        placeholder: 'Coloque sua senha',
      },
    },
    resetPasswordMessage: 'Recuperar senha',
    sideDescription: 'Solução integrada para toda cadeia do agronegócio.',
    sideTitle:
      'Somos um ecossistema que digitaliza o Agronegócio transformando commodities em um ativo digital.',
    signUpLabel: 'Crie uma agora',
    signUpMessage: 'Não tem uma conta?',
    subtitle: 'Informe seus dados para acessar sua conta em nossa plataforma.',
    title: 'Acesse sua conta',
  },
  navbar: {
    account: 'Minha conta',
    accountSettings: 'Configurações da conta',
    links: {
      home: 'Início',
      paymentRequests: 'Pagamentos',
      tokenizations: 'Tokenização',
      userAdmin: 'Usuários',
      wallet: 'Movimentos',
      card: 'Cartão',
      credits: 'Créditos',
      notifications: 'Notificações',
    },
    logout: 'Sair',
  },
  noAccessModal: {
    confirmBtn: 'Aceitar',
    textNoPermissions:
      'Infelizmente você não tem permissão para ver esta seção. Você pode entrar em contato com o criador da sua organização para obter mais informações',
    textNoPermissionUserSection:
      'Infelizmente você não tem permissão para realizar esta ação. Você pode entrar em contato com o criador da sua organização para obter mais informações',
    titleNoPermissions: 'Você não tem permissão para ver esta seção',
    titleNoPermissionUserSection:
      'Você não tem permissão para realizar esta ação',
  },
  notifications: {
    title: 'Todas as suas notificações',
    emptyState: {
      title: 'Você ainda não recebeu nenhuma notificação.',
      subtitle: 'Nós o informaremos quando alguém chegar.',
    },
    intlDateTime: '{{val, datetime}}',
    paymentRequest: {
      receivedPaymentLink: {
        title: 'Pagamento pendente',
        body: 'Você recebeu um link de pagamento de {{currency}} {{amount}}',
      },
      receivedPaymentLinkInKind: {
        title: 'Registro de pagamento em espécie pendente',
        body: 'Você recebeu um link de registro de pagamento em espécie de {{currency}} {{amount}}',
      },
      paymentLinkPaid: {
        title: 'Pagamento realizado com sucesso',
        body: 'Foi feito um pagamento de {{currency}} {{amount}}',
      },
      paymentLinkInKindPaid: {
        title: 'Registro de pagamento em espécie com sucesso',
        body: 'Foi feito um pagamento em espécie de {{currency}} {{amount}}',
      },
      paymentReceived: {
        title: 'Pagamento recebido com sucesso',
        body: 'Você recebeu um pagamento de {{currency}} {{amount}}',
      },
      paymentInKindReceived: {
        title: 'Registro de pagamento em espécie recebido com sucesso',
        body: 'Você recebeu um registro de pagamento em espécie de {{currency}} {{amount}}',
      },
      transactionDetails: 'Ver detalhe da transação',
    },
    tokenGifting: {
      receivedTokens: {
        title: 'Você recebeu seu benefício de {{senderName}}',
        body: 'Sua conta é creditada  {{tokenAmount}} {{tokenName}}',
      },
      sentTokens: {
        title: 'Você concedeu com sucesso o benefício a {{beneficiaryName}}',
        body: 'Recebido {{tokenAmount}} {{tokenName}}.',
      },
    },
  },
  onboardingLevelThree: {
    bankAccountInfo: {
      [ENationalities.BR]: {
        accountNumber: {
          errorMsg: 'Deve ter entre 3 e 20 dígitos',
          label: 'Numero de conta',
          placeholder: 'Ex. 02900001',
        },
        accountTypeId: {
          label: 'Tipo de conta',
          placeholder: 'Selecione o tipo de conta',
        },
        agencyCode: {
          errorMsg: 'Deve ter entre 3 e 8 dígitos',
          label: 'Código de agência (de 3 a 8 dígitos)',
          placeholder: 'Ex. 60746948',
        },
        bankCode: {
          errorMsg: 'Deve ter 3 dígitos',
          label: 'Código do banco (3 dígitos)',
          placeholder: 'Ex. 222',
        },
        bankName: {
          label: 'Nome do banco',
          placeholder: 'Ex. Banco do Brasil',
        },
        pix: {
          EMAIL: {
            label: 'Digite seu correio eletronico',
            placeholder: 'Ex. nome@mail.com',
          },
          PHONE_NUMBER: {
            label: 'Digite seu número de telefone',
            placeholder: '$$$$$$',
          },
          RANDOM_KEY: {
            label: 'Digite sua chave aleatoria',
            placeholder: 'Ex. fe0d16f8-4777-4e9f-8b5',
          },
          FISCAL_ID: {
            label: 'Digite seu número de CNPJ/CPF',
            placeholder: 'Ex. 89425826560',
          },
        },
        pixTypeId: {
          label: 'Tipo de chave Pix',
          placeholder: 'Selecione o tipo de chave PIX',
        },
        subtitle:
          'Carregue as informações bancárias onde você receberá o dinheiro para suas cobranças, benefícios e possíveis superávits originados das transações',
        title: 'Dados bancários',
      },
      [ENationalities.AR]: {
        alias: {
          label: 'Alias (opcional)',
        },
        cbu: {
          label: 'CBU',
          invalidCbuMessage: 'CBU inválido',
          cbuDocument: 'Anexar certificado CBU',
        },
        accountTypeId: {
          label: 'Tipo de cuenta',
        },
        bankName: {
          label: 'Nombre Entidad Bancaria',
        },
        subtitle:
          'Carga la información bancaria donde recibirás el dinero por tus cobros, beneficios y posibles excedentes originados a partir de las transacciones',
        title: 'Datos bancarios',
      },
    },
    generalInfo: {
      mainActivity: {
        label: 'Profissão',
        placeholder: 'Selecione sua profissão',
      },
      subtitle: 'Dados gerais subtitle $$$$',
      title: 'Dados gerais',
    },
    progress: {
      title: 'Minha conta',
      step1: '1. Dados gerais',
      step2: '2. Dados pessoais e tipo de empresa',
      step3: '3. Dados bancários e fiscais',
    },
    requiredMsg: 'Este campo é obrigatório',
    subtitle:
      'Precisamos que você preencha essas informações extras para começar a usar seus tokens.',
    title: 'Para começar a usar sua conta, precisamos ter mais informações',
    successModal: {
      primaryActionLabel: 'Aceitar',
      description:
        'Estamos validando suas informaçoes para que você possa começar a usar seus tokens. Isso levará cerca de 24 a 48 horas.',
      title: 'Parabéns! Você concluiu todas as etapas.',
      titleTwoSteps: 'Você concluiu 2/2 etapas.',
      titleThreeSteps: 'Você completou 2/3 etapas.',
    },
    backToMain: 'Retornar à página inicial',
    continueEdit: 'Continuar para completar seu perfil',
    finalBeneficiaries: {
      beneficiaryForm: {
        errors: {
          required: 'Este campo é obrigatório',
        },
        cantUpdate:
          'Se você deseja editar esta seção, entre em contato com operaciones@agrotoken.io via e-mail',
        title: 'Beneficiário final:',
        description: 'Você pode adicionar um ou mais beneficiários efetivos.',
        fields: {
          name: {
            label: 'Qual é o seu nome?',
          },
          surname: {
            label: 'Qual é o seu sobrenome?',
          },
          identificationValue: {
            label: 'DNI',
          },
          maritalStatus: {
            label: 'Estado civil',
            placeholder: 'Selecione seu estado civil',
          },
          nationality: {
            label: 'Nacionalidade',
            placeholder: 'Selecione sua nacionalidade',
          },
          address: {
            label: 'Endereço',
            errors: {
              maxLength: 'Este campo tem no máximo {{max}} caracteres',
            },
          },
          postalCode: {
            label: 'Código postal',
          },
          geographicalDivision: {
            label: 'Província',
          },
          city: {
            label: 'Cidade',
          },
          participationPercentage: {
            label: 'Porcentagem de participação',
            errors: {
              minLength:
                'A porcentagem de participação deve estar entre {{min}} e {{max}}',
              maxLength:
                'A porcentagem de participação deve estar entre {{min}} e {{max}}',
            },
          },
          managementPosition: {
            label: 'Posição de gestão',
            placeholder: 'Selecione uma posição de gestão',
          },
          pep: {
            label: 'Você é uma pessoa politicamente exposta?',
          },
          identificationTaxValue: {
            label: 'CUIT',
            error: 'O CUIT deve ter 11 dígitos.',
          },
        },
      },
      title: 'Declaro sob juramento que os beneficiários finais são:',
      description:
        'Entende-se por beneficiário final qualquer pessoa humana que detenha, direta ou indiretamente, pelo menos 10% do pacote acionário da empresa.',
    },
    mainActivity: {
      label: 'Atividade principal',
      placeholder: 'Selecione uma opção',
    },
    uifObligated: {
      label: 'Declaro sob juramento que:',
      yes: 'Sou um sujeito obrigado perante a UIF',
      no: 'Não sou um sujeito obrigado perante a UIF',
      uifDocumentLabel: 'Anexar certificado de registro na UIF',
    },
    merchantRequest: 'Eu quero ser Fornecedor.',
    merchantRequestDescription:
      'Como fornecedor, você tem Liquidez imediata, Simplicidade na coleta, Disponibilidade 24/7 e Menos intermediários.',
  },
  onboardingLevelThreeAccessModal: {
    confirmBtn: 'ir para casa',
    textNoPermissions:
      'Se você precisar alterar algo, entre em contato conosco em',
    titleNoPermissions: 'Esta organização já foi aprovada',
  },
  onboardingLevelTwoAccessModal: {
    confirmBtn: 'ir para casa',
    textNoPermissions:
      'Se você precisar alterar algo, entre em contato conosco em',
    titleNoPermissions: 'Este usuário já foi aprovado',
  },
  payment: {
    amount: {
      equals: 'Equivale à',
      label: 'Valor a pagar:',
    },
    asset: 'Em qual ativo você deseja efetuar o pagamento?',
    availableBalance: 'Saldo disponivel:',
    comment: 'Conceito de pagamento ou feedback.',
    defaultOption: 'Selecione uma opção...',
    expired: {
      btnLabel: 'Ir à página principal',
      text1:
        'Entre em contato com a pessoa que lhe enviou este link e solicite-o novamente.',
      title: 'Este link expirou',
    },
    insuficientBalance: 'Seu saldo é insuficiente:',
    payBtnLabel: 'Pagar',
    receiver: 'Destinatário:',
    title: 'Faça um pagamento',
    tooltip: 'Selecione uma moeda para efetuar o pagamento',
    unauthorized: {
      btnLabel: 'Ir à página principal',
      text1: 'Apenas o destinatário tem acesso a este link.',
      text2: 'Entre em contato com a pessoa que lhe enviou este link.',
      title: 'Este link não foi atribuído à sua conta',
    },
    summary: {
      title: 'Resumo:',
      totalToPay: 'Total a pagar:',
      totalToReceive: 'Total a receber:',
      details: 'Detalhamento:',
      localSubtotal: 'Total do link de pagamento:',
      subtotal: 'Subtotal',
      internalTax: 'Comissão de transação',
      internalTaxToolTip:
        'Os tokens que você receber serão debitados deste pagamento.',
      equivalence: 'Equivalência',
      equivalenceToolTip:
        'O valor dos tokens é apenas uma estimativa que pode mudar com base nas flutuações do mercado. O valor final será definido no ato do pagamento.',
      equivalenceInLocalCurrency: 'Equivalente em moeda local',
      receptionDateLabel: 'Você receberá o pagamento em:',
      warranty: {
        payerSuccessInfo:
          'O valor total do link de pagamento será cobrado no dia da liquidação da garantia.',
      },
      toast: {
        claimSuccess: {
          title: 'Você solicitou a liquidação com sucesso.',
          text: 'Enviaremos um e-mail quando o pagamento estiver em sua conta.',
        },
      },
      m2m: {
        totalForwarded: 'Total encaminhado',
        totalToForward: 'Total para encaminhar',
        totalReceivedAfterForward: 'Recebido após encaminhamento',
      },
    },
  },
  paymentFix: {
    breakdown: {
      comments: 'Conceito de pagamento ou comentários:',
      equivalence: 'Equivalência em moeda local:',
      from: 'De:',
      subtotal: 'Pagamento subtotal',
      to: 'O pagamento será recebido:',
    },
    fixButton: 'Preparar agora',
    information: {
      finishedTitle: 'Este link de pagamento foi completado',
      finishedSubtitle: 'O preço das tokens foi fixado em {{finalizedAt}}',
      title: 'Este link de pagamento está ativo',
      subtitle:
        'Você pode definir o preço de suas Tokens até {dia-esquerda} dias ({final-date}) a partir de agora.',
    },
    modal: {
      confirm: 'Confirme',
      title: 'Você tem certeza de que quer consertar?',
      text: 'Favor observar que ao confirmar o pagamento em tokens, o preço dos grãos representados pela ficha será automaticamente fixado pela Agrotoken.',
    },
    payBtnLabel: 'Pagar',
    receiver: 'Destinatario:',
    selectToken: '¿Con qué token querés realizar el pago?',
    summary: {
      collateral: 'Devolução da garantia',
      equivalence: 'Equivalência em BR',
      fee: 'Taxa de transação ({{fee}}%)',
      guaranteeTokens: 'Tokens colaterais:',
      interest: 'Taxa de juros acumulada',
      subtotal: 'Subtotal do link de pagamento (ARS {{amount}})',
      title: 'Fixação de tokens',
      totalFixed: 'Total fixo:',
      total: 'Total a ser fixado hoje:',
      subtitle:
        'Estas foram as tokens que você deixou como garantia no momento de confirmar o bloqueio.',
    },
    title: {
      kind: 'Seus tokens são garantidos por um link de pagamento.',
      regular: 'Seus tokens são garantidos por um link de pagamento.',
    },
    finalizedTitle: {
      kind: 'Você definiu com sucesso o preço do link de pagamento.',
      regular: 'Você definiu com sucesso o preço do link de pagamento.',
    },
    tooltips: {
      collateral:
        'Um buffer agrotokens é estabelecido para ser capaz de controlar a volatilidade dos preços. Leva em conta o custo do link de pagamento, comissão Agrotoken e do custo de financiamento.',
      interest:
        'Este é o custo no final do período. Se você pagar mais cedo, os juros acumulados até aquele dia serão calculados.',
      fee: 'Esta comissão é uma porcentagem que é calculada no subtotal do link de pagamento. Ela permanece fixa em fichas até o momento do pagamento, portanto, ao final do período pacificamos este valor.',
    },
    tonsToFix: {
      text: 'A aceitação da transação fixará ({{netAmount}} + {{reservedAmount}}) toneladas de {{token}}',
    },
    tyc: {
      text: 'Eu concordo com a ',
      link: 'Termos e condições especiais para este produto',
    },
    inBlockchain: {
      text: 'Este proceso puede tomar unos segundos.',
      title: 'Se está procesando la transacción en Blockchain.',
    },
  },
  paymentCreated: {
    breakdown: {
      amount: {
        kind: 'Valor da inscrição em espécie:',
        regular: 'Valor do link de pagamento:',
        warranty: 'Link de pagamento',
      },
      comments: 'Conceito ou comentários.',
      deposit: 'Serão depositados em:',
      equivalence: 'Equivalência em tokens',
      fee: 'Taxa de transação:',
      fiscalId: 'CNPJ',
      payer: 'Destinatário',
      tooltip: {
        equivalence: {
          kind: 'O destinatário poderá pagar com apenas um desses tokens em um valor que será fixado quando o link de registro de pagamento em espécie for aceito.',
          regular:
            'O destinatário poderá pagar apenas com um desses tokens em um valor que será fixado quando o link de pagamento for aceito.',
        },
        fee: 'Você será debitado do pagamento em tokens.',
      },
      total: 'Total a receber em',
      viewDoc: 'Ver anexo',
    },
    link: {
      copyLabel: 'Clique aqui para copiar este link',
      label: 'O link para acessar o pagamento é:',
      shareBtnLabel: 'Compartilhar',
      transactionLabel: 'Transação',
      whatsappBtnLabel: 'Envie por Whatsapp',
    },
    subtitle:
      'A pessoa para quem você enviar o link receberá uma notificação, você também pode compartilhar o link por e-mail ou whatsapp.',
    title: {
      kind: 'Seu link de registro de pagamento em espécie está pronto',
      regular: 'Link de pagamento criado com sucesso',
      warranty: 'Link de pagamento criado com sucesso',
      fixed: 'Link de pagamento criado com sucesso',
    },
  },
  paymentDetails: {
    amount: {
      payer: 'Quantidade a pagar:',
      receiver: 'Quantidade recebida:',
    },
    breakdown: {
      amount: {
        payer: 'Tokens transacionados:',
        receiver: 'Tokens recebidos:',
      },
      equivalence: 'Equivalência em moeda local:',
      fee: 'Taxa de transação:',
      linkAmount: {
        kind: 'Valor da inscrição em espécie:',
        regular: 'Valor do link de pagamento:',
      },
      sacas: 'Equivalência em SACAS:',
    },
    comments: 'Observações ou comentários:',
    date: 'Data:',
    equivalence: 'Equivale à',
    fiscalId: 'BRL:',
    status: 'Status:',
    subtitle: {
      completed: {
        kind: 'Seu pagamento foi confirmado.',
        regular: 'Seu pagamento foi confirmado.',
      },
      expired: {
        kind: 'Crie um novo registro de pagamento em espécie e envie-o novamente.',
        regular: 'Crie um novo link de pagamento e envie-o novamente.',
      },
      inProgress: {
        kind: 'Você receberá um e-mail quando receber o pagamento.',
        regular: 'Você receberá um e-mail quando receber o pagamento.',
      },
    },
    timeline: {
      completed: {
        kind: 'Pagamento em espécie recebido',
        regular: 'Pagamento recebido',
      },
      created: {
        kind: 'Pagamento em espécie criado',
        regular: 'Link de pagamento criado',
      },
      expired: 'O pagamento expirou',
      inProgress: {
        kind: 'Pagamento em espécie em andamento',
        regular: 'Pagamento em andamento',
      },
    },
    title: 'Link de pagamento',
    user: {
      payer: 'O pagamento virá para:',
      receiver: 'Recebido de:',
    },
  },
  paymentInKind: {
    amount: {
      equals: 'Equivale à',
      label: 'Valor a pagar:',
    },
    asset: 'Em qual ativo você deseja efetuar o pagamento?',
    availableBalance: 'Saldo disponivel:',
    comment: 'Conceito de pagamento ou feedback.',
    defaultOption: 'Selecione uma opção...',
    insuficientBalance: 'Seu saldo é insuficiente:',
    payBtnLabel: 'Pagar',
    receiver: 'Destinatário:',
    title: 'Faça um pagamento',
    tooltip: 'Selecione uma moeda para efetuar o pagamento',
    unauthorized: {
      text1: 'Este pagamento não foi lançado em sua conta.',
      text2: 'Apenas o destinatário tem acesso ao link de pagamento.',
    },
  },
  paymentRequestDetails: {
    farmer: {
      completed: {
        details: {
          amount: 'Valor enviado:',
          date: 'Fecha:',
          description: 'Observações ou comentários:',
          fee: 'Taxa de transação:',
          receiver: 'Destinatário:',
          status: 'Estado:',
        },
        subtitle: 'Seu pagamento foi confirmado e enviado com sucesso',
        title: 'Pagamento enviado',
      },
      inProcess: {
        details: {
          amount: 'Valor a pagar:',
          date: 'Fecha:',
          description: 'Observações ou comentários:',
          fee: 'Taxa de transação:',
          receiver: 'Destinatário:',
          status: 'Estado:',
        },
        subtitle:
          'Seu pagamento está em processo de envio. Você receberá um e-mail quando for confirmado',
        title: 'Seu pagamento está em andamento',
      },
    },
    merchant: {
      completed: {
        details: {
          amount: 'Valor recebido:',
          date: 'Fecha:',
          description: 'Observações ou comentários:',
          fee: 'Taxa de transação:',
          receiver: 'Destinatário:',
          status: 'Estado:',
        },
        subtitle: 'Seu pagamento foi confirmado e recebido com sucesso',
        title: 'Pagamento recebido',
      },
      inProcess: {
        details: {
          amount: 'Valor a receber:',
          date: 'Fecha:',
          description: 'Observações ou comentários:',
          fee: 'Taxa de transação:',
          receiver: 'Destinatário:',
          status: 'Estado:',
        },
        subtitle: 'O pagamento já foi feito e está em processo',
        title: 'Pagamento em andamento',
      },
      pending: {
        details: {
          amount: 'Valor a receber:',
          date: 'Fecha:',
          description: 'Observações ou comentários:',
          fee: 'Taxa de transação:',
          receiver: 'Destinatário:',
          status: 'Estado:',
        },
        link: {
          copyLabel: 'Clique aqui para copiar este link',
          label: 'O link para acessar o pagamento é:',
          shareBtnLabel: 'Compartilhar',
          transactionLabel: 'Transação',
          whatsappBtnLabel: 'Envie por Whatsapp',
        },
        subtitle:
          'A pessoa para quem você enviar o link receberá uma notificação, você também pode compartilhar o link por e-mail ou WhatsApp.',
        title: 'Seu link de pagamento está pronto',
      },
    },
    status: {
      bnplPending: 'Pendente',
      bnplCompleted: 'Concluído',
      completed: 'Concluído',
      expired: 'Expirado',
      inProgress: 'Em processo',
      pending: 'Pendente',
      created: 'Criado',
      notClaimable: 'Liquidez pendente',
      claimable: 'Claim disponível',
      claimed: 'Concluído',
    },
    timeline: {
      confirmed: 'Pagamento Confirmado',
      created: 'Link de pagamento criado',
      done: 'Pagamento realizado',
      inProcess: 'Pagamento em processo',
    },
    transactionBtnLabel: 'Ver transação em ',
    remainingTime: 'Expira em',
  },
  creditsPage: {
    emptyState: {
      title: 'Você não tem créditos',
      subtitle: 'Quando você tiver créditos, verá os detalhes aqui.',
    },
  },
  paymentRequestsPage: {
    summary: {
      amountReceived: 'Recebido',
      transactionsReceived: 'Transações recebidas',
    },
    table: {
      buttonGroup: {
        accepted: 'Aceitaram',
        all: 'Ver todos',
        expired: 'Expirado',
        pending: 'Pendentes',
      },
      columns: {
        amount: 'Data',
        date: 'Encontro',
        fee: 'Taxa de transação',
        receiver: 'Destinatário',
        status: 'Estado',
        tokens: 'Tokens',
        transactionType: 'Tipo de transação',
      },
      download: 'Baixar em CSV',
      emptyState: {
        subtitle:
          'Você verá os detalhes aqui assim que fizer seu primeiro link de pagamento',
        title: 'Você não tem nenhum link de pagamento',
      },
      loadingError: 'Erro ao carregar links de pagamento.',
      newPaymentRequestBtn: 'Criar link de pagamento',
      paginator: {
        next: 'Seguinte',
        previous: 'Anterior',
      },
      paymentInKindBtn: 'Criar registro de pagamento em espécie',
      search: 'Pesquisar por destinatário',
      subtitle:
        'Todos os seus links de pagamento criados aparecem no seguinte saldo',
      title: 'Links de pagamento',
      transactionType: {
        kind: 'Pagamento em espécie',
        regular: 'Link de pagamento',
      },
    },
    title: 'Links de pagamento',
  },
  paymentSent: {
    title: {
      kind: 'Registro de pagamento em espécie enviado com sucesso',
      regular: 'Pagamento realizado com sucesso',
    },
  },
  bnplPaymentSent: {
    title: {
      kind: 'Seus tokens são como garantia de pagamento.',
      regular: 'Seus tokens são como garantia de pagamento.',
    },
    transaction: 'Transação bem sucedida.',
    home: 'Voltar ao topo',
    balance: 'Ir ao meu balanço',
  },
  paymentsFeeModal: {
    bold: 'do valor da transação',
    btnLabel: 'Aceitar',
    text1:
      'Gostaríamos de informá-lo de que alteramos a taxa de transação. É agora',
    text2: {
      payer: 'e será exibido no momento do pagamento.',
      receiver: 'e será exibido quando o pagamento for criado.',
    },
    text3: {
      payer:
        'Se você tiver alguma dúvida, por favor entre em contato conosco em',
      receiver:
        'Se você tiver alguma dúvida, entre em contato com seu representante de vendas.',
    },
    title: 'Comissão para as transações',
  },
  payPaymentRequest: {
    amount: {
      equals: 'Equivale à',
      label: 'Valor a pagar:',
    },
    asset: 'Em qual ativo você deseja efetuar o pagamento?',
    availableBalance: 'Saldo disponivel',
    breakdown: {
      operationDetail: 'Detalhes da operação',
      generalData: 'Dados gerais',
      paymentDetails: 'Dados de pagamento',
      amount: 'Valor a pagar:',
      comments: 'Conceito:',
      equivalence: 'Valor do link de pagamento:',
      localEquivalence: 'Equivalencia em moeda local:',
      fee: 'Taxa de transação:',
      from: 'De:',
      linkAmount: {
        kind: 'Valor do registro de pagamento em espécie:',
        regular: 'Valor do link de pagamento:',
      },
      subtotal: 'Subtotal de pagamento',
      to: {
        paymentSent: 'Quem recebe o link de pagamento:',
        paymentMake: 'Quem recebeu o link de pagamento:',
      },
      warrantyLabel: 'Garantia:',
      warranty: 'Garantia #{{grainContractFutureId}}',
      fixedGrainLabel: 'Activo:',
      fixedGrain: {
        SOYB: 'Soja fixada #{{fixedGrainRequestNumber}}',
        CORB: 'Milho fixado #{{fixedGrainRequestNumber}}',
      },
      file: 'Arquivo anexado:',
      amountToResend: 'Valor para reenviar:',
      totalToForward: 'Total para encaminhar:',
      localCurrencyEquivalent: 'Equivalente em moeda local:',
      paymentMade: 'Pagamento realizado: {{date}}',
      paymentCreated: 'Criada: {{date}}',
      transactionType: 'Tipo de transação:',
      paymentWithForwarding: 'Pagamento com encaminhamento',
      amountToSend: 'Quantidade a enviar:',
      amountSent: 'Quantidade enviada',
      warrantyId: 'Garantia #{{warrantyId}}',
      whoForwardedThePayment: 'Quem encaminhou o pagamento:',
      transactionNumber: 'Número da transação:',
    },
    comment: 'Observações ou comentários:',
    defaultOption: 'Selecione uma opção...',
    emptyState:
      'Ao selecionar as condições, você poderá ver um cálculo do seu pagamento',
    emptyStateMerchant:
      'Ao inserir os dados correspondentes, você poderá visualizar um cálculo da criação do link de pagamento.',
    error: 'O destinatário não pertence a um coletor ativo',
    fiscalIdTitle: 'CUIT',
    fixingPeriod: '¿Qual o prazo máximo de fixação desse token?',
    inBlockchain: {
      text: 'Este processo pode demorar alguns segundos.',
      title: 'O pagamento Blockchain está sendo processado',
    },
    insuficientBalance: {
      kind: 'Você não tem tokens suficientes no pool:',
      regular: 'Saldo insuficiente:',
      warranty: 'Saldo insuficiente:',
    },
    messageBNPL:
      'No final do prazo, seus tokens colaterais serão automaticamente transacionados para a dívida correspondente ao link de pagamento, taxa de transação e custo de financiamento. Qualquer agrotokens restante será devolvido a você.',
    payBtnLabel: 'Pagar',
    payBtnLabelBNPL: 'Confirme',
    receiver: 'Destinatário:',
    selectToken: 'Com qual token você deseja efetuar o pagamento?',
    selectedAsset: 'Ativo a ser utilizado como pagamento:',
    summary: {
      collateral: 'Capacidade',
      equivalence: 'Equivalência em {{localCurrency}}:',
      fee: 'Comissão Agrotoken',
      guaranteeTokens: 'Tokens em garantia:',
      interest: 'Taxa de juros',
      sacas: 'Equivalência em SACAS:',
      subtotal: 'Subtotal',
      title: 'Resumo:',
      total: 'Total a pagar',
    },
    summaryBNPL: {
      collateral: 'Capacidade ({{fee}}%) (ARS {{amount}})',
      equivalence: 'Equivalência em ARS',
      fee: 'Comissão Agrotoken ({{fee}}%) (ARS {{amount}})',
      interest: 'Custo do financiamento ({{interest}}%) (ARS {{amount}})',
      subtotal: 'Subtotal do link de pagamento (ARS {{amount}})',
      title: 'Sumário',
      total: 'Tokens en colaterais:',
    },
    timeline: {
      completed: {
        kind: 'Pagamento em espécie concluído',
        regular: 'pagamento completo',
      },
      make: {
        kind: 'Fazer um pagamento em espécie',
        regular: 'Faça um pagamento',
      },
      sent: {
        kind: 'Pagamento em espécie enviado',
        regular: 'Pagamento enviado',
      },
    },
    title: {
      kind: 'Faça um registro do pagamento em espécie',
      regular: 'Faça um pagamento',
      warranty: 'Faça um pagamento com garantia',
    },
    tonsToFix: {
      text1: 'Será corrigido',
      text2: 'toneladas de',
      text3: 'correspondente à comissão Agrotoken.',
    },
    tonsToRegister: {
      text1: {
        kind: 'A aceitação da transação será registrada',
        regular: 'Aceitar a transação será corrigido',
      },
      text2: 'toneladas de',
    },
    tooltip: 'Selecione uma moeda para efetuar o pagamento',
    unauthorized: {
      text1: 'Este pagamento não foi lançado em sua conta.',
      text2: 'Apenas o destinatário tem acesso ao link de pagamento.',
    },
    isRedirecting: 'Estamos redirecionando você...',
  },
  rates: {
    title: 'Cotações:',
    isLoading: 'Carregando cotações...',
    isError: 'Ocorreu um erro ao carregar as cotações',
  },
  resetPassword: {
    email: {
      errors: {
        format: 'O formato de e-mail não está correto',
        required: 'Digite seu e-mail',
      },
      label: 'Digite seu e-mail',
      placeholder: 'exemplo@mail.com',
    },
    forcedResetSubtitle:
      'Para fazer o login, precisamos que você defina uma nova senha.',
    login: {
      link: 'Entre aqui',
      message: 'Se você já tem uma conta',
    },
    signup: {
      link: 'Cadastre-se',
      message: 'Não tem uma conta?',
    },
    submitButton: 'Recuperar senha',
    subtitle:
      'Enviaremos um e-mail com instruções sobre como recuperar sua senha.',
    title: 'Recuperar senha',
  },
  resetPasswordVerification: {
    newPasswordForm: {
      password: {
        errors: {
          pattern:
            'A senha deve ter pelo menos uma maiúscula, uma minúscula, um número e um símbolo',
          required: 'Insira uma senha',
        },
        label: 'Digite sua nova senha',
        placeholder: 'Digite sua nova senha',
      },
      passwordConfirmation: {
        errors: {
          match: 'A confirmação não corresponde à senha',
        },
        label: 'Confirme sua nova senha',
        placeholder: 'Confirme sua senha',
      },
      submit: 'Definir nova senha',
      subtitle:
        'Estabeleci uma nova senha para poder entrar na plataforma Agrotoken',
      title: 'Nova senha',
      verificationCode: {
        errors: {
          invalid: 'Código de confirmação inválido',
        },
      },
    },
    subtitle:
      'Por favor, marque sua caixa e digite o código de 6 dígitos para continuar',
    title: 'Enviamos um e-mail para que você possa verificar sua conta.',
  },
  selectBusinessPage: {
    placeholder: 'Selecione seu firma',
  },
  sendTokensToRipio: {
    step1: {
      firstMessage: 'Estendi a vida útil de seus tokens para enviá-los',
      subtitle: 'Nova funcionalidade',
      text: 'Para que seus tokens existam fora da rede Agrotoken, precisamos convertê-los em tokens sem vencimento. Para isso, teremos que lançar o teste de reserva de grãos para que seus tokens sejam sempre apoiados por uma tonelada de grãos.',
      title: 'Enviar Agrotokens',
    },
  },
  serverError: {
    text: 'Estamos com problemas em nosso servidor, tente novamente mais tarde.',
    title: 'Ops, algo está errado!',
  },
  signUp: {
    form: {
      business: {
        label: 'Razão social',
        placeholder: 'Digite o nome da firma',
      },
      buttonLabel: 'Criar uma conta',
      confirmPassword: {
        label: 'Confirme sua senha',
        placeholder: 'Digite a senha novamente',
      },
      cuit: {
        label: 'CPF/CNPJ',
        placeholder: 'Digite seu CPF/CNPJ',
      },
      email: {
        label: 'Email',
        placeholder: 'Digite seu e-mail',
      },
      errorMessages: {
        DefaultError: 'Ocorreu um erro, por favor, tente novamente',
        InvalidConfirmPassword: 'Senha e confirmação não coincidem',
        InvalidPasswordException:
          'A senha deve ter pelo menos 8 caracteres, maiúsculas e minúsculas',
        UnfilledFields: 'Faltam campos para preencher',
        UsernameExistsException: 'Já existe uma conta com o email fornecido',
      },
      password: {
        hintMessage: 'Pelo menos 8 caracteres, maiúsculas e minúsculas',
        label: 'Senha',
        placeholder: 'Coloque sua senha',
      },
    },
    loginLabel: 'Entre aqui',
    loginMessage: 'Você já tem uma conta?',
    subtitle:
      'Preencha este formulário para fazer parte de uma nova dimensão do agronegócio',
    title: 'Criar uma conta',
  },
  signUpSuccess: {
    btnLabel: 'Clique aqui e accese a sua conta',
    subtitle: '',
    title: 'Obrigado, sua conta foi criada com sucesso!',
  },
  softOnboarding: {
    emailConfirmation: {
      confirmationSubtitle:
        'Se você já verificou a caixa de spam e não recebeu nada, clique no botão "Reenviar código de verificação" e reenviaremos o e-mail.',
      confirmationTitle: 'Não recebeu o email?',
      emailLabel: 'Qual é o seu endereço de e-mail?',
      next: 'Verificar',
      resend: 'Reenviar código de verificação',
      subtitle:
        'Por favor verifique seu e-mail e informe o código verificador de 6 dígitos para continuar.',
      title: 'Enviamos um e-mail para que você possa verificar sua conta.',
    },
    signUpScreen: {
      companyTitle: 'Dados gerais pessoa jurídica',
      form: {
        accept: 'Aceito os ',
        businessAlreadyRegisteredError: {
          [EBusinessStructureTypes.COMPANY]: 'Este CNPJ já foi registrado',
          [EBusinessStructureTypes.PERSON]: 'Este CPF já foi registrado',
        },
        companyName: 'Nome da razão social',
        companyNamePlaceholder: 'Digite a razão social',
        companyRepresentativeLastName: 'Sobrenome do representante',
        companyRepresentativeLastNamePlaceholder: 'Digite seu sobrenome',
        companyRepresentativeName: 'Nome do representante',
        companyRepresentativeNamePlaceholder: 'Digite seu nome',
        country: 'Escolha o seu país',
        createAccount: 'Crie sua conta',
        email: 'E-mail',
        emailError: 'Endereço de email invalido',
        emailPlaceholder: 'Digite seu E-mail',
        fiscalIdType: {
          [EBusinessStructureTypes.COMPANY]: {
            AR: 'CUIT',
            BR: 'CNPJ',
          },
          [EBusinessStructureTypes.PERSON]: {
            AR: 'DNI',
            BR: 'CPF',
          },
        },
        fiscalIdTypePlaceholder: {
          [EBusinessStructureTypes.COMPANY]: {
            AR: 'Ex: 33246963281',
            BR: 'Ex: 84619302754821',
          },
          [EBusinessStructureTypes.PERSON]: {
            AR: 'Digite seu DNI',
            BR: 'Digite seu CPF',
          },
        },
        fiscalIdValidationError: {
          [EBusinessStructureTypes.COMPANY]: {
            AR: 'O CUIT deve ter 11 dígitos.',
            BR: 'O CNPJ deve ter 14 dígitos.',
          },
          [EBusinessStructureTypes.PERSON]: {
            AR: 'O DNI deve ter entre 7 ou 8 dígitos.',
            BR: 'O CPF deve ter 11 dígitos.',
          },
        },
        lastName: 'Sobrenome',
        lastNamePlaceholder: 'Digite seu sobrenome',
        name: 'Nome',
        namePlaceholder: 'Digite seu nome',
        password: 'Escolha sua senha',
        passwordError:
          'A senha deve ter pelo menos 8 caracteres:\n\t\t\t• Pelo menos um número, uma minúscula e uma maiúscula e pelo menos um caractere especial (@ ! # $ % ^ & + =).\n\t\t\t• Não inclua espaços.',
        passwordPlaceholder: 'Escolha sua senha',
        phone: 'Telefone',
        phoneHelper: '',
        phonePlaceholder: '+54 123-123-1234',
        requiredError: 'Este campo é obrigatório',
        termsAndConditions: 'termos e condições',
        termsAndConditionsError:
          'Você deve aceitar os termos e condições de uso.',
        termsAndConditionsLink:
          'https://agrotoken.notion.site/Termos-e-Condi-es-Gerais-b8ca9fde001f438e9e52ffbb42317a4c',
        typeOfProducer: {
          company: 'Conta empresa',
          individual: 'Conta pessoal',
          question: 'Selecione o tipo de conta que deseja criar',
        },
        verifyPassword: 'Repita sua senha',
        verifyPasswordError: 'As senhas não correspondem. tente novamente.',
        verifyPasswordPlaceholder: 'Repita sua senha',
      },
      individualTitle: 'Dados gerais pessoa física',
      subtitle: 'Insira os dados gerais da sua empresa',
    },
  },
  specialLogin: {
    title: 'convidou você para fazer parte de sua organização',
    onboardingPushTile: 'Agrotoken o convidou para participar da organização',
  },
  specialSignUp: {
    acceptance: 'Eu aceito o',
    form: {
      error: {
        confirmPassword: 'Senha e confirmação não coincidem',
        empty: 'O campo não pode ficar vazio',
        name: 'Deve conter pelo menos 3 caracteres e apenas letras',
        password:
          'A senha deve ter pelo menos 8 caracteres:\n\t\t\t• Pelo menos um número, uma minúscula e uma maiúscula e pelo menos um caractere especial (@ ! # $ % ^ & + =).\n\t\t\t• Não inclua espaços.',
        surname: 'Deve conter pelo menos 3 caracteres e apenas letras',
      },
      hintMessage: {
        password:
          'A senha deve ter pelo menos 8 caracteres:\n\t\t\t• Pelo menos um número, uma minúscula e uma maiúscula e pelo menos um caractere especial (@ ! # $ % ^ & + =).\n\t\t\t• Não inclua espaços.',
      },
    },
    label: {
      btnNext: 'Seguindo',
      confirmPassword: 'Confirme sua senha',
      email: 'Mail',
      name: 'Nome',
      organization: 'Organização',
      password: 'Coloque sua senha',
      surname: 'Sobrenome',
      phone: 'Telefone',
    },
    placeholder: {
      confirmPassword: 'Insira uma senha',
      name: 'Digite seu nome',
      password: 'Insira uma senha',
      surname: 'Digite seu sobrenome',
    },
    termsAndCons: 'termos e condições',
    termsAndConsError: 'Você deve aceitar os termos e condições de uso',
    text: 'Insira as informações básicas para criar uma conta no Agrotoken',
    title: 'Você foi convidado para fazer parte da Agrotoken',
  },
  swap: {
    emptyState: {
      subtitle:
        'Estamos trabalhando em uma nova funcionalidade para que você possa trocar seus tokens.',
      title: 'Em breve',
    },
  },
  termsAndCons: 'Termos e Condições',
  timeline: {
    assignmentNotificationStep: 'Aviso de Cessão',
    docVerificationStep: 'Verificação pendente',
    firstStep: 'Prova de origem',
    firstStepFutureGrain: 'Origem do grão',
    inProgressBlockchainStep: 'Em processo (Blockchain)',
    offerLetterStep: 'Assinatura da carta de oferta',
    secondStep: 'Prova de existência de grãos',
    secondStepFutureGrain: 'Previsão de produção',
    summaryStep: 'Resumo da tokenização',
    thirdStep: 'Prova de liquidez',
    thirdStepFutureGrain: 'Contrato de compra e venda',
    title: 'Progresso',
    tokenizationCompletedStep: 'Tokenización completada',
  },
  toasts: {
    businessSwitch: {
      success: {
        text: 'A organização foi alterada para {{businessName}} para que você possa revisar os dados solicitados',
        title: 'Você tem alguns dados para revisar?',
      },
    },
    destokenization: {
      error: {
        text: 'Não foi possível criar a destokenização. Tente novamente mais tarde',
        title: 'Desculpe, ocorreu um erro',
      },

      success: {
        text: 'Você criou uma nova destokenização com sucesso, veja os movimentos para mais informações',
        title: 'Uma destokenização bem-sucedida foi criada',
      },
    },
    newTokenization: {
      newFarmTitle: 'Fazenda adicionada com sucesso',
    },
    renovation: {
      error: {
        text: 'Não foi possível renovar sua tokenização. Tente novamente mais tarde',
        title: 'Desculpe, ocorreu um erro',
      },
      success: {
        text: 'Você renovou a data de expiração da sua tokenização',
        title: 'A tokenização foi renovada com sucesso',
      },
    },
    serverError: {
      text: 'Estamos teniendo problemas en nuestro servidor. Por favor intentá nuevamente.',
      title: 'Ocurrió un error',
    },
  },
  tokenization: {
    backButton: 'Voltar',
    cdaNumber: 'Número CDA do armazém',

    confirm: {
      btnLabel: 'Enviar solicitação',
      futurePendingText:
        'Estamos analisando as informações e retornaremos em até 24h após a solicitação de tokenização.',
      actionFooterBtnLabel: 'Enviar solicitação de tokenização',
      actionFooterLink: 'Terms and Conditions',
      actionFooterText:
        'By submitting the tokenization request, I agree that I have read and understood the following',
      alertLink: 'margem de segurança',
      alertText:
        'Assim que a solicitação de tokenização for enviada, revisaremos os termos de negociação do contrato de compra para determinar a ',
      alertText2: ' apropriado e confirmar quantos tokens você receberá.',
      mutateError: {
        alreadySent: 'Erro! Este pedido já foi enviado anteriormente.',
        invalidTokenization:
          'Erro! As toneladas a serem tokenizadas devem ser menores ou iguais às inseridas no documento.',
      },
      termsAndConds: {
        footer:
          'Estes Termos e Condições Específicas de Tokenização e Transação foram atualizados pela última vez em 18 de novembro de 2022.',
        p1: '1. Definições: Todos os termos incluídos nestes termos e condições específicos de tokenização e transação (estes “Termos”) utilizados com iniciais em maiúsculas e que não possuem uma definição específica nestes Termos, terão o significado atribuído a eles nos termos e condições gerais, conforme publicados no website www.agrotoken.io (respectivamente, “Termos e Condições Gerais” e “Site”).',
        p2: '2. Processo de tokenização',
        p3: '3. Processo de queima do Token',
        p4: '4. Processo de uso de tokens',
        p11: '1.1. Ticket: É o contrato, pedido, título de crédito ou outro instrumento representativo de venda, permuta ou promessa, inclusive de entrega futura ou qualquer outro documento de características semelhantes, sendo que, dentre estes, os principais documentos utilizados serão as Cédulas de Produto Rural, conforme reguladas pela Lei nº 8.929, de 22 de agosto de 1994, conforme alterada, e os contratos de compra e venda de produtos agropecuários com previsão de entrega futura (todas as hipóteses, indistintamente como o "Ticket") assinado pelo Produtor e/ou entre o Produtor e o Oráculo cuja cópia tenha sido incorporada ao Site ou aplicativo pelo Produtor e cujos direitos de cobrança sejam atribuídos ou por qualquer meio transferidos à Agrotoken, na garantia do cumprimento das obrigações assumidas no Pedido de Tokenização feito pelo Produtor.',
        p12: '1.2. Grãos Tokenizáveis: Estes são os grãos entregues ou a serem entregues pelo Produtor ao Oráculo, suscetíveis de serem representados digitalmente (tokenizados), e podem consistir em grãos de soja, milho ou trigo. Agrotoken é a entidade que define os tipos de grãos que podem ser tokenizados.',
        p13: '1.3. Grãos Tokenizados: Estas são as unidades de Tokens representativas da quantidade de grãos que foram tokenizados de acordo com o Pedido de Tokenização, e podem consistir em tokens de soja (SOYB), milho (CORB) ou trigo (WHEB).',
        p14: '1.4. HUB: É a pessoa jurídica (com um único CNPJ) que atua em uma transação com os papéis da Comerciante e do Oráculo simultaneamente, sendo que os Tokens usados pelo Produtor para transacionar com a referida pessoa jurídica que atua sob o papel de Comerciante foram emitidos com base em um Ticket mantido com a mesma pessoa jurídica em seu papel de Oráculo.',
        p15: '1.5. Preço do Token: A Agrotoken estabelecerá o valor de cada Token emitido com base no preço diário das fixações de grãos disponíveis e que será publicado no Site.',
        p16: '1.6. Comerciante: É uma empresa de comércio de bens e/ou serviços, devidamente cadastrados como tal no Site, com relação ao qual o Produtor adquirirá um ou mais bens e/ou serviços e os pagará com Tokens.',
        p17: '1.7. Oráculo: É um coletor ou exportador de grãos que assinou um Ticket com um Produtor ou titular de um Ticket emitido pelo Produtor.',
        p18: '1.8. Provedor de Serviços Financeiros: Refere-se a qualquer entidade, financeira ou não, que tenha um acordo com a Agrotoken para receber Tokens como garantia para empréstimos comerciais concedidos a um produtor.',
        p19: '1.9. Produtor: É o Usuário que assinou um Ticket e/ou o emitiu, transferiu ou celebrou com um Oráculo e que atribuiu à Agrotoken seus direitos oriundos da comercialização dos Grãos.',
        p21: '2.1. Para prosseguir com o processo de tokenização, o Produtor deve anteriormente ter assinado um Ticket cuja cópia tenha sido incorporada ao Site ou aplicativo e cujos direitos de cobrança sejam atribuídos ou por qualquer meio transferidos à Agrotoken, na garantia do cumprimento das obrigações assumidas no Pedido de Tokenização. ',
        p22: '2.2. Para iniciar o processo, o Produtor deve realizar o processo de onboarding no Site ou aplicativo e, em seguida, inserir através da seção "Tokenização" e preencher o formulário solicitação de tokenização, incluindo o upload (a) dos instrumentos que evidenciem a existência o existência futura dos Grãos Tokenizáveis, aceitos a critério da Agrotoken e (b) do Ticket correspondente.',
        p23: '2.3. O Produtor deve assinar eletronicamente a carta de oferta de cessão dos direitos de cobrança do Ticket através do serviço Docusign integrado ao Site (a "Oferta de Cessão"), indicando a quantidade de toneladas a serem tokenizadas e fixando o prazo para o qual solicita a Tokenização. A Agrotoken só emitirá Tokens para uma porcentagem dos Grãos Tokenizáveis, reservando outra porcentagem a ser definida dependendo do Produtor e do que ele concordou anteriormente no respectivo Ticket com o Oráculo, como uma capacidade de atender às despesas e impostos ("Grãos Reservados"). Essas porcentagens podem variar a critério exclusivo da Agrotoken.',
        p24: '2.4. A Agrotoken prosseguirá, após a validação pelo Oráculo, para emitir (mineração) através de contratos inteligentes os respectivos Tokens e depositá-los na carteira virtual ou carteira correspondente ao Produtor. Esta carteira virtual será aberta e gerenciada pela Agrotoken, de modo que o Produtor que decidir tokenizar aceita expressamente esta abertura e gerenciamento de sua conta tokens.',
        p25: '2.5. O período máximo para o qual a tokenização pode ser solicitada será o prazo máximo de cada Ticket aplicável.',
        p26: '2.6. A aceitação da Oferta de Cessão pela Agrotoken ocorrerá através do credenciamento dos Tokens na carteira virtual do produtor aberta e gerenciada pela Agrotoken. O Oráculo receberá da Agrotoken a notificação da cessão nos termos da legislação em vigor.',
        p27: '2.7. Com isso, o processo de tokenização é concluído e os Grãos Tokenizáveis tornam-se Grãos Tokenizados.',
        p28: '2.8. Se o prazo para o qual a Tokenização foi solicitada for menor do que o prazo do Ticket, o Agrotoken enviará um e-mail ao Produtor oferecendo a renovação da Tokenização até o final do prazo do Ticket. A expressão de vontade a este respeito pode ser feita pelo Produtor selecionando a opção correspondente no Site, recebendo ao Oráculo da Agrotoken uma notificação da cessão nos termos da legislação em vigor informando a renovação da Oferta de Cessão.',
        p31: '3.1. A queima do Token pode ser automática, ou seja, no vencimento do prazo para o qual o Pedido de Tokenização foi emitido ou pode ser antecipado, ou seja, a pedido do Produtor antes do término do prazo para o qual o Pedido de Tokenização foi feito.',
        p32: '3.2. Queima automática: Em caso de expiração do termo para o qual o Pedido de Tokenização foi emitido, a Agrotoken passará a resgatar (queimar) os Tokens não usados e encerrar a cessão dos direitos de cobrança do Ticket, notificando o Oráculo.',
        p33: '3.3. Queima antecipada: O Produtor pode resgatar os Tokens com antecedência no todo ou em parte, selecionando a opção solicitação de queima antecipada no Site.  Uma vez recebido o referido Pedido de Queima Antecipada, a Agrotoken deve processá-lo e dentro de 2 dias úteis deve resgatar os Tokens, e informar ao Oráculo que os Grãos Tokenizados foram lançados e que a cessão dos direitos de cobrança do Ticket respectivo foi encerrada.',
        p34: '3.4. O Produtor concorda expressamente que a Queima Antecipada será realizada pela Agrotoken de forma automática e totalmente -sem a necessidade de qualquer notificação ao Produtor-, caso a Agrotoken  decida por razões de mercado ou qualquer outro motivo, bem como tomar conhecimento, por qualquer meio, que o Produtor e/ou o Oráculo tenha iniciado ou iniciará um processo preventivo de falência dos credores do mesmo e/ou seja informado, por qualquer meio, iniciou um pedido de falência e/ou recuperação judicial ou extrajudicial, independentemente da jurisdição em que tais circunstâncias ocorram.',
        p35: '3.5. No caso de o Produtor ter entregue seus Tokens ao Provedor de Serviços Financeiros, como garantia de cumprimento das operações de crédito, conforme previsto na cláusula 4.4. prevista abaixo, e no caso de o Produtor não cumprir com as obrigações assumidas com o Provedor de Serviços Financeiros ou que o Produtor decida cancelar o empréstimo concedido através dos Tokens dados como garantia, no vencimento ou com antecedência, o Provedor de Serviços Financeiros pode solicitar que a Agrotoken desbloqueie os Tokens incluídos no Contrato Inteligente e os transfira para a carteira do Provedor de Serviços Financeiros aberta na plataforma digital da Agrotoken para o valor correspondente, e automaticamente realize sua eliminação (procedendo ao exercício dos direitos de cobrança correspondentes ao Ticket antes do Oráculo em nome e ordem do Provedor de Serviços Financeiros).',
        p41: '4.1. O Produtor poderá utilizar seus Tokens para a compra de bens e/ou serviços oferecidos por um Comerciante devidamente cadastrado no Site, bem como para o cancelamento de uma dívida que o Produtor tem com um Comerciante para uma compra de bens e/ou serviços feitos anteriormente. Tal transação é uma transação particular entre um produtor individual e um comerciante individual, não relacionado à Agrotoken. Para este efeito, o seguinte procedimento deve ser seguido:',
        p41a: 'a) O Produtor e o Comerciante devem concordar bilateralmente e fora do ambiente do Site, o objeto da transação e o preço que se destina a ela, ou - quando for apropriado- o valor da dívida a ser cancelada. Para isso, o Produtor terá no site a lista de Comerciantes com os respectivos dados de contato (site, e-mail e telefone).',
        p41b: 'b) Uma vez acordados os termos, o Comerciante deve gerar um link de pagamento ou codigo QR no Site e enviá-lo ao Produtor através dele. Este link conterá o valor em Reais que o Produtor deve pagar ao Comerciante pelo bem ou serviço contratado. Para tais finalidades, o Produtor será notificado de que recebeu o link e terá um prazo peremptório de 30 (trinta) minutos para efetuar o pagamento, após o qual o vínculo será nulo e cancelado.',
        p41c: 'c) Uma vez que o link seja aberto pelo Produtor, será exibida uma tela na qual o valor em Reais, a quantidade de Tokens que devem ser transferidos para o Comerciante e a quantidade de Grãos envolvidos na transação serão indicados. O preço a que o cálculo é feito é fixado com base no valor do Grão em questão publicado no Site.',
        p41d: 'd) O Produtor aceita e reconhece que, pressionando o botão "Pagar", uma instrução de preços será automaticamente enviada ao Oráculo sobre a quantidade de grãos envolvidos na transação mais a proporcional parcela dos grãos reservados no momento da tokenização.',
        p41e: 'e) O Produtor também aceita e reconhece que ao pressionar o botão "Pagar", além de definir o preço dos Grãos (mais a porcentagem da capacidade), a Agrotoken exerce o direito de recolher o Ticket atribuído em tempo hábil. Caso o valor recebido do assentamento seja maior do que o valor real da operação, a Agrotoken transferirá o saldo resultante para a conta indicada pelo Produtor, de sua titularidade, no prazo de 2 (dois) dias úteis.',
        p41g: 'g) Uma vez que o botão "Pagar" tenha sido pressionado e confirmado, os Tokens são automaticamente transferidos da carteira virtual do Produtor, abertos e gerenciados pela Agrotoken, para a carteira virtual do Comerciante, também abertos e gerenciados pela Agrotoken, e disseram que os Tokens serão adquiridos diretamente pela Agrotoken e transferindo o valor correspondente para a conta do Comerciante.',
        p41h: 'h) Caso um HUB gere através do Site o link "Registro de pagamento em espécie" e seja aceito pelo Produtor, este último deve informar ao HUB da quantidade de toneladas a serem transacionadas. Esta transação é uma transação particular entre um produtor individual e um HUB individual, fora da Agrotoken, este último sem qualquer responsabilidade em relação à transação comercial entre o Produtor e o HUB. Após aceitar o link de registro de pagamento em espécie pelo produtor, o HUB será notificado de que a cessão dos direitos de cobrança do Ticket - que foi devidamente notificado em sua capacidade como Oráculo - foi parcialmente encerrada bilateralmente, em relação aos Grãos Tokenizados para o equivalente aos Tokens usados na transação.',
        p41i: 'i) O Comerciante é obrigado a cumprir os termos da transação previamente acordado com o Produtor, conforme indicado no parágrafo a) acima. A Agrotoken desconhece o desempenho do Comerciante de tal obrigação e não se responsabiliza pela entrega oportuna do bem ou serviço adquirido pelo Comerciante e/ou qualquer outra obrigação do Comerciante em relação à sua atividade comercial.',
        p42: '4.2. O Produtor e o Comerciante reconhecem que qualquer transação realizada através do Site é de seu respectivo e exclusivo risco e que a Agrotoken não é responsável pelo cumprimento do mesmo ou pela qualidade ou quantidade dos bens ou serviços transacionados ou pela sua entrega. A Agrotoken é responsável tão somente pelo crédito dos fundos relevantes na conta do Comerciante para a compra dos Tokens recebidos.',
        p43: '4.3. O Comerciante que oferecer um bem e/ou serviço para aquisição pelo Produtor, deve ter a intenção e capacidade de vender o bem e/ou serviço, ser o proprietário ou ser autorizado pelo proprietário do bem a fazer a venda e ter a disponibilidade do bem para entrega no tempo acordado e/ou as condições adequadas para a prestação do serviço em tempo hábil. O Comerciante deverá cumprir a legislação e regulamentos específicos aplicáveis de acordo com o bem e/ou serviço oferecido e/ou vendido, não sendo de forma alguma responsável a Agrotoken para tal cumprimento pelo Comerciante.',
        p44: '4.4 O Produtor pode usar seus Tokens para entregá-los ao Provedor de Serviços Financeiros, garantindo o cumprimento das operações de crédito. Para este fim, o seguinte procedimento deve ser seguido:',
        p44a: '1. O Produtor e o Provedor de Serviços Financeiros devem concordar entre eles individualmente e fora do Site, os termos e condições de assistência ao crédito. A concessão do referido auxílio será de competência única e exclusiva do Provedor de Serviços Financeiros.',
        p44b: '2. Uma vez acordados os termos com o Provedor de Serviços Financeiros, a Agrotoken fornecerá ao Provedor de Serviços Financeiros as informações necessárias sobre os Tokens do Produtor que serão transferidos como garantia da operação de crédito e estruturação da transação, sendo que o Produtor dará seu consentimento total para tal divulgação de informações ao Provedor de Serviços Financeiros para tais fins pela Agrotoken.',
        p44c: '3. Se o Provedor de Serviços Financeiros aceitar a garantia oferecida pelo Produtor e cumprir os outros requisitos de contratação exigidos pelo Provedor de Serviços Financeiros, ambos implementarão o contrato de empréstimo e a garantia sobre os Tokens, utilizando os documentos que o Provedor de Serviços Financeiros possui para ele, que devem ser notificados à Agrotoken.',
        p44d: '4. A garantia sobre os Tokens será programada em um Contrato Inteligente, para que os respectivos Tokens sejam retirados e bloqueados da carteira do Produtor.',
        p45: '4.5. O Produtor pode usar os Tokens disponíveis em sua carteira virtual através do uso de cartões pré-pagos que venham a ser oferecidos por prestadores autorizados pela Agrotoken (o "Cartão Pré-Pago"). A solicitação e utilização do Cartão Pré-Pago pelo Produtor implica a aceitação dos termos e condições detalhados abaixo:',
        p451: '4.5.1. Cartão pré-pago',
        p4511:
          '4.5.1.1. Qualquer produtor com carteira virtual aberta na Agrotoken pode ser detentor de um Cartão Pré-Pago (o "Holder"). Caso um Produtor que seja pessoa jurídica solicite um Cartão Pré-Pago em favor de um autorizado, ele deve ter um relacionamento com o Produtor conforme declarado entre as opções disponíveis no momento da solicitação do Cartão Pré-Pago. Nesse sentido, o Titular pode ser, entre outros, sócio, acionista, administrador ou representante legal.',
        p4512:
          '4.5.1.2. O Cartão Pré-Pago é um cartão plástico ou virtual que pode ser usado para a compra de bens e/ou serviços no Brasil em estabelecimentos associados à rede Visa. Da mesma forma, pode ser usado para adquirir bens e/ou serviços no exterior em estabelecimentos associados à rede Visa. Em nenhum caso as extrações podem ser realizadas no Brasil ou no exterior. O Cartão Pré-Pago pode ser usado para efetuar pagamentos pessoalmente, online, por telefone ou por qualquer outro canal habilitado.',
        p4513:
          '4.5.1.3. O Cartão Pré-Pago não é um cartão de crédito, débito ou compra, e não aceita compras parceladas. Os pagamentos feitos com o Cartão Pré-Pago serão debitados do valor dos Tokens disponíveis na carteira virtual, mediante a queima imediata dos respectivos Tokens e convertendo-os em solicitação legal através do exercício dos direitos de cobrança do Ticket.',
        p452: '4.5.2. Taxas',
        p4521:
          '4.5.2.1. As taxas aplicáveis à obtenção e utilização do Cartão Pré-Pago estão disponíveis para consulta em todos os momentos (as "Taxas").',
        p453: '4.5.3. Pedido de cartão pré-pago',
        p4531:
          '4.5.3.1. Ao solicitar o Cartão Pré-Pago, o Produtor deverá fornecer de forma exata, verdadeira e completa os dados e documentos que foram exigidos (nome completo, endereço real, número de telefone, relacionamento com o Produtor pessoa jurídica, etc.). Da mesma forma, o Produtor deve manter esses dados ou documentação atualizados, registrando-os e/ou fornecendo-os através dos canais habilitados. Para fins de geração do Cartão Pré-Pago, serão utilizadas as informações fornecidas através da plataforma pelo solicitante, como usuário previamente validado. Em caso de omissão ou imprecisão nos dados fornecidos ou informações adicionais necessárias à emissão do Cartão Pré-Pago, em especial para fins de cumprimento das normas relativas à Prevenção da Lavagem de Dinheiro e Financiamento do Terrorismo e/ou com qualquer outra cobrança que possa ser necessária para fins de cumprimento dessas normas. O Produtor deve fornecer de forma exata, verdadeira e completa todos os dados pessoais, documentos e informações que foram necessários. O Produtor se compromete a manter esses dados, documentação e informações atualizados, cadastrando-os na plataforma e/ou fornecendo-os através dos diferentes canais de atendimento habilitados. Sem prejuízo disso, você também pode ser solicitado para comprovação ou informações adicionais para completar ou corroborar as informações fornecidas.',
        p45312:
          '4.5.3.1.2. Consentimento para o processamento de Dados Pessoais.',
        p45312a:
          'A Agrotoken e instituições de pagamento habilitadas são autorizadas pelo Produtor a fornecer à Visa bem como às autoridades competentes e/ou órgão que o solicite, conforme apropriado, com todas as informações fornecidas sobre o Produtor, o que é necessário para cobrir os requisitos do Sistema de Cartões Pré-Pagos.',
        p45312b:
          'A Agrotoken possui seus Bancos de Dados Registrados de acordo com as disposições das normas vigentes. Nesse sentido, o Produtor ao aceitar esses termos e condições dá seu consentimento livre e informado, declara conhecer e aceitar que seus dados pessoais e todos aqueles vinculados ou decorrentes do uso do Cartão Pré-Pago integram o Banco de Dados. Da mesma forma, autoriza a Agrotoken e/ou instituições de pagamento habilitadas e suas afiliadas, a processar essas informações e usá-la para realizar o funcionamento do Cartão Pré-Pago.',
        p45312c:
          'Sem prejuízo do fato de que o Produtor tem acesso aos seus dados pessoais existentes no Banco de Dados da Agrotoken e/ou instituições de pagamento habilitadas através da plataforma, fica claro que ele também pode acessar suas informações pessoais gratuitamente. Da mesma forma, o Produtor poderá solicitar, a qualquer momento, a atualização, retificação e/ou exclusão dos dados pessoais devidamente fornecidos. Para os propósitos estabelecidos neste parágrafo, eles devem enviar sua solicitação por e-mail para suporte@agrotoken.io ou por postagem para o seguinte endereço: Avenida Engenheiro Luiz Carlos Berrini, nº 1140, conjunto 72, Cidade Monções, CEP 04571-930 - São Paulo/SP',
        p4532:
          '4.5.3.2. Todas as informações ou documentações fornecidas pelo Produtor são uma declaração. No entanto, tanto a Agrotoken quanto a instituição de pagamento habilitada podem solicitar vouchers ou informações adicionais para concluir ou corroborar as informações fornecidas por um Produtor, mesmo a partir do excesso de determinadas quantidades de transações mensais feitas pelo titular do Cartão Pré-Pago.',
        p4533:
          '4.5.3.3. A solicitação do Cartão Pré-Pago será aperfeiçoada com o recebimento do Cartão Pré-Pago pelo respectivo titular. No entanto, a instituição de pagamento pode rejeitar uma solicitação de Cartão pré-pago, suspendê-la ou cancelá-la em caso de detecção de inconsistências nos dados ou documentação fornecidas por um Produtor, ou no caso de o Produtor não fornecer os dados ou documentação adicional solicitado. Da mesma forma, a instituição de pagamento pode, em qualquer caso, rejeitar um pedido de cartão pré-pago a seu exclusivo critério, sem a necessidade de fornecer uma justificativa ao Produtor.',
        p4534:
          '4.5.3.4. O Produtor pode solicitar cartões pré-pagos adicionais vinculados à mesma carteira virtual aberta na Agrotoken.',
        p454: '4.5.4. Ativação e uso do Cartão Pré-Pago',
        p4541:
          '4.5.4.1. Uma vez recebido, o Cartão Pré-Pago pode ser ativado através dos meios indicados pela instituição de pagamento, seguindo as instruções correspondentes. Se a correspondência contendo o Cartão Pré-Pago for danificada ou violada, o titular deve recusar seu recebimento e comunicar o fato imediatamente através dos canais de atendimento disponíveis.',
        p4542:
          '4.5.4.2. Após ser ativado, o Cartão Pré-Pago pode ser utilizado dentro do prazo de validade impresso em sua frente, sujeito ao saldo valorizado dos Tokens disponíveis na carteira virtual aberta na Agrotoken, e dentro dos limites de uso diário para operações determinadas pela instituição de pagamento e/ou pelas redes de serviços associadas.',
        p4543:
          '4.5.4.3 O Cartão Pré-Pago é pessoal e intransferível. O Produtor será o único responsável pela custódia e confidencialidade do Cartão Pré-Pago e sua senha ou código de segurança. Portanto, o titular é responsável por todas as operações realizadas com o Cartão Pré-Pago. Caso um Produtor tenha solicitado um Cartão Pré-Pago para um autorizado ou adicional, a responsabilidade pela custódia, confidencialidade e operações realizadas com o referido Cartão Pré-Pago é do Produtor e isso em nenhum caso pode ser isento de responsabilidade alegando responsabilidades do titular.',
        p4544:
          '4.5.4.4. Por razões de segurança, a entrada incorreta da senha do Cartão Pré-Pago em um caixa eletrônico pode resultar na retenção do Cartão Pré-Pago pela rede de atendimento ATM. Nesse caso, o titular deve solicitar um novo Cartão Pré-Pago através dos canais habilitados.',
        p4545:
          '4.5.4.5. O uso do Cartão Pré-Pago estará sujeito à disponibilidade da rede Visa, uma circunstância além do controle da Agrotoken e/ou instituição de pagamento. A aprovação da transação será feita de acordo com os procedimentos específicos, dependendo do tipo de transação ou canal de pagamento utilizado. Por exemplo, para alguns canais de pagamento oferecidos por determinados estabelecimentos, o uso da senha ou assinatura pode ser substituído indicando o número do cartão pré-pago, sua data de validade e seu código de segurança. De qualquer forma, o cumprimento dos requisitos necessários para a realização da transação implica a aceitação da transação e sua consequente responsabilidade por parte do titular.',
        p4546:
          '4.5.4.6. Os valores das transações realizadas com o Cartão Pré-Pago e as taxas correspondentes serão debitados do saldo valorizado dos Tokens disponíveis na carteira virtual do Produtor imediatamente, totalmente e em um único débito da quantidade de Tokens necessários para cancelar o valor da transação realizada, procedendo automaticamente à sua queima (queima) e exercendo os respectivos direitos de cobrança em relação ao Ticket. Se o saldo avaliado dos Tokens disponíveis não for suficiente, a transação não será autorizada. As operações realizadas com o Cartão Pré-Pago podem ser consultadas nos movimentos da carteira virtual do Produtor.',
        p4546a:
          'Os tokens disponíveis na carteira virtual do Produtor ou Titular não constituem depósitos em uma instituição financeira, nem possuem nenhuma das garantias que tais depósitos podem desfrutar de acordo com a legislação e regulamentos aplicáveis sobre depósitos de Instituições Financeiras.',
        p4547:
          '4.5.4.7. Qualquer cancelamento de uma transação de compra deve ser solicitado pelo Proprietário ao estabelecimento. No momento da solicitação de cancelamento, o Proprietário deverá obter o comprovante correspondente de cancelamento diretamente do estabelecimento. Os saldos do Cartão Pré-Pago serão reembolsados ao Produtor a partir da aprovação do cancelamento pela Visa.',
        p45471: '4.5.4.7.1. Ignorância e Chargebacks:',
        p4547411:
          '4.5.4.7.1.1. A aquisição de bens e/ou serviços pelo Produtor e/ou Titular utilizando o Cartão Pré-Pago como meio de pagamento gera uma relação entre o Produtor e o fornecedor de referidos bens e/ou serviços fora da Agrotoken e/ou da instituição de pagamento, portanto, tais transações não podem ser revogadas ou canceladas por este último.',
        p4547412:
          '4.5.4.7.1.2. O pedido de cancelamento de uma transação deve ser gerenciado pessoalmente pelo Produtor e/ou Titular ao fornecedor de bens e/ou serviços e, uma vez obtido o comprovante correspondente, o referido pedido será submetido à VISA para que, sujeito à aprovação deste último, o valor do cancelamento seja reembolsado na conta do Produtor.',
        p4547413:
          '4.5.4.7.1.3. O Produtor e/ou titular do Cartão Pré-Pago podem desconhecer uma transação e/ou movimentação de sua conta, no prazo de 30 dias após vê-la na carteira virtual. Para isso, você deve informar a Agrotoken e/ou instituição de pagamento através dos canais autorizados, que devem processar tal ignorância no prazo de 7 dias após o recebimento da reclamação. Se aplicável, o Produtor e/ou Titular pode ser obrigado a fornecer documentação comprobatória comprovando tal desconhecimento antes da restituição do valor no prazo não superior a 15 dias após o recebimento da declaração.',
        p455: '4.5.5. Declaração PEP e declaração de origem dos fundos',
        p4551:
          '4.5.5.1. O Produtor declara de forma irrevogável e irretratável que não é considerado uma “Pessoas Politicamente Expostas" (PEP) de acordo com a legislação vigente. Se sim, o Produtor compromete-se a indicar detalhadamente o motivo através dos canais de atendimento autorizados.  Além disso, o Produtor assume o compromisso de informar qualquer modificação que ocorra a este respeito no prazo de 30 dias após a ocorrência, mediante a apresentação de uma nova declaração através dos canais de atendimento autorizados.',
        p4552:
          '4.5.5.2. O Produtor declara que os recursos utilizados para as operações realizadas com o Cartão Pré-Pago foram obtidos por meio de atividades legais e declaradas, e terão um destino igualmente legítimo.',
        p456: '4.5.6. Validade do Cartão Pré-Pago',
        p4561:
          '4.5.6.1. O Cartão Pré-Pago será válido a partir da data de sua ativação até a data de vencimento informada em sua frente. Após o término do prazo, o Cartão Pré-Pago será automaticamente cancelado. No entanto, a instituição de pagamento pode cancelar o Cartão Pré-Pago caso não tenha sido usado pelo seu titular por um período de 6 meses ou mais sem notificação prévia ao seu titular.',
        p4562:
          '4.5.6.2. Em caso de vencimento e/ou cancelamento do Cartão Pré-Pago, o Titular poderá solicitar um novo Cartão Pré-Pago através dos canais habilitados.',
        p457: '4.5.7. Relatório de perdas, furtos, roubos, cancelamentos e reemissões do Cartão Pré-Pago',
        p4571:
          '4.5.7.1. O Titular deve notificar imediatamente o emissor ou por outro meio autorizado, da perda, furto, roubo ou uso indevido do Cartão Pré-Pago. O Cartão Pré-Pago informado como perdido, furtado, roubado ou mal utilizado será cancelado e não poderá ser reativado.',
        p4572:
          '4.5.7.2. O Produtor ou Titular será o único responsável pelo uso de seu Cartão Pré-Pago até comunicar o evento e obter a confirmação do cancelamento do Cartão Pré-Pago. A instituição de pagamento e Visa não serão responsáveis em qualquer caso pelo uso do Cartão Pré-Pago antes de seu cancelamento efetivo.',
        p4573:
          '4.5.7.3. Uma vez cancelada, a emissão de um novo Cartão Pré-Pago estará sujeita às Taxas aplicáveis.',
        p458: '4.5.8. Cancelamento ou suspensão do Cartão Pré-Pago',
        p4581:
          '4.5.8.1. O Titular pode solicitar o cancelamento voluntário de seu Cartão Pré-Pago a qualquer momento através dos canais habilitados.',
        p4582:
          '4.5.8.2. Além disso, o Cartão Pré-Pago será cancelado ou suspenso, sem limitação, nos seguintes casos: (i) denúncia de dano, perda, roubo ou roubo do Cartão Pré-Pago pelo Titular, (ii) liminar judicial ou competente, (iii) suspeita de fraude, uso ilegal ou proibido a critério exclusivo da instituição de pagamento ou Visa, (iv) suspensão ou cancelamento da carteira virtual do Produtor aberta na Agrotoken, (v) Falha no fornecer informações ou documentação solicitada e (vi) violação destes termos e condições.',
        t4582:
          '4.5.8.2. Direito do Produtor e/ou Titular retirar ou arrepender-se',
        p45821:
          '4.5.8.2.1. Cancelamento: A qualquer momento o titular tem o direito de solicitar o cancelamento do Cartão Pré-Pago, de forma fácil e rápida através de um canal habilitado no site.',
        p45822:
          '4.5.8.2.2 Arrependimento: O titular tem o direito de solicitar a revogação de sua solicitação de Cartão Pré-Pago, no prazo de 10 dias corridos a partir de seu recebimento. Para isso, você deve comunicar através do canal habilitado no site, a vontade de revogar a solicitação e devolver o Cartão Pré-Pago, sem qualquer custo ou responsabilidade para o titular, caso você não tenha feito qualquer uso do Cartão Pré-Pago. Caso contrário, você será debitado os custos associados proporcionais ao tempo de seu respectivo uso.',
        p45823:
          '4.5.8.2.3. Para proceder ao cancelamento ou revogação do serviço, um botão identificado com o procedimento a ser realizado será disponibilizado ao titular em local visível no site. Caso o titular ative este botão, o número do procedimento correspondente será informado e o cancelamento ou revogação do produto será realizado, conforme apropriado, automaticamente ou, de outra forma, será informado dos motivos e prazos para resolução do pedido. O cancelamento e a revogação não terão qualquer custo, sem prejuízo das disposições do ponto anterior em relação à revogação do produto.',
        t459: '4.5.9. Canais de Atendimento ao Cliente',
        p4591:
          '4.5.9.1. A Agrotoken e/ou a instituição de pagamento fornecerão ao Holder diferentes canais de serviço para que ele possa tirar suas dúvidas ou fazer consultas relacionadas ao Cartão Pré-Pago. O Proprietário pode obter ajuda no Site de Ajuda ou através do serviço telefônico',
        t4510: '4.5.10. Operações no exterior',
        p4510:
          'O cartão Pré-Pago, neste primeiro momento, não está habilitado para compras internacionais.',
        t4511: '4.5.11. Limitação da Responsabilidade',
        p45111:
          '4.5.11.1. A Agrotoken e/ou a instituição de pagamento não garantem acesso ininterrupto aos canais de uso do Cartão Pré-Pago, que podem não estar disponíveis devido a inconvenientes técnicos, falhas na internet, indisponibilidade da rede Visa, indisponibilidade de caixas eletrônicos conectados à rede, ou qualquer outro motivo, mesmo fora da Agrotoken ou instituição de pagamento.',
        p45112:
          '4.5.11.2. Os Produtores ou titulares exoneram a Agrotoken e/ou a instituição de pagamento de todos os tipos de responsabilidade por danos diretos, lucros perdidos ou qualquer outro dano ou dano que o titular possa ter sofrido em relação às transações com o Cartão Pré-Pago feito, rejeitado ou restrito.',
        p45113:
          '4.5.11.3. Os Produtores e/ou titulares devem manter indenes a Agrotoken e a instituição de pagamento, seus afiliados e seus diretores, gerentes, funcionários, agentes, operadores, representantes e advogados de fato, por quaisquer danos diretos, lucros perdidos ou qualquer outro dano ou dano que sofrer ou possa sofrer em relação às transações feitas, rejeições ou qualquer outro movimento relacionado ao Cartão Pré-Pago.',
        t4512: '4.5.12. Prestador de Serviços de Pagamento',
        p4512a:
          'A Agrotoken e a instituição de pagamento firmaram um acordo com a Pomelo Instituição de Pagamento Ltda. e Pomelo Tecnologia Ltda, pelo qual estes últimos oferecem a infraestrutura tecnológica para a emissão de processamento de cartões pré-pagos e emissor de cartões, não sendo autorizado a operar como instituição financeira pelo Banco Central do Brasil. Agrotoken e instituição de pagamento não fornecem ao Produtor nenhum produto ou serviço bancário ou suporte financeiro. Os Tokens depositados na carteira virtual do Produtor não constituem depósitos em uma instituição financeira, nem possuem nenhuma das garantias que tais depósitos podem desfrutar de acordo com a legislação e regulamentos aplicáveis sobre depósitos de instituições financeiras. A Pomelo Instituição de Pagamento Ltda. e Pomelo Tecnologia Ltda  será responsável pela (i) emissão de cartões virtuais e de plástico em favor de Produtores, (ii) Processamento de transações relacionadas a Cartões Pré-Pagos, (iii) Distribuição de Cartões Pré-Pagos.',
        t4513: '4.5.13. Impostos',
        p45131:
          '4.5.13.1 Todos os impostos correntes e futuros cobrados direta ou indiretamente no Cartão Pré-Pago, bem como as despesas incorridas com ele (emissão/manutenção/consumo), serão suportados pelo titular conforme apropriado, e serão debitados de sua conta.',
        p45132:
          '4.5.13.2. O Titular compromete-se a manter em sua conta permanentemente um saldo em tokens destinados ao pagamento dos impostos que podem ser cobrados no Cartão Pré-Pago. Caso seja insuficiente, será informado, através da plataforma ou dos canais de atendimento disponíveis, a forma como será subscrito.',
        p5: '5. Compromissos do Produtor: A assinatura do Pedido de Tokenização pelo Produtor implica seu compromisso irrevogável e irretratável para: (a) atribuir à Agrotoken os direitos de cobrança do Ticket para o cumprimento das obrigações assumidas no Pedido de Tokenização; (b) Não atribua ou elimine em nenhuma circunstância a posição contratual e/ou dos direitos de cobrança do Ticket. Além disso, a assinatura do Pedido de Tokenização implica para o Produtor que declara e garante que: (c) ele não atribuiu ou descartou em nenhuma circunstância nem a posição contratual nem os direitos de cobrança do Ticket; (d) Reconhece e aceita que o convite recebido para participar desta tokenização foi feito diretamente, individualmente (um a um) e confidencialmente, e que você não participou de reuniões de grupo ou foi destinatário de convites por meios de divulgação em massa, impessoais ou generalizados, portanto não houve oferta pública dos Tokens,  e, portanto, a autorização dos reguladores governamentais não é necessária; (e) É um investidor que possui conhecimento e experiência suficientes em questões comerciais, financeiras, legais, fiscais e/ou outras questões e adequação na ponderação do risco de crédito para avaliar os benefícios e riscos relacionados ao investimento nos Tokens, e que sua decisão de realizar essa transação foi baseada em sua própria análise; (f) Analisou este e todos os outros documentos relativos aos Tokens e teve a oportunidade de solicitar e revisar todas as informações adicionais que considerou necessárias para verificar a veracidade e exatidão das informações contidas em tais documentos; (g) De acordo com as regras aplicáveis em vigor em qualquer jurisdição a que você está sujeito, Você pode assinar validamente os Tokens e (h) os Tokens não representam e não serão utilizados como derivativos.',
        p6: '6. Remuneração da Agrotoken: A Agrotoken receberá do Produtor e do Comerciante uma comissão entre 0,10% e 1% do valor dos Tokens para os serviços de Tokenização, Queima e Transação, conforme determinado no Site ao tokenizar ou queimar os tokens ou ao transacionar os Tokens. A Agrotoken pode modificar esses percentuais de comissão a seu exclusivo critério.',
        p7: '7. Despesas e impostos: Caso a aplicação de qualquer imposto à presente operação corresponda, ficará a cargo do Produtor e/ou do Comerciante e/ou HUB. Cada parte arcará com os impostos cobrados sobre sua renda em uma base global.',
        p8: '8. Indenização: O Produtor, o Comerciante e/ou o Oráculo, respectivamente, comprometem-se a indenizar e manter indenes a Agrotoken, as empresas relacionadas, seus diretores, funcionários e representantes, de e contra quaisquer acusações, incluindo, mas não se limitando a qualquer ação judicial e/ou extrajudicial, resultante do uso do Site,  ou a violação por você destes Termos e Condições Específicas da Tokenização e da Transação, e o Produtor, o Comerciante e/ou o Oráculo devem arcar com as despesas legais ou de qualquer outra natureza em que a Agrotoken, as empresas relacionadas, seus diretores, funcionários e representantes, de e contra qualquer acusação pode ser incorrido. Para isso, a Agrotoken irá notificá-lo prontamente de qualquer demanda e/ou ação e/ou processo.',
        p9: '9. Legislação e jurisdição aplicáveis: Quais disputas ou litígios decorrentes destes Termos serão tramitados na Comarca da Capital do Estado de São Paulo, com a expressa renúncia de qualquer outro foro.  Estes Termos são regidos pelas leis da República Federativa do Brasil.',
        p10: '10. A adesão aos Termos e Condições Gerais: A aceitação destes Termos e Condições Específicas da Tokenização e Transação implica a adesão e aceitação dos Termos e Condições Gerais do Site.',
        p110: '1.10. Contrato Inteligente (Smart Contract): É um acordo digital entre as partes programadas pela Agrotoken que contém um tipo especial de instruções que serão armazenadas na blockchain Ethereum, Polygon ou Algorand, e tem a capacidade de auto-executar ações de acordo com uma série de parâmetros já programados, imutavelmente, de forma transparente e segura.',
        p111: '1.11. Tokens: São os ativos digitais (ativos virtuais) que representam os direitos oriundos da comercialização dos Grãos e transferidos à Agrotoken. Cada unidade de Token representa o equivalente à obrigação de entrega de uma tonelada de grãos ou seu equivalente em moeda corrente ou criptoativos (“Tokens”).',
        p112: '1.12. Transação: É o processo de utilização dos Tokens descritos no ponto 4 destes Termos e Condições Específicas de Tokenização e Transação (“Transação”).',
      },
      termsAndCondsTitle:
        'Termos e Condições Específicos de Tokenização e Transação',
      pretokenization: {
        alert: {
          title: 'Esta tokenização não está finalizada',
          description:
            'Lembre-se de que, ao enviar a solicitação, você não poderá usar seus grãos tokenizados até carregar seu arquivo de compra e venda.',
        },
      },
      generalDetailsTitle: 'Dados gerais',
    },
    currency: 'Tipo de grão',
    documentation: {
      title: 'Documentação: Certificado',
    },
    documentationCard: {
      agreementDocTitle: 'Documentação: Contrato de compra',
      farmRegistrationDocTitle: 'Inscrição Estadual',
      attachedFile: 'Arquivo anexo:',
      btnEditLabel: 'Editar',
      carNumberLabel: 'Número do documento:',
      carRegistrationNumber: 'Número de matrícula:',
      cdeNumber: 'número documento:',
      farmTitle: 'Fazenda de origem',
      futureFarmTitle: 'Origem do grão',
      carTitle: 'CAR - Cadastro Ambiental Rural',
      intNumberLabel: 'Número interno',
      maxContractDeadline: 'Prazo máximo de fixação',
      registrationNumberLabel: 'Número de inscrição estadual:',
      title: 'Certificado de depósito eletrônico #',
      titleInCard: 'Prova de esperança de grão',
      tonsAgreementDocLabel: 'Toneladas de contrato',
      tonsCde: 'Toneladas atribuídas ao documento:',
      tonsFromCde: 'Toneladas para dar',
      typeLabel: 'Tipo de contrato:',
      viewDocLabel: 'Ver documentação',
    },
    documentationOfferCard: {
      actionButton: 'Ver documentação',
      description: 'Cessão de crédito',
      title: 'Cessão de crédito',
    },
    expirationDate: 'Data do pagamento',
    maxPaymentDate: 'Data máxima do pagamento',
    healthRatio: 'Índice de cobertura',
    farmName: 'Nome da fazenda:',
    fazendaName: 'Nome da fazenda:',
    noData: 'Dados pendentes',
    oracle: 'Comprador de grãos',
    oracleCompany: 'Comprador de grãos',
    placeKept: 'Armazenamento de grãos',
    signer: 'Signatário',
    userSignerTooltip: 'Usuário designado para assinar a carta de oferta',
    userCreatorTooltip:
      'Usuário que criou a solicitação de tokenização, designado para assinar a carta de oferta',
    progressIndicator: {
      proofOfExistence: 'Prova de existência de grãos',
      proofOfLiquidity: 'Prova de liquidez',
      proofOfOrigin: 'Prova de origem',
      summary: 'Resumo da tokenização',
      title: 'Progreso',
    },
    productionExpectation: {
      title: 'Previsão de produção',
      text: 'Preencha as informações de acordo com o documento de garantia de sua produção, que consistirá na prova de existência do grão.',
      form: {
        documentTypeLabel: 'Tipo de documento',
        documentTypePlaceholder: 'Selecione o tipo de CPR/Penhor',
        documentNumberLabel: 'Número do documento:',
        documentNumberPlaceholder: 'Digite o número do documento',
        plantDateLabel: 'Possível data de plantio',
        plantDatePlaceholder: 'Selecione a data',
        expirationDateLabel: 'Data de vencimento',
        expirationDatePlaceholder: 'Selecione a data',
        plantedAreaLabel: 'Área plantada (ha)',
        plantedAreaPlaceholder: 'Ex. 100',
        documentGrainVolumeLabel: 'Volume em grãos do documento (t)',
        documentGrainVolumePlaceholder: 'Ex. 400',
        totalReferenceValueLabel: 'Valor total de referência do documento',
        totalReferenceValuePlaceholder: 'Digite o preço',
        grainTypeCprTitle: 'CPR emitida para Agrotoken ',
        grainTypeEndorsementTitle: 'Endosso a favor da Agrotoken',
        grainTypeCprText: 'CPR que foi emitida para a Agrotoken',
        grainTypeEndorsementText:
          'CPR que foi emitida para um terceiro e endossada para a Agrotoken',
        fileAttachment: 'Anexar documentação',
        errorMessages: {
          isValidExpirationDateAfterPlatingDate:
            'A data de vencimento deve ser posterior à data de plantio',
          isInvalidInternalNumberFormat:
            'Este campo aceita apenas números ou -',
        },
      },
    },
    proofOfExistence: {
      addInfo: 'Preencha as informações com os dados solicitados',
      addNewSubTitle:
        'As toneladas inseridas no prova serão o máximo possível de tokenizar.',
      addNewTitle: 'Documento de prova de existência',
      cdeEmpyError: 'É necessário carregar pelo menos um documento',
      form: {
        addressLabel: 'Endereço',
        addressNumberLabel: 'Nro. de cadastro ambiental rural',
        attachCde: 'Anexar Comprovante de Entrega dos grāos.',
        cityLabel: 'Cidade',
        errorMessage: {
          empty: 'Este campo é obrigatório',
          length: 'O número documento deve conter 12 dígitos',
          min: 'Você deve tokenizar pelo menos 5 toneladas.',
          unique: 'Não é possível inserir o mesmo documento',
        },
        inputNumberLabel: 'Número do documento',
        inputNumberPlaceholder: 'Digite o número do documento',
        inputTonsLabel: 'Toneladas do documento',
        inputTonsPlaceholder: 'Digite o número de toneladas',
        search: 'Armazém',
        searchMessage:
          'Caso não encontre o armazém desejado, entre em contato com suporte@agrotoken.io',
        searchBuyer:
          'Caso não encontre o comprador dos grãos desejado, entre em contato com suporte@agrotoken.io',
        searchPlaceholder: 'Pesquisar pelo nome da armazém',
        stateLabel: 'Estado',
      },
      helpTipText:
        'As toneladas inseridas no documento serão o máximo possível de tokenizar.',
      helpTipTitle: 'Uma ajudinha..',
      mutateError:
        'Não é possível editar os documentos pois esta solicitação já foi enviada.',
      subtitle:
        'Preencha as informações conforme o comprovante de entrega dos grãos a serem tokenizados.',
      title: 'Prova de existência de grãos',
    },
    proofOfLiquidity: {
      agreementTypeSelectLabel: 'Selecione o tipo de contrato',
      form: {
        agreementType: {
          fixedPrice: 'Preço fixo',
          priceToFix: 'Preço a fixar',
          priceToFixAGTK: 'Preço a fixar Agrotoken',
        },
        errorMessage: {
          agreementTons:
            'O número de toneladas do contrato deve ser maior ou igual ao número de toneladas a ser tokenizado',
          empty: 'Este campo é obrigatório',
          exceededIsFixedPrice:
            'A data não pode ser posterior à data de vigência máxima do contrato',
          exceededNoFixedPrice:
            'A data não pode ser posterior à data do prazo máximo de precificação',
          format: 'Este campo só pode conter números, letras, "-" ou "/"',
          price: 'O preço deve ser maior que 0',
          tokenizationTonsMin:
            'O número de toneladas deve ser maior ou igual a 5',
          tonsMissing: 'Falta adicionar',
          tonsMissingMessage:
            'toneladas no prova de existência de grãos . Você está tentando tokenizar mais toneladas do que adicionou no prova de existência de grãos.',
          tonsMissingMessageCpr: 'toneladas no Cpr .',
          validPeriod:
            'A data não pode ser anterior a 30 dias a partir de hoje',
          validEndDateBeforeExpiration:
            ' A data de entrega do grão não pode ser posterior à data de pagamento',
          validEndDatePeriod: 'O período inserido é inválido',
          validAgreeementExpirationDate:
            'A data máxima de pagamento não pode ser posterior à data de vencimento da previsão de produção',
        },
        expDateLabelFixed: 'Prazo máximo do contrato',
        expirationDateLabel: 'Prazo máximo de preço',
        fileAttachDoc: 'Anexe o contrato de compra e venda',
        fixedPriceLabel: 'Preço do contrato em USD por toneladas',
        fixedPriceLabelBr: 'Preço do contrato em Reais por saca',
        fixedPricePlaceHolder: 'Indique o preço do contrato em USD',
        fixedPricePlaceHolderBr: 'Indique o preço do contrato em Reais',
        inputAgreementDoc: 'Qual é o total de toneladas do contrato?',
        inputAgreementDocPlaceholder:
          'Insira o número de toneladas do contrato',
        inputInternalNumber: 'Número do contrato',
        inputMaxDate: 'Data máxima de pagamento',
        inputEstimateGrainDelivery: 'Período estimado de entrega dos grãos',
        inputAgreementPricePlaceholder: 'Digite o preço',
        inputAgreementPricePerSaca: 'Preço do contrato por saca',
        inputInternalNumberPlaceholder: 'Digite o número do documento',
        selectContractLabel: 'Condição de fixação',
        selectDatePlaceholder: 'Selecione a data',
        tokenizationDateHint:
          'Requer um mínimo de 30 dias e o máximo será determinado pelo prazo do preço',
        tokenizationDateHintFixed:
          'Requer um mínimo de 30 dias e o máximo será determinado pelo prazo máximo do contrato',
        tokenizationTermLabel: 'Data de pagamento',
        tokenizationTons: 'Toneladas a tokenizar',
        tokenizationTonsPlaceholder:
          'Insira o número de toneladas para tokenizar',
        radioButtons: {
          hasDocumentLabel: 'Tenho a documentação',
          noDocumentLabel: 'Não tenho a documentação (carregarei depois)',
        },
      },
      mutateError:
        'Não é possível editar o prova de liquidez pois este pedido já foi enviado.',
      subtitle:
        'Preencha as informações de acordo com o contrato de compra e venda dos grãos.',
      title: 'Prova de liquidez',
      futureTitle: 'Contrato de compra e venda',
      futureSubtitle:
        'Preencha as informações de acordo com o contrato de compra e venda entre produtor e comprador dos grãos, que consistirá na prova de liquidez.',
    },
    newTokenization: {
      add: 'Adicionar',
      addNewFazenda: 'Adicionar nova fazenda',
      addFazenda: 'Adicionar fazenda',
      addNewFazendaDescription:
        'Fazendas são propriedade rural pertencente à pessoa ou empresa na conta.',
      emptyFarmTitle: 'Você não tem nenhuma fazenda',
      emptyFarmText:
        'Para continuar com o processo de tokenização você deve adicionar uma fazenda.',
      farmForm: {
        address: 'Endereço',
        addressPlaceholder: 'Ex. Rio Branco, 59600-145',
        cityId: 'Cidade',
        cityIdPlaceholder: 'Escolha sua cidade',
        capacity: 'Capacidade produtiva da fazenda (ha)',
        capacityPlaceholder: 'Ex. 200.000',
        errors: {
          maxLength: 'Este campo tem no máximo {{max}} caracteres',
          requiredField: 'Este campo é obrigatório',
          onlyLetters: 'Este campo só aceita letras.',
          onlyNumbers: 'Este campo só aceita números.',
          onlyNumbersAndLettersWithCharacters:
            'Este campo só aceita números e letras e los caracteres especiais (- e .)',
          onlyNumbersAndLettersWithHyphen:
            'Este campo só aceita números e letras e caracter especial (-)',
          minValue: 'O valor inserido deve ser maior que 0',
        },
        farmOwnerTypes: 'Tipo de posse da terra',
        farmOwnerTypesOptions: {
          borrower: 'Comodatário',
          owner: 'Proprietário',
          partner: 'Parceiro',
          tenant: 'Arrendatário',
        },
        farmOwnerTypesPlaceholder: 'Selecione tipo',
        geographicalDivision: 'Estado',
        geographicalDivisionPlaceholder: 'Escolha seu estado',
        internalNumberRural: 'Nro. de cadastro ambiental rural',
        internalNumberRuralDoc: 'Adicione aqui CAR - Status da inscrição',
        internalNumberRuralPlaceholder:
          'Ex. SP-3509007-67514511452E4809BE0D78CFF3D18CF8',
        internalNumberState: 'Nro. de inscrição estadual',
        internalNumberStatePlaceholder: 'Ex. 109492892',
        internalNumberStatePreview: 'Número do registro estadual:',
        name: 'Nome',
        namePlaceholder: 'Ex. Céu Azul',
        nextStep: 'Próximo passo',
        postalCode: 'CEP',
        postalCodePlaceholder: 'Ex. 40301110',
        registration: 'Matrícula',
        registrationPlaceholder: 'Ex. 0094928',
        statalRegisterDoc: 'Adicione aqui inscrição estadual',
      },
      form: {
        currencyLabel: 'Selecione o grão que deseja tokenizar',
        proofOfGrainReserveLabel: 'Selecione a condição dos grãos',
        currencyCORB: 'Milho',
        currencySOY: 'Soja',
        proofOfGrainFuture: 'Grão futuro',
        proofOfGrainToday: 'Grão entregue',
        proofOfGrainFixed: 'Grão fixado',
        fazendaLabel: 'Pesquisar a fazenda de origem',
        oracleLabel: 'Pesquisar o comprador dos grãos',
        zendeskId: 'Digite o número Zendesk',
        zendeskIdPlaceholder: 'Ej: 531',
      },
      rightSide: {
        buttonLabel: 'Próximo',
        sectionOne: {
          title: 'Você receberá em sua carteira:',
          warrantyCORB: 'Garantia de Milho',
          currencyCORB: 'Agrotokens CORB',
          warrantySOY: 'Garantia de Soja',
          currencySOY: 'Agrotokens SOYB',
          fixedSOYB: 'Soja fixada',
          fixedCORB: 'Milho fixado',
          warranty: 'Garantias',
          agrotokens: 'Agrotokens',
          fixedGrain: 'Grão fixado',
          SOYB: 'Garantia de Soja',
          CORB: 'Garantia de Milho',
          COFB: 'Garantia de Café',
        },
        sectionTwo: {
          requestLoan: 'Solicitar crédito',
          requestTokens: 'Solicitar agrotokens',
          payments: 'Links de pagamento',
          Transferencia: 'Transferência',
          creditCard: 'Cartão',
          productWarranty: 'Garantia para custear produtos e serviços',
          title: 'Você poderá utilizá-las para:',
        },
        sectionThree: {
          badge: 'Anexado',
          title: 'Documentação necessária para tokenizar:',
          subtitleOrigin: 'Documentos de origem',
          textOrigin:
            'Documentos da fazenda onde os grãos foram plantados e colhidos, sendo eles: Inscrição Estadual e Cadastro Ambiental Rural da fazenda.',
          subtitleTodayDocument: 'Comprovante de entrega:',
          textTodayDocument:
            'Documento que garante onde o grão está depositado.',

          subtitleCpr: 'CPR/Penhor',
          textCpr:
            'Documento que representa a entrega futura dos grãos a serem tokenizados emitido pelo produtor ou endossado pelo credor em favor da Agrotoken.',
          subtitleAgreementDoc: 'Contrato de compra e venda',
          textAgreementDoc:
            'Documento que representa o contrato de compra e venda futura entre produtor e comprador do grão.',
        },
      },
      secondaryTokenizationDoc:
        'Se tokenização secundária adicionar pdf como prova de origem (opcional)',
      subtitle:
        'Documento que valida a origem das toneladas a serem tokenizadas.',
      title: 'Iniciar a tokenização',
    },
    startTokenization: {
      searchFarmPlaceholder: 'Pesquisar fazenda',
      searchPlaceholder: 'Pesquisar comprador',
    },
    subtitle:
      'Aqui você pode visualizar todas as informações sobre sua solicitação de tokenização.',
    createdByAgrotoken: 'Solicitud #{{requestNumber}} criada por Agrotoken',
    success: {
      buttonLabel: 'Ir a tokenização',
      buttonLabelDocusign: 'Ver tokenizações',
      buttonLabelConfirm: 'Voltar ao início',
      confirmSubtitle:
        'A sua solicitação de tokenização foi criada com sucesso.',
      summaryDocusignSubtitle: 'Recebemos a sua assinatura com sucesso.',
      summaryDocusignText:
        'Validaremos a cessão de crédito assinada por todas as partes e retornaremos por e-mail com a atualização de sua tokenização.',
      confirmTextAr:
        'Vamos validar a informação e enviar-lhe um e-mail nas próximas 24 horas para que possa continuar com o processo de tokenização.',
      confirmText:
        'Você receberá um e-mail com a validação da documentação e orientações para assinar a cessão de crédito. Após a confirmação dessas etapas, a tokenização será finalizada.',
      confirmText2:
        'Você receberá um e-mail com a validação da documentação e deverá assinar a cessão do contrato para finalizar o processo de tokenização.',
      pretokenizationSuccessText:
        'Você receberá um e-mail com a validação da documentação. Lembre-se de que não poderá usar seus grãos tokenizados até carregar seu arquivo de compra e venda.',
      subtitle: 'A solicitação de tokenização foi concluída com sucesso.',
      text: 'Analisaremos a cessão de crédito e e notificaremos o comprador do grão para assinar a cessão de crédito. Enviaremos um e-mail nas próximas 24 horas quando seus tokens estiverem em sua carteira.',
      text2:
        'Você receberá um e-mail com a validação da documentação e deverá assinar a cessão do contrato para finalizar o processo de tokenização.',
      title: 'Convidamos você para a cripto revolução do campo',
    },
    summary: {
      actions: {
        alert:
          'Caso não solicite, será realizada uma destokenização automática no dia ',
        alertMoreInfo: ' Saiba mais',
        canRenewText:
          'Eu concordei em realizar uma destokenização total ou parcial ou em renovar a tokenização.',
        detokenize: 'Destokenizar',
        renew: 'Renovar',
        text: 'Você pode solicitar uma destokenização antecipada, que significa anular a tokenização, até um dia útil do vencimento do contrato.',
        title: 'Destokenização',
        tonsRemainingText:
          'Você não pode solicitar uma destokenização antecipada, devido a que algumas toneladas dessa tokenização já foram utilizadas.',
      },
      futureMovementsTitle: 'Detalhe de garantia utilizada',
      futureMovementsSubTitle: 'Todos os movimentos da sua garantia',
      columns: {
        equivalence: 'Equivalência',
        remainingTons: 'Toneladas restantes',
      },
      breadCrumbOpt1: 'Tokenização',
      breadCrumbOpt2: 'Tokenização #',
      breakDown: {
        breakDownAlert: 'Todas as sacas disponíveis estão bloqueadas.',
        breakDownAlertLink: 'Saber mais.',
        breakDownModalTitle: 'Sacas bloqueadas',
        breakDownModalText1:
          'Sua garantia pode ser bloqueada parcial ou totalmente, dependendo de uma análise realizada pela Agrotoken.',
        breakDownModalText2:
          '\n\n Essa análise contempla fatores tanto da produtividade provável da fazenda quanto do risco de crédito associado à tokenização. Se você tiver dúvidas, pode ',
        breakDownContactUs: 'entrar em contato conosco.',
        breakDownTitle: 'Detalhes da tokenização:',
        tons: 'Toneladas',
        sacas: 'sacas',
        currencyEquivalenceLabel: 'Equivalência em reais:',
        tokensEquivalenceLabel: 'Equivalência em tokens:',
        sacasEquivalenceLabel: 'Equivalência em sacas:',
        creationLabel: 'Você está criando:',
        creationLabelDone: 'Você criou:',
        initialTonsLabel: 'Toneladas no garantia',
        currentTons: 'Sacas disponíveis:',
        tonsAlert:
          'Uma vez utilizadas as sacas dessa tokenização, ela não pode ser destokenizada',

        farm: 'Fazenda:',
        collateralPercentageLabel: 'Margem de segurança',
        fee: 'Comissão de tokenização:',
        feeSubsidized: 'Bonificada',
        helpIconSupportText:
          'Corresponde a conceptos impositivos más un porcentaje por la variación de precio máximo que ese grano puede tener durante la solicitud de tokenización contra el precio hecho del contrato, que luego será devuelto al productor al momento de la destokenización o se afectarán en el momento de la liquidación de las toneladas por los tokens transaccionados.',
        helpIconSupportText2:
          'Corresponde a conceptos impositivos, que se devolverán al momento de la destokenización o se afectarán en el momento de la liquidación de las toneladas por los tokens transaccionados.',
        percentageAmountText: 'margem de segurança:',
        tokensToRecieve: 'Total de tokens a receber:',
        warrentyTons: 'Toneladas da garantia:',
        tonsToReserve: 'Toneladas reservadas',
        tonsToTokens: 'Toneladas para tokenizar:',
        corWarranty: 'Garantia de Milho',
        coffeeWarranty: 'Garantia de Café',
        soyWarranty: 'Garantia de Soja',
        soyFixedGrain: 'Soja fixada',
        corFixedGrain: 'Milho fixado',
      },
      btnLabel: 'Assinar a cessão de crédito',
      collateralDetails: {
        labelConfirm: 'Entendido',
        mail: 'suporte@agrotoken.io',
        text: 'Corresponde a conceitos que afetam diretamente o valor da mercadoria, desde impostos, volatilidade de preços, qualidade, entre outros.',
        text2:
          'O mesmo será devolvido no momento da destokenização ou será afetado no momento da liquidação das toneladas para os tokens negociados.',
        text3: 'Se você tiver dúvidas, entre em contato conosco em ',
        title: 'Qual é o percentual de capacidade para toneladas reservadas?',
      },
      creditAssigmentTypeDocusignLessTons:
        'Caso concorde com os tokens a receber, assine a cessão de crédito. Caso não concorde, você pode criar uma nova tokenização.',
      futureCreditAssignmentTypeManualTotalTons:
        'Um e-mail foi enviado para o seu e-mail associado à sua conta Agrotoken com instruções para assinar a cessão de crédito.',
      creditAssigmentTypeDocusignPendingAgtkTitle:
        'Cessão de crédito com verificação pendente',
      creditAssigmentTypeDocusignPendingAgtkText:
        'A cessão de crédito está pendente de verificação pela Agrotoken. Enviaremos um e-mail com a atualização de sua tokenização.',
      manualCreditAssigmentText:
        'Um e-mail foi enviado para o seu e-mail associado à sua conta Agrotoken com instruções para assinar a cessão de crédito.',
      docusignCreditAssigmentText:
        'Caso concorde com os tokens a receber, assine a cessão de crédito.',
      creditAssigmentTypeManualLessTons:
        'Caso concorde com os tokens a receber, assine a cessão de crédito que foi enviada a seu e-mail. Caso não concorde, você pode criar uma nova tokenização.',
      futureCreditAssigmentTypeManualText:
        'Um e-mail foi enviado para o seu e-mail associado à sua conta Agrotoken com instruções para assinar a cessão de crédito.',
      futureCreditAssigmentTypeDocusignText:
        'Para confirmar a tokenização, é necessário assinar a cessão de crédito, conforme orientado por e-mail. Qualquer dúvida, entre em contato conosco a través de suporte@agrotoken.io',
      creditAssigmentTypeDocusignTitle: 'Assine a cessão de crédito',
      creditAssigmentTypeManualTitle: 'Assine a cessão de crédito',
      currency: 'Tipo de grano tokenizado:',
      detokenization: {
        text: 'Concorde em realizar uma destokenização parcial ou total.',
        title: 'Destokenização',
      },
      detokenizationModal: {
        btnDetokenizeLabel: 'Destokenizar',
        emptyError: 'Este campo é obrigatório',
        hintMessage: 'Tienes',
        hintMessage2: 'disponíveis',
        inputPlaceholder: 'Ingresá el monto que desees destokenizar',
        labelBtnClose: 'Cancelar',
        maxError:
          'Los tokens a destokenizar no pueden ser mayores a los tokens disponibles',
        minError: 'Los tokens a destokenizar deben ser mayor a 0',
        modalTitle: 'Qual destokenização você deseja executar?',
        onlyTotalModalTitle:
          'Tem certeza de que deseja destokenizar seus tokens?',
        onlyTotaltext: 'Tokens a destokenizar: ',
        text: 'Você pode realizar a destokenização parcial ou total.',
        title: 'Destokenização',
        corWarranty: 'Garantia de Milho',
        coffeeWarranty: 'Garantia de Café',
        soyWarranty: 'Garantia de Soja',
      },
      documentation: {
        title: 'Prova de existência de grãos',
        futureTitle: 'Previsão de produção',
      },
      documentationCard: {
        agreementDocTitle: 'Prova de liquidez',
        proofTooltip: 'Informaçōes da fazenda de produção do grão.',
        existanceTooltip: 'Documento que garante a existência do grão',
        contractTooltip: 'Contrato que representa a liquidez do grão',
        futureAgreementDocTitle: 'Contrato de compra e venda',
        agreementDocType: {
          fixedPrice: 'Preço fixo',
          priceToFix: 'Preço a fixar',
          priceToFixAGTK: 'Preço a fixar Agrotoken',
        },
        attachedFile: 'Arquivo anexado:',
        btnEditLabel: 'Editar',
        btnAgreementDocPending: 'Pendente de carregamento',
        buttonLabel: 'Ver mais',
        cdeNumber: 'Número do documento:',
        expirationDate: 'Data do pagamento:',
        intNumberLabel: 'Número do contrato:',
        maxContractDeadline: 'Plazo máximo del contrato',
        maxFixationDeadline: 'Plazo máximo de fijación',
        price: 'Preço do contrato em Reais por saca:',
        futurePrice: {
          USD: 'Preço do contrato em USD por saca:',
          BRL: 'Preço do contrato em Reais por saca:',
        },
        title: 'Prova de existência de grãos #',
        titleInCard: 'Prova de liquidez',
        futureTitleInCard: 'Contrato de compra e venda',
        tonsAgreementDocLabel: 'Toneladas do contrato:',
        tonsCde: 'Toneladas do documento:',
        tonsFromCde: 'Tons a ceder',
        typeLabel: 'Tipo de contrato:',
        cprNumber: 'Número do documento:',
        grainVolume: 'Volume dos grãos',
      },
      documentationOfferCard: {
        actionButton: 'Ver documentação',
        description: 'Cessão de crédito',
        title: 'Cessão de crédito',
      },
      expirationDate: 'Data do pagamento',
      generalData: 'Dados gerais de tokenização:',
      internalNumber: 'Número de solicitud interna:',
      lessTonsVerifiedText:
        'Se você concordar com os tokens a receber, assine a cessão de crédito para a transferência dos direitos de cobrança. Caso não concorde, você pode criar uma nova tokenização.',
      lessTonsVerifiedTitle:
        'Foram verificadas no contrato menos toneladas do que o solicitado:',
      lowHealthRatioTooltip:
        'Foi destokenizado devido a uma quedano valor da garantia de sua tokenização.',
      healthRatioTooltip: 'Tokenização com baixovalor de colateral.',
      noHealthratioTooltip:
        'Esta é a proporção para seus tokenizaçoesem relação a base de precificação.',
      moreInfoModal: {
        mail: 'suporte@agrotoken.io',
        text: 'O período de um dia útil antes do término do contrato é utilizado para a coleta das assinaturas, por todas as partes, do distrato da cessão de crédito. ',
        text2:
          'Portanto, durante esse período, os tokens não poderão ser utilizados em transações.',
        text3: 'Em caso de dúvidas, entre em contato conosco em',
        title:
          'Por que a destokenização automática ocorre um dia útil antes do vencimento?',
        confirm: 'Entendido',
      },
      new: 'Criar nova tokenizaçāo',
      collateralDetokization:
        'Uma destokenização automática foi realizada devido a uma flutuação na garantia de sua tokenização. Você pode criar uma nova tokenização com um novo cálculo de margem de segurança.',
      offerLetter: {
        offerLetterText: 'La carta oferta está lista para que la firmes',
        offerLetterText2:
          ' Con la firma de la carta oferta de cesion de derechos de cobro estas completando el proceso de tokenización',
      },
      offerLetterValidationText:
        'A cessão de crédito está pendente de verificação pela Agrotoken. Enviaremos um e-mail nas próximas 24 horas assim que seus tokens estiverem em sua carteira.',
      offerLetterValidationTitle: 'Cessão de crédito com validação pendente',
      oracle: 'Acopiador selecionado:',
      pendingApproval: {
        text: 'Estamos analisando as condições comerciais do contrato de compra para determinar a',
        text2: ' correspondente e confirmaremos quantos tokens você receberá.',
        title: 'Verificação pendente de toneladas',
        futureTitle: 'Verificação pendente',
      },
      signOfferLetterText:
        'Um e-mail foi enviado para o seu e-mail associado à sua conta Agrotoken com instruções para assinar a cessão de crédito.',
      signOfferLetterTitle: 'Assine a cessão de crédito',
      noSignOfferLetterText:
        'Para continuar o processo de tokenização, o usuário criador da solicitação de tokenização deve assinar a cessão de crédito.',
      noSignOfferLetterTitle:
        'O usuário que criou a solicitação de tokenização deve assinar a cessão de crédito',
      statusText: {
        ASSIGNMENT_NOTIFICATION:
          'A notificação de cessão está sendo assinada pela Agrotoken',
        DETOKENIZATION_IN_PROGRESS:
          'A destokenização está sendo processada em Blockchain',
        IN_PROGRESS_BLOCKCHAIN:
          'A tokenização está sendo processada em Blockchain',
        OFFER_LETTER: 'Cessão de crédito disponível para assinar',
        OFFER_LETTER_VALIDATION:
          'A cessão de crédito está sendo verificada pela Agrotoken',
        PENDING_APPROVAL: 'Verificação pendente de toneladas',
        PENDING_BLOCKCHAIN: 'A tokenização está sendo processada em Blockchain',
        TOKENIZED: 'Tokenização concluída',
      },
      title: 'Resumo da tokenização:',
      tokenizationCompletedText: 'A tokenização foi concluída com sucesso.',
      tokenizationCompletedTitle: 'Tokenização concluída',
      futureTokenizationDetails: {
        currencyLabel: 'Tipo de grão',
        grainTypeLabel: 'Condição dos grãos',
        farmLabel: 'Fazenda',
        oracleLabel: 'Comprador do grão',
        fixationLabel: 'Condição de fixação',
        tonsToTokenizeLabel: 'Toneladas a tokenizar',
        cprExpirationLabel: 'Vencimento de CPR',
        deliveryPeriodLabel: 'Período estimado de entrega dos grãos',
      },
      tonsVerified: {
        supportText1: 'Toneladas verificadas',
        supportText2:
          'toneladas. Se você concordar, prossiga para assinar a cessão de crédito para atribuir os direitos de cobrança.',
        text: 'Menos toneladas do que o solicitado foram verificadas',
      },
    },
    summaryTitle: 'Resumo da tokenização',
    futureSummaryTitle: 'Garantia de',
    CORB: 'Milho',
    SOYB: 'Soja',
    COFB: 'Café',
    futureSummarySubtitle:
      'Aqui você pode visualizar todas as informações sobre sua garantia.',
    informationTab: 'Informações gerais',
    movementsTab: 'Movimentos',
    termsAndConditionsLink:
      'https://agrotoken.notion.site/Termos-e-Condi-es-Espec-ficas-de-Tokeniza-o-e-Transa-o-a2ecbb030bd7458097e3790c51850de2',
    title: 'Resumo da tokenização:',
    tonsRequested: 'Toneladas solicitadas',
  },
  // up here
  tokenizations: {
    modalConfirm: {
      labelBtnClose: 'Cancelar',
      labelBtnConfirm: 'Confirme',
      subtitle: 'Tem certeza de que deseja detokenizar?',
      title: 'Destokenización',
    },
    newTokenization: 'Nova tokenização',
    startTokenization: {
      agreementDoc: 'Contrato de compra e venda',
      agreementDocDescription:
        'Documento entre a comprador e o produtor que apoia a venda de grãos.',
      btnLabelConfirm: 'Iniciar tokenização',
      cde: 'Certificado de depósito eletrônico',
      cdeDescription:
        'Documento que valida a disponibilidade na cobrança das toneladas a serem tokenizadas.',
      currency: 'Que grão você deseja tokenizar?',
      currencyOptions: 'Selecione um tipo de grão',
      emptyInputError: 'Este campo é obrigatório.',
      helpInfoTitle: 'Documentação necessária para tokenizar:',
      oracle: 'Com que comprador você tem o contrato?',
      oracleOptions: 'Selecione seu comprador',
      proofOfExistence: 'Prova de existência de grãos',
      proofOfExistenceDescription:
        'Documento que valida a disponibilidade no Oracle das toneladas a serem tokenizadas.',
      proofOfLiquidity: 'Prova de liquidez',
      proofOfLiquidityDescription:
        'Documento entre comprador do grão e o produtor que apoia a venda de grãos.',
      proofOfOrigin: 'Prova de origem',
      proofOfOriginDescription:
        'Documento que valida a disponibilidade no Oracle das toneladas a serem tokenizadas.',
      subtitle:
        'Você tokeniza seus grãos 100% online, recebe os agrotokens ou garantias em sua carteira digital, \n e já pode começar a utilizá-los para facilitar as suas transações.',
      title: 'Iniciar o processo de tokenização é simples!',
      oracleIsFarmerError: {
        title: 'O comprador deve ser diferente da organização.',
        description:
          'Não é possível seguir com a tokenização desejada pois o CPF/CNPJ do comprador deve ser distinto de sua organização. Para prosseguir, selecione outro comprador. Em caso de dúvidas, entre em contato suporte@agrotoken.io',
      },
    },
    status: {
      consumed: 'Consumido',
      detokenized: 'Destokenizado',
      offerLetter: 'Cessão de crédito',
      pending: 'Pendente',
      pendingApproval: 'Aprovação pendente',
      tokenized: 'Tokenizado',
    },
    table: {
      buttonGroup: {
        all: 'Todas',
        detokenized: 'Destokenizadas',
        pending: 'Pendentes',
        tokenized: 'Tokenizadas',
      },
      filterByStatus: {
        placeholder: 'Filtrar por status',
        detokenized: 'Destokenizado',
        pending: 'Pendente',
        tokenized: 'Tokenizado',
        all: 'Todos',
      },
      columns: {
        actions: 'Ações',
        agreementNumber: 'Contrato',
        alertHealthRatioToolTip: 'Tokenização em alerta de variação de preço.',
        tonsVerified: 'Valor tokenizado',
        amount: 'Tokens disponíveis',
        expirationDate: 'Final do contrato',
        healthRatio: 'Índice de cobertura',
        healthRatioPending:
          'Você poderá visualizar esses dados assimque a tokenização for concluída.',
        healthRatioToolTip:
          'Esta é a proporção para seus tokenizações em relação a base de precificação.',
        noHealthRatio: 'Dados pendentes',
        healthRatioMediumRiskToolTip:
          'Tokenização em alerta devariação de preço. Contactenos.',
        healthRatioLowRiskToolTip:
          'Ela foi desativada devido a uma flutuação na garantia de sua tokenização.',
        oracle: 'Comprador do grão',
        request: 'Solicitaçāo',
        tons: 'Toneladas',
        status: 'Status',
        sacas: 'Sacas',
        token: 'Grão',
        farmName: 'Fazenda',
        cprExpirationDate: 'Expiração da cpr',
        healthRatioModal: 'Saber mais',
      },
      deleteModal: {
        labelBtnClose: 'Cancelar',
        labelBtnConfirm: 'Confirme',
        text: 'Ao confirmar que iremos proceder à eliminação da sua tokenização, concorda?',
        title: 'Remover tokenização',
      },
      destokenizeButtonLabel: 'Destokenizar',
      download: 'Baixar Histórico',
      emptyState: {
        subtitle:
          'Você verá os detalhes aqui assim que fizer sua primeira tokenização.',
        title: 'Você não tem nenhuma tokenização!',
      },
      loadingError: 'Erro ao carregar tokenizações',
      pageControl: {
        btnNextLabel: 'Próximo',
        btnPrevLabel: 'Anterior',
      },
      paginator: {
        next: 'Próximo',
        previous: 'Anterior',
      },
      resultNotFound: {
        title: 'Não foram encontrados resultados!',
        subtitle:
          'Tente ajustar suas opções de pesquisa ou filtros para encontrar o que procura.',
      },

      pendingInformation: 'Dados pendentes',
      noData: 'Dados pendentes',
      renovationModal: {
        labelBtnClose: 'Cancelar',
        labelBtnConfirm: 'Confirme',
        text: 'Ao confirmar estaremos procedendo com a renovação da sua tokenização até a data final do contrato, você concorda?',
        title: 'Confirmação de renovação',
      },
      renovationToolTip: {
        action: 'Renovar',
        reminder:
          'A data de validade está se aproximando, faltam menos de 48 horas para renovar',
      },
      searcherPlaceholder: 'Pesquisar solicitação ou contrato...',
      serverError: {
        text: 'Estamos com problemas em nosso servidor, tente novamente mais tarde.',
        title: 'Ops, algo está errado!',
      },
      status: {
        detokenized: 'Destokenizado',
        inProgress: 'Em processo',
        pending: 'Pendente',
        pendingApproval: 'Verificando',
        requiredAction: 'Ação necessária',
        tokenized: 'Tokenizado',
        preTokenized: 'Documentação pendente',
      },
      subtitle: 'Esta é a visão geral de suas tokenizações',
      title: 'Tokenizações',
      summary: 'Resumo',
    },
    tabs: {
      agrotokens: 'Agrotokens',
      guarantees: 'Garantias',
      fixedGrains: 'Grãos fixados',
    },
    title: 'Tokenizações',
    healthRatioModal: {
      title: 'Índice de cobertura',
      info: 'O índice de cobertura é a proporção para seus tokenizaçoes em relação a base de precificação. Permite entender o estado da sua tokenização em tempo real.',
      ranges: 'Intervalos de segurança de uma tokenização:',
      safe: 'Área segura',
      safeText:
        'A tokenização tem um alto valor de colateral com base no preço dos agrotokens.',
      risk: 'Área de risco',
      riskText:
        'A tokenização tem um baixo valor de colateral. Se continuar caindo, uma destokenização será realizada para restabelecer essa relação.',
      blocked: 'Destokenização',
      blockedText:
        'A tokenização foi destokenizada devido a uma queda no valor da colateral. Caso isso aconteça você pode criar uma nova tokenização.',
      okButton: 'Entendido',
    },
  },
  transferTokens: {
    initialStep: {
      badge: 'Nova funcionalidade',
      btnLabel: 'Seguindo',
      subtitle: 'Estendi a vida útil de seus tokens para enviá-los',
      text: 'Para que seus tokens existam fora da rede Agrotoken, precisamos convertê-los em tokens sem vencimento. Para isso, teremos que lançar o teste de reserva de grãos para que seus tokens sejam sempre apoiados por uma tonelada de grãos.',
      title: 'Enviar Agrotokens',
    },
    nav: 'enviar tokens',
    submitStep: {
      banner: 'Você receberá um e-mail com este resumo assim que confirmado',
      blockchain: {
        subtitle: 'Esta operação pode demorar até 30 segundos...',
        title: 'A remessa está sendo processada no Blockchain',
      },
      btnLabel: 'Confirmar envio',
      equivalence: 'Equivalência em ARS:',
      exchange: 'Intercâmbio:',
      immediately: 'Imediato',
      ipp: 'Índice de passagem em perpetuidade',
      subtotal: 'Subtotal',
      time: 'Tempo estimado de envio:',
      title: 'Resumo de envio',
      total: 'Total a enviar:',
      wallet: 'Carteira de destino:',
    },
    successStep: {
      btnLabel: 'É entendido',
      exchange: 'Intercâmbio:',
      ipp: 'Índice de passagem em perpetuidade',
      sentMail:
        'Enviamos um e-mail com o resumo para o seu e-mail e você também pode vê-lo na sua carteira',
      subtotal: 'Subtotal',
      title: 'Eles foram enviados para a carteira de Ripio',
      total: 'Total enviado:',
      wallet: 'Carteira de destino:',
    },
    terms: {
      title: 'Termos e Condições Particulares do Token Cryptosoja',
      accept: 'Aceito os ',
      termsAndConds: 'termos e condições',
      text: [
        '1. Definiciones: Todos los términos incluidos en estos términos y condiciones particulares del Token Criptosoja (los “Términos y Condiciones Particulares del Token Criptosoja”) que se encuentren en mayúsculas y que no tienen una definición específica, tendrán el significado que se les asigna en los términos y condiciones generales (Términos y Condiciones) y en los términos y condiciones particulares de la tokenización y transacción (los “Términos y Condiciones Particulares de Tokenización y Transacción”) publicados en el sitio web www.agrotoken.io (el “Sitio Web”).',
        '1.1. Boleto: Es el boleto de compraventa o cualquier otro documento de similares características (el “Boleto”) firmado entre el Productor y el Oráculo cuya copia ha sido incorporada al Sitio Web por el Productor y cuyos derechos de cobro han sido cedidos a Tech Demeter en garantía del cumplimiento de las obligaciones asumidas en la Solicitud de Tokenización efectuada por el Productor.',
        '1.2. Precio Token Criptosoja: Cada unidad de Token Criptosoja será equivalente a una tonelada de soja valuada conforme el índice “Agrotoken” publicado  diariamente por MATBA Rofex y que además también se publica en el Sitio Web y en [*].',
        '1.3. Suma a Abonar: Es el monto dinerario equivalente a determinada cantidad de toneladas de soja que el Oráculo adeudará a Tech Demeter en virtud de la emisión de Tokens Criptosoja y su entrega al Productor para la cancelación de los derechos de cobro bajo el Boleto.',
        '1.4. Tokens: Son los activos digitales representativos de los derechos de cobro bajo el Boleto. Cada unidad de Token representa el equivalente al valor de una tonelada de granos.',
        '1.5. Tokens Criptosoja: Son los activos digitales representativos del valor de los granos de soja y respaldados por la Suma a Abonar, siendo que cada unidad de Token Criptosoja será equivalente al Precio del Token Criptosoja.',
        '2. Conversión de Tokens en Tokens Criptosoja y cancelación de los derechos de cobro del Boleto',
        '2.1. El Productor podrá, a su sola discreción, optar por convertir sus Tokens existentes en su billetera virtual abierta en Tech Demeter por nuevos Tokens Criptosoja, los cuales no tendrán vencimiento y podrán ser transaccionados dentro de la Red de Intercambio de Agrotoken (RIA) o bien por fuera de dicha red utilizando billeteras virtuales propias o de terceros.',
        '2.2. A efectos de optar por la conversión indicada en el punto 2.1 de estos Términos y Condiciones Particulares del Token Criptosoja, el Productor deberá seguir el siguiente procedimiento:',
        'a) DESCRIBIR PROCEDIMIENTO OPERATIVO DE CONVERSIÓN.',
        'El Productor podrá optar por convertir sus Tokens en Tokens Criptosoja.',
        'b) El Productor acepta y reconoce que al ejercer dicha opción y presionar el botón “Confirmar Envío” se enviará automáticamente al Oráculo, una instrucción de fijación de precio de los granos según el Boleto respecto de la cantidad de granos involucrados en la conversión de sus Tokens en Tokens Criptosoja, con más el proporcional de los granos aforados al momento de la conversión, y simultáneamente remitirá la solicitud de acreditación de dichos Tokens Criptosoja en su cuenta abierta en Tech Demeter en cancelación de los Tokens. ',
        'c) El ejercicio de dicha opción por parte del Productor importa la cancelación de los derechos de cobro según el Boleto y la acreditación de los tokens Criptosoja en la billetera del Productor importa la revocación de la cesión de derechos de cobro.',
        'd) Asimismo, el Productor podrá, en cualquier momento, instruir a Tech Demeter para que proceda a enviar y acreditar sus Tokens Criptosoja en la billetera virtual que indique, a efectos de que los mismos puedan ser transaccionados fuera de la Red de Intercambio de Agrotoken (RIA).',
        'e) Tech Demeter procederá al quemado de los Tokens, entregando al Productorel Token Criptosoja por subrogación en los derechos del Oráculo y por el mismo valor que los Tokens convertidos, con un descuento equivalente a los gastos en que incurrirá Tech Demeter por la emisión del Token Criptosoja y la retribución descripta en el punto 3.',
        'f) Los sucesivos compradores de los Tokens Criptosoja fuera de la Red de Intercambio de Agrotoken (RIA) no son clientes de Tech Demeter, aunque esta última garantiza la reserva de su valor equivalente y actuará como proveedor de liquidez de los Tokens Criptosoja.',
        '2.3. Al aceptar estos Términos y Condiciones Particulares del Token Criptosoja, el Productor acepta que los derechos de cobro que tiene bajo el Boleto serán pagados directamente por Tech Demeter, por subrogación del Oráculo, mediante la acreditación en su cuenta en Tech Demeter de la cantidad correspondiente de Tokens Criptosoja, quedando obligado Tech Demeter a proveer la liquidez de los Tokens Criptosoja, y el Oráculo obligado frente a Tech Demeter por la Suma a Abonar.',
        '2.4. El Productor, así como cualquier tercero, podrá comprar y vender Tokens Criptosoja a través de una plataforma externa o Exchange  al Precio del Token Criptosoja.',
        '2.5. El plazo máximo hasta el cual se podrá solicitar la conversión de Tokens en Tokens Criptosoja será el plazo máximo de cada Boleto aplicable.',
        '3. Retribución de Tech Demeter: Tech Demeter recibirá de parte del Productor una comisión de entre el 0,1 % hasta 8% del valor de los Tokens Criptosoja por el servicio de emisión (minting). Tech Demeter podrá modificar estos porcentajes de comisiones a su exclusivo criterio.',
        '4. Gastos e impuestos: En caso de corresponder la aplicación de cualquier impuesto a la presente operación, el mismo se encontrará a cargo del Productor y/o el Oráculo. Cada Parte afrontará los impuestos que graven sus ingresos en forma global.',
        '5. Indemnidad: El Productor se compromete a indemnizar y mantener indemne a Tech Demeter, las sociedades vinculadas, sus directivos, empleados y representantes, de y contra cualquier cargo, incluyendo pero no limitándose a cualquier acción ya sea judicial y/o extrajudicial, que resulten del uso que los mismos hagan del Sitio Web, o bien de la infracción por su parte de estos Términos y Condiciones Particulares del Token Criptosoja, debiendo hacerse cargo el Productor de los gastos legales o de cualquier otra naturaleza en los que Tech Demeter, las sociedades vinculadas, sus directivos, empleados y representantes, de y contra cualquier cargo pudieran incurrir. A tal fin, Tech Demeter le notificará puntualmente de cualquier demanda, y/o acción y/o proceso.',
        '6. Ley aplicable y jurisdicción: En caso de controversia, las diferencias serán sometidas a los tribunales competentes de la Ciudad Autónoma de Buenos Aires. El presente se regirá por la ley argentina.',
        '7. Adhesión a los términos y condiciones generales: La aceptación de estos Términos y Condiciones Particulares del Token Criptosoja implican la adhesión y aceptación a los Términos y Condiciones Particulares de la Tokenización y Transacción y de los Términos y Condiciones Generales del Sitio Web.',
        'Estos Términos y Condiciones Particulares del Token Criptosoja fueron actualizados por última vez el [*] de diciembre de 2022.',
      ],
    },
    tokenAmountStep: {
      balance: 'Saldo:',
      btnLabel: 'Próximo passo',
      error: 'Você não tem tokens suficientes para enviar',
      rate: 'Cotação',
      subtitle: 'Eu escolhi o valor e qual token você quer enviar',
      title: 'Quantos tokens você deseja enviar?',
    },
    walletStep: {
      btnLabel: 'Próximo',
      externalWalletSubtitle: 'Para usuários fora do Agrotoken',
      externalWalletTitle: 'Para carteira externa',
      ripioSubtitle: 'Envie para sua carteira digital',
      ripioTitle: 'Para a carteira de Ripio',
      soon: 'Em breve',
      subtitle: 'Selecione uma carteira',
      title: 'Onde você envia?',
    },
  },
  uploadFile: {
    typeError: 'Este tipo de arquivo não é aceito. Carregue um arquivo PDF.',
  },
  user: {
    edition: {
      breadCrumbOpt1: 'Usuários',
      breadCrumbOpt2: 'Editar usuário',
      cancelBtnLabel: 'Cancelar',
      check1: 'Administrador',
      check1text:
        'O usuário poderá criar e editar usuários, acessar o aplicativo, solicitar um cartão VISA e todas as permissões padrão',
      check2: 'Usuário com permissões',
      check2text:
        'Você pode atribuir permissões ao usuário para realizar ações ou limitá-las.',
      editBtnLabel: 'Guardar mudanças',
      emailError: 'O e-mail inserido não está no formato correto',
      emptyEmailError: 'Este campo é obrigatório',
      mailLabel: 'Digite o e-mail',
      mailPlaceholder: 'mail@compañia.com.ar',
      payWithTokensCheck: 'Pague com fichas',
      payWithTokensFootnote:
        'Precisamos validar as informações antes que você possa conceder essa permissão',
      payWithTokensText:
        'O usuário receberá notificações de pagamentos pendentes de sua organização e poderá pagá-los com tokens.',
      recieveTokensBtnLabel: 'Candidatar-se a',
      recieveTokensBtnLabelDone: 'Requeridos',
      recieveTokensCheck: 'Carregar com tokens',
      recieveTokensFootnote: 'Esta funcionalidade é apenas para Merchants.',
      recieveTokensText:
        'O usuário poderá acessar a seção de pagamento onde poderá criar links de pagamento para cobrar com tokens em sua organização',
      section1Title: 'Editar tipo de usuário',
      section2Title: 'Editar permissões',
      subtitle: 'Para onde você vai enviar o convite?',
      text: 'Você pode convidar pessoas para fazer parte',
      title: 'Editar usuário',
      toast: {
        textEdition: 'O usuário foi editado com sucesso',
        titleEdition: 'edição do usuário',
      },
      tokenizationCheck: 'Tokenizar / Detokenizar',
      tokenizationFootnote: 'Documentação validada',
      tokenizationText:
        'O usuário terá acesso à seção de tokenização, onde poderá tokenizar e destokenizar os grãos de sua organização',
      viewWalletCheck: 'Ver Carteira',
      viewWalletFootnote: 'Todos os usuários podem ver a carteira.',
      viewWalletText:
        'O usuário terá acesso à seção Carteira onde poderá ver o saldo da organização e as movimentações de tokens.',
    },
    invitation: {
      breadCrumbOpt1: 'Usuarios',
      breadCrumbOpt2: 'Convidar um novo usuário',
      cancelNewBtnLabel: 'Cancelar',
      check1: 'Administrador',
      check1text:
        'O usuário poderá criar e editar usuários, acessar o aplicativo, solicitar um cartão VISA e todas as permissões padrão',
      check2: 'Usuário com permissões',
      check2text:
        'Você pode atribuir permissões ao usuário para realizar ações ou limitá-las.',
      checkboxError: 'Você deve selecionar uma opção',
      createNewBtnLabel: 'Enviar novo convite',
      emailError: 'O e-mail inserido não está no formato correto',
      emptyEmailError: 'Este campo é obrigatório',
      existingInvitationError:
        'O usuário já recebeu um convite desta organização',
      mailLabel: 'Digite o e-mail',
      mailPlaceholder: 'Digite o e-mail do usuário',
      payWithTokensCheck: 'Pague com tokens',
      payWithTokensFootnote:
        'Precisamos validar as informações antes que você possa conceder essa permissão',
      payWithTokensText:
        'O usuário receberá notificações de pagamentos pendentes de sua organização e poderá pagá-los com tokens.',
      recieveTokensBtnLabel: 'Candidatar-se a',
      recieveTokensBtnLabelDone: 'Requeridos',
      recieveTokensCheck: 'Carregar com tokens',
      recieveTokensFootnote: 'Esta funcionalidade é apenas para comerciantes.',
      recieveTokensText:
        'O usuário poderá acessar a seção de pagamento onde poderá criar links de pagamento para cobrar com tokens em sua organização',
      section1Title: 'Quais usuário você deseja convidar?',
      section2Title: 'Quais permissões o usuário terá?',
      subtitle: 'Para onde você vai enviar o convite?',
      text: 'Você pode convidar pessoas para fazer parte',
      title: 'Convidar um novo usuário',
      toast: {
        textInvitationSent:
          'Enviaremos um e-mail ao usuário para que ele possa continuar com o cadastro.',
        titleInvitationSent: 'O convite foi enviado',
      },
      tokenizationCheck: 'Tokenizar / Detokenizar',
      tokenizationFootnote: 'Documentação validada',
      tokenizationText:
        'O usuário terá acesso à seção de tokenização, onde poderá tokenizar e destokenizar os grãos de sua organização',
      viewWalletCheck: 'Ver Carteira',
      viewWalletFootnote: 'Todos os usuários podem ver a carteira.',
      viewWalletText:
        'O usuário terá acesso à seção Carteira onde poderá ver o saldo da organização e as movimentações de tokens.',
    },
    permission: {
      VIEW_WALLET: {
        label: 'Ver Carteira',
        description:
          'O usuário terá acesso à seção Carteira, onde poderá visualizar os ativos e movimentos da organização.',
      },
      MAKE_TOKENIZATIONS_AND_DETOKENIZATION: {
        label: 'Tokenizar / Destokenizar',
        description:
          'O usuário terá acesso à seção de tokenização, onde poderá tokenizar e destokenizar os ativos de sua organização.',
      },
      MAKE_PAYMENT_IN_KIND: {
        label: 'Pagamento com Tokens',
        description:
          'O usuário receberá notificações de pagamentos pendentes, podendo aprovar a transfêrencia de ativos da organização para terceiros.',
      },
      CREATE_PAYMENT_LINK: {
        label: 'Recebimento com Tokens',
        description:
          'O usuário poderá acessar a seção de pagamentos, onde poderá criar links de pagamento para que terceiros transfiram ativos para a organização.',
      },
    },
    requestMerchantModal: {
      availability: 'Disponibilidade 24/7',
      cancelBtnLabel: 'Talvez mais tarde',
      item1: 'Liquidez imediata',
      item2: 'Simplicidade para coletar',
      item3: 'Disponibilidade 24/7',
      item4: 'Menos intermediários',
      lessIntermediaries: 'Menos intermediários',
      liquidity: 'Liquidez imediata',
      sendBtnLabel: 'Enviar solicitação',
      simplicity: 'Simplicidade para coletar',
      subtitle: 'Benefícios de ser Merchant:',
      text1: 'Envie-nos uma solicitação para converter',
      text2:
        'em Fornecedor e comece a aceitar agrotokens como meio de pagamento.',
      title:
        'Essa funcionalidade exige que você se confirme como um Fornecedor.',
    },
    thankyouModal: {
      labelBtnConfirm: 'Aceitar',
      text: 'Obrigado, em breve entraremos em contato. Se precisar falar conosco, entre em contato com o suporte suporte@agrotoken.io',
      title: 'Solicitação enviada',
    },
  },
  users: {
    btnNextLabel: 'Próximo',
    btnPrevLabel: 'Anterior',
    deleteModal: {
      labelBtnClose: 'Cancelar',
      labelBtnConfirm: 'Confirme',
      text1: 'O usuário',
      text2: 'não vai conseguir acessar',
      text3: 'na plataforma.',
      title: 'Tem certeza de que deseja excluir este usuário?',
    },
    footNote: {
      mail: 'suporte@agrotoken.io ',
      note: 'Contacte-nos em ',
      note2: 'para qualquer problema.',
      subject: 'Entre em contato com o suporte da Agrotoken',
    },
    table: {
      columns: {
        email: 'Correio eletrônico',
        name: 'Nome',
        permissions: 'Permissões',
        status: 'Status',
      },
      destokenizeButtonLabel: 'Destokenizar',
      emptyState: {
        subtitle:
          'Você verá os detalhes aqui assim que fizer sua primeira tokenização',
        title: 'Você não tem nenhuma tokenização!',
      },
      emptyText:
        'Você verá os detalhes aqui assim que fizer seu primeiro convite',
      emptyTitle: 'Você não tem nenhum usuário!',
      inviteUserLabel: 'Convidar novo usuário',
      loadingError: 'Erro ao carregar tokenizações',
      pageControl: {
        btnNextLabel: 'Próximo',
        btnPrevLabel: 'Anterior',
      },
      paginator: {
        next: 'Proximo',
        previous: 'Anterior',
      },
      pendingInformation: 'Informações pendentes',
      renovationToolTip: {
        action: 'Renovar',
        reminder:
          'A data de validade está se aproximando, faltam menos de 48 horas para renovar',
      },
      searcherPlaceholder: 'Pesquise por nome, email ou permissões',
      serverError: {
        text: 'Estamos com problemas em nosso servidor, tente novamente mais tarde.',
        title: 'Ops, algo está errado!',
      },
      subtitle:
        'Aqui você pode ver uma lista com os usuários da organização, convidar novos e excluir ou editar os existentes.',
      tableTitle: 'Lista de usuários',
      title: 'Gerenciamento de usuários',
    },
    toast: {
      text1DeleteUser: 'O usuário associado',
      text2DeleteUser: 'Não vai conseguir acessar',
      text3DeleteUser: 'Na plataforma',
      titleDeleteInviteduser: 'O usuário convidado foi excluído',
      titleDeleteuser: 'O usuário foi excluído',
    },
  },
  verificationPending: {
    acceptBtn: 'Aceitar',
    textMerchantRequestDenied:
      'Informamos que o pedido feito foi rejeitado. Para mais informações contacte support@agrotoken.io',
    textMerchantRequestVerificationPending:
      'Informaremos você o mais rápido possível sobre seu status.',
    textVerificationPending:
      'Estamos validando suas informações para que você possa começar a usar seus tokens. Isso levará cerca de 24 a 48 horas.',
    titleMerchantRequestDenied: 'Solicitação negada',
    titleMerchantRequestVerificationPending:
      'Estamos verificando sua solicitação',
    titleVerificationPending: 'Parabéns! Você concluiu todas as etapas.',
  },
  verifyIdentity: {
    confirmBtn: 'Completar dados',
    textVerifyIdentity:
      'Para acessar essa funcionalidade, precisamos que você preencha algumas informações da sua conta.',
    titleVerifyIdentity: 'Precisamos dos detalhes da sua conta',
  },
  wallet: WALLET_PT,
  detokenization: {
    success: {
      confirmSubtitle: 'A destokenização foi solicitada com sucesso.',
      confirmText:
        'A Agrotoken entrará em contato para enviar o distrato da cessão de crédito para assinatura e concluir a destokenização.',
      summaryDocusignText:
        'Analisaremos o distrato da cessão de crédito e notificaremos o comprador do grão para assinarla. Assim a destokenização será concluída.',
    },
    summary: {
      rescissionDocusignPendingTitle: 'Destokenização pendente de assinatura',
      rescissionDocusignPendingText:
        'Assim que você, Agrotoken e comprador de grão o distrato de cessão de crédito, a destokenização será concluída',
      btnLabel: 'Assine distrato de cessão',
    },
  },
  warrantySelector: {
    title: 'Selecione uma garantia deste productor:',
    emptyState: {
      title: 'Não há garantias disponíveis',
      description1:
        ' Não há garantias disponíveis para o CPF ou CNPJ selecionado.',
      description2:
        'Tente novamente com outra organização ou escolha outro ativo como opção.',
    },
  },
  fixedGrainSelector: {
    title: 'Selecione grãos fixados deste produtor:',
    emptyState: {
      title: 'Não há grãos fixados disponíveis',
      description1:
        'No hay grãos fixados disponibles para el CPF ou CNPJ selecionado.',
      description2:
        'Tente novamente com outra organização ou escolha outro ativo como opción.',
    },
  },
  paymentRequestWarrantyCard: {
    title: {
      SOYB: 'Garantia de Soja',
      CORB: 'Garantia de Milho',
      COFB: 'Garantia de Café',
    },
    viewMore: 'Ver mais',
    price: 'Avaliação disponível:',
    tooltip: 'Equivalência com base no valor do contrato de tokenização.',
    expirationDate: 'Liquidação:',
    availableSacas: 'Sacas disponíveis:',
    score: 'Puntuação:',
    excellent: 'Excelente',
    ruim: 'Ruim',
    farm: 'Local:',
  },
  paymentRequestFixedGrainCard: {
    price: 'Avaliação disponível:',
    availableSacas: 'Sacas disponíveis:',
  },
  warrantyDetail: {
    title: {
      SOYB: 'Garantia de Soja',
      CORB: 'Garantia de Milho',
      COFB: 'Garantia de Café',
    },
    tabs: {
      data: ' Dados de garantia',
      movements: 'Movimientos',
    },
    sections: {
      featured: 'Destaques',
      generalData: 'Dados gerais',
      farmData: 'Dados da fazenda',
    },
    oracle: 'Comprador de grão',
    plantingDate: 'Possível data de plantío',
    movementType: 'Tipo de movimento',
    agreementDocPeriod: 'Período de entrega dos grãos',
    plantedArea: 'Área plantada (ha)',
    farmOwnerType: 'Tipo da posse da terra',
    farmCapacity: 'Capacidade produtiva (ha)',
    farmLocation: 'Local',
    documents: {
      cpr: 'CPR - Cédula de Produto Rural',
      cprAbreviation: 'CPR',
      agreementDoc: 'Contrato',
      contractType: 'Tipo de contrato:',
      documentNumber: 'Número do documento:',
      documentTons: 'Toneladas do documento:',
      paymentDate: 'Data do pagamento:',
      offerLetter: 'Cessão de Crédito',
      stateRegistration: 'Inscrição Estadual',
      car: 'CAR - Cadastro Ambiental Rural',
      carAbreviation: 'CAR',
      registrationNumber: 'Número de matrícula:',
      esg: 'ESG - Enviromental Social Governance',
      tokenizationDocuments: 'Documentação da tokenização',
      farmDocuments: 'Documentação da fazenda',
      viewDocument: 'Ver documentação',
      attachedDocuments: 'Documentação anexada',
      attachedDocumentsSubtitle:
        'Estes documentos contêm informações que afetam diretamente a garantia em relação a riscos, preços e quaisquer modificações que possam ocorrer a partir do momento da tokenização.',
    },
    featuredCards: {
      price: 'Preço',
      expirationDate: 'Data de vencimento',
      avaiableSacas: 'Sacas disponíveis',
      blockedSacas: 'Sacas bloqueadas:',
      scoring: 'Saúde',
      fixCondition: 'Condição de fixação:',
      blocked: 'Bloqueadas',
      viewMore: 'Ver mais',
      status: 'Status',
    },
    status: {
      TOKENIZED: 'Tokenizada',
      PENDING_LIQUIDITY: 'Aguardando liquidação',
      COMPLETED: 'Liquidação concluída',
    },
    tokenizedWarranty: 'Garantia Tokenizada',
    paymentWarranty: 'Garantia Recibida',
    scoring: {
      ruim: 'Ruim',
      excellent: 'Excelente',
    },
  },
  acceptTermAndCondsModal: {
    title: 'Deseja aceitar os termos e condições?',
    confirmBtn: 'Continuar',
    closeBtn: 'Voltar',
  },
  fixedGrain: 'Grãos fixados',
  agrotokens: 'Agrotokens',
  warranties: 'Garantias',
};
