import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Select,
  Text,
} from '@chakra-ui/react';
import { IBusinessActivity } from '@common/types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getBusinessActivities } from '@services/Businesses';

const MainActivity = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboardingLevelThree',
  });

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const { isError, data: activities } = useQuery<IBusinessActivity[]>(
    ['businessActivities'],
    getBusinessActivities
  );

  if (isError) return <div>error al cargar las activities</div>; // TODO

  return (
    <Box>
      <Heading fontSize="xl" mb="2">
        {t('generalInfo.title')}
      </Heading>
      <Grid
        templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
        gap="6"
      >
        <Flex flexDir="column">
          <FormControl isInvalid={Boolean(errors.businessActivityId)}>
            <FormLabel htmlFor="businessActivityId">
              {t('generalInfo.mainActivity.label')}
            </FormLabel>
            <Controller
              control={control}
              name="businessActivityId"
              defaultValue=""
              rules={{ required: t('requiredMsg') }}
              render={({ field }) => (
                <Select
                  id="businessActivityId"
                  placeholder={t('generalInfo.mainActivity.placeholder')}
                  {...field}
                  {...register('businessActivityId', {
                    required: t('requiredMsg'),
                  })}
                >
                  {activities?.map((activity) => (
                    <option key={activity.id} value={activity.id}>
                      {activity.name}
                    </option>
                  ))}
                </Select>
              )}
            ></Controller>
            <FormErrorMessage>
              {errors.businessActivityId?.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
      </Grid>
    </Box>
  );
};

export default MainActivity;
