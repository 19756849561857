import { GrainContractTypes } from '@common/enum';
import { MovementsTabs } from '@common/enum/movements.enum';
import {
  OperationStatus,
  OPERATION_STATUSES,
  SelectOption,
} from '@common/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdType } from 'react-table';
import { Button, Select } from '@chakra-ui/react';
import { ButtonGroup } from '@agrotoken-tech/ui';

interface ToolbarProps<T extends object> {
  setFilter: (columnId: IdType<T>, updater: any) => void;
  setGlobalFilter: (filterValue: any) => void;
  showTabs: boolean;
  onChangeTab?: (value: MovementsTabs) => void;
}

enum MovementStatusOption {
  EMPTY = '',
  ALL = 'ALL',
  PENDING = 'PENDING',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export function MovementsToolbar<T extends object>({
  setFilter,
  setGlobalFilter,
  showTabs,
  onChangeTab,
}: ToolbarProps<T>): React.ReactElement {
  const [MovementTabSelected, setMovementTabSelected] = useState<MovementsTabs>(
    MovementsTabs.ALL
  );
  const [movementStatus, setMovementStatus] = useState<MovementStatusOption>(
    MovementStatusOption.EMPTY
  );

  const onChangeMovementStatus = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const statusSelected = ev.currentTarget.value as MovementStatusOption;
    setMovementStatus(statusSelected);
    setFilter('OperationStatus', movementStatusFilter[statusSelected]);
  };

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  const { t } = useTranslation();

  const movementPendingStatus: OperationStatus[] = [
    OPERATION_STATUSES.PENDING,
    OPERATION_STATUSES.SUCCESSFUL,
    OPERATION_STATUSES.FAILED,
  ];

  const movementStatusFilter: Record<MovementStatusOption, OperationStatus[]> =
    {
      [MovementStatusOption.SUCCESSFUL]: [OPERATION_STATUSES.SUCCESSFUL],
      [MovementStatusOption.FAILED]: [OPERATION_STATUSES.FAILED],
      [MovementStatusOption.PENDING]: [OPERATION_STATUSES.PENDING],
      [MovementStatusOption.ALL]: [...movementPendingStatus],
      [MovementStatusOption.EMPTY]: [],
    };

  const movementStatusOptions: SelectOption[] = [
    {
      value: MovementStatusOption.SUCCESSFUL,
      label: t('wallet.movementsTable.status.completed'),
    },
    {
      value: MovementStatusOption.FAILED,
      label: t('wallet.movementsTable.status.expired'),
    },
    {
      value: MovementStatusOption.PENDING,
      label: t('wallet.movementsTable.status.pending'),
    },
    {
      value: MovementStatusOption.ALL,
      label: t('wallet.movementsTable.status.all'),
    },
  ];

  const handleClickTab = (
    grainContractType: GrainContractTypes | GrainContractTypes[],
    movementTab: MovementsTabs
  ) => {
    setFilter('grainContractType', [grainContractType]);
    setMovementTabSelected(movementTab);
    if (onChangeTab) onChangeTab(movementTab);
  };

  const tabButtons = [
    {
      key: 1,
      grainContractType: [
        GrainContractTypes.REAL,
        GrainContractTypes.FUTURE,
        GrainContractTypes.FIXED_GRAIN,
      ],
      movementTab: MovementsTabs.ALL,
      label: t('wallet.movementsTable.tabs.all'),
    },
    {
      key: 2,
      grainContractType: GrainContractTypes.FUTURE,
      movementTab: MovementsTabs.GUARANTEES,
      label: t('wallet.movementsTable.tabs.guarantees'),
    },
    {
      key: 3,
      grainContractType: GrainContractTypes.FIXED_GRAIN,
      movementTab: MovementsTabs.FIXED_GRAINS,
      label: t('wallet.movementsTable.tabs.fixedGrains'),
    },
    {
      key: 4,
      grainContractType: GrainContractTypes.REAL,
      movementTab: MovementsTabs.AGROTOKENS,
      label: t('wallet.movementsTable.tabs.agrotokens'),
    },
  ];

  const tokenizationTabButtons = tabButtons.map((tabButton) => (
    <Button
      key={tabButton.key}
      onClick={() =>
        handleClickTab(tabButton.grainContractType, tabButton.movementTab)
      }
    >
      {tabButton.label}
    </Button>
  ));

  return (
    <div
      className={`px-5 py-4 flex gap-2 items-center border-b border-gray-200 ${
        showTabs ? 'block' : 'hidden'
      }`}
    >
      <ButtonGroup
        buttons={tokenizationTabButtons}
        selectedId={0}
        size={'md'}
      />
      <div
        className={`flex ml-4 w-2/12 justify-start h-10  ${
          showTabs ? 'block' : 'hidden'
        }`}
      >
        <Select
          name="type"
          onChange={onChangeMovementStatus}
          value={movementStatus}
          height={10}
        >
          <option selected disabled value="">
            {t('wallet.movementsTable.status.placeholder')}
          </option>
          {movementStatusOptions?.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
}
