import { Flex, Hide, Spinner, Text } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ECurrencySymbol } from '@common/enum';
import { PricingCurrency, TCurrencyName } from '@common/types';
import Rate from '@comp/Rates/Rate';
import { useAuth } from '@src/context/authContext';
import { useRates } from '@src/context/ratesContext';

export const Rates: FC = () => {
  const { nationality } = useAuth();
  const { t } = useTranslation();
  const { currencies, isLoadingRates, isErrorRates } = useRates();
  const [rates, setRates] = useState<PricingCurrency[]>();

  useEffect(() => {
    const currenciesFiltered = currencies?.filter(
      (currency: PricingCurrency) =>
        ![
          ECurrencySymbol.BRL,
          ECurrencySymbol.ABRC,
          ECurrencySymbol.ARS,
        ].includes(currency.name as ECurrencySymbol)
    );

    // Move 'USD' at the beginning of the array
    const sortedCurrencies = currenciesFiltered.sort(
      (currency1, currency2) =>
        (currency1.name === (ECurrencySymbol.USD as TCurrencyName) ? -1 : 0) -
        (currency2.name === (ECurrencySymbol.USD as TCurrencyName) ? -1 : 0)
    );

    setRates(sortedCurrencies);
  }, [nationality, currencies]);

  if (isLoadingRates && currencies.length === 0) {
    return (
      <Flex gap="2" alignItems="center">
        <Spinner size="sm" speed="0.75s" color="primary.300" />
        <Text fontSize="xs" color="gray.500">
          {t('rates.isLoading')}
        </Text>
      </Flex>
    );
  }

  if (isErrorRates || !rates) {
    return (
      <Flex gap="2" color="gray.500" alignItems="center">
        <FeatherIcon icon="alert-circle" size="16" />
        <Text fontSize="xs">{t('rates.isError')}</Text>
      </Flex>
    );
  }

  return (
    <>
      <Hide above="sm">
        <Text textColor="gray.900" fontSize="lg" variant="medium">
          {t('rates.title')}
        </Text>
      </Hide>
      <Flex
        gap="2"
        flexWrap="wrap"
        alignItems={['start', 'center']}
        flexDirection={['column', 'row']}
        ml="-8px"
      >
        {rates?.map((currency, index) => (
          <Rate key={index} currency={currency} />
        ))}
      </Flex>
    </>
  );
};
