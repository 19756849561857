import { LenderType } from '../enums';

const getLenderTypeTranslation = (type?: LenderType) => {
  switch (type) {
    case LenderType.ReciprocalGuaranteeSociety:
      return 'endorsements';
    case LenderType.Business:
      return 'credits';
    case LenderType.Bank:
    default:
      return 'loans';
  }
};

export default getLenderTypeTranslation;
