import { PLATFORM_PREFIX } from '@agrotoken/common-utils';

import { IOracle } from '../common/types';
import { http } from '../http-common';

export const getOracles = ({
  queryKey,
}: any): Promise<IOracle[] | undefined> => {
  const [] = queryKey;
  return http.get<IOracle[]>(`/${PLATFORM_PREFIX}/oracles`).then((resp) => {
    const dataResult: IOracle[] | undefined = resp.data;
    return dataResult;
  });
};

export const isMerchantOracle = (fiscalId: string): Promise<boolean> => {
  return http
    .get(`/${PLATFORM_PREFIX}/oracles/${fiscalId}/valid`)
    .then((resp) => {
      const dataResult = resp.data?.isOracle;
      return !!dataResult;
    });
};
