import { Heading, Text } from '@chakra-ui/react';
import { useUser } from '@src/context/userContext';
import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import AddressFields from '@comp/BR/FullOnboarding/Level2/AddressFields';

interface Props {
  disabled?: boolean;
}

const UserAddressSection: VFC<Props> = ({ disabled = false }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.lvl_2',
  });

  const { currentUser } = useUser();
  const address = currentUser?.addresses[0];

  return (
    <>
      <Text size="xl" variant="medium" mb="4" mt="12">
        {t('form.address.legalRepresentativeAddressTitle')}
      </Text>
      <AddressFields
        entityKey="user"
        defaultComplement={address?.complement}
        defaultFiscalAddress={address?.legalAddress}
        defaultStreetNumber={address?.streetNumber}
        defaultNeighborhood={address?.neighborhood}
        defaultPostalCode={address?.postalCode}
        defaultCityId={address?.cityId}
        defaultGeographicalDivisionId={address?.city?.geographicalDivisionId}
        disabled={disabled}
      />
    </>
  );
};

export default UserAddressSection;
