import get from 'lodash.get';
import { ChangeEvent, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { USERS_PATH } from '../../../common/const';
import {
  EBenefitsListTranslation,
  EMerchantRequestStatusId,
  EPayloadStatus,
  EPermission,
  EPermissionCategoryId,
  EUserRole,
  IBusinessUser,
  IMerchantRequest,
  IPermission,
  IUpdateUser,
  TBreadcrumbOption,
} from '../../../common/types';
import Breadcrumb from '../../Global/Breadcrumb';
import { Container } from '../../Global/Container';
import { Title } from '../../Global/Title';
import { useUser } from '../../../context/userContext';
import { Input } from '@comp/Global/Input';
import { Checkbox } from '@comp/Global/Checkbox/Checkbox';
import { Button } from '@comp/Global/Button';
import { BeMerchantRequestModal } from '../../AccessControl/BeMerchantRequestModal';
import { ServerError } from '@comp/Global/ServerError';
import { useMutation, useQuery } from 'react-query';
import { useOutletContext, useParams } from 'react-router-dom';
import { Loader } from '@comp/Global/Loader';
import {
  getAllPermissions,
  getDisplayPermissions,
} from '@services/Permissions';
import {
  getBusinessUser,
  updateBusinessUserPermissions,
} from '../../../services/BusinessUsers';
import { useAccess } from '@src/context/accessContext';
import { getMerchantRequest } from '@services/MerchantRequests';
import { Modal } from '@comp/Global/Modal';
import { FiUserCheck } from 'react-icons/fi';

export const Edition = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpenRequestMerchantModal, setIsOpenRequestMerchantModal] =
    useState<boolean>(false);
  const [isOpenValidationModal, setIsOpenValidationModal] =
    useState<boolean>(false);
  const [isOpenDeniedMerchantModal, setIsOpenDeniedMerchantModal] =
    useState<boolean>(false);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [mutateError, setMutateError] = useState<string>('');
  const [userEditedPermissions, setUserEditedPermissions] = useState<
    IPermission[] | undefined
  >([]);

  const { setOptions } = useOutletContext() as any;

  const breadcrumbOptions: TBreadcrumbOption[] = [
    { label: t('user.edition.breadCrumbOpt1'), to: USERS_PATH },
    { label: `${t('user.edition.breadCrumbOpt2')}`, to: '' },
  ];

  const benefitsList: EBenefitsListTranslation[] = [
    t(`user.requestMerchantModal.${EBenefitsListTranslation.LIQUIDITY}`),
    t(`user.requestMerchantModal.${EBenefitsListTranslation.SIMPLICITY}`),
    t(`user.requestMerchantModal.${EBenefitsListTranslation.AVAILABILITY}`),
    t(
      `user.requestMerchantModal.${EBenefitsListTranslation.LESS_INTERMIDIARIES}`
    ),
  ];

  const { selectedBusiness, selectedBusinessUser, isLoadingUser, errorUser } =
    useUser();

  const {
    isLoading,
    error: businessUserError,
    data: businessUser,
  } = useQuery<IBusinessUser | undefined>(
    ['businessUser', id],
    getBusinessUser
  );

  const {
    isLoading: isLoadingPermissions,
    isError: isErrorPermissions,
    error: errorPermissions,
    data: permissions,
  } = useQuery<IPermission[] | undefined>(
    ['permissions'],
    getDisplayPermissions
  );

  const {
    isLoading: isLoadingAllPermissions,
    error: allPermissionsError,
    data: allPermissions,
  } = useQuery<IPermission[] | undefined>(
    ['allPermissions'],
    getAllPermissions
  );

  const {
    isLoading: isLoadingMerchantRequests,
    data: merchantRequest,
    isError: isErrorMerchantRequests,
  } = useQuery<IMerchantRequest | undefined>(
    ['merchantRequest'],
    getMerchantRequest
  );

  const { isRepresentant, isTransactionable, isMerchant } = useAccess();

  useEffect(() => {
    if (!businessUser) return;
    setUserEditedPermissions(businessUser.permissions);
  }, [businessUser]);

  useEffect(() => {
    if (!userEditedPermissions) return;
    if (
      userEditedPermissions.some(
        (item) =>
          item.permissionCategoryId === EPermissionCategoryId.ADMINISTRATOR
      )
    ) {
      setUserRole(EUserRole.ADMINISTRATOR);
    } else setUserRole(EUserRole.USER_WITH_PERMISSIONS);
  }, [userEditedPermissions]);

  const handleAdminEditionOnChange = (ev: {
    currentTarget: { value: SetStateAction<string | undefined> };
  }) => {
    setUserRole(ev.currentTarget.value);
    if (ev.currentTarget.value) {
      const filteredPermissions = allPermissions?.filter(
        (item) =>
          item.permissionCategoryId === EPermissionCategoryId.ADMINISTRATOR
      );
      const adminBasicPermissions = allPermissions?.filter(
        (item) => item.preload === EPayloadStatus.ENABLED
      );
      setUserEditedPermissions([
        ...filteredPermissions!,
        ...adminBasicPermissions!,
      ]);
    }
  };

  const handleUserWithPermissionsOnChange = (ev: {
    currentTarget: { value: SetStateAction<string | undefined> };
  }) => {
    setUserRole(ev.currentTarget.value);
    if (ev.currentTarget.value && businessUser)
      if (
        businessUser?.permissions.some(
          (item) =>
            item.permissionCategoryId === EPermissionCategoryId.ADMINISTRATOR ||
            item.permissionCategoryId === EPermissionCategoryId.SUPER_ADMIN
        )
      ) {
        setUserEditedPermissions(
          permissions?.filter(
            (item) => item.keyCode === EPermission.VIEW_WALLET
          )
        );
      } else {
        setUserEditedPermissions(businessUser?.permissions);
      }
  };

  const handlePermissionOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    permission: IPermission
  ) => {
    {
      if (e.target.checked) {
        const filteredPermissions = allPermissions?.filter(
          (item) =>
            item.permissionCategoryId === permission.permissionCategoryId
        );
        if (!userEditedPermissions) return;
        setUserEditedPermissions((prevState) => [
          ...prevState!,
          ...filteredPermissions!,
        ]);
      } else
        setUserEditedPermissions((prevState) =>
          prevState?.filter(
            (item) =>
              permission.permissionCategoryId !== item.permissionCategoryId
          )
        );
    }
  };

  const {
    mutate: mutateUpdateBusinessUserPermissions,
    isLoading: isLoadingMutate,
  } = useMutation(updateBusinessUserPermissions, {
    onSuccess: () => {
      setMutateError('');
      navigate(`${USERS_PATH}`, { state: { showEditionToast: true } });
    },
    onError: (err: Error) => {
      setMutateError(err.message);
    },
  });

  const handleSubmitUpdateBusinessUser = async (ev: any) => {
    ev.preventDefault(ev);
    if (!selectedBusiness && !businessUser) return;
    const body = {
      permissionIds: userEditedPermissions?.map((item) => item.id),
    };
    mutateUpdateBusinessUserPermissions({
      id: parseInt(id!),
      body,
    });
  };

  const handleCancel = () => {
    navigate(`${USERS_PATH}`);
  };

  const merchantRequestPending =
    merchantRequest &&
    (merchantRequest.merchantRequestStatusId ===
      EMerchantRequestStatusId.PENDING ||
      merchantRequest.merchantRequestStatusId ===
        EMerchantRequestStatusId.IN_PROGRESS) &&
    !isMerchant;

  const merchantRequestDenied =
    merchantRequest &&
    merchantRequest.merchantRequestStatusId ===
      EMerchantRequestStatusId.DENIED &&
    !isMerchant;

  if (
    isLoading ||
    isLoadingUser ||
    isLoadingAllPermissions ||
    isLoadingPermissions ||
    isLoadingMutate
  )
    return <Loader />;

  if (businessUserError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(businessUserError as any)?.message}
      />
    );
  }

  if (errorUser) {
    return (
      <ServerError title={t('serverError.title')} text={errorUser.message} />
    );
  }

  if (allPermissionsError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(allPermissionsError as any)?.message}
      />
    );
  }

  if (errorPermissions) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(errorPermissions as any)?.message}
      />
    );
  }

  return (
    <Container className="mb-28">
      <div className="flex justify-between items-center pt-4 pb-12">
        <Breadcrumb options={breadcrumbOptions} />
      </div>
      <div className="flex gap-x-12">
        <div className="">
          <Title
            title={`${t('user.edition.title')} ${businessUser?.user.email}`}
            subtitle={`${t('user.edition.text')} ${selectedBusiness?.name}`}
          />
          <div className="w-1/2">
            <p className="text-xl font-medium text-gray-900 mb-4 mt-8">
              {t('user.edition.subtitle')}
            </p>
            <form
              id="invitationEmail"
              onSubmit={handleSubmitUpdateBusinessUser}
            >
              <Input
                className="mb-8"
                name="userEmail"
                label={t('user.edition.mailLabel')}
                fullWidth
                value={businessUser?.user.email}
                type="text"
                disabled
              />
            </form>
          </div>
          <hr />
          <div className="mt-8">
            <p className="text-xl font-medium text-gray-900 mb-4 mt-8">
              {t('user.edition.section1Title')}
            </p>
            <div className="flex justify-start gap-x-4">
              <div>
                <Checkbox
                  id="administrator"
                  onChange={(ev) => handleAdminEditionOnChange(ev)}
                  checked={userRole === EUserRole.ADMINISTRATOR}
                  value={EUserRole.ADMINISTRATOR}
                  name="administrator"
                  type="radio"
                  required
                  label={t('user.edition.check1')}
                  className="mb-4"
                  hintMessage={t('user.edition.check1text')}
                />
                <Checkbox
                  id="userWithPermissions"
                  onChange={handleUserWithPermissionsOnChange}
                  checked={userRole === EUserRole.USER_WITH_PERMISSIONS}
                  value={EUserRole.USER_WITH_PERMISSIONS}
                  name="userWithPermissions"
                  type="radio"
                  required
                  label={t('user.edition.check2')}
                  className="mb-8"
                  hintMessage={t('user.edition.check2text')}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-8 mb-4">
            <p className="text-xl font-medium text-gray-900 mb-4 mt-8">
              {t('user.edition.section2Title')}
            </p>
            <div className="">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
                {permissions?.map((permission) => {
                  return (
                    <div
                      key={permission.id}
                      className={`${
                        EPermission.VIEW_WALLET === permission.keyCode ||
                        userRole === EUserRole.ADMINISTRATOR
                          ? 'bg-gray-50 '
                          : 'bg-white'
                      } border rounded border-gray-200 px-4 pt-4 pb-6`}
                    >
                      <Checkbox
                        id={permission.keyCode}
                        onChange={(e) =>
                          handlePermissionOnChange(e, permission)
                        }
                        checked={userEditedPermissions?.some(
                          (item) =>
                            item.permissionCategoryId ===
                            permission.permissionCategoryId
                        )}
                        name={permission.keyCode}
                        disabled={
                          (!isMerchant &&
                            permission.keyCode ===
                              EPermission.CREATE_PAYMENT_LINK) ||
                          EPermission.VIEW_WALLET === permission.keyCode ||
                          userRole === EUserRole.ADMINISTRATOR
                        }
                        type="checkbox"
                        required
                        label={t(`user.permission.${permission.keyCode}.label`)}
                        className="mb-4"
                      />

                      <p className="text-sm text-gray-500 mb-4">
                        {t(`user.permission.${permission.keyCode}.description`)}
                      </p>
                      {!isMerchant &&
                        isRepresentant &&
                        isTransactionable &&
                        permission.keyCode ===
                          EPermission.CREATE_PAYMENT_LINK && (
                          <>
                            <p className="text-sm font-normal text-gray-700 mb-4">
                              {t('user.invitation.recieveTokensFootnote')}
                            </p>
                            <Button
                              type="button"
                              variant="secondary-gray"
                              label={
                                !merchantRequest
                                  ? t('user.edition.recieveTokensBtnLabel')
                                  : t('user.edition.recieveTokensBtnLabelDone')
                              }
                              onClick={
                                merchantRequestPending
                                  ? () => setIsOpenValidationModal(true)
                                  : merchantRequestDenied
                                  ? () => setIsOpenDeniedMerchantModal(true)
                                  : () => setIsOpenRequestMerchantModal(true)
                              }
                            />
                          </>
                        )}
                      {/* //TODO: DEPENDS ON ONBOARDING STAGE  */}
                      <p className="text-sm font-normal text-gray-700">
                        {EPermission.VIEW_WALLET === permission.keyCode
                          ? t('user.invitation.viewWalletFootnote')
                          : ''}
                      </p>
                    </div>
                  );
                })}
              </div>
              <hr />
              <div className="flex w-1/2 gap-x-4 mt-8">
                <Button
                  form="invitationEmail"
                  type="submit"
                  variant="primary"
                  label={t('user.edition.editBtnLabel')}
                  isLoading={isLoadingMutate}
                />
                <Button
                  onClick={handleCancel}
                  form="invitationEmail"
                  type="button"
                  variant="secondary-gray"
                  label={t('user.edition.cancelBtnLabel')}
                />
              </div>
              {mutateError && (
                <p className="mt-1.5 text-sm text-error-500">{mutateError}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpenDeniedMerchantModal}
        title={t('verificationPending.titleMerchantRequestDenied')}
        text={t('verificationPending.textMerchantRequestDenied')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => setIsOpenDeniedMerchantModal(false)}
        cancelButtonHidden
      />

      <BeMerchantRequestModal
        setIsOpenRequestMerchantModal={setIsOpenRequestMerchantModal}
        isOpen={isOpenRequestMerchantModal}
        title={t('user.requestMerchantModal.title')}
        text={`${t('user.requestMerchantModal.text1')} ${
          selectedBusiness?.name
        } ${t('user.requestMerchantModal.text2')} `}
        subtitle={t('user.requestMerchantModal.subtitle')}
        labelBtnClose={t('user.requestMerchantModal.cancelBtnLabel')}
        labelBtnConfirm={t('user.requestMerchantModal.sendBtnLabel')}
        onClickClose={() => setIsOpenRequestMerchantModal(false)}
        center
      />
      <Modal
        isOpen={isOpenValidationModal}
        title={t('verificationPending.titleMerchantRequestVerificationPending')}
        text={t('verificationPending.textMerchantRequestVerificationPending')}
        labelBtnConfirm={t('verificationPending.acceptBtn')}
        icon={FiUserCheck}
        onClickConfirm={() => setIsOpenValidationModal(false)}
        cancelButtonHidden
      />
    </Container>
  );
};
