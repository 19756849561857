import { Box, Divider, Flex, Skeleton, Text } from '@chakra-ui/react';

import OperationDetailRow, {
  OperationDetailRowProps,
} from './OperationDetailRow';

interface OperationDetailProps {
  title: string;
  date?: string;
  topElementsTitle: string;
  topElements: OperationDetailRowProps[];
  bottomElementsTitle?: string;
  bottomElements?: OperationDetailRowProps[];
  children?: JSX.Element | JSX.Element[];
  isLoading?: boolean;
}

/**
 * @name OperationDetail
 * @description A general component used to display Operation details
 * @param {string} title Main title to display at the top of the component
 * @param {string} date Date to display at the right side of the title
 * @param {string} topElementsTitle The title for the top elements
 * @param {string} bottomElementsTitle The title for the bottom elements
 * @param {OperationDetailRowProps[]} topElements Elements to display at the top part of the component
 * @param {OperationDetailRowProps[]} bottomElements Elements to display at the bottom part of the component
 * @param {JSX.Element | JSX.Element[]} children Optional elements to display under the bottom elements
 * @param {boolean} isLoading Determines if the component is loading or not
 * @returns {React.ReactElement} The react component
 */
const OperationDetail = ({
  title,
  date,
  topElements,
  topElementsTitle,
  bottomElements,
  bottomElementsTitle,
  children,
  isLoading,
}: OperationDetailProps) => {
  const shouldShowBottomElements =
    bottomElementsTitle !== undefined &&
    bottomElements !== undefined &&
    bottomElements.length > 0;

  const shouldShowDate = !!date;

  return (
    <Box
      as="section"
      p="6"
      bgColor="white"
      border="1px"
      borderColor="gray.200"
      borderRadius="4px"
    >
      <Skeleton isLoaded={!isLoading}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="lg" color="gray.900" variant="medium">
            {title}
          </Text>
          {shouldShowDate && (
            <Text size="sm" color="gray.700" variant="regular">
              {date}
            </Text>
          )}
        </Flex>
      </Skeleton>
      <Divider my="6" />
      <Skeleton isLoaded={!isLoading}>
        <Text size="md" color="gray.900" variant="medium" mb="4">
          {topElementsTitle}
        </Text>
      </Skeleton>
      <Flex flexDirection="column" rowGap="4">
        {topElements.map((topElement) => {
          const { element, icon, label } = topElement;

          return (
            <OperationDetailRow
              key={label}
              {...{ element, icon, label, isLoading }}
            />
          );
        })}
      </Flex>
      {shouldShowBottomElements && (
        <>
          <Divider my="6" />
          <Skeleton isLoaded={!isLoading}>
            <Text size="md" color="gray.900" variant="medium" mb="4">
              {bottomElementsTitle}
            </Text>
          </Skeleton>
          <Flex flexDirection="column" rowGap="4">
            {bottomElements.map((bottomElement) => {
              const { element, icon, label } = bottomElement;

              return (
                <OperationDetailRow
                  key={label}
                  {...{ element, icon, label, isLoading }}
                />
              );
            })}
          </Flex>
        </>
      )}
      {children}
    </Box>
  );
};

export default OperationDetail;
