import { Flex } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { UseMutateFunction } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { TRANSFER_PATH } from '@common/const';
import { IBusiness } from '@common/types';

import { TRANSFER_ASSET_TYPES } from '../../types';
import {
  GeneralFormDataSection,
  TransferFormDataSection,
  WarrantyFormDataSection,
} from '../components';
import { useCreateWarrantyTransferData } from '../hooks';
import { UseCreateWarrantyTransferValues } from '../hooks/useCreateWarrantyTransferForm';
import { CreateTransferDto } from '../interfaces';

export const CREATE_WARRANTY_TRANSFER_FORM_ID = 'create-warranty-transfer';

interface CreateWarrantyTransferFormProps {
  form: UseFormReturn<UseCreateWarrantyTransferValues, object>;
  businessSearch: {
    data: IBusiness | undefined;
    isLoading: boolean;
    error: unknown;
  };
  createTransfer: UseMutateFunction<any, unknown, CreateTransferDto, unknown>;
}

const CreateWarrantyTransferForm = ({
  form,
  businessSearch,
  createTransfer,
}: CreateWarrantyTransferFormProps) => {
  const { warranty } = useCreateWarrantyTransferData();
  const navigate = useNavigate();

  const { handleSubmit, getValues } = form;

  const onSubmitHandler = () => {
    const { amountFiat, fiscalId, comments, document } = getValues();

    if (!amountFiat || !fiscalId || !businessSearch.data || !warranty) return;

    const dto: CreateTransferDto = {
      amountFiat: parseFloat(amountFiat),
      assetType: TRANSFER_ASSET_TYPES.WARRANTY,
      toBusinessFiscalId: fiscalId,
      comments,
      documentUuid: document,
      warrantyUuid: warranty?.uuid,
    };

    createTransfer(dto, {
      onSuccess: (data: { uuid: string }) =>
        navigate(`${TRANSFER_PATH}/${data.uuid}`),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      id={CREATE_WARRANTY_TRANSFER_FORM_ID}
    >
      <Flex flexDirection="column" rowGap="6">
        <GeneralFormDataSection form={form} businessSearch={businessSearch} />
        <WarrantyFormDataSection />
        <TransferFormDataSection form={form} />
      </Flex>
    </form>
  );
};
export default CreateWarrantyTransferForm;
