import { AxiosError } from 'axios';

import { ERROR_CODES } from '@common/constants';
import { ServerError } from '@comp/Global/ServerError';
import { GenericError } from '@def/error';

import { UnauthorizedTransfer } from '../components';

interface TransferOwnershipControlProps {
  children: React.ReactNode;
  error: unknown;
}

const TransferOwnershipControl = ({
  children,
  error,
}: TransferOwnershipControlProps) => {
  if (error && typeof error === 'object') {
    const axiosError = error as AxiosError;

    const transferError = axiosError.response?.data as GenericError;

    if ('code' in transferError) {
      switch (transferError.code) {
        case ERROR_CODES.NOT_OWNER:
        case ERROR_CODES.ENTITY_NOT_FOUND:
          return <UnauthorizedTransfer />;

        default:
          return <ServerError />;
      }
    }

    return <ServerError />;
  }

  return <>{children}</>;
};

export default TransferOwnershipControl;
