import { Box, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ResetPasswordNotice: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <Box my="6">
      <Button
        as={Link}
        to="/reset-password"
        variant="linkPrimary"
        size="sm"
        color="primary.700"
      >
        {t('login.resetPasswordMessage')}
      </Button>
    </Box>
  );
};

export default ResetPasswordNotice;
