import { ENationalities } from '@agrotoken/common-utils';
import { Checkbox } from '@agrotoken-tech/ui';
import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@comp/Global/Loader';
import CompleteOnboardingBanner from '@comp/Global/Onboarding/CompleteOnboardingBanner';
import { ServerError } from '@comp/Global/ServerError';
import { useAccess } from '@src/context/accessContext';
import { useAuth } from '@src/context/authContext';

import {
  PAYMENT_PATH,
  RATES_REFETCH_INTERVAL,
  SELECT_ORG,
  TOKENIZATIONS_PATH,
} from '../../common/const';
import {
  EBusinessStructureTypes,
  INavigationLoginState,
} from '../../common/types';
import { useUser } from '../../context/userContext';
import TokenCard from '../AR/Wallet/TokenCard';
import TokenCards from '../BR/Home/TokenCards';
import { Container } from '../Global/Container';
import {
  useBalances,
  useBusiness,
  useWarranties,
  usePreTokenizations,
} from './hooks';
import { Shortcut } from './Shortcut';
import useFixedTokenizations from './hooks/useFixedTokenizations';

export const Home: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    currentUser,
    selectedBusinessId,
    isErrorUser,
    errorUser,
    isLoadingUser,
  } = useUser();

  const { isMerchant } = useAccess();

  const { nationality } = useAuth();

  const intervalRef = useRef<NodeJS.Timeout | undefined>();

  const {
    isLoading: isLoadingBusiness,
    isError: isErrorBusiness,
    error: errorBusiness,
    data: business,
  } = useBusiness();

  const {
    isLoading: isLoadingBalances,
    data: balances,
    isError: isErrorBalances,
    refetch: refetchBalances,
  } = useBalances();

  const { filteredWarranties, onChangeShowAllWarrantiesCheckboxHandler } =
    useWarranties();

  const { data: preTokenizations } = usePreTokenizations();
  const { data: fixedTokenizations } = useFixedTokenizations();

  useEffect(() => {
    refetchBalances();
    const interval = setInterval(() => {
      refetchBalances();
    }, RATES_REFETCH_INTERVAL);

    intervalRef.current = interval;

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (
      !isErrorBusiness &&
      !isErrorUser &&
      currentUser &&
      !selectedBusinessId
    ) {
      navigate(SELECT_ORG);
    }
  }, [isErrorBusiness, isErrorUser, currentUser, selectedBusinessId, navigate]);

  const [returnUrl, setReturnUrl] = useState<string>('');
  const location = useLocation();
  const state = location.state as INavigationLoginState;
  const storedReturnUrl = state?.returnUrl ?? localStorage.getItem('stateUrl');
  if (
    storedReturnUrl &&
    storedReturnUrl !== returnUrl &&
    storedReturnUrl !== 'undefined'
  ) {
    setReturnUrl(storedReturnUrl);
  }

  useEffect(() => {
    if (returnUrl) {
      navigate(returnUrl, {
        replace: true,
        state: { returnUrl: undefined },
      });

      localStorage.removeItem('stateUrl');
    }
  }, [navigate, returnUrl]);

  if (isLoadingBusiness || isLoadingUser || isLoadingBalances)
    return <Loader />;

  if (errorBusiness || isErrorBalances) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(errorBusiness as AxiosError)?.message}
      />
    );
  }

  if (errorUser) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(errorUser as AxiosError)?.message}
      />
    );
  }

  if (!currentUser || !selectedBusinessId) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={t('serverError.text')}
      />
    );
  }

  const shouldShowWarrantiesCheckbox =
    isMerchant && nationality === ENationalities.BR;

  const isCompanyBusiness =
    business?.businessStructureTypeId === EBusinessStructureTypes.COMPANY;

  return (
    <Container data-testid="home-page">
      {isCompanyBusiness && (
        <Text size="lg" color="gray.700">
          {business?.name},
        </Text>
      )}
      <Heading my="1">{`${t('home.header.title')} ${currentUser.firstName} ${
        currentUser.lastName
      }`}</Heading>
      <Text size="lg" color="gray.700" mb="8">
        {t('home.header.subtitle')}
      </Text>

      <CompleteOnboardingBanner />

      <Text size="xl" color="gray.900" mb="4" variant="medium">
        {t('home.shortcut.title')}
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="8">
        <Shortcut
          title={t('home.shortcut.tokenization.title')}
          text={t('home.shortcut.tokenization.text')}
          linkLabel={t('home.shortcut.tokenization.linkLabel')}
          to={TOKENIZATIONS_PATH}
        />
        <Shortcut
          title={t('home.shortcut.payment.title')}
          text={t('home.shortcut.payment.text')}
          linkLabel={t('home.shortcut.payment.linkLabel')}
          to={PAYMENT_PATH}
        />
      </SimpleGrid>
      <Flex justifyContent="space-between" alignItems="center">
        <Text size="xl" color="gray.900" mt="12" mb="4" variant="medium">
          {t('home.actives')}
        </Text>
        {shouldShowWarrantiesCheckbox && (
          <Checkbox
            onChange={onChangeShowAllWarrantiesCheckboxHandler}
            size="sm"
          >
            {t('home.warranties.showAll')}
          </Checkbox>
        )}
      </Flex>

      {nationality === ENationalities.BR && (
        <TokenCards
          {...{
            balances,
            preTokenizations,
            warranties: filteredWarranties,
            fixedTokenizations,
          }}
        />
      )}

      {/* // TODO: Remove this balances component when design is unified for Argentina and Brazil */}
      {nationality === ENationalities.AR && balances && (
        <Box>
          <Box className="mt-8 mb-4">
            <Text
              fontSize={'lg'}
              textColor="gray.900"
              fontFamily={'Larsseit-Medium'}
            >
              {t('home.tokens')}
            </Text>
          </Box>
          <Box
            className="flex gap-8 "
            flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
          >
            {balances?.map((balance, index) => (
              <TokenCard balance={balance} key={'balances' + index} />
            ))}
          </Box>
        </Box>
      )}
    </Container>
  );
};
