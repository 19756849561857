import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@comp/Global/Input';

import { TokenIconRow } from '../../TokenIconRow';

interface PaymentDataSectionProps {
  amount: number;
  children?: ReactNode;
  firstSubmit: boolean;
  isValidAmount: boolean;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  title: string;
  translateType: string;
  isWarranty?: boolean;
}

const PaymentDataSection = ({
  title,
  amount,
  children,
  firstSubmit,
  isValidAmount,
  setAmount,
  translateType,
  isWarranty,
}: PaymentDataSectionProps) => {
  const { t } = useTranslation();

  return (
    <Box className="bg-white border border-gray-200 rounded p-6 mb-6">
      <Text className="text-base text-gray-900 font-medium mb-4">{title}</Text>
      <Input
        name="amount"
        type="number"
        label={t(`createPayment.form.amount.label.${translateType}`)}
        placeholder={t(
          `createPayment.form.amount.placeholder.${translateType}`
        )}
        value={amount || ''}
        onChange={(ev) => setAmount(Math.abs(+ev.currentTarget.value ?? 0))}
        fullWidth
        showError={firstSubmit && !isValidAmount}
        errorMessage={t('createPayment.form.amount.error')}
        isValid={isValidAmount}
      />
      {!isWarranty && (
        <Flex alignItems={'center'} alignContent={'center'} className="mt-3">
          <TokenIconRow />
          <Text
            color={'gray.700'}
            fontSize={'sm'}
            textAlign={'center'}
            className="ml-1"
          >
            {t('createPayment.form.amount.allowedTokens')}
          </Text>
        </Flex>
      )}
      {children}
    </Box>
  );
};

export default PaymentDataSection;
