import {
  ENationalities,
  getAccountNumberFromCbu,
  getBankEntityNumberFromCbu,
} from '@agrotoken/common-utils';
import {
  Divider,
  Flex,
  Heading,
  Stack,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { FULL_ONBOARDING_LEVEL_3, HOME_PATH } from '@src/common/const';
import {
  EBusinessStructureTypes,
  EFullOnboardingLevel,
  EIdentificationTaxTypes,
  IDocumentPreview,
  OnboardingLevel3RequestDto,
  UpdateBusinessUserDto,
} from '@src/common/types';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { updateBusinessUser } from '@services/BusinessUsers';
import { updateBusiness } from '@services/Businesses';
import {
  saveOnboardingLevel3,
  updateOnboardingLevel3,
} from '@services/FullOnboarding';
import ChangeRequestedAlert from '../common/ChangeRequestedAlert';
import FullOnboardingProgress from '../common/FullOnboardingProgress';
import Form from '../common/layouts/Form';
import {
  getBusinessRequestedChangesFromBackOffice,
  hasValues,
} from '../common/utils';
import { IFinalBeneficiary } from '../types';
import FinalBeneficiaries from './ FinalBeneficiaries/ FinalBeneficiaries';
import BankDetailsFields from './BankDetailsFields';
import FinalStepModal from './FinalStepModal';
import LoadDefaultsEffect from './LoadDefaultsEffect';
import MainActivity from './MainActivity';
import UIFRadio from './UIFRadio';
import { UIF_RADIO } from './consts';
import { useTranslation } from 'react-i18next';

// 📝 Note: by now we don't have multiple bank accounts for business, but our DB is ready to handle it
const indexOfSelectedBankAccount = 0;
const dniIdentificationType = 1;
const cuitIdentificationTaxType = EIdentificationTaxTypes.CUIT;

const BankingAndBilling = () => {
  const { nationality } = useAuth();
  const { selectedBusiness, selectedBusinessUser } = useUser();
  const navigate = useNavigate();
  const isCompany =
    selectedBusiness?.businessStructureTypeId ===
    EBusinessStructureTypes.COMPANY;
  const [defaultUifDocument, setDefaultUIFDocument] =
    useState<IDocumentPreview>();
  const [uifDocumentId, setUIFDocumentId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const changeRequest =
    selectedBusiness &&
    getBusinessRequestedChangesFromBackOffice(selectedBusiness);
  const { t } = useTranslation();

  const { hasChangeRequests, changeRequestMessages } = changeRequest || {};

  const canEditBeneficiaries = !hasChangeRequests;

  const {
    isOpen: isModalOpen,
    onOpen: handleOpenModal,
    onClose: handleCloseModal,
  } = useDisclosure();

  const formMethods = useForm({
    criteriaMode: 'all',
  });

  const { handleSubmit, watch } = formMethods;
  const uifRadioValue = watch(UIF_RADIO);
  const handleSubmitForm = (data: any) => {
    setIsLoading(true);
    data[UIF_RADIO] = Number(data[UIF_RADIO]);
    data.businessBankAccounts[indexOfSelectedBankAccount].accountNumber =
      getAccountNumberFromCbu(
        data.businessBankAccounts[indexOfSelectedBankAccount].cbu
      );
    data.businessBankAccounts[indexOfSelectedBankAccount].entityNumber =
      getBankEntityNumberFromCbu(
        data.businessBankAccounts[indexOfSelectedBankAccount].cbu
      );

    const documentIds = uifDocumentId ? [uifDocumentId] : [];

    const { beneficiaryBusinesses, documents, ...formData } = data;

    const emptyFiltered = beneficiaryBusinesses.filter(
      (beneficiary: IFinalBeneficiary) => hasValues(beneficiary, ['pep'])
    );

    const beneficiaries = emptyFiltered.map((beneficiary: any) => ({
      ...beneficiary,
      identificationTypeId: dniIdentificationType,
      identificationTaxTypeId: cuitIdentificationTaxType,
    }));

    const body: UpdateBusinessUserDto = {
      fullOnboardingLevelId: EFullOnboardingLevel.LEVEL3,
    };
    // TODO: [EJ]
    // This is one hell of a promise concatenation. It certainly shouldn't be done this way.
    // For the sake of removing this, we've added the `OnboardingLevels` module in Core-Api.
    // There, we have a `saveOnboardingLevel3` and this `updateOnboardingLevel3` method used down here.
    // Since this would take some effort to refactor, we didn't move on with it for the time being, but the key
    // idea here would be to wrap all this information (Business + Business User + Intermediate tables) and just
    // execute the `saveOnboardingLevel3` method. Then, in the backend, it must do whatever is needed.
    // This will certainly simplify this, sorry we didn't handly it better from the start, too many hands and changing
    // priorities on this.
    // PD: The `updateOnboardingLevel3` right now just updates the validationStatus of the related entities. It should
    // be also merged into the `saveOnboardingLevel3` when the refactor is done.
    updateBusinessUser({
      id: selectedBusinessUser?.id ?? 0,
      body,
    }).then(() => {
      updateBusiness({
        id: selectedBusiness?.id ?? 0,
        body: {
          ...formData,
          businessActivityId: Number(formData.businessActivityId),
          ...(canEditBeneficiaries
            ? { beneficiaryBusinesses: beneficiaries }
            : {}),
          ...(documentIds.length ? { documentIds } : {}),
        },
      })
        .then(() => {
          if (hasChangeRequests)
            updateOnboardingLevel3({ businessId: selectedBusiness?.id ?? 0 });
        })
        .then(() => {
          // Save data in onboarding_level3_requests table
          const { cbu, alias } =
            data.businessBankAccounts[indexOfSelectedBankAccount] ?? {};
          const requestData: OnboardingLevel3RequestDto = {
            mainActivity: data.mainActivity,
            cbu,
            alias,
            ddjjSobligated: Boolean(data[UIF_RADIO]),
            ddjjSobligatedDocumentId: data[UIF_RADIO] ? uifDocumentId : null,
            ...(canEditBeneficiaries ? { beneficiaries } : {}),
          };
          saveOnboardingLevel3(requestData)
            .then(() => handleOpenModal())
            .finally(() => setIsLoading(false));
        });
    });
  };

  const saveAndCompleteLater = () => {
    // TODO: We need to model the 'draft' mode, that lets the user save a preview but without confirming that the
    // onboarding is ready to be reviewed.
    navigate(HOME_PATH);
  };

  useEffect(() => {
    if (nationality === ENationalities.BR) navigate(FULL_ONBOARDING_LEVEL_3);
  }, [nationality]);

  return (
    <FormProvider {...formMethods}>
      <FinalStepModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      />
      <LoadDefaultsEffect setDefaultUIFDocument={setDefaultUIFDocument} />
      <Form
        isLoading={isLoading}
        handleSubmit={handleSubmit(handleSubmitForm)}
        rightSideContent={<FullOnboardingProgress currentStep={3} />}
        secondaryAction={saveAndCompleteLater}
      >
        <Flex px="4" flexDir="column" w="full">
          <Heading size="sm" variant="medium" mb="1.5">
            {t('onboardingLevelThree.title')}
          </Heading>
          <Text variant="regular">{t('onboardingLevelThree.subtitle')}</Text>

          {hasChangeRequests && changeRequestMessages && (
            <ChangeRequestedAlert messages={changeRequestMessages} />
          )}
          <Heading mt="16" fontSize="2xl">
            Datos Generales
          </Heading>
          <Stack spacing="4" mt="4">
            <MainActivity />
            <UIFRadio
              key={uifRadioValue}
              uifDocumentId={uifDocumentId}
              setUifDocumentId={setUIFDocumentId}
              defaultUifDocument={defaultUifDocument}
            />
            {isCompany && (
              <>
                <Divider my="6" />
                <FinalBeneficiaries canEdit={canEditBeneficiaries} />
              </>
            )}
            <Divider my="6" />
            <BankDetailsFields
              indexOfSelectedBankAccount={indexOfSelectedBankAccount}
            />
          </Stack>
          <Divider my="6" />
        </Flex>
      </Form>
    </FormProvider>
  );
};
export default BankingAndBilling;
