import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { IFees } from '@common/types';

/** Estimates Merchant Fees on Payment request creation */
export const estimateMerchantFeeAmountInPaymentRequestCreation = (
  businessFee: IFees | undefined,
  amount: number | undefined,
  isPaymentRequestInWarranties: boolean,
  areFeesInPaymentInWarrantiesActive: boolean
): number => {
  if (!businessFee || !amount) {
    return 0;
  }
  if (isPaymentRequestInWarranties && !areFeesInPaymentInWarrantiesActive) {
    return 0;
  }

  return roundValue({
    value: amount * (+businessFee.receiverFee / 100),
    operationType: EPaymentRequestOperationType.MERCHANT_FEE,
    scale: 2,
  }).toNumber();
};

export const estimateMerchantFeeAmountInReceivedPaymentDetail = (
  merchantFee: number | undefined,
  amount: number
): number => {
  if (!merchantFee) {
    return 0;
  }
  const roundedEstimatedFeeAmount = roundValue({
    value: amount * (merchantFee / 100),
    operationType: EPaymentRequestOperationType.MERCHANT_FEE,
    scale: 2,
  }).toNumber();
  return roundedEstimatedFeeAmount;
};
