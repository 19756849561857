import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

interface Props {
  title?: string;
  text?: string;
}

export const ServerError = ({ title, text }: Props) => {
  const { t } = useTranslation();

  const errorTitle = title || t('serverError.title');
  const errorText = text || t('serverError.text');
  return (
    <div className="flex flex-col items-center justify-center gap-x-4 text-gray-400  mt-10 text-center">
      <FeatherIcon icon="alert-circle" size={48} />
      <p className="text-xl font-medium text-gray-900 mt-2">{errorTitle}</p>
      <p className="text-md font-normal text-gray-500 mt-1">{errorText}</p>
    </div>
  );
};
