import { ENationalities } from '@agrotoken/common-utils';
import { Button, Icon, Box } from '@chakra-ui/react';
import { IGrainContract } from '@common/types';
import { useAuth } from '@src/context/authContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';

interface Props {
  className?: string;
  onClickDetokenize?: () => void;
  onClickRenew?: () => void;
  onClickMoreInfo?: () => void;
  canRenew?: boolean;
  grainContract: IGrainContract;
  tonsCompletlyUsed?: boolean;
  isFutureGrain: boolean;
  expiredControl?: any;
}

export const Actions: FC<Props> = ({
  onClickMoreInfo,
  className = '',
  onClickDetokenize,
  onClickRenew,
  canRenew,
  grainContract,
  tonsCompletlyUsed,
  isFutureGrain,
  expiredControl,
}) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();

  return (
    <div
      className={`bg-white border border-gray-200 py-6 px-6 rounded ${className}`}
    >
      <p className="text-medium font-normal text-gray-900 mb-1.5">
        {tonsCompletlyUsed || !isFutureGrain
          ? t('tokenization.summary.actions.title')
          : ''}
      </p>
      <p className="text-sm font-normal text-gray-700 mb-4">
        {canRenew ? (
          t('tokenization.summary.actions.canRenewText')
        ) : tonsCompletlyUsed || !isFutureGrain ? (
          t('tokenization.summary.actions.text')
        ) : (
          <div className="flex gap-2">
            <Icon boxSize={4} color="gray.700" as={FiAlertCircle} />
            {t('tokenization.summary.actions.tonsRemainingText')}
          </div>
        )}
      </p>
      {nationality === ENationalities.BR &&
        (tonsCompletlyUsed || !isFutureGrain) && (
          <Box mb={4}>
            <p className="text-sm font-normal text-gray-700 inline mr-1">
              {t('tokenization.summary.actions.alert')}
              {expiredControl.date}.
            </p>
            <Button
              as="u"
              textColor={'primary.700'}
              variant="linkPrimary"
              size="sm"
              onClick={onClickMoreInfo}
            >
              {t('tokenization.summary.actions.alertMoreInfo')}
            </Button>
          </Box>
        )}

      <div className="flex gap-3">
        {(tonsCompletlyUsed || !isFutureGrain) && (
          <Button variant="secondary" onClick={onClickDetokenize}>
            {t('tokenization.summary.actions.detokenize')}
          </Button>
        )}
        {nationality === ENationalities.AR && canRenew && (
          <Button variant="secondary" onClick={onClickRenew}>
            {t('tokenization.summary.actions.renew')}
          </Button>
        )}
      </div>
    </div>
  );
};
