import { EUnits, IMovement } from '@common/types';
import { formatNumber } from '@common/utils';
import { formatDate } from '@common/utils/format-date';
import { useAuth } from '@src/context/authContext';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { TitleSubtitle } from '@comp/Global/TitleSubtitle';
import MovementsTable from '@comp/Wallet/MovementsTable';
import { Text } from '@chakra-ui/react';

interface IMovementRow extends IMovement {
  remainingTons?: number;
}
interface Props {
  filteredMovements: IMovementRow[];
}

const FutureGrainMovements: FC<Props> = ({ filteredMovements }) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();

  // Calculate remainingTons for each row.
  for (let i = 0; i < filteredMovements.length; i++) {
    // Initial tokenization, we take the complete amount, and substract/add from that.
    if (i === 0) {
      filteredMovements[i].remainingTons = Number(filteredMovements[i].amount);
      continue;
    }

    // We substract/add from the previous remaining amount.
    filteredMovements[i].remainingTons =
      (filteredMovements[i - 1].remainingTons ?? 0) +
      Number(filteredMovements[i].amount);
  }

  const futureGrainMovements: Column<IMovementRow>[] = React.useMemo(
    () => [
      {
        Header: t('wallet.movementsTable.columns.transactionType'),
        accessor: 'operation', // accessor is the "key" in the data
        Cell: (props: any) => {
          const movement = props.row.original;
          return (
            <Text size="sm" variant="medium">
              {movement.movementLabel?.displayName}
            </Text>
          );
        },
      },
      {
        Header: t('wallet.movementsTable.columns.amount'),
        id: 'amount',
        accessor: 'amount',
        Cell: (props: any) => {
          const { amount } = props.row.original;
          const formattedAmount = formatNumber(amount, EUnits.CRYPTO);
          return (
            <p
              className={`text-sm font-normal ${
                amount > 0 ? 'text-primary-700' : 'text-error-600'
              }`}
            >
              {`${amount > 0 ? '+' : ''}${formattedAmount} ${t(
                'tokenization.summary.breakDown.tons'
              )}`}
            </p>
          );
        },
      },
      {
        Header: t('tokenization.summary.columns.equivalence'),
        id: 'equivalence',
        accessor: 'amount',
        Cell: (props: any) => {
          const { conversions } = props.row.original;

          const conversion = conversions.find(
            (conversion: any) => conversion.unit === EUnits.BRL
          );

          return (
            <p
              className={`text-sm font-normal ${
                +conversion.amount > 0 ? 'text-primary-700' : 'text-error-600'
              }`}
            >
              R$ {formatNumber(conversion.amount, conversion.unit)}
            </p>
          );
        },
      },
      {
        Header: t('wallet.movementsTable.columns.date'),
        accessor: 'createdAt',
        Cell: (props: any) => (
          <TitleSubtitle
            title={formatDate(+props.row.original.createdAt, 'DD/MM/YY')}
            subtitle={formatDate(+props.row.original.createdAt, 'hh:mm A')}
          />
        ),
      },
      {
        Header: t('tokenization.summary.columns.remainingTons'),
        id: 'remainingTons',
        accessor: 'remainingTons',
        Cell: (props: any) => {
          const { remainingTons } = props.row.original;
          const formattedAmount = formatNumber(remainingTons, EUnits.CRYPTO);
          return <Text variant="regular">{formattedAmount}</Text>;
        },
      },
    ],
    [t]
  );

  return (
    <MovementsTable
      showTabs={false}
      columns={futureGrainMovements}
      data={filteredMovements}
    />
  );
};

export default FutureGrainMovements;
