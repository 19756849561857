import { Box } from '@chakra-ui/react';
import { ECurrencySymbol } from '@common/enum';
import { TCurrencyName } from '@common/types';
import { FC } from 'react';
import backgroundImageCor from '../../../assets/images/CardCOR.png';
import backgroundImageSoy from '../../../assets/images/CardSOY.png';
import backgroundImageWhe from '../../../assets/images/CardWHE.png';

interface TokenIconProps {
  token: string;
  className?: string;
}

export const TokenCardBackground: FC<TokenIconProps> = ({
  token,
  className = '',
}) => {
  const getSvg = () => {
    switch (token) {
      case ECurrencySymbol.SOYA:
      case ECurrencySymbol.SOYB:
        return (
          <img
            src={backgroundImageSoy}
            alt={'SOY card'}
            className="w-full h-44"
          />
        );
      case ECurrencySymbol.CORA:
      case ECurrencySymbol.CORB:
        return (
          <img
            src={backgroundImageCor}
            alt={'COR card'}
            className="w-full h-44"
          />
        );
      case ECurrencySymbol.WHEA:
        return (
          <img
            src={backgroundImageWhe}
            alt={'Whe card'}
            className="w-full h-44"
          />
        );
      default:
        break;
    }
  };

  return <Box className={className}>{getSvg()}</Box>;
};
