import {
  Flex,
  Heading,
  Text,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from '@agrotoken-tech/ui';
import NewCard from '@src/components/svgs/card-new.svg';
import FeatherIcon from 'feather-icons-react';
import { useCards } from '@src/context/cardsContext';
import { BusinessUserCards, Card } from '../../interfaces/card';
import { useState, VFC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface HeaderProps {
  currentUserCards: Card[] | undefined;
  otherBuWithCards: BusinessUserCards[] | undefined;
  handleDownloadFile: () => void;
  isLoading?: boolean;
}

const Header: VFC<HeaderProps> = ({
  isLoading,
  currentUserCards,
  otherBuWithCards,
  handleDownloadFile,
}) => {
  const { t } = useTranslation();
  const { showCardShipmentForm, setShowCardShipmentForm } = useCards();
  const userWithNoPersonalCards = !currentUserCards && otherBuWithCards;
  const userHasPersonalCards = currentUserCards;
  const { enableSettlementDownload } = useFlags();

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  return (
    <>
      <Flex
        justifyContent={'space-between'}
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'initial', md: 'center' }}
        columnGap="4"
        rowGap="2"
        mt="5"
        paddingBottom={isMobile ? 5 : 7}
        borderBottom={'1px solid'}
        borderBottomColor="gray.200"
      >
        <Heading size="md" color="gray.900" variant="medium">
          {t('cards.cardList.header.title')}
        </Heading>
        <Flex gap={3}>
          {userWithNoPersonalCards && (
            <Button
              width={isMobile ? '50%' : ''}
              gap={2}
              variant={'primary'}
              onClick={() => setShowCardShipmentForm(true)}
            >
              <Image src={NewCard} />
              {t('cards.cardList.header.requestButton')}
            </Button>
          )}
          {enableSettlementDownload && (
            <Button
              isLoading={isLoading}
              onClick={() => handleDownloadFile()}
              width={
                userHasPersonalCards && isMobile
                  ? '100%'
                  : isMobile && userWithNoPersonalCards
                  ? '50%'
                  : ''
              }
              gap={2}
              variant={'secondary'}
            >
              <FeatherIcon icon={'download'} color="gray.600" size="20" />
              {t('cards.cardList.header.downloadButton')}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
