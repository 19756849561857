import { Button } from '@chakra-ui/react';
import { IDocumentPreviewForBusinessUser } from '@src/common/types';
import { useState, VFC, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUpload } from 'react-icons/fi';
import FileUploader from './FileUploader';

interface Props {
  documentTypeId: number;
  documentIds: number[];
  setDocumentIds: Dispatch<SetStateAction<number[]>>;
  description: string;
  defaultFiles?: any;
  isValid: boolean;
}
const MultipleFileUploader: VFC<Props> = ({
  documentTypeId,
  documentIds,
  setDocumentIds,
  description,
  defaultFiles = [],
  isValid,
}) => {
  const { t } = useTranslation();

  const [uploaders, setUploaders] = useState(
    new Array(defaultFiles?.length || 1).fill(null)
  );

  const defaultFilesIds = defaultFiles?.map(
    ({ documentId, id }: any) => documentId ?? id
  );

  useEffect(() => {
    setDocumentIds(defaultFilesIds as any);
    setUploaders(new Array(defaultFiles?.length || 1).fill(null));
  }, [defaultFilesIds?.join('')]);

  const handleAddDocumentId = (index: number) => (documentId: number) => {
    const prev = [...documentIds];
    prev[index] = documentId;
    const noFalsyIds = prev.filter(Boolean);

    setDocumentIds(noFalsyIds);
  };

  const addUploader = () => {
    setUploaders((prev) => [...prev, null]);
  };

  return (
    <>
      {uploaders.map((_, index) => {
        const shouldShowDescription = index === 0;

        const setDocumentId = handleAddDocumentId(index) as Dispatch<
          SetStateAction<number | null>
        >;

        const documentObject = (
          defaultFiles as IDocumentPreviewForBusinessUser[]
        )?.[index]?.document;

        const defaultFile = documentObject
          ? documentObject
          : (defaultFiles?.[
              index
            ] as unknown as IDocumentPreviewForBusinessUser['document']);

        const hasDefaultValue = Boolean(defaultFile);
        return (
          <FileUploader
            documentTypeId={documentTypeId}
            setDocumentId={setDocumentId}
            documentId={documentIds[index] || null}
            key={index}
            description={shouldShowDescription ? description : ''}
            {...(hasDefaultValue ? { defaultFile } : {})}
            isValid={isValid}
          />
        );
      })}
      <Button
        leftIcon={<FiUpload />}
        onClick={addUploader}
        w="fit-content"
        mt="6"
      >
        {t('fullOnboarding.multipleFileUploader.addNewDocument')}
      </Button>
    </>
  );
};

export default MultipleFileUploader;
