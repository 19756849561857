import { useState } from 'react';
import { useQuery } from 'react-query';
import { PricingCurrency } from '@common/types';
import { getAllCurrenciesByRegion } from '@services/Currencies';
import { useAuth } from '@src/context/authContext';

export const useGetAllCurrenciesByRegion = () => {
  const { nationality } = useAuth();
  const [currencies, setCurrencies] = useState<PricingCurrency[]>([]);
  const [errorRates, setErrorRates] = useState<{ message: string } | null>(
    null
  );

  const { isLoading, isError, refetch } = useQuery<any | undefined>(
    ['convertedPrice'],
    async () => await getAllCurrenciesByRegion(nationality),
    {
      onSuccess: (data) => {
        setCurrencies(data);
        setErrorRates(null);
      },
      onError: (error) => setErrorRates(error as { message: string }),
    }
  );

  return {
    isLoading,
    isError,
    errorRates,
    refetch,
    currencies,
    setCurrencies,
  };
};

export default useGetAllCurrenciesByRegion;
