import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import ResetPasswordNotice from './ResetPasswordNotice';
import FeatherIcon from 'feather-icons-react';

interface Props {
  onSubmit: (ev: ChangeEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  isAuthorized: boolean;
  errorMessage: string;
}

const Form: FC<Props> = ({
  onSubmit,
  isLoading = false,
  email,
  setEmail,
  password,
  setPassword,
  isAuthorized,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const [showPassword, { toggle: toggleShowPassword }] = useBoolean(false);

  return (
    <form onSubmit={onSubmit}>
      <FormControl>
        <FormLabel htmlFor="email">{t('login.form.email.label')}</FormLabel>
        <Input
          id="email"
          mb="5"
          h="12"
          type="email"
          autoComplete="username"
          placeholder={t('login.form.email.placeholder')}
          value={email}
          onChange={(ev) => {
            setEmail(ev.currentTarget.value);
          }}
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="password">
          {t('login.form.password.label')}
        </FormLabel>
        <InputGroup>
          <InputRightElement onClick={() => toggleShowPassword()} h="full">
            <FeatherIcon
              icon={showPassword ? 'eye-off' : 'eye'}
              size={20}
              color="var(--chakra-colors-gray-500)"
            />
          </InputRightElement>
          <Input
            id="password"
            name="password"
            value={password}
            h="12"
            autoComplete="current-password"
            onChange={(ev) => {
              setPassword(ev.currentTarget.value);
            }}
            type={showPassword ? 'text' : 'password'}
            placeholder={t('login.form.password.placeholder')}
          />
        </InputGroup>
      </FormControl>
      {!isAuthorized && (
        <p className="text-sm text-error-500 mt-2">
          {t('login.form.invalidCredentialsMessage')}
        </p>
      )}
      {errorMessage && (
        <p className="text-sm text-error-500 mt-2">{errorMessage}</p>
      )}
      <ResetPasswordNotice />

      <Button
        variant="primary"
        size="lg"
        w="full"
        type="submit"
        isLoading={isLoading}
        disabled={isLoading}
      >
        {t('login.form.buttonLabel')}
      </Button>
    </form>
  );
};

export default Form;
