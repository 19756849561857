import { Box, Spinner, Tooltip } from '@chakra-ui/react';
import { Input } from '@comp/Global/Input';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IWarehouse } from '../../../common/types';
import { Icon } from '@comp/Global/Icon';
import { EmptyState } from '@comp/Global/EmptyState';

interface Props {
  searchDataValue: string;
  setSearchDataValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  isValidSearchData: boolean;
  searchErrorMessage: string;
  shouldShowError: boolean;
  warehouses: IWarehouse[] | undefined;
  selectedWarehouse: IWarehouse | undefined;
  setSelectedWarehouse: React.Dispatch<
    React.SetStateAction<IWarehouse | undefined>
  >;
  onClick?: () => void;
  isLoadingWarehouses: boolean;
  isDisabled?: boolean;
}

const SearcherBr: FC<Props> = ({
  warehouses,
  searchDataValue,
  setSearchDataValue,
  isValidSearchData,
  searchErrorMessage,
  shouldShowError,
  selectedWarehouse,
  setSelectedWarehouse,
  isLoadingWarehouses,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  const onChangeSearch = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDataValue(ev.currentTarget.value);
  };

  const handleEraseSearcher = () => {
    setSearchDataValue('');
    setSelectedWarehouse(undefined);
  };

  const handleWarehouseClick = (warehouse: IWarehouse) => {
    setSelectedWarehouse(warehouse);
    setSearchDataValue(warehouse.name);
  };

  const errorMessage = {
    empty: t('tokenization.proofOfExistence.form.errorMessage.empty'),
    letters: t('tokenization.proofOfExistence.form.errorMessage.min'),
  };

  return (
    <div className="relative">
      <div className="flex items-center group relative">
        <p className="text-sm">
          {t('tokenization.proofOfExistence.form.search')}
        </p>
        <Tooltip
          label={t('tokenization.proofOfExistence.form.searchMessage')}
          hasArrow
          placement="top-start"
          gutter={14}
          shouldWrapChildren
        >
          <Icon
            size={'sm'}
            color={'gray'}
            theme={'no-background'}
            icon="help-circle"
          />
        </Tooltip>
      </div>
      <div className="">
        <Input
          handleEraseClick={() => handleEraseSearcher()}
          canErase={isValidSearchData}
          autoComplete="on"
          icon="search"
          name="search"
          placeholder={t(
            'tokenization.proofOfExistence.form.searchPlaceholder'
          )}
          fullWidth
          value={searchDataValue ? searchDataValue : ''}
          onChange={onChangeSearch}
          isValid={isValidSearchData}
          errorMessage={errorMessage.empty}
          showError={shouldShowError}
          type="text"
          disabled={isDisabled}
        />
      </div>
      <div
        className={
          searchDataValue === undefined
            ? 'hidden'
            : searchDataValue &&
              `w-full  ${
                isLoadingWarehouses && !selectedWarehouse
                  ? 'flex justify-center py-10'
                  : selectedWarehouse
                  ? 'hidden'
                  : 'h-80'
              } bg-white absolute z-20 overflow-auto border rounded shadow-xs placeholder:text-gray-500 focus-visible:outline-0 disabled:bg-gray-50 disabled:text-gray-500`
        }
      >
        {!selectedWarehouse && isLoadingWarehouses && <Spinner />}
        {!selectedWarehouse &&
          !isLoadingWarehouses &&
          warehouses?.length === 0 && (
            <EmptyState
              color="primary"
              theme="light-circle"
              title={t('tokenizations.table.resultNotFound.title')}
              text={t('tokenizations.table.resultNotFound.subtitle')}
              ilustration="cloud"
              className="py-6"
            />
          )}
        {!selectedWarehouse && (
          <div>
            {warehouses?.map((warehouse) => (
              <div className="hover:bg-gray-50" key={warehouse.id}>
                <button
                  type="button"
                  className="w-full rounded flex items-center justify-start"
                  onClick={() => handleWarehouseClick(warehouse)}
                >
                  <Box
                    px="3.5"
                    py="2.5"
                    display="flex"
                    flexDir="column"
                    alignItems="baseline"
                  >
                    <p className="text-md font-medium text-gray-900 text-left">
                      {warehouse.name}
                    </p>
                    <p className="text-sm font-normal text-gray-500 text-left">
                      {warehouse.address}
                    </p>
                  </Box>
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearcherBr;
