import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PAYMENT_PATH } from '@common/const';
import { formatNumber } from '@common/utils';
import { TokenIcon } from '@comp/Global/TokenIcon';
import { CurrencyCode, EUnits, Loan, TCurrencyName } from '@src/common/types';

interface Props {
  loan: Loan;
}
const LoanCard: FC<Props> = ({ loan }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="items-start p-4 w-1/5 border mr-6 border-gray-200 shadow-md overflow-x-auto bg-white h-full flex flex-col justify-center">
      <TokenIcon token={loan.token.name as TCurrencyName} size="lg" />
      <div className="font-normal text-sm mt-4">
        {t('wallet.warrantyTokens.cardTitle')}
      </div>

      <div className="font-medium text-lg mt-1">
        {`${loan.tokensLockedTotal} ${loan.token.name}`}
      </div>
      <div className="font-normal text-sm mt-1">{`≈ ${formatNumber(
        loan.amountToFinanceFiat,
        EUnits.ARS
      )}
			${CurrencyCode[loan.country]}`}</div>
      <button
        className="text-primary-700 mt-4"
        onClick={() => navigate(`${PAYMENT_PATH}/${loan.paymentRequestId}`)}
      >
        {t('wallet.warrantyTokens.more')}
      </button>
    </div>
  );
};

export default LoanCard;
