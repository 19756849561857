import {
  EBackOfficeValidationStatus,
  IBusiness,
  IBusinessUser,
  IUserKYCData,
} from '@src/common/types';
import { ITimeLineItem } from '@comp/TimeLine/TimeLine';

export const getTimelineItems = ({
  isCompany = false,
  currentStep,
  isInvited = true,
  isAdminInvited = false,
}: {
  isCompany?: boolean;
  isInvited?: boolean;
  isAdminInvited?: boolean;
  currentStep: number;
}): ITimeLineItem[] => {
  const isSecondStep = currentStep === 2;
  const isThirdStep = currentStep === 3;
  const timelineItems: any[] = [
    {
      text: '1. Datos Generales',
      status: 'completed',
    },
    {
      text: `2. Datos Personales ${isCompany ? 'y de la empresa' : ''}`,
      status: isSecondStep ? 'active' : 'completed',
    },
    ...(isInvited && !isAdminInvited
      ? []
      : [
          {
            text: '3. Datos bancarios y tributarios',
            status: isThirdStep ? 'active' : 'pending',
          },
        ]),
  ].filter(Boolean);

  return timelineItems;
};

export const hasValues = (object: object, skipKeys: string[] = []): boolean => {
  const entries = Object.entries(object);
  return entries.some(([key, value]) => {
    if (skipKeys.includes(key)) {
      return false;
    }
    return Boolean(value);
  });
};

type BusinessRequestedChanges = {
  hasChangeRequests: boolean;
  changeRequestMessages: string[];
  businessHasChangesRequest: boolean;
  businessBankAccountHasChangesRequest: boolean;
};
export const getBusinessRequestedChangesFromBackOffice = (
  selectedBusiness: IBusiness,
  indexOfSelectedBankAccount = 0
): BusinessRequestedChanges => {
  const changeRequestMessages: string[] = [];

  const businessHasChangesRequest =
    selectedBusiness?.validationStatusId ===
    EBackOfficeValidationStatus.CHANGE_REQUEST;

  businessHasChangesRequest &&
    selectedBusiness?.validationMessage &&
    changeRequestMessages.push(selectedBusiness?.validationMessage);

  const businessBankAccountHasChangesRequest =
    selectedBusiness?.businessBankAccounts?.[indexOfSelectedBankAccount]
      ?.validationStatusId === EBackOfficeValidationStatus.CHANGE_REQUEST;

  const bankAccountChangeRequestMessage =
    selectedBusiness?.businessBankAccounts?.[indexOfSelectedBankAccount]
      ?.validationMessage;

  businessBankAccountHasChangesRequest &&
    bankAccountChangeRequestMessage &&
    changeRequestMessages.push(bankAccountChangeRequestMessage);

  const hasChangeRequests =
    businessHasChangesRequest || businessBankAccountHasChangesRequest;

  return {
    hasChangeRequests,
    changeRequestMessages,
    businessHasChangesRequest,
    businessBankAccountHasChangesRequest,
  };
};

type UserRequestedChanges = {
  hasChangeRequests: boolean;
  userChangeRequestMessages: string[];
  userHasChangeRequest: boolean;
  businessAddressHasChangesRequest: boolean;
  businessUserHasChangeRequest: boolean;
};
export const getUserRequestedChangesFromBackOffice = (
  user: IUserKYCData,
  selectedBusinessUser: IBusinessUser,
  selectedBusiness: IBusiness
): UserRequestedChanges => {
  const userChangeRequestMessages: string[] = [];
  const userHasChangeRequest =
    user?.validationStatusId === EBackOfficeValidationStatus.CHANGE_REQUEST;
  userHasChangeRequest &&
    user?.validationMessage &&
    userChangeRequestMessages.push(user?.validationMessage);

  const businessUserHasChangeRequest =
    selectedBusinessUser?.validationStatusId ===
    EBackOfficeValidationStatus.CHANGE_REQUEST;
  businessUserHasChangeRequest &&
    selectedBusinessUser?.validationMessage &&
    userChangeRequestMessages.push(selectedBusinessUser?.validationMessage);

  const businessAddressHasChangesRequest =
    selectedBusiness?.businessAddress?.validationStatusId ===
    EBackOfficeValidationStatus.CHANGE_REQUEST;

  businessAddressHasChangesRequest &&
    selectedBusiness?.businessAddress?.validationMessage &&
    userChangeRequestMessages.push(
      selectedBusiness?.businessAddress?.validationMessage
    );

  const hasChangeRequests =
    userHasChangeRequest ||
    businessUserHasChangeRequest ||
    businessAddressHasChangesRequest;

  return {
    hasChangeRequests,
    userChangeRequestMessages,
    businessAddressHasChangesRequest,
    userHasChangeRequest,
    businessUserHasChangeRequest,
  };
};
