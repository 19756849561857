import { ENationalities } from '@agrotoken/common-utils';
import { Box, Button, Text } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { PaymentRequestAsset } from '@common/enum';

import { maxSizeFileBytes } from '@comp/Global/DragAndDrop/consts';
import { PaymentLayout, Title } from '@comp/PaymentRequestView/Fragments';
import {
  estimateMerchantFeeAmountInPaymentRequestCreation,
  estimateMerchantFeePercentageInPaymentRequestCreation,
} from '@comp/PaymentRequestView/utils';
import { FixedGrain } from '@features/cards/interfaces/fixedGrain';

import { getWarrantyPricePerSaca } from '@services/GrainContracts';
import { useAuth } from '@src/context/authContext';
import { NO_FEES, PAYMENT_PATH } from '../../../common/const';
import {
  EPaymentRequestType,
  EUnits,
  IBusiness,
  ICreatePaymentRequestDto,
  IFees,
  IGrainContract,
  IPaymentRequest,
  IPaymentRequestForwardingsDto,
  TBreadcrumbOption,
} from '../../../common/types';
import { useUser } from '../../../context/userContext';
import useDebounce from '../../../hooks/useDebounce';
import {
  getBusinessByFiscalId,
  getBusinessFee,
} from '../../../services/Businesses';
import { createPaymentRequest } from '../../../services/PaymentRequests';
import {
  CreatePaymentFormAR,
  CreatePaymentFormBR,
} from '../Fragments/CreatePaymentForm';
import { EmptyState } from '../Fragments/Summary/EmptyState';
import { PaymentSummaryReceiver } from '../Receiver/PaymentSummary';
import TermsAndConditions from './TermsAndConditions';
import { useForwardingsValidations } from './hooks';
import { CreatePaymentRequestProps, PaymentForwardingFormData } from './types';

// TODO: Review usage of states to simplify.
const CreatePaymentRequest: FC<CreatePaymentRequestProps> = ({
  paymentRequestType,
}) => {
  // ===== Hooks =====
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const navigate = useNavigate();
  const { currentUser, selectedBusinessId, selectedBusiness } = useUser();
  const { applyFeesOnPaymentInWarranties } = useFlags();
  const [fiscalId, setFiscalId] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [documentId, setDocumentId] = useState<number | null>(null);
  const [businessFee, setBusinessFee] = useState<IFees | undefined>(NO_FEES);
  const [firstSubmit, setFirstSubmit] = useState<boolean>(false);
  const [firstCheck, setFirstCheck] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false);
  const [selectedWarranty, setSelectedWarranty] = useState<
    IGrainContract | undefined
  >();
  const [selectedFixedGrain, setSelectedFixedGrain] = useState<
    FixedGrain | undefined
  >();
  const [isChecked, setIsChecked] = useState(false); // Marks if an element is selected.
  const [isPayButtonEnabled, setIsPayButtonEnabled] = useState<boolean>(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);
  const debouncedFiscalId = useDebounce(fiscalId, 1000);
  const [asset, setAsset] = useState<PaymentRequestAsset | undefined>(
    nationality === ENationalities.AR ? PaymentRequestAsset.TOKENS : undefined
  );
  const [forwardings, setForwardings] = useState<PaymentForwardingFormData[]>(
    []
  );

  const estimatedMerchantFeeAmount: number =
    estimateMerchantFeeAmountInPaymentRequestCreation(
      businessFee,
      amount,
      asset,
      applyFeesOnPaymentInWarranties
    );
  const merchantFeePercentage: number =
    estimateMerchantFeePercentageInPaymentRequestCreation(
      businessFee,
      asset,
      applyFeesOnPaymentInWarranties
    );

  const { areValidForwardings } = useForwardingsValidations({
    amountToReceive: amount - estimatedMerchantFeeAmount,
    creatorFiscalId: selectedBusiness?.fiscalId as string,
    forwardings,
    payerFiscalId: fiscalId,
    country: nationality,
    paymentType: paymentRequestType as EPaymentRequestType,
  });

  // ===== Queries | Mutations =====
  const {
    data: fees,
    isLoading: isLoadingFees,
    isError: isErrorFees,
  } = useQuery<IFees>(['businessFee'], () =>
    getBusinessFee(selectedBusinessId ?? NaN)
  );

  const { data: pricePerSaca, isLoading: isLoadingPricePerSaca } = useQuery(
    ['pricePerSaca', { selectedWarranty }],
    async () => {
      let fetchedData;
      if (selectedWarranty) {
        fetchedData = await getWarrantyPricePerSaca(selectedWarranty.id);
      }
      return fetchedData;
    }
  );

  useEffect(() => {
    if (
      paymentRequestType === EPaymentRequestType.STANDARD &&
      !isLoadingFees &&
      !isErrorFees
    ) {
      setBusinessFee(fees);
    }
  }, [fees, isLoadingFees, isErrorFees, paymentRequestType]);

  const {
    data: checkedBusiness,
    isError: isErrorCheck,
    isLoading: isChecking,
  } = useQuery<IBusiness | null>(['business', debouncedFiscalId], async () => {
    if (!debouncedFiscalId) return null;
    const business = await getBusinessByFiscalId(debouncedFiscalId);
    setFirstCheck(true);
    return business;
  });

  const {
    mutate: mutateCreatePaymentRequest,
    isLoading: isLoadingCreatePaymentRequest,
  } = useMutation(createPaymentRequest, {
    onSuccess: (data: IPaymentRequest) => {
      navigate(`${PAYMENT_PATH}/${data.uuid}`);
    },
    onError: (err: Error) => {
      setError(err.message);
    },
  });

  // ===== Constants =====
  const isValidFiscalId = !!checkedBusiness;
  const isValidAmount = !!amount;
  let translateType: string;
  switch (paymentRequestType) {
    case EPaymentRequestType.IN_KIND:
      translateType = 'kind';
      break;
    case EPaymentRequestType.FUTURE_WARRANTY:
      translateType = 'warranty';
      break;
    case EPaymentRequestType.STANDARD:
    default:
      translateType = 'regular';
      break;
  }

  const breadcrumbOptions: TBreadcrumbOption[] = [
    { label: t('breadCrumb.paymentRequests'), to: PAYMENT_PATH },
    {
      label:
        paymentRequestType === EPaymentRequestType.IN_KIND
          ? t('breadCrumb.createPaymentInKind')
          : t('breadCrumb.createPaymentRequest'),
      to: `${PAYMENT_PATH}/create-payment`,
    },
  ];

  const isDisabled = nationality === ENationalities.AR;

  const isWarrantyAmountEnough =
    !!selectedWarranty?.equivalences?.amount[EUnits.BRL] &&
    new Decimal(selectedWarranty?.equivalences?.amount[EUnits.BRL]).greaterThan(
      new Decimal(amount)
    );

  const isFixedGrainAmountEnough =
    !!selectedFixedGrain?.equivalences.amount[EUnits.BRL] &&
    new Decimal(selectedFixedGrain.equivalences.amount[EUnits.BRL]).greaterThan(
      new Decimal(amount)
    );

  // ===== Effects =====
  useEffect(() => {
    if (!paymentRequestType && !isLoadingFees && !isErrorFees) {
      setBusinessFee(fees);
    }
  }, [fees, isLoadingFees, isErrorFees, paymentRequestType]);

  useEffect(() => {
    setIsPayButtonEnabled(
      !!amount &&
        !!debouncedFiscalId &&
        hasAcceptedTerms &&
        (asset === PaymentRequestAsset.TOKENS ||
          (asset === PaymentRequestAsset.FIXED_GRAIN &&
            isFixedGrainAmountEnough) ||
          (asset === PaymentRequestAsset.WARRANTY &&
            !!selectedWarranty &&
            isWarrantyAmountEnough)) &&
        areValidForwardings
    );
  }, [
    isWarrantyAmountEnough,
    debouncedFiscalId,
    amount,
    selectedWarranty,
    asset,
    hasAcceptedTerms,
    areValidForwardings,
    isFixedGrainAmountEnough,
  ]);

  // ===== Helpers =====

  const handleCreatePaymentRequest = () => {
    let type: EPaymentRequestType = EPaymentRequestType.STANDARD;
    if (paymentRequestType === EPaymentRequestType.IN_KIND) {
      type = EPaymentRequestType.IN_KIND;
    }
    if (selectedWarranty) {
      type = EPaymentRequestType.FUTURE_WARRANTY;
    }
    if (selectedFixedGrain) {
      type = EPaymentRequestType.FIXED;
    }

    setFirstSubmit(true);
    if (!isValidFiscalId || !isValidAmount || !areValidForwardings) return;

    const forwardingsDto: IPaymentRequestForwardingsDto[] = forwardings.map(
      (forwarding) => {
        return {
          toBusinessFiscalId: forwarding.fiscalId,
          grossAmount: forwarding.amount,
          concept: forwarding.concept ?? null,
          documentUuid: forwarding.documentUuid ?? null,
        };
      }
    );

    const createPaymentRequestDto: ICreatePaymentRequestDto = {
      amount: amount,
      createdByUserId: currentUser?.id ?? NaN,
      fromBusinessId: selectedBusinessId ?? NaN,
      toBusinessFiscalId: fiscalId,
      description: comment,
      documentId,
      type: type,
      warrantyId: selectedWarranty?.id,
      fixedGrainUuid: selectedFixedGrain?.uuid,
      forwardings: forwardingsDto,
    };
    mutateCreatePaymentRequest(createPaymentRequestDto);
  };

  const handleSelectedWarranty = () => {
    setAsset(PaymentRequestAsset.WARRANTY);
    setShowEmptyState(false);
    setSelectedFixedGrain(undefined);
  };

  const handleSelectedToken = () => {
    setAsset(PaymentRequestAsset.TOKENS);
    setSelectedWarranty(undefined);
    setShowEmptyState(false);
    setIsChecked(false);
    setSelectedFixedGrain(undefined);
  };

  const handleSelectedFixedGrain = () => {
    setAsset(PaymentRequestAsset.FIXED_GRAIN);
    setSelectedWarranty(undefined);
    setShowEmptyState(false);
    setIsChecked(false);
  };

  const forwardingsAmountSum = forwardings.reduce((acc, forwarding) => {
    return acc + Number(forwarding.amount);
  }, 0);

  const shouldShowSummary =
    amount &&
    (asset === PaymentRequestAsset.TOKENS ||
      (asset === PaymentRequestAsset.FIXED_GRAIN && selectedFixedGrain) ||
      (asset === PaymentRequestAsset.WARRANTY &&
        !isLoadingPricePerSaca &&
        pricePerSaca));

  return (
    <PaymentLayout
      customWidth={{ main: 'md:w-1/2' }}
      breadcrumbOptions={breadcrumbOptions}
      header={<Title title={t(`createPayment.title.${translateType}`)} />}
      main={
        <>
          {/* ARG */}
          {nationality === ENationalities.AR && (
            <CreatePaymentFormAR
              translateType={translateType}
              fiscalId={fiscalId}
              setFiscalId={setFiscalId}
              isChecking={isChecking}
              firstCheck={firstCheck}
              isValidFiscalId={isValidFiscalId}
              firstSubmit={firstSubmit}
              checkedBusiness={checkedBusiness}
              isErrorCheck={isErrorCheck}
              amount={amount}
              setAmount={setAmount}
              isValidAmount={isValidAmount}
              asset={asset}
              receiverFee={merchantFeePercentage}
              comment={comment}
              setComment={setComment}
              isInKind={paymentRequestType === EPaymentRequestType.IN_KIND}
              setDocumentId={setDocumentId}
              documentId={documentId}
              maxSizeFileBytes={maxSizeFileBytes}
              forwardings={forwardings}
              setForwardings={setForwardings}
              estimatedReceiverFeeAmount={estimatedMerchantFeeAmount}
            />
          )}

          {/* BRA */}
          {nationality === ENationalities.BR && (
            <CreatePaymentFormBR
              translateType={translateType}
              fiscalId={fiscalId}
              setFiscalId={setFiscalId}
              isChecking={isChecking}
              firstCheck={firstCheck}
              isValidFiscalId={isValidFiscalId}
              firstSubmit={firstSubmit}
              checkedBusiness={checkedBusiness}
              isErrorCheck={isErrorCheck}
              isDisabled={isDisabled}
              isWarranty={asset === PaymentRequestAsset.WARRANTY}
              isTokenized={asset === PaymentRequestAsset.TOKENS}
              showEmptyState={showEmptyState}
              handleSelectedWarranty={handleSelectedWarranty}
              handleSelectedToken={handleSelectedToken}
              handleSelectedFixedGrain={handleSelectedFixedGrain}
              setSelectedWarranty={setSelectedWarranty}
              selectedWarranty={selectedWarranty}
              setSelectedFixedGrain={setSelectedFixedGrain}
              selectedFixedGrain={selectedFixedGrain}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              amount={amount}
              setAmount={setAmount}
              isValidAmount={isValidAmount}
              asset={asset}
              receiverFee={merchantFeePercentage}
              isEnoughAmount={isWarrantyAmountEnough}
              comment={comment}
              setComment={setComment}
              paymentRequestType={paymentRequestType}
              setDocumentId={setDocumentId}
              documentId={documentId}
              maxSizeFileBytes={maxSizeFileBytes}
            />
          )}
        </>
      }
      rightSide={
        <Box>
          {/* Summary */}
          {shouldShowSummary ? (
            <PaymentSummaryReceiver
              amount={amount}
              paymentAsset={asset ?? PaymentRequestAsset.TOKENS}
              tax={estimatedMerchantFeeAmount}
              status={asset === undefined ? 'EMPTY' : 'READY'}
              isPayer={false}
              warrantyReceptionDate={selectedWarranty?.expirationDate}
              warrantyPricePerSaca={pricePerSaca}
              receiverFee={new Decimal(merchantFeePercentage)}
              forwardingsAmountSum={forwardingsAmountSum}
              finalPrice={selectedFixedGrain?.agreementDoc.finalPrice}
            />
          ) : (
            <EmptyState isPayer={false}></EmptyState>
          )}
          {/* T&C */}
          <TermsAndConditions
            setHasAcceptedTerms={setHasAcceptedTerms}
            hasAcceptedTerms={hasAcceptedTerms}
            isDisabled={amount ? false : true}
          />
          {/* Submit */}
          <Button
            variant="primary"
            type="submit"
            size="lg"
            disabled={!isPayButtonEnabled}
            isLoading={isLoadingCreatePaymentRequest}
            onClick={handleCreatePaymentRequest}
            w={'100%'}
          >
            {t(`createPayment.form.submitBtnLabel.${translateType}`)}
          </Button>
          {/* Errors */}
          {error && (
            <Text className="mt-1.5 text-sm text-error-500 text-right">
              {error}
            </Text>
          )}
        </Box>
      }
    />
  );
};

export default CreatePaymentRequest;
