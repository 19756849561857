import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { EUnits, IMovement } from '@common/types';
import { formatNumber } from '@common/utils';
import { formatDate } from '@common/utils/format-date';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { TitleSubtitle } from '@comp/Global/TitleSubtitle';
import MovementsTable from '@comp/Wallet/MovementsTable';
import { useAuth } from '@src/context/authContext';

import { useTokenizationMovements } from './useTokenizationMovements';

interface FixedGrainMovementsProps {
  tokenizationUuid: string;
}

const FixedGrainMovements = ({
  tokenizationUuid,
}: FixedGrainMovementsProps) => {
  const { t } = useTranslation();

  const {
    data: grainContractMovements,
    error: grainContractMovementsError,
    isLoading: isLoadingGrainContractMovements,
  } = useTokenizationMovements(tokenizationUuid);

  const movementsColumns: Column<IMovement>[] = [
    {
      Header: t('wallet.movementsTable.columns.transactionType'),
      accessor: 'operation',
      Cell: (props: any) => {
        const movement = props.row.original;

        return (
          <Text size="sm" variant="medium">
            {movement.movementLabel?.displayName}
          </Text>
        );
      },
    },
    {
      Header: t('wallet.movementsTable.columns.amount'),
      id: 'amount',
      accessor: 'amount',
      Cell: (props: any) => {
        const { amount, operation } = props.row.original;
        const formattedAmount = formatNumber(amount, EUnits.TONS);
        return (
          <Text size="sm" color={'gray.600'}>
            {`${amount > 0 ? '+' : ''}${formattedAmount} TONS`}
          </Text>
        );
      },
    },
    {
      Header: t('wallet.movementsTable.columns.date'),
      accessor: 'createdAt',
      Cell: (props: any) => (
        <TitleSubtitle
          title={formatDate(+props.row.original.createdAt, 'DD/MM/YY')}
          subtitle={formatDate(+props.row.original.createdAt, 'hh:mm A')}
        />
      ),
    },
  ];

  if (isLoadingGrainContractMovements) return <Loader />;

  if (grainContractMovementsError) {
    return <ServerError title={t('serverError.title')} />;
  }

  return (
    <>
      {grainContractMovements && (
        <MovementsTable
          showTabs={false}
          columns={movementsColumns}
          data={grainContractMovements}
        />
      )}
    </>
  );
};

export default FixedGrainMovements;
