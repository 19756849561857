import { ISO_CURRENCIES, TokenCurrency } from '@agrotoken/common-utils';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Origin } from '@common/types';
import { BlockchainState } from '@comp/Global/BlockchainState';
import GenericTransactionLayout from '@layouts/GenericTransactionLayout';
import { useUser } from '@src/context/userContext';

import { WarrantyTransferSummaryDetail } from '../../transfers/components';
import { TransferOwnershipControl } from '../security';
import { TRANSFER_STATUSES } from '../types';
import {
  AcceptTransferModal,
  Alert,
  CancelTransferModal,
  ConditionalButtons,
  ErrorTransferToast,
  RejectTransferModal,
  WarrantyTransferDetailHeader,
  WarrantyTransferOperationDetail,
} from './components';
import {
  useIsInBlockchainChecker,
  useTransferDetail,
  useTransferTriggerer,
} from './hooks';
import { getAlertPropsByStatus } from './utils';

const TransferDetail = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.details',
  });
  const { uuid } = useParams();
  const { selectedBusiness } = useUser();

  // Toasts handlers
  const {
    isOpen: isOpenErrorTransferToast,
    onClose: onCloseErrorTransferToast,
    onOpen: onOpenErrorTransferToast,
  } = useDisclosure();

  // Modals handlers
  const {
    isOpen: isOpenAcceptTransferModal,
    onClose: onCloseAcceptTransferModal,
    onOpen: onOpenAcceptTransferModal,
  } = useDisclosure();

  const {
    isOpen: isOpenRejectTransferModal,
    onClose: onCloseRejectTransferModal,
    onOpen: onOpenRejectTransferModal,
  } = useDisclosure();

  const {
    isOpen: isOpenCancelTransferModal,
    onClose: onCloseCancelTransferModal,
    onOpen: onOpenCancelTransferModal,
  } = useDisclosure();

  // Get transfer details data
  const { data, isLoading, refetch, error } = useTransferDetail(uuid ?? '');

  // Transfer triggerers
  const {
    acceptTransfer,
    cancelTransfer,
    isLoadingAcceptTransfer,
    isLoadingCancelTransfer,
  } = useTransferTriggerer();

  const {
    amountFiat,
    assetName,
    issuerFeePercentage,
    receiverFeePercentage,
    issuerFeeAmountFiat,
    receiverFeeAmountFiat,
    toBusiness,
    warrantyTransfer,
    fromBusiness,
    createdAt,
    referenceCurrency,
    uuid: transferUuid,
    comment,
    document,
    status,
    expirationDate,
    acceptedAt,
    assetLockOperation,
    assetUnlockOperation,
    operation,
  } = data ?? {};

  // Check if transfer is in a processing status
  const { isInBlockchain } = useIsInBlockchainChecker({
    refetch,
    status,
    assetLockOperation,
    assetUnlockOperation,
    operation,
  });

  const {
    amountSacas,
    issuerFeeAmountSacas,
    receiverFeeAmountSacas,
    warranty,
  } = warrantyTransfer ?? {};

  // Check the status of the transfer
  const isTransferPendingApproval =
    status === TRANSFER_STATUSES.PENDING_APPROVAL;

  // Determine if the logged in user is the sender and the values to display
  const isSender = selectedBusiness?.fiscalId === fromBusiness?.fiscalId;
  const feeAmountFiat = isSender ? issuerFeeAmountFiat : receiverFeeAmountFiat;
  const feePercentage = isSender ? issuerFeePercentage : receiverFeePercentage;
  const feeAmountSacas = isSender
    ? issuerFeeAmountSacas
    : receiverFeeAmountSacas;
  const multiplier = isSender ? 1 : -1;

  // Total amount of the transfer
  const totalAmountFiat =
    Number(amountFiat) + Number(feeAmountFiat) * multiplier;
  const totalAmountSacas =
    Number(amountSacas) + Number(feeAmountSacas) * multiplier;

  // Determine if Alert component should be displayed and it props
  const shouldShowAlert =
    !isInBlockchain &&
    (status === TRANSFER_STATUSES.CANCELLED ||
      status === TRANSFER_STATUSES.EXPIRED ||
      status === TRANSFER_STATUSES.REJECTED);
  const alertProps = getAlertPropsByStatus({ status, isSender });

  const onClickAcceptTransferHandler = () => {
    if (!uuid) return;

    acceptTransfer(uuid, {
      onSuccess: () => {
        onCloseAcceptTransferModal();
        refetch();
      },
      onError: () => {
        onOpenErrorTransferToast();
      },
    });
  };

  const onClickCancelTransferHandler = () => {
    if (!uuid) return;

    cancelTransfer(uuid, {
      onSuccess: () => {
        onCloseCancelTransferModal();
        onCloseRejectTransferModal();
        refetch();
      },
      onError: () => {
        onOpenErrorTransferToast();
      },
    });
  };

  return (
    <TransferOwnershipControl error={error}>
      <GenericTransactionLayout
        header={
          <WarrantyTransferDetailHeader
            status={status ?? TRANSFER_STATUSES.PENDING_APPROVAL}
            expirationTimestamp={expirationDate ?? 0}
            isLoading={isLoading}
            assetName={assetName as TokenCurrency}
            isSender={isSender}
            origin={warranty?.origin as Origin}
            requestNumber={Number(warranty?.requestNumber)}
            isInBlockchain={isInBlockchain}
          />
        }
        main={
          <Flex flexDirection="column" rowGap="4">
            {shouldShowAlert && (
              <Alert
                text={t(`${alertProps.text}`)}
                supportText={
                  alertProps.supportText ? t(`${alertProps.supportText}`) : ''
                }
              />
            )}
            <WarrantyTransferOperationDetail
              assetName={assetName as TokenCurrency}
              fromBusiness={fromBusiness}
              toBusiness={toBusiness}
              createdAt={createdAt ?? NaN}
              referenceCurrency={referenceCurrency?.name ?? ISO_CURRENCIES.BRL}
              requestNumber={warranty?.requestNumber ?? NaN}
              transferAmountFiat={Number(amountFiat)}
              transferUuid={transferUuid ?? ''}
              comment={comment}
              document={document}
              isLoading={isLoading}
              isSender={isSender}
              acceptedAt={acceptedAt}
            />
          </Flex>
        }
        aside={
          <Flex flexDirection="column" rowGap="4">
            <WarrantyTransferSummaryDetail
              feeAmountFiat={Number(feeAmountFiat)}
              feeAmountSacas={Number(feeAmountSacas)}
              feePercentage={Number(feePercentage)}
              totalAmountFiat={totalAmountFiat}
              totalAmountSacas={totalAmountSacas}
              transferAmountFiat={Number(amountFiat)}
              transferAmountSacas={Number(amountSacas)}
              isLoading={isLoading}
              isSender={isSender}
            />
            {!isInBlockchain && isTransferPendingApproval && (
              <ConditionalButtons
                {...{
                  isSender,
                  onOpenAcceptTransferModal,
                  onOpenCancelTransferModal,
                  onOpenRejectTransferModal,
                }}
              />
            )}
            {isInBlockchain && (
              <BlockchainState
                title={t('blockchainState.title')}
                subtitle={t('blockchainState.subtitle')}
              />
            )}
          </Flex>
        }
      />
      <ErrorTransferToast
        isOpen={isOpenErrorTransferToast}
        onClose={onCloseErrorTransferToast}
      />
      <AcceptTransferModal
        isOpen={isOpenAcceptTransferModal}
        onClickAcceptTransferHandler={onClickAcceptTransferHandler}
        onClose={onCloseAcceptTransferModal}
        isLoading={isLoadingAcceptTransfer}
      />
      <RejectTransferModal
        isOpen={isOpenRejectTransferModal}
        onClickRejectTransferHandler={onClickCancelTransferHandler}
        onClose={onCloseRejectTransferModal}
        isLoading={isLoadingCancelTransfer}
      />
      <CancelTransferModal
        isOpen={isOpenCancelTransferModal}
        onClickCancelTransferHandler={onClickCancelTransferHandler}
        onClose={onCloseCancelTransferModal}
        isLoading={isLoadingCancelTransfer}
      />
    </TransferOwnershipControl>
  );
};

export default TransferDetail;
