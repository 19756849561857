import { Box } from '@chakra-ui/react';
import { ECurrencySymbol } from '@common/enum';
import { TCurrencyName } from '@common/types';
import { FC } from 'react';
import { CoraSvg, SoyaSvg, UsdSvg, WheaSvg } from '../CurrencySvg';

interface TokenIconProps {
  token: TCurrencyName;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}

export const TokenIcon: FC<TokenIconProps> = ({
  token,
  size = 'md',
  className = '',
}) => {
  const getSvg = () => {
    switch (token) {
      case ECurrencySymbol.SOYA:
      case ECurrencySymbol.SOYB:
      case ECurrencySymbol.SOYA_ARS:
        return <SoyaSvg size={size} />;
      case ECurrencySymbol.CORA:
      case ECurrencySymbol.CORA_ARS:
      case ECurrencySymbol.CORB:
        return <CoraSvg size={size} />;
      case ECurrencySymbol.WHEA:
        return <WheaSvg size={size} />;
      default:
        return 'USD:';
    }
  };

  return <Box className={className}>{getSvg()}</Box>;
};
