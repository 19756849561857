import { Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ConditionalButtonsProps {
  isSender: boolean;
  onOpenRejectTransferModal: () => void;
  onOpenAcceptTransferModal: () => void;
  onOpenCancelTransferModal: () => void;
}

const ConditionalButtons = ({
  isSender,
  onOpenAcceptTransferModal,
  onOpenRejectTransferModal,
  onOpenCancelTransferModal,
}: ConditionalButtonsProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.details.buttons',
  });

  if (isSender) {
    return (
      <Button
        size="lg"
        variant="destructive"
        onClick={onOpenCancelTransferModal}
        isFullWidth
      >
        {t('cancel')}
      </Button>
    );
  }

  return (
    <Flex gap="3">
      <Button
        size="lg"
        variant="secondary"
        onClick={onOpenRejectTransferModal}
        isFullWidth
      >
        {t('reject')}
      </Button>
      <Button
        size="lg"
        variant="primary"
        onClick={onOpenAcceptTransferModal}
        isFullWidth
      >
        {t('accept')}
      </Button>
    </Flex>
  );
};

export default ConditionalButtons;
