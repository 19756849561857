import { Flex, Heading, Text } from '@chakra-ui/react';
import {
  EDocumentTypes,
  IDocumentPreviewForBusinessUser,
} from '@src/common/types';
import { Dispatch, SetStateAction, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FileUploader from '../FileUploader';
import MultipleFileUploader from '../MultipleFileUploader';
import { IncompleteFieldError } from '../types';
import TypeOfDocument from './TypeOfDocument';
import { TYPE_OF_LEGAL_POWER_DOC } from './consts';

interface Props {
  statuteDocumentId: number | null;
  setStatuteDocumentId: Dispatch<SetStateAction<number | null>>;
  authoritiesDocumentId: number | null;
  setAuthoritiesDocumentId: Dispatch<SetStateAction<number | null>>;
  legalPowerDocumentIds: number[];
  setLegalPowerDocumentIds: any;
  missingFileError: IncompleteFieldError['errors'];
  defaultBusinessUserDocuments: IDocumentPreviewForBusinessUser[];
}

const DocOfRepresentative: VFC<Props> = ({
  statuteDocumentId,
  setStatuteDocumentId,
  authoritiesDocumentId,
  setAuthoritiesDocumentId,
  legalPowerDocumentIds,
  setLegalPowerDocumentIds,
  missingFileError,
  defaultBusinessUserDocuments,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const businessUserLegalPowerDocument =
    defaultBusinessUserDocuments?.filter(
      ({ document }) => document.documentType.id === EDocumentTypes.LEGAL_POWER
    ) || [];
  const businessUserStatuteDocument =
    defaultBusinessUserDocuments?.filter(
      ({ document }) =>
        document.documentType.id === EDocumentTypes.STATUTE_OR_SOCIAL_CONTRACT
    ) || [];
  const authoritiesDocument =
    defaultBusinessUserDocuments?.filter(
      ({ document }) =>
        document.documentType.id === EDocumentTypes.DESIGNATION_OF_AUTHORITIES
    ) || [];

  const hasDefaultLegalPower = Boolean(businessUserLegalPowerDocument?.length);
  const typeOfLegalPower = watch(TYPE_OF_LEGAL_POWER_DOC);

  const radioButtonIsInLegalPowerValue =
    Number(typeOfLegalPower) === EDocumentTypes.LEGAL_POWER;

  return (
    <Flex flexDir="column">
      <Text size="xl" variant="medium" mb="1.5">
        {t('fullOnboarding.lvl_2.KYC.representativeDocs.title')}
      </Text>
      <Text size="sm" variant="regular">
        {t('fullOnboarding.lvl_2.KYC.representativeDocs.subtitle')}
      </Text>
      <TypeOfDocument
        defaultValue={
          hasDefaultLegalPower
            ? EDocumentTypes.LEGAL_POWER
            : EDocumentTypes.STATUTE_OR_SOCIAL_CONTRACT
        }
      />
      {radioButtonIsInLegalPowerValue ? (
        <>
          <MultipleFileUploader
            description={t(
              'fullOnboarding.lvl_2.KYC.representativeDocs.powerLabel'
            )}
            documentTypeId={EDocumentTypes.LEGAL_POWER}
            documentIds={legalPowerDocumentIds}
            setDocumentIds={setLegalPowerDocumentIds}
            defaultFiles={businessUserLegalPowerDocument ?? []}
            isValid={!missingFileError.legalPowerDocumentId}
          />
        </>
      ) : (
        <>
          <FileUploader
            description={t(
              'fullOnboarding.lvl_2.KYC.representativeDocs.socialContractLabel'
            )}
            documentId={statuteDocumentId}
            setDocumentId={setStatuteDocumentId}
            documentTypeId={EDocumentTypes.STATUTE_OR_SOCIAL_CONTRACT}
            isValid={!missingFileError.statuteDocumentId}
            defaultFile={businessUserStatuteDocument?.[0]?.document}
          />
          <FileUploader
            description={t(
              'fullOnboarding.lvl_2.KYC.representativeDocs.designationOfAuthoritiesLabel'
            )}
            documentId={authoritiesDocumentId}
            setDocumentId={setAuthoritiesDocumentId}
            documentTypeId={EDocumentTypes.DESIGNATION_OF_AUTHORITIES}
            isValid={!missingFileError.authoritiesDocumentId}
            defaultFile={authoritiesDocument?.[0]?.document}
          />
        </>
      )}
    </Flex>
  );
};

export default DocOfRepresentative;
