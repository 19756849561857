import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Link,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCards } from '@src/context/cardsContext';

import useValidationSchema from './hooks/useValidationSchema';
import InputItem from './InputItem';
import { SecondStepFormProps } from './interfaces';
import FormSelect from './SelectItems';

const SecondStepForm: FC<SecondStepFormProps> = ({
  onSubmit,
  setCityByGeographicalDivisionId,
}) => {
  const { t } = useTranslation();
  const { setShowCardShipmentForm } = useCards();

  const [geographicalDivisionId, setGeographicalDivisionId] = useState<
    number | null
  >(null);
  const { requestCardFlowSchema } = useValidationSchema();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FieldValues>({ resolver: yupResolver(requestCardFlowSchema) });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box gap={6} display={{ base: 'block', md: 'flex' }}>
        <InputItem
          register={register}
          errors={errors}
          inputName="street"
          inputType="text"
          label={t('card.shipmentCard.form.street')}
          placeholder={t('card.shipmentCard.form.streetPlaceHolder')}
        />
        <InputItem
          register={register}
          errors={errors}
          inputName="streetNumber"
          inputType="number"
          label={t('card.shipmentCard.form.streetNumber')}
          placeholder={t('card.shipmentCard.form.streetNumberPlaceHolder')}
        />
      </Box>
      <Box gap={6} display={{ base: 'block', md: 'flex' }}>
        <InputItem
          register={register}
          errors={errors}
          inputName="floor"
          inputType="number"
          label={t('card.shipmentCard.form.floor')}
          placeholder={t('card.shipmentCard.form.floorPlaceHolder')}
        />
        <InputItem
          register={register}
          errors={errors}
          inputName="apartment"
          inputType="text"
          label={t('card.shipmentCard.form.apartment')}
          placeholder={t('card.shipmentCard.form.apartmentPlaceHolder')}
        />
      </Box>
      <Box gap={6} display={{ base: 'block', md: 'flex' }}>
        <FormSelect
          errors={errors}
          setGeographicalDivisionId={setGeographicalDivisionId}
          setCityByGeographicalDivisionId={setCityByGeographicalDivisionId}
          geographicalDivisionId={geographicalDivisionId}
          register={register}
        />
      </Box>
      <Flex gap={6}>
        <InputItem
          register={register}
          errors={errors}
          inputName="postalCode"
          inputType="text"
          label={t('card.shipmentCard.form.postalCode')}
          placeholder={t('card.shipmentCard.form.postalCodePlaceHolder')}
        />
      </Flex>
      <FormControl isInvalid={Boolean(errors['consentTerms'])}>
        <Checkbox colorScheme="primary" {...register('consentTerms')}>
          {t('general.accept')} "
          <Link
            fontWeight="medium"
            color="primary.800"
            href={`${t('general.termsAndConditionsLink')}`}
            textDecor="underline"
            target="_blank"
          >
            {t('general.termsAndConditions')}
          </Link>
          "
        </Checkbox>
      </FormControl>

      <FormControl isInvalid={Boolean(errors.consentTerms)}>
        <FormErrorMessage>{errors.consentTerms?.message}</FormErrorMessage>
      </FormControl>

      <Flex gap={6} mt="8">
        <Button
          isLoading={isSubmitting}
          type="submit"
          background="primary.300"
          border="1px solid primary.300"
          color="gray.900"
        >
          {t('card.shipmentCard.form.applyFor')}
        </Button>

        <Button onClick={() => setShowCardShipmentForm(false)} color="gray.900">
          {t('card.shipmentCard.form.maybeLater')}
        </Button>
      </Flex>
    </form>
  );
};

export default SecondStepForm;
