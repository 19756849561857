import { useQuery } from 'react-query';

import { IBusiness } from '@common/types';
import { getBusinessById } from '@services/Businesses';
import { useUser } from '@src/context/userContext';

const useBusiness = () => {
  const { selectedBusinessId } = useUser();

  const { isLoading, isError, data, error } = useQuery<IBusiness | undefined>(
    ['business', selectedBusinessId],
    getBusinessById,
    { enabled: !!selectedBusinessId }
  );

  return { isLoading, data, isError, error };
};

export default useBusiness;
