import { ENationalities } from '@agrotoken/common-utils';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { EGrainContractStatusId, Warranty } from '@common/types';
import { getWarranties } from '@services/GrainContracts';
import { useAuth } from '@src/context/authContext';
import { useUser } from '@src/context/userContext';

const useWarranties = () => {
  const [shouldShowAllWarranties, setShouldShowAllWarranties] = useState(false);
  const [filteredWarranties, setFilteredWarranties] = useState<Warranty[]>([]);

  const { selectedBusinessId } = useUser();
  const { nationality } = useAuth();

  const query = useQuery<Warranty[] | undefined>(
    ['warranties', selectedBusinessId],
    getWarranties,
    { enabled: nationality === ENationalities.BR && !!selectedBusinessId }
  );

  useEffect(() => {
    if (!query.data) return;

    if (!shouldShowAllWarranties) {
      // Filter the warranties that have a status of `TOKENIZED`.
      setFilteredWarranties(
        query.data.filter(
          (warranty) =>
            warranty.grainContractStatusId === EGrainContractStatusId.TOKENIZED
        )
      );

      return;
    }

    setFilteredWarranties(query.data);
  }, [query.data, shouldShowAllWarranties]);

  const onChangeShowAllWarrantiesCheckboxHandler = () => {
    setShouldShowAllWarranties((prev) => !prev);
  };

  return {
    query,
    filteredWarranties,
    onChangeShowAllWarrantiesCheckboxHandler,
  };
};

export default useWarranties;
