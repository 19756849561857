import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useAccess } from '@src/context/accessContext';
import { useUser } from '@src/context/userContext';
import { TextBox } from '@src/features/cards/components/TextBox';

const CanNotRequestCard = () => {
  const { t } = useTranslation();
  const { canAdminUsers } = useAccess();
  const { selectedBusinessUser } = useUser();
  const isCreator = selectedBusinessUser?.isCreator;
  return (
    <Flex display="block">
      <TextBox
        className="mb-5"
        icon="info"
        title={t('card.revisionCard.userIsNotBusinessRepresentantTitle')}
      />
      {(isCreator || canAdminUsers) && (
        <TextBox
          icon="info"
          title={t('cards.cardsRequest.onboardingTitle')}
          buttonLabel={t('cards.cardsRequest.linkButton')}
          hasButton
        />
      )}
    </Flex>
  );
};
export default CanNotRequestCard;
