import { ENationalities } from '@agrotoken/common-utils';
import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { ETokensAR, ETokensBR } from '@common/types';
import { TokenIcon } from '@comp/Global/TokenIcon';
import { useAuth } from '@src/context/authContext';

export const TokenIconRow: FC = () => {
  const { nationality } = useAuth();

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      alignContent={'center'}
    >
      {nationality === ENationalities.AR &&
        Object.values(ETokensAR).map((token, index) => (
          <TokenIcon key={index} token={token} size={'sm'} className="mr-1" />
        ))}

      {nationality === ENationalities.BR &&
        Object.values(ETokensBR)
          //TODO remove this filter and bring active tokens from backend
          .filter((value) => value !== ETokensBR.WHEB)
          .map((token, index) => (
            <TokenIcon key={index} token={token} size={'sm'} className="mr-1" />
          ))}
    </Flex>
  );
};
