import { ENationalities, getMultiRegionMap } from '@agrotoken/common-utils';
import { Button, Heading, Box } from '@chakra-ui/react';
import moment from 'moment';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { GrainContractTypes } from '@common/enum';
import { Spinner } from '@comp/Global/Spinner';
import { useAuth } from '@src/context/authContext';
import {
  getRealTokenizationStep,
  getFutureTokenizationStep,
} from '@utils/getTokenizationStep';
import { SELECT_ORG, TOKENIZATIONS_PATH } from '../../common/const';
import {
  EGrainContractStatus,
  RealTokenizationStep,
  IGrainContract,
  IUpdateGrainContract,
  FutureTokenizationStep,
  EGrainContractStatusId,
} from '../../common/types';
import { getTodayTimestamp, isAlmostExpired } from '../../common/utils';
import { useRates } from '../../context/ratesContext';
import { useUser } from '../../context/userContext';
import {
  deleteGrainContract,
  getGrainContracts,
  getGrainContractsForExcel,
  updateGrainContract,
} from '../../services/GrainContracts';
import { Container } from '../Global/Container';
import { Modal } from '../Global/Modal';
import { ServerError } from '../Global/ServerError';
import { useNavigateSelectOrg } from '@hooks/useNavigateSelectOrg';

const TokenizationColumns_Ar = React.lazy(
  () => import('../AR/Tokenizations/TokenizationColumns')
);
const TokenizationColumns_Br = React.lazy(
  () => import('../BR/Tokenizations/TokenizationColumns')
);

const tokenizationColumns = getMultiRegionMap({
  AR: TokenizationColumns_Ar,
  BR: TokenizationColumns_Br,
});

const StartTokenization_Ar = React.lazy(
  () => import('../AR/Tokenizations/StartTokenization')
);
const StartTokenization_Br = React.lazy(
  () => import('../BR/Tokenizations/StartTokenization')
);

const startTokenization = getMultiRegionMap({
  AR: StartTokenization_Ar,
  BR: StartTokenization_Br,
});

export const Tokenizations = () => {
  const { t } = useTranslation();
  const { selectedBusinessId } = useUser();
  const { errorRates } = useRates();
  const { nationality } = useAuth();
  const TokenizationColumnsComponent = tokenizationColumns[nationality];
  const StartTokenizationComponent = startTokenization[nationality];

  const navigate = useNavigate();

  useNavigateSelectOrg();

  const {
    isLoading: isLoadingGrainContracts,
    data: grainContracts,
    error: getGrainContractsError,
    refetch,
  } = useQuery<IGrainContract[] | undefined>(
    ['grainContracts', selectedBusinessId],
    getGrainContracts
  );

  const [isOpenRenovationModal, setIsOpenRenovationModal] =
    useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [selectedGrainContract, setSelectedGrainContract] = useState<
    IGrainContract | undefined
  >(undefined);
  const [renovateError, setRenovateError] = useState('');
  const [isLoadingHistoricMovements, setIsLoadingHistoricMovements] =
    useState(false);

  const handleTokenizationDetails = (grainContract: IGrainContract) => {
    const { id, grainContractStatusId, isPretokenized } = grainContract;
    const isPretokenizedStatus =
      grainContractStatusId === EGrainContractStatusId.PRE_TOKENIZED;

    // Grain contract is in Pre-Tokenized status but the user needs to fill the proof of liquidity form
    if (isPretokenizedStatus && isPretokenized) {
      navigate(`${TOKENIZATIONS_PATH}/${id}/proof-of-liquidity`);
      return;
    }

    // Grain contract is in Pre-Tokenized status, the user has filled the proof of liquidity form but the tokenization is not confirmed
    if (isPretokenizedStatus && !isPretokenized) {
      navigate(`${TOKENIZATIONS_PATH}/${id}/confirm`);
      return;
    }

    navigate(`${TOKENIZATIONS_PATH}/${id}`);
  };

  const { mutate: mutateRenovateGrainContract, isLoading: isLoadingRenovate } =
    useMutation(updateGrainContract, {
      onSuccess: () => {
        setIsOpenRenovationModal(false);
        setSelectedGrainContract(undefined);
        setRenovateError('');
        refetch();
      },
      onError: (_err: Error) => {
        setSelectedGrainContract(undefined);
        setRenovateError(_err.message);
      },
    });
  const { mutate: mutateDeleteGrainContract, isLoading: isLoadingDelete } =
    useMutation(deleteGrainContract, {
      onSuccess: () => {
        setIsOpenDeleteModal(false);
        setSelectedGrainContract(undefined);
        refetch();
      },
      onError: (_err: Error) => {
        setIsOpenDeleteModal(false);
        setSelectedGrainContract(undefined);
      },
    });

  const openRenovationModal = (grainContract: IGrainContract) => {
    setSelectedGrainContract(grainContract);
    setIsOpenRenovationModal(true);
  };
  const openDeleteModal = (grainContract: IGrainContract) => {
    setSelectedGrainContract(grainContract);
    setIsOpenDeleteModal(true);
  };

  const canRenovateGrainContract = (grainContract: IGrainContract): boolean => {
    const { grainContractStatus, expirationDate, agreementDoc, renovatedAt } =
      grainContract;
    return (
      grainContractStatus?.name === EGrainContractStatus.TOKENIZED &&
      expirationDate !== agreementDoc?.expirationDate &&
      !renovatedAt &&
      isAlmostExpired(expirationDate!)
    );
  };

  const handleRenovateTokenization = (grainContract: IGrainContract) => {
    if (!selectedGrainContract) return;

    const body: IUpdateGrainContract = {
      expirationDate: +grainContract.agreementDoc!.expirationDate!,
      renovatedAt: getTodayTimestamp(),
    };
    mutateRenovateGrainContract({
      id: selectedGrainContract.id,
      body,
    });
  };
  const handleDelete = () => {
    if (!selectedGrainContract) return;
    mutateDeleteGrainContract(selectedGrainContract.id);
  };

  const handleEditTokenization = (
    id: number,
    index: number,
    grainContractType: GrainContractTypes
  ) => {
    if (!grainContracts) return;

    // For REAL and FIXED_GRAIN contracts, the tokenization step is the same for all the contracts
    const tokenizationStep =
      grainContractType === GrainContractTypes.REAL ||
      grainContractType === GrainContractTypes.FIXED_GRAIN
        ? getRealTokenizationStep(grainContracts[index], nationality)
        : getFutureTokenizationStep(grainContracts[index], nationality);

    if (!tokenizationStep) return;

    const tokenizationPath = (
      step: RealTokenizationStep | FutureTokenizationStep
    ) => {
      const commonPath = `${TOKENIZATIONS_PATH}/${id}`;
      switch (step) {
        case RealTokenizationStep.PROOF_OF_ORIGIN:
          return nationality === ENationalities.AR
            ? `${commonPath}/proof-of-origin`
            : undefined;
        case RealTokenizationStep.PROOF_OF_EXISTENCE:
        case FutureTokenizationStep.PRODUCTION_EXPECTATION:
          return `${commonPath}/proof-of-existence`;
        case RealTokenizationStep.PROOF_OF_LIQUIDITY:
        case FutureTokenizationStep.PROOF_OF_LIQUIDITY:
          return `${commonPath}/proof-of-liquidity`;
        case RealTokenizationStep.VERIFICATION:
        case FutureTokenizationStep.VERIFICATION:
          return `${commonPath}/confirm`;
        default:
          return undefined;
      }
    };

    const path = tokenizationPath(tokenizationStep);
    if (!path) return;

    navigate(path);
  };

  const handleDownloadGrainContractsExcel = () => {
    const downloadBalance = async () => {
      try {
        setIsLoadingHistoricMovements(true);

        const data = await getGrainContractsForExcel();
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const tempLink = document.createElement('a');
        const filename = `${t(
          'tokenizations.table.title'
        ).toLowerCase()}_${moment().format('DD-MM-YYYY')}`;
        tempLink.href = url;
        tempLink.setAttribute('download', `${filename}.xlsx`);
        tempLink.click();
        tempLink.remove();
        setIsLoadingHistoricMovements(false);
      } catch {
        setIsLoadingHistoricMovements(false);
      }
    };
    downloadBalance();
  };

  if (errorRates) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(errorRates as any)?.message}
      />
    );
  }
  if (getGrainContractsError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(getGrainContractsError as any)?.message}
      />
    );
  }

  return (
    <>
      <Container className="mt-10 xl:mt-0">
        <Heading color="gray.900" mb="10">
          {t('tokenizations.title')}
        </Heading>
        <Suspense fallback={<div>Loading...</div>}>
          <StartTokenizationComponent className="mb-8" />
        </Suspense>
        <Box className="border overflow-x-auto bg-white rounded">
          <Box
            className="flex gap-4 flex-col md:flex-row md:justify-between py-5 px-6 bg-white border-b border-gray-200"
            position="sticky"
            left={0}
          >
            <div>
              <h3 className="text-lg text-gray-900 font-medium">
                {t('tokenizations.table.title')}
              </h3>
              <p className="text-sm text-gray-500 font-normal">
                {t('tokenizations.table.subtitle')}
              </p>
            </div>
            {grainContracts && grainContracts.length > 0 && (
              <Button
                variant="secondary"
                leftIcon={<FiDownload size={'20'} />}
                onClick={handleDownloadGrainContractsExcel}
                isLoading={isLoadingHistoricMovements}
              >
                {t('tokenizations.table.download')}
              </Button>
            )}
          </Box>
          {isLoadingGrainContracts && <Spinner className="m-auto py-10" />}
          {grainContracts && (
            <Suspense fallback={<div>Loading...</div>}>
              <TokenizationColumnsComponent
                handleEditTokenization={handleEditTokenization}
                grainContracts={grainContracts}
                openDeleteModal={openDeleteModal}
                handleTokenizationDetails={handleTokenizationDetails}
                canRenovateGrainContract={canRenovateGrainContract}
                openRenovationModal={openRenovationModal}
              />
            </Suspense>
          )}
        </Box>
      </Container>

      <Modal
        isOpen={isOpenRenovationModal}
        title={t('tokenizations.table.renovationModal.title')}
        text={t('tokenizations.table.renovationModal.text')}
        labelBtnClose={t('tokenizations.table.renovationModal.labelBtnClose')}
        labelBtnConfirm={t(
          'tokenizations.table.renovationModal.labelBtnConfirm'
        )}
        iconName="check"
        iconColor="success"
        onClickConfirm={() =>
          selectedGrainContract
            ? handleRenovateTokenization(selectedGrainContract)
            : null
        }
        onClickClose={() => setIsOpenRenovationModal(false)}
        center
        isLoading={isLoadingRenovate}
        error={renovateError}
      />
      <Modal
        isOpen={isOpenDeleteModal}
        title={`${t(
          'tokenizations.table.deleteModal.title'
        )} #${selectedGrainContract?.requestNumber?.toString()}`}
        text={t('tokenizations.table.deleteModal.text')}
        labelBtnClose={t('tokenizations.table.deleteModal.labelBtnClose')}
        labelBtnConfirm={t('tokenizations.table.deleteModal.labelBtnConfirm')}
        iconName="trash-2"
        iconColor="error"
        onClickConfirm={handleDelete}
        onClickClose={() => setIsOpenDeleteModal(false)}
        center
        isLoading={isLoadingDelete}
      />
    </>
  );
};
