import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getLoanMovementName } from '@common/loans/utils';
import {
  EMovementTypesId,
  IMovement,
  IOperation,
  OPERATION_TYPES,
  PAYMENT_REQUEST_TYPES,
} from '@common/types';
import { getLocalCurrency } from '@common/utils';
import { useAuth } from '@src/context/authContext';

export const useGetTransactionTypeTranslation = () => {
  const { nationality } = useAuth();
  const localCurrency = getLocalCurrency(nationality);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wallet.movementsTable.transactionType.',
  });

  const getTransactionTypeTranslation = useCallback(
    (operation: IOperation, movement: IMovement): string | undefined => {
      const paymentTypeTranslation = operation.paymentRequest
        ? operation.paymentRequest.type === PAYMENT_REQUEST_TYPES.IN_KIND
          ? 'paymentInKind'
          : 'payment'
        : null;
      switch (operation.operationType) {
        case OPERATION_TYPES.TOKENIZATION:
          return t('tokenization');
        case OPERATION_TYPES.DETOKENIZATION:
          return t('detokenization');
        case OPERATION_TYPES.PAYMENT:
        case OPERATION_TYPES.PAYMENT_IN_KIND:
          switch (movement.movementTypeId) {
            case EMovementTypesId.PAYMENT_FEE:
              return t('fee');
            case EMovementTypesId.TRANSFER:
              if (movement.amount > 0) {
                return t(`${paymentTypeTranslation}.received`);
              }
              return t(`${paymentTypeTranslation}.sent`);
            case EMovementTypesId.CRYPTO_ASSET_SETTLEMENT:
              return t(`${paymentTypeTranslation}.cryptoAssetSettlement`, {
                localCurrency,
              });
            default:
              break;
          }

          break;
        case OPERATION_TYPES.CREDIT_CARD_BLOCKED_RETURN:
          return t('creditCardBlockedReturn');
        case OPERATION_TYPES.PAYMENT_WITH_CARD:
          return t('paymentWithCard');
        case OPERATION_TYPES.LOANS:
          return getLoanMovementName(movement.movementTypeId, t);
        case OPERATION_TYPES.GIFT:
          return t('gift');
        default:
          break;
      }
    },
    [localCurrency, t]
  );

  return { getTransactionTypeTranslation };
};

export default useGetTransactionTypeTranslation;
