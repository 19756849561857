import { useUser } from '@src/context/userContext';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';

import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import {
  getNotifications,
  markAllNotificationsAsRead,
} from '@services/Notifications';

import notificationIcon from '../../assets/icons/notification-icon.svg';
import emptyState from '../../assets/images/empty-list.svg';

const NotificationIcon: FC = () => {
  return (
    <div className="h-8 w-8 rounded-2xl bg-[#F2F4F7] mr-4 flex items-center justify-center">
      <img src={notificationIcon} alt="" />
    </div>
  );
};

interface NotificationProps {
  title: string;
  body: string;
  date: string;
  actionTitle?: string;
  actionUrl?: string;
  data: any;
}

const Notification: FC<NotificationProps> = ({
  title,
  body,
  date,
  actionTitle,
  actionUrl,
  data,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex items-center">
        <NotificationIcon />
        <div>
          <p>{t(title, data)}</p>
          <p>{t(body, data)}</p>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <p>
          {t('notifications.intlDateTime', {
            val: new Date(date),
            formatParams: {
              val: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              },
            },
          })}
        </p>
        {actionTitle && actionUrl && (
          <NavLink className="text-[#2D9803]" to={actionUrl}>
            {t(actionTitle, data)}
          </NavLink>
        )}
      </div>
    </>
  );
};

interface INotification {
  title: string;
  body: string;
  createdAt: string;
  actionTitle: string;
  actionUrl: string;
  data: any;
}

const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center flex-col">
      <img className="mb-4" src={emptyState} alt="Notifications" />
      <h1 className="text-xl text-[#101828]">
        {t('notifications.emptyState.title')}
      </h1>
      <h2 className="text-sm text-[#667085]">
        {t('notifications.emptyState.subtitle')}
      </h2>
    </div>
  );
};

export const NotificationList: FC<{
  notifications: INotification[];
}> = ({ notifications }) => {
  const { t } = useTranslation();
  return (
    <>
      {notifications.length > 0 && (
        <>
          <h1
            className="text-4xl text-[#101828] mb-12"
            style={{ fontSize: '36px' }}
          >
            {t('notifications.title')}
          </h1>
          <ul>
            {notifications.map(
              ({ title, body, createdAt, actionTitle, actionUrl, data }) => (
                <li key={title} className="flex justify-between mb-12">
                  <Notification
                    title={title}
                    body={body}
                    date={createdAt}
                    actionTitle={actionTitle}
                    actionUrl={actionUrl}
                    data={data}
                  />
                </li>
              )
            )}
          </ul>
        </>
      )}
      {notifications.length === 0 && <EmptyState />}
    </>
  );
};

export const Notifications: FC = () => {
  const { currentUser, isLoadingUser, selectedBusinessId } = useUser();

  const { t } = useTranslation();

  const {
    isLoading: isLoadingNotifications,
    data: notifications,
    error: notificationsError,
  } = useQuery(
    ['notifications', selectedBusinessId],
    () => getNotifications(selectedBusinessId?.toString() || ''),
    {
      enabled: !!currentUser?.authUuid && !!selectedBusinessId,
    }
  );

  const {
    mutate: markAllAsRead,
    isLoading: markingAsRead,
    isSuccess: markedAsRead,
  } = useMutation((variables: any) =>
    markAllNotificationsAsRead(variables.authUuid, variables.businessId)
  );

  const hasNotSeenNotifications = useMemo(
    () => notifications?.data.some(({ seenAt }: any) => seenAt === null),
    [notifications]
  );

  const isLoading = isLoadingNotifications || isLoadingUser;

  useEffect(() => {
    if (
      !markingAsRead &&
      !markedAsRead &&
      hasNotSeenNotifications &&
      currentUser?.authUuid &&
      selectedBusinessId
    ) {
      markAllAsRead({
        authUuid: currentUser.authUuid,
        businessId: selectedBusinessId.toString(),
      });
    }
  }, [
    markingAsRead,
    markedAsRead,
    hasNotSeenNotifications,
    currentUser,
    markAllAsRead,
    selectedBusinessId,
  ]);

  return (
    <div className="px-8 lg:container mx-auto flex flex-col">
      {isLoading && <Loader />}
      {!isLoading && notifications && (
        <NotificationList notifications={notifications.data} />
      )}
      {notificationsError && (
        <ServerError
          title={t('serverError.title')}
          text={(notificationsError as any)?.message}
        />
      )}
    </div>
  );
};
