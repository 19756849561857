import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@comp/Global/Icon';
import { Loader } from '@comp/Global/Loader';
import LoanAuthorizationCard from '@comp/LoanAuthorizationCard';
import { LenderType } from '@features/loans/enums';
import { useCollateralizeLoanData } from '@features/loans/hooks';
import { getLenderTypeTranslation } from '@features/loans/utils';

const LoanAuthorizationSuccessLayout = () => {
  const { t } = useTranslation();

  const { isLoadingCollateralizeLoanDetails, collateralizeLoanDetails } =
    useCollateralizeLoanData();

  const { lenderLogo: logo, lenderType } = collateralizeLoanDetails ?? {};

  const translation = getLenderTypeTranslation(lenderType as LenderType);

  if (isLoadingCollateralizeLoanDetails) {
    return <Loader />;
  }

  return (
    <LoanAuthorizationCard logo={logo}>
      <Box color="primary.700" mb="4">
        <Icon
          size={'lg'}
          color={'success'}
          theme={'no-background'}
          icon="check-circle"
        />
      </Box>
      <Text size="lg" variant="bold" color="gray.900" mb="2">
        {t(`${translation}.blockAuthorization.success.heading`)}
      </Text>
      <Text size="md" color="gray.700">
        {t(`${translation}.blockAuthorization.success.subheading`)}
      </Text>
    </LoanAuthorizationCard>
  );
};

export default LoanAuthorizationSuccessLayout;
