import { useContext, Dispatch, SetStateAction } from 'react';
import { StepFormContext } from './index';
import type { StepFormContextValue, FormValues } from './index';

type StepFormValues<T> = StepFormContextValue & {
  formValues: T;
  setFormValues: Dispatch<SetStateAction<T>>;
};

/**
 * useStepForm
 *
 * Hook to access the context values of a parent `StepFormContext.Provider`. Allows interaction with the overarching state
 * of the step form.
 *
 * @returns {StepFormValues<T>}
 */
export const useStepForm = <T extends FormValues>(): StepFormValues<T> => {
  return useContext(StepFormContext) as StepFormValues<T>;
};
