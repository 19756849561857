import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { PaymentRequestAsset } from '@common/enum';
import { formatDate } from '@common/utils/format-date';
import { Decimal } from 'decimal.js';
import {
  IFees,
  IPaymentRequestChangeLog,
  PaymentRequestStatus,
} from '../../common/types';

/**
 * getReceiverFee
 *
 * Returns the receiver fee based on the asset and the businessFee
 *
 * @param businessFee businessFee value
 * @param asset asset value
 * @param areFeesInPaymentInWarrantiesActive areFeesInPaymentInWarrantiesActive value
 * @returns {number} receiver fee result
 */
const getReceiverFee = (
  businessFee: IFees | undefined,
  asset: PaymentRequestAsset | undefined,
  areFeesInPaymentInWarrantiesActive: boolean
): number => {
  if (!asset || !businessFee) return 0;

  const isPaymentRequestInWarranties = asset === PaymentRequestAsset.WARRANTY;
  const isPaymentRequestInFixedGrain =
    asset === PaymentRequestAsset.FIXED_GRAIN;

  if (isPaymentRequestInWarranties && !areFeesInPaymentInWarrantiesActive) {
    return 0;
  }

  let fee = businessFee.receiverFee;

  if (isPaymentRequestInFixedGrain) fee = businessFee.receiverFixedFee;

  return new Decimal(fee ?? 0).toNumber();
};

export const findChangeLogDateByStatus = (
  changeLogs: IPaymentRequestChangeLog[],
  status: PaymentRequestStatus
) => {
  const changeLog = changeLogs.find((elem) => elem.status === status);
  if (!changeLog) return null;
  return formatDate(+changeLog.createdAt, 'DD/MM/YY - HH:mm');
};

/** Estimates Merchant Fees on Payment request creation */
export const estimateMerchantFeeAmountInPaymentRequestCreation = (
  businessFee: IFees | undefined,
  amount: number | undefined,
  asset: PaymentRequestAsset | undefined,
  areFeesInPaymentInWarrantiesActive: boolean
): number => {
  if (!amount) return 0;

  const fee = getReceiverFee(
    businessFee,
    asset,
    areFeesInPaymentInWarrantiesActive
  );

  return roundValue({
    value: amount * (fee / 100),
    operationType: EPaymentRequestOperationType.MERCHANT_FEE,
    scale: 2,
  }).toNumber();
};

export const estimateMerchantFeePercentageInPaymentRequestCreation = (
  businessFee: IFees | undefined,
  asset: PaymentRequestAsset | undefined,
  areFeesInPaymentInWarrantiesActive: boolean
): number => {
  const fee = getReceiverFee(
    businessFee,
    asset,
    areFeesInPaymentInWarrantiesActive
  );

  return fee;
};
