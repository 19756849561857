"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var frontend_utils_exports = {};
__export(frontend_utils_exports, {
  EPaymentRequestOperationType: () => EPaymentRequestOperationType,
  ESwapOperationType: () => ESwapOperationType,
  ETokenizationOperationType: () => ETokenizationOperationType,
  WarrantyCard: () => WarrantyCard,
  getCountryFlagEmoji: () => getCountryFlagEmoji,
  preventOnKeyDown: () => preventOnKeyDown,
  roundValue: () => roundValue
});
module.exports = __toCommonJS(frontend_utils_exports);

// common/countries.ts
var getCountryFlagEmoji = (countryCode) => {
  const platform = navigator.platform || navigator.userAgent;
  if (platform.toUpperCase().includes("WIN"))
    return "";
  return countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
};

// common/helpers.ts
var preventOnKeyDown = (ev, keys) => {
  if (keys.includes(ev.key)) {
    ev.preventDefault();
  }
};

// components/WarrantyCard.tsx
var import_ui = require("@agrotoken-tech/ui");
var import_common_utils = require("@agrotoken/common-utils");
var import_react = require("@chakra-ui/react");

// ../../node_modules/react-icons/lib/esm/iconBase.js
var React2 = __toESM(require("react"));

// ../../node_modules/react-icons/lib/esm/iconContext.js
var React = __toESM(require("react"));
var DefaultContext = {
  color: void 0,
  size: void 0,
  className: void 0,
  style: void 0,
  attr: void 0
};
var IconContext = React.createContext && React.createContext(DefaultContext);

// ../../node_modules/react-icons/lib/esm/iconBase.js
var __assign = function() {
  __assign = Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s)
        if (Object.prototype.hasOwnProperty.call(s, p))
          t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = function(s, e) {
  var t = {};
  for (var p in s)
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
      t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") {
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++)
      if (e.indexOf(p[i]) < 0)
        t[p[i]] = s[p[i]];
  }
  return t;
};
function Tree2Element(tree) {
  return tree && tree.map(function(node, i) {
    return React2.createElement(node.tag, __assign({
      key: i
    }, node.attr), Tree2Element(node.child));
  });
}
function GenIcon(data) {
  return function(props) {
    return React2.createElement(IconBase, __assign({
      attr: __assign({}, data.attr)
    }, props), Tree2Element(data.child));
  };
}
function IconBase(props) {
  var elem = function(conf) {
    var computedSize = props.size || conf.size || "1em";
    var className;
    if (conf.className)
      className = conf.className;
    if (props.className)
      className = (className ? className + " " : "") + props.className;
    var attr = props.attr, title = props.title, svgProps = __rest(props, ["attr", "title"]);
    return React2.createElement("svg", __assign({
      stroke: "currentColor",
      fill: "currentColor",
      strokeWidth: "0"
    }, conf.attr, attr, svgProps, {
      className,
      style: __assign({
        color: props.color || conf.color
      }, conf.style, props.style),
      height: computedSize,
      width: computedSize,
      xmlns: "http://www.w3.org/2000/svg"
    }), title && React2.createElement("title", null, title), props.children);
  };
  return IconContext !== void 0 ? React2.createElement(IconContext.Consumer, null, function(conf) {
    return elem(conf);
  }) : elem(DefaultContext);
}

// ../../node_modules/react-icons/fi/index.esm.js
function FiFileText(props) {
  return GenIcon({ "tag": "svg", "attr": { "viewBox": "0 0 24 24", "fill": "none", "stroke": "currentColor", "strokeWidth": "2", "strokeLinecap": "round", "strokeLinejoin": "round" }, "child": [{ "tag": "path", "attr": { "d": "M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" } }, { "tag": "polyline", "attr": { "points": "14 2 14 8 20 8" } }, { "tag": "line", "attr": { "x1": "16", "y1": "13", "x2": "8", "y2": "13" } }, { "tag": "line", "attr": { "x1": "16", "y1": "17", "x2": "8", "y2": "17" } }, { "tag": "polyline", "attr": { "points": "10 9 9 9 8 9" } }] })(props);
}
function FiHelpCircle(props) {
  return GenIcon({ "tag": "svg", "attr": { "viewBox": "0 0 24 24", "fill": "none", "stroke": "currentColor", "strokeWidth": "2", "strokeLinecap": "round", "strokeLinejoin": "round" }, "child": [{ "tag": "circle", "attr": { "cx": "12", "cy": "12", "r": "10" } }, { "tag": "path", "attr": { "d": "M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" } }, { "tag": "line", "attr": { "x1": "12", "y1": "17", "x2": "12", "y2": "17" } }] })(props);
}

// components/WarrantyCard.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var mapOriginToType = {
  TOKENIZATION: "Garantia Tokenizada",
  PAYMENT: "Garantia Recebida"
};
var mapCurrencyToTitle = {
  SOYB: "Garantia de Soja",
  CORB: "Garantia de Milho",
  COFB: "Garantia de Caf\xE9",
  WHEB: "",
  ABRC: ""
};
var mapCurrencyToIcon = {
  SOYB: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Icon, { as: import_ui.SoyIcon, color: "gray.500", mr: "1", boxSize: "16px" }),
  CORB: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Icon, { as: import_ui.CornIcon, color: "gray.500", mr: "1", boxSize: "16px" }),
  COFB: "",
  ABRC: "",
  WHEB: ""
};
var mapStatusToLabel = {
  PENDING_LIQUIDITY: "Aguardando liquida\xE7\xE3o",
  COMPLETED: "Liquida\xE7\xE3o conclu\xEDda"
};
var WarrantyCard = (_a) => {
  var _b = _a, {
    onClickButton,
    currencyName,
    requestNumber,
    expirationDate,
    farmCity,
    farmGeographicalDivision,
    origin,
    healthControl,
    amountInSacas,
    initialAmountInSacas,
    equivalencesAmountBrl,
    handleOpenInfoModal,
    status,
    isLoading = false,
    width = "100%"
  } = _b, otherProps = __objRest(_b, [
    "onClickButton",
    "currencyName",
    "requestNumber",
    "expirationDate",
    "farmCity",
    "farmGeographicalDivision",
    "origin",
    "healthControl",
    "amountInSacas",
    "initialAmountInSacas",
    "equivalencesAmountBrl",
    "handleOpenInfoModal",
    "status",
    "isLoading",
    "width"
  ]);
  const availableSacas = "TOKENIZATION" /* TOKENIZATION */ === origin ? ` ${(0, import_common_utils.formatNumber)(amountInSacas, import_common_utils.UNITS.SACAS)} / 
      ${(0, import_common_utils.formatNumber)(initialAmountInSacas, import_common_utils.UNITS.SACAS)}` : (0, import_common_utils.formatNumber)(amountInSacas, import_common_utils.UNITS.SACAS);
  const fields = [
    {
      label: "Pre\xE7o fixo:",
      data: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_react.Flex, { alignItems: "center", gap: 1, children: [
        "R$ ",
        (0, import_common_utils.formatNumber)(equivalencesAmountBrl, import_common_utils.UNITS.BRL),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
          import_react.Tooltip,
          {
            label: "Equival\xEAncia com base no valor do contrato de tokeniza\xE7\xE3o.",
            hasArrow: true,
            placement: "top",
            gutter: 16,
            shouldWrapChildren: true,
            children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Icon, { as: FiHelpCircle, color: "gray.400", mb: "-0.5" })
          }
        )
      ] })
    },
    { label: "Liquida\xE7\xE3o:", data: (0, import_common_utils.formatDate)(expirationDate) },
    { label: "Local:", data: `${farmCity}, ${farmGeographicalDivision}` },
    { label: "Tipo:", data: mapOriginToType[origin] },
    {
      label: "Pontua\xE7\xE3o:",
      data: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_ui.Badge, { colorScheme: healthControl ? "success" : "error", children: healthControl ? "Excelente" : "Ruim" })
    },
    {
      label: "Status:",
      data: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        import_ui.Badge,
        {
          colorScheme: origin === "TOKENIZATION" /* TOKENIZATION */ || status === "COMPLETED" ? "success" : "gray",
          children: origin === "TOKENIZATION" /* TOKENIZATION */ ? "Tokenizada" : status && mapStatusToLabel[status]
        }
      )
    },
    {
      label: "Sacas dispon\xEDveis:",
      data: healthControl ? /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, { children: [
        mapCurrencyToIcon[currencyName],
        availableSacas
      ] }) : /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, { children: [
        "Bloqueadas",
        handleOpenInfoModal && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Button, { variant: "link", onClick: handleOpenInfoModal, ml: "2", children: "Saber mais" })
      ] })
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(
    import_react.Flex,
    __spreadProps(__spreadValues({
      padding: 4,
      flexDirection: "column",
      borderRadius: "xs",
      borderColor: "gray.200",
      borderWidth: "1px",
      width
    }, otherProps), {
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Skeleton, { isLoaded: !isLoading, children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_react.Flex, { justifyContent: "space-between", children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_react.Flex, { gap: "5px", children: [
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Icon, { as: FiFileText, color: "gray.700", boxSize: "20px" }),
            /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
              import_react.Text,
              {
                color: "gray.900",
                fontSize: "sm",
                fontFamily: "Larsseit-Medium",
                children: `${mapCurrencyToTitle[currencyName]} #${requestNumber}`
              }
            )
          ] }),
          onClickButton && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Button, { onClick: onClickButton, variant: "linkPrimary", children: "Ver mais" })
        ] }) }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Divider, { color: "gray.200", my: 4 }),
        /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
          import_react.Grid,
          {
            templateColumns: { base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
            rowGap: "3",
            columnGap: "4",
            children: fields.map((field) => /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Skeleton, { isLoaded: !isLoading, children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.GridItem, { children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_react.Flex, { columnGap: "1", children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Text, { color: "gray.900", size: "sm", variant: "medium", children: field.label }),
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react.Text, { color: "gray.500", size: "sm", children: field.data })
            ] }) }) }))
          }
        )
      ]
    })
  );
};

// roundings/rounding-functions.ts
var import_decimal = __toESM(require("decimal.js"));
var ceil = (value, scale) => round(value, scale, import_decimal.default.ROUND_CEIL);
var floor = (value, scale) => round(value, scale, import_decimal.default.ROUND_FLOOR);
var round = (value, scale, rounding) => {
  const unrounded = new import_decimal.default(value);
  return unrounded.toDecimalPlaces(scale, rounding);
};

// roundings/index.ts
var ETokenizationOperationType = /* @__PURE__ */ ((ETokenizationOperationType2) => {
  ETokenizationOperationType2["TONS_TO_TOKENIZE"] = "TOKENIZATION:TONS_TO_TOKENIZE";
  ETokenizationOperationType2["RESERVE"] = "TOKENIZATION:RESERVE";
  ETokenizationOperationType2["FEE"] = "TOKENIZATION:FEE";
  ETokenizationOperationType2["TOKENS_TO_RECEIVE"] = "TOKENIZATION:TOKENS_TO_RECEIVE";
  return ETokenizationOperationType2;
})(ETokenizationOperationType || {});
var EPaymentRequestOperationType = /* @__PURE__ */ ((EPaymentRequestOperationType2) => {
  EPaymentRequestOperationType2["TOKENS_TO_RECEIVE_BY_MERCHANT"] = "PAYMENT_REQUEST:TOKENS_TO_RECEIVE_BY_MERCHANT";
  EPaymentRequestOperationType2["MERCHANT_FEE"] = "PAYMENT_REQUEST:MERCHANT_FEE";
  EPaymentRequestOperationType2["TOKENS_TO_PAY_BY_FARMER"] = "PAYMENT_REQUEST:TOKENS_TO_PAY_BY_FARMER";
  EPaymentRequestOperationType2["FARMER_FEE"] = "PAYMENT_REQUEST:FARMER_FEE";
  EPaymentRequestOperationType2["TONS_TO_FIX"] = "PAYMENT_REQUEST:TONS_TO_FIX";
  EPaymentRequestOperationType2["RESERVE_TO_FIX"] = "PAYMENT_REQUEST:RESERVE_TO_FIX";
  EPaymentRequestOperationType2["FEE_TO_FIX_DURING_TOKENIZATION"] = "PAYMENT_REQUEST:FEE_TO_FIX_DURING_TOKENIZATION";
  EPaymentRequestOperationType2["BNPL_OVERCOLLATERAL_TOKENS"] = "PAYMENT_REQUEST:BNPL_OVERCOLLATERAL_TOKENS";
  EPaymentRequestOperationType2["BNPL_FINANCING_TOKENS_COST"] = "PAYMENT_REQUEST:BNPL_FINANCING_TOKENS_COST";
  return EPaymentRequestOperationType2;
})(EPaymentRequestOperationType || {});
var ESwapOperationType = /* @__PURE__ */ ((ESwapOperationType2) => {
  ESwapOperationType2["TOKENS_TO_CONVERT"] = "SWAP:TOKENS_TO_CONVERT";
  ESwapOperationType2["SWAP_FEE"] = "SWAP:SWAP_FEE";
  ESwapOperationType2["CASH_ADVANCE_RATE"] = "SWAP:CASH_ADVANCE_RATE";
  ESwapOperationType2["CASH_TO_RECEIVE"] = "SWAP:CASH_TO_RECEIVE";
  ESwapOperationType2["TONS_TO_FIX"] = "SWAP:TONS_TO_FIX";
  ESwapOperationType2["RESERVE_TO_FIX"] = "SWAP:RESERVE_TO_FIX";
  ESwapOperationType2["FEE_TO_FIX_DURING_TOKENIZATION"] = "SWAP:FEE_TO_FIX_DURING_TOKENIZATION";
  return ESwapOperationType2;
})(ESwapOperationType || {});
var roundOperations = {
  ["TOKENIZATION:TONS_TO_TOKENIZE" /* TONS_TO_TOKENIZE */]: floor,
  ["TOKENIZATION:RESERVE" /* RESERVE */]: ceil,
  ["TOKENIZATION:FEE" /* FEE */]: floor,
  ["TOKENIZATION:TOKENS_TO_RECEIVE" /* TOKENS_TO_RECEIVE */]: floor,
  /**
   * Payment requests
   *
   * N/A: TOKENS_TO_RECEIVE_BY_MERCHANT
   */
  ["PAYMENT_REQUEST:MERCHANT_FEE" /* MERCHANT_FEE */]: floor,
  ["PAYMENT_REQUEST:FARMER_FEE" /* FARMER_FEE */]: floor,
  ["PAYMENT_REQUEST:TOKENS_TO_PAY_BY_FARMER" /* TOKENS_TO_PAY_BY_FARMER */]: ceil,
  ["PAYMENT_REQUEST:TOKENS_TO_RECEIVE_BY_MERCHANT" /* TOKENS_TO_RECEIVE_BY_MERCHANT */]: ceil,
  ["PAYMENT_REQUEST:TONS_TO_FIX" /* TONS_TO_FIX */]: ceil,
  ["PAYMENT_REQUEST:RESERVE_TO_FIX" /* RESERVE_TO_FIX */]: ceil,
  ["PAYMENT_REQUEST:FEE_TO_FIX_DURING_TOKENIZATION" /* FEE_TO_FIX_DURING_TOKENIZATION */]: floor,
  ["PAYMENT_REQUEST:BNPL_FINANCING_TOKENS_COST" /* BNPL_FINANCING_TOKENS_COST */]: ceil,
  ["PAYMENT_REQUEST:BNPL_OVERCOLLATERAL_TOKENS" /* BNPL_OVERCOLLATERAL_TOKENS */]: ceil,
  /**
   * Swap
   *
   * N/A: TOKENS_TO_CONVERT
   */
  ["SWAP:SWAP_FEE" /* SWAP_FEE */]: floor,
  ["SWAP:CASH_ADVANCE_RATE" /* CASH_ADVANCE_RATE */]: ceil,
  ["SWAP:CASH_TO_RECEIVE" /* CASH_TO_RECEIVE */]: floor,
  ["SWAP:TONS_TO_FIX" /* TONS_TO_FIX */]: ceil,
  ["SWAP:RESERVE_TO_FIX" /* RESERVE_TO_FIX */]: ceil,
  ["SWAP:FEE_TO_FIX_DURING_TOKENIZATION" /* FEE_TO_FIX_DURING_TOKENIZATION */]: floor
};
var roundValue = (params) => {
  const { value, operationType, scale } = params;
  const roundOperation = roundOperations[operationType];
  return roundOperation(value, scale);
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  EPaymentRequestOperationType,
  ESwapOperationType,
  ETokenizationOperationType,
  WarrantyCard,
  getCountryFlagEmoji,
  preventOnKeyDown,
  roundValue
});
