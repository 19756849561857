import {
  Flex,
  HStack,
  PinInput,
  PinInputField,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import type { FieldError } from 'react-hook-form';

interface VerificationCodeInputProps {
  setPin: (value: string) => void;
  error?: FieldError;
}

const VerificationCodeInput: React.VFC<VerificationCodeInputProps> = (
  props
) => {
  const { error, setPin } = props;

  return (
    <FormControl isInvalid={Boolean(error?.message)}>
      <Flex direction={['column', 'column', 'row']} align="center">
        <HStack m="6">
          <PinInput
            placeholder=""
            onComplete={(value: string) => setPin(value)}
            isInvalid={Boolean(error)}
          >
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
            <PinInputField h={['14', '14', '16']} w={['14', '14', '16']} />
          </PinInput>
        </HStack>
      </Flex>
      <FormErrorMessage ml="6">{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default VerificationCodeInput;
