import { Flex, Spinner } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { TBreadcrumbOption } from '../common/types';
import Breadcrumb from './Global/Breadcrumb';
import Navbar from './Global/Navbar';
import { Rates } from './Rates';

const Layout: React.FC = () => {
  const [options, setOptions] = React.useState<TBreadcrumbOption[]>([]);

  return (
    <Flex flexDir="column" minH="100vh" pb={'8'}>
      <Navbar />
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
        gap={{ base: 4, sm: 0 }}
        px={{ base: 4, sm: 8 }}
        mt={{ base: 0, sm: 4 }}
        mb={6}
      >
        <Breadcrumb options={options} />
        <Suspense fallback={<Spinner />}>
          <Rates />
        </Suspense>
      </Flex>
      <Outlet context={{ options, setOptions }} />
    </Flex>
  );
};

export default Layout;
