import { useMutation } from 'react-query';

import {
  acceptTransfer as acceptTransferService,
  cancelTransfer as cancelTransferService,
} from '@services/transfers';

const useTransferTriggerer = () => {
  const {
    mutate: acceptTransfer,
    isLoading: isLoadingAcceptTransfer,
    error: errorAcceptTransfer,
  } = useMutation(['transfer', 'accept'], (uuid: string) =>
    acceptTransferService(uuid)
  );

  const {
    mutate: cancelTransfer,
    isLoading: isLoadingCancelTransfer,
    error: errorCancelTransfer,
  } = useMutation(['transfer', 'cancel'], (uuid: string) =>
    cancelTransferService(uuid)
  );

  return {
    acceptTransfer,
    cancelTransfer,
    isLoadingAcceptTransfer,
    isLoadingCancelTransfer,
    errorAcceptTransfer,
    errorCancelTransfer,
  };
};

export default useTransferTriggerer;
