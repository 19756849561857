import i18n from '@src/translations/i18n';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useRegionLanguageFromQueryString = () => {
  const location = useLocation();
  const setLanguageBasedOnRegionQueryString = () => {
    const searchParams = new URLSearchParams(location.search);
    const region = searchParams.get('region');
    if (region) {
      const languages = {
        AR: 'es',
        BR: 'pt',
      };
      const language = languages[region as keyof typeof languages];
      if (language) {
        i18n.changeLanguage(language);
      }
    }
  };
  useEffect(setLanguageBasedOnRegionQueryString, []);
};
