import {
  CURRENCY_PREFIXES,
  ReferenceCurrency,
  REFERENCE_CURRENCIES,
  WeightUnit,
  WEIGHT_UNITS,
} from '@agrotoken/common-utils';

/**
 * getCurrencyPrefix
 *
 * This method is used in the Rates component to get the currency
 * prefix based on the reference and weight units.
 *
 * @param reference reference currency value
 * @param weightUnits weight units value
 * @returns string
 */
export const getCurrencyPrefix = (
  reference: ReferenceCurrency,
  weightUnits: WeightUnit
): string => {
  // TODO: Add other combinations of reference and weight units.
  const prefixesResult = {
    [`${REFERENCE_CURRENCIES.BRL}_${WEIGHT_UNITS.TONS}`]:
      CURRENCY_PREFIXES.BRL_TONS,
    [`${REFERENCE_CURRENCIES.BRL}_${WEIGHT_UNITS.SACAS}`]:
      CURRENCY_PREFIXES.BRL_SACAS,
    [`${REFERENCE_CURRENCIES.ARS}_${WEIGHT_UNITS.TONS}`]:
      CURRENCY_PREFIXES.ARS_TONS,
    [`${REFERENCE_CURRENCIES.ARS}_${WEIGHT_UNITS.KILOGRAMS}`]:
      CURRENCY_PREFIXES.ARS_KG,
    [`${REFERENCE_CURRENCIES.USD}_${WEIGHT_UNITS.SACAS}`]:
      CURRENCY_PREFIXES.USD_SACAS,
    [`${REFERENCE_CURRENCIES.USD}_${WEIGHT_UNITS.KILOGRAMS}`]:
      CURRENCY_PREFIXES.USD_KG,
  };

  return (
    prefixesResult[`${reference}_${weightUnits}`] ?? CURRENCY_PREFIXES.USD_TONS
  );
};
export default getCurrencyPrefix;
