import { Button, Divider, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { TCurrencyName } from '@common/types';
import { Loader } from '@comp/Global/Loader';
import { TokenIcon } from '@comp/Global/TokenIcon';
import LoanAuthorizationCard from '@comp/LoanAuthorizationCard';
import { LenderType } from '@features/loans/enums';
import {
  useAuthorizeLoanPayment,
  useAuthorizeLoanPaymentData,
} from '@features/loans/hooks';
import { getLenderTypeTranslation } from '@features/loans/utils';

const PaymentAuthorizationLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loanUuid } = useParams();
  const { mutate, isLoading } = useAuthorizeLoanPayment();

  // Get payment details data
  const { isLoadingAuthorizeLoanPaymentDetails, authorizeLoanPaymentDetails } =
    useAuthorizeLoanPaymentData();

  const { cryptoCurrency, amountToPay, lenderType, lenderLogo } =
    authorizeLoanPaymentDetails ?? {};

  if (isLoadingAuthorizeLoanPaymentDetails) {
    return <Loader />;
  }

  const onClickAuthorizeHandler = () => {
    if (!loanUuid) return;

    mutate(loanUuid, {
      onSuccess: () =>
        navigate('success', { state: { logo: lenderLogo, lenderType } }),
      onError: () =>
        navigate('error', { state: { logo: lenderLogo, lenderType } }),
    });
  };

  const translation = getLenderTypeTranslation(lenderType as LenderType);

  return (
    <LoanAuthorizationCard logo={lenderLogo}>
      <Text size="lg" color="gray.900" mb="2">
        {t(`${translation}.paymentAuthorization.heading`)}
      </Text>
      <Text size="md" color="gray.700">
        {t(`${translation}.paymentAuthorization.subheading`)}
      </Text>
      <Divider borderColor="gray.200" mt="2" mb="4" />
      <Text size="sm" color="gray.700">
        {t(`${translation}.paymentAuthorization.tokenAmount.label`)}
      </Text>
      <Flex columnGap="2" alignItems="center">
        <TokenIcon token={cryptoCurrency as TCurrencyName} />
        <Text size="xl" color="gray.900" my="1">
          {t(`${translation}.paymentAuthorization.tokenAmount.value`, {
            tokenAmount: amountToPay,
            token: cryptoCurrency,
          })}
        </Text>
      </Flex>
      <Text size="sm" color="gray.700">
        {t(`${translation}.paymentAuthorization.tokenAmount.supportText`)}
      </Text>
      <Divider borderColor="gray.200" mt="4" mb="8" />
      <Button
        variant="primary"
        size="lg"
        w="100%"
        isLoading={isLoading}
        onClick={onClickAuthorizeHandler}
      >
        {t(`${translation}.paymentAuthorization.button`)}
      </Button>
    </LoanAuthorizationCard>
  );
};

export default PaymentAuthorizationLayout;
