import { ECardStatus, ECardType, ICard } from '@def/card';
import {
  getBusinessUsersWithCards,
  getOracleSettlements,
} from '@services/Card';
import CardList from '@src/features/cards/cardList';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useCards } from '@src/context/cardsContext';
import { useAccess } from '@src/context/accessContext';
import { getCardByType } from '@services/Card';
import {
  IResponseCreateCardShippingAddress,
  IShippingAddress,
} from '@def/shippingAddress';
import { getUserAddressById } from '@services/UserAddress';
import { Box, Spinner } from '@chakra-ui/react';
import ThirdStep from './cardRequestProcess/ThirdStep';
import CardRequestProcess from './cardRequestProcess';
import { BusinessUserCards } from '@src/features/cards/interfaces/card';
import { ServerError } from '@comp/Global/ServerError';

const UserCardsContainer = () => {
  const [businessHasCards, setBusinessHasCards] = useState<boolean>(false);
  const { t } = useTranslation();
  const { showCardShipmentForm, setShowCardShipmentForm } = useCards();
  const { canRequestCard } = useAccess();
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const preventCall = !canRequestCard;
  const {
    isLoading: isLoadingBusinessCards,
    data: businessCards,
    error: getBusinessCardsError,
  } = useQuery<BusinessUserCards[]>(
    ['businessUserWithCards'],
    getBusinessUsersWithCards,
    {
      enabled: !preventCall,
    }
  );

  const { data: physicalCards, isLoading: isLoadingCards } = useQuery<ICard[]>(
    ['physicalCards', ECardType.PHYSICAL],
    async () => await getCardByType(ECardType.PHYSICAL)
  );

  const handleDownloadFile = async () => {
    setIsLoadingFile(true);
    try {
      const data = await getOracleSettlements();
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        })
      );

      window.open(url);

      setIsLoadingFile(false);
    } catch (error) {
      setIsLoadingFile(false);
    }
  };

  useEffect(() => {
    if (businessCards?.length) {
      setBusinessHasCards(true);
    } else {
      setBusinessHasCards(false);
    }
  }, [businessCards]);

  const shippingAddressId = physicalCards?.find((physicalCard) =>
    [ECardStatus.CREATED, ECardStatus.ACTIVE, ECardStatus.EMBOSSED].includes(
      physicalCard.status!
    )
  )?.shippingAddressId;

  const { data: shippingAddress, isLoading } = useQuery<IShippingAddress | any>(
    ['shippingAddress', shippingAddressId],
    async () => {
      if (shippingAddressId) return getUserAddressById(shippingAddressId);
    }
  );

  const shippingAddressDB: IResponseCreateCardShippingAddress = {
    legalAddress: shippingAddress?.legalAddress,
    postalCode: shippingAddress?.postalCode,
    streetNumber: shippingAddress?.streetNumber,
    geographicalDivisionName: shippingAddress?.city?.geographicalDivision?.name,
    cityName: shippingAddress?.city?.name,
  };

  if (isLoading || isLoadingBusinessCards)
    return (
      <Box className="p-10 min-h-[225px] flex items-center justify-center text-center border border-gray-200 bg-gray-50 rounded">
        <Spinner
          size="md"
          thickness="2px"
          speed="0.75s"
          emptyColor="primary.50"
          color="primary.600"
        />
      </Box>
    );

  if (getBusinessCardsError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(getBusinessCardsError as any)?.message}
      />
    );
  }

  return (
    <>
      {businessHasCards && canRequestCard && !showCardShipmentForm ? (
        <CardList
          isLoadingFile={isLoadingFile}
          handleDownloadFile={handleDownloadFile}
          businessUserWithCards={businessCards}
        />
      ) : canRequestCard && shippingAddressDB && physicalCards?.length ? (
        <ThirdStep shippingAddress={shippingAddressDB} />
      ) : (
        <CardRequestProcess />
      )}
    </>
  );
};

export default UserCardsContainer;
