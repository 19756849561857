import { ENationalities, validateCBU } from '@agrotoken/common-utils';
import { MIN_TONS_VALIDATION } from '@common/const';
import { useAuth } from '@src/context/authContext';
import { useTranslation } from 'react-i18next';

import {
  namesRegExp,
  onlyNumbersAndHyphen,
  onlyNumbersAndLettersSlashAndDashRegExp,
} from '@utils/regExp';
import moment from 'moment';
import {
  CprForm,
  EAgreementType,
  IAgreementForm,
  ICdeForm,
  IPix,
  Result,
} from '../common/types';

export const useValidateAgreementForm = (
  agreementForm: IAgreementForm,
  cdeTons: number,
  missingTons?: string,
  grainVolume?: number,
  plantingDate?: number,
  expirationDate?: number,
  isFutureGrain?: boolean,
  selectedOracle?: Result
) => {
  const { t } = useTranslation();
  const validPeriod = moment().startOf('day').add(3, 'days').toDate();
  const minDaysForBr = 10;
  const formatExpirationDate =
    expirationDate && moment.unix(expirationDate).toDate();

  const validPeriodBrFuture = formatExpirationDate as Date;

  const validPeriodBr = moment()
    .startOf('day')
    .add(minDaysForBr, 'days')
    .toDate();

  const isFixedPrice =
    parseInt(agreementForm.agreementTypeId) === EAgreementType.FIXED_PRICE;

  //** type validations */
  const isValidType = agreementForm.agreementTypeId !== '';
  //** internal number validations */
  const isNotEmptyInternalNumber = Boolean(agreementForm.internalNumber);
  const isValidInternalNumber = onlyNumbersAndLettersSlashAndDashRegExp.test(
    agreementForm.internalNumber
  );
  //** agreement tons validations */
  const isValidTons = agreementForm.agreementTons !== '';
  const isValidTonsQuantity =
    +agreementForm.agreementTons >= +agreementForm.tons;
  //** tokenization tons validations */
  const isValidTokenizationTons = agreementForm.tons !== '';
  const isValidTokenizationTonsQuantity = +agreementForm.tons <= cdeTons;
  const isValidTokenizationTonsQuantityCpr =
    grainVolume !== undefined && +agreementForm.tons <= grainVolume;
  const isValidTokenizationTonsMin = +agreementForm.tons >= 5;
  //** price date (`expirationDate`) validations */
  const isValidPriceDate = agreementForm.expirationDate !== null;
  const isValidPriceDatePeriod = moment(
    agreementForm.expirationDate
  ).isSameOrAfter(validPeriod);
  //** tokenization date (`limitRenewDate`) validations */
  const isValidTokenizationDate =
    agreementForm.limitRenewDate !== null &&
    agreementForm.limitRenewDate !== undefined;
  const isValidArgreementExpirationDate = moment(
    agreementForm.limitRenewDate
  ).isSameOrBefore(formatExpirationDate);
  const isValidTokenizationDatePeriod = moment(
    agreementForm.limitRenewDate
  ).isSameOrAfter(validPeriod);
  const isValidTokenizationDateExceeded = moment(
    agreementForm.limitRenewDate
  ).isSameOrBefore(agreementForm.expirationDate);
  //** start date validations */
  const isValidStartDate =
    agreementForm.startDate !== undefined && agreementForm.startDate !== null;
  const isValidStartDatePeriod = moment(agreementForm.startDate).isAfter(
    plantingDate
  );
  //** END date validations */
  const isValidEndDate =
    agreementForm.endDate !== undefined && agreementForm.endDate !== null;
  const isValidEndDatePeriod = moment(agreementForm.endDate).isAfter(
    agreementForm.startDate
  );
  const isValidEndDateBeforeExpiration = moment(
    agreementForm.endDate
  ).isSameOrBefore(agreementForm.limitRenewDate);

  //** price validations */
  const isValidPrice = isFixedPrice ? agreementForm.price !== '' : true;
  const isValidPriceMin = isFixedPrice ? +agreementForm.price > 0 : true;
  //** price validations */
  const isValidFile =
    agreementForm.documentId !== null && agreementForm.documentId !== undefined;

  //** selected oracle validations */
  const isValidOracle = selectedOracle !== undefined;

  const isValidAgreementDoc =
    isValidType &&
    isValidInternalNumber &&
    isNotEmptyInternalNumber &&
    isValidTons &&
    isValidTonsQuantity &&
    isValidTokenizationTons &&
    isValidTokenizationTonsQuantity &&
    isValidTokenizationTonsMin &&
    isValidTokenizationDate &&
    isValidTokenizationDatePeriod &&
    isValidTokenizationDateExceeded &&
    isValidPriceDate &&
    isValidPriceDatePeriod &&
    isValidPrice &&
    isValidPriceMin &&
    isValidFile;

  const isValidAgreementDocBr =
    isValidType &&
    isValidInternalNumber &&
    isNotEmptyInternalNumber &&
    isValidTokenizationTons &&
    isValidTokenizationTonsQuantity &&
    isValidTokenizationTonsMin &&
    isValidTokenizationDate &&
    isValidPrice &&
    isValidPriceMin &&
    isValidFile &&
    isValidOracle;

  const isValidFutureAgreementDocBr =
    isValidArgreementExpirationDate &&
    isValidType &&
    isValidInternalNumber &&
    isNotEmptyInternalNumber &&
    isValidTokenizationTons &&
    isValidTokenizationTonsQuantityCpr &&
    isValidTokenizationDate &&
    isValidStartDate &&
    isValidStartDatePeriod &&
    isValidEndDate &&
    isValidEndDatePeriod &&
    isValidEndDateBeforeExpiration &&
    isValidPrice &&
    isValidPriceMin &&
    isValidFile &&
    isValidOracle;

  const errorMessage = {
    required: t('tokenization.proofOfLiquidity.form.errorMessage.empty'),
    empty: t('tokenization.proofOfLiquidity.form.errorMessage.empty'),
    format: t('tokenization.proofOfLiquidity.form.errorMessage.format'),
    validPeriod: t(
      'tokenization.proofOfLiquidity.form.errorMessage.validPeriod'
    ),
    invalidEndDateBeforeExpiration: t(
      'tokenization.proofOfLiquidity.form.errorMessage.validEndDateBeforeExpiration'
    ),
    invalidEndDatePeriod: t(
      'tokenization.proofOfLiquidity.form.errorMessage.validEndDatePeriod'
    ),
    exceeded: isFixedPrice
      ? t(
          'tokenization.proofOfLiquidity.form.errorMessage.exceededIsFixedPrice'
        )
      : t(
          'tokenization.proofOfLiquidity.form.errorMessage.exceededNoFixedPrice'
        ),
    agreementTons: t(
      'tokenization.proofOfLiquidity.form.errorMessage.agreementTons'
    ),
    tokenizationTons: `${t(
      'tokenization.proofOfLiquidity.form.errorMessage.tonsMissing'
    )} ${missingTons} ${t(
      'tokenization.proofOfLiquidity.form.errorMessage.tonsMissingMessage'
    )} 
        `,
    tokenizationTonsCpr: `${t(
      'tokenization.proofOfLiquidity.form.errorMessage.tonsMissing'
    )} ${missingTons} ${t(
      'tokenization.proofOfLiquidity.form.errorMessage.tonsMissingMessageCpr'
    )} 
        `,
    tokenizationTonsMin: t(
      'tokenization.proofOfLiquidity.form.errorMessage.tokenizationTonsMin'
    ),
    price: t('tokenization.proofOfLiquidity.form.errorMessage.price'),
    validAgreeementExpirationDate: t(
      'tokenization.proofOfLiquidity.form.errorMessage.validAgreeementExpirationDate'
    ),
  };

  const getTokenizationDateErrorMessage = () => {
    const tokenizationDateErrors = [
      {
        validation: !isValidTokenizationDate,
        error: errorMessage.required,
      },
      {
        validation: !isValidTokenizationDatePeriod,
        error: errorMessage.validPeriod,
      },
      {
        validation: !isValidTokenizationDateExceeded,
        error: errorMessage.exceeded,
      },
      {
        validation: isFutureGrain && !isValidArgreementExpirationDate,
        error: errorMessage.validAgreeementExpirationDate,
      },
    ];
    return tokenizationDateErrors.find(
      (tokenizationDateError) => tokenizationDateError.validation
    )?.error;
  };

  const getTokenizationTonsErrorMessage = () => {
    const tonsErrors = [
      {
        validation: !isValidTokenizationTons,
        error: errorMessage.empty,
      },
      {
        validation: !isValidTokenizationTonsMin,
        error: errorMessage.tokenizationTonsMin,
      },
      {
        validation: !isValidTokenizationTonsQuantityCpr && isFutureGrain,
        error: errorMessage.tokenizationTonsCpr,
      },
      {
        validation: !isValidTokenizationTonsQuantity && !isFutureGrain,
        error: errorMessage.tokenizationTons,
      },
    ];
    return tonsErrors.find((tonError) => tonError.validation)?.error;
  };

  return {
    isValidEndDatePeriod,
    isValidFutureAgreementDocBr,
    isValidArgreementExpirationDate,
    isValidAgreementDocBr,
    isValidAgreementDoc,
    isValidStartDate,
    isValidEndDate,
    isValidEndDateBeforeExpiration,
    isValidType,
    isValidTons,
    isValidTonsQuantity,
    isValidInternalNumber,
    isNotEmptyInternalNumber,
    isValidTokenizationTons,
    isValidTokenizationTonsQuantity,
    isValidTokenizationTonsQuantityCpr,
    isValidTokenizationTonsMin,
    isValidPriceDate,
    isValidPriceDatePeriod,
    isValidTokenizationDate,
    isValidTokenizationDatePeriod,
    isValidTokenizationDateExceeded,
    isValidPrice,
    isValidPriceMin,
    isValidFile,
    validPeriodBr,
    validPeriod,
    validPeriodBrFuture,
    isValidOracle,
    tokenizationDateError: getTokenizationDateErrorMessage(),
    tokenizationTonsError: getTokenizationTonsErrorMessage(),
  };
};

export const useValidateCdeForm = (cdesForm: ICdeForm[], index: number) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const isValidTons = (index: number): boolean => cdesForm[index].tons !== '';
  const isValidTonsMin = (index: number): boolean =>
    Number(cdesForm[index].tons) >= 5;

  const isValidTonsMinAllCdes = (cdesForm: ICdeForm[]): boolean => {
    let sum = 0;
    cdesForm.forEach((cde) => {
      sum += Number(cde.tons);
    });
    return sum >= MIN_TONS_VALIDATION;
  };

  // const isValidTonsMinAllCdes = (): boolean => Number(cdesForm) >= 5;
  const isValidInternalNumberLength = (index: number): boolean =>
    nationality === ENationalities.BR ||
    cdesForm[index].internalNumber.length === 12;
  const isValidInternalNumberUnique = (index: number): boolean => {
    const arr = cdesForm.map((cde) => cde.internalNumber);
    return !(arr.indexOf(arr[index]) !== arr.lastIndexOf(arr[index]));
  };
  const isNotEmptyInternalNumber = (index: number): boolean =>
    cdesForm[index].internalNumber !== '';
  const isValidFile = (index: number): boolean =>
    cdesForm[index].documentId !== null &&
    cdesForm[index].documentId !== undefined;

  const isValidInternalNumber = () => {
    if (nationality === ENationalities.AR) {
      return (
        isValidInternalNumberLength(index) &&
        isValidInternalNumberUnique(index) &&
        isNotEmptyInternalNumber(index)
      );
    }
    if (nationality === ENationalities.BR) {
      return (
        isValidInternalNumberUnique(index) && isNotEmptyInternalNumber(index)
      );
    }
  };

  const isValidCde = (index: number) =>
    isValidTonsMinAllCdes(cdesForm) &&
    isValidInternalNumber() &&
    isValidFile(index);

  const isValidCdeBr = (index: number) =>
    isValidTonsMinAllCdes(cdesForm) &&
    isValidInternalNumber() &&
    isValidFile(index);
  const validCdesArr = cdesForm.map((_el, i) => {
    if (nationality === ENationalities.AR) return isValidCde(i);
    return isValidCdeBr(i);
  });
  const areValidCdes: boolean = validCdesArr.every((el) => el === true);

  const errorMessage = {
    empty: t('tokenization.proofOfExistence.form.errorMessage.empty'),
    min: t('tokenization.proofOfExistence.form.errorMessage.min'),
    length: t('tokenization.proofOfExistence.form.errorMessage.length'),
    unique: t('tokenization.proofOfExistence.form.errorMessage.unique'),
  };

  const getInternalNumberErrorMessage = (index: number) => {
    const internalNumberErrors = [
      {
        validation: !isNotEmptyInternalNumber(index),
        error: errorMessage.empty,
      },
      {
        validation: !isValidInternalNumberLength(index),
        error: errorMessage.length,
      },
      {
        validation: !isValidInternalNumberUnique(index),
        error: errorMessage.unique,
      },
    ];
    return internalNumberErrors.find(
      (internalNumberError) => internalNumberError.validation
    )?.error;
  };

  return {
    isValidTons,
    isValidTonsMinAllCdes,
    isValidInternalNumberLength,
    isValidInternalNumberUnique,
    isValidInternalNumber,
    isNotEmptyInternalNumber,
    isValidFile,
    areValidCdes,
    internalNumberError: getInternalNumberErrorMessage(index),
  };
};

export const useValidateDetokenizeForm = (
  tokensToDetokenize: number | null,
  tokenAmountAvailable: number | null
) => {
  const isValidTokens: boolean =
    tokensToDetokenize !== null && !isNaN(tokensToDetokenize);
  const isValidTokensMin: boolean =
    tokensToDetokenize !== null && tokensToDetokenize > 0;
  const isValidTokensMax: boolean =
    tokensToDetokenize !== null &&
    tokenAmountAvailable !== null &&
    tokensToDetokenize <= tokenAmountAvailable;
  const isValidTokensToDetokenize: boolean =
    isValidTokens && isValidTokensMin && isValidTokensMax;
  return {
    isValidTokens,
    isValidTokensMin,
    isValidTokensMax,
    isValidTokensToDetokenize,
  };
};

export const useValidateCprForm = (cprForm: CprForm) => {
  const validPeriod = moment().startOf('day').add(2, 'days').toDate();
  const minDaysForBr = 10;
  const minDaysForFutureBr = 1;
  const validPeriodFutureBr = moment()
    .startOf('day')
    .add(minDaysForFutureBr, 'days')
    .toDate();

  const validPeriodBr = moment()
    .startOf('day')
    .add(minDaysForBr, 'days')
    .toDate();

  const minExpirationDateBr = moment(cprForm.plantingDate)
    .startOf('day')
    .add(minDaysForFutureBr, 'days')
    .toDate();

  //** type validations */
  const isValidType = cprForm.type !== '';
  //** internal number validations */
  const isValidInternalNumber = onlyNumbersAndHyphen.test(
    cprForm.internalNumber
  );
  const isNotEmptyInternalNumber = Boolean(cprForm.internalNumber);
  //** planted area validations */
  const isValidPlantedArea = cprForm.plantedArea !== '';
  //** grain volume validations */
  const isNotEmptyVolume =
    cprForm.grainVolume !== '' &&
    cprForm.grainVolume !== null &&
    cprForm.grainVolume !== undefined;
  const isValidGrainMinVolume = +cprForm.grainVolume >= 5;

  //** plantingDate validations */
  const isValidPlantingDate = cprForm.plantingDate !== null;

  //** expirationDate validations */
  const isValidExpirationDate = cprForm.expirationDate !== null;
  const isValidExpirationDateAfterPlatingDate = moment(
    cprForm.expirationDate
  ).isAfter(cprForm.plantingDate);

  //** price validations */
  const isValidPrice = cprForm.price !== '';
  const isValidPriceMin = +cprForm.price > 0;
  //** file validations */
  const isValidFile =
    cprForm.documentId !== null && cprForm.documentId !== undefined;

  const isValidCpr =
    isValidType &&
    isValidInternalNumber &&
    isNotEmptyInternalNumber &&
    isValidPlantedArea &&
    isNotEmptyVolume &&
    isValidGrainMinVolume &&
    isValidPlantingDate &&
    isValidExpirationDate &&
    isValidExpirationDateAfterPlatingDate &&
    isValidPrice &&
    isValidPriceMin &&
    isValidFile;

  return {
    isValidCpr,
    isValidType,
    isValidInternalNumber,
    isNotEmptyInternalNumber,
    isValidPlantedArea,
    isNotEmptyVolume,
    isValidGrainMinVolume,
    isValidPlantingDate,
    isValidExpirationDate,
    isValidExpirationDateAfterPlatingDate,
    isValidPrice,
    isValidPriceMin,
    isValidFile,
    validPeriodBr,
    validPeriodFutureBr,
    minExpirationDateBr,
  };
};

export const useValidateBusinessForm = (
  cbu: string | null,
  alias: string | null,
  bankEntityNumber: string | null
) => {
  const isValidCbu: boolean = cbu !== null && validateCBU(cbu);

  const isValidAlias: boolean = alias !== null && alias.length >= 3;
  const isValidBankEntityNumber: boolean = bankEntityNumber !== null;

  const isValidBankAccountForm =
    isValidCbu && isValidAlias && isValidBankEntityNumber;

  return {
    isValidBankAccountForm,
    isValidCbu,
    isValidAlias,
    isValidBankEntityNumber,
  };
};

const isValidLength = (
  value: string | null | undefined,
  minLength: number,
  maxLength: number
): boolean => {
  return (
    value !== null &&
    value !== undefined &&
    value.length >= minLength &&
    value.length <= maxLength
  );
};

export const useValidateBusinessFormBr = (
  bankName: string | null,
  accountNumber: string | null,
  accountTypeId: number | null,
  bankEntityNumber: string | null,
  pix: IPix | null,
  subsidiaryNumber: string | null
) => {
  const isValidBankName: boolean =
    bankName !== null &&
    bankName !== undefined &&
    namesRegExp.test(bankName) &&
    isValidLength(bankName, 3, Infinity);
  const isValidAccountNumber: boolean = isValidLength(accountNumber, 3, 20);
  const isValidSubsidiaryNumber: boolean = isValidLength(
    subsidiaryNumber,
    3,
    8
  );
  const isValidAccountType: boolean =
    accountTypeId !== null && accountTypeId !== undefined;
  const isValidBankEntityNumber: boolean = isValidLength(
    bankEntityNumber,
    3,
    3
  );
  const isValidPix: boolean = pix !== null && pix !== undefined;

  const isValidBankAccountFormBr =
    isValidBankName &&
    isValidAccountNumber &&
    isValidSubsidiaryNumber &&
    isValidAccountType &&
    isValidBankEntityNumber &&
    isValidPix;

  return {
    isValidBankAccountFormBr,
    isValidBankName,
    isValidAccountNumber,
    isValidSubsidiaryNumber,
    isValidAccountType,
    isValidBankEntityNumber,
    isValidPix,
  };
};

export const useValidateCreateNewBusiness = (
  businessName: string,
  businessCuit: string,
  representantName: string,
  representantLastName: string
) => {
  const isValidBusinessName = businessName
    ? namesRegExp.test(businessName)
    : false;

  const numberRegex = /^\d+$/;
  const isValidBusinessCuit = businessCuit
    ? numberRegex.test(businessCuit)
    : false; //todo:cambiar
  const isValidRepresentantName = representantName
    ? namesRegExp.test(representantName)
    : false;
  const isValidRepresentantLastName = representantLastName
    ? namesRegExp.test(representantLastName)
    : false;

  const isValidCreateNewBusinessForm =
    isValidBusinessName &&
    isValidBusinessCuit &&
    isValidRepresentantName &&
    isValidRepresentantLastName;

  return {
    isValidCreateNewBusinessForm,
    isValidBusinessName,
    isValidBusinessCuit,
    isValidRepresentantName,
    isValidRepresentantLastName,
  };
};
