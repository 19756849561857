import { Text } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import { useState, FC, ReactElement, useEffect, ReactNode } from 'react';

interface Props {
  color: 'primary' | 'warning' | 'error' | 'info' | 'gray' | 'white' | 'blue';
  text?: string;
  supportText?: string;
  className?: string;
  canClose?: boolean;
  children?: ReactNode;
}

const bgColor = {
  white: 'bg-white border-gray-100',
  blue: 'bg-white border-gray-50',
  primary: 'bg-white border-gray-50',
  warning: 'bg-warning-25 border-warning-50',
  error: 'bg-error-25 border-error-50',
  info: 'bg-info-25 border-info-50',
  gray: 'bg-gray-50 border-gray-100',
};
const textColor = {
  white: 'warning.800',
  primary: 'primary.700',
  warning: 'warning.700',
  error: 'error.700',
  info: 'info.700',
  gray: 'gray.700',
  blue: 'blue.700',
};
const supportTextColor = {
  white: 'gray.700',
  primary: 'gray.700',
  warning: 'warning.700',
  error: 'error.700',
  info: 'info.700',
  gray: 'gray.700',
  blue: 'gray.700',
};
const iconColor = {
  blue: 'var(--chakra-colors-blue-700)',
  white: '#AB6900',
  primary: '#288703',
  warning: '#DA8702',
  error: '#C72100',
  info: '#026AA2',
  gray: '#475467',
};
const icon = {
  white: 'alert-circle',
  primary: 'check-circle',
  warning: 'alert-circle',
  error: 'alert-circle',
  info: 'info',
  gray: 'info',
  blue: 'alert-circle',
};

export const Alert: FC<Props> = ({
  color,
  text,
  supportText,
  className,
  children,
  canClose,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div
      className={`flex justify-between items-start rounded border gray.200 ${className} ${
        !isOpen && 'hidden'
      }`}
    >
      <div className="flex">
        <div className="ml-4 my-5">
          <FeatherIcon
            icon={icon[color]}
            color={`${iconColor[color]}`}
            size={16}
          />
        </div>
        <div className="mx-3 my-4">
          <Text
            fontSize="sm"
            fontFamily="Larsseit-Medium"
            display="inline"
            textColor={`${textColor[color]}`}
          >
            {text}
          </Text>
          {supportText && (
            <Text
              fontSize="sm"
              marginTop="1"
              textColor={supportTextColor[color]}
            >
              {supportText}
            </Text>
          )}
          {children}
        </div>
      </div>
      {canClose && (
        <button className="p-2 m-2" onClick={() => setIsOpen(false)}>
          <FeatherIcon icon="x" color={iconColor[color]} size={20} />
        </button>
      )}
    </div>
  );
};
