import { useUser } from '@src/context/userContext';
import { VFC } from 'react';
import RepresentativeDocs from '../business/RepresentativeDocs';
import UserAddressSection from '../business/UserAddressSection';
import LevelTwoCommon from '../common';
import { OnboardingLevel2Props } from '../types';

const LevelTwoPerson: VFC<OnboardingLevel2Props> = ({
  defaultValues,
  changeRequestMessages = [],
  selectedBusiness,
}) => {
  let { selectedBusinessUserWasInvited } = useUser();
  const { selectedBusinessUserWasAdminInvited } = useUser();

  selectedBusinessUserWasInvited =
    selectedBusinessUserWasInvited && !selectedBusinessUserWasAdminInvited;
  return (
    <LevelTwoCommon
      shouldShowCPFField={selectedBusinessUserWasInvited}
      defaultValues={defaultValues}
      userAddressSection={
        selectedBusinessUserWasInvited ? <UserAddressSection /> : null
      }
      changeRequestMessages={changeRequestMessages}
      representativeSection={
        selectedBusinessUserWasInvited && (
          <RepresentativeDocs documents={defaultValues.legalPowerDocuments} />
        )
      }
      selectedBusiness={selectedBusiness}
    />
  );
};

export default LevelTwoPerson;
