import { ENationalities } from '@agrotoken/common-utils';
import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import FeatherIcon from 'feather-icons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentRequestAsset } from '@common/enum';
import {
  CryptoCurrencySymbol,
  ETokens,
  ETokensBR,
  EUnits,
} from '@common/types';
import { formatNumber } from '@common/utils';
import { formatDate } from '@common/utils/format-date';
import { GenericSummary, SummaryElement } from '@comp/GenericSummary';
import { TokensAmount } from '@comp/PaymentRequestView/Fragments';
import { TokenAmount } from '@comp/PaymentRequestView/Fragments/TokenAmount';
import { useGetConversion, useGetLocalCurrency } from '@hooks/index';
import { useAuth } from '@src/context/authContext';

interface PaymentSummaryProps {
  status: 'READY' | 'LOADING' | 'EMPTY';
  amount: number; // In local currency.
  tax: number; // In local currency.
  paymentAsset: PaymentRequestAsset;
  isPayer: boolean;
  forwardingsAmountSum?: number;
  warrantyReceptionDate?: number;
  selectedToken?: CryptoCurrencySymbol;
  createdDate?: number;
  warrantyPricePerSaca?: Decimal; // In local currency per saca
  receiverFee?: Decimal;
  payerFee?: Decimal;
  finalPrice?: Decimal;
}

export const PaymentSummaryReceiver: FC<PaymentSummaryProps> = ({
  status,
  amount,
  tax,
  paymentAsset,
  isPayer,
  forwardingsAmountSum,
  warrantyReceptionDate,
  selectedToken,
  createdDate,
  warrantyPricePerSaca,
  receiverFee,
  finalPrice,
}) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const { localCurrency } = useGetLocalCurrency();
  const format = 'DD/MM/YYYY';
  const warrantyExpiredDate = formatDate(Number(warrantyReceptionDate), format);
  const showAmountsInSacas =
    paymentAsset === PaymentRequestAsset.WARRANTY && isPayer;
  const showAmountsInTokens =
    paymentAsset === PaymentRequestAsset.TOKENS && selectedToken;

  //TODO: use hooks to getBottom and getTop elements

  /**
   * Currency varies depending on the parameters:
   * When using warranties, it should set local amount when showing to receiver, and SACAS when showing to payer.
   * When using tokens, it should set the amount in tokens when a selected token is received, or local currency otherwise.
   */

  const convertToRequiredCurrency = (amountParam: number, isFee = false) => {
    const amount = new Decimal(amountParam);

    if (showAmountsInSacas) {
      // Convert to SACAS.
      return amount.div(warrantyPricePerSaca ?? 1).toNumber();
    }

    return amount.toNumber();
  };

  const getFormattedNumber = (
    amount: number,
    showAmountsInSacas = false
  ): string => {
    if (showAmountsInSacas) {
      return `${formatNumber(+amount, EUnits.SACAS)} sacas`;
    }

    return formatNumber(amount, localCurrency, true);
  };

  // Change amount/taxes/totalToPay according to what we need to show.
  const totalToPayFiat = new Decimal(amount).minus(tax);
  const paymentRequestAmount = convertToRequiredCurrency(amount);
  const feeAmount = convertToRequiredCurrency(tax, true);

  // TODO: Fix classes for left side paragraphs.
  const getTopElements = (): SummaryElement[] => {
    const subtotalElement: SummaryElement = {
      left: (
        <Text fontSize="lg" variant="medium" color="gray.900">
          {t('payment.summary.localSubtotal')}
        </Text>
      ),
      right:
        nationality === ENationalities.BR ? (
          <Text fontSize="lg" variant="medium" color="gray.900">
            {getFormattedNumber(paymentRequestAmount)}
          </Text>
        ) : (
          <Text fontSize="lg" variant="medium" color="gray.900">
            {localCurrency} {getFormattedNumber(paymentRequestAmount)}
          </Text>
        ),
    };

    const equivalence: SummaryElement = {
      left: <></>,
      right: <></>,
    };

    let rightEquivalenceElement = <></>;

    if (paymentAsset === PaymentRequestAsset.TOKENS) {
      if (selectedToken) {
        // Show equivalence in local currency
        rightEquivalenceElement = (
          <Text>{formatNumber(totalToPayFiat, localCurrency, false)}</Text>
        );
      } else {
        // Show equivalences in all tokens
        equivalence.left = (
          <Box className="flex">
            <Text fontSize="sm" color="gray.700">
              {t('payment.summary.equivalence')}
            </Text>
            <Tooltip
              label={t('payment.summary.equivalenceToolTip')}
              hasArrow
              placement="top"
              gutter={16}
              shouldWrapChildren
              bg="white"
              textColor={'gray.500'}
            >
              <Icon bgSize={4} color="gray.500" padding="0" marginLeft={1}>
                {<FeatherIcon icon="alert-circle" size="24" />}
              </Icon>
            </Tooltip>
          </Box>
        );

        rightEquivalenceElement = (
          <Text fontSize="sm" color="gray.700">
            <TokensAmount
              amountFiat={paymentRequestAmount}
              showCurrencyLabel={false}
            />
          </Text>
        );
      }
    }
    if (paymentAsset === PaymentRequestAsset.WARRANTY) {
      equivalence.left = (
        <Box className="flex">
          <Text fontSize="sm" color="gray.700">
            {t('payment.summary.equivalence')}
          </Text>
          <Tooltip
            label={t('payment.summary.equivalenceToolTip')}
            hasArrow
            placement="top"
            gutter={16}
            shouldWrapChildren
            bg="white"
            textColor={'gray.500'}
          >
            <Icon bgSize={4} color="gray.500" padding="0" marginLeft={1}>
              {<FeatherIcon icon="alert-circle" size="24" />}
            </Icon>
          </Tooltip>
        </Box>
      );
      // isReceiver
      // TotalToPay amount is in local currency, so we convert to SACAS.
      const sacasAmount = new Decimal(paymentRequestAmount).div(
        warrantyPricePerSaca ?? 1
      );
      rightEquivalenceElement = warrantyReceptionDate ? (
        <Text fontSize="sm" color="gray.700">{`${formatNumber(
          sacasAmount,
          EUnits.SACAS
        )} sacas`}</Text>
      ) : (
        <Text>- sacas</Text>
      );
    }
    if (paymentAsset === PaymentRequestAsset.FIXED_GRAIN) {
      equivalence.left = (
        <Box className="flex">
          <Text fontSize="sm" color="gray.700">
            {t('payment.summary.equivalence')}
          </Text>
        </Box>
      );

      const sacasAmount = new Decimal(paymentRequestAmount).div(
        finalPrice && new Decimal(finalPrice).greaterThan(0)
          ? new Decimal(finalPrice)
          : 1
      );
      rightEquivalenceElement = (
        <Text fontSize="sm" color="gray.700">{`${formatNumber(
          sacasAmount,
          EUnits.SACAS
        )} sacas`}</Text>
      );
    }
    equivalence.right = rightEquivalenceElement;
    return [subtotalElement, equivalence];
  };

  // TODO: Fix classes for left side paragraphs.
  const getBottomElements = (): SummaryElement[] => {
    let subtotalElementRight;
    let taxElementRight;
    if (showAmountsInTokens) {
      subtotalElementRight = (
        <TokenAmount
          amount={new Decimal(amount).toNumber()}
          token={selectedToken}
        />
      );
      taxElementRight = (
        <Flex alignContent={'center'}>
          <Text fontSize="sm" color="gray.700">
            <TokenAmount amount={feeAmount} token={selectedToken} />
          </Text>
        </Flex>
      );
    } else {
      const formattedAmount = getFormattedNumber(paymentRequestAmount);
      const formattedTax = getFormattedNumber(feeAmount);
      subtotalElementRight = <Text>{formattedAmount}</Text>;
      taxElementRight = formattedTax;
    }

    const taxElement: SummaryElement = {
      left: (
        <Box className="flex">
          <Text fontSize="sm" color="gray.700">
            {t('payment.summary.internalTax') + ` (${receiverFee}` + '%)'}
          </Text>
          <Tooltip
            label={t('payment.summary.internalTaxToolTip')}
            hasArrow
            placement="top"
            gutter={16}
            shouldWrapChildren
            bg="white"
            textColor={'gray.500'}
          >
            <Icon bgSize={4} color="gray.500" padding="0" marginLeft={1}>
              {<FeatherIcon icon="alert-circle" size="24" />}
            </Icon>
          </Tooltip>
        </Box>
      ),
      right: (
        <Text fontSize="sm" color="gray.700">
          {taxElementRight}
        </Text>
      ),
    };

    const bottomElements = [];
    bottomElements.push(taxElement);

    if (warrantyReceptionDate) {
      const warrantyDateElement: SummaryElement = {
        left: <Text>{t('payment.summary.receptionDateLabel')}</Text>,
        right: <Text>{warrantyExpiredDate}</Text>,
      };
      bottomElements.push(warrantyDateElement);
    }

    if (forwardingsAmountSum) {
      const grossAmountSumElement = formatNumber(
        forwardingsAmountSum,
        localCurrency,
        true
      );

      const totalForwardedElement: SummaryElement = {
        left: (
          <Text fontSize="sm" color="gray.700">
            {t('payment.summary.m2m.totalToForward')}
          </Text>
        ),
        right: <Box className="flex">{grossAmountSumElement}</Box>,
      };
      bottomElements.push(totalForwardedElement);

      const receivedAfterForwardElement: SummaryElement = {
        left: (
          <Text fontSize="sm" color="gray.700">
            {t('payment.summary.m2m.totalReceivedAfterForward')}
          </Text>
        ),
        right: (
          <Box className="flex items-center">
            <Text fontSize="sm" color="gray.700">
              {formatNumber(
                paymentRequestAmount - feeAmount - forwardingsAmountSum,
                localCurrency,
                true
              )}
            </Text>
          </Box>
        ),
      };

      bottomElements.push(receivedAfterForwardElement);
    }
    return bottomElements;
  };

  const getTotalText = () => {
    let totalText;
    if (nationality === ENationalities.BR) {
      totalText = (
        <Text className="font-medium text-sm mb-4 text-gray-700">
          {t('payment.summary.details')}
        </Text>
      );
      return totalText;
    } else {
      totalText = (
        <Text fontSize="sm" variant="medium" color="gray.700" mb={4}>
          {' '}
          {t('payment.summary.totalToReceive')}
        </Text>
      );
      return totalText;
    }
  };

  return (
    <>
      <Box>
        <GenericSummary
          status={status}
          title={
            <Text fontSize={'sm'} variant="medium" color="gray.700">
              {' '}
              {t('payment.summary.title')}
            </Text>
          }
          totalText={getTotalText()}
          totalAmount={<></>}
          topElements={getTopElements()}
          bottomElements={getBottomElements()}
          createdDate={createdDate}
        />
      </Box>
    </>
  );
};
