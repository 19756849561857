import { useQuery } from 'react-query';

import { getLoanPaymentAuthorizationDetails } from '@services/loans';

const useAuthorizeLoanPaymentDetails = (uuid: string) => {
  const { data, isLoading, error, refetch } = useQuery(
    ['loans-authorization-details', uuid],
    () => getLoanPaymentAuthorizationDetails(uuid)
  );

  return { data, isLoading, error, refetch };
};

export default useAuthorizeLoanPaymentDetails;
