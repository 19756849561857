import { useRegionLanguageFromQueryString } from '@hooks/useRegionLanguageFromQueryString';
import BasePage from '../BasePage';
import GeneralInfoForm from './GeneralInfoForm';

const GeneralInfoPage = () => {
  useRegionLanguageFromQueryString();
  return (
    <BasePage>
      <GeneralInfoForm />
    </BasePage>
  );
};

export default GeneralInfoPage;
