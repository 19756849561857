/* eslint-disable import/no-unresolved */
import { Button, Text } from '@chakra-ui/react';
import { SUPPORT_EMAIL } from '@common/const';
import { EPaymentRequestViewer } from '@common/enum';
import { EUnits } from '@common/types';
import { formatNumber } from '@common/utils';
import { useUser } from '@src/context/userContext';
import Decimal from 'decimal.js';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getBusinessFee } from '@services/Businesses';
import { useAuth } from '@src/context/authContext';
import { Modal } from '@agrotoken-tech/ui';
import { FiInfo } from 'react-icons/fi';

interface PaymentsFeeModalProps {
  onAccept: () => void;
  onClose: () => void;
  viewer: EPaymentRequestViewer;
  isOpen: boolean;
}

export const PaymentsFeeModal: FC<PaymentsFeeModalProps> = (props) => {
  const { onAccept, onClose, viewer, isOpen } = props;
  const { t } = useTranslation();
  const { nationality } = useAuth();

  const { selectedBusinessId } = useUser();
  const { data: businessFee } = useQuery<{
    receiverFee: Decimal;
    payerFee: Decimal;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  }>(['businessFee'], () => getBusinessFee(selectedBusinessId!), {
    enabled: !!selectedBusinessId,
  });

  const fee = {
    payer: businessFee?.payerFee,
    receiver: businessFee?.receiverFee,
  };

  const handleSendEmail = () => {
    window.location.href = `mailto:${SUPPORT_EMAIL[nationality]}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('paymentsFeeModal.title')}
      icon={FiInfo}
      onClose={onClose}
    >
      <>
        <Text textColor="gray.700" fontSize="sm" mb="4">
          {t('paymentsFeeModal.text1')}{' '}
          <span className="font-medium">
            {formatNumber(fee[viewer], EUnits.PARAM)}%{' '}
            {t('paymentsFeeModal.bold')}
          </span>{' '}
          {t(`paymentsFeeModal.text2.${viewer}`)}
        </Text>
        <Text textColor="gray.700" fontSize="sm">
          {t(`paymentsFeeModal.text3.${viewer}`)}{' '}
        </Text>
        {viewer === EPaymentRequestViewer.PAYER && (
          <Button
            variant="linkPrimary"
            size="sm"
            color="primary.700"
            onClick={handleSendEmail}
            _hover={{
              textDecoration: 'underline',
            }}
          >
            {SUPPORT_EMAIL[nationality]}
          </Button>
        )}
        <Button
          variant="primary"
          size="lg"
          mt="8"
          width="full"
          onClick={onAccept}
        >
          {t('paymentsFeeModal.btnLabel')}
        </Button>
      </>
    </Modal>
  );
};
