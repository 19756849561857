import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentRequestAsset } from '@common/enum';

import { PaymentAssetSelector } from '../../../CreatePaymentRequest/PaymentAssetSelector';
import { CreatePaymentFormBRProps } from '../../../CreatePaymentRequest/types';
import { GeneralDataSection } from '../GeneralDataSection';
import PaymentDataSection from '../PaymentDataSection';

export const CreatePaymentForm: FC<CreatePaymentFormBRProps> = ({
  translateType,
  fiscalId,
  setFiscalId,
  isChecking,
  firstCheck,
  isValidFiscalId,
  firstSubmit,
  checkedBusiness,
  isErrorCheck,
  isDisabled,
  isWarranty,
  isTokenized,
  showEmptyState,
  handleSelectedWarranty,
  handleSelectedToken,
  handleSelectedFixedGrain,
  setSelectedWarranty,
  selectedWarranty,
  setSelectedFixedGrain,
  selectedFixedGrain,
  isChecked,
  setIsChecked,
  amount,
  setAmount,
  isValidAmount,
  comment,
  setComment,
  setDocumentId,
  documentId,
  maxSizeFileBytes,
  paymentRequestType,
  asset,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <GeneralDataSection
        translateType={translateType}
        fiscalId={fiscalId}
        setFiscalId={setFiscalId}
        isChecking={isChecking}
        firstCheck={firstCheck}
        isValidFiscalId={isValidFiscalId}
        firstSubmit={firstSubmit}
        checkedBusiness={checkedBusiness}
        isErrorCheck={isErrorCheck}
        comment={comment}
        setComment={setComment}
        setDocumentId={setDocumentId}
        documentId={documentId}
        maxSizeFileBytes={maxSizeFileBytes}
        paymentRequestType={paymentRequestType}
      />

      {/* Asset Type */}
      <Box className="mb-6">
        <PaymentAssetSelector
          isDisabled={isDisabled}
          isWarranty={isWarranty}
          isTokenized={isTokenized}
          isFixedGrain={asset === PaymentRequestAsset.FIXED_GRAIN}
          showEmptyState={showEmptyState}
          handleSelectedWarranty={handleSelectedWarranty}
          handleSelectedToken={handleSelectedToken}
          handleSelectedFixedGrain={handleSelectedFixedGrain}
          setSelectedWarranty={setSelectedWarranty}
          selectedWarranty={selectedWarranty}
          setSelectedFixedGrain={setSelectedFixedGrain}
          selectedFixedGrain={selectedFixedGrain}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          toBusinessFiscalId={fiscalId}
        />
      </Box>
      <PaymentDataSection
        title={`3. ${t('createPayment.form.amount.title')}`}
        {...{
          amount,
          firstSubmit,
          isValidAmount,
          setAmount,
          translateType,
          isWarranty,
        }}
      />
    </Box>
  );
};
