import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { FC } from 'react';
import { IInputProps } from './interfaces';

const InputItem: FC<IInputProps> = ({
  register,
  errors,
  inputName,
  inputType,
  label,
  placeholder,
}) => {
  return (
    <FormControl
      isInvalid={Boolean(errors[`${inputName}`])}
      marginBottom={4}
      maxW={{ base: '100%', xl: '295px' }}
    >
      <FormLabel>{label}</FormLabel>
      <Input
        {...register(`${inputName}`)}
        marginTop={1.5}
        type={inputType}
        placeholder={placeholder}
      />
      <FormErrorMessage>
        {errors[inputName] && errors[inputName]?.message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default InputItem;
