import { IMerchantRequest } from '@src/common/types';
import { http } from '../http-common';
import { PLATFORM_PREFIX } from '@agrotoken/common-utils';

export const createMerchantRequest = async (
  merchantRequestData: any
): Promise<IMerchantRequest | undefined> => {
  try {
    const resp = await http.post<IMerchantRequest>(
      `/${PLATFORM_PREFIX}/merchant-requests`,
      merchantRequestData
    );
    const dataResult: IMerchantRequest | undefined = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data?.message);
  }
};

export const getMerchantRequest = (): Promise<IMerchantRequest> => {
  return http
    .get<IMerchantRequest>(`/${PLATFORM_PREFIX}/merchant-requests`)
    .then((resp) => {
      const dataResult: IMerchantRequest = resp.data;
      return dataResult;
    });
};
