import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SELECT_ORG } from '@common/const';
import { useUser } from '@src/context/userContext';

/**
 * Custom hook for navigating to the SELECT_ORG route if the user does not have a selected business.
 *
 * @returns {void}
 */
export const useNavigateSelectOrg = () => {
  const { selectedBusinessId } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedBusinessId) {
      navigate(SELECT_ORG);
    }
  }, [selectedBusinessId, navigate]);
};
