import { Box, Flex, Text } from '@chakra-ui/react';
import Feathericon from 'feather-icons-react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RipioSvg } from './RipioSvg';

interface RipioWalletProps {
  selected: boolean;
  toggleSelected: () => void;
}

const RIPIO_PURPLE = '#7908FF';
const WHITE = '#FFFFFF';

export const RipioWallet: React.VFC<RipioWalletProps> = (props) => {
  const { selected, toggleSelected } = props;
  const { t } = useTranslation();

  const [hovered, setHovered] = useState<boolean>(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const mouseOverListener = useCallback(() => setHovered(true), []);
  const mouseOutListener = useCallback(() => setHovered(false), []);

  useEffect(() => {
    const box = boxRef.current;

    box?.addEventListener('mouseenter', mouseOverListener);
    box?.addEventListener('mouseleave', mouseOutListener);

    return () => {
      box?.removeEventListener('mouseenter', mouseOverListener);
      box?.removeEventListener('mouseleave', mouseOutListener);
    };
  }, []); // eslint-disable-line

  return (
    <Box
      border="1px"
      borderColor="gray.200"
      height="80px"
      marginTop="6"
      borderRadius="4"
      alignItems="center"
      cursor="pointer"
      backgroundColor={selected ? `${RIPIO_PURPLE}` : ''}
      onClick={toggleSelected}
      ref={boxRef}
      webkit-justify-content="none"
      justifify-content="none"
      _hover={{
        borderColor: 'gray.300',
      }}
    >
      <Flex justifyContent="space-between" marginTop="4" alignItems="center">
        <Box>
          <Flex flexDirection="row" alignItems="center">
            {selected && (
              <Flex marginStart="4">
                <Feathericon icon="check-circle" size="20" color="white" />
              </Flex>
            )}

            <Flex flexDir="column">
              <Text
                marginStart="4"
                fontFamily="Larsseit-Medium"
                fontSize="md"
                color={selected ? `${WHITE}` : 'gray.900'}
              >
                {t('transferTokens.walletStep.ripioTitle')}
              </Text>
              <Text
                fontFamily="Larsseit"
                fontSize="md"
                marginStart="4"
                color={selected ? `${WHITE}` : 'gray.700'}
              >
                {t('transferTokens.walletStep.ripioSubtitle')}
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Box>
          <RipioSvg
            fill={selected ? `${WHITE}` : hovered ? `${RIPIO_PURPLE}` : 'black'}
            fillOpacity={!hovered && !selected ? 0.3 : 1}
          />
        </Box>
      </Flex>
    </Box>
  );
};
