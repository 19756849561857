/**
 * ERROR_CODES
 *
 * Error codes exposed by the API
 */
export const ERROR_CODES = {
  INVALID_AMOUNT: 'INVALID_AMOUNT',
  INVALID_TYPE: 'INVALID_TYPE',
  INVALID_ENTITY_ID: 'INVALID_ENTITY_ID',
  ENTITY_NOT_FOUND: 'ENTITY_NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_OWNER: 'NOT_OWNER',
  NOT_IMPLEMENTED: 'NOT_IMPLEMENTED',
};

/**
 * ErrorCode
 *
 * Error codes exposed by the API
 */
export type ErrorCode = keyof typeof ERROR_CODES;
