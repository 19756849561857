import { AgrotokenLogo } from '@agrotoken-tech/ui';
import { Heading } from '@chakra-ui/react';
import jwt_decode from 'jwt-decode';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { useRegionLanguageFromQueryString } from '@hooks/useRegionLanguageFromQueryString';
import welcomeVideo from '../../assets/videos/welcome-video.m4v';
import {
  HOME_PATH,
  RESET_PASSWORD_PATH,
  SELECT_ORG,
  ECognitoErrors,
  ERROR_MESSAGE,
  BREAKPOINTS,
} from '../../common/const';
import { INavigationLoginState } from '../../common/types';
import { useAuth } from '../../context/authContext';
import Form from './Form';
import SignupNotice from './SignupNotice';

const Login: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as INavigationLoginState;
  const selectedBusinessId = localStorage.getItem('selectedBusinessId');

  useRegionLanguageFromQueryString();

  const { t } = useTranslation();

  const isMobile = window.innerWidth < BREAKPOINTS.md;

  useEffect(() => {
    if (!auth) return;

    auth
      .isAuth()
      .then(() => {
        if (selectedBusinessId !== 'null') {
          navigate(HOME_PATH);
        } else {
          navigate(SELECT_ORG);
        }
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }, [auth, navigate]);

  const ldClient = useLDClient();

  const handleSubmit = (ev: any) => {
    ev.preventDefault();
    setErrorMessage('');
    if (email && password) {
      setIsLoading(true);
      if (!auth) return;
      auth.signIn(email, password).then(
        async (resp: any) => {
          try {
            setIsAuthorized(true);
            setIsLoading(false);

            if (state?.returnUrl) {
              localStorage.setItem('stateUrl', state?.returnUrl);
            }

            navigate(SELECT_ORG);
          } catch (error: any) {
            setIsLoading(false);
            setErrorMessage(error?.message || ERROR_MESSAGE);
            auth.signOut();
            console.error(error);
          }

          // Set user in LaunchDarkly
          const decoded_jwt: any = jwt_decode(resp.idToken.jwtToken);
          const newUser = {
            key: decoded_jwt['cognito:username'],
          };
          const previousUser = ldClient?.getUser();
          await ldClient?.identify(newUser);
          if (previousUser) {
            ldClient?.alias(newUser, previousUser);
          }
        },
        (error: unknown) => {
          // Users may be due for a forced reset - in this case,
          // redirect the the reset password screen
          if (
            (error as CognitoError).code ===
            ECognitoErrors.PASSWORD_RESET_REQUIRED
          ) {
            return navigate(`${RESET_PASSWORD_PATH}?force-reset=true`, {
              state: {
                email,
              },
            });
          }
          if (error === 'NEW_PASSWORD_REQUIRED') {
            return navigate(`${RESET_PASSWORD_PATH}/new-password-required`);
          }

          setIsAuthorized(false);
          setIsLoading(false);
          setPassword('');
        }
      );
    }
  };

  return (
    <div className="flex h-screen justify-center">
      <div
        className={isMobile ? 'w-full px-4' : 'max-w-sm m-auto px-4 md:px-0'}
      >
        {isMobile ? (
          <>
            <div className="mb-8 w-full flex justify-center">
              <AgrotokenLogo variant="isotype" height="80" width="80" />
            </div>
            <div className="mb-8 flex justify-center">
              <Heading
                className="display-md font-semibold text-gray-900 mb-2"
                size="xs"
              >
                {t('login.title')}
              </Heading>
            </div>
          </>
        ) : (
          <>
            <div className="mb-8 w-full flex">
              <AgrotokenLogo variant="imagotype" />
            </div>
            <div className="mb-8">
              <Heading
                className="display-md font-semibold text-gray-900 mb-2"
                size="md"
              >
                {t('login.title')}
              </Heading>
              <p className="text-md text-gray-500">{t('login.subtitle')}</p>
            </div>
          </>
        )}

        <Form
          onSubmit={handleSubmit}
          isLoading={isLoading}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          isAuthorized={isAuthorized}
          errorMessage={errorMessage}
        />
        <SignupNotice />
      </div>
      {!isMobile && (
        <div className="hidden lg:block w-1/2">
          <div className="overflow-y-hidden h-screen relative">
            <div className="absolute h-screen w-full flex items-center justify-center">
              <div className="px-6 xl:px-0 max-w-lg">
                <h1 className="display-lg font-normal text-white mb-6">
                  {t('login.sideTitle')}
                </h1>
                <p className="text-white mr-8">{t('login.sideDescription')}</p>
              </div>
            </div>
            <video
              autoPlay
              muted
              loop
              className="overflow-y-hidden object-cover w-full h-screen"
            >
              <source src={welcomeVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
