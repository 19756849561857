import { Forwarding } from '@common/types/payment-request-forwarding';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getForwardingsDetail } from '@services/PaymentRequests';

export const useForwarding = () => {
  const { uuid } = useParams();

  //Query
  const { isLoading, error, data } = useQuery<Forwarding | undefined>(
    ['forwarding', uuid],
    async () => {
      if (!uuid) return;
      return getForwardingsDetail(uuid);
    }
  );

  return { isLoading, error, data };
};
