import { ENationalities } from '@agrotoken/common-utils';
import { ButtonGroup } from '@agrotoken-tech/ui';
import { Box, Button, Text } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WarrantySelector from '@comp/WarrantySelector/WarrantySelector';
import FixedGrainSelector from '@features/paymentRequest/FixedGrainSelector';
import { useAuth } from '@src/context/authContext';
import { Tokens } from './Tokens';
import { PaymentAssetSelectorProps } from './types';

export const PaymentAssetSelector: FC<PaymentAssetSelectorProps> = (props) => {
  const { nationality } = useAuth();
  const { t } = useTranslation();
  const {
    isDisabled,
    isWarranty,
    isFixedGrain,
    showEmptyState,
    handleSelectedWarranty,
    handleSelectedToken,
    handleSelectedFixedGrain,
    setIsChecked,
    setSelectedWarranty,
    selectedWarranty,
    setSelectedFixedGrain,
    selectedFixedGrain,
    toBusinessFiscalId,
  } = props;

  useEffect(() => {
    //TODO:Add isRealGrain as prop is more payment types are added
    if (!isWarranty && !isFixedGrain) {
      handleSelectedToken();
    }
  }, [isWarranty]);

  switch (nationality) {
    case ENationalities.AR:
    default:
      return (
        <>
          {/* Show available tokens */}
          <Box className="bg-white border border-gray-200 rounded p-6 mb-6">
            <Text className="text-base text-gray-900 font-medium mb-4">
              {`2. ${t('createPayment.form.asset.title')}`}
            </Text>
            {/* TODO: Replace component to use asset selection. */}
            <Tokens />
          </Box>
        </>
      );

    case ENationalities.BR:
      return (
        <>
          {!isDisabled && (
            <Box
              padding={6}
              borderRadius={3}
              borderColor="gray.200"
              borderWidth="1px"
              w="100%"
            >
              <Text fontFamily="Larsseit-Medium" fontSize="md" color="#000000">
                {`2. ${t('createPayment.form.asset.title')}`}
              </Text>
              <Box
                display={'flex'}
                flexDirection={{ base: 'column', xl: 'row' }}
                justifyContent="space-between"
                alignItems={{ base: 'space-between', xl: 'center' }}
                mt={4}
              >
                <ButtonGroup
                  size="lg"
                  width="100%"
                  selectedId={0}
                  buttons={[
                    <Button key={3} onClick={() => handleSelectedWarranty()}>
                      {t('warranties')}
                    </Button>,
                    <Button
                      key={2}
                      onClick={handleSelectedFixedGrain}
                      defaultChecked
                    >
                      {t('fixedGrain')}
                    </Button>,

                    <Button
                      key={1}
                      onClick={handleSelectedToken}
                      defaultChecked
                    >
                      {t('agrotokens')}
                    </Button>,
                  ]}
                />
              </Box>

              {isWarranty && !showEmptyState && (
                <>
                  <WarrantySelector
                    setSelectedWarranty={setSelectedWarranty}
                    selectedWarranty={selectedWarranty}
                    setIsChecked={setIsChecked}
                    toBusinessFiscalId={toBusinessFiscalId}
                  />
                </>
              )}

              {isFixedGrain && (
                <FixedGrainSelector
                  setSelectedFixedGrain={setSelectedFixedGrain}
                  selectedFixedGrain={selectedFixedGrain}
                  setIsChecked={setIsChecked}
                  toBusinessFiscalId={toBusinessFiscalId}
                />
              )}
            </Box>
          )}
        </>
      );
  }
};
