import React, { FC, HTMLAttributes } from 'react';

export interface Props extends HTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  className?: string;
}

export const Container: FC<Props> = ({
  children,
  className = '',
  ...extraProps
}) => {
  return (
    <div
      className={`max-w-full sm:max-w-7xl  sm:max-xl:mx-8 mx-auto sm:w-full px-4 lg:px-0    ${className}`}
      {...extraProps}
    >
      {children}
    </div>
  );
};
