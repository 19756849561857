import { Checkbox } from '@agrotoken-tech/ui';
import { Box, Flex, Heading, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FULL_ONBOARDING_LEVEL_2, HOME_PATH } from '@common/const';
import {
  EBusinessStructureTypes,
  EFullOnboardingLevel,
  EValidationStatusId,
  IBankAccount,
  IBusiness,
  IOnboardingLevel3Request,
} from '@common/types';
import CongratsModal from '@comp/FullOnboarding/CongratsModal';
import ChangeRequestedAlert from '@comp/FullOnboarding/common/ChangeRequestedAlert';
import { getOneBankAccountByBusiness } from '@services/BankAccounts';
import { getBusinessById } from '@services/Businesses';
import { getLvl3Request, saveOnboardingLevel3 } from '@services/FullOnboarding';
import { createMerchantRequest } from '@services/MerchantRequests';
import { useFullOnboardingAccess } from '@src/context/fullOnboardingAccessContext';
import { useUser } from '@src/context/userContext';
import Form from '../../common/layouts/Form';
import ProgressIndicator from '../common/ProgressIndicator';
import BankAccountForm from './BankAccountForm';
import MainActivity from './MainActivity';
import { ServerError } from '@comp/Global/ServerError';

const FullOnboardingLvl3 = () => {
  const [merchantRequest, setMerchantResquest] = useState<boolean>(false);
  const { fetchRequests, hasNotSentOnboardingLevel2_v2 } =
    useFullOnboardingAccess();
  const { t } = useTranslation();

  const user = useUser();

  const navigate = useNavigate();

  const { selectedBusinessId, selectedBusinessUserId, currentUser } = user;

  const {
    isError: isErrorBusiness,
    isLoading: isLoadingBusiness,
    data: business,
  } = useQuery<IBusiness>(['business', selectedBusinessId], getBusinessById);

  const {
    isError: isErrorRequest,
    isLoading: isLoadingRequest,
    data: request,
    refetch,
  } = useQuery<IOnboardingLevel3Request | undefined>(
    ['lvl3Request', selectedBusinessUserId],
    getLvl3Request
  );

  const {
    isLoading: isLoadingBankAccount,
    error: isErrorBankAccount,
    data: bankAccount,
  } = useQuery<IBankAccount | undefined>(
    ['bankAccount', selectedBusinessId],
    getOneBankAccountByBusiness
  );

  const {
    mutate: mutateCreateMerchantRequest,
    isLoading: isLoadingMutateCreateMerchantRequest,
    isError: isErrorMutateCreateMerchantRequest,
  } = useMutation(createMerchantRequest);

  const { isLoading, isError, mutate } = useMutation(saveOnboardingLevel3, {
    onSuccess: fetchRequests,
  });

  const formMethods = useForm();

  const { handleSubmit, reset, watch } = formMethods;

  useEffect(() => reset(request), [request, reset]);

  useEffect(() => {
    const bussinessOnboardingLevel =
      user.selectedBusinessUser?.fullOnboardingLevel?.id ?? 1;
    const canFillOnboardingLvl3 =
      bussinessOnboardingLevel > EFullOnboardingLevel.LEVEL1 ||
      !hasNotSentOnboardingLevel2_v2;
    if (!canFillOnboardingLvl3) {
      navigate(FULL_ONBOARDING_LEVEL_2);
    }
  }, [hasNotSentOnboardingLevel2_v2, user, navigate]);

  useEffect(() => {
    const businessActivityId = business?.businessActivityId ?? '';

    reset({
      accountNumber: bankAccount?.accountNumber ?? '',
      accountTypeId: bankAccount?.accountTypeId ?? '',
      bankName: bankAccount?.entityName ?? '',
      bankCode: bankAccount?.entityNumber ?? '',
      subsidiaryNumber: bankAccount?.subsidiaryNumber ?? '',
      pixTypeId: bankAccount?.pix?.pixTypeId ?? '',
      pix: bankAccount?.pix?.value ?? '',
      businessActivityId,
    });
  }, [bankAccount, business]);

  const manipulateData = (data: any, isDraft: boolean): any => {
    const {
      businessActivityId,
      bankName,
      bankCode,
      accountTypeId,
      subsidiaryNumber,
      accountNumber,
      pix,
      pixTypeId,
    } = data;

    return {
      businessActivityId: businessActivityId ? +businessActivityId : null,
      bankName,
      bankCode,
      accountTypeId: accountTypeId ? +accountTypeId : null,
      subsidiaryNumber,
      accountNumber,
      pix,
      pixTypeId: pixTypeId ? +pixTypeId : null,
      ...(isDraft
        ? {}
        : { isPendingOpsReview: true, isPendingUserReview: false }),
    };
  };

  const onSubmit = async (data: any) => {
    const dataToSend = manipulateData(data, false);
    dataToSend.validationStatusId = EValidationStatusId.PENDING;

    const businessUserId = currentUser?.businessUsers.find(
      (bu) => bu.businessId === selectedBusinessId
    )?.id;

    if (merchantRequest) {
      mutateCreateMerchantRequest({
        createdByBusinessUserId: businessUserId,
      });
    }
    mutate(dataToSend, { onSuccess: () => refetch() });
  };

  const onCompleteLater = async () => {
    const data = watch();
    const dataToSend = manipulateData(data, true);
    await saveOnboardingLevel3(dataToSend);
    navigate(HOME_PATH);
  };

  if (isErrorBusiness || isErrorRequest || isErrorBankAccount)
    return (
      <ServerError
        title={t('serverError.title')}
        text={t('serverError.text')}
      />
    );

  if (isLoadingBusiness || isLoadingRequest || isLoadingBankAccount)
    return (
      <Flex alignItems="center" justifyContent="center">
        <Spinner />
      </Flex>
    );

  const isCompany =
    EBusinessStructureTypes.COMPANY === business?.businessStructureTypeId;

  const isPerson =
    EBusinessStructureTypes.PERSON === business?.businessStructureTypeId;

  const requestExists = !!request;

  const alreadyCompleted =
    requestExists && request.validationStatusId === EValidationStatusId.PENDING;

  const validationMessages = [];
  const businessValidationMessage = request?.businessValidationMessage;
  if (businessValidationMessage) {
    validationMessages.push(businessValidationMessage);
  }
  const bankAccountValidationMessage = request?.bankAccountValidationMessage;
  if (bankAccountValidationMessage) {
    validationMessages.push(bankAccountValidationMessage);
  }

  if (!alreadyCompleted)
    return (
      <FormProvider {...formMethods}>
        <Form
          handleSubmit={handleSubmit(onSubmit)}
          isLoading={isLoading || isLoadingMutateCreateMerchantRequest}
          rightSideContent={<ProgressIndicator level={3} />}
          secondaryAction={onCompleteLater}
        >
          <Flex flexDir="column" w="full" gap="8">
            <Box>
              <Heading size="sm" variant="medium" mb="2">
                {t('onboardingLevelThree.title')}
              </Heading>
              <Text fontWeight="normal">
                {t('onboardingLevelThree.subtitle')}
              </Text>
            </Box>
            {validationMessages.length > 0 && (
              <Box>
                <ChangeRequestedAlert messages={validationMessages} />
              </Box>
            )}
            {isPerson && <MainActivity />}
            <BankAccountForm />

            <Checkbox
              description={t('onboardingLevelThree.merchantRequestDescription')}
              onChange={() => {
                setMerchantResquest((prev) => !prev);
              }}
              checked={merchantRequest}
            >
              {t('onboardingLevelThree.merchantRequest')}
            </Checkbox>
          </Flex>
        </Form>
      </FormProvider>
    );

  return (
    <CongratsModal
      title={t('onboardingLevelThree.successModal.title')}
      description={t('onboardingLevelThree.successModal.description')}
      isModalOpen={alreadyCompleted}
      handleCloseModal={() => navigate(HOME_PATH)}
      primaryAction={() => navigate(HOME_PATH)}
      primaryActionLabel={t(
        'onboardingLevelThree.successModal.primaryActionLabel'
      )}
    />
  );
};

export default FullOnboardingLvl3;
