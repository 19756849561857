import { ENABLED, HOME_PATH, SELECT_ORG } from '@src/common/const';
import { AuthRegisterDto, IBusiness, IBusinessUser } from '@src/common/types';
import { useUser } from '@src/context/userContext';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { createNewBusiness } from '@services/Businesses';
import BasePage from '../BasePage';
import GeneralInfoForm from './GeneralInfoForm';

export interface FormValues extends AuthRegisterDto {
  'password-confirm': string;
  'consent-terms': boolean;
}

const CreateNewBusiness = () => {
  const [mutateError, setMutateError] = useState<string>('');
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  const navigate = useNavigate();

  const { currentUser, setCurrentUser, setSelectedBusiness } = useUser();
  const {
    mutate: mutateCreateNewBusiness,
    isLoading: isLoadingMutate,
    isError: isErrorMutate,
  } = useMutation(createNewBusiness, {
    onSuccess: (data: IBusiness | undefined) => {
      if (!data) return;
      const business: IBusiness = data;
      setMutateError('');
      if (
        currentUser!.businessUsers.filter(
          (bu) => bu.isActive === ENABLED && bu.isRegistered === ENABLED
        ).length > 1
      )
        navigate(SELECT_ORG);
      else {
        navigate(HOME_PATH);
      }
    },
    onError: (err: Error) => {
      setMutateError(err.message);
    },
  });
  const userId = currentUser?.id;

  return (
    <BasePage>
      <GeneralInfoForm
        createNewBusinessFunction={async (body: object) =>
          mutateCreateNewBusiness({ ...body, userId })
        }
      />
    </BasePage>
  );
};

export default CreateNewBusiness;
