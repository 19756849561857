import { ENationalities } from '@agrotoken/common-utils';
import { EPaymentRequestType } from '@common/types';
import { useCallback, useMemo } from 'react';
import { PaymentForwardingFormData } from '../types';

interface UseForwardingsValidationsParams {
  forwardings: PaymentForwardingFormData[];
  creatorFiscalId: string;
  payerFiscalId: string;
  amountToReceive: number;
  country: ENationalities;
  paymentType: EPaymentRequestType;
}

const useForwardingsValidations = ({
  forwardings,
  creatorFiscalId,
  payerFiscalId,
  amountToReceive,
  country,
  paymentType,
}: UseForwardingsValidationsParams) => {
  const validateForwardingsFiscalId = useCallback(() => {
    const isCreatorOrPayerOrEmptyFiscalId = forwardings.some((forwarding) => {
      return (
        // Check if any forwardings fiscalIds is empty
        forwarding.fiscalId === '' ||
        // Check if any forwardings fiscalIds is equal to creator fiscalId repeated
        forwarding.fiscalId === creatorFiscalId ||
        // Check if any forwardings fiscalIds is equal to payer fiscalId repeated
        forwarding.fiscalId === payerFiscalId
      );
    });

    if (isCreatorOrPayerOrEmptyFiscalId) return false;

    const areForwardingsFiscalIdDuplicated = forwardings.reduce(
      (acc, obj, _index, arr) => {
        const duplicateIndex = arr.findIndex(
          (item) => item.fiscalId === obj.fiscalId && item !== obj
        );
        if (duplicateIndex !== -1) {
          acc = true;
        }
        return acc;
      },
      false
    );

    if (areForwardingsFiscalIdDuplicated) return false;

    return true;
  }, [creatorFiscalId, forwardings, payerFiscalId]);

  const validateForwardingsAmounts = useCallback(() => {
    const isEmptyAmount = forwardings.some(
      (forwarding) => forwarding.amount === ''
    );

    if (isEmptyAmount) return false;

    // Get the total amount of forwardings
    const forwardingsAmount = forwardings.reduce((acc, currentValue) => {
      const amount = Number.isNaN(parseFloat(currentValue.amount))
        ? 0
        : parseFloat(currentValue.amount);

      return acc + amount;
    }, 0);

    return forwardingsAmount <= amountToReceive;
  }, [amountToReceive, forwardings]);

  const areValidForwardings = useMemo(() => {
    // Evaluate cases where we don't need to validate forwardings
    if (
      // Check if country is Brazil
      country === ENationalities.BR ||
      // Check if is a payment in kind
      paymentType === EPaymentRequestType.IN_KIND ||
      // Check if we have forwardings
      forwardings.length === 0
    ) {
      return true;
    }

    return validateForwardingsFiscalId() && validateForwardingsAmounts();
  }, [
    country,
    forwardings.length,
    paymentType,
    validateForwardingsAmounts,
    validateForwardingsFiscalId,
  ]);

  return { areValidForwardings };
};

export default useForwardingsValidations;
