import { Currency, weightUnits, WEIGHT_UNITS } from '@agrotoken/common-utils';

import { useQuery } from 'react-query';
import { pricingConvert } from '@services/Currencies';
import { ValueOf } from '@common/types';

/**
 * useGetConversion
 *
 * @param {number} quantity
 * @param {string} sourceCurrency
 * @param {string} targetCurrency
 * @returns {number}
 */

// TODO: Remove duplicate type for targetCurrency param
export const useGetConversion = (
  quantity: number,
  sourceCurrency: Currency,
  targetCurrency?: Currency,
  targetWeightUnits?: ValueOf<typeof WEIGHT_UNITS>
): number => {
  const { data } = useQuery<any | undefined>(
    ['convertedPrice', quantity, sourceCurrency, targetCurrency],
    () =>
      pricingConvert({
        quantity,
        sourceCurrency,
        targetCurrency,
        targetWeightUnits,
      }),
    {
      enabled: !!targetCurrency,
    }
  );

  return data?.amount ?? 0;
};

export default useGetConversion;
