import { Box, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Icon } from '@comp/Global/Icon';
import LoanAuthorizationCard from '@comp/LoanAuthorizationCard';
import { LenderType } from '@features/loans/enums';
import { getLenderTypeTranslation } from '@features/loans/utils';

// TODO: Use env
const supportEmail = 'soporte@agrotoken.io';

const PaymentAuthorizationErrorLayout = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const { logo, lenderType } = location.state;

  const translation = getLenderTypeTranslation(lenderType as LenderType);

  return (
    <LoanAuthorizationCard logo={logo}>
      <Box color="error.700" mb="4">
        <Icon
          size={'lg'}
          color={'success'}
          theme={'no-background'}
          icon="x-circle"
        />
      </Box>
      <Text size="lg" color="gray.900" mb="2">
        {t(`${translation}.paymentAuthorization.error.heading`)}
      </Text>
      <Text size="md" color="gray.700">
        {t(`${translation}.paymentAuthorization.error.subheading`)}
        <Button
          color="primary.700"
          variant="linkPrimary"
          _hover={{ textDecoration: 'underline' }}
        >
          {supportEmail}
        </Button>
      </Text>
    </LoanAuthorizationCard>
  );
};

export default PaymentAuthorizationErrorLayout;
