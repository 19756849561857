import { BRA_TOKEN_CURRENCIES } from '@agrotoken/common-utils';
import { GrainContractTypes } from '@common/enum/grain-contract.enum';
import CurrencySummaryTranslationParams from './currency-summary-translation.interface';

/**
 * Retrieves the translation key for detokenization modal summary based on the grain contract.
 * @param {IGrainContract} grainContract - The grain contract.
 * @returns {string | currencyName} The translation key for detokenization modal summary, or currencyName if not found.
 */

export const getCurrencySummaryTranslation = (
  getGrainContractDetailsByTypeParams: CurrencySummaryTranslationParams
) => {
  const { grainContractType, currencyName } =
    getGrainContractDetailsByTypeParams;

  const keyPrefix = 'tokenization.summary.breakDown';
  const CURRENCY_TRANSLATIONS = [
    {
      grainContractType: GrainContractTypes.FUTURE,
      token: BRA_TOKEN_CURRENCIES.SOYB,
      translation: `${keyPrefix}.soyWarranty`,
    },
    {
      grainContractType: GrainContractTypes.FUTURE,
      token: BRA_TOKEN_CURRENCIES.CORB,
      translation: `${keyPrefix}.corWarranty`,
    },
    {
      grainContractType: GrainContractTypes.FUTURE,
      token: BRA_TOKEN_CURRENCIES.COFB,
      translation: `${keyPrefix}.coffeeWarranty`,
    },
    {
      grainContractType: GrainContractTypes.FIXED_GRAIN,
      token: BRA_TOKEN_CURRENCIES.SOYB,
      translation: `${keyPrefix}.soyFixedGrain`,
    },
    {
      grainContractType: GrainContractTypes.FIXED_GRAIN,
      token: BRA_TOKEN_CURRENCIES.CORB,
      translation: `${keyPrefix}.corFixedGrain`,
    },
    {
      grainContractType: GrainContractTypes.REAL,
      token: BRA_TOKEN_CURRENCIES.SOYB,
      translation: currencyName,
    },
    {
      grainContractType: GrainContractTypes.REAL,
      token: BRA_TOKEN_CURRENCIES.CORB,
      translation: currencyName,
    },
  ];

  return (
    CURRENCY_TRANSLATIONS.find(
      (item) =>
        item.grainContractType === grainContractType &&
        item.token === currencyName
    )?.translation ?? currencyName
  );
};
