import { Button, Divider, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { TCurrencyName } from '@common/types';
import { Loader } from '@comp/Global/Loader';
import { TokenIcon } from '@comp/Global/TokenIcon';
import LoanAuthorizationCard from '@comp/LoanAuthorizationCard';
import { LenderType } from '@features/loans/enums';
import {
  useCollateralizeLoan,
  useCollateralizeLoanData,
} from '@features/loans/hooks';
import { getLenderTypeTranslation } from '@features/loans/utils';

const LoanAuthorizationLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loanUuid } = useParams();
  const { mutate, isLoading } = useCollateralizeLoan();

  // Get transfer details data
  const { isLoadingCollateralizeLoanDetails, collateralizeLoanDetails } =
    useCollateralizeLoanData();

  const {
    beneficiaryName,
    lenderLogo,
    lenderName,
    lenderType,
    cryptoCurrency,
    fiatCurrency,
    amountToBlock,
    amountToFinance,
  } = collateralizeLoanDetails ?? {};

  if (isLoadingCollateralizeLoanDetails) {
    return <Loader />;
  }

  const onClickCollateralizeHandler = () => {
    if (!loanUuid) return;

    mutate(loanUuid, {
      onSuccess: () =>
        navigate('success', { state: { logo: lenderLogo, lenderType } }),
      onError: () =>
        navigate('error', { state: { logo: lenderLogo, lenderType } }),
    });
  };

  const translation = getLenderTypeTranslation(lenderType as LenderType);

  return (
    <LoanAuthorizationCard logo={lenderLogo}>
      <Text size="lg" variant="bold" color="gray.900" mb="2">
        {t(`${translation}.blockAuthorization.heading`, { beneficiaryName })}
      </Text>
      <Text size="md" color="gray.700">
        {t(`${translation}.blockAuthorization.subheading`, {
          lenderName,
        })}
      </Text>
      <Divider borderColor="gray.200" mt="2" mb="4" />
      <Text size="sm" color="gray.700">
        {t(`${translation}.blockAuthorization.amountToBorrow.label`)}
      </Text>
      <Text size="xl" color="gray.900" variant="bold" my="1">
        {t(`${translation}.blockAuthorization.amountToBorrow.value`, {
          currency: fiatCurrency,
          value: amountToFinance,
        })}
      </Text>
      <Text size="sm" color="gray.700">
        {t(`${translation}.blockAuthorization.amountToBorrow.supportText`)}
      </Text>
      <Divider borderColor="gray.200" my="4" />
      <Text size="sm" color="gray.700">
        {t(`${translation}.blockAuthorization.total.label`)}
      </Text>
      <Flex columnGap="2" alignItems="center">
        <TokenIcon token={cryptoCurrency as TCurrencyName} />
        <Text size="xl" color="gray.900" variant="bold">
          {t(`${translation}.blockAuthorization.total.value`, {
            token: cryptoCurrency,
            value: amountToBlock,
          })}
        </Text>
      </Flex>
      <Button
        variant="primary"
        size="lg"
        w="100%"
        mt="8"
        isLoading={isLoading || isLoadingCollateralizeLoanDetails}
        onClick={onClickCollateralizeHandler}
      >
        {t(`${translation}.blockAuthorization.buttons.authorize`)}
      </Button>
    </LoanAuthorizationCard>
  );
};

export default LoanAuthorizationLayout;
