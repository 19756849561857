import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { AGROTOKEN_VISA_ANIMATION_URL } from '@common/const';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import VisaLogo from '@src/components/svgs/visaLogo.svg';
import RequestCard from '@src/features/cards/cardRequestProcess/components/RequestCard';
import { useCards } from '@src/context/cardsContext';

const FirstStep = () => {
  const { t } = useTranslation();
  const { showCardShipmentForm, setShowCardShipmentForm } = useCards();

  return (
    <>
      <Box
        display={{ base: 'none', sm: 'flex' }}
        flexFlow="column"
        height="100%"
        justifyContent="center"
        flex="1 1 auto"
        paddingBottom="-2rem"
      >
        <Box display={{ base: 'block', lg: 'flex' }} flex="1 1 auto">
          <>
            <Box
              paddingX="80px"
              paddingY="32px"
              width={{ base: '100%', lg: '60%' }}
              display={{ base: 'block', lg: 'flex' }}
              flexDirection="column"
              height="100%"
              flex="1 1 auto"
            >
              <Flex>
                <Image src={VisaLogo} marginRight={6} />
              </Flex>
              <Text
                marginTop={8}
                fontFamily="Larsseit"
                fontStyle="normal"
                fontWeight="medium"
                fontSize="4xl"
                lineHeight="44px"
                letterSpacing="-0.02em"
                color="gray.900"
              >
                {t('card.title')}
              </Text>
              <Text
                marginTop={2}
                fontFamily="Larsseit"
                fontStyle="normal"
                fontWeight="400"
                fontSize="18px"
                lineHeight="28px"
                color="gray.600"
              >
                {t('card.subTitle')}
              </Text>

              <RequestCard setShowCardShipmentForm={setShowCardShipmentForm} />
            </Box>
            <Box
              width={{ base: '100%', lg: '40%' }}
              marginBottom={0}
              background="#f8f7f5"
            >
              <video
                autoPlay
                muted
                loop
                className="overflow-y-hidden object-cover w-full "
              >
                <source src={AGROTOKEN_VISA_ANIMATION_URL} type="video/mp4" />
              </video>
              <Flex paddingX="64px" paddingY="56px" gap={6}>
                <Box>
                  <FeatherIcon icon="zap" color="primary.600" size="16px" />
                  <Text
                    marginTop={4}
                    marginBottom={2}
                    fontFamily="Larsseit"
                    fontStyle="normal"
                    fontWeight="medium"
                    fontSize="16px"
                    lineHeight="24px"
                    color="gray.900"
                  >
                    {t('card.descriptionVideo.itIsEasy.title')}
                  </Text>
                  <Text
                    fontFamily="Larsseit"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="16px"
                    lineHeight="24px"
                    color="gray.700"
                  >
                    {t('card.descriptionVideo.itIsEasy.description')}
                  </Text>
                </Box>
                <Box>
                  <FeatherIcon icon="globe" color="primary.600" size="16px" />
                  <Text
                    marginTop={4}
                    marginBottom={2}
                    fontFamily="Larsseit"
                    fontStyle="normal"
                    fontWeight="medium"
                    fontSize="16px"
                    lineHeight="24px"
                    color="gray.900"
                  >
                    {t('card.descriptionVideo.whenAndWhere.title')}
                  </Text>
                  <Text
                    fontFamily="Larsseit"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="16px"
                    lineHeight="24px"
                    color="gray.700"
                  >
                    {t('card.descriptionVideo.whenAndWhere.description')}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
};

export default FirstStep;
