import { LoansTranslationType } from '../../loans';

const ENDORSEMENTS_TRANSLATIONS_ES: LoansTranslationType = {
  blockAuthorization: {
    heading: 'Autorización de Aval para {{beneficiaryName}}',
    subheading:
      'Necesitamos tu autorización para poder utilizar tus tokens como colateral en tu aval {{lenderName}}',
    cancelled: 'Este aval fue cancelado, por favor comunicate con el banco.',
    amountToBorrow: {
      label: 'Monto del aval:',
      value: '{{currency}} {{value, fiat}}',
      supportText:
        'Este monto es el capital que será desembolsado en tu cuenta bancaria',
    },
    total: {
      label: 'Tokens totales a bloquear en tu billetera como colateral:',
      value: '{{value, crypto}} {{token}}',
    },
    buttons: {
      authorize: 'Autorizar',
    },
    error: {
      heading: 'No se han bloqueado los tokens de tu billetera.',
      subheading:
        'Hubo un error y no pudimos bloquear los tokens para autorizar tu aval. Contactate con ',
    },
    success: {
      heading: 'Se han bloqueado los tokens de tu billetera con éxito.',
      subheading:
        'Recibirás un mail y una notificación del banco cuando el aval sea depositado',
    },
  },
  paymentAuthorization: {
    heading: 'Solicitaste el pago del aval con tokens',
    subheading:
      'Necesitamos tu autorización para saldar tu deuda con los tokens utilizados como colateral',
    tokenAmount: {
      label: 'Tokens totales a utilizar para cubrir el aval',
      value: '{{tokenAmount, crypto}} {{token}}',
      supportText:
        'Este monto incluye capital, intereses acumulados a la fecha, otros gastos y la comisión agrotoken',
    },
    button: 'Autorizar',
    success: {
      heading:
        'Se ha saldado tu deuda con los tokens utilizados como colateral.',
      subheading:
        'Recibirás un mail y una notificación del banco cuando el aval quede saldado.',
    },
    error: {
      heading:
        'No se ha podido saldar tu deuda con los tokens utilizados como colateral.',
      subheading:
        'Hubo un error y no pudimos utilizar tus tokens para saldar tu deuda. Contactate con Agrotoken a través de ',
    },
  },
};

export default ENDORSEMENTS_TRANSLATIONS_ES;
