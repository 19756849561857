import { Stack } from '@chakra-ui/react';
import { IDocumentPreviewForBusinessUser } from '@src/common/types';
import { Dispatch, SetStateAction, VFC } from 'react';
import DocOfRepresentative from '../DocOfRepresentative/DocOfRepresentative';
import { IncompleteFieldError } from '../types';

interface Props {
  statuteDocumentId: number | null;
  setStatuteDocumentId: Dispatch<SetStateAction<number | null>>;
  authoritiesDocumentId: number | null;
  setAuthoritiesDocumentId: Dispatch<SetStateAction<number | null>>;
  legalPowerDocumentIds: number[];
  setLegalPowerDocumentIds: any;
  missingFileError: IncompleteFieldError['errors'];
  defaultBusinessUserDocuments: IDocumentPreviewForBusinessUser[];
  selectedBusinessAddressId?: number;
}

const RepresentativeDocsFields: VFC<Props> = ({
  statuteDocumentId,
  setStatuteDocumentId,
  authoritiesDocumentId,
  setAuthoritiesDocumentId,
  legalPowerDocumentIds,
  setLegalPowerDocumentIds,
  missingFileError,
  defaultBusinessUserDocuments,
}) => {
  return (
    <Stack spacing="12" mt="4">
      <DocOfRepresentative
        statuteDocumentId={statuteDocumentId}
        setStatuteDocumentId={setStatuteDocumentId}
        authoritiesDocumentId={authoritiesDocumentId}
        setAuthoritiesDocumentId={setAuthoritiesDocumentId}
        legalPowerDocumentIds={legalPowerDocumentIds}
        setLegalPowerDocumentIds={setLegalPowerDocumentIds}
        missingFileError={missingFileError}
        defaultBusinessUserDocuments={defaultBusinessUserDocuments}
      />
    </Stack>
  );
};

export default RepresentativeDocsFields;
