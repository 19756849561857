import { ENationalities } from '@agrotoken/common-utils';
import { getLocalToken } from '@common/utils';
import { TCryptoCurrencyName } from '@src/common/types';
import { useAuth } from '@src/context/authContext';
import React, { FC, useState } from 'react';
import { TokenIcon } from '@comp/Global/TokenIcon';

interface SelectTokensProps {
  token: TCryptoCurrencyName | undefined;
  setToken: React.Dispatch<
    React.SetStateAction<TCryptoCurrencyName | undefined>
  >;
  isDisabled?: boolean;
  className?: string;
  isInBlockchain?: any;
  selectedTerm?: any;
  isInBlockchainBnpl?: any;
  setSelectedTerm?: any;
  buyNowFixLaterOptionPayment?: any;
}

export const SelectTokens: FC<SelectTokensProps> = ({
  className,
  token,
  isDisabled = false,
  setToken,
}) => {
  const { nationality } = useAuth();
  const isBrlNationality = nationality === ENationalities.BR;

  return (
    <div className={`flex flex-wrap border border-grey-300 ${className}`}>
      <button
        type="button"
        disabled={isDisabled}
        onClick={() =>
          setToken(getLocalToken(nationality).tokenSoy as TCryptoCurrencyName)
        }
        className={`flex items-center justify-center border-r border-r-grey-300 ${
          isBrlNationality ? 'w-1/2' : 'w-1/3'
        } ${
          token === getLocalToken(nationality).tokenSoy
            ? 'bg-gray-50 border border-primary-600'
            : 'bg-white'
        }`}
      >
        <TokenIcon
          size="md"
          token={getLocalToken(nationality).tokenSoy as TCryptoCurrencyName}
        />
        <span className="text-sm font-medium text-gray-700 ml-2">
          {getLocalToken(nationality).tokenSoy}
        </span>
      </button>
      <div
        className={`${
          isBrlNationality ? 'w-1/2' : 'w-1/3'
        } border-r border-r-grey-300 ${
          token === getLocalToken(nationality).tokenCor
            ? 'bg-gray-50 border border-primary-600'
            : 'bg-white'
        }`}
      >
        <button
          type="button"
          disabled={isDisabled}
          onClick={() =>
            setToken(getLocalToken(nationality).tokenCor as TCryptoCurrencyName)
          }
          className="py-3 w-full text-sm font-medium flex items-center justify-center"
        >
          <TokenIcon
            size="sm"
            token={getLocalToken(nationality).tokenCor as TCryptoCurrencyName}
          />
          <span className="ml-2 text-sm fonst-medium text-gray-700">
            {getLocalToken(nationality).tokenCor}
          </span>
        </button>
      </div>
      {!isBrlNationality && (
        <div
          className={`w-1/3 ${
            token === getLocalToken(nationality).tokenWhe
              ? 'bg-gray-50 border border-primary-600'
              : 'bg-white'
          }`}
        >
          <button
            type="button"
            disabled={isDisabled}
            onClick={() =>
              setToken(
                getLocalToken(nationality).tokenWhe as TCryptoCurrencyName
              )
            }
            className="py-3 w-full text-sm font-medium flex items-center justify-center"
          >
            <TokenIcon
              size="sm"
              token={getLocalToken(nationality).tokenWhe as TCryptoCurrencyName}
            />
            <span className="ml-2 text-sm fonst-medium text-gray-700">
              {getLocalToken(nationality).tokenWhe}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
