import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { EPEPValues } from '@src/common/types';
import { VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PEP_RADIO } from './KYC/consts';
import { Register } from './types';

interface Props {
  name?: string;
  register?: Register;
  title: string;
  defaultPep?: number;
  disabled?: boolean;
}
/**
 *  Q: Whats PEP?
 *  A: Politically Exposed Person
 */
const PEPRadio: VFC<Props> = ({
  name = PEP_RADIO,
  register: registerFromProps,
  disabled = false,
}) => {
  const { watch, register: registerFromContext } = useFormContext();
  const register = registerFromProps || registerFromContext;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'fullOnboarding.lvl_2.form.pep',
  });

  const value = watch(name) ? Number(watch(name)) : EPEPValues.IS_NOT_PEP;

  return (
    <RadioGroup
      mt="6"
      value={value}
      defaultChecked
      key={value}
      isDisabled={disabled}
    >
      <Stack>
        <Text fontSize="sm" fontWeight="medium">
          {t('label')}
        </Text>
        <Radio
          size="md"
          value={EPEPValues.IS_PEP}
          colorScheme="primary"
          {...register(name, { valueAsNumber: true })}
        >
          {t('yes')}
        </Radio>
        <Radio
          size="md"
          value={EPEPValues.IS_NOT_PEP}
          colorScheme="primary"
          {...register(name, { valueAsNumber: true })}
        >
          {t('no')}
        </Radio>
      </Stack>
    </RadioGroup>
  );
};

export default PEPRadio;
