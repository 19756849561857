import {
  EBusinessStructureTypes,
  IBusiness,
  IDocument,
  IUser,
} from '@common/types';
import {
  FieldValues,
  FormProvider,
  UseFormHandleSubmit,
  UseFormReturn,
} from 'react-hook-form';
import Form from '@comp/FullOnboarding/common/layouts/Form';
import ProgressIndicator from '../common/ProgressIndicator';
import BusinessForm from './business';
import PersonalForm from './person';

type OnboardingLevel2FormProps = {
  formMethods: UseFormReturn<FieldValues, object>;
  onCompleteLater: () => void;
  handleFormSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: (data: FieldValues) => void;
  loading: boolean;
  userWasInvited: boolean;
  businessStructureType?: number;
  defaultValues: {
    frontPersonDocument?: IDocument;
    backPersonDocument?: IDocument;
    legalPowerDocuments?: IDocument[];
    socialContractDocuments?: IDocument[];
  };
  isSaving: boolean;
  changeRequestMessages?: string[];
  selectedBusiness?: IBusiness;
  currentUser?: IUser;
};

const OnboardingLevel2Form = ({
  formMethods,
  onCompleteLater,
  handleFormSubmit,
  onSubmit,
  loading,
  userWasInvited,
  businessStructureType,
  defaultValues,
  isSaving,
  changeRequestMessages,
  selectedBusiness,
  currentUser,
}: OnboardingLevel2FormProps) => {
  return (
    <FormProvider {...formMethods}>
      <Form
        handleSubmit={handleFormSubmit(onSubmit, (e) => {
          console.error(e);
        })}
        isLoading={loading || isSaving}
        isDisabled={isSaving}
        secondaryAction={onCompleteLater}
        rightSideContent={
          !userWasInvited ? <ProgressIndicator level={2} /> : null
        }
        currentStep={2}
      >
        {businessStructureType === EBusinessStructureTypes.COMPANY && (
          <BusinessForm
            defaultValues={defaultValues}
            changeRequestMessages={changeRequestMessages}
            selectedBusiness={selectedBusiness}
            currentUser={currentUser}
          />
        )}
        {businessStructureType === EBusinessStructureTypes.PERSON && (
          <PersonalForm
            defaultValues={defaultValues}
            changeRequestMessages={changeRequestMessages}
            selectedBusiness={selectedBusiness}
          />
        )}
      </Form>
    </FormProvider>
  );
};

export default OnboardingLevel2Form;
