import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';

import ilustration from '../../assets/images/payment-illustration.svg';
import { Container } from '../Global/Container';

interface EdgeCaseViewProps {
  heading: string;
  text: string;
  buttonLabel: string;
  buttonOnClick: () => void;
}

/**
 * EdgeCaseView component displays an edge case view with a heading, text, and a button.
 * Edge cases could be unauthorized, not found cases for example
 *
 * @param {string} heading The heading text for the edge case view.
 * @param {string} text The main text for the edge case view.
 * @param {string} buttonLabel The label for the button in the edge case view.
 * @param {() => void} buttonOnClick The click handler function for the button in the edge case view.
 * @returns {JSX.Element} The JSX element representing the EdgeCaseView.
 */
const EdgeCaseView: React.FC<EdgeCaseViewProps> = ({
  heading,
  text,
  buttonLabel,
  buttonOnClick,
}: EdgeCaseViewProps) => {
  return (
    <Container className="my-auto">
      <Flex
        as="main"
        columnGap="16"
        alignItems="center"
        justifyContent="center"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Box maxWidth="540px">
          <Heading size="lg" variant="medium" color="gray.900" mb="6">
            {heading}
          </Heading>
          <Text variant="regular" size="xl" color="gray.500" mb="12">
            {text}
          </Text>
          <Button variant="primary" size="lg" onClick={buttonOnClick}>
            {buttonLabel}
          </Button>
        </Box>
        <Image
          src={ilustration}
          alt="edge-case"
          display={{ base: 'none', md: 'inline-block' }}
        />
      </Flex>
    </Container>
  );
};

export default EdgeCaseView;
