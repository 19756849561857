import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import { IMovement } from '../common/types';
import { http } from '../http-common';

export const getMovements = (): Promise<IMovement[]> => {
  return http.get<IMovement[]>(`/${PLATFORM_PREFIX}/movements`).then((resp) => {
    const movements: IMovement[] = resp.data;
    return movements;
  });
};

export const getMovementsForWallet = (): Promise<IMovement[]> => {
  return http
    .get<IMovement[]>(`/${PLATFORM_PREFIX}/movements/wallet`)
    .then((resp) => {
      const movements: IMovement[] = resp.data;
      return movements;
    });
};

export const getMovementsByGrainContract = (
  tokenizationUuid: string
): Promise<IMovement[]> => {
  return http
    .get<IMovement[]>(
      `/${PLATFORM_PREFIX}/movements/wallet?tokenizationUuid=${tokenizationUuid}`
    )
    .then((resp) => {
      const grainContractMovements: IMovement[] = resp.data;

      return grainContractMovements;
    });
};

export const getHistoricMovements = (businessId: number): Promise<any> => {
  return http
    .get<any>(`/${PLATFORM_PREFIX}/movements/${businessId}/historic`, {
      responseType: 'arraybuffer',
    })
    .then((resp) => {
      const dataResult: any = resp.data;
      return dataResult;
    });
};
