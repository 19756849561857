import {
  FeaturedIcons,
  SeedIcon,
  WheatIcon,
  Accordion,
  Badge,
  SoyIcon,
  SpeedometerIcon,
  CornIcon,
  CoinsStackIcon,
  Breadcrumb,
} from '@agrotoken-tech/ui';
import {
  Text,
  TabList,
  Tabs,
  Tab,
  Flex,
  Box,
  Button,
  Icon,
  Grid,
  Heading,
} from '@chakra-ui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import {
  FiExternalLink,
  FiHexagon,
  FiLogIn,
  FiMapPin,
  FiUser,
  FiCalendar,
} from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  AGREEMENT_TYPE_LABEL,
  BREAKPOINTS,
  TRANSFER_PATH,
  WARRANTY_PATH,
} from '@common/const';
import {
  EDocumentTypes,
  EDocusignType,
  ETokensBR,
  EUnits,
  ExtendedWarranty,
  Origin,
  WarrantyStatus,
} from '@common/types';
import { formatNumber } from '@common/utils';
import { formatDate } from '@common/utils/format-date';
import { Container } from '@comp/Global/Container';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { downloadDocument } from '@services/Documents';
import { viewDocusign } from '@services/Docusign';
import { getWarranty } from '@services/GrainContracts';
import BlockedSacasModal from './BlockedSacasModal';
import Scoring from './scoring';

const Warranty = ({
  paymentRequestSelectWarrantyId,
  farmerFiscalId,
}: {
  paymentRequestSelectWarrantyId?: number;
  farmerFiscalId?: string;
}) => {
  const { id, origin } = useParams();
  const { t } = useTranslation();
  const [isLoadingDocument, setIsLoadingDocument] = useState('');
  const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);
  const { warrantyTransfersBrazil } = useFlags();
  const navigate = useNavigate();

  const handleOpenInfoModal = () => {
    setIsOpenInfoModal(true);
  };
  const {
    isLoading: isLoadingWarranty,
    error: warrantyError,
    data: warranty,
  } = useQuery<ExtendedWarranty | undefined>(
    [
      'warranty',
      paymentRequestSelectWarrantyId ?? id,
      origin ?? Origin.TOKENIZATION,
      farmerFiscalId,
    ],
    getWarranty
  );
  if (isLoadingWarranty)
    return (
      <Flex justifyContent={'center'}>
        <Loader size="lg" />
      </Flex>
    );

  if (warrantyError) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={(warrantyError as any)?.message}
      />
    );
  }

  if (!warranty) {
    return (
      <ServerError
        title={t('serverError.title')}
        text={t('serverError.text')}
      />
    );
  }
  const isMobile = window.innerWidth < BREAKPOINTS.md;

  const mapOriginToType = {
    [Origin.TOKENIZATION]: t('warrantyDetail.tokenizedWarranty'),
    [Origin.PAYMENT]: t('warrantyDetail.paymentWarranty'),
  };

  const mapCurrencyToIcon = {
    [ETokensBR.CORB]: CornIcon,
    [ETokensBR.SOYB]: SoyIcon,
  };
  const docusignOfferLetter = warranty?.docusigns?.find(
    (docusign) => docusign.docusignTypeId === EDocusignType.OFFER_LETTER
  );
  const hasOfferLetterDocusign = !!docusignOfferLetter;

  const getDocument = async (uuid: string) => {
    const document = await downloadDocument(uuid);
    return document;
  };

  const offerLetterDocUuid = warranty?.documents?.find(
    (item) => item.documentTypeId === EDocumentTypes.CREDIT_ASSIGNMENT
  )?.uuid;

  const handleViewOfferLetter = async () => {
    if (hasOfferLetterDocusign) {
      const response = await viewDocusign(
        { sign: false, returnUrl: window.location.href },
        docusignOfferLetter?.id
      );
      window.open(response.url);
    }

    if (offerLetterDocUuid) {
      const url = await getDocument(offerLetterDocUuid);
      window.open(url);
    }
  };
  const generalData = [
    {
      title: t('warrantyDetail.oracle'),
      data: warranty.oracle.name,
      icon: FiUser,
    },
    {
      title: t('warrantyDetail.plantingDate'),
      data: `${formatDate(warranty.cpr.plantingDate, 'DD/MM/YYYY')}`,
      icon: WheatIcon as IconType,
    },
    {
      title: t('warrantyDetail.movementType'),
      data: mapOriginToType[origin as Origin],
      icon: FiLogIn,
      hide: !!paymentRequestSelectWarrantyId,
    },
    {
      title: t('warrantyDetail.agreementDocPeriod'),
      data: `${formatDate(warranty.agreementDoc.startDate, 'DD/MM/YYYY')} -
        ${formatDate(warranty.agreementDoc.endDate, 'DD/MM/YYYY')}`,
      icon: SeedIcon as IconType,
    },
    {
      title: t('warrantyDetail.plantedArea'),
      data: formatNumber(warranty.cpr.plantedArea, EUnits.PARAM),
      icon: FiHexagon,
    },
  ];
  const farmData = [
    {
      title: t('warrantyDetail.farmOwnerType'),
      data: warranty.farm?.farmOwnerType.name,
      icon: FiUser,
    },
    {
      title: t('warrantyDetail.farmCapacity'),
      data: formatNumber(warranty.farm?.capacity, EUnits.PARAM),
      icon: FiHexagon,
    },
    {
      title: t('warrantyDetail.farmLocation'),
      data: `${warranty.farm?.city?.name}, ${warranty.farm?.city?.geographicalDivision.name}`,
      icon: FiMapPin,
    },
  ];
  const tokenizationDocuments = [
    {
      uuid: warranty.cpr.document.uuid,
      title: isMobile
        ? t('warrantyDetail.documents.cprAbreviation')
        : t('warrantyDetail.documents.cpr'),
      data: [
        {
          label: t('warrantyDetail.documents.documentNumber'),
          value: warranty.cpr.internalNumber,
        },
        {
          label: t('warrantyDetail.documents.documentTons'),
          value: `${formatNumber(warranty.cpr.grainVolume, EUnits.TONS)} (t)`,
        },
      ],
      isExpandable: true,
    },
    {
      uuid: warranty.agreementDoc.document.uuid,
      title: t('warrantyDetail.documents.agreementDoc'),
      data: [
        {
          label: t('warrantyDetail.documents.documentNumber'),
          value: warranty.agreementDoc.internalNumber,
        },
        {
          label: t('warrantyDetail.documents.documentTons'),
          value: formatNumber(warranty.agreementDoc.tons, EUnits.TONS),
        },
        {
          label: t('warrantyDetail.documents.contractType'),
          value: t(
            `tokenization.proofOfLiquidity.form.agreementType.${
              AGREEMENT_TYPE_LABEL[warranty.agreementDoc.agreementTypeId]
            }`
          ),
        },
        {
          label: t('warrantyDetail.documents.paymentDate'),
          value: formatDate(
            warranty.agreementDoc.expirationDate!,
            'DD/MM/YYYY'
          ),
        },
      ],
      isExpandable: true,
    },
    {
      uuid:
        origin === Origin.TOKENIZATION
          ? EDocumentTypes.CREDIT_ASSIGNMENT.toString()
          : null,
      title: t('warrantyDetail.documents.offerLetter'),
      onClickViewDoc: handleViewOfferLetter,
      isExpandable: false,
    },
  ];
  const stateRegistration = warranty.farm?.documents?.find(
    (doc) => doc.documentTypeId === EDocumentTypes.STATE_REGISTRATION
  );
  const car = warranty.farm?.documents?.find(
    (doc) => doc.documentTypeId === EDocumentTypes.CAR
  );
  const esg = warranty?.documents
    .sort((a, b) => b.id - a.id)
    .find((document) => document.documentTypeId === EDocumentTypes.ESG);

  const proofOfHealthDocuments = warranty.documents.filter(
    (doc) => doc.documentTypeId === EDocumentTypes.PROOF_OF_HEALTH
  );
  const farmDocuments = [
    {
      uuid: stateRegistration?.uuid,
      title: t('warrantyDetail.documents.stateRegistration'),
      data: [
        {
          label: t('warrantyDetail.documents.documentNumber'),
          value: warranty.farm?.internalNumberState,
        },
      ],
      isExpandable: true,
    },
    {
      uuid: car?.uuid,
      title: isMobile
        ? t('warrantyDetail.documents.carAbreviation')
        : t('warrantyDetail.documents.car'),
      data: [
        {
          label: t('warrantyDetail.documents.documentNumber'),
          value: warranty.farm?.internalNumberRural,
        },
        {
          label: t('warrantyDetail.documents.registrationNumber'),
          value: warranty.farm?.registration,
        },
      ],
      isExpandable: true,
    },
    {
      uuid: esg?.uuid,
      title: t('warrantyDetail.documents.esg'),
      isExpandable: false,
    },
  ];
  const documents = [
    {
      title: t('warrantyDetail.documents.tokenizationDocuments'),
      docs: tokenizationDocuments,
    },
    {
      title: t('warrantyDetail.documents.farmDocuments'),
      docs: farmDocuments,
    },
    {
      title: t('warrantyDetail.documents.attachedDocuments'),
      subtitle: t('warrantyDetail.documents.attachedDocumentsSubtitle'),
      docs: proofOfHealthDocuments.map((doc) => {
        return {
          uuid: doc.uuid,
          title: doc.customName ?? doc.originalName,
          isExpandable: false,
          data: null,
        };
      }),
    },
  ];

  const featuredCards = [
    {
      icon: CoinsStackIcon,
      title: t('warrantyDetail.featuredCards.price'),
      value: `R$ ${formatNumber(
        warranty.equivalences?.amount['BRL'],
        EUnits.BRL
      )}`,
      info: (
        <>
          <Text textColor={'gray.500'} fontSize="md">
            {t('warrantyDetail.featuredCards.fixCondition')}
          </Text>
          <Text
            textColor={'gray.600'}
            fontSize="md"
            ml="2"
            fontFamily={'Larsseit-Medium'}
          >
            {t(
              `tokenization.proofOfLiquidity.form.agreementType.${
                AGREEMENT_TYPE_LABEL[warranty.agreementDoc.agreementTypeId]
              }`
            )}
          </Text>
        </>
      ),
    },
    {
      icon: FiCalendar,
      title: t('warrantyDetail.featuredCards.expirationDate'),
      value: formatDate(warranty.expirationDate, 'DD/MM/YYYY'),
      info: !paymentRequestSelectWarrantyId && (
        <>
          <Text textColor={'gray.500'} fontSize="md" mr="2">
            {t('warrantyDetail.featuredCards.status')}:
          </Text>
          <Badge
            colorScheme={
              origin === Origin.TOKENIZATION ||
              warranty.status === WarrantyStatus.COMPLETED
                ? 'success'
                : 'gray'
            }
          >
            {origin === Origin.TOKENIZATION
              ? t('warrantyDetail.status.TOKENIZED')
              : t(`warrantyDetail.status.${warranty.status}`)}
          </Badge>
        </>
      ),
    },
    {
      icon: mapCurrencyToIcon[
        warranty.currency.name as ETokensBR.CORB | ETokensBR.SOYB
      ],
      title: t('warrantyDetail.featuredCards.avaiableSacas'),
      value: Number(warranty.healthControl) ? (
        !paymentRequestSelectWarrantyId && Origin.TOKENIZATION === origin ? (
          `${formatNumber(
            warranty.conversions.amount[EUnits.SACAS],
            EUnits.SACAS
          )} / ${formatNumber(
            warranty.conversions.initialAmount[EUnits.SACAS],
            EUnits.SACAS
          )}`
        ) : (
          formatNumber(warranty.conversions.amount[EUnits.SACAS], EUnits.SACAS)
        )
      ) : (
        <>{t('warrantyDetail.featuredCards.blocked')}</>
      ),
      info: origin === Origin.TOKENIZATION && (
        <>
          <Text textColor={'gray.500'} fontSize="md">
            {t('warrantyDetail.featuredCards.blockedSacas')}
          </Text>
          <Text
            textColor={'gray.600'}
            fontSize="md"
            ml="2"
            fontFamily={'Larsseit-Medium'}
          >
            {formatNumber(
              warranty.conversions.frozenAmount['SACAS'],
              EUnits.SACAS
            )}
          </Text>
        </>
      ),
      id: 'avaiableSacas',
    },
    {
      icon: SpeedometerIcon,
      title: t('warrantyDetail.featuredCards.scoring'),
      value: `${
        Number(warranty.healthControl) === 1
          ? t('warrantyDetail.scoring.excellent')
          : t('warrantyDetail.scoring.ruim')
      }  (${warranty.healthControl && Number(warranty.healthControl) * 100})`,
      info: <Scoring healthControl={Number(warranty.healthControl)} />,
    },
  ];

  return (
    <Container>
      <BlockedSacasModal
        setIsOpenInfoModal={setIsOpenInfoModal}
        isOpenInfoModal={isOpenInfoModal}
      />
      <Flex justifyContent={'center'}>
        <Flex maxWidth={'840px'} flexDir={'column'} width="100%">
          <Breadcrumb
            onNavigate={(link: string) => navigate(link)}
            options={[
              {
                label: 'home',
                to: '../home',
              },
              {
                label: `${t(`warrantyDetail.title.${warranty.currency.name}`)} #
                ${warranty.requestNumber}`,
                to: '',
              },
            ]}
          ></Breadcrumb>
          <Flex
            align="center"
            justifyContent={'space-between'}
            flexWrap="wrap"
            mt={8}
            gap="2"
          >
            <Heading size={'md'} variant="medium" textColor={'gray.900'}>
              {t(`warrantyDetail.title.${warranty.currency.name}`)} #
              {warranty.requestNumber}
            </Heading>
            {warrantyTransfersBrazil && !paymentRequestSelectWarrantyId && (
              <Button
                gap={3}
                onClick={() =>
                  navigate(
                    `${TRANSFER_PATH}${WARRANTY_PATH}/${warranty?.requestNumber}/add`
                  )
                }
              >
                {t('transfers.transferWarrantyButton')}
              </Button>
            )}
          </Flex>
          {!paymentRequestSelectWarrantyId && (
            <Tabs marginTop={8}>
              <TabList>
                <Tab
                  _focus={{
                    outline: 'none',
                  }}
                  _selected={{
                    color: 'primary.700',
                    bg: 'white',
                    borderColor: 'primary.700',
                  }}
                >
                  {t('warrantyDetail.tabs.data')}
                </Tab>
                <Tab
                  _focus={{
                    outline: 'none',
                  }}
                  _selected={{
                    color: 'primary.700',
                    bg: 'white',
                    borderColor: 'primary.700',
                  }}
                  isDisabled={true}
                >
                  {t('warrantyDetail.tabs.movements')}
                </Tab>
              </TabList>
            </Tabs>
          )}
          <Text mt="8" mb="6" fontSize={'xl'} fontFamily="Larsseit-Medium">
            {t('warrantyDetail.sections.featured')}
          </Text>
          <Grid
            gridTemplateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
            ]}
            gap="8"
          >
            {featuredCards.map((card) => (
              <Flex
                borderRadius={'4px'}
                shadow="xs"
                borderColor={'gray.200'}
                flexDir="column"
                padding={4}
                key={card.title}
              >
                <Flex justifyContent={'space-between'}>
                  <Icon as={card.icon} color="gray.700" boxSize={'24px'} />
                  {card.id === 'avaiableSacas' && (
                    <Button
                      variant={'link'}
                      onClick={handleOpenInfoModal}
                      ml="2"
                    >
                      {t('warrantyDetail.featuredCards.viewMore')}
                    </Button>
                  )}
                </Flex>

                <Text textColor={'gray.500'} fontSize="lg" mt="6">
                  {card.title}
                </Text>
                <Text
                  textColor={'gray.900'}
                  fontSize="2xl"
                  fontFamily={'Larsseit-Medium'}
                  mt="1"
                >
                  {card.value}
                </Text>
                <Flex mt="4">{card.info}</Flex>
              </Flex>
            ))}
          </Grid>
          <Text mt="16" mb="6" fontSize={'xl'} fontFamily="Larsseit-Medium">
            {t('warrantyDetail.sections.generalData')}
          </Text>
          <Grid
            gridTemplateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
            ]}
            gap="8"
          >
            {generalData.map(
              (item) =>
                !item.hide && (
                  <Flex key={item.title}>
                    <FeaturedIcons
                      size="lg"
                      shape="square"
                      variant="primary"
                      icon={item.icon}
                    />
                    <Box ml="3">
                      <Text
                        textColor={'gray.900'}
                        fontSize="md"
                        fontFamily={'Larsseit-Medium'}
                      >
                        {item.title}
                      </Text>
                      <Text textColor={'gray.500'} fontSize="md">
                        {item.data}
                      </Text>
                    </Box>
                  </Flex>
                )
            )}
          </Grid>
          <Text mt="16" mb="6" fontSize={'xl'} fontFamily="Larsseit-Medium">
            {t('warrantyDetail.sections.farmData')}
          </Text>
          <Grid
            gridTemplateColumns={[
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
            ]}
            gap="8"
          >
            {farmData.map((item) => (
              <Flex key={item.title}>
                <FeaturedIcons
                  size="lg"
                  shape="square"
                  variant="primary"
                  icon={item.icon}
                />
                <Box ml="3">
                  <Text
                    textColor={'gray.900'}
                    fontSize="md"
                    fontFamily={'Larsseit-Medium'}
                  >
                    {item.title}
                  </Text>
                  <Text textColor={'gray.500'} fontSize="md">
                    {item.data}
                  </Text>
                </Box>
              </Flex>
            ))}
          </Grid>
          {documents.map(
            (item) =>
              item.docs.length > 0 && (
                <>
                  <Flex flexDir={'column'} mt="16" mb="6" gap="0.5">
                    <Text fontSize={'xl'} fontFamily="Larsseit-Medium">
                      {item.title}
                    </Text>
                    {item.subtitle && (
                      <Text fontSize={'md'} textColor="gray.500">
                        {item.subtitle}
                      </Text>
                    )}
                  </Flex>
                  <Flex flexDirection={'column'} gap="3">
                    {item.docs.map(
                      (doc) =>
                        doc.uuid && (
                          <Accordion
                            key={doc.title}
                            title={doc.title}
                            isExpandable={doc.isExpandable}
                            HeadButton={(props) => (
                              <Button
                                leftIcon={<FiExternalLink />}
                                aria-label=""
                                {...props}
                                alignItems="end"
                                onClick={async () => {
                                  setIsLoadingDocument(doc.uuid!.toString());
                                  try {
                                    if (
                                      doc.uuid ===
                                      EDocumentTypes.CREDIT_ASSIGNMENT.toString()
                                    ) {
                                      await handleViewOfferLetter();
                                    } else {
                                      const url = await getDocument(
                                        doc.uuid!.toString()
                                      );
                                      window.open(url);
                                    }
                                    setIsLoadingDocument('');
                                  } catch (error) {
                                    setIsLoadingDocument('');
                                  }
                                }}
                                isLoading={
                                  doc.uuid?.toString() === isLoadingDocument
                                }
                              >
                                {t('warrantyDetail.documents.viewDocument')}
                              </Button>
                            )}
                          >
                            <>
                              {doc.data
                                ? doc.data.map((data) => (
                                    <Flex
                                      justifyContent={'space-between'}
                                      key={data.label}
                                    >
                                      <Text
                                        textColor={'gray.700'}
                                        fontSize="md"
                                      >
                                        {data.label}
                                      </Text>
                                      <Text
                                        textColor={'gray.700'}
                                        fontSize="md"
                                      >
                                        {data.value}
                                      </Text>
                                    </Flex>
                                  ))
                                : undefined}
                            </>
                          </Accordion>
                        )
                    )}
                  </Flex>
                </>
              )
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export default Warranty;
