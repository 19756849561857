import { MovementsTabs } from '@common/enum/movements.enum';
import { useTranslation } from 'react-i18next';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { EmptyState } from '../../Global/EmptyState';
import { Button, Table, Thead, Tr } from '@chakra-ui/react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  onChangeTab?: (value: MovementsTabs) => void;
  showTabs?: boolean;
  showPagination?: boolean;
  openDetails?(id: string): void;
}

export function BaseTable<T extends object>({
  columns,
  data,
  onChangeTab,
  showTabs = true,
  showPagination = true,
  openDetails,
}: TableProps<T>): React.ReactElement {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    pageCount,
  } = useTable<T>(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const { t } = useTranslation();
  const { pageIndex } = state;

  if (data && data.length === 0)
    return (
      <EmptyState
        color="primary"
        theme="dark-circle"
        title={t('creditsPage.emptyState.title')}
        text={t('creditsPage.emptyState.subtitle')}
        ilustration="cloud"
        className="py-6"
      />
    );

  const needsPagination = data.length > 0 && pageCount > 1;
  const shouldShowPaginationUI = showPagination && needsPagination;

  return (
    <>
      {page && page.length === 0 && (
        <EmptyState
          color="primary"
          theme="light-circle"
          title={t('tokenizations.table.resultNotFound.title')}
          text={t('tokenizations.table.resultNotFound.subtitle')}
          ilustration="cloud"
          className="py-6"
        />
      )}
      {page && page.length > 0 && (
        <Table {...getTableProps()}>
          <Thead borderRadius="3px" background="red">
            {headerGroups.map((headerGroup: any, index: number) => (
              <Tr
                overflow="hidden"
                borderRadius="33px"
                key={`A${index}`}
                {...headerGroup.getHeaderGroupProps()}
                bg="gray.50"
              >
                {headerGroup.headers.map((column: any, i: number) => (
                  <th
                    key={`B${i}`}
                    {...column.getHeaderProps()}
                    className="text-gray-500 text-xs text-left px-6 py-5 font-normal"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </Tr>
            ))}
          </Thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any, index: number) => {
              prepareRow(row);
              const originalId = row.original.id;
              return (
                <tr
                  key={`C${index}`}
                  {...row.getRowProps()}
                  className="border-t border-gray-200"
                  onClick={() => openDetails && openDetails(originalId)}
                >
                  {row.cells.map((cell: any, i: number) => {
                    return (
                      <td
                        key={`D${i}`}
                        {...cell.getCellProps()}
                        className="px-6 py-4"
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {shouldShowPaginationUI ? (
        <div className="flex justify-between items-center px-5 py-4 border-t border-gray-200">
          <Button
            onClick={() => previousPage()}
            variant="secondary"
            disabled={!canPreviousPage}
            leftIcon={<FiArrowLeft />}
          >
            {t('btnPrevLabel')}
          </Button>
          <span className="mx-3">{`Página ${pageIndex + 1} de ${
            pageOptions.length
          }`}</span>
          <Button
            onClick={() => nextPage()}
            variant="secondary"
            disabled={!canNextPage}
            rightIcon={<FiArrowRight />}
          >
            {t('btnNextLabel')}
          </Button>
        </div>
      ) : null}
    </>
  );
}
