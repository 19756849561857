import { EDocumentTypes } from '@src/common/types';
import { TYPE_OF_LEGAL_POWER_DOC } from './DocOfRepresentative/consts';
import { PEP_RADIO } from './KYC/consts';
import { IncompleteFieldError } from './types';

type PersonData = {
  frontPersonDocumentId: number | null;
  backPersonDocumentId: number | null;
  pep: number;
};

type CompanyData = {
  legalPowerDocumentIds: number[];
  statuteDocumentId: number | null;
  authoritiesDocumentId: number | null;
  [TYPE_OF_LEGAL_POWER_DOC]: number;
};

/**
 * Checks if:
 * pep, frontPersonDocumentId and backPersonDocumentId has a value
 * @param data
 * @returns {errors:{}}  an object with a errors sub object in witch each key value is an incomplete field
 */
export const getIncompleteFieldsForGeneralDataLevel2 = (
  data: PersonData
): IncompleteFieldError => {
  const frontPersonDocumentIdIsLoaded = Boolean(data.frontPersonDocumentId);
  const backPersonDocumentIdIsLoaded = Boolean(data.backPersonDocumentId);
  const isPepCompleted = data.pep !== undefined;
  return {
    errors: {
      ...(!frontPersonDocumentIdIsLoaded
        ? { frontPersonDocumentId: 'Needs to upload document front' }
        : {}),
      ...(!backPersonDocumentIdIsLoaded
        ? { backPersonDocumentId: 'Needs to upload document back' }
        : {}),
      ...(!isPepCompleted
        ? { [PEP_RADIO]: 'Needs to complete if is a PEP' }
        : {}),
    },
  };
};

export const getIncompleteFieldsForRepDocsDataLevel2 = (
  data: CompanyData
): IncompleteFieldError => {
  const legalPowerDocumentIdIsLoaded = Boolean(
    data.legalPowerDocumentIds.length
  );
  const statuteDocumentIdIsLoaded = Boolean(data.statuteDocumentId);
  const authoritiesDocumentIdIsLoaded = Boolean(data.authoritiesDocumentId);

  const isLegalPower =
    Number(data[TYPE_OF_LEGAL_POWER_DOC]) === EDocumentTypes.LEGAL_POWER;

  if (isLegalPower) {
    return {
      errors: {
        ...(!legalPowerDocumentIdIsLoaded
          ? { legalPowerDocumentId: 'Needs to upload legal power document' }
          : {}),
      },
    };
  } else {
    return {
      errors: {
        ...(!statuteDocumentIdIsLoaded
          ? { statuteDocumentId: 'Needs to upload statute document' }
          : {}),
        ...(!authoritiesDocumentIdIsLoaded
          ? { authoritiesDocumentId: 'Needs to upload authorities document' }
          : {}),
      },
    };
  }
};
