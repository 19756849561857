import { FC } from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';

interface Props {
  error: string;
  fileName: string;
  onClick: () => void;
}

export const ErrorPreview: FC<Props> = ({ error, fileName, onClick }) => {
  return (
    <li className="border border-error-300 bg-error-25 p-4 mt-4 flex justify-between">
      <div className="flex">
        <Icon
          size="md"
          color="error"
          theme="light-circle-outline"
          icon="file-text"
        />
        <div className="ml-3.5">
          <p className="text-sm text-error-700 font-medium">{fileName}</p>
          <p className="text-sm text-error-600 font-normal">{error}</p>
          <Button
            label="Intentar nuevamente"
            size="sm"
            variant="link-error"
            className="px-0 py-0 mt-1"
            onClick={onClick}
          />
        </div>
      </div>
    </li>
  );
};
