import { PLATFORM_PREFIX } from '@agrotoken/common-utils';

import { GetLoanCollateralizationDetailsResponse } from '@features/loans/interfaces';
import { http } from '../../http-common';

const getLoanCollateralizationDetails = async (
  uuid: string
): Promise<GetLoanCollateralizationDetailsResponse | undefined> => {
  const { data } = await http.get<GetLoanCollateralizationDetailsResponse>(
    `/${PLATFORM_PREFIX}/loans/${uuid}/collateralization-details`
  );

  return data;
};

export default getLoanCollateralizationDetails;
