import {
  IBusiness,
  PaymentRequestStatus,
  PAYMENT_REQUEST_STATUSES,
} from '@src/common/types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Column,
  IdType,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { Button } from '@comp/Global/Button';
import { ButtonGroup } from '@comp/Global/ButtonGroup';
import { Input } from '@comp/Global/Input';
import { PAYMENT_PATH } from '../../common/const';
import { EmptyState } from '../Global/EmptyState';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
}

function PaymentsTable<T extends object>({
  columns,
  data,
}: TableProps<T>): React.ReactElement {
  const globalFilterFunction = useCallback(
    (rows: Row<T>[], _ids: IdType<T>[], query: string) => {
      return rows.filter((row) => {
        if (query === '') return row;
        const business: IBusiness | null = row.values['toBusiness'];
        const businessQuery = business ? business.name.includes(query) : null;

        return businessQuery;
      });
    },
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    pageCount,
    setFilter,
    setGlobalFilter,
  } = useTable<T>(
    {
      columns,
      data,
      globalFilter: globalFilterFunction,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pageIndex } = state;

  const [filteredBy, setFilteredBy] = useState<PaymentRequestStatus | null>(
    null
  );

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  const buttonGroupList = [
    {
      id: 1,
      label: t('paymentRequestsPage.table.buttonGroup.all'),
      onClick: () => {
        setFilter('status', []);
        setFilteredBy(null);
      },
      isActive: filteredBy === null,
    },
    {
      id: 2,
      label: t('paymentRequestsPage.table.buttonGroup.accepted'),
      onClick: () => {
        setFilter('status', [PAYMENT_REQUEST_STATUSES.COMPLETED]);
        setFilteredBy(PAYMENT_REQUEST_STATUSES.COMPLETED);
      },
      isActive: filteredBy === PAYMENT_REQUEST_STATUSES.COMPLETED,
    },
    {
      id: 3,
      label: t('paymentRequestsPage.table.buttonGroup.pending'),
      onClick: () => {
        setFilter('status', [PAYMENT_REQUEST_STATUSES.PENDING]);
        setFilteredBy(PAYMENT_REQUEST_STATUSES.PENDING);
      },
      isActive: filteredBy === PAYMENT_REQUEST_STATUSES.PENDING,
    },
    {
      id: 4,
      label: t('paymentRequestsPage.table.buttonGroup.expired'),
      onClick: () => {
        setFilter('status', [PAYMENT_REQUEST_STATUSES.EXPIRED]);
        setFilteredBy(PAYMENT_REQUEST_STATUSES.EXPIRED);
      },
      isActive: filteredBy === PAYMENT_REQUEST_STATUSES.EXPIRED,
    },
  ];

  if (data && data.length === 0)
    return (
      <EmptyState
        color="primary"
        theme="dark-circle"
        title={t('paymentRequestsPage.table.emptyState.title')}
        text={t('paymentRequestsPage.table.emptyState.title')}
        ilustration="cloud"
        className="py-6"
      />
    );

  return (
    <>
      <Box className="px-5 py-4 flex items-center gap-2 justify-between border-b border-gray-200">
        <ButtonGroup list={buttonGroupList} />
        <Input
          name="application-number"
          placeholder={t('paymentRequestsPage.table.search')}
          icon="search"
          fullWidth
          className="w-4/12"
          onChange={handleFilterInputChange}
        />
      </Box>
      <Table {...getTableProps()} className="w-full">
        <Thead>
          {headerGroups.map((headerGroup: any, index: number) => (
            <Tr key={`A${index}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, index: number) => (
                <Th key={`B${index}`} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row: any, index: number) => {
            prepareRow(row);
            return (
              <Tr
                key={`C${index}`}
                {...row.getRowProps()}
                onClick={() => navigate(`${PAYMENT_PATH}/${row.original.uuid}`)}
              >
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <Td key={`D${index}`} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {data.length > 0 && pageCount > 1 && (
        <Box className="flex justify-between items-center px-5 py-4 border-t border-gray-200">
          <Button
            onClick={() => previousPage()}
            label={t('btnPrevLabel')}
            size="sm"
            variant="secondary-gray"
            disabled={!canPreviousPage}
            icon="arrow-left"
            iconPosition="leading"
          />
          <span className="mx-3">{`Página ${pageIndex + 1} de ${
            pageOptions.length
          }`}</span>
          <Button
            onClick={() => nextPage()}
            label={t('btnNextLabel')}
            size="sm"
            variant="secondary-gray"
            disabled={!canNextPage}
            icon="arrow-right"
            iconPosition="trailing"
          />
        </Box>
      )}
    </>
  );
}

export default PaymentsTable;
