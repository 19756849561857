import { Modal } from '@agrotoken-tech/ui';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';

interface CancelTransferModalProps {
  isOpen: boolean;
  onClickCancelTransferHandler: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

const CancelTransferModal = ({
  isOpen,
  onClickCancelTransferHandler,
  onClose,
  isLoading,
}: CancelTransferModalProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.details.cancelModal',
  });

  return (
    <Modal
      title={t('title')}
      text={t('text')}
      labelBtnConfirm={t('labelBtnConfirm')}
      labelBtnClose={t('labelBtnClose')}
      icon={FiAlertCircle}
      isOpen={isOpen}
      onClose={onClose}
      onClickConfirm={onClickCancelTransferHandler}
      variantBtnConfirm="destructive"
      isLoadingConfirmBtn={isLoading}
    />
  );
};

export default CancelTransferModal;
