import { EMovementTypesId } from '@common/types';
import { TFunction } from 'i18next';

/**
 * Get loan movement name by movement id and returns it in the current language
 */
export const getLoanMovementName = (
  movementId: number,
  translations: TFunction
) => {
  switch (movementId) {
    case EMovementTypesId.LOAN_GRANTED:
      return translations(
        'wallet.movementsTable.transactionType.loans.granted'
      );
    case EMovementTypesId.LOAN_REIMBURSMENT:
      return translations(
        'wallet.movementsTable.transactionType.loans.reimbursement'
      );
    case EMovementTypesId.LOAN_PAID:
      return translations(
        'wallet.movementsTable.transactionType.loans.loanPaid'
      );
    case EMovementTypesId.PAYMENT_FEE:
      return translations('wallet.movementsTable.transactionType.loans.fee');
    default:
      return translations('wallet.movementsTable.transactionType.unknown');
  }
};
