import { Box, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@comp/Global/Icon';
import { Loader } from '@comp/Global/Loader';
import LoanAuthorizationCard from '@comp/LoanAuthorizationCard';
import { LenderType } from '@features/loans/enums';
import { useCollateralizeLoanData } from '@features/loans/hooks';
import { getLenderTypeTranslation } from '@features/loans/utils';

const supportEmail = 'soporte@agrotoken.io';

const LoanAuthorizationErrorLayout = () => {
  const { t } = useTranslation();

  const { isLoadingCollateralizeLoanDetails, collateralizeLoanDetails } =
    useCollateralizeLoanData();

  const { lenderLogo: logo, lenderType } = collateralizeLoanDetails ?? {};

  const translation = getLenderTypeTranslation(lenderType as LenderType);

  if (isLoadingCollateralizeLoanDetails) {
    return <Loader />;
  }

  return (
    <LoanAuthorizationCard logo={logo}>
      <Box color="error.700" mb="4">
        <Icon
          size={'lg'}
          color={'success'}
          theme={'no-background'}
          icon="x-circle"
        />
      </Box>
      <Text size="lg" variant="bold" color="gray.900" mb="2">
        {t(`${translation}.blockAuthorization.error.heading`)}
      </Text>
      <Text fontSize="md" color="gray.700">
        {t(`${translation}.blockAuthorization.error.subheading`)}
        <Button
          color="primary.700"
          variant="linkPrimary"
          _hover={{ textDecoration: 'underline' }}
        >
          {supportEmail}
        </Button>
      </Text>
    </LoanAuthorizationCard>
  );
};

export default LoanAuthorizationErrorLayout;
