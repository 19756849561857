import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import agrotoken from '../../assets/logos/logo_standard.svg';

const Header: React.VFC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const forcedReset = searchParams.get('force-reset') === 'true';

  return (
    <>
      <div className="mb-8 w-full flex">
        <img src={agrotoken} alt="" className="inline-block max-h-6" />
      </div>
      <div className="mb-8">
        <h1 className="display-md font-semibold text-gray-900 mb-2">
          {t('resetPassword.title')}
        </h1>
        <p className="text-md text-gray-500">
          {forcedReset
            ? t('resetPassword.forcedResetSubtitle')
            : t('resetPassword.subtitle')}
        </p>
      </div>
    </>
  );
};

export default Header;
