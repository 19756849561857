export const IdIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="4" fill="#DBF5D4" />
    <path
      d="M8 10.5H16M8 13.5H16M11 7.5L10 16.5M14 7.5L13 16.5"
      stroke="#288703"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
