import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Select,
} from '@chakra-ui/react';
import { SELECT_CURRENCIES } from '@common/const';
import { CprTypes } from '@common/enum/cpr-types.enum';
import {
  CprForm,
  EDocumentTypes,
  GrainTypeOption,
  IGrainContract,
} from '@common/types';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DragAndDrop } from '@comp/Global/DragAndDrop';
import { useValidateCprForm } from '@hooks/useValidateForm';
import 'react-datepicker/dist/react-datepicker.css';
import { maxSizeFileBytes } from '../../Global/DragAndDrop/consts';
import { DateCalendar } from '@agrotoken-tech/ui';

interface Props {
  className?: string;
  grainContract: IGrainContract | undefined;
  shouldShowError: boolean;
  setShouldShowError: React.Dispatch<React.SetStateAction<boolean>>;
  setIsValidForm: React.Dispatch<React.SetStateAction<boolean>>;
  cprForm: CprForm;
  cprFormDocumentId: number | null;
  setCprFormDocumentId: (documentId: number | null) => void;
  onChangeCprForm: (newForm: CprForm) => void;
  setCurrencySelected: React.Dispatch<React.SetStateAction<string>>;
  currencySelected: string | undefined;
  isDisabled?: boolean;
}

export const Form: FC<Props> = ({
  currencySelected,
  setCurrencySelected,
  className,
  shouldShowError,
  setIsValidForm,
  cprFormDocumentId,
  setCprFormDocumentId,
  cprForm,
  onChangeCprForm,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
  } = useForm();
  const { t: translateFromForm } = useTranslation(undefined, {
    keyPrefix: 'tokenization.newTokenization.farmForm',
  });
  const requiredFieldError = translateFromForm('errors.requiredField');
  const [grainTypeOptions, setGrainTypeOptions] = useState<GrainTypeOption[]>(
    []
  );

  const [currencyOptions, setCurrencyOptions] = useState<
    { label: string; value: number | string }[]
  >([{ label: '', value: 0 }]);

  const {
    isValidCpr,
    isValidInternalNumber,
    isNotEmptyInternalNumber,
    isValidPlantedArea,
    isNotEmptyVolume,
    isValidGrainMinVolume,
    isValidPrice,
    isValidPlantingDate,
    isValidType,
    isValidFile,
    isValidExpirationDate,
    isValidExpirationDateAfterPlatingDate,
    minExpirationDateBr,
  } = useValidateCprForm(cprForm);

  const onChangeType = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeCprForm({
      ...cprForm,
      type: ev.currentTarget.value,
    });
  };

  const onChangeInternalNumber = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCprForm({
      ...cprForm,
      internalNumber: ev.currentTarget.value,
    });
  };

  const onChangeExpirationDate = (date: Date | null) => {
    onChangeCprForm({ ...cprForm, expirationDate: date });
  };

  const onChangePlantingDate = (date: Date | null) => {
    onChangeCprForm({ ...cprForm, plantingDate: date });
  };

  const onChangePlantedArea = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCprForm({
      ...cprForm,
      plantedArea: ev.currentTarget.value,
    });
  };

  const onChangeGrainVolume = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCprForm({
      ...cprForm,
      grainVolume: ev.currentTarget.value,
    });
  };

  const onChangePrice = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCprForm({
      ...cprForm,
      price: ev.currentTarget.value,
    });
  };

  useEffect(() => {
    setCurrencyOptions(SELECT_CURRENCIES);
  }, []);

  useEffect(() => {
    setIsValidForm(isValidCpr);
  }, [isValidCpr, setIsValidForm]);

  useEffect(() => {
    setGrainTypeOptions([
      {
        label: t(
          'tokenization.productionExpectation.form.documentTypePlaceholder'
        ),
        value: '',
        text: '',
      },
      {
        label: t('tokenization.productionExpectation.form.grainTypeCprTitle'),
        text: t('tokenization.productionExpectation.form.grainTypeCprText'),
        value: CprTypes.FOR_AGROTOKEN,
      },
      {
        label: t(
          'tokenization.productionExpectation.form.grainTypeEndorsementTitle'
        ),
        text: t(
          'tokenization.productionExpectation.form.grainTypeEndorsementText'
        ),
        value: CprTypes.ENDORSEMENT_AGROTOKEN,
      },
    ]);
  }, []);

  return (
    <form className={`${className}`}>
      <div className={'pt-8'}>
        <div className="grid md:grid-cols-2 gap-x-6 gap-y-8">
          <FormControl isInvalid={shouldShowError && !isValidType} flex="1">
            <FormLabel textTransform={'initial'}>
              {t('tokenization.productionExpectation.form.documentTypeLabel')}
            </FormLabel>
            <Select
              name="type"
              value={cprForm.type}
              onChange={onChangeType}
              isDisabled={isDisabled}
            >
              {grainTypeOptions.map((item) => (
                <option
                  key={item.value.toString()}
                  value={item.value.toString()}
                >
                  {item.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
          </FormControl>
          <FormControl
            mr="4"
            isInvalid={
              shouldShowError &&
              (!isValidInternalNumber || !isNotEmptyInternalNumber)
            }
          >
            <FormLabel htmlFor="internalNumber" textTransform={'initial'}>
              {t('tokenization.productionExpectation.form.documentNumberLabel')}
            </FormLabel>
            <Input
              name="internalNumber"
              placeholder={t(
                'tokenization.productionExpectation.form.documentNumberPlaceholder'
              )}
              value={cprForm.internalNumber}
              type="text"
              onChange={onChangeInternalNumber}
              isDisabled={isDisabled}
            />
            <FormErrorMessage>
              {!isNotEmptyInternalNumber
                ? requiredFieldError
                : t(
                    'tokenization.productionExpectation.form.errorMessages.isInvalidInternalNumberFormat'
                  )}
            </FormErrorMessage>
          </FormControl>

          <DateCalendar
            isDisabled={isDisabled}
            label={t('tokenization.productionExpectation.form.plantDateLabel')}
            isInvalid={shouldShowError && !isValidPlantingDate}
            defaultDate={
              cprForm.plantingDate ? cprForm.plantingDate : undefined
            }
            placeholder={t(
              'tokenization.productionExpectation.form.plantDatePlaceholder'
            )}
            name="plantingDate"
            onChange={onChangePlantingDate}
            errorMessage={requiredFieldError}
          />

          <DateCalendar
            isDisabled={isDisabled}
            label={t(
              'tokenization.productionExpectation.form.expirationDateLabel'
            )}
            isInvalid={
              shouldShowError &&
              (!isValidExpirationDate || !isValidExpirationDateAfterPlatingDate)
            }
            defaultDate={
              cprForm.expirationDate ? cprForm.expirationDate : undefined
            }
            placeholder={t(
              'tokenization.productionExpectation.form.expirationDatePlaceholder'
            )}
            name="expirationDate"
            minDate={minExpirationDateBr}
            onChange={onChangeExpirationDate}
            errorMessage={
              !isValidExpirationDate
                ? requiredFieldError
                : t(
                    'tokenization.productionExpectation.form.errorMessages.isValidExpirationDateAfterPlatingDate'
                  )
            }
          />

          <FormControl
            mr="4"
            isInvalid={shouldShowError && !isValidPlantedArea}
          >
            <FormLabel htmlFor="plantedArea" textTransform={'initial'}>
              {t('tokenization.productionExpectation.form.plantedAreaLabel')}
            </FormLabel>
            <Input
              isDisabled={isDisabled}
              name="plantedArea"
              placeholder={t(
                'tokenization.productionExpectation.form.plantedAreaPlaceholder'
              )}
              value={cprForm.plantedArea}
              type="number"
              onChange={onChangePlantedArea}
              onWheel={(e) => e.currentTarget.blur()}
            />
            <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
          </FormControl>
          <FormControl
            mr="4"
            isInvalid={
              shouldShowError && (!isNotEmptyVolume || !isValidGrainMinVolume)
            }
          >
            <FormLabel htmlFor="documentGrainVolume" textTransform={'initial'}>
              {t(
                'tokenization.productionExpectation.form.documentGrainVolumeLabel'
              )}
            </FormLabel>
            <Input
              isDisabled={isDisabled}
              name="documentGrainVolume"
              placeholder={t(
                'tokenization.productionExpectation.form.documentGrainVolumePlaceholder'
              )}
              value={cprForm.grainVolume}
              type="number"
              onChange={onChangeGrainVolume}
              onWheel={(e) => e.currentTarget.blur()}
            />
            <FormErrorMessage>
              {!isNotEmptyVolume
                ? requiredFieldError
                : t(
                    'tokenization.proofOfLiquidity.form.errorMessage.tokenizationTonsMin'
                  )}
            </FormErrorMessage>
          </FormControl>
          <FormControl mr="4" isInvalid={!isValidPrice && shouldShowError}>
            <FormLabel htmlFor="price" textTransform={'initial'}>
              {t(
                'tokenization.productionExpectation.form.totalReferenceValueLabel'
              )}
            </FormLabel>
            <InputGroup id="price" alignItems={'start'} width="100%">
              <Select
                isDisabled={isDisabled}
                name="priceCurrency"
                value={currencySelected as string}
                onChange={(ev) => setCurrencySelected(ev.target.value)}
                width="25%"
              >
                {currencyOptions.map((currencyOption) => (
                  <option
                    key={currencyOption.value}
                    value={currencyOption.value}
                  >
                    {currencyOption.label}
                  </option>
                ))}
              </Select>
              <Input
                isDisabled={isDisabled}
                name="price"
                placeholder={t(
                  'tokenization.productionExpectation.form.totalReferenceValuePlaceholder'
                )}
                value={cprForm.price}
                type="number"
                onChange={onChangePrice}
                width="full"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </InputGroup>
            <FormErrorMessage>{requiredFieldError}</FormErrorMessage>
          </FormControl>
        </div>
        <div className="mt-8">
          <p className="text-sm font-medium text-gray-700 mb-1.5">
            {t('tokenization.productionExpectation.form.fileAttachment')}
          </p>
          <DragAndDrop
            disabled={isDisabled}
            documentTypeId={EDocumentTypes.CPR}
            shouldShowError={shouldShowError}
            isValid={isValidFile}
            maxFileSize={maxSizeFileBytes}
            setDocumentId={setCprFormDocumentId}
            documentId={cprFormDocumentId}
            deleteCallback={() =>
              onChangeCprForm({
                ...cprForm,
                documentId: null,
                preloadedFile: undefined,
              })
            }
            preloadedFile={cprForm.preloadedFile}
          />
        </div>
      </div>
    </form>
  );
};
