import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import FeatherIcon from 'feather-icons-react';
import React, { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import { EPaymentRequestViewer } from '@common/enum';
import { formatDate } from '@common/utils/format-date';
import { Loader } from '@comp/Global/Loader';
import { ServerError } from '@comp/Global/ServerError';
import { useGetLocalCurrency } from '@hooks/index';
import { isMerchantOracle } from '@services/Oracles';
import { getPaymentRequestsFromBusiness } from '@services/PaymentRequests';
import { PAYMENT_PATH, RECEIVER_FEE_MODAL_COOKIE } from '../../common/const';
import {
  EUnits,
  IPaymentRequest,
  PaymentRequestStatus,
  PAYMENT_REQUEST_STATUS_COLORS,
  PAYMENT_REQUEST_TYPES,
} from '../../common/types';
import { formatNumber } from '../../common/utils';
import { useUser } from '../../context/userContext';
import { Badge } from '../Global/Badge';
import { Container } from '../Global/Container';
import { Spinner } from '../Global/Spinner';
import { TitleSubtitle } from '../Global/TitleSubtitle';
import { PaymentsFeeModal } from '../PaymentsFeeModal';
import PaymentsTable from './PaymentsTable';
import { getPaymentRequestStatusTranslationKey } from './utils';

const PaymentRequests: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([RECEIVER_FEE_MODAL_COOKIE]);
  const [isOpenPaymentsFeeModal, setIsOpenPaymentsFeeModal] = useState(false);
  const { localCurrency } = useGetLocalCurrency();
  const rememberReceiverFee = cookies[RECEIVER_FEE_MODAL_COOKIE] === 'true';

  const onClosePaymentFeeModal = () => {
    setIsOpenPaymentsFeeModal(false);
  };

  const onAcceptPaymentFeeModal = () => {
    setCookie(RECEIVER_FEE_MODAL_COOKIE, true);
    navigate(`${PAYMENT_PATH}/create-payment`);
  };

  const onClickCreatePayment = () => {
    if (rememberReceiverFee) {
      navigate(`${PAYMENT_PATH}/create-payment`);
      return;
    }

    setIsOpenPaymentsFeeModal(true);
  };

  const columns: Column<IPaymentRequest>[] = React.useMemo(
    () => [
      {
        Header: t('paymentRequestsPage.table.columns.transactionType'),
        accessor: 'type',
        Cell: (props: any) => {
          const paymentTypeTranslate =
            props.row.original.type === PAYMENT_REQUEST_TYPES.IN_KIND
              ? 'kind'
              : 'regular';
          return (
            <Text fontSize="sm" color="gray.900" variant="medium">
              {t(
                `paymentRequestsPage.table.transactionType.${paymentTypeTranslate}`
              )}
            </Text>
          );
        },
      },
      {
        Header: t('paymentRequestsPage.table.columns.receiver'),
        accessor: 'toBusiness',
        Cell: (props: any) => {
          const { toBusiness, operation } = props.row
            .original as IPaymentRequest;
          const user = operation
            ? `${operation.fromBusinessUser.user.firstName} ${operation.fromBusinessUser.user.lastName}`
            : '';
          return <TitleSubtitle title={toBusiness.name} subtitle={user} />;
        },
      },
      {
        Header: t('paymentRequestsPage.table.columns.amount'),
        accessor: 'amount',
        Cell: (props: any) => (
          <Text fontSize="sm" color="gray.500">
            {localCurrency}{' '}
            {formatNumber(props.row.original.amount, EUnits.FIAT)}
          </Text>
        ),
      },
      {
        Header: t('paymentRequestsPage.table.columns.date'),
        accessor: 'createdAt',
        Cell: (props: any) => (
          <TitleSubtitle
            title={formatDate(+props.row.original.createdAt, 'DD/MM/YY')}
            subtitle={formatDate(+props.row.original.createdAt, 'hh:mm A')}
          />
        ),
      },
      {
        Header: t('paymentRequestsPage.table.columns.status'),
        accessor: 'status',
        Cell: (props: any) => {
          const { status, type } = props.row.original;

          return (
            <Badge
              size="sm"
              color={
                PAYMENT_REQUEST_STATUS_COLORS[
                  status as PaymentRequestStatus
                ] as any
              }
              label={t(
                `paymentRequestDetails.status.${getPaymentRequestStatusTranslationKey(
                  {
                    paymentRequestStatus: status,
                    paymentRequestType: type,
                  }
                )}`
              )}
            />
          );
        },
      },
    ],
    [t]
  );

  const { selectedBusinessId, errorUser, isLoadingUser, selectedBusiness } =
    useUser();

  const [canCreatePaymentInKind, setCanCreatePaymentInKind] = useState(false);

  const {
    isLoading: isLoadingPayments,
    data: payments,
    isError: isErrorPayments,
  } = useQuery<IPaymentRequest[] | []>(
    ['payments', selectedBusinessId],
    getPaymentRequestsFromBusiness
  );
  useEffect(() => {
    const getCanCreatePaymentInKind = async () => {
      if (selectedBusiness && selectedBusiness.fiscalId) {
        const res = await isMerchantOracle(selectedBusiness.fiscalId);
        setCanCreatePaymentInKind(res);
      }
    };
    getCanCreatePaymentInKind();
  }, [selectedBusiness]);

  if (errorUser) {
    return (
      <ServerError title={t('serverError.title')} text={errorUser?.message} />
    );
  }

  if (isLoadingUser) return <Loader />;
  return (
    <Container>
      {isOpenPaymentsFeeModal && !rememberReceiverFee && (
        <PaymentsFeeModal
          isOpen={isOpenPaymentsFeeModal}
          onAccept={onAcceptPaymentFeeModal}
          onClose={onClosePaymentFeeModal}
          viewer={EPaymentRequestViewer.RECEIVER}
        />
      )}
      <Heading color="gray.900" mb="6">
        {t('paymentRequestsPage.title')}
      </Heading>
      {/* 
			//TODO: Leave this comment since we are not controlling the total amount of transactions
			<Box className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
				<Box className="p-6 shadow-md bg-white">
					<Text className="text-sm text-gray-500 font-medium mb-2">
						{t('paymentRequestsPage.summary.amountReceived')}
					</Text>
					<Text className="display-md text-gray-900 font-medium">
						{`${ELocalCurrency.ARS} ${
							payments
								? formatNumber(
										payments
											.filter((link) => link.operation)
											.reduce((acc, curr) => acc + +curr.amount, 0),
										EUnits.ARS
								  )
								: '0'
						}`}
					</Text>
				</Box>
				<Box className="p-6 shadow-md bg-white">
					<Text className="text-sm text-gray-500 font-medium mb-2">
						{t('paymentRequestsPage.summary.transactionsReceived')}
					</Text>
					<Text className="display-md text-gray-900 font-medium">
						{payments?.filter((link) => link.operation).length}
					</Text>
				</Box>
			</Box> */}
      <Box className="border border-gray-200 shadow-md overflow-x-auto bg-white">
        <Flex className="gap-4 flex-col md:flex-row md:justify-between py-5 px-6 bg-white border-b border-gray-200">
          <Box>
            <Text
              fontSize="lg"
              color="gray.900"
              variant="medium"
              marginBottom={1}
            >
              {t('paymentRequestsPage.table.title')}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {t('paymentRequestsPage.table.subtitle')}
            </Text>
          </Box>
          <Flex gap={3} flexWrap={'wrap'}>
            <Button
              variant="outline"
              leftIcon={<FeatherIcon icon="download" size="20" />}
            >
              {t('paymentRequestsPage.table.download')}
            </Button>
            {canCreatePaymentInKind && (
              <Button
                variant="outline"
                onClick={() =>
                  navigate(`${PAYMENT_PATH}/create-payment-in-kind`)
                }
              >
                {t('paymentRequestsPage.table.paymentInKindBtn')}
              </Button>
            )}
            <Button variant="primary" onClick={onClickCreatePayment}>
              {t('paymentRequestsPage.table.newPaymentRequestBtn')}
            </Button>
          </Flex>
        </Flex>
        {isLoadingPayments && <Spinner className="m-auto py-10" />}
        {isErrorPayments && (
          <Text py={10} textAlign="center">
            {t('paymentRequestsPage.table.loadingError')}
          </Text>
        )}
        {payments && <PaymentsTable columns={columns} data={payments} />}
      </Box>
    </Container>
  );
};
export default PaymentRequests;
