import { Center, Spinner } from '@chakra-ui/react';
import { FC } from 'react';

interface LoaderProps {
  size?: string;
  thickness?: string;
  speed?: string;
  emptyColor?: string;
  color?: string;
}
export const Loader: FC<LoaderProps> = (props: LoaderProps) => {
  const { size, thickness, speed, emptyColor, color } = props;
  return (
    <Center mt="6">
      <Spinner
        size={size ?? 'xl'}
        thickness={thickness ?? '4px'}
        speed={speed ?? '0.75s'}
        emptyColor={emptyColor ?? 'primary.50'}
        color={color ?? 'primary.600'}
        data-testid={'loading-spinner'}
      />
    </Center>
  );
};
