import React, { FC, HTMLAttributes } from 'react';
import { Icon } from '../../Icon';
import { TColor, TSize } from '../../types';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  size: 'sm' | 'md' | 'lg';
  icon?: string;
  color: TColor;
  className: string;
  variant: 'box' | 'cloud';
}

const width = {
  sm: 'w-[152px]',
  md: 'w-[172px]',
  lg: 'w-[220px]',
};

const iconSizes: { [key: string]: TSize } = {
  sm: 'lg',
  md: 'xl',
  lg: 'xl',
};

export const Ilustration: FC<Props> = ({
  size,
  icon,
  color,
  className,
  variant,
}) => {
  return (
    <div
      className={`${width[size]} flex flex-col items-center relative ${className}`}
    >
      {variant === 'cloud' ? (
        <svg
          width="152"
          height="120"
          viewBox="0 0 152 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="76" cy="52" r="52" fill="#BBEF9C" />
          <g filter="url(#filter0_dd_9583_25627)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M77.6 16C66.8273 16 57.2978 21.3233 51.4987 29.4829C49.605 29.0363 47.6301 28.8 45.6 28.8C31.4615 28.8 20 40.2615 20 54.4C20 68.5385 31.4615 80 45.6 80L45.62 80H109.6C121.971 80 132 69.9712 132 57.6C132 45.2288 121.971 35.2 109.6 35.2C108.721 35.2 107.854 35.2506 107.002 35.349C102.098 23.9677 90.7797 16 77.6 16Z"
              fill="#DBF5D4"
            />
            <ellipse
              cx="45.6"
              cy="54.4"
              rx="25.6"
              ry="25.6"
              fill="url(#paint0_linear_9583_25627)"
            />
            <circle
              cx="77.6001"
              cy="48"
              r="32"
              fill="url(#paint1_linear_9583_25627)"
            />
            <ellipse
              cx="109.6"
              cy="57.5999"
              rx="22.4"
              ry="22.4"
              fill="url(#paint2_linear_9583_25627)"
            />
          </g>
          <circle cx="21" cy="19" r="5" fill="#CAF2B8" />
          <circle cx="18" cy="109" r="7" fill="#CAF2B8" />
          <circle cx="145" cy="35" r="7" fill="#CAF2B8" />
          <circle cx="134" cy="8" r="4" fill="#CAF2B8" />
          <defs>
            <filter
              id="filter0_dd_9583_25627"
              x="0"
              y="16"
              width="152"
              height="104"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="erode"
                in="SourceAlpha"
                result="effect1_dropShadow_9583_25627"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_9583_25627"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="erode"
                in="SourceAlpha"
                result="effect2_dropShadow_9583_25627"
              />
              <feOffset dy="20" />
              <feGaussianBlur stdDeviation="12" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_9583_25627"
                result="effect2_dropShadow_9583_25627"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_9583_25627"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_9583_25627"
              x1="25.9429"
              y1="37.4858"
              x2="71.2"
              y2="80"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E6FED7" />
              <stop offset="0.350715" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_9583_25627"
              x1="53.0287"
              y1="26.8571"
              x2="109.6"
              y2="80"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E6FED7" />
              <stop offset="0.350715" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_9583_25627"
              x1="92.3999"
              y1="42.8"
              x2="132"
              y2="79.9999"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E6FED7" />
              <stop offset="0.350715" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="152"
          height="131"
          viewBox="0 0 152 131"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="76" cy="58" r="52" fill="#E4E7EC" />
          <circle cx="21" cy="25" r="5" fill="#F2F4F7" />
          <circle cx="18" cy="109" r="7" fill="#F2F4F7" />
          <circle cx="145" cy="41" r="7" fill="#F2F4F7" />
          <circle cx="134" cy="14" r="4" fill="#F2F4F7" />
          <g filter="url(#filter0_dd_7502_103338)">
            <path
              d="M76 36.0629L80.536 34.1629L76.0006 36.0951V90.1898L117.796 72.6754V18.5566L118 18.4712L117.796 18.3809V18.29L117.692 18.3346L76.3298 0L34 18.0314L34.2004 18.1175L34 72.0166L76 90.1576L76 36.0629Z"
              fill="#F9FAFB"
            />
            <path
              d="M76 36.0631V90.1578L34 72.0168L34.2004 18.1177L76 36.0631Z"
              fill="url(#paint0_linear_7502_103338)"
            />
            <path
              d="M76.001 36.0951V90.1898L117.797 72.6754V18.29L76.001 36.0951Z"
              fill="url(#paint1_linear_7502_103338)"
            />
            <path
              d="M76 36.0629L118 18.4712L76.3298 0L34 18.0314L76 36.0629Z"
              fill="#F2F4F7"
            />
            <path
              d="M49.832 11.2873L91.6718 29.4986L92.0519 42.7698L103.758 37.9395L103.404 24.5846L60.3266 6.81689L49.832 11.2873Z"
              fill="#E4E7EC"
            />
          </g>
          <defs>
            <filter
              id="filter0_dd_7502_103338"
              x="14"
              y="0"
              width="124"
              height="130.19"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="erode"
                in="SourceAlpha"
                result="effect1_dropShadow_7502_103338"
              />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="4" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.04 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_7502_103338"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="erode"
                in="SourceAlpha"
                result="effect2_dropShadow_7502_103338"
              />
              <feOffset dy="20" />
              <feGaussianBlur stdDeviation="12" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_7502_103338"
                result="effect2_dropShadow_7502_103338"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_7502_103338"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_7502_103338"
              x1="34"
              y1="72.2887"
              x2="50.587"
              y2="27.3667"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DEE1E8" />
              <stop offset="1" stopColor="#EFF1F5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_7502_103338"
              x1="76.0004"
              y1="37.1539"
              x2="123.359"
              y2="46.5826"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E4E7EC" />
              <stop offset="1" stopColor="#F2F4F7" />
            </linearGradient>
          </defs>
        </svg>
      )}
      {icon && (
        <Icon
          size={iconSizes[size]}
          theme="light-circle"
          color={color}
          icon={icon}
          className="absolute bottom-3"
        />
      )}
    </div>
  );
};
