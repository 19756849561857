import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@agrotoken-tech/ui';
import { CARD_TYPE } from '../../types';
import { FC } from 'react';
import { Card } from '../../interfaces';
import { getCardStatus } from '@utils/getCardStatus';

interface Props {
  card: Card;
  image?: string;
}

const CardDetail: FC<Props> = ({ card }) => {
  const { t } = useTranslation();

  const selectedBadgeProperties = getCardStatus(card);
  const physicalCard = card.type.toUpperCase() === CARD_TYPE.PHYSICAL;

  return (
    <>
      <Flex align={'center'} gap={2}>
        <Image src={selectedBadgeProperties?.image} mr={1} />
        <Text fontSize="sm" color="gray.700">
          {selectedBadgeProperties?.text}
        </Text>
        <Badge size="sm" colorScheme={'gray'}>
          {physicalCard
            ? t('cards.cardList.main.cardType.physical')
            : t('cards.cardList.main.cardType.virtual')}
        </Badge>
        <Badge size="sm" colorScheme={selectedBadgeProperties?.color}>
          {t(
            `cards.cardList.main.cardStatus.${selectedBadgeProperties?.uiStatus}`
          )}
        </Badge>
      </Flex>
    </>
  );
};

export default CardDetail;
