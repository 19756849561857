import { Flex, Button, Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';

interface FileErrorProps {
  error?: string;
  fileName?: string;
  onClick: () => void;
}

export const FileError = ({ error, fileName, onClick }: FileErrorProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      borderRadius="base"
      border="1px"
      borderColor="error.300"
      bgColor="error.25"
      p="4"
      mt="4"
      justifyContent="space-between"
    >
      <Flex>
        <Icon
          size="md"
          color="error"
          theme="light-circle-outline"
          icon="file-text"
        />
        <Box ml="3.5">
          <Text fontSize="sm" color="error.700" className="font-medium">
            {fileName}
          </Text>
          <Text fontSize="sm" color="error.600" className="font-normal">
            {error}
          </Text>
          <Button
            size="sm"
            variant="linkPrimary"
            color="error.700"
            mt="2"
            onClick={onClick}
            textDecoration="none"
            _hover={{ color: 'error.800' }}
          >
            {t('fileUpload.buttons.tryAgain')}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
