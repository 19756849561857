import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useStepForm } from '.';

interface StepTitleProps {
  children: string;
}

export const StepTitle: React.FC<StepTitleProps> = (props) => {
  const { children } = props;
  const { setTitle } = useStepForm();

  useEffect(() => {
    setTitle(children);
  }, []); // eslint-disable-line

  return null;
};
