import { Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { getLoanDetail } from '@services/Credits';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { CreditSummary } from './CreditSummary';
import { CreditsBanner } from './CreditsBanner';
import { LockedTokensSummary } from './LockedTokensSummary';
import moment from 'moment';
import { countDaysLeft } from '@utils/getDaysLeft';
import { PaymentSummary } from './PaymentSummary';

export const CreditDetailsPage = () => {
  const { creditId = '' } = useParams();

  const { data: creditDetail } = useQuery(['get-loan-detail', creditId], () =>
    getLoanDetail(creditId)
  );

  const daysLeft = creditDetail?.dueAt && countDaysLeft(creditDetail?.dueAt);
  const creationDate = moment(creditDetail?.insertedAt).format('DD/MM/YYYY');
  const dueDate = moment(creditDetail?.dueAt).format('DD/MM/YYYY');

  const lockOperation = (creditDetail?.operations || []).find((operation) => {
    return operation.type === 'lock';
  });
  const lockedTotal = lockOperation?.total || NaN;
  const token = creditDetail?.cryptoCurrency;
  const isTokenPayment = false;

  return (
    <Flex px="80px" pt="48px" pb="66px" flexDir={'column'}>
      <Heading as="h1" mb="24px" fontSize="36px">
        Detalle de préstamo
      </Heading>
      <Flex gap="32px">
        <Flex flexDir={'column'} gap="32px" flex="1">
          <CreditsBanner isActive />
          <CreditSummary
            id={creditId}
            cuit={creditDetail?.beneficiaryId || ''}
            businessName="Business Name"
            loanCreationDate={creationDate}
            amountToFinance={creditDetail?.amountToFinance || NaN}
            lockedTotal={lockedTotal}
            token={token!}
            loanDueDate={dueDate}
          />
        </Flex>
        <Flex flexDir="column" gap="16px" flex="0.7">
          <Text fontSize="16px">
            {`El vencimiento del préstamo será en ${daysLeft} días (${dueDate}).`}
          </Text>

          {creditDetail && lockOperation ? (
            <LockedTokensSummary
              amountToFinance={creditDetail.amountToFinance}
              operationAmountToFinance={lockOperation.amountToFinance || NaN}
              feePercentageRate={lockOperation?.feePercentageRate || NaN}
              operationFee={lockOperation?.fee || NaN}
              operationInterest={lockOperation?.interest || NaN}
              operationReserve={lockOperation?.reserve || NaN}
              operationTotal={lockOperation?.total || NaN}
              annualPercentageRate={lockOperation?.annualPercentageRate || NaN}
              reservePercentageRate={
                lockOperation?.reservePercentageRate || NaN
              }
              operationTokenPriceRate={lockOperation?.tokenPriceRate || NaN}
              token={token!}
            />
          ) : null}
          <PaymentSummary isTokenPayment={isTokenPayment} token={token!} />
        </Flex>
      </Flex>
    </Flex>
  );
};
