import { Flex, Text, Heading } from '@chakra-ui/react';
import { TCurrencyName } from '@common/types';
import { TokenValue } from './TokenValue';

interface Props {
  token: TCurrencyName;
  value: string | number;
  localCurrencyValue: string | number;
}
export const SumLockedTokens = ({
  token,
  value,
  localCurrencyValue,
}: Props) => {
  return (
    <Flex gap="8px" flexDir="column">
      <Flex justifyContent="space-between" fontFamily={'Larsseit-Bold'}>
        <Heading fontSize="18px" fontWeight="500" fontFamily={'Larsseit-Bold'}>
          Total tokens bloqueados:
        </Heading>
        <TokenValue token={token} value={value} />
      </Flex>
      <Flex justifyContent={'space-between'} color="gray.700">
        <Text>Equivalencia en moneda local:</Text>
        <Text>{localCurrencyValue}</Text>
      </Flex>
    </Flex>
  );
};
