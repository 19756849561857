import { LOANS_PREFIX } from '@agrotoken/common-utils';
import { Route } from 'react-router-dom';

import { RequireAuth } from '@comp/Content';
import LoanAuthorizationPage from './authorize';
import LoanAuthorizationErrorPage from './authorize/error';
import LoanAuthorizationSuccessPage from './authorize/success';
import PaymentAuthorizationPage from './authorize-payment';
import PaymentAuthorizationErrorPage from './authorize-payment/error';
import PaymentAuthorizationSuccessPage from './authorize-payment/success';
import {
  AuthorizeLoanPaymentDataProvider,
  CollateralizeLoanDataProvider,
} from './providers';

const LoansRouter = [
  <Route
    key="collateralizeLoan"
    path={`/${LOANS_PREFIX}/:loanUuid/collateralize`}
    element={
      <RequireAuth>
        <CollateralizeLoanDataProvider>
          <LoanAuthorizationPage />
        </CollateralizeLoanDataProvider>
      </RequireAuth>
    }
  />,
  <Route
    key="collateralizeLoanSuccess"
    path={`/${LOANS_PREFIX}/:loanUuid/collateralize/success`}
    element={
      <RequireAuth>
        <CollateralizeLoanDataProvider>
          <LoanAuthorizationSuccessPage />
        </CollateralizeLoanDataProvider>
      </RequireAuth>
    }
  />,
  <Route
    key="collateralizeLoanError"
    path={`/${LOANS_PREFIX}/:loanUuid/collateralize/error`}
    element={
      <RequireAuth>
        <CollateralizeLoanDataProvider>
          <LoanAuthorizationErrorPage />
        </CollateralizeLoanDataProvider>
      </RequireAuth>
    }
  />,

  <Route
    key="authorizeLoanPayment"
    path={`/${LOANS_PREFIX}/:loanUuid/authorize-payment`}
    element={
      <RequireAuth>
        <AuthorizeLoanPaymentDataProvider>
          <PaymentAuthorizationPage />
        </AuthorizeLoanPaymentDataProvider>
      </RequireAuth>
    }
  />,
  <Route
    key="authorizeLoanPaymentSuccess"
    path={`/${LOANS_PREFIX}/:loanUuid/authorize-payment/success`}
    element={
      <RequireAuth>
        <AuthorizeLoanPaymentDataProvider>
          <PaymentAuthorizationSuccessPage />
        </AuthorizeLoanPaymentDataProvider>
      </RequireAuth>
    }
  />,
  <Route
    key="authorizeLoanPaymentError"
    path={`/${LOANS_PREFIX}/:loanUuid/authorize-payment/error`}
    element={
      <RequireAuth>
        <AuthorizeLoanPaymentDataProvider>
          <PaymentAuthorizationErrorPage />
        </AuthorizeLoanPaymentDataProvider>
      </RequireAuth>
    }
  />,
];

export default LoansRouter;
