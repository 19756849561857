export const CalendarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="4" fill="#DBF5D4" />
    <path
      d="M14 7V9M10 7V9M7.5 11H16.5M8.5 8H15.5C16.0523 8 16.5 8.44772 16.5 9V16C16.5 16.5523 16.0523 17 15.5 17H8.5C7.94772 17 7.5 16.5523 7.5 16V9C7.5 8.44772 7.94772 8 8.5 8Z"
      stroke="#288703"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
