import {
  SimpleGrid,
  Box,
  Text,
  Button,
  Stack,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { HOME_PATH } from '@src/common/const';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import broken_tractor from '../../assets/images/broken_tractor.svg';
import logo_standard from '../../assets/logos/logo_standard.svg';
import { Container } from '../Global/Container';

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container>
      <SimpleGrid columns={[1, null, 2]} spacing="0">
        <Flex bg="white" justifyContent="center" flexDirection="column">
          <Box pb="10">
            <img
              src={logo_standard}
              alt={t('Logo Agrotoken')}
              className="w100"
            />
          </Box>
          <Text color="primary.600">{t('notFound.title')}</Text>
          <Heading variant="medium" size="lg">
            {t('notFound.subtitle')}
          </Heading>

          <Text pt="4" pr="4" size="xl" color="gray.500">
            {t('notFound.description')}
          </Text>

          <Stack direction="row" spacing={4} align="center" pt="10">
            <Button
              variant="outline"
              size="md"
              p="6"
              onClick={() => navigate(-1)}
            >
              <FeatherIcon icon="arrow-left" size="16" className="mx-3" />
              {t('shared.backButton')}
            </Button>
            <Button
              variant="primary"
              size="md"
              p="6"
              onClick={() => navigate(HOME_PATH)}
            >
              {t('shared.btnLabel')}
            </Button>
          </Stack>
        </Flex>
        <Flex flexDir="column" justifyContent="center">
          <img src={broken_tractor} alt={t('notFound.imageDescription')} />
        </Flex>
      </SimpleGrid>
    </Container>
  );
};

export default NotFound;
