import { Box } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { FiCheckCircle } from 'react-icons/fi';
import { PAYMENT_TYPE_TRANSLATION } from '@common/const';
import { PAYMENT_REQUEST_STATUSES } from '@common/types/payment-request';
import { calculateWarrantyGCNetPricePerSaca } from '@common/utils';
import { estimateMerchantFeeAmountInReceivedPaymentDetail } from '@common/utils/PaymentRequests/fees';
import { Toast } from '@comp/Global/Toast';
import {
  Details,
  PaymentLayout,
  Title,
} from '@comp/PaymentRequestView/Fragments';
import { PaymentCompleteSummary } from '@comp/PaymentRequestView/Fragments/PaymentCompleteSummary';
import { PaymentRequestAsset, PaymentRequestType } from '@src/common/enum';
import {
  CryptoCurrencySymbol,
  EPaymentRequestStatusTranslated,
  IPaymentRequest,
} from '../../../../common/types';
import { PaymentSummaryReceiver } from '../PaymentSummary';

interface Props {
  paymentRequest: IPaymentRequest;
}

export const PaymentDetails: FC<Props> = ({ paymentRequest }) => {
  const { t } = useTranslation();

  const { status } = paymentRequest;

  const isPaymentCompleted =
    status === PAYMENT_REQUEST_STATUSES.COMPLETED && paymentRequest.operation;
  const paymentStatusTranslated = EPaymentRequestStatusTranslated[status];

  const amount = paymentRequest.amount;
  const merchantFee = paymentRequest.operation?.merchantFee;
  const estimatedFeeAmount: number =
    estimateMerchantFeeAmountInReceivedPaymentDetail(merchantFee, amount);
  const asset =
    paymentRequest.type === PaymentRequestType.FUTURE_WARRANTY
      ? PaymentRequestAsset.WARRANTY
      : PaymentRequestAsset.TOKENS;

  const paymentTypeTranslation = PAYMENT_TYPE_TRANSLATION[paymentRequest.type];

  const createdDate = paymentRequest?.operation?.createdAt;

  const [toast, setToast] = useState<{
    title: string;
    text: string;
    featuredIcon: IconType;
    variant: 'primary' | 'destructive';
    isOpen: boolean;
  }>({
    title: '',
    text: '',
    featuredIcon: FiCheckCircle,
    variant: 'primary',
    isOpen: false,
  });

  let remainingDaysBeforeClaimingWarranty;
  const warrantyClaimabilityDate =
    paymentRequest.warrantyMetadata?.claimabilityDate;

  if (warrantyClaimabilityDate) {
    const today = moment().unix();

    remainingDaysBeforeClaimingWarranty = Math.ceil(
      (warrantyClaimabilityDate - today) / (3600 * 24)
    );
  }

  const warrantyPricePerSaca = paymentRequest.warrantyMetadata?.pricePerSaca
    ? new Decimal(paymentRequest.warrantyMetadata?.pricePerSaca)
    : calculateWarrantyGCNetPricePerSaca(
        paymentRequest.warrantyMetadata?.warrantyGrainContract
      );

  return (
    <PaymentLayout
      header={
        <>
          <Title
            title={t('paymentDetails.title', {
              paymentRequestId: paymentRequest.id,
            })}
            subtitle={
              paymentRequest.type === PaymentRequestType.FUTURE_WARRANTY ||
              paymentRequest.type === PaymentRequestType.FIXED
                ? ''
                : t(
                    `paymentDetails.subtitle.${paymentStatusTranslated}.${paymentTypeTranslation}`
                  )
            }
          />
        </>
      }
      main={<Details paymentRequest={paymentRequest} showShareLink={false} />}
      rightSide={
        <Box>
          {isPaymentCompleted ? (
            <PaymentCompleteSummary
              status={asset === undefined ? 'EMPTY' : 'READY'}
              paymentRequest={paymentRequest}
              paymentAsset={asset ?? PaymentRequestAsset.TOKENS}
              isPayer={false}
              selectedToken={
                asset === PaymentRequestAsset.TOKENS
                  ? (paymentRequest.operation?.currency
                      .name as CryptoCurrencySymbol)
                  : undefined
              }
              warrantyPricePerSaca={warrantyPricePerSaca}
            />
          ) : (
            <PaymentSummaryReceiver
              amount={paymentRequest.amount}
              paymentAsset={asset ?? PaymentRequestAsset.TOKENS}
              tax={estimatedFeeAmount}
              status={asset === undefined ? 'EMPTY' : 'READY'}
              isPayer={false}
              createdDate={Number(createdDate)}
              warrantyReceptionDate={
                paymentRequest.warrantyMetadata?.claimabilityDate
              }
              warrantyPricePerSaca={warrantyPricePerSaca}
              selectedToken={
                asset === PaymentRequestAsset.TOKENS
                  ? (paymentRequest.operation?.currency
                      .name as CryptoCurrencySymbol)
                  : undefined
              }
              receiverFee={new Decimal(paymentRequest.merchantFee)}
              payerFee={new Decimal(paymentRequest.payerFee)}
              forwardingsAmountSum={
                paymentRequest.forwardingMetadata?.totalAmount
              }
            />
          )}

          {/* Claim warranty button and info */}

          <Toast
            className="fixed top-6 right-6 z-50"
            show={toast.isOpen}
            onClickClose={() => setToast({ ...toast, isOpen: false })}
            title={toast.title}
            text={toast.text}
            featuredIcon={toast.featuredIcon}
            variant={toast.variant}
          />
        </Box>
      }
    />
  );
};
