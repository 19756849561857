import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { HOME_PATH } from '@common/const';
import { EdgeCaseView } from '@comp/EdgeCaseView';

const UnauthorizedTransfer = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.unauthorized',
  });

  const onClickHandler = () => {
    navigate(HOME_PATH);
  };

  return (
    <EdgeCaseView
      heading={t('heading')}
      text={t('text')}
      buttonLabel={t('buttonLabel')}
      buttonOnClick={onClickHandler}
    />
  );
};

export default UnauthorizedTransfer;
