import { Modal } from '@agrotoken-tech/ui';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';

interface RejectTransferModalProps {
  isOpen: boolean;
  onClickRejectTransferHandler: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

const RejectTransferModal = ({
  isOpen,
  onClickRejectTransferHandler,
  onClose,
  isLoading,
}: RejectTransferModalProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.details.rejectModal',
  });

  return (
    <Modal
      title={t('title')}
      text={t('text')}
      labelBtnConfirm={t('labelBtnConfirm')}
      labelBtnClose={t('labelBtnClose')}
      icon={FiAlertCircle}
      isOpen={isOpen}
      onClose={onClose}
      onClickConfirm={onClickRejectTransferHandler}
      variantBtnConfirm="destructive"
      isLoadingConfirmBtn={isLoading}
    />
  );
};

export default RejectTransferModal;
