import axios, { AxiosRequestConfig } from 'axios';
import get from 'lodash.get';
import { ERROR_MESSAGE } from './common/const';
import { getCurrentUser } from './context/authContext';
import jwt_decode from 'jwt-decode';

export const COMMON_HEADERS = {
  CURRENT_REGION: 'x-current-region',
};

// Default axios instance config
// withCredentials = true allows cookies
axios.defaults.withCredentials = true;

export const httpLoansApiClient = axios.create({
  baseURL: process.env.REACT_APP_LOANS_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

// Base axios instance core-api-gateway
export const http = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_GATEWAY_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

http.interceptors.request.use(async (config: AxiosRequestConfig) => {
  // Get the current user
  const currentUser = getCurrentUser();

  if (!currentUser) {
    return config;
  }

  try {
    // Get the user session
    const session = await promisifyGetSession(currentUser);

    if (!config.headers) {
      return config;
    }

    // Set 'x-current-business-user-id' header if available in localStorage
    config.headers['x-current-business-user-id'] =
      localStorage.getItem('selectedBusinessUserId') || '';

    // Get the JWT token from the user session
    let token = session.getIdToken().getJwtToken();

    if (!token) {
      return Promise.reject('Token not found');
    }

    const decodedToken: any = jwt_decode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = decodedToken.exp;

    // Validates that the token hasn't expired yet
    const canExecuteRequest = expirationTime - currentTime > 5;

    // Function to refresh the JWT token
    const refreshJwtToken = async () => {
      try {
        // Refresh the user session and get the new token
        const refreshedSession = await promisifyRefreshSession(
          currentUser,
          session.refreshToken
        );
        token = refreshedSession.getIdToken().getJwtToken();
      } catch (err) {
        console.error('Error refreshing session:', err);
        throw err;
      }
    };

    // Asynchronous function to handle token refresh and update headers
    const handleTokenRefresh = async () => {
      try {
        await refreshJwtToken();
      } catch (error) {
        // Handle error if token refresh fails
        console.error('Error refreshing token:', error);
      }
    };

    // If the token needs to be refreshed
    if (!canExecuteRequest) {
      await handleTokenRefresh();
    }

    // Update headers with the refreshed token or the original token
    config.headers['Authorization'] = 'Bearer ' + token;

    // Continue with the original logic
    return config;
  } catch (error) {
    console.error('Error getting session:', error);
    return Promise.reject(error);
  }
});

// Helper function to promisify currentUser.getSession
const promisifyGetSession = (currentUser: any) => {
  return new Promise<any>((resolve, reject) => {
    currentUser.getSession((err: any, session: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  });
};

// Helper function to promisify currentUser.refreshSession
const promisifyRefreshSession = (currentUser: any, refreshToken: string) => {
  return new Promise<any>((resolve, reject) => {
    currentUser.refreshSession(
      refreshToken,
      (err: any, refreshedSession: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(refreshedSession);
        }
      }
    );
  });
};

http.interceptors.response.use(
  (response) => response, // if ok just response
  (error: any) => {
    if (error?.response?.status === 500) {
      error.response.data.message = ERROR_MESSAGE;
      error.message = ERROR_MESSAGE;
      return Promise.reject(error);
    }

    const errorMessage = get(error, 'response.data.message');
    error.message = errorMessage;
    // should reject the promise so your api call goes to catch block
    return Promise.reject(error);
  }
);

export const httpLoans = axios.create({
  baseURL: process.env.REACT_APP_LOANS_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

export const httpBusinessInvites = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

httpBusinessInvites.interceptors.response.use(
  (response) => response, // if ok just response
  (error: any) => {
    if (error?.response?.status === 500) {
      error.response.data.message = ERROR_MESSAGE;
      error.message = ERROR_MESSAGE;
      return Promise.reject(error);
    }

    const errorMessage = get(error, 'response.data.message');
    error.message = errorMessage;
    // should reject the promise so your api call goes to catch block
    return Promise.reject(error);
  }
);

httpLoans.interceptors.request.use(
  (config) => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      return config;
    }
    return currentUser.getSession(function (err: any, session: any) {
      if (err) return Promise.reject(err);
      if (!config.headers) return;
      config.headers['x-current-business-user-id'] = localStorage.getItem(
        'selectedBusinessUserId'
      )!;
      config.headers['x-current-auth-uuid'] =
        localStorage.getItem('currentAuthUuid')!;
      config.headers['Authorization'] =
        'Bearer ' + session.getIdToken().getJwtToken();
      return Promise.resolve(config);
    });
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpLoans.interceptors.response.use(
  (response) => response, // if ok just response
  (error: any) => {
    if (error?.response?.status === 500) {
      error.response.data.message = ERROR_MESSAGE;
      error.message = ERROR_MESSAGE;
      return Promise.reject(error);
    }

    const errorMessage = get(error, 'response.data.message');
    error.message = errorMessage;
    // should reject the promise so your api call goes to catch block
    return Promise.reject(error);
  }
);

// Base axios instance pricing-api-gateway
export const httpPricing = axios.create({
  baseURL: process.env.REACT_APP_PRICING_API_GATEWAY_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

//TODO: Need to check why with [ httpPricing.defaults.withCredentials = true ] CORS prevent fetch
httpPricing.defaults.withCredentials = false;

httpPricing.interceptors.response.use(
  (response) => response, // if ok just response
  (error: any) => {
    if (error?.response?.status === 500) {
      error.response.data.message = ERROR_MESSAGE;
      error.message = ERROR_MESSAGE;
      return Promise.reject(error);
    }

    const errorMessage = get(error, 'response.data.message');
    error.message = errorMessage;
    // should reject the promise so your api call goes to catch block
    return Promise.reject(error);
  }
);

export const httpCredits = axios.create({
  baseURL: process.env.REACT_APP_CREDITS_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

httpCredits.interceptors.request.use((config) => {
  const currentUser = getCurrentUser();
  if (!currentUser) {
    return config;
  }
  return currentUser.getSession(function (err: any, session: any) {
    if (err) return Promise.reject(err);
    if (!config.headers) return;
    config.headers['Authorization'] =
      'Bearer ' + session.getIdToken().getJwtToken();
    return Promise.resolve(config);
  });
});

// Platform axios instance
export const httpPlatform = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/platform`,
  headers: {
    'Content-type': 'application/json',
  },
});

httpPlatform.interceptors.request.use(
  (config) => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      return config;
    }
    return currentUser.getSession(function (err: any, session: any) {
      if (err) return Promise.reject(err);

      if (!config.headers) return;

      config.headers['x-current-business-user-id'] =
        localStorage.getItem('selectedBusinessUserId') ?? '';

      config.headers['Authorization'] =
        'Bearer ' + session.getIdToken().getJwtToken();

      return Promise.resolve(config);
    });
  },
  (error) => {
    return Promise.reject(error);
  }
);
