import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { getCountryFlagEmoji } from '@agrotoken/frontend-utils';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ENationalities } from '@agrotoken/common-utils';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { languages } from '@src/translations/i18n';

const CountrySelector = () => {
  const { t, i18n } = useTranslation();
  const { brazilSelectOptionSoftOnboarding } = useFlags();
  const [searchParams] = useSearchParams();
  const region = searchParams.get('region');
  const { watch, register, setValue } = useFormContext();
  const selectedCountry = watch('country');

  useEffect(() => {
    if (region && brazilSelectOptionSoftOnboarding) {
      setValue('country', region);
    }
    // brazilSelectOptionSoftOnboarding inits as undefined in first render
    // we want to set the country value after the flag is loaded
  }, [region, brazilSelectOptionSoftOnboarding]);

  useEffect(() => {
    if (selectedCountry) {
      i18n.changeLanguage(languages[selectedCountry as ENationalities]);
    }
  }, [selectedCountry]);

  return (
    <FormControl>
      <FormLabel htmlFor="country">
        {t('softOnboarding.signUpScreen.form.country')}
      </FormLabel>
      <Select id="country" {...register('country')} h="12">
        <option value={ENationalities.AR}>
          {getCountryFlagEmoji(ENationalities.AR)} Argentina
        </option>
        {brazilSelectOptionSoftOnboarding && (
          <option value={ENationalities.BR}>
            {getCountryFlagEmoji(ENationalities.BR)} Brasil
          </option>
        )}
      </Select>
    </FormControl>
  );
};

export default CountrySelector;
