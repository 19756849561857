import { Text, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStepForm, StepTitle } from '@comp/Global/StepForm';
import { TransferTokensFormValues } from '@comp/TransferTokens';
import { ExternalWallet } from './ExternalWallet';
import { RipioWallet } from './RipioWallet';

export const WalletStep = () => {
  const { t } = useTranslation();

  const { handleNextStep } = useStepForm<TransferTokensFormValues>();
  const [checkedIndex, setCheckedIndex] = useState<number | null>(null);

  const toggleSelected = (index: number) => () => {
    if (checkedIndex === index) {
      setCheckedIndex(null);
      return;
    }

    setCheckedIndex(index);
  };

  return (
    <>
      <StepTitle>{t('transferTokens.walletStep.title')}</StepTitle>
      <Text align="center" fontFamily="Larsseit" fontSize="md">
        {t('transferTokens.walletStep.subtitle')}
      </Text>
      <RipioWallet
        selected={checkedIndex === 0}
        toggleSelected={toggleSelected(0)}
      />
      <ExternalWallet />

      <Button
        variant="primary"
        border="px"
        marginTop="6"
        isFullWidth
        size="lg"
        onClick={handleNextStep}
        isDisabled={checkedIndex === null}
      >
        {t('transferTokens.walletStep.btnLabel')}
      </Button>
    </>
  );
};
