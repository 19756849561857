import { dateToTimestamp, timestampToDate } from '@agrotoken/common-utils';
import { HOME_PATH } from '@common/const';
import {
  EBusinessStructureTypes,
  EDocumentTypes,
  EValidationStatusId,
} from '@common/types';
import { Loader } from '@src/components/Global/Loader';
import { useFullOnboardingAccess } from '@src/context/fullOnboardingAccessContext';
import { useUser } from '@src/context/userContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  getOnboardingLevel2,
  saveOnboardingLevel2,
} from '@services/FullOnboarding';
import OnboardingLevel2Form from './OnboardingLevel2Form';

const OnboardingLevel2FormContainer = () => {
  const { selectedBusiness, selectedBusinessUserWasAdminInvited, currentUser } =
    useUser();
  let { selectedBusinessUserWasInvited } = useUser();
  selectedBusinessUserWasInvited =
    selectedBusinessUserWasInvited && !selectedBusinessUserWasAdminInvited;
  const { fetchRequests } = useFullOnboardingAccess();
  const formMethods = useForm();
  const { t } = useTranslation();
  const {
    watch,
    handleSubmit: handleFormSubmit,
    reset,
    setError,
    getValues,
  } = formMethods;
  const { mutate: saveOnboardingData, isLoading: isSaving } = useMutation({
    mutationFn: saveOnboardingLevel2,
    onSuccess: fetchRequests,
  });

  const { data: level2Data, isLoading: isLoadingLevel2Data } = useQuery(
    'getOnboardingLevel2',
    getOnboardingLevel2
  );

  const dniFront = currentUser?.documents?.find(
    (e: any) => e.documentTypeId === EDocumentTypes.DNI_FRONT
  );
  const dniBack = currentUser?.documents?.find(
    (e: any) => e.documentTypeId === EDocumentTypes.DNI_BACK
  );

  useEffect(() => {
    if (!isLoadingLevel2Data && currentUser && level2Data) {
      const socialContractFilesIds = getValues('socialContractFilesIds');
      const legalPowerFilesIds = getValues('legalPowerFilesIds');
      const defaultNationality =
        level2Data.nationality ?? currentUser?.nationalityRelation;

      reset({
        ...level2Data,
        pep: String(level2Data?.pep ?? currentUser?.pep ?? 0),
        cpf: level2Data?.cpf ?? currentUser?.identificationTaxValue,
        birthDate:
          (level2Data?.birthDate &&
            moment(timestampToDate(Number(level2Data?.birthDate))).format(
              'yyyy-MM-DD'
            )) ??
          (currentUser?.birthDate &&
            moment(timestampToDate(Number(currentUser?.birthDate))).format(
              'yyyy-MM-DD'
            )) ??
          undefined,
        motherName:
          level2Data?.motherName ?? currentUser?.userPayfacDetails?.motherName,
        personIdDocumentFront: level2Data?.idFrontFileId ?? dniFront?.id,
        personIdDocumentBack: level2Data?.idBackFileId ?? dniBack?.id,
        socialContractFilesIds,
        legalPowerFilesIds,
        nationality: defaultNationality
          ? {
              value: defaultNationality.id.toString(),
              label: t(
                `fullOnboarding.lvl_2.countries.${defaultNationality.isoCode2}`
              ),
            }
          : undefined,
      });
    }
  }, [currentUser, level2Data, isLoadingLevel2Data, reset, dniFront, dniBack]);

  const navigate = useNavigate();

  const getDataToSave = (data: FieldValues, isDraft: boolean) => {
    const {
      personIdDocumentBack,
      personIdDocumentFront,
      birthDate,
      cpf,
      physicalAddress,
      streetNumber,
      neighborhood,
      complement,
      postalCode,
      geographicalDivisionId,
      cityId,
      userPhysicalAddress,
      userStreetNumber,
      userNeighborhood,
      userComplement,
      userPostalCode,
      userGeographicalDivisionId,
      userCityId,
      motherName,
      legalPowerFilesIds,
      socialContractFilesIds,
      nationality,
    } = data;
    const pep = data.pep ? Number(data.pep) : 0;

    const notNumbersRegex = /\D/g;
    // TODO: Improve typing
    const onboardingData: any = {
      physicalAddress: physicalAddress || undefined,
      streetNumber: streetNumber || undefined,
      neighborhood: neighborhood || undefined,
      complement: complement || undefined,
      postalCode: postalCode?.replace(notNumbersRegex, '') || undefined,
      geographicalDivisionId,
      cityId,
      userPhysicalAddress: userPhysicalAddress || undefined,
      userStreetNumber: userStreetNumber || undefined,
      userNeighborhood: userNeighborhood || undefined,
      userComplement: userComplement || undefined,
      userPostalCode: userPostalCode?.replace(notNumbersRegex, '') || undefined,
      userGeographicalDivisionId,
      userCityId,
      cpf,
      motherName: motherName || undefined,
      idFrontFileId: personIdDocumentFront,
      idBackFileId: personIdDocumentBack,
      birthDate: dateToTimestamp(moment(birthDate).toDate()),
      socialContractFilesIds,
      legalPowerFilesIds,
      pep,
      nationalityId: Number(nationality?.value),
    };

    if (!isDraft) {
      onboardingData['isPendingOpsReview'] = true;
      onboardingData['isPendingUserReview'] = false;
    }

    return onboardingData;
  };

  const onSubmit = (data: FieldValues) => {
    const onboardingData = getDataToSave(data, false);
    const validationStatusId = EValidationStatusId.PENDING;
    const payload = {
      ...onboardingData,
      validationStatusId,
      userInvited: selectedBusinessUserWasInvited,
      tokenizationConditionsAcceptedAt: moment().unix(),
    };

    let hasFileErrors = false;
    if (
      selectedBusiness?.businessStructureTypeId ===
        EBusinessStructureTypes.COMPANY ||
      (selectedBusinessUserWasInvited &&
        selectedBusiness?.businessStructureTypeId ===
          EBusinessStructureTypes.PERSON)
    ) {
      if (onboardingData.legalPowerFilesIds?.length === 0) {
        hasFileErrors = true;
        setError('legalPowerFilesIds', { message: 'Este campo é obrigatório' });
      }
    }

    if (
      !selectedBusinessUserWasInvited &&
      selectedBusiness?.businessStructureTypeId ===
        EBusinessStructureTypes.COMPANY
    ) {
      if (onboardingData.socialContractFilesIds?.length === 0) {
        hasFileErrors = true;
        setError('socialContractFilesIds', {
          message: 'Este campo é obrigatório',
        });
      }
    }

    if (!hasFileErrors) {
      saveOnboardingData(payload);
    }
  };

  const onCompleteLater = () => {
    const data = watch();
    const payload = getDataToSave(data, true);
    saveOnboardingData(payload, { onSuccess: () => navigate(HOME_PATH) });
  };

  const {
    validationStatusId,
    userValidationMsg,
    userDocumentsValidationMsg,
    businessUserDocumentsValidationMsg,
    businessDocumentsValidationMsg,
    businessAddressValidationMsg,
    userAddressValidationMsg,
  } = level2Data || {};

  const messages = {
    userValidationMsg,
    userDocumentsValidationMsg,
    businessUserDocumentsValidationMsg,
    businessDocumentsValidationMsg,
    businessAddressValidationMsg,
    userAddressValidationMsg,
  };
  const changeRequestMessages: string[] = Object.values(messages).filter(
    (m) => m
  );

  const loading = isLoadingLevel2Data && !currentUser && !level2Data;

  if (loading) return <Loader />;

  return (
    <OnboardingLevel2Form
      formMethods={formMethods}
      onCompleteLater={onCompleteLater}
      onSubmit={onSubmit}
      loading={loading}
      userWasInvited={selectedBusinessUserWasInvited}
      handleFormSubmit={handleFormSubmit}
      defaultValues={{
        frontPersonDocument: level2Data?.idFrontFile ?? dniFront,
        backPersonDocument: level2Data?.idBackFile ?? dniBack,
        legalPowerDocuments: level2Data?.legalPowerFiles,
        socialContractDocuments: level2Data?.socialContractFiles,
      }}
      businessStructureType={selectedBusiness?.businessStructureTypeId}
      isSaving={isSaving}
      changeRequestMessages={changeRequestMessages}
      selectedBusiness={selectedBusiness ?? undefined}
      currentUser={currentUser ?? undefined}
    />
  );
};

export default OnboardingLevel2FormContainer;
