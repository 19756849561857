import FeatherIcon from 'feather-icons-react';
import { FC, HTMLAttributes } from 'react';
import { TBreadcrumbOption } from '../../../common/types';
import { Button } from '../Button';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  options: TBreadcrumbOption[];
  className?: string;
}
// TODO: modify component to accept label as ReactNode and FeatherIcon alignment.
// Also upload it to component library

const Breadcrumb: FC<Props> = ({ options, className }) => {
  return (
    <div className={`flex items-center ${className}`}>
      {options.map((item, index) =>
        index !== options.length - 1 ? (
          <div key={index} className="flex items-center">
            <Button
              variant="link-gray"
              to={item.to}
              label={item.label}
              size="sm"
            />
            <FeatherIcon
              icon="chevron-right"
              size="16"
              className="mx-3 text-gray-300"
            />
          </div>
        ) : (
          <p key={index} className="text-sm text-gray-500">
            {item.label}
          </p>
        )
      )}
    </div>
  );
};

export default Breadcrumb;
