import { Flex, Progress, Image } from '@chakra-ui/react';
import arrow from '../../assets/icons/arrow.svg';

const Scoring = ({ healthControl }: { healthControl: number }) => {
  return (
    <Flex position={'relative'} width="90%" mt="3" height={'12px'}>
      <Image
        src={arrow}
        w="14px"
        position={'absolute'}
        right={healthControl ? '0%' : 'calc(100% - 14px)'}
        top={'-10px'}
      />
      <Progress
        value={0}
        bgColor="error.600"
        borderRadius={'8px'}
        height="8px"
        width={'calc(100% / 3)'}
        position="absolute"
        left={0}
        zIndex="3"
        borderColor={'white'}
        borderWidth="2px"
        boxSizing="content-box"
      />
      <Progress
        value={0}
        bgColor="warning.600"
        borderRadius={'8px'}
        height="8px"
        width={'calc(200% / 3)'}
        position="absolute"
        left={0}
        zIndex="2"
        borderColor={'white'}
        borderWidth="2px"
        boxSizing="content-box"
      />
      <Progress
        value={0}
        bgColor="success.600"
        borderRadius={'8px'}
        height="8px"
        width={'100%'}
        borderColor={'white'}
        borderWidth="2px"
        boxSizing="content-box"
      />
    </Flex>
  );
};

export default Scoring;
