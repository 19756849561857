import { WarrantyCard } from '@agrotoken/frontend-utils';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { PaymentRequestType } from '@common/enum';
import {
  EUnits,
  IGrainContract,
  Origin,
  TCryptoCurrencyName,
} from '@common/types';
import { Loader } from '@comp/Global/Loader';
import { SelectTokens } from '@comp/PaymentRequestView/Payer/MakePayment/SelectTokens';
import { FixedGrain } from '@features/cards/interfaces/fixedGrain';
import PaymentRequestFixedGrainCard from '@features/paymentRequest/FixedGrainSelector/PaymentRequestFixedGrainCard';
import {
  getFixedGrainContractByPaymentRequestUuid,
  getFutureGrainContractsByPaymentRequestId,
} from '@services/PaymentRequests';

interface Props {
  paymentRequestId: number;
  paymentRequestUuid: string;

  type: PaymentRequestType;
  token: TCryptoCurrencyName | undefined;
  setToken: React.Dispatch<
    React.SetStateAction<TCryptoCurrencyName | undefined>
  >;
  isInBlockchain: boolean;
  selectedTerm: number | undefined;
  setSelectedTerm: React.Dispatch<React.SetStateAction<number | undefined>>;
}
const SelectTypePaymentMethod = (props: Props) => {
  const { t } = useTranslation();
  const {
    paymentRequestId,
    paymentRequestUuid,
    type,
    token,
    setToken,
    isInBlockchain,
  } = props;
  const { data: grainContract } = useQuery(
    ['grainContract', paymentRequestId],
    async () =>
      paymentRequestId && type === PaymentRequestType.FUTURE_WARRANTY
        ? await getFutureGrainContractsByPaymentRequestId(paymentRequestId)
        : ({} as IGrainContract)
  );
  const { data: fixedGrainContract } = useQuery<FixedGrain | undefined>(
    ['fixedGrainContract', paymentRequestUuid],
    getFixedGrainContractByPaymentRequestUuid,
    { enabled: !!paymentRequestUuid && type === PaymentRequestType.FIXED }
  );

  switch (type) {
    case PaymentRequestType.STANDARD:
    default:
      return (
        <>
          <Text className="text-xl text-gray-900 font-medium mb-4">
            {t('payPaymentRequest.selectToken')}
          </Text>
          <SelectTokens
            token={token}
            setToken={setToken}
            isDisabled={isInBlockchain}
          />
        </>
      );
    case PaymentRequestType.IN_KIND:
      return (
        <>
          <Text className="text-xl text-gray-900 font-medium mb-4">
            {t('payPaymentRequest.selectToken')}
          </Text>
          <SelectTokens
            token={token}
            setToken={setToken}
            isDisabled={isInBlockchain}
          />
        </>
      );
    case PaymentRequestType.FIXED:
      return (
        <>
          {fixedGrainContract && (
            <Flex direction="column" gap="4">
              <Text size="xl" variant="medium">
                {t('payPaymentRequest.selectedAsset')}
              </Text>
              <PaymentRequestFixedGrainCard
                showRadioButton={false}
                grainContract={fixedGrainContract}
              ></PaymentRequestFixedGrainCard>
            </Flex>
          )}
        </>
      );
    case PaymentRequestType.FUTURE_WARRANTY:
      return (
        <>
          <Text
            fontFamily="Larsseit"
            fontStyle="normal"
            fontWeight="500"
            fontSize="20px"
            lineHeight="30px"
            className="mb-4"
          >
            Garantia com a qual o pagamento será feito:
          </Text>
          {grainContract === undefined ? (
            <Loader />
          ) : (
            <WarrantyCard
              key={grainContract.requestNumber}
              currencyName={grainContract.currency.name as 'SOYB' | 'CORB'}
              requestNumber={grainContract.requestNumber!}
              amountInSacas={grainContract.conversions.amountConversion}
              initialAmountInSacas={grainContract.conversions.initialConversion}
              farmCity={grainContract.farm?.city?.name ?? ''}
              farmGeographicalDivision={
                grainContract.farm?.city?.geographicalDivision.name ?? ''
              }
              expirationDate={grainContract.expirationDate}
              healthControl={Number(grainContract.healthControl)}
              status={'COMPLETED'}
              origin={Origin.TOKENIZATION}
              equivalencesAmountBrl={
                grainContract.equivalences.amount[EUnits.BRL]
              }
            />
          )}
        </>
      );
  }
};

export default SelectTypePaymentMethod;
