import {
  formatNumber,
  TokenCurrency,
  TOKEN_CURRENCIES,
} from '@agrotoken/common-utils';
import { CornToken, SoyToken, WheatToken } from '@agrotoken-tech/ui';
import { Box, Flex, Text } from '@chakra-ui/react';

import { BaseCard } from '@comp/BaseCard';

/**
 * A mapping of token currency codes to their corresponding icon components.
 * @type {Object}
 */
const TOKEN_ICON = {
  [TOKEN_CURRENCIES.SOYA]: <SoyToken size="xs" />,
  [TOKEN_CURRENCIES.SOYB]: <SoyToken size="xs" />,
  [TOKEN_CURRENCIES.CORA]: <CornToken size="xs" />,
  [TOKEN_CURRENCIES.CORB]: <CornToken size="xs" />,
  [TOKEN_CURRENCIES.WHEA]: <WheatToken size="xs" />,
  [TOKEN_CURRENCIES.WHEB]: <></>,
  [TOKEN_CURRENCIES.ABRC]: <></>,
  [TOKEN_CURRENCIES.COFB]: <></>,
};

/**
 * A mapping of token currency codes to their background colors.
 * @type {Object}
 */
const BACKGROUND_COLOR = {
  [TOKEN_CURRENCIES.SOYA]: '#C0FAA1',
  [TOKEN_CURRENCIES.SOYB]: '#C0FAA1',
  [TOKEN_CURRENCIES.CORA]: '#F8FAA1',
  [TOKEN_CURRENCIES.CORB]: '#F8FAA1',
  [TOKEN_CURRENCIES.WHEA]: '',
  [TOKEN_CURRENCIES.WHEB]: '',
  [TOKEN_CURRENCIES.ABRC]: '',
  [TOKEN_CURRENCIES.COFB]: '',
};

interface TokenBalanceCardProps {
  amountTokens: number;
  amountFiat: number;
  amountSacas: number;
  currency: TokenCurrency;
}

/**
 * React component for displaying the balance of a specific token currency.
 *
 * @param {number} amountTokens - The amount of tokens.
 * @param {number} amountFiat - The equivalent amount in fiat currency.
 * @param {number} amountSacas - The equivalent amount in SACAS.
 * @param {TokenCurrency} currency - The token currency.
 */
const TokenBalanceCard = ({
  amountTokens,
  amountFiat,
  amountSacas,
  currency,
}: TokenBalanceCardProps) => {
  const Subtitle = (
    <Flex columnGap="1" alignItems="center">
      {formatNumber(amountTokens, 'CRYPTO')}
      {TOKEN_ICON[currency]}
    </Flex>
  );

  const BottomElements = (
    <Box>
      <Text variant="medium" size="sm" color="content-secondary" mb="1">
        {`≈ R$ ${amountFiat}`}
      </Text>
      <Text variant="regular" size="sm" color="content-secondary">
        {`≈ ${amountSacas} Sacas`}
      </Text>
    </Box>
  );

  return (
    <BaseCard
      title={currency}
      subtitle={Subtitle}
      variant="white"
      bottomElements={BottomElements}
      borderWidth="0"
      backgroundColor={BACKGROUND_COLOR[currency]}
    />
  );
};

export default TokenBalanceCard;
