import { useQuery } from 'react-query';

import { getLoanCollateralizationDetails } from '@services/loans';

const useCollateralizeLoanDetails = (uuid: string) => {
  const { data, isLoading, error, refetch } = useQuery(
    ['loans-collateralization-details', uuid],
    () => getLoanCollateralizationDetails(uuid)
  );

  return { data, isLoading, error, refetch };
};

export default useCollateralizeLoanDetails;
