import { PLATFORM_PREFIX } from '@agrotoken/common-utils';
import {
  OnboardingLevel2RequestDto,
  IOnboardingLevel2Request,
  IOnboardingLevel3Request,
  OnboardingLevel3RequestDto,
  IBusinessUser,
  UpdateOnboardingLevel2RequestDto,
  UpdateOnboardingLevel3RequestDto,
} from '@common/types';
import { http } from '@src/http-common';

export const createOnboardingLevel2 = async (
  body: OnboardingLevel2RequestDto
) => {
  try {
    const resp = await http.post(
      `/${PLATFORM_PREFIX}/onboarding-levels/level-2`,
      body
    );
    const dataResult = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateOnboardingLevel2 = async (
  body: UpdateOnboardingLevel2RequestDto
) => {
  try {
    const resp = await http.put(
      `/${PLATFORM_PREFIX}/onboarding-levels/level-2`,
      body
    );
    const dataResult = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const createOnboardingLevel3 = async (
  body: OnboardingLevel3RequestDto
) => {
  try {
    const resp = await http.post(
      `/${PLATFORM_PREFIX}/onboarding-levels/level-3`,
      body
    );
    const dataResult = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateOnboardingLevel3 = async (
  body: UpdateOnboardingLevel3RequestDto
) => {
  try {
    const resp = await http.put(
      `/${PLATFORM_PREFIX}/onboarding-levels/level-3`,
      body
    );
    const dataResult = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
};

// TODO: Deprecate below

// TODO: Improve typing
export const saveOnboardingLevel2 = async (
  body: OnboardingLevel2RequestDto
) => {
  try {
    const resp = await http.put(
      `/${PLATFORM_PREFIX}/onboarding-level2-requests`,
      body
    );
    const dataResult = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

// TODO: Improve typing
export const getOnboardingLevel2 = async (): Promise<any> => {
  try {
    const resp = await http.get(
      `/${PLATFORM_PREFIX}/onboarding-level2-requests/pending-request`
    );
    const dataResult = resp.data;
    return dataResult;
  } catch (error: any) {
    throw new Error(error.response.data!.message);
  }
};

// TODO: Unify this method with above's
export const getLvl2Request = async (): Promise<
  IOnboardingLevel2Request | undefined
> => {
  const response = await http.get(
    `/${PLATFORM_PREFIX}/onboarding-level2-requests/pending-request`
  );
  return response.data;
};

export const saveOnboardingLevel3 = async (
  data: OnboardingLevel3RequestDto
) => {
  return http.put(`/${PLATFORM_PREFIX}/onboarding-level3-requests`, data);
};

export const getLvl3Request = async (): Promise<
  IOnboardingLevel3Request | undefined
> => {
  const response = await http.get(
    `/${PLATFORM_PREFIX}/onboarding-level3-requests/pending-request`
  );
  return response.data;
};
