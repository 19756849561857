import { formatNumber } from '@agrotoken/common-utils';
import { Card, CornIcon, RadioButton, SoyIcon, Text } from '@agrotoken-tech/ui';
import { Divider, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ETokensBR } from '@common/types';
import { FixedGrainCardProps } from './interfaces/fixedGrainCardProps';

const PaymentRequestFixedGrainCard: FC<FixedGrainCardProps> = (
  props: FixedGrainCardProps
) => {
  const { t } = useTranslation();
  const { grainContract, showRadioButton } = props;
  const currencyIcon = {
    [ETokensBR.SOYB]: <SoyIcon />,
    [ETokensBR.CORB]: <CornIcon />,
    [ETokensBR.WHEB]: null,
  };

  return (
    <Card>
      <Flex>
        {showRadioButton && <RadioButton value={grainContract.id} />}
        <Text variant="medium" size="md">
          {grainContract.currency.displayName} #{grainContract.requestNumber}
        </Text>
      </Flex>
      <Divider my="4" />
      <Flex justifyContent={'space-between'}>
        <Flex gap={1}>
          <Text size="sm" variant="medium">
            {t('paymentRequestFixedGrainCard.price')}
          </Text>
          <Text size="sm" variant="regular" color="content-terciary">
            {formatNumber(
              grainContract.equivalences.amount['BRL'],
              'BRL',
              true
            )}
          </Text>
        </Flex>
        <Flex gap={1}>
          <Text size="sm" variant="medium">
            {t('paymentRequestFixedGrainCard.availableSacas')}
          </Text>
          <Text size="sm" variant="regular" color="content-terciary">
            {currencyIcon[grainContract.currency.name as ETokensBR]}
            {formatNumber(
              grainContract.equivalences.amount['SACAS'],
              'SACAS',
              false
            )}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default PaymentRequestFixedGrainCard;
