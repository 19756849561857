import { TokenCurrency } from '@agrotoken/common-utils';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  Link,
  Text,
  useToken,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiChevronRight, FiHome } from 'react-icons/fi';

import { HOME_PATH, WARRANTY_PATH } from '@common/const';
import { Origin } from '@common/types';

interface WarrantyTransferBreadcrumbProps {
  requestNumber: number;
  assetName: TokenCurrency;
  origin: Origin;
  isTransferCreation: boolean;
}

const WarrantyTransferBreadcrumb = ({
  requestNumber,
  assetName,
  origin,
  isTransferCreation,
}: WarrantyTransferBreadcrumbProps) => {
  const { t } = useTranslation();

  const translationKeyPrefix = 'transfers.warrantyBreadcrumb';
  const assetGrain = assetName ? t(`grain.${assetName}`) : '';

  const translation = isTransferCreation ? 'add' : 'detail';

  const gray300 = useToken('colors', 'gray.300');
  const gray500 = useToken('colors', 'gray.500');

  const warrantyId = requestNumber - 5000;

  const breadcrumbOptions = [
    {
      label: (
        <Text
          size="sm"
          variant="medium"
          color="gray.500"
          textDecoration="underline"
        >
          {t(`${translationKeyPrefix}.before`, {
            assetName: assetGrain,
            requestNumber,
          })}
        </Text>
      ),
      href: `${WARRANTY_PATH}/${warrantyId}/${origin}`,
    },
    {
      label: (
        <Text size="sm" variant="medium" color="gray.700">
          {t(`${translationKeyPrefix}.actual.${translation}`)}
        </Text>
      ),
    },
  ];
  return (
    <Flex align="center">
      <Link href={HOME_PATH} rel="noreferrer" mr="4">
        <FiHome size="20" color={gray500} />
      </Link>
      <FiChevronRight size="16" color={gray300} />
      <Breadcrumb
        ml="4"
        separator={
          <BreadcrumbSeparator>
            <FiChevronRight size="16" color={gray300} />
          </BreadcrumbSeparator>
        }
      >
        {breadcrumbOptions.map((option, index) => (
          <BreadcrumbItem key={index}>
            <BreadcrumbLink href={option.href}>{option.label}</BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Flex>
  );
};

export default WarrantyTransferBreadcrumb;
