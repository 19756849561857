import { EParameterId, ExtendedWarranty, Origin } from '@common/types';
import { createContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getWarranty } from '@services/GrainContracts';
import { getParameterById } from '@services/parameters';

interface CreateWarrantyTransferDataContextType {
  warranty?: ExtendedWarranty;
  origin?: Origin;
  transferExpirationLimitHours?: number;
  senderTransferFeePercentage?: number;
  isLoadingWarranty: boolean;
}

export const CreateWarrantyTransferDataContext =
  createContext<CreateWarrantyTransferDataContextType>(
    {} as CreateWarrantyTransferDataContextType
  );

interface CreateWarrantyTransferDataProviderProps {
  children?: JSX.Element | JSX.Element[];
}

const CreateWarrantyTransferDataProvider = ({
  children,
}: CreateWarrantyTransferDataProviderProps) => {
  const { gcRequestNumber } = useParams();

  // TODO: Create a service to find grain contract by Uuid or requestNumber to avoid this subtraction
  const grainContractId = parseInt(gcRequestNumber ?? '') - 5000; //Need to sub 5000 to get GC id

  const { data: warranty, isLoading: isLoadingWarranty } = useQuery(
    ['warranty', grainContractId],
    getWarranty
  );

  const { data: transferExpirationLimitParameter } = useQuery(
    ['parameter', EParameterId.TRANSFER_EXPIRATION_LIMIT],
    getParameterById
  );

  const { data: senderTransferFeePercentageParameter } = useQuery(
    ['parameter', EParameterId.SENDER_WARRANTY_TRANSFER_FEE_PERCENTAGE],
    getParameterById
  );

  return (
    <CreateWarrantyTransferDataContext.Provider
      value={{
        transferExpirationLimitHours: transferExpirationLimitParameter?.value,
        senderTransferFeePercentage:
          senderTransferFeePercentageParameter?.value,
        warranty,
        isLoadingWarranty,
      }}
    >
      {children}
    </CreateWarrantyTransferDataContext.Provider>
  );
};

export default CreateWarrantyTransferDataProvider;
