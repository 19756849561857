import {
  IResponseCreateCardShippingAddress,
  IShippingAddress,
} from './shippingAddress';

export interface ICard {
  cardType: ECardType;
  businessId: number;
  defaultCurrency: string;
  shippingAddress?: IShippingAddress;
  previousCardId?: string;
  idempotencyKey?: string;
  businessUserId?: number;
  createdAt?: string;
  defaultCurrencyId?: number;
  expiration?: null;
  expirationDate?: null;
  lastFour?: string;
  shippingAddressId?: number;
  status?: ECardStatus;
  statusReason?: string;
  updatedAt?: string;
}

export interface ICardCreateResponse {
  id: number;
  shippingAdress: IResponseCreateCardShippingAddress;
}

export enum ECardType {
  VIRTUAL = 'VIRTUAL',
  PHYSICAL = 'PHYSICAL',
}

export enum ECardStatus {
  ACTIVE = 'active',
  BLOCKED = 'paused',
  DISABLED = 'blocked',
  CREATED = 'created',
  EMBOSSED = 'embossed',
}
