import { Modal } from '@agrotoken-tech/ui';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';

interface AcceptTransferModalProps {
  isOpen: boolean;
  onClickAcceptTransferHandler: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

const AcceptTransferModal = ({
  isOpen,
  onClickAcceptTransferHandler,
  onClose,
  isLoading,
}: AcceptTransferModalProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.details.acceptModal',
  });

  return (
    <Modal
      title={t('title')}
      text={t('text')}
      labelBtnConfirm={t('labelBtnConfirm')}
      labelBtnClose={t('labelBtnClose')}
      icon={FiAlertCircle}
      isOpen={isOpen}
      onClose={onClose}
      onClickConfirm={onClickAcceptTransferHandler}
      variantBtnConfirm="primary"
      isLoadingConfirmBtn={isLoading}
    />
  );
};

export default AcceptTransferModal;
