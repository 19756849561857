import { Box, Flex, Text, Image, Spinner, Button } from '@chakra-ui/react';
import { useState, FC } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle, FiArrowLeft } from 'react-icons/fi';
import { useMutation } from 'react-query';
import { ECurrencySymbol } from '@common/enum';
import { Toast } from '@comp/Global/Toast';
import { ECardType } from '@def/card';
import { createCard } from '@services/Card';
import { useCards } from '@src/context/cardsContext';
import { useUser } from '@src/context/userContext';
import cardAgrotoken from '../../../../../assets/images/cardAgrotoken.svg';
import SecondStepForm from './SecondStepForm';
import { SecondStepFormContainerProps } from './interfaces';

const SecondStepFormContainer: FC<SecondStepFormContainerProps> = ({
  setUserHasRequestedCard,
  setShippingAddress,
}) => {
  const [cityByGeographicalDivisionId, setCityByGeographicalDivisionId] =
    useState<number | undefined>();
  const [showToast, setShowToast] = useState<boolean>(false);
  const { selectedBusinessId } = useUser();
  const { setShowCardShipmentForm } = useCards();
  const { t } = useTranslation();
  const { mutate: mutateCard, isLoading } = useMutation(createCard);

  const onSubmit = (values: FieldValues) => {
    const {
      street: legalAddress,
      postalCode,
      streetNumber,
      apartment,
      floor,
    } = values;

    // Check if the user has selected a business and a city
    if (!selectedBusinessId || !cityByGeographicalDivisionId) return;

    const data = {
      cardType: ECardType.PHYSICAL,
      businessId: selectedBusinessId,
      defaultCurrency: ECurrencySymbol.SOYA,
      shippingAddress: {
        cityId: cityByGeographicalDivisionId,
        legalAddress,
        streetNumber: streetNumber.toString(),
        postalCode: postalCode.toString(),
        apartment,
        floor,
      },
    };

    mutateCard(data, {
      onSuccess: async (data) => {
        setShippingAddress(await data);
        setUserHasRequestedCard((prev) => !prev);
      },
      onError: () => {
        setShowToast((prev) => !prev);
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <Box display="block" marginX="auto">
          <Spinner />
        </Box>
      ) : (
        <>
          <Box
            display={{ base: 'block', lg: 'flex' }}
            flexFlow="column"
            justifyContent="center"
            flex="1 1 auto"
          >
            <Box display={{ base: 'block', lg: 'flex' }} flex="1 1 auto">
              <>
                <Flex
                  direction={'column'}
                  paddingLeft="80px"
                  paddingRight="137px"
                  paddingBottom="32px"
                  width={{ base: '100%', lg: '60%' }}
                  display={{ base: 'block', lg: 'flex' }}
                  fontFamily="Larsseit"
                >
                  <Button
                    justifyContent={'flex-start'}
                    mb={4}
                    variant="linkPrimary"
                    leftIcon={<FiArrowLeft />}
                    onClick={() => setShowCardShipmentForm(false)}
                  >
                    {t('tokenization.backButton')}
                  </Button>
                  <Box marginBottom="32px">
                    <Text
                      fontStyle="normal"
                      fontWeight="medium"
                      fontSize="2xl"
                      lineHeight={6}
                      letterSpacing="-0.01em"
                      color="gray.900"
                    >
                      {t('card.shipmentCard.title')}
                    </Text>
                    <Text
                      fontStyle="normal"
                      fontWeight="normal"
                      fontSize="sm"
                      lineHeight={5}
                      color="gray.600"
                    >
                      {t('card.shipmentCard.subtitle')}
                    </Text>
                  </Box>
                  <Box>
                    <SecondStepForm
                      onSubmit={onSubmit}
                      setCityByGeographicalDivisionId={
                        setCityByGeographicalDivisionId
                      }
                    />
                  </Box>
                </Flex>
                <Box width={{ base: '100%', lg: '40%' }} background="#F5F3EF">
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Image margin="auto" src={cardAgrotoken} />
                  </Flex>
                </Box>
              </>
            </Box>
            <Toast
              className="fixed top-6 right-6 z-50"
              show={showToast}
              onClickClose={() => setShowToast(false)}
              title={t('toasts.serverError.title')}
              text={t('toasts.serverError.text')}
              featuredIcon={FiAlertCircle}
              variant="destructive"
            />
          </Box>
        </>
      )}
    </>
  );
};

export default SecondStepFormContainer;
