import { ENationalities } from '@agrotoken/common-utils';
import { ContextPhoneInput } from '@agrotoken-tech/ui';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
} from '@chakra-ui/react';
import { EPixTypeId, IPixType } from '@common/types';
import { useAuth } from '@src/context/authContext';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPixTypes } from '@services/PixTypes';

const PixForm = () => {
  const { nationality } = useAuth();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboardingLevelThree',
  });

  const context = useFormContext();
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = context;

  const pixTypeId = Number(watch('pixTypeId'));

  const {
    isError: isErrorPixTypes,
    isLoading: isLoadingPixTyes,
    data: pixTypes,
  } = useQuery<IPixType[]>(['pixTypes'], getPixTypes);

  if (isErrorPixTypes) return <div>error</div>;

  // if (isLoadingPixTyes) return <Spinner />;

  return (
    <Grid
      templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
      columnGap="6"
      rowGap="4"
    >
      <FormControl isInvalid={Boolean(errors.pixTypeId)}>
        <FormLabel htmlFor="pixTypeId">
          {t(`bankAccountInfo.${nationality}.pixTypeId.label`)}
        </FormLabel>
        {/* TODO: There's a warning in browser console */}
        <Controller
          control={control}
          name="pixTypeId"
          defaultValue=""
          rules={{ required: t('requiredMsg') }}
          render={({ field }) => (
            <Select id="pixTypeId" {...field}>
              <option value="" disabled>
                {t(`bankAccountInfo.${nationality}.pixTypeId.placeholder`)}
              </option>
              {pixTypes?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.description}
                </option>
              ))}
            </Select>
          )}
        ></Controller>
        <FormErrorMessage>{errors.pixTypeId?.message}</FormErrorMessage>
      </FormControl>

      {pixTypeId === EPixTypeId.EMAIL && (
        <FormControl isInvalid={Boolean(errors.pix)}>
          <FormLabel htmlFor="pix">
            {t(`bankAccountInfo.${nationality}.pix.EMAIL.label`)}
          </FormLabel>
          <Input
            id="pix"
            type="email"
            placeholder={t(
              `bankAccountInfo.${nationality}.pix.EMAIL.placeholder`
            )}
            {...register('pix', {
              required: t('requiredMsg'),
            })}
          />
          <FormErrorMessage>{errors.pix?.message}</FormErrorMessage>
        </FormControl>
      )}
      {pixTypeId === EPixTypeId.PHONE_NUMBER && (
        <ContextPhoneInput
          label={t(`bankAccountInfo.${nationality}.pix.PHONE_NUMBER.label`)}
          name="pix"
          context={context}
          defaultCounty={ENationalities.BR}
          options={{
            required: t('requiredMsg'),
          }}
        />
      )}
      {pixTypeId === EPixTypeId.RANDOM_KEY && (
        <FormControl isInvalid={Boolean(errors.pix)}>
          <FormLabel htmlFor="pix">
            {t(`bankAccountInfo.${nationality}.pix.RANDOM_KEY.label`)}
          </FormLabel>
          <Input
            id="pix"
            type="text"
            placeholder={t(
              `bankAccountInfo.${nationality}.pix.RANDOM_KEY.placeholder`
            )}
            {...register('pix', {
              required: t('requiredMsg'),
            })}
          />
          <FormErrorMessage>{errors.pix?.message}</FormErrorMessage>
        </FormControl>
      )}
      {pixTypeId === EPixTypeId.FISCAL_ID && (
        <FormControl isInvalid={Boolean(errors.pix)}>
          <FormLabel htmlFor="pix">
            {t(`bankAccountInfo.${nationality}.pix.FISCAL_ID.label`)}
          </FormLabel>
          <Input
            id="pix"
            type="text"
            placeholder={t(
              `bankAccountInfo.${nationality}.pix.FISCAL_ID.placeholder`
            )}
            {...register('pix', {
              required: t('requiredMsg'),
            })}
          />
          <FormErrorMessage>{errors.pix?.message}</FormErrorMessage>
        </FormControl>
      )}
    </Grid>
  );
};

export default PixForm;
