import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  link: string;
}
export const ShareLink: FC<Props> = ({ link }) => {
  const { t } = useTranslation();

  const linkRef = useRef<HTMLParagraphElement>(null);
  const copyToClipboard = () => {
    if (!linkRef.current) return;
    navigator.clipboard.writeText(linkRef.current.innerText);
  };
  const sendMeThisEmail = () => {
    document.location =
      'mailto:?subject=AGROTOKEN - New Payment Request&body=' + link;
  };
  const handleClickWindow = () => {
    window.open(`https://api.whatsapp.com/send?text=${link}`, '_blank');
  };

  return (
    <Box className="bg-gray-50 border border-gray-200 p-4 rounded flex items-center flex-wrap gap-x-8 gap-y-4 mb-6">
      <Box className="w-full md:w-max">
        <Text className="text-sm text-gray-900 font-normal">
          {t('paymentCreated.link.label')}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="flex gap-3"
        >
          <Text
            className="my-1.5 px-3.5 py-2.5 bg-white rounded border border-gray-300 shadow-xs"
            ref={linkRef}
            height="3rem"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            maxWidth="369px"
          >
            {link}
          </Text>

          <Button
            size="lg"
            backgroundColor={'white'}
            borderColor={'gray.300'}
            textColor={'gray.700'}
            onClick={sendMeThisEmail}
          >
            {t('paymentCreated.link.shareBtnLabel')}
          </Button>
          <Button
            size="lg"
            backgroundColor={'white'}
            borderColor={'gray.300'}
            textColor={'gray.700'}
            onClick={handleClickWindow}
          >
            {t('paymentCreated.link.whatsappBtnLabel')}
          </Button>
        </Flex>
        <Text
          className="cursor-pointer text-sm font-normal text-gray-900"
          onClick={copyToClipboard}
        >
          {t('paymentCreated.link.copyLabel')}
        </Text>
      </Box>
    </Box>
  );
};
