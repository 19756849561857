import { validateCBU } from '@agrotoken/common-utils';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useAuth } from '@src/context/authContext';
import { VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  indexOfSelectedBankAccount: number;
}

const BankDetailsFields: VFC<Props> = ({ indexOfSelectedBankAccount }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { nationality } = useAuth();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'onboardingLevelThree',
  });

  const bankNameError =
    errors.businessBankAccounts?.[indexOfSelectedBankAccount]?.entityName;
  const cbuError =
    errors?.businessBankAccounts?.[indexOfSelectedBankAccount]?.cbu;
  const accountTypeError =
    errors.businessBankAccounts?.[indexOfSelectedBankAccount]?.accountTypeId;

  return (
    <>
      <Heading fontSize="2xl" mt="16">
        {t(`bankAccountInfo.${nationality}.title`)}
      </Heading>
      <Text>{t(`bankAccountInfo.${nationality}.subtitle`)}</Text>
      <SimpleGrid columns={[1, 1, 2]} gap="4">
        <FormControl isInvalid={Boolean(cbuError)}>
          <FormLabel htmlFor="CBU">
            {t(`bankAccountInfo.${nationality}.cbu.label`)}
          </FormLabel>
          <Input
            type="tel"
            id="CBU"
            h="12"
            {...register(
              `businessBankAccounts.${indexOfSelectedBankAccount}.cbu`,
              {
                required: t('requiredMsg'),
                validate: validateCBU,
              }
            )}
          />

          {cbuError && (
            <FormErrorMessage>
              {cbuError?.message ||
                t(`bankAccountInfo.${nationality}.cbu.invalidCbuMessage`)}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="alias">
            {t(`bankAccountInfo.${nationality}.alias.label`)}
          </FormLabel>
          <Input
            id="alias"
            h="12"
            {...register(
              `businessBankAccounts.${indexOfSelectedBankAccount}.alias`
            )}
          />
        </FormControl>
      </SimpleGrid>
    </>
  );
};
export default BankDetailsFields;
