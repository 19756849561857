import { FormErrorMessage } from '@agrotoken-tech/ui';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormSectionCard from '@comp/FormSectionCard';

import { UseCreateWarrantyTransferValues } from '../hooks/useCreateWarrantyTransferForm';

interface TransferFormDataSectionProps {
  form: UseFormReturn<UseCreateWarrantyTransferValues, object>;
}

const TransferFormDataSection = ({ form }: TransferFormDataSectionProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'transfers.createWarrantyTransfer.form.transferDataSection',
  });

  const { register } = form;

  return (
    <FormSectionCard title={t('title')}>
      <FormControl isInvalid={!!form.formState.errors.amountFiat}>
        <FormLabel
          size="sm"
          variant="medium"
          color="gray.700"
          htmlFor="amountFiat"
        >
          {t('inputLabel')}
        </FormLabel>
        <InputGroup>
          <InputLeftAddon
            bg="white"
            border="1px"
            borderColor="gray.300"
            rounded="md"
          >
            {t('inputLeftAddon')}
          </InputLeftAddon>
          <Input
            {...register('amountFiat')}
            id="amountFiat"
            type="number"
            step="any"
            placeholder={t('inputPlaceholder')}
          />
        </InputGroup>
        <FormErrorMessage>
          {form.formState.errors.amountFiat?.message}
        </FormErrorMessage>
      </FormControl>
    </FormSectionCard>
  );
};
export default TransferFormDataSection;
