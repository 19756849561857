import { WEIGHT_UNITS } from '@agrotoken/common-utils';
import {
  EPaymentRequestOperationType,
  roundValue,
} from '@agrotoken/frontend-utils';
import { Text } from '@chakra-ui/react';
import { Decimal } from 'decimal.js';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentRequestAsset } from '@common/enum';
import { CryptoCurrencySymbol, EUnits } from '@common/types';
import { Forwarding } from '@common/types/payment-request-forwarding';
import { formatNumber, getLocalCurrency } from '@common/utils';
import { GenericSummary, SummaryElement } from '@comp/GenericSummary';
import { TokenAmount } from '@comp/PaymentRequestView/Fragments/TokenAmount';
import { useGetConversion } from '@hooks/index';
import { useAuth } from '@src/context/authContext';

interface PaymentSummaryProps {
  status: 'READY' | 'LOADING' | 'EMPTY';
  amount: number | Decimal; // In local currency.
  tax: number | Decimal; // In local currency.
  paymentAsset: PaymentRequestAsset;
  isPayer: boolean;
  warrantyReceptionDate?: number;
  selectedToken?: CryptoCurrencySymbol;
  createdDate?: number;
  warrantyPricePerSaca?: Decimal; // In local currency per saca
  receiverFee?: Decimal;
  payerFee?: Decimal;
  fixedFinalPrice?: Decimal;
  forwardings?: Forwarding[];
}

export const PaymentSummaryPayer: FC<PaymentSummaryProps> = ({
  status,
  amount,
  tax,
  paymentAsset,
  isPayer,
  selectedToken,
  createdDate,
  warrantyPricePerSaca,
  fixedFinalPrice,
  payerFee,
}) => {
  const { t } = useTranslation();
  const { nationality } = useAuth();
  const localCurrency = getLocalCurrency(nationality);
  const showAmountsInSacas =
    (paymentAsset === PaymentRequestAsset.WARRANTY ||
      paymentAsset === PaymentRequestAsset.FIXED_GRAIN) &&
    isPayer;
  const showAmountsInTokens =
    paymentAsset === PaymentRequestAsset.TOKENS && selectedToken;
  //TODO: use hooks to getBottom and getTop elements

  /**
   * Currency varies depending on the parameters:
   * When using warranties, it should set local amount when showing to receiver, and SACAS when showing to payer.
   * When using tokens, it should set the amount in tokens when a selected token is received, or local currency otherwise.
   */
  const convertToRequiredCurrency = (params: {
    tokenAmount: number;
    isFee?: boolean;
  }) => {
    const { tokenAmount, isFee = false } = params;
    const amountInTokens = new Decimal(tokenAmount);
    const amountInBRL = new Decimal(amount);

    if (showAmountsInSacas) {
      const pricePerSaca =
        paymentAsset === PaymentRequestAsset.WARRANTY
          ? warrantyPricePerSaca
          : fixedFinalPrice;

      return amountInBRL.div(pricePerSaca ?? 1).toNumber();
    }

    if (showAmountsInTokens) {
      const operationType = isFee
        ? EPaymentRequestOperationType.FARMER_FEE
        : EPaymentRequestOperationType.TOKENS_TO_PAY_BY_FARMER;

      // Convert to Tokens
      return roundValue({
        value: tokenAmount ?? 1,
        operationType,
        scale: 4,
      }).toNumber();
    }

    return amountInTokens.toNumber();
  };

  // Change amount/taxes/totalToPay according to what we need to show.
  const paymentRequestAmountFormatted = formatNumber(
    amount,
    localCurrency,
    true
  );
  const amountEquivalence = convertToRequiredCurrency({
    tokenAmount: useGetConversion(
      Number(amount),
      localCurrency,
      selectedToken,
      WEIGHT_UNITS.TONS
    ),
  });

  const feeAmountEquivalence = convertToRequiredCurrency({
    tokenAmount: useGetConversion(
      Number(tax),
      localCurrency,
      selectedToken,
      WEIGHT_UNITS.TONS
    ),
    isFee: true,
  });

  const totalToPay = amountEquivalence + feeAmountEquivalence;

  const getTopElements = (): SummaryElement[] => {
    const paymentRequestAmountElement: SummaryElement = {
      left: (
        <Text size="lg" variant="medium" color="gray.900">
          {t('payment.summary.localSubtotal')}
        </Text>
      ),
      right: (
        <Text size="lg" variant="medium" color="gray.900">
          {paymentRequestAmountFormatted}
        </Text>
      ),
    };

    const equivalenceElement: SummaryElement = {
      left: (
        <Text size="sm" color="gray.700">
          {t('payment.summary.equivalence')}
        </Text>
      ),
      right: (
        <Text size="sm" color="gray.700">
          {formatNumber(amountEquivalence, EUnits.SACAS, true)}
        </Text>
      ),
    };

    if (showAmountsInTokens) {
      paymentRequestAmountElement.right = (
        <TokenAmount amount={amountEquivalence} token={selectedToken} />
      );

      equivalenceElement.right = (
        <Text size="sm" color="gray.700">
          {paymentRequestAmountFormatted}
        </Text>
      );
    }

    return [paymentRequestAmountElement, equivalenceElement];
  };

  // TODO: Fix classes for left side paragraphs.
  const getBottomElements = (): SummaryElement[] => {
    const feeElement: SummaryElement = {
      left: (
        <Text size="sm" color="gray.700">
          {t('payment.summary.internalTax') + ` (${payerFee}%)`}
        </Text>
      ),
      right: (
        <Text size="sm" color="gray.700">
          {formatNumber(tax, localCurrency, true)}
        </Text>
      ),
    };

    if (showAmountsInTokens) {
      const totalElement: SummaryElement = {
        left: (
          <Text size="sm" color="gray.700">
            {t('payment.summary.totalToPay')}
          </Text>
        ),
        right: <TokenAmount amount={totalToPay} token={selectedToken} />,
      };

      feeElement.right = (
        <TokenAmount amount={feeAmountEquivalence} token={selectedToken} />
      );

      return [feeElement, totalElement];
    }

    return [feeElement];
  };

  return (
    <GenericSummary
      status={status}
      title={
        <Text size="sm" variant="medium" color="gray.700">
          {t('payment.summary.title')}
        </Text>
      }
      totalText={
        <Text size="sm" variant="medium" color="gray.700" mb="4">
          {t('payment.summary.details')}
        </Text>
      }
      totalAmount={<></>}
      topElements={getTopElements()}
      bottomElements={getBottomElements()}
      createdDate={createdDate}
    />
  );
};
