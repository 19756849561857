import { FeaturedIcons } from '@agrotoken-tech/ui';
import { Box, Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { type IconType } from 'react-icons';

interface ItemIconProps {
  icon?: IconType;
  label: ReactNode | string;
  value?: ReactNode | string;
  className?: string;
  variant?: 'success' | 'primary';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isBold?: boolean;
}
export const ItemIcon: FC<ItemIconProps> = ({
  icon,
  label,
  value,
  variant,
  className = '',
  size,
  isBold = true,
}) => {
  return (
    <Flex
      gap={2}
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems={{ base: 'none', sm: 'center' }}
      justifyContent="space-between"
      className={className}
    >
      <Flex gap={3}>
        <Box display={icon ? 'flex' : 'none'}>
          <FeaturedIcons
            shape="square"
            variant={variant ?? 'primary'}
            size={size ?? 'xs'}
            icon={icon}
          />
        </Box>
        <Box className="text-md text-gray-700 font-normal">{label}</Box>
      </Flex>
      <Box
        fontWeight={isBold ? 500 : 400}
        textColor={isBold ? '#101828' : '#667085'}
      >
        {value}
      </Box>
    </Flex>
  );
};
