export const TRANSFERS_TRANSLATIONS_ES = {
  transferWarrantyButton: 'Transferir garantía',
  createWarrantyTransfer: {
    heading: {
      title: 'Solicitar transferencia de garantía',
      subtitle:
        'Transfiera una cantidad de su garantía a otros clientes de Agrotoken',
      expiration: 'Solicitud vence en',
    },
    form: {
      errors: {
        fiscalId: {
          typeError: 'Este campo debe ser una cadena alfanumérica',
          required: 'Este campo es obligatorio',
        },
        comments: {
          typeError: 'Este campo debe ser una cadena alfanumérica',
        },
        amount: {
          typeError: 'Este campo debe ser un número',
          required: 'Este campo es obligatorio',
          moreThan0: 'La cantidad debe ser mayor a 0',
          lessThan:
            'El importe total de la transferencia no puede ser superior al valor total de la garantía.',
        },
        file: 'Tamaño del archivo excede el permitido',
      },
      button: 'Solicitar',
      generalDataSection: {
        title: '1. Datos generales',
        inputLabel: 'A quién quieres enviar la solicitud?',
        inputPlaceholder: 'Ingrese un número de {{fiscalPrefix}}',
        observationsLabel: 'Observaciones (opcional)',
        observationsPlaceholder: 'Digite una observación o comentario',
        observationsHintMessage: 'Digite um máximo de 90 caracteres.',
        dragAndDropLabel: 'Adjuntar archivo (opcional)',
        dragAndDropPlaceholderLink: 'Haga clic aquí',
        dragAndDropDragFile: 'o arraste el archivo',
        dragAndDropFormat: 'Formatos permitidos: PDF (tamaño máximo: 20MB)',
        error: 'El CUIT/CUIL no existe',
        hintMessage: 'El CUIT ingresado pertenece a ',
      },
      selectedWarrantyToTransfer: {
        title: '2. Garantía a transferir',
      },
      transferDataSection: {
        title: '3. Datos de transferencia',
        inputLabel: 'Valor a ser transferido',
        inputPlaceholder: 'Digite el valor que desea transferir',
        inputLeftAddon: 'BRL',
        error: 'La cantidad debe ser mayor a 0',
      },
    },
  },
  warrantyBreadcrumb: {
    actual: {
      add: 'Solicitar transferencia de garantia',
      detail: 'Transferencia de garantía',
    },
    before: 'Garantía de {{assetName}} #{{requestNumber}}',
  },
  warrantySummary: {
    topTitle: 'Resumen:',
    transferAmount: 'Total de transferencia',
    equivalence: 'Equivalencia',
    bottomTitle: 'Desglose:',
    fee: {
      text: 'Comisión de transacción ({{feePercentage, number}}%)',
      tooltip: 'Se debitará de los tokens recibidos de esta transferencia',
    },
    total: 'Total',
    emptyState:
      'Al completar la información, podrá ver un resumen de su solicitud',
    termsAndConditionsAccept: 'He leído y acepto los',
    termsAndConditions: 'términos y condiciones',
    termsAndConditionsLink:
      'https://agrotoken.notion.site/T-rminos-y-Condiciones-Generales-8aa02616adba488ebaa0ecc708908fc6',
    termsAndConditionsError: 'Debes aceptar los términos y condiciones de uso.',
    request: 'Solicitar',
    errors: {
      unknown: 'Ocurrió un error al intentar crear la transferencia',
    },
  },
  details: {
    errorMessages: {
      defaultError:
        'Ha ocurrido un error al momento de ejecutar esta transacción',
    },
    acceptModal: {
      title: '¿Está seguro de que desea aceptar la transferencia?',
      text: 'Al aceptar la transferencia, la acción no se puede revertir.',
      labelBtnConfirm: 'Aceptar',
      labelBtnClose: 'Cancelar',
    },
    rejectModal: {
      title: '¿Está seguro de que desea rechazar la transferencia?',
      text: 'Al rechazar la transferencia, la acción no se puede revertir y ya no podrá acceder a los detalles de su garantía.',
      labelBtnConfirm: 'Rechazar',
      labelBtnClose: 'Cancelar',
    },
    cancelModal: {
      title:
        '¿Está seguro de que desea cancelar la solicitud de transferencia?',
      text: 'Al cancelar la solicitud de transferencia, la acción no se puede revertir y el destinatario de la transferencia no podrá recibirla. Si desea crear una nueva transferencia con esta garantía, deberá crearla desde cero.',
      labelBtnConfirm: 'Cancelar transferencia',
      labelBtnClose: 'Volver',
    },
    buttons: {
      accept: 'Aceptar',
      reject: 'Rechazar',
      cancel: 'Cancelar transferencia',
    },
    heading: 'Transferencia de garantia',
    badges: {
      cancelled: 'Cancelado',
      error: 'Error',
      expired: 'Expirado',
      pendingApproval: 'Pendiente de confirmación',
      processing: 'Procesando en blockchain',
      rejected: 'Rechazada',
      successful: 'Finalizada',
    },
    operationDetail: {
      title: 'Detalles de la operación',
      topElementsTitle: 'Datos generales',
      bottomElementsTitle: 'Datos de tranferencia',
      createdAt: 'Fecha de creación:',
      acceptedAt: 'Transferido: {{acceptedAt}}',
      transactionNumber: 'Número de transacción:',
      business: {
        receiver: 'Receptor de la transferencia:',
        sender: 'Creador de la transferencia:',
      },
      warranty: {
        label: 'Garantía:',
        value: 'Garantía de {{assetName}} #{{requestNumber}}',
      },
      comment: 'Concepto:',
      document: 'Archivo adjunto:',
      total: 'Total de la transferencia:',
    },
    blockchainState: {
      title: 'La transacción esta siendo procesada en Blockchain.',
      subtitle: 'Este proceso puede demorar unos segundos.',
    },
    alerts: {
      sender: {
        cancelled: {
          text: 'Cancelaste la transferencia de garantía',
        },
        expired: {
          text: 'La solicitud de transferencia expiró',
          supportText:
            'Pasaron 48 horas sin que el usuario receptor acepte la transferencia.',
        },
        rejected: {
          text: 'El usuario receptor rechazó la transferencia.',
        },
      },
      receiver: {
        cancelled: {
          text: 'El usuario creador canceló la transferencia.',
        },
        expired: {
          text: 'La solicitud de transferencia expiró',
          supportText: 'Pasaron 48 horas sin que aceptes la transferencia.',
        },
        rejected: {
          text: 'Rechasaste la transferencia de garantía',
        },
      },
    },
  },
  unauthorized: {
    heading: 'Esta transferencia no ha sido asignada a su cuenta',
    text: 'Solo el destinatario tiene acceso a la transferencia. Póngase en contacto con la persona que le envió este enlace.',
    buttonLabel: 'Ir a la página principal',
  },
};

export type TransfersTranslationsType = typeof TRANSFERS_TRANSLATIONS_ES;
